import { ScreenTagView } from '@dooh/models';

export const SCREEN_TAGS = {
  RESOLUTIONS: 'resolution',
  PUBLISHERS: 'publisher',
  VENUETYPE: 'venueType',
  DMAID: 'dmaId',
  MIMETYPE:'mimeType'

};

export const RESPONSE_TAGS_MAP = {
  'resolution.name_agg_result': SCREEN_TAGS.RESOLUTIONS,
  'publisher_agg_result': SCREEN_TAGS.PUBLISHERS,
  'venue_agg_result': SCREEN_TAGS.VENUETYPE,
  'dma_agg_result': SCREEN_TAGS.DMAID,
  'mimeTypes_agg_result':SCREEN_TAGS.MIMETYPE
};

export const RESPONSE_SCREEN_TAGS = {
  [SCREEN_TAGS.PUBLISHERS]: 'publisher_agg_result',
  [SCREEN_TAGS.RESOLUTIONS]: 'resolution.name_agg_result',
  [SCREEN_TAGS.VENUETYPE]: 'venue_agg_result',
  [SCREEN_TAGS.DMAID]: 'dma_agg_result',
  [SCREEN_TAGS.MIMETYPE]:'mimeTypes_agg_result'
};

export interface ScreenTagsPagination {
  [key: string]: {
    page: number;
    pageSize: number;
  };
};

export interface ScreensFilters {
  screenTags?: ScreenTagView[] | ScreenTagView;
  pagination?: ScreenTagsPagination;
  pois?: { points: number[][], radius: number };
  bboxes?: any[];
  placeIq?: any;
  postalCodes?:any;
}
