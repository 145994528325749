/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AudienceTargetingForm } from '../model/audienceTargetingForm';
import { AudienceTargetingView } from '../model/audienceTargetingView';
import { CampaignDealRelationForm } from '../model/campaignDealRelationForm';
import { CampaignForm } from '../model/campaignForm';
import { CampaignSspRelationForm } from '../model/campaignSspRelationForm';
import { CampaignTargetingSetRelationForm } from '../model/campaignTargetingSetRelationForm';
import { CampaignView } from '../model/campaignView';
import { CampaignsPatchesForm } from '../model/campaignsPatchesForm';
import { LineItemBudgetForm } from '../model/lineItemBudgetForm';
import { LineItemBudgetView } from '../model/lineItemBudgetView';
import { LineItemCreativeLinkView } from '../model/lineItemCreativeLinkView';
import { LineItemCreativeRelationForm } from '../model/lineItemCreativeRelationForm';
import { LineItemCreativeView } from '../model/lineItemCreativeView';
import { LineItemForm } from '../model/lineItemForm';
import { LineItemScheduleForm } from '../model/lineItemScheduleForm';
import { LineItemScheduleView } from '../model/lineItemScheduleView';
import { LineItemScreenForm } from '../model/lineItemScreenForm';
import { LineItemScreenView } from '../model/lineItemScreenView';
import { LineItemView } from '../model/lineItemView';
import { LineItemsPatchesForm } from '../model/lineItemsPatchesForm';
import { PageDto } from '../model/pageDto';
import { RelatedDealView } from '../model/relatedDealView';
import { RelatedSspView } from '../model/relatedSspView';
import { RelatedTargetingSetView } from '../model/relatedTargetingSetView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CampaignsService {

  protected basePath = 'https://dooh-api.staging.theneuron.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Create new campaign
   * Create new campaign
   * @param body Campaign Object
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCampaign(body: CampaignForm, observe?: 'body', reportProgress?: boolean): Observable<CampaignView>;
  public createCampaign(body: CampaignForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampaignView>>;
  public createCampaign(body: CampaignForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampaignView>>;
  public createCampaign(body: CampaignForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createCampaign.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<CampaignView>(`${this.basePath}/dsp/campaigns`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create a new relation between campaign and deal
   * Create a new relation between campaign and deal
   * @param body Relation Object
   * @param campaignId Campaign Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCampaignDealRelation(body: CampaignDealRelationForm, campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<RelatedDealView>;
  public createCampaignDealRelation(body: CampaignDealRelationForm, campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RelatedDealView>>;
  public createCampaignDealRelation(body: CampaignDealRelationForm, campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RelatedDealView>>;
  public createCampaignDealRelation(body: CampaignDealRelationForm, campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createCampaignDealRelation.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling createCampaignDealRelation.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<RelatedDealView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/deals`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create new relations between campaign and deals
   * Create new relations between campaign and deals
   * @param body Relation Objects
   * @param campaignId Campaign Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCampaignDealRelations(body: Array<CampaignDealRelationForm>, campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public createCampaignDealRelations(body: Array<CampaignDealRelationForm>, campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public createCampaignDealRelations(body: Array<CampaignDealRelationForm>, campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public createCampaignDealRelations(body: Array<CampaignDealRelationForm>, campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createCampaignDealRelations.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling createCampaignDealRelations.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/deals_batch`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create a new campaign line item
   * Create a new campaign line item
   * @param body Campaign Line Item Object
   * @param campaignId Campaign Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCampaignLineItem(body: LineItemForm, campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<LineItemView>;
  public createCampaignLineItem(body: LineItemForm, campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LineItemView>>;
  public createCampaignLineItem(body: LineItemForm, campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LineItemView>>;
  public createCampaignLineItem(body: LineItemForm, campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createCampaignLineItem.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling createCampaignLineItem.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<LineItemView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create a new line item audience targeting
   * Create a new campaign line item audience targeting
   * @param body Campaign Line Item Audience Targeting Object
   * @param campaignId campaign_id
   * @param lineItemId line_item_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCampaignLineItemAudienceTargeting(body: AudienceTargetingForm, campaignId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<AudienceTargetingView>;
  public createCampaignLineItemAudienceTargeting(body: AudienceTargetingForm, campaignId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AudienceTargetingView>>;
  public createCampaignLineItemAudienceTargeting(body: AudienceTargetingForm, campaignId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AudienceTargetingView>>;
  public createCampaignLineItemAudienceTargeting(body: AudienceTargetingForm, campaignId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createCampaignLineItemAudienceTargeting.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling createCampaignLineItemAudienceTargeting.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling createCampaignLineItemAudienceTargeting.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<AudienceTargetingView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/demographics`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create a new line item budget
   * Create a new campaign line item budget
   * @param body Campaign Line Item Budget Object
   * @param campaignId Campaign Id
   * @param lineItemId Campaign Line Item Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCampaignLineItemBudget(body: LineItemBudgetForm, campaignId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<LineItemBudgetView>;
  public createCampaignLineItemBudget(body: LineItemBudgetForm, campaignId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LineItemBudgetView>>;
  public createCampaignLineItemBudget(body: LineItemBudgetForm, campaignId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LineItemBudgetView>>;
  public createCampaignLineItemBudget(body: LineItemBudgetForm, campaignId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createCampaignLineItemBudget.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling createCampaignLineItemBudget.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling createCampaignLineItemBudget.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<LineItemBudgetView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/budget`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create a new link between line item and creative
   * Create a new link between line item and creative
   * @param body Campaign Line Item Creative metadata
   * @param campaignId Campaign Id
   * @param lineItemId Campaign Line Item Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCampaignLineItemCreativeLink(body: LineItemCreativeRelationForm, campaignId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<LineItemCreativeView>;
  public createCampaignLineItemCreativeLink(body: LineItemCreativeRelationForm, campaignId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LineItemCreativeView>>;
  public createCampaignLineItemCreativeLink(body: LineItemCreativeRelationForm, campaignId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LineItemCreativeView>>;
  public createCampaignLineItemCreativeLink(body: LineItemCreativeRelationForm, campaignId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createCampaignLineItemCreativeLink.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling createCampaignLineItemCreativeLink.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling createCampaignLineItemCreativeLink.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<LineItemCreativeView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/creatives`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create a new line item schedule
   * Create a new campaign line item schedule
   * @param body Campaign Line Item Schedule Object
   * @param campaignId campaign_id
   * @param lineItemId line_item_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCampaignLineItemSchedule(body: LineItemScheduleForm, campaignId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<LineItemScheduleView>;
  public createCampaignLineItemSchedule(body: LineItemScheduleForm, campaignId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LineItemScheduleView>>;
  public createCampaignLineItemSchedule(body: LineItemScheduleForm, campaignId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LineItemScheduleView>>;
  public createCampaignLineItemSchedule(body: LineItemScheduleForm, campaignId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createCampaignLineItemSchedule.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling createCampaignLineItemSchedule.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling createCampaignLineItemSchedule.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<LineItemScheduleView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/schedule`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create a new campaign line item screen
   * Create a new campaign line item screen
   * @param body Campaign Line Item Screen Object
   * @param campaignId Campaign Id
   * @param lineItemId Campaign Line Item Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCampaignLineItemScreen(body: LineItemScreenForm, campaignId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<LineItemScreenView>;
  public createCampaignLineItemScreen(body: LineItemScreenForm, campaignId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LineItemScreenView>>;
  public createCampaignLineItemScreen(body: LineItemScreenForm, campaignId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LineItemScreenView>>;
  public createCampaignLineItemScreen(body: LineItemScreenForm, campaignId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createCampaignLineItemScreen.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling createCampaignLineItemScreen.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling createCampaignLineItemScreen.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<LineItemScreenView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/screens`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create a new relation between campaign and ssp
   * Create a new relation between campaign and ssp
   * @param body Relation Object
   * @param campaignId Campaign Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCampaignSspRelation(body: CampaignSspRelationForm, campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<RelatedSspView>;
  public createCampaignSspRelation(body: CampaignSspRelationForm, campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RelatedSspView>>;
  public createCampaignSspRelation(body: CampaignSspRelationForm, campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RelatedSspView>>;
  public createCampaignSspRelation(body: CampaignSspRelationForm, campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createCampaignSspRelation.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling createCampaignSspRelation.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<RelatedSspView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/ssps`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create new relations between campaign and ssps
   * Create new relations between campaign and ssps
   * @param body Relation Objects
   * @param campaignId Campaign Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCampaignSspRelations(body: Array<CampaignSspRelationForm>, campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public createCampaignSspRelations(body: Array<CampaignSspRelationForm>, campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public createCampaignSspRelations(body: Array<CampaignSspRelationForm>, campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public createCampaignSspRelations(body: Array<CampaignSspRelationForm>, campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createCampaignSspRelations.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling createCampaignSspRelations.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/ssps_batch`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create a new relation between campaign and targeting set
   * Create a new relation between campaign and targeting set
   * @param body Relation Object
   * @param campaignId Campaign Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCampaignTargetingSetRelation(body: CampaignTargetingSetRelationForm, campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<RelatedTargetingSetView>;
  public createCampaignTargetingSetRelation(body: CampaignTargetingSetRelationForm, campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RelatedTargetingSetView>>;
  public createCampaignTargetingSetRelation(body: CampaignTargetingSetRelationForm, campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RelatedTargetingSetView>>;
  public createCampaignTargetingSetRelation(body: CampaignTargetingSetRelationForm, campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createCampaignTargetingSetRelation.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling createCampaignTargetingSetRelation.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<RelatedTargetingSetView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/targeting_sets`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create new relations between campaign and targeting sets
   * Create new relations between campaign and targeting sets
   * @param body Relation Objects
   * @param campaignId Campaign Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCampaignTargetingSetRelations(body: Array<CampaignTargetingSetRelationForm>, campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public createCampaignTargetingSetRelations(body: Array<CampaignTargetingSetRelationForm>, campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public createCampaignTargetingSetRelations(body: Array<CampaignTargetingSetRelationForm>, campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public createCampaignTargetingSetRelations(body: Array<CampaignTargetingSetRelationForm>, campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createCampaignTargetingSetRelations.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling createCampaignTargetingSetRelations.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/targeting_sets_batch`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create new relations between line item and creatives
   * Create new relations between line item and creatives
   * @param body Relation Objects
   * @param campaignId Campaign Id
   * @param lineItemId Line Item Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createLineItemCreativeRelations(body: Array<LineItemCreativeRelationForm>, campaignId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public createLineItemCreativeRelations(body: Array<LineItemCreativeRelationForm>, campaignId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public createLineItemCreativeRelations(body: Array<LineItemCreativeRelationForm>, campaignId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public createLineItemCreativeRelations(body: Array<LineItemCreativeRelationForm>, campaignId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createLineItemCreativeRelations.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling createLineItemCreativeRelations.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling createLineItemCreativeRelations.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/creatives_batch`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete campaign
   * Delete campaign
   * @param campaignId Campaign Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCampaign(campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteCampaign(campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteCampaign(campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteCampaign(campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling deleteCampaign.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete<any>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete campaign and deal relation
   * Delete campaign and deal relation
   * @param campaignId Campaign Id
   * @param relationId Relation Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCampaignDealRelation(campaignId: string, relationId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteCampaignDealRelation(campaignId: string, relationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteCampaignDealRelation(campaignId: string, relationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteCampaignDealRelation(campaignId: string, relationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling deleteCampaignDealRelation.');
    }

    if (relationId === null || relationId === undefined) {
      throw new Error('Required parameter relationId was null or undefined when calling deleteCampaignDealRelation.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete<any>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/deals/${encodeURIComponent(String(relationId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete campaign and deal relations
   * Delete campaign and deal relations
   * @param body Relation IDs
   * @param campaignId Campaign Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCampaignDealRelations(body: Array<string>, campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteCampaignDealRelations(body: Array<string>, campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteCampaignDealRelations(body: Array<string>, campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteCampaignDealRelations(body: Array<string>, campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling deleteCampaignDealRelations.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling deleteCampaignDealRelations.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('DELETE', `${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/deals_batch`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: body
      }
    );
  }

  /**
   * Delete campaign line item
   * Delete campaign line item
   * @param campaignId Campaign Id
   * @param lineItemId Campaign Line Item Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCampaignLineItem(campaignId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteCampaignLineItem(campaignId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteCampaignLineItem(campaignId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteCampaignLineItem(campaignId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling deleteCampaignLineItem.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling deleteCampaignLineItem.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete<any>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete campaign line item audience targeting
   * Delete campaign line item audience targeting
   * @param campaignId Campaign Id
   * @param lineItemId Campaign Line Item Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCampaignLineItemAudienceTargeting(campaignId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<AudienceTargetingView>;
  public deleteCampaignLineItemAudienceTargeting(campaignId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AudienceTargetingView>>;
  public deleteCampaignLineItemAudienceTargeting(campaignId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AudienceTargetingView>>;
  public deleteCampaignLineItemAudienceTargeting(campaignId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling deleteCampaignLineItemAudienceTargeting.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling deleteCampaignLineItemAudienceTargeting.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete<AudienceTargetingView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/demographics`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete campaign line item budget
   * Delete campaign line item budget
   * @param campaignId Campaign Id
   * @param lineItemId Campaign Line Item Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCampaignLineItemBudget(campaignId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteCampaignLineItemBudget(campaignId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteCampaignLineItemBudget(campaignId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteCampaignLineItemBudget(campaignId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling deleteCampaignLineItemBudget.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling deleteCampaignLineItemBudget.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete<any>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/budget`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete campaign line item schedule
   * Delete campaign line item schedule
   * @param campaignId Campaign Id
   * @param lineItemId Campaign Line Item Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCampaignLineItemSchedule(campaignId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<LineItemScheduleView>;
  public deleteCampaignLineItemSchedule(campaignId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LineItemScheduleView>>;
  public deleteCampaignLineItemSchedule(campaignId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LineItemScheduleView>>;
  public deleteCampaignLineItemSchedule(campaignId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling deleteCampaignLineItemSchedule.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling deleteCampaignLineItemSchedule.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete<LineItemScheduleView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/schedule`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete campaign line item screen
   * Delete campaign line item screen
   * @param campaignId Campaign Id
   * @param lineItemId Campaign Line Item Id
   * @param lineItemScreenId Campaign Line Item Screen Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCampaignLineItemScreen(campaignId: string, lineItemId: string, lineItemScreenId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteCampaignLineItemScreen(campaignId: string, lineItemId: string, lineItemScreenId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteCampaignLineItemScreen(campaignId: string, lineItemId: string, lineItemScreenId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteCampaignLineItemScreen(campaignId: string, lineItemId: string, lineItemScreenId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling deleteCampaignLineItemScreen.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling deleteCampaignLineItemScreen.');
    }

    if (lineItemScreenId === null || lineItemScreenId === undefined) {
      throw new Error('Required parameter lineItemScreenId was null or undefined when calling deleteCampaignLineItemScreen.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete<any>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/screens/${encodeURIComponent(String(lineItemScreenId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete campaign and ssp relation
   * Delete campaign and ssp relation
   * @param campaignId Campaign Id
   * @param relationId Relation Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCampaignSspRelation(campaignId: string, relationId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteCampaignSspRelation(campaignId: string, relationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteCampaignSspRelation(campaignId: string, relationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteCampaignSspRelation(campaignId: string, relationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling deleteCampaignSspRelation.');
    }

    if (relationId === null || relationId === undefined) {
      throw new Error('Required parameter relationId was null or undefined when calling deleteCampaignSspRelation.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete<any>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/ssps/${encodeURIComponent(String(relationId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete campaign and ssp relations
   * Delete campaign and ssp relations
   * @param body Relation IDs
   * @param campaignId Campaign Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCampaignSspRelations(body: Array<string>, campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteCampaignSspRelations(body: Array<string>, campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteCampaignSspRelations(body: Array<string>, campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteCampaignSspRelations(body: Array<string>, campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling deleteCampaignSspRelations.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling deleteCampaignSspRelations.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('DELETE', `${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/ssps_batch`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: body
      }
    );
  }

  /**
   * Delete campaign and targeting set relation
   * Delete campaign and targeting set relation
   * @param campaignId Campaign Id
   * @param relationId Relation Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCampaignTargetingSetRelation(campaignId: string, relationId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteCampaignTargetingSetRelation(campaignId: string, relationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteCampaignTargetingSetRelation(campaignId: string, relationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteCampaignTargetingSetRelation(campaignId: string, relationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling deleteCampaignTargetingSetRelation.');
    }

    if (relationId === null || relationId === undefined) {
      throw new Error('Required parameter relationId was null or undefined when calling deleteCampaignTargetingSetRelation.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete<any>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/targeting_sets/${encodeURIComponent(String(relationId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete campaign and targeting set relations
   * Delete campaign and targeting set relations
   * @param body Relation IDs
   * @param campaignId Campaign Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCampaignTargetingSetRelations(body: Array<string>, campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteCampaignTargetingSetRelations(body: Array<string>, campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteCampaignTargetingSetRelations(body: Array<string>, campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteCampaignTargetingSetRelations(body: Array<string>, campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling deleteCampaignTargetingSetRelations.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling deleteCampaignTargetingSetRelations.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('DELETE', `${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/targeting_sets_batch`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: body
      }
    );
  }

  /**
   * Delete campaigns
   * Delete campaigns
   * @param body Campaign IDs
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCampaigns(body: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteCampaigns(body: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteCampaigns(body: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteCampaigns(body: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling deleteCampaigns.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('DELETE', `${this.basePath}/dsp/campaigns_batch`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: body
      }
    );
  }

  /**
   * Delete campaign line item link to creative
   * Delete campaign line item link to creative
   * @param campaignId Campaign Id
   * @param creativeId Creative Id
   * @param lineItemId Campaign Line Item Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteLineItemCreativeLink(campaignId: string, creativeId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteLineItemCreativeLink(campaignId: string, creativeId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteLineItemCreativeLink(campaignId: string, creativeId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteLineItemCreativeLink(campaignId: string, creativeId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling deleteLineItemCreativeLink.');
    }

    if (creativeId === null || creativeId === undefined) {
      throw new Error('Required parameter creativeId was null or undefined when calling deleteLineItemCreativeLink.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling deleteLineItemCreativeLink.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete<any>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/creatives/${encodeURIComponent(String(creativeId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete line item and creative relations
   * Delete line item and creative relations
   * @param body Creative IDs
   * @param campaignId Campaign Id
   * @param lineItemId Line Item Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteLineItemCreativeRelations(body: Array<string>, campaignId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteLineItemCreativeRelations(body: Array<string>, campaignId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteLineItemCreativeRelations(body: Array<string>, campaignId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteLineItemCreativeRelations(body: Array<string>, campaignId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling deleteLineItemCreativeRelations.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling deleteLineItemCreativeRelations.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling deleteLineItemCreativeRelations.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.delete<any>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/creatives_batch`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete line items
   * Delete line items
   * @param body Campaign IDs
   * @param campaignId Campaign Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteLineItems(body: Array<string>, campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteLineItems(body: Array<string>, campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteLineItems(body: Array<string>, campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteLineItems(body: Array<string>, campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling deleteLineItems.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling deleteLineItems.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.delete<any>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items_batch`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get line item audience targeting by campaign id and line item id
   * Get line item audience targeting by campaign id and line item id
   * @param campaignId campaign_id
   * @param lineItemId line_item_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAudienceTargeting(campaignId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<AudienceTargetingView>;
  public getAudienceTargeting(campaignId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AudienceTargetingView>>;
  public getAudienceTargeting(campaignId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AudienceTargetingView>>;
  public getAudienceTargeting(campaignId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getAudienceTargeting.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling getAudienceTargeting.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<AudienceTargetingView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/demographics`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get campaign by id
   * Get campaign by id
   * @param campaignId Campaign Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCampaign(campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<CampaignView>;
  public getCampaign(campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampaignView>>;
  public getCampaign(campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampaignView>>;
  public getCampaign(campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getCampaign.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<CampaignView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get campaign and deal relation by id
   * Get campaign and deal relation by id
   * @param campaignId Campaign Id
   * @param relationId Relation Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCampaignDealRelation(campaignId: string, relationId: string, observe?: 'body', reportProgress?: boolean): Observable<RelatedDealView>;
  public getCampaignDealRelation(campaignId: string, relationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RelatedDealView>>;
  public getCampaignDealRelation(campaignId: string, relationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RelatedDealView>>;
  public getCampaignDealRelation(campaignId: string, relationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getCampaignDealRelation.');
    }

    if (relationId === null || relationId === undefined) {
      throw new Error('Required parameter relationId was null or undefined when calling getCampaignDealRelation.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<RelatedDealView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/deals/${encodeURIComponent(String(relationId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get list of campaign and deal relations
   * Get list of campaign and deal relations, attributes: page&#x3D;2&amp;page_size&#x3D;10&amp;sort&#x3D;myfield,asc/desc;myfield2,asc/desc
   * @param campaignId Campaign Id
   * @param page the number of page
   * @param pageSize the size of page
   * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCampaignDealRelations(campaignId: string, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
  public getCampaignDealRelations(campaignId: string, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
  public getCampaignDealRelations(campaignId: string, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
  public getCampaignDealRelations(campaignId: string, page?: number, pageSize?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getCampaignDealRelations.');
    }




    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      })
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageDto>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/deals`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get campaign line item by id
   * Get campaign line item by id
   * @param campaignId Campaign Id
   * @param lineItemId Campaign Line Item Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCampaignLineItem(campaignId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<LineItemView>;
  public getCampaignLineItem(campaignId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LineItemView>>;
  public getCampaignLineItem(campaignId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LineItemView>>;
  public getCampaignLineItem(campaignId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getCampaignLineItem.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling getCampaignLineItem.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<LineItemView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get campaign line item creative link by ids
   * Get campaign line item creative link by ids
   * @param campaignId Campaign Id
   * @param creativeId Creative Id
   * @param lineItemId Campaign Line Item Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCampaignLineItemLinkByIds(campaignId: string, creativeId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<LineItemCreativeLinkView>;
  public getCampaignLineItemLinkByIds(campaignId: string, creativeId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LineItemCreativeLinkView>>;
  public getCampaignLineItemLinkByIds(campaignId: string, creativeId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LineItemCreativeLinkView>>;
  public getCampaignLineItemLinkByIds(campaignId: string, creativeId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getCampaignLineItemLinkByIds.');
    }

    if (creativeId === null || creativeId === undefined) {
      throw new Error('Required parameter creativeId was null or undefined when calling getCampaignLineItemLinkByIds.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling getCampaignLineItemLinkByIds.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<LineItemCreativeLinkView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/creatives/${encodeURIComponent(String(creativeId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get campaign line item screen by id
   * Get campaign line item screen by id
   * @param campaignId Campaign Id
   * @param lineItemId Campaign Line Item Id
   * @param lineItemScreenId Campaign Line Item Screen Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCampaignLineItemScreen(campaignId: string, lineItemId: string, lineItemScreenId: string, observe?: 'body', reportProgress?: boolean): Observable<LineItemScreenView>;
  public getCampaignLineItemScreen(campaignId: string, lineItemId: string, lineItemScreenId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LineItemScreenView>>;
  public getCampaignLineItemScreen(campaignId: string, lineItemId: string, lineItemScreenId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LineItemScreenView>>;
  public getCampaignLineItemScreen(campaignId: string, lineItemId: string, lineItemScreenId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getCampaignLineItemScreen.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling getCampaignLineItemScreen.');
    }

    if (lineItemScreenId === null || lineItemScreenId === undefined) {
      throw new Error('Required parameter lineItemScreenId was null or undefined when calling getCampaignLineItemScreen.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<LineItemScreenView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/screens/${encodeURIComponent(String(lineItemScreenId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get list of campaign line item screens
   * Get list of campaign line item screens,, attributes: page&#x3D;2&amp;page_size&#x3D;10&amp;sort&#x3D;myfield,asc/desc;myfield2,asc/desc
   * @param campaignId Campaign Id
   * @param lineItemId Campaign Line Item Id
   * @param page the number of page
   * @param pageSize the size of page
   * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCampaignLineItemScreens(campaignId: string, lineItemId: string, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
  public getCampaignLineItemScreens(campaignId: string, lineItemId: string, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
  public getCampaignLineItemScreens(campaignId: string, lineItemId: string, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
  public getCampaignLineItemScreens(campaignId: string, lineItemId: string, page?: number, pageSize?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getCampaignLineItemScreens.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling getCampaignLineItemScreens.');
    }




    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      })
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageDto>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/screens`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get list of campaign line items
   * Get list of campaign line items. Available fields for sorting and filtering: - &#39;campaign_id&#39; - the ID of campaign; - &#39;line_item_id&#39; - the ID of line item; - &#39;name&#39; - the name of line item.  Request example: /line_items?page&#x3D;2&amp;page_size&#x3D;10&amp;sort&#x3D;name,asc&amp;filters&#x3D;name,like,%o%
   * @param campaignId Campaign Id
   * @param filters Filtering criteria in the format: field_name,operation,value. Supported operations: eq (equal, &#x3D;), neq (not equal, !&#x3D;), lt (less than, &lt;), lte (less than or equal, &lt;&#x3D;), gt (greater than, &gt;), gte (greater than or equal, &gt;&#x3D;), like.
   * @param page the number of page
   * @param pageSize the size of page
   * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCampaignLineItems(campaignId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
  public getCampaignLineItems(campaignId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
  public getCampaignLineItems(campaignId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
  public getCampaignLineItems(campaignId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getCampaignLineItems.');
    }





    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (filters) {
      filters.forEach((element) => {
        queryParameters = queryParameters.append('filters', <any>element);
      })
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      })
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageDto>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get campaign and ssp relation by id
   * Get campaign and ssp relation by id
   * @param campaignId Campaign Id
   * @param relationId Relation Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCampaignSspRelation(campaignId: string, relationId: string, observe?: 'body', reportProgress?: boolean): Observable<RelatedSspView>;
  public getCampaignSspRelation(campaignId: string, relationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RelatedSspView>>;
  public getCampaignSspRelation(campaignId: string, relationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RelatedSspView>>;
  public getCampaignSspRelation(campaignId: string, relationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getCampaignSspRelation.');
    }

    if (relationId === null || relationId === undefined) {
      throw new Error('Required parameter relationId was null or undefined when calling getCampaignSspRelation.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<RelatedSspView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/ssps/${encodeURIComponent(String(relationId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get list of campaign and ssp relations
   * Get list of campaign and ssp relations, attributes: page&#x3D;2&amp;page_size&#x3D;10&amp;sort&#x3D;myfield,asc/desc;myfield2,asc/desc
   * @param campaignId Campaign Id
   * @param page the number of page
   * @param pageSize the size of page
   * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCampaignSspRelations(campaignId: string, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
  public getCampaignSspRelations(campaignId: string, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
  public getCampaignSspRelations(campaignId: string, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
  public getCampaignSspRelations(campaignId: string, page?: number, pageSize?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getCampaignSspRelations.');
    }




    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      })
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageDto>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/ssps`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get campaign and targeting set relation by id
   * Get campaign and targeting set relation by id
   * @param campaignId Campaign Id
   * @param relationId Relation Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCampaignTargetingSetRelation(campaignId: string, relationId: string, observe?: 'body', reportProgress?: boolean): Observable<RelatedTargetingSetView>;
  public getCampaignTargetingSetRelation(campaignId: string, relationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RelatedTargetingSetView>>;
  public getCampaignTargetingSetRelation(campaignId: string, relationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RelatedTargetingSetView>>;
  public getCampaignTargetingSetRelation(campaignId: string, relationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getCampaignTargetingSetRelation.');
    }

    if (relationId === null || relationId === undefined) {
      throw new Error('Required parameter relationId was null or undefined when calling getCampaignTargetingSetRelation.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<RelatedTargetingSetView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/targeting_sets/${encodeURIComponent(String(relationId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get list of campaign and targeting set relations
   * Get list of campaign and targeting set relations, attributes: page&#x3D;2&amp;page_size&#x3D;10&amp;sort&#x3D;myfield,asc/desc;myfield2,asc/desc
   * @param campaignId Campaign Id
   * @param page the number of page
   * @param pageSize the size of page
   * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCampaignTargetingSetRelations(campaignId: string, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
  public getCampaignTargetingSetRelations(campaignId: string, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
  public getCampaignTargetingSetRelations(campaignId: string, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
  public getCampaignTargetingSetRelations(campaignId: string, page?: number, pageSize?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getCampaignTargetingSetRelations.');
    }




    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      })
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageDto>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/targeting_sets`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get list of campaign available targeting sets
   * Get list of campaign available targeting sets, attributes: page&#x3D;2&amp;page_size&#x3D;10&amp;sort&#x3D;myfield,asc/desc;myfield2,asc/desc
   * @param campaignId Campaign Id
   * @param page the number of page
   * @param pageSize the size of page
   * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCampaignAvailableTargetingSets(campaignId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
  public getCampaignAvailableTargetingSets(campaignId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
  public getCampaignAvailableTargetingSets(campaignId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
  public getCampaignAvailableTargetingSets(campaignId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getCampaignTargetingSetRelations.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (filters) {
      filters.forEach((element) => {
        queryParameters = queryParameters.append('filters', <any>element);
      })
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      })
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<PageDto>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/available_targeting_sets`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get list of campaigns
   * Get list of campaigns. Available fields for sorting and filtering: - &#39;campaign_id&#39; - the ID of campaign; - &#39;name&#39; - the name of campaign; - &#39;deactivated&#39; - the status of campaign (active or inactive); - &#39;agency[name]&#39; - the name of related agency; - &#39;advertiser[name]&#39; - the name of related advertiser; - &#39;start_date&#39; - the campaign start date; - &#39;end_date&#39; - the campaign end date; - &#39;is_archived&#39; - if true the campaign is archived and could not be used for bidding; - &#39;budget&#39; - the campaign budget.  Request example: /campaigns?page&#x3D;2&amp;page_size&#x3D;10&amp;sort&#x3D;agency[name],asc;advertiser[name],desc.
   * @param filters Filtering criteria in the format: field_name,operation,value. Supported operations: eq (equal, &#x3D;), neq (not equal, !&#x3D;), lt (less than, &lt;), lte (less than or equal, &lt;&#x3D;), gt (greater than, &gt;), gte (greater than or equal, &gt;&#x3D;), like.
   * @param page the number of page
   * @param pageSize the size of page
   * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCampaigns(filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
  public getCampaigns(filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
  public getCampaigns(filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
  public getCampaigns(filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (filters) {
      filters.forEach((element) => {
        queryParameters = queryParameters.append('filters', <any>element);
      })
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      })
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageDto>(`${this.basePath}/dsp/campaigns`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get campaign line item creative links by line item id
   * Returns creatives ids connected with this line item. Attributes: page&#x3D;2&amp;page_size&#x3D;10&amp;sort&#x3D;creative_id,asc/desc;
   * @param campaignId Campaign Id
   * @param lineItemId Campaign Line Item Id
   * @param filters Filtering criteria in the format: field_name,operation,value. Supported operations: eq (equal, &#x3D;), neq (not equal, !&#x3D;), lt (less than, &lt;), lte (less than or equal, &lt;&#x3D;), gt (greater than, &gt;), gte (greater than or equal, &gt;&#x3D;), like.
   * @param page the number of page
   * @param pageSize the size of page
   * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCreativeLinksByLineItemId(campaignId: string, lineItemId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
  public getCreativeLinksByLineItemId(campaignId: string, lineItemId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
  public getCreativeLinksByLineItemId(campaignId: string, lineItemId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
  public getCreativeLinksByLineItemId(campaignId: string, lineItemId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getCreativeLinksByLineItemId.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling getCreativeLinksByLineItemId.');
    }





    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (filters) {
      filters.forEach((element) => {
        queryParameters = queryParameters.append('filters', <any>element);
      })
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      })
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageDto>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/creatives`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get creatives available for given campaign line item.
   * Get list of available creatives. Available fields for sorting and filtering: - &#39;creatvie_id&#39; - the ID of creative; - &#39;name&#39; - the name of creative; - &#39;type&#39; - the type of creative; - &#39;size&#39; - the size of creative; - &#39;length&#39; - the length of creative; - &#39;has_relation&#39; - the creative which available of adding to current line item.  Request example: /campaigns/{campaign_id}/line_items/{line_item_id}/available_creatives?filters&#x3D;has_relation,eq,false&amp;sort&#x3D;name,asc;type,desc.
   * @param campaignId Campaign Id
   * @param lineItemId Campaign Line Item Id
   * @param filters Filtering criteria in the format: field_name,operation,value. Supported operations: eq (equal, &#x3D;), neq (not equal, !&#x3D;), lt (less than, &lt;), lte (less than or equal, &lt;&#x3D;), gt (greater than, &gt;), gte (greater than or equal, &gt;&#x3D;), like.
   * @param page the number of page
   * @param pageSize the size of page
   * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCreativesAvailableForGivenCampaignLineItem(campaignId: string, lineItemId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
  public getCreativesAvailableForGivenCampaignLineItem(campaignId: string, lineItemId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
  public getCreativesAvailableForGivenCampaignLineItem(campaignId: string, lineItemId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
  public getCreativesAvailableForGivenCampaignLineItem(campaignId: string, lineItemId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getCreativesAvailableForGivenCampaignLineItem.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling getCreativesAvailableForGivenCampaignLineItem.');
    }





    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (filters) {
      filters.forEach((element) => {
        queryParameters = queryParameters.append('filters', <any>element);
      })
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      })
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PageDto>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/available_creatives`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get line item budget by campaign id and line item id
   * Get line item budget by campaign id and line item id
   * @param campaignId Campaign Id
   * @param lineItemId Campaign Line Item Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLineItemBudget(campaignId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<LineItemBudgetView>;
  public getLineItemBudget(campaignId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LineItemBudgetView>>;
  public getLineItemBudget(campaignId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LineItemBudgetView>>;
  public getLineItemBudget(campaignId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getLineItemBudget.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling getLineItemBudget.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<LineItemBudgetView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/budget`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get line item schedule by campaign id and line item id
   * Get line item schedule by campaign id and line item id
   * @param campaignId campaign_id
   * @param lineItemId line_item_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLineItemSchedule(campaignId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<LineItemScheduleView>;
  public getLineItemSchedule(campaignId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LineItemScheduleView>>;
  public getLineItemSchedule(campaignId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LineItemScheduleView>>;
  public getLineItemSchedule(campaignId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getLineItemSchedule.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling getLineItemSchedule.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<LineItemScheduleView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/schedule`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Patch campaign fields
   * Patch campaign fields
   * @param body Fields
   * @param campaignId Campaign Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchCampaignFields(body: any, campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<CampaignView>;
  public patchCampaignFields(body: any, campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampaignView>>;
  public patchCampaignFields(body: any, campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampaignView>>;
  public patchCampaignFields(body: any, campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling patchCampaignFields.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling patchCampaignFields.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.patch<CampaignView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Patch campaigns
   * Patch campaigns
   * @param form Campaigns Patches
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchCampaigns(form: CampaignsPatchesForm, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public patchCampaigns(form: CampaignsPatchesForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public patchCampaigns(form: CampaignsPatchesForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public patchCampaigns(form: CampaignsPatchesForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (form === null || form === undefined) {
      throw new Error('Required parameter form was null or undefined when calling patchCampaigns.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.patch<any>(`${this.basePath}/dsp/campaigns_batch`,
      form,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Patch line item fields
   * Patch line item fields
   * @param body Fields
   * @param campaignId Campaign Id
   * @param lineItemId Campaign Line Item Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchLineItemFields(body: any, campaignId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<LineItemView>;
  public patchLineItemFields(body: any, campaignId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LineItemView>>;
  public patchLineItemFields(body: any, campaignId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LineItemView>>;
  public patchLineItemFields(body: any, campaignId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling patchLineItemFields.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling patchLineItemFields.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling patchLineItemFields.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.patch<LineItemView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Patch line items
   * Patch line items
   * @param campaignId Campaign Id
   * @param form Line Items Patches
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchLineItems(campaignId: string, form: LineItemsPatchesForm, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public patchLineItems(campaignId: string, form: LineItemsPatchesForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public patchLineItems(campaignId: string, form: LineItemsPatchesForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public patchLineItems(campaignId: string, form: LineItemsPatchesForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling patchLineItems.');
    }

    if (form === null || form === undefined) {
      throw new Error('Required parameter form was null or undefined when calling patchLineItems.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.patch<any>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items_batch`,
      form,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update campaign
   * Update campaign
   * @param body Campaign Object
   * @param campaignId Campaign Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateCampaign(body: CampaignForm, campaignId: string, observe?: 'body', reportProgress?: boolean): Observable<CampaignView>;
  public updateCampaign(body: CampaignForm, campaignId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CampaignView>>;
  public updateCampaign(body: CampaignForm, campaignId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CampaignView>>;
  public updateCampaign(body: CampaignForm, campaignId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateCampaign.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling updateCampaign.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<CampaignView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update campaign and deal relation
   * Update campaign and deal relation
   * @param body Relation Object
   * @param campaignId Campaign Id
   * @param relationId Relation Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateCampaignDealRelation(body: CampaignDealRelationForm, campaignId: string, relationId: string, observe?: 'body', reportProgress?: boolean): Observable<RelatedDealView>;
  public updateCampaignDealRelation(body: CampaignDealRelationForm, campaignId: string, relationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RelatedDealView>>;
  public updateCampaignDealRelation(body: CampaignDealRelationForm, campaignId: string, relationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RelatedDealView>>;
  public updateCampaignDealRelation(body: CampaignDealRelationForm, campaignId: string, relationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateCampaignDealRelation.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling updateCampaignDealRelation.');
    }

    if (relationId === null || relationId === undefined) {
      throw new Error('Required parameter relationId was null or undefined when calling updateCampaignDealRelation.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<RelatedDealView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/deals/${encodeURIComponent(String(relationId))}`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update campaign line item
   * Update campaign line item
   * @param body Campaign Line Item Object
   * @param campaignId Campaign Id
   * @param lineItemId Campaign Line Item Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateCampaignLineItem(body: LineItemForm, campaignId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<LineItemView>;
  public updateCampaignLineItem(body: LineItemForm, campaignId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LineItemView>>;
  public updateCampaignLineItem(body: LineItemForm, campaignId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LineItemView>>;
  public updateCampaignLineItem(body: LineItemForm, campaignId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateCampaignLineItem.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling updateCampaignLineItem.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling updateCampaignLineItem.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<LineItemView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update campaign line item audience targeting
   * Update campaign line item audience targeting
   * @param body Campaign Line Item Audience Targeting Object
   * @param campaignId Campaign Id
   * @param lineItemId Campaign Line Item Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateCampaignLineItemAudienceTargeting(body: AudienceTargetingForm, campaignId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<AudienceTargetingView>;
  public updateCampaignLineItemAudienceTargeting(body: AudienceTargetingForm, campaignId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AudienceTargetingView>>;
  public updateCampaignLineItemAudienceTargeting(body: AudienceTargetingForm, campaignId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AudienceTargetingView>>;
  public updateCampaignLineItemAudienceTargeting(body: AudienceTargetingForm, campaignId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateCampaignLineItemAudienceTargeting.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling updateCampaignLineItemAudienceTargeting.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling updateCampaignLineItemAudienceTargeting.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<AudienceTargetingView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/demographics`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update campaign line item budget
   * Update campaign line item budget
   * @param body Campaign Line Item Budget Object
   * @param campaignId Campaign Id
   * @param lineItemId Campaign Line Item Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateCampaignLineItemBudget(body: LineItemBudgetForm, campaignId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<LineItemBudgetView>;
  public updateCampaignLineItemBudget(body: LineItemBudgetForm, campaignId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LineItemBudgetView>>;
  public updateCampaignLineItemBudget(body: LineItemBudgetForm, campaignId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LineItemBudgetView>>;
  public updateCampaignLineItemBudget(body: LineItemBudgetForm, campaignId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateCampaignLineItemBudget.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling updateCampaignLineItemBudget.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling updateCampaignLineItemBudget.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<LineItemBudgetView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/budget`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update campaign line item schedule
   * Update campaign line item schedule
   * @param body Campaign Line Item Schedule Object
   * @param campaignId Campaign Id
   * @param lineItemId Campaign Line Item Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateCampaignLineItemSchedule(body: LineItemScheduleForm, campaignId: string, lineItemId: string, observe?: 'body', reportProgress?: boolean): Observable<LineItemScheduleView>;
  public updateCampaignLineItemSchedule(body: LineItemScheduleForm, campaignId: string, lineItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LineItemScheduleView>>;
  public updateCampaignLineItemSchedule(body: LineItemScheduleForm, campaignId: string, lineItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LineItemScheduleView>>;
  public updateCampaignLineItemSchedule(body: LineItemScheduleForm, campaignId: string, lineItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateCampaignLineItemSchedule.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling updateCampaignLineItemSchedule.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling updateCampaignLineItemSchedule.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<LineItemScheduleView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/schedule`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update campaign line item screen
   * Update campaign line item screen
   * @param body Campaign Line Item Object
   * @param campaignId Campaign Id
   * @param lineItemId Campaign Line Item Id
   * @param lineItemScreenId Campaign Line Item Screen Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateCampaignLineItemScreen(body: LineItemScreenForm, campaignId: string, lineItemId: string, lineItemScreenId: string, observe?: 'body', reportProgress?: boolean): Observable<LineItemScreenView>;
  public updateCampaignLineItemScreen(body: LineItemScreenForm, campaignId: string, lineItemId: string, lineItemScreenId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LineItemScreenView>>;
  public updateCampaignLineItemScreen(body: LineItemScreenForm, campaignId: string, lineItemId: string, lineItemScreenId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LineItemScreenView>>;
  public updateCampaignLineItemScreen(body: LineItemScreenForm, campaignId: string, lineItemId: string, lineItemScreenId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateCampaignLineItemScreen.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling updateCampaignLineItemScreen.');
    }

    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling updateCampaignLineItemScreen.');
    }

    if (lineItemScreenId === null || lineItemScreenId === undefined) {
      throw new Error('Required parameter lineItemScreenId was null or undefined when calling updateCampaignLineItemScreen.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<LineItemScreenView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/line_items/${encodeURIComponent(String(lineItemId))}/screens/${encodeURIComponent(String(lineItemScreenId))}`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update campaign and ssp relation
   * Update campaign and ssp relation
   * @param body Relation Object
   * @param campaignId Campaign Id
   * @param relationId Relation Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateCampaignSspRelation(body: CampaignSspRelationForm, campaignId: string, relationId: string, observe?: 'body', reportProgress?: boolean): Observable<RelatedSspView>;
  public updateCampaignSspRelation(body: CampaignSspRelationForm, campaignId: string, relationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RelatedSspView>>;
  public updateCampaignSspRelation(body: CampaignSspRelationForm, campaignId: string, relationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RelatedSspView>>;
  public updateCampaignSspRelation(body: CampaignSspRelationForm, campaignId: string, relationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateCampaignSspRelation.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling updateCampaignSspRelation.');
    }

    if (relationId === null || relationId === undefined) {
      throw new Error('Required parameter relationId was null or undefined when calling updateCampaignSspRelation.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<RelatedSspView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/ssps/${encodeURIComponent(String(relationId))}`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update campaign and targeting set relation
   * Update campaign and targeting set relation
   * @param body Relation Object
   * @param campaignId Campaign Id
   * @param relationId Relation Id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateCampaignTargetingSetRelation(body: CampaignTargetingSetRelationForm, campaignId: string, relationId: string, observe?: 'body', reportProgress?: boolean): Observable<RelatedTargetingSetView>;
  public updateCampaignTargetingSetRelation(body: CampaignTargetingSetRelationForm, campaignId: string, relationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RelatedTargetingSetView>>;
  public updateCampaignTargetingSetRelation(body: CampaignTargetingSetRelationForm, campaignId: string, relationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RelatedTargetingSetView>>;
  public updateCampaignTargetingSetRelation(body: CampaignTargetingSetRelationForm, campaignId: string, relationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateCampaignTargetingSetRelation.');
    }

    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling updateCampaignTargetingSetRelation.');
    }

    if (relationId === null || relationId === undefined) {
      throw new Error('Required parameter relationId was null or undefined when calling updateCampaignTargetingSetRelation.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<RelatedTargetingSetView>(`${this.basePath}/dsp/campaigns/${encodeURIComponent(String(campaignId))}/targeting_sets/${encodeURIComponent(String(relationId))}`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
