import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from '@ngx-translate/core';
import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { AlertDialogData, CustomContentDialogData } from '@dooh/models';

@Component({
  selector: 'dooh-dialog-common',
  templateUrl: './custom-content-dialog.component.html',
  styleUrls: ['./custom-content-dialog.component.scss'],
})
export class CustomContentDialogComponent implements OnInit, OnDestroy {
  @ViewChild('target', { read: ViewContainerRef, static: true }) viewContentRef: ViewContainerRef;

  contentRef: ComponentRef<any>;
  alertData: AlertDialogData = {
    type: 'warning',
    title: 'dialog.alert.title.sure',
    message: 'dialog.weatherDialog.canDeactivate',
    submitButtonText: 'dialog.alert.submitButtonText.yes',
    cancelButtonText: 'dialog.alert.cancelButtonText.no',
  }
  showAlert = false;

  constructor(
    private translateService: TranslateService,
    private resolver: ComponentFactoryResolver,
    private dialogRef: MatDialogRef<CustomContentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomContentDialogData
  ) {
    this.translateService.setDefaultLang('en');
  }

  initContent(component, context) {
    const factory = this.resolver.resolveComponentFactory(component);
    this.contentRef = this.viewContentRef.createComponent(factory);
    this.contentRef.instance.dialogRef = this.dialogRef;
    if (context) {
      this.contentRef.instance.setContext(context)
    }
    if(this.data.useCanDeactivate){
      this.dialogRef.disableClose = true;
      this.dialogRef.backdropClick().subscribe(() => {
        this.onCancelClick();
      })
    }
  }

  ngOnInit() {
    const { component, context } = this.data;
    if (!component) return;
    this.initContent(component, context);
  }

  ngOnDestroy() {
    if (this.contentRef) {
      this.contentRef.destroy();
    }
  }

  onSubmitClick() {
    if (this.data.onSubmitClick) {
      this.data.onSubmitClick(this.contentRef);
    }
  }

  onCancelClick() {
    if(this.data.useCanDeactivate && !this.contentRef.instance.canDeactivate()){
      this.showAlert  = true;
    }
    else{
      if (this.data.onCancelClick) {
        this.data.onCancelClick(this.contentRef);
      }
      this.dialogRef.close();
    }
  }
  
  onDeleteClick() {
    if (this.data.onDeleteClick) {
      this.data.onDeleteClick(this.contentRef);
    }
  }
}
