import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertDialogData } from '@dooh/models';
import { TranslateService } from '@ngx-translate/core';

const MISSED_FROM_STR = '--:--';
const MISSED_TO_STR = '--:--';

const DEFAULT_FROM_STR = '00:00';
const DEFAULT_TO_STR = '23:59';

enum Errors {
  'REQUIRED' = 'Both fields are required.',
  'BIGGER' = 'Start time can not be greater than end time',
  'OVERLAP' = 'There is a time overlap with another selected time of this day',
}

@Component({
  selector: 'dooh-add-time',
  templateUrl: './add-time.component.html',
  styleUrls: ['./add-time.component.scss'],
})
export class AddTimeComponent implements OnInit {
  timeRange: FormGroup;
  times: string[];
  timesToField: string[];

  startTime: any;
  endTime: any;
  error: string;
  disableSubmit = true;
  daySegments: any[] = [];
  isEditing: boolean;

  @HostListener('document:keyup', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    const keyPressed = event?.key;
    if (keyPressed === 'Enter') {
      this.onSubmitClick();
    } else if (keyPressed === 'Delete') {
      this.onDeleteClick()
    }
  }
  constructor(
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<AddTimeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.translateService.setDefaultLang('en');
    if (this.data?.presetData) {
      const startTime = this.data.presetData['startTime'];
      const endTime = this.data.presetData['endTime'];
      this.isEditing = true;
      this.startTime = `${startTime.split(':')[0]}:${startTime.split(':')[1]}`;
      this.endTime = `${endTime.split(':')[0]}:${endTime.split(':')[1]}`;
    }
    if (this.data?.timeSlot) {
      this.startTime = this.setHourFormat(this.data?.timeSlot, ':00');
      this.endTime = this.setHourFormat(+this.data?.timeSlot, ':59');
      this.checkInput();
    }
  }

  ngOnInit(): void {
    this.times = [];
    this.timesToField = [];
    for (let i = 0; i < 24; i++) {
      this.times.push(this.setHourFormat(i, ':00'));
      this.timesToField.push(this.setHourFormat(i, ':59'));
    }
    if (this.startTime === null || this.startTime === undefined) {
      this.startTime = MISSED_FROM_STR;
      this.endTime = MISSED_TO_STR;
    }
    this.timeRange = new FormGroup({
      startTime: new FormControl(this.startTime),
      endTime: new FormControl(this.endTime),
    });
    if (this.data?.data) {
      this.data.data.forEach((item) => {
        const startTime = `${item.startTime.split(':')[0]}:${
          item.startTime.split(':')[1]
        }`;
        const endTime = `${item.endTime.split(':')[0]}:${
          item.endTime.split(':')[1]
        }`;
        if (
          this.isEditing &&
          this.startTime === startTime &&
          this.endTime === endTime
        ) {
          return;
        }
        this.daySegments.push({ startTime, endTime });
      });
    }
  }

  updateStartTime(value?: string) {
    if (value) {
      this.startTime = value;
    } else {
      this.startTime = this.timeRange.controls['startTime'].value;
      this.getValue();
    }
    this.setStartTime(this.startTime);
  }

  updateEndTime(value?: string) {
    if (value) {
      this.endTime = value;
    } else {
      this.endTime = this.timeRange.controls['endTime'].value;
      this.getValue();
    }
    this.setToValue(this.endTime);
  }

  setStartTime(value: string) {
    this.startTime = value;
    this.timeRange.value['startTime'] = value;
  }

  setToValue(value: string) {
    this.endTime = value;
    this.timeRange.value['endTime'] = value;
  }

  private checkInput(): boolean {
    this.error = null;
    this.disableSubmit = false;
    if (this.startTime === MISSED_FROM_STR || this.endTime === MISSED_TO_STR) {
      this.error = Errors.REQUIRED;
      this.disableSubmit = true;
      return false;
    } else if (this.startTime > this.endTime) {
      this.error = Errors.BIGGER;
      this.disableSubmit = true;
      return false;
    } else if (
      this.checkOverlap(this.daySegments, this.startTime, this.endTime) && !this.checkIfWholeDay()
    ) {
      this.error = Errors.OVERLAP;
      this.disableSubmit = true;
      return false;
    }
    return true;
  }

  getValue(): any {
    if (!this.timeRange?.controls['startTime'].dirty) {
      this.timeRange?.controls['startTime'].setValue(this.startTime);
    }
    if (!this.timeRange?.controls['endTime'].dirty) {
      this.timeRange?.controls['endTime'].setValue(this.endTime);
    }
    if (this.checkInput()) {
      return this.timeRange;
    }
    return null;
  }

  onSubmitClick(): void {
    if (!this.disableSubmit) {
      this.dialogRef.close({ range: this.timeRange });
    }
  }
  onDeleteClick(): void {
    if (this.isEditing) {
      this.dialogRef.close({delete: true});
    }
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  private setHourFormat(value: number, formatField: string): string {
    if (value <= 9) return '0' + value + formatField;
    else if (value > 9) return value + formatField;
  }

  private checkOverlap(
    dayData: any[],
    newStartTime: string,
    newEndTime: string
  ): boolean {
    if (dayData?.length === 0) return false;
    const timeSegments = [];
    dayData.forEach((item) => {
      const startTime = item.startTime;
      const endTime = item.endTime;
      const timeArr = [startTime, endTime];
      timeSegments.push(timeArr);
    });
    timeSegments.push([newStartTime, newEndTime]);
    if (timeSegments.length === 1) return false;

    timeSegments.sort((timeSegment1, timeSegment2) =>
      timeSegment1[0].localeCompare(timeSegment2[0])
    );

    for (let i = 0; i < timeSegments.length - 1; i++) {
      const currentEndTime = timeSegments[i][1];
      const nextStartTime = timeSegments[i + 1][0];

      if (currentEndTime > nextStartTime) {
        return true;
      }
    }

    return false;
  }

  private checkIfWholeDay(): boolean {
    if (!this.daySegments || this.daySegments?.length !== 1) return false;
    if (
      this.daySegments[0]?.startTime === DEFAULT_FROM_STR &&
      this.daySegments[0]?.endTime === DEFAULT_TO_STR
    )
      return true;

      return false;
  }
}
