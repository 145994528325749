import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DemographicsComponent } from './components/demographics/demographics.component';
import { BehaviouralTargetingComponent } from './components/behavioural-targeting/behavioural-targeting.component';
import { AudienceComponent } from './components/audience/audience.component';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ComponentsLibraryModule } from '@dooh/components-library';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { CommonPipesModule } from '@dooh/common-pipes';

@NgModule({
  declarations: [
    DemographicsComponent,
    BehaviouralTargetingComponent,
    AudienceComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    MatIconModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    CommonPipesModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatExpansionModule,
    ReactiveFormsModule,
    ComponentsLibraryModule,
  ],
  exports: [
    DemographicsComponent,
    BehaviouralTargetingComponent,
    AudienceComponent,
  ],
})
export class AudienceLibraryModule {}
