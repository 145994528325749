import { Injectable } from '@angular/core';
import { ApiService, CustomHttpUrlEncodingCodec } from '@dooh/api';
import { Observable } from 'rxjs';
import { CreativeForm, PageDto } from '@dooh/models';
import { HttpParams } from '@angular/common/http';
import { QueryParams } from '@dooh/api';

const apiUrl = 'dsp';

@Injectable({
  providedIn: 'root'
})
export class CreativeService {

  constructor(
    public apiService: ApiService
  ) { }

  getCommonApiUrl() {
    return apiUrl;
  }

  getSpecificApiUrl() {
    return this.getCommonApiUrl();
  }

  getList(sort?: string[], filters?: Array<string>, page: number = 1, pageSize: number = 50): Observable<PageDto> {
    const queryParameters: HttpParams = QueryParams.getHttpParams(
      new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() }),
      page,
      pageSize,
      sort,
      filters
    );

    return this.apiService.get(`${this.getSpecificApiUrl()}/creatives`, queryParameters);
  }

  getAll(sort?: string[], filters?: Array<string>, page?: number, pageSize?: number): Observable<PageDto> {
    return this.getList(sort, filters, page, pageSize);
  }

  deleteBatch(ids: string[]) {
    if (ids === null || ids === undefined) {
      throw new Error('Required parameter ids was null or undefined when calling deleteBatch.');
    }
    return this.apiService.delete(`${this.getSpecificApiUrl()}/creatives_batch`, ids);
  }

  archiveBatch(ids: string[], status: boolean) {
    const creativesBatch = ids.map(id => {
      return { id: id, isArchived: status };
    });
    const creatives = {
      'creatives': creativesBatch
    };
    if (ids === null || ids === undefined) {
      throw new Error('Required parameter ids was null or undefined when calling archiveBatch.');
    }

    return this.apiService.patch(`${this.getSpecificApiUrl()}/creatives_batch`, creatives);
  }

  public uploadCreativeFile(url: string, body: any, contentType: string): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createCampaignLineItemAudienceTargeting.');
    }
    return this.apiService.putThridPartyServer(url, body, contentType);
  }

  public createCreativeUpload(): Observable<any> {
    const queryUrl = `${this.getCommonApiUrl()}/creative_uploads`;
    return this.apiService.post(queryUrl);
  }

  public saveCreative(body: CreativeForm): Observable<any> {
    const queryUrl = `${this.getSpecificApiUrl()}/creatives`;
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createCampaignLineItemAudienceTargeting.');
    }
    return this.apiService.post(queryUrl, body);
  }

  public getCreative(campaignId: string,creativeId: string,lineItemId?:string): Observable<any> {
    if (creativeId === null || creativeId === undefined) {
      throw new Error('Required parameter creativeId was null or undefined when calling getCreative.');
    }    
    if(lineItemId){
      return this.apiService.get(`${this.getSpecificApiUrl()}/campaigns/${encodeURIComponent(campaignId)}/line_items/${encodeURIComponent(lineItemId)}/creatives/${encodeURIComponent(creativeId)}`);
    }else{
      return this.apiService.get(`${this.getSpecificApiUrl()}/campaigns/${encodeURIComponent(campaignId)}/creatives/${encodeURIComponent(creativeId)}`);
    }
  }

  public getCreativeWithoutCampaign(creativeId: string): Observable<any> {
    if (creativeId === null || creativeId === undefined) {
      throw new Error('Required parameter creativeId was null or undefined when calling getCreative.');
    }
    return this.apiService.get(`${this.getSpecificApiUrl()}/creatives/${encodeURIComponent(creativeId)}`);
  }

  public updateCreative(creativeId: string, body: CreativeForm): Observable<any> {
    if (creativeId === null || creativeId === undefined) {
      throw new Error('Required parameter creativeId was null or undefined when calling updateCreative.');
    }

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateCreative.');
    }

    return this.apiService.put(`${this.getSpecificApiUrl()}/creatives/${encodeURIComponent(creativeId)}`, body);
  }
}
