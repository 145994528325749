import { MapsAPILoader } from '@agm/core';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MapService, SelectedInstanceService } from '@dooh/common-services';
import { LocationMap } from '@dooh/models';
import { environment } from 'apps/sso-webapp/src/environments/environment';

import * as mapboxgl from 'mapbox-gl';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

declare var google: any;

const SEARCH_DELAY = 600;
const MOVES_TO_ACTIVE_GEOSEARCH = 5;
const PAGE_OPTIONS = [1000, 2000, 3000];
const MAP_BOUND_PADDING = 100;
const INITIAL_GEOCODE_ZOOM = 7;

const MetersToPixelsAtMaxZoom = (meters: number, latitude: number) =>
  meters / 0.075 / Math.cos((latitude * Math.PI) / 180);

@Component({
  selector: 'dooh-map-box',
  templateUrl: './map-box.component.html',
  styleUrls: ['./map-box.component.scss'],
})
export class MapBoxComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  location: LocationMap;

  @Input()
  markers?: any;

  @Input()
  isPaginated?: boolean;

  @Input()
  isLoading?: boolean;

  @Input()
  pagination?: any;

  @Input()
  canGeoSearch: boolean;

  @Output()
  markerClick?: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  mapClick: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  pageToggle: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  searchOnTheMapArea?: EventEmitter<any> = new EventEmitter<any>();

  markerIconMap = {};
  markerTypeMap = {};
  searchPoiSubscription$: Subscription;

  mapObject$ = new BehaviorSubject<any>(null);
  unsubscriber$: Subject<void> = new Subject<void>();
  boundsChangeSubject = new Subject();

  map: mapboxgl.Map;
  pois: any = [];
  searchPOIData = '';
  geoSearchButton = false;
  activeGeoSearch: boolean;
  mapMoves: number;
  pageOptions = PAGE_OPTIONS;
  latMin: any;
  latMax: any;
  longMin: any;
  longMax: any;
  newBounds: mapboxgl.LngLatBounds;
  zoomRadius: number;
  poiMarkers: mapboxgl.Marker[] = [];

  constructor(
    private mapService: MapService,
    public mapsApiLoader: MapsAPILoader
  ) {
    this.mapsApiLoader = mapsApiLoader;
    this.mapsApiLoader.load().then(() => {
      this.setInstanceLocation();
    });
    this.mapService.markerIconMap$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((markerIconMap) => {
        this.markerIconMap = markerIconMap;
      });
  }

  ngOnInit(): void {
    if (!this.location.lng || !this.location.lat) {
      this.location = {
        lat: 0,
        lng: 0,
        zoom: 3,
      };
    }
    
    const instance = SelectedInstanceService.getInstance();
    const instanceLocation = instance?.location;

    if (instance?.location) {
      this.location = {
        lat: parseFloat(instanceLocation.lat),
        lng: parseFloat(instanceLocation.lng),
        zoom: 3,
      };
    } else {
      this.mapsApiLoader.load().then(() => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: instance['country']?.name }, (results) => {
          if (this.markers?.length <= 0 && results?.length > 0) {
            this.location.lat = parseFloat(
              results[0]?.geometry?.location?.lat()
            );
            this.location.lng = parseFloat(
              results[0]?.geometry?.location?.lng()
            );
            this.location.zoom = INITIAL_GEOCODE_ZOOM;
          }
        });
      })
      
    }
    this.initMapInstance();
    this.mapObject$.pipe(takeUntil(this.unsubscriber$)).subscribe((res) => {
      if (res) {
        this.fitBoundsBasedOnMarker();
      }
    });

    this.mapService
      .getPoisMapData()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        if (String(typeof data) !== 'function') {
          this.pois = data;
          if (this.pois && this.pois.length > 0) {
            this.showPois();
          } else {
            this.zoomRadius = 0;
            if (this.map?.getLayer('poi-data')) {
              this.map?.removeLayer('poi-data');
              this.map?.removeSource('pois');
            }
            if (this.poiMarkers?.length > 0) {
              this.poiMarkers.forEach((poiMarker) => {
                poiMarker.remove();
              });
              this.poiMarkers = [];
            }
          }
        }
      });

    this.mapService.activeGeoSearchButton$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((isActiveGeoSearch) => {
        this.activeGeoSearch = isActiveGeoSearch;
      });

    this.boundsChangeSubject
      .pipe(debounceTime(SEARCH_DELAY))
      .subscribe((event: mapboxgl.LngLatBounds) => {
        this.searchInBounds(event);
        if (
          this.mapMoves >= MOVES_TO_ACTIVE_GEOSEARCH &&
          this.activeGeoSearch
        ) {
          this.geoSearchButton = true;
        } else {
          this.mapMoves++;
        }
      });
  }

  ngOnDestroy() {
    this.map?.remove();
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.location &&
      this.markers.length === 0 &&
      !changes?.isLoading?.currentValue
    ) {
      this.panMapToLocation();
    }
    if (changes['markers'] && changes.markers) {
      this.fitBoundsBasedOnMarker();
      const source: any = this.map?.getSource('markers');
      source?.setData(this.buildGeoJson(this.markers.length > 0 ? this.markers : []));
    }
  }

  onMarkerClick(marker: any) {
    this.markerClick.emit(marker);
    this.resizeMap();
  }

  onMapClick() {
    this.mapClick.emit();
    this.resizeMap();
  }

  setInstanceLocation(): void {
    const geocoder = new google.maps.Geocoder();
    let instance: any = localStorage.getItem('instance');
    instance = JSON.parse(instance);
    if (!this.location) {
      this.location = {
        lat: 0,
        lng: 0,
        zoom: INITIAL_GEOCODE_ZOOM,
      };
    }

    if (instance?.location) {
      this.location.lat = instance?.location?.lat;
      this.location.lng = instance?.location?.lng;
    } else if (instance.country.name) {
      geocoder.geocode({ address: instance['country'].name }, (results) => {
        if (this.markers?.length <= 0 && results?.length > 0) {
          this.location.lat = results[0]?.geometry?.location?.lat();
          this.location.lng = results[0]?.geometry?.location?.lng();
          this.location.zoom = INITIAL_GEOCODE_ZOOM;
        }
      });
    }

    this.panMapToLocation();
  }

  panMapToLocation(): void {
    if (!this.location || !this.location?.lng || !this.location?.lat) return;
    this.map?.setCenter([this.location?.lng, this.location?.lat]);
    this.map?.setZoom(this.location?.zoom ?? INITIAL_GEOCODE_ZOOM);
  }

  fitBoundsBasedOnMarker(): void {
    setTimeout(() => {
      const markersHasLocation = this.markers.some((marker) => {
        return marker.location.latitude || marker.location.longitude;
      });
      if (this.markers?.length > 0 && markersHasLocation) {
        const bounds = new mapboxgl.LngLatBounds();
        for (const marker of this.markers) {
          bounds.extend(
            new mapboxgl.LngLat(
              marker?.location?.longitude,
              marker?.location?.latitude
            )
          );
        }

        this.map.fitBounds(bounds, {
          padding: MAP_BOUND_PADDING,
          animate: false,
        });
      } else {
        this.markers = [];
      }
    });
  }

  searchInBounds(bounds?: mapboxgl.LngLatBounds): void {
    if (this.markers?.length > 0) {
      if (!bounds) bounds = new mapboxgl.LngLatBounds();
      for (const marker of this.markers) {
        bounds.extend(
          new mapboxgl.LngLat(
            marker?.location?.longitude,
            marker?.location?.latitude
          )
        );
      }
      this.mapService.setMapBoundForPoi(bounds);
    }
  }

  onPageHandler($event: any): void {
    const data = {
      currentPage: $event.pageIndex + 1,
      pageSize: $event.pageSize,
    };
    this.pageToggle.emit(data);
  }

  resizeMap(): void {
    setTimeout(() => {
      this.map.resize();
    }, 1);
  }

  geoSearch() {
    this.searchOnTheMapArea.emit({
      minLatitude: this.latMin,
      minLongitude: this.longMin,
      maxLatitude: this.latMax,
      maxLongitude: this.longMax,
    });
    this.mapMoves = 2;
    this.geoSearchButton = false;
  }

  boundsChange(event: mapboxgl.LngLatBounds) {
    if (event) {
      this.resizeMap();
      this.latMin = event.getSouthWest().lat;
      this.latMax = event.getNorthEast().lat;
      this.longMin = event.getSouthWest().lng;
      this.longMax = event.getNorthEast().lng;
      this.newBounds = event;
      this.boundsChangeSubject.next(event);
      // this.clusterChange();
    }
  }

  initMapInstance(): void {
    let mapCenter;
    if (this.markers.length > 0) {
      mapCenter = [
        this.markers[0]?.location?.longitude,
        this.markers[0]?.location?.latitude,
      ];
    } else {
      mapCenter = [this.location.lng, this.location.lat];
    }
    this.map = new mapboxgl.Map({
      accessToken: environment?.mapbox?.accessToken,
      container: 'map',
      style: environment?.mapbox?.style,
      zoom: 10,
      center: mapCenter,
      minZoom: 0,
      renderWorldCopies: false,
    });
    // Add map controls
    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.on('style.load', () => {
      this.map.loadImage('/assets/icons/ico-marker-new.png', (error, image) => {
        if (error) throw error;
        this.map.addImage('marker-pin', image, { sdf: false });

        this.map.addSource('markers', {
          type: 'geojson',
          data: this.buildGeoJson(this.markers),
          cluster: true,
          clusterMaxZoom: 10,
          clusterRadius: 50,
        });

        // Markers layer
        this.map.addLayer({
          id: 'markers_layer',
          source: 'markers',
          type: 'symbol',
          layout: {
            'icon-image': 'marker-pin',
            'icon-offset': [0, -15],
            'icon-size': 0.6,
          },
          filter: ['==', '$type', 'Point'],
        });

        // Marker cluster layer
        this.map.addLayer({
          id: 'clusters_layer',
          type: 'circle',
          source: 'markers',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': [
              'step',
              ['get', 'point_count'],
              '#51bbd6',
              10,
              '#f1f075',
              50,
              '#f28cb1',
            ],
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              20,
              10,
              30,
              50,
              40,
            ],
          },
        });

        // Cluster count
        this.map.addLayer({
          id: 'cluster_count',
          type: 'symbol',
          source: 'markers',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12,
          },
        });

        // inspect a cluster on click
        this.map.on('click', 'clusters_layer', (e) => {
          const features = this.map.queryRenderedFeatures(e?.point, {
            layers: ['clusters_layer'],
          });
          const clusterId = features[0]?.properties?.cluster_id;
          const source: mapboxgl.GeoJSONSource = this.map.getSource(
            'markers'
          ) as mapboxgl.GeoJSONSource;
          source.getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;
            const geometry = features[0]?.geometry as any;
            this.map.easeTo({
              center: geometry?.coordinates,
              zoom: zoom + 2,
            });
          });
        });

        this.map.on('click', 'markers_layer', (e) => {
          const markerId = e?.features[0]?.properties?.id;
          const marker = this.markers.find((marker) => marker?.id === markerId);
          const markerType = this.markerTypeMap[marker.id];
          const screen = {
            marker,
            type: markerType,
          };
          e.clickOnLayer = true;
          this.onMarkerClick(screen);
        });
        this.map.on('click', (e) => {
          if (e.clickOnLayer) {
            return;
          }
          this.onMapClick();
        });

        this.map.on('mouseenter', 'clusters_layer', () => {
          this.map.getCanvas().style.cursor = 'pointer';
        });
        this.map.on('mouseleave', 'clusters_layer', () => {
          this.map.getCanvas().style.cursor = '';
        });
        this.map.on('mouseenter', 'markers_layer', () => {
          this.map.getCanvas().style.cursor = 'pointer';
        });
        this.map.on('mouseleave', 'markers_layer', () => {
          this.map.getCanvas().style.cursor = '';
        });
      });
    });
  }

  showPois(): void {
    this.zoomRadius = MetersToPixelsAtMaxZoom(
      this.pois[0]?.radius,
      this.pois[0].location.lat
    );

    if (this.map.getLayer('poi-data')) {
      this.map.removeLayer('poi-data');
      this.map.removeSource('pois');
      if (this.poiMarkers?.length > 0) {
        this.poiMarkers.forEach((poiMarker) => {
          poiMarker.remove();
        });
        this.poiMarkers = [];
      }
    }
    this.map.addSource('pois', {
      type: 'geojson',
      data: this.buildGeoJson(this.pois),
    });
    this.map.addLayer({
      id: 'poi-data',
      type: 'circle',
      source: 'pois',
      filter: ['==', '$type', 'Point'],
      paint: {
        'circle-color': '#2969B0',
        'circle-radius': {
          stops: [
            [0, 0],
            [20, this.zoomRadius],
          ],
          base: 2,
        },
        'circle-stroke-width': 1.5,
        'circle-stroke-color': '#2969B0',
        'circle-opacity': this.pois?.length < 10 ? 0.4 : 0,
      },
    });

    for (const poi of this.pois) {
      let el;
      if (poi?.iconSvg) {
        el = document.createElement('div');
        el.className = 'marker';
        el.style.backgroundImage = `url(${poi?.iconSvg})`;
        el.style.width = `30px`;
        el.style.height = `30px`;
        el.style.backgroundSize = '100%';
      }
      const popupHtml = `<h3>${
        poi?.name ? poi?.name : poi?.description
      }</h3> <p>${
        poi?.name ? poi?.formatted_address : poi?.geoCode?.formatted_address
      }</p>`;
      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(popupHtml);
      const marker = new mapboxgl.Marker(poi?.iconSvg ? el : null)
        .setLngLat([poi.location.lng, poi.location.lat])
        .setPopup(popup)
        .addTo(this.map);

      this.poiMarkers.push(marker);
    }
  }

  buildGeoJson(data: any[]): any {
    const geoJsonFile = {
      type: 'FeatureCollection',
      features: [],
    };
    if (data.length === 0) {
      return geoJsonFile;
    }

    data.forEach((marker) => {
      if (marker?.location?.lat) {
        marker.location.longitude = marker.location.lng;
        marker.location.latitude = marker.location.lat;
      }
      let obj = {
        type: 'Feature',
        properties: marker,
        geometry: {
          type: 'Point',
          coordinates: [marker.location.longitude, marker.location.latitude],
        },
      };

      geoJsonFile.features.push(obj);
    });
    return geoJsonFile;
  }

  clearMapMarkers(): void {
    const source: any = this.map?.getSource('markers');
    source?.setData(this.buildGeoJson([]));
  }
}
