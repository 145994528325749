import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: 'dooh-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent implements OnInit {
  @Input()
  placeHolderMessage = 'Search';
  @Input()
  debounceTime? = 400;

  @Output()
  inputValue: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value: string;

  valueUpdate = new Subject<string>();

  constructor() { }

  ngOnInit(): void {
    this.valueUpdate.pipe(
      debounceTime(this.debounceTime),
      distinctUntilChanged())
      .subscribe(value => {
        this.inputValue.emit(value || '');
      });
  }
}
