import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '@dooh/common-services';
import { AppRoutingModule } from './app-routing.module';
import { InternalizationModule } from "@dooh/internalization";
import { ComponentsLibraryModule, DialogService } from "@dooh/components-library";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SelectionPanelComponent } from './selection-panel/selection-panel.component';
import { GridPanelComponent } from './selection-panel/components/grid-panel/grid-panel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthModule, AuthService, NewPasswordGuard, SmbAuth, SmbUnauth } from '@dooh/auth';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '../environments/environment';
import { BASE_PATH } from '@dooh/models';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { WhoamiService } from '@dooh/common-services';
import { ApiService, JwtInterceptor } from '@dooh/api';
import { LoginUserTypeService } from './services/login-user-type.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SetNewPasswordComponent } from './set-new-password/set-new-password.component';
import { SmbSignupComponent } from './smb-signup/smb-signup.component';
import { SmbCreateUserService } from "./services/smb-create-user.service";
import { InstanceCreateComponent } from "./instance-create/instance-create.component";
import { UpdateUserDataDialogComponent } from './instance-create/update-user-data-dialog/update-user-data-dialog.component';
import { TutorialsPanelComponent } from './tutorials-panel/tutorials-panel.component';
import {VgCoreModule} from "@videogular/ngx-videogular/core";
import {VgControlsModule} from "@videogular/ngx-videogular/controls";
import {VgOverlayPlayModule} from "@videogular/ngx-videogular/overlay-play";
import {VgBufferingModule} from "@videogular/ngx-videogular/buffering";
import { TermsOverlayComponent } from './terms-overlay/terms-overlay.component';
import { DownloadPageComponent } from './download-page/download-page.component';
import { SspBuyerGuard } from '@dooh/auth';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SelectionPanelComponent,
    GridPanelComponent,
    WelcomePageComponent,
    NewPasswordComponent,
    ResetPasswordComponent,
    SetNewPasswordComponent,
    SmbSignupComponent,
    InstanceCreateComponent,
    UpdateUserDataDialogComponent,
    TutorialsPanelComponent,
    TermsOverlayComponent,
    DownloadPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    InternalizationModule,
    ComponentsLibraryModule,
    FormsModule,
    AuthModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    ReactiveFormsModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule
  ],
  providers: [
    AuthService,
    NewPasswordGuard,
    WhoamiService,
    ApiService,
    DialogService,
    SspBuyerGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: BASE_PATH, useValue: environment.api_url },
    LoginUserTypeService,
    SmbCreateUserService,
    SmbAuth,
    SmbUnauth
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

