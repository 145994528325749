<div class="sidenav-container">
  <div class="text-place-n-radius">
    <form class="sidenav-search" *ngIf="selectedCategories.length > 0">
      <mat-form-field appearance="outline" class="search-field">
        <mat-label>
          {{ 'screenProperties.poi.textSearch' | translate }}
          {{
            selectedCategories.length === 1
              ? selectedCategories[0].split('_').join(' ')
              : 'all selected categories'
          }}
        </mat-label>
        <input
          matInput
          name="name"
          [(ngModel)]="textSearchValue"
          (input)="onTextKeywordChange()"
        />
        <button
          class="search-field__button color-gray-2"
          mat-icon-button
          matSuffix
          type="submit"
        >
          <mat-icon class="search-field__icon" matSuffix>search</mat-icon>
        </button>
      </mat-form-field>
    </form>
    <div class="slider-container">
      <mat-form-field appearance="outline" class="search-field radius">
        <mat-label>
          {{ 'screenProperties.poi.searchRadius' | translate }}
          <span>
            ({{
              isUSCitizen
                ? ('screenProperties.poi.mile' | translate)
                : ('screenProperties.poi.kilometer' | translate)
            }})
          </span>
        </mat-label>
        <input
          matInput
          name="name"
          placeholder="Search Radius"
          type="number"
          step="0.1"
          min="0.1"
          [max]="!isUSCitizen ? 30 : 18"
          [(ngModel)]="searchRadius"
        />
      </mat-form-field>
    </div>
    <div class="sidenav-search place-autocomplete">
      <form [formGroup]="placeForm">
        <dooh-autocomplete-field
          *ngIf="!isResetting"
          class="search-field"
          formControlName="placesSearched"
          searchBy="id"
          [label]="'screenProperties.poi.placeSearch' | translate"
          [service]="getScreensApi()"
          [multi]="true"
          [searchDelay]="800"
          [searchMinLength]="3"
          [itemTemplate]="placeItemTemplate"
          (allowSearch)="getPoiFromPlaces()"
          matTooltip="Type 3 letters or more to search places"
        ></dooh-autocomplete-field>
        <p class="place-info" *ngIf="poisSearchCountry">Places results will be from {{poisSearchCountry?.name}}</p>
      </form>
    </div>
  </div>
  <form class="sidenav-search">
    <mat-form-field appearance="outline" class="search-field">
      <input
        matInput
        name="name"
        placeholder="Search Categories"
        maxlength="255"
        [(ngModel)]="searchValue"
        (input)="onKeywordChange()"
      />
      <button
        class="search-field__button color-gray-2"
        mat-icon-button
        matSuffix
        type="submit"
      >
        <mat-icon class="search-field__icon" matSuffix>search</mat-icon>
      </button>
    </mat-form-field>
    <p class="country-info" *ngIf="isCountryOnly">
      <mat-icon class="icon">report_problem</mat-icon>
      <span
        >Your selected area is a country which can affect category
        results.</span
      >
    </p>
  </form>

  <div class="poi-filters">
    <mat-accordion>
      <mat-expansion-panel
        class="POI-items"
        *ngFor="let item of filteredList | keyvalue"
      >
        <mat-expansion-panel-header>
          <div class="poi-filter__group">
            <mat-checkbox
              class="poi-filter__selector"
              color="primary"
              [checked]="
                selectedPOIs[item.key].length === list[item.key].list.length &&
                list[item.key].list.length > 0
              "
              [indeterminate]="
                selectedPOIs[item.key].length > 0 &&
                selectedPOIs[item.key].length < list[item.key].list.length
              "
              (change)="selectPoiGroup(item.key, $event)"
              (click)="$event.stopPropagation()"
            ></mat-checkbox>

            <mat-panel-title
              [ngClass]="{ selectedPOI: selectedPOIs[item.key].length > 0 }"
            >
              <mat-icon
                class="poi-filter__icon"
                [svgIcon]="item.value.icon"
              ></mat-icon>
              <span>
                {{ item.value.text }}
              </span>
            </mat-panel-title>
          </div>
        </mat-expansion-panel-header>

        <div
          class="content-panel"
          *ngFor="let poi of item.value.list"
          (click)="selectPOI(poi, item)"
          [ngClass]="{ 'active-child': activeStyle[poi.id] }"
        >
          <span class="content-panel__check">
            <mat-checkbox
              class="poi-filter__selector"
              color="primary"
              [checked]="activeStyle[poi.id]"
              (click)="$event.stopPropagation()"
              (change)="selectPOI(poi, item)"
            ></mat-checkbox>
          </span>
          <span class="content-panel__content">
            <span class="content-panel__name">
              {{ poi.name }}
            </span>
            <span class="content-panel__address" [title]="poi.address">{{
              poi.address
            }}</span>
          </span>
        </div>
        <div
          class="content-panel"
          *ngIf="
            item.value.list.length > 0 &&
            !item.value.nextPageToken &&
            item.value.called
          "
        >
          <span class="content-panel__dummy-text">{{
            'screenProperties.poi.allLoaded' | translate
          }}</span>
        </div>

        <div class="content-panel" *ngIf="item.value.isLoading">
          <span class="content-panel__loader"
            ><mat-spinner diameter="30"></mat-spinner
          ></span>
        </div>

        <div
          class="content-panel"
          *ngIf="item.value.list.length > 0 && item.value.nextPageToken"
        >
          <button
            mat-button
            color="primary-inverse"
            class="button-container__button--stroked"
            [disabled]="item.value.isLoading"
            (click)="loadMorePois(item.value.name)"
          >
            Load more
          </button>
        </div>
        <div class="content-panel" *ngIf="item.value.list.length === 0">
          <span class="content-panel__dummy-text" *ngIf="!item.value.called">{{
            'screenProperties.poi.startLoad' | translate
          }}</span>
          <span class="content-panel__dummy-text" *ngIf="item.value.called">{{
            'screenProperties.poi.noResult' | translate
          }}</span>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div *ngIf="isPoiLoading" class="poi-filters__overlay">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  </div>

  <div class="radius-form">
    <span *ngIf="poiErrorMessage" class="error-message">{{
      poiErrorMessage
    }}</span>
    <div class="button-container">
      <div class="import-button">
        <div>
          <button
            mat-button
            color="primary-inverse"
            class="button-container__button--stroked import-poi-btn"
            (click)="openImportInstructionDialog()"
          >
            {{ 'screenProperties.poi.import' | translate }}
          </button>
          <p class="import-success" *ngIf="isCustomPois">
            <mat-icon class="icon">assignment_turned_in</mat-icon>
            <span>{{ allCustomPois.length }} pois imported.</span>
            <mat-icon (click)="removeCustomPoiFile()" class="icon delete-icon"
              >highlight_off</mat-icon
            >
          </p>
      </div>

        <div>
          <button
            mat-button
            color="primary-inverse"
            class="button-container__button--stroked import-zip-btn"
            (click)="openImportZipCodeInstructionDialog()"
          >
            {{ 'screenProperties.poi.importZipCode' | translate }}
          </button>
          <p class="import-success" *ngIf="isCustomZipCode">
            <mat-icon class="icon">assignment_turned_in</mat-icon>
            <span>{{ zipCodesCount }} ZIP/Postal codes imported.</span>
            <mat-icon (click)="removeCustomZipCodeFile()" class="icon delete-icon"
              >highlight_off</mat-icon
            >
          </p>
      </div>
      </div>
      <div class="right">
        <button
          mat-button
          color="primary-inverse"
          class="button-container__button stroked"
          (click)="onReset()"
        >
          Reset
        </button>
        <button
          [disabled]="isPoiLoading || !allowShowButton"
          mat-raised-button
          color="primary"
          class="button-container__button"
          (click)="onShow()"
        >
          Apply filter
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #placeItemTemplate let-item="item">
  <span *ngIf="!!item" class="autocomplete-container">
    <span class="icon-autocomplete">
      <mat-icon
        svgIcon="map-pin"
        *ngIf="item?.place_id; else publisher"
      ></mat-icon>
      <ng-template #publisher>
        <mat-icon svgIcon="users"></mat-icon>
      </ng-template>
    </span>
    <span class="autocomplete-text">
      <span class="country">{{ item.id }}</span>
      <span class="region">{{ item.tag }}</span>
    </span>
  </span>
</ng-template>
