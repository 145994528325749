import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@dooh/api';
import { RESPONSE_TAGS_MAP, ScreenTagsPagination, SCREEN_TAGS } from '@dooh/common-services';
import { CustomHttpUrlEncodingCodec, FieldFilterRequest, LimitsSet, ScreensTagsSet } from '@dooh/models';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import * as _ from 'lodash';
export interface FilterObj {
  filters: any;
  trigger: boolean;
}

const PAGE_SIZE = 50;

@Injectable({
  providedIn: 'root'
})
export class SearchFilterService {

  private syncingSearchField = new BehaviorSubject<any>(null);
  currentSearchField$ = this.syncingSearchField.asObservable();

  private tagsData = this._getDefaultTagsData();
  private tagsSource = new BehaviorSubject<ScreensTagsSet>(this.tagsData);
  tags$ = this.tagsSource.asObservable();

  private tagsPagination: ScreenTagsPagination = this._getDefaultTagsPagination();
  private tagsPaginationSource = new BehaviorSubject<ScreenTagsPagination>(this.tagsPagination);
  tagsPagination$ = this.tagsPaginationSource.asObservable();

  private getTagsNextPageSource = new Subject<string>();
  getTagsNextPage$ = this.getTagsNextPageSource.asObservable();

  
  private limitsSource = new ReplaySubject<LimitsSet>(1);
  limits$ = this.limitsSource.asObservable();
  
  private poiFiltersSource = new ReplaySubject<FilterObj>(1);
  poiFilters$ = this.poiFiltersSource.asObservable();

  private dmaIdsSource = new ReplaySubject<FilterObj>(1);
  dmaIds$ = this.dmaIdsSource.asObservable();

  private placeIqFiltersSource = new ReplaySubject<FilterObj>(1);
  placeIqFilters$ = this.placeIqFiltersSource.asObservable();
  
  private screenPropertiesFiltersSource = new ReplaySubject<FilterObj>(1);
  screenPropertiesFilters$ = this.screenPropertiesFiltersSource.asObservable();

  private filterByFieldSource = new BehaviorSubject<FieldFilterRequest>(null);
  filterByFieldSource$ = this.filterByFieldSource.asObservable();

  constructor(private apiService: ApiService) { }

  syncSearchField(value: any) {
    this.syncingSearchField.next(value)
  }

  setPoiFilters(poiFilters: any, trigger = true) {
    this.poiFiltersSource.next({filters: poiFilters, trigger});
  }

  setDmaIds(dmaIds: any, trigger = true) {
    this.dmaIdsSource.next({filters: dmaIds, trigger});
  }

  setPlaceIqFilters(placeIqFilters: any, trigger = true) {
    this.placeIqFiltersSource.next({filters: placeIqFilters, trigger});
  }

  setLimits(limits: LimitsSet) {
    this.limitsSource.next(limits);
  }

  setFilterByFieldSource(filter: FieldFilterRequest): void {
    if (filter) {
      let filterQueryParam = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
      filterQueryParam = filterQueryParam.set('page', <any>1);
      filterQueryParam = filterQueryParam.set('page_size', <any>1);
      const filterBody = _.cloneDeep(filter?.body);
      filterBody.isNeededAggregation = true;

      const filterRequest: FieldFilterRequest = {
        body: filterBody, queryParams: filterQueryParam, queryUrl: filter?.queryUrl
      }
      this.filterByFieldSource.next(filterRequest);
    }
  }

  setScreenPropertiesFilters(screenPropertiesFilters: any, trigger = true) {
    this.screenPropertiesFiltersSource.next({filters: screenPropertiesFilters, trigger});
  }

  setTags(tags: ScreensTagsSet) {
    const tagsData = {};
    this.resetTagsPages();
    tags = Object.keys(tags).reduce((storage, key) => {
      if (!RESPONSE_TAGS_MAP[key]) {
        return storage;
      }
      
      storage[RESPONSE_TAGS_MAP[key]] = tags[key];
      return storage;
    }, {});

    const refresh = Object.keys(SCREEN_TAGS).every(key => {
      return this.tagsPagination[SCREEN_TAGS[key]].page === 1;
    });

    Object.keys(SCREEN_TAGS).forEach(key => {
      const tagKey = SCREEN_TAGS[key];
      const tagPagination = this.tagsPagination[tagKey];
      const tagData = this.tagsData[tagKey];
      if (!tags[tagKey]) {
        return;
      }
      
      if (refresh) {
        tagsData[tagKey] = {
          ...tags[tagKey],
          hasMore: tags[tagKey].values.length >= PAGE_SIZE
        };
        
        return;
      }
      
      if (tagData.values.length < tagPagination.page * tagPagination.pageSize && tagData.hasMore) {
        tagsData[tagKey] = {
          ...tagData,
          values: [...tagData.values, ...tags[tagKey].values],
          hasMore: tags[tagKey].values.length >= PAGE_SIZE
        };
      } else {
        tagsData[tagKey] = tagData;
      }
    });

    this.tagsData = tagsData;

    this.tagsSource.next(this.tagsData);
  }

  nextTagsPage(tagKey: string) {
    if (!this.tagsPagination[tagKey] || !this.tagsData[tagKey].hasMore) {
      return;
    }

    this.tagsPagination = {
      ...this.tagsPagination,
      [tagKey]: {
        ...this.tagsPagination[tagKey],
        page: this.tagsPagination[tagKey].page + 1
      }
    };

    this.tagsPaginationSource.next(this.tagsPagination);
    this.getTagsNextPageSource.next(tagKey);
  }

  resetTagsPages() {
    this.tagsPagination = this._getDefaultTagsPagination();
    this.tagsPaginationSource.next(this.tagsPagination);
  }

  filterByField(filter: FieldFilterRequest): Observable<any> {
    return this.apiService.post(filter.queryUrl, filter.body, filter.queryParams);
  }

  private _getDefaultTagsPagination(): ScreenTagsPagination {
    return Object.keys(SCREEN_TAGS).reduce((storage, key) => {
      storage[SCREEN_TAGS[key]] = {
        page: 1,
        pageSize: PAGE_SIZE
      };

      return storage;
    }, {});
  }

  private _getDefaultTagsData(): ScreensTagsSet {
    return Object.keys(SCREEN_TAGS).reduce((storage, key) => {
      storage[SCREEN_TAGS[key]] = {
        values: [],
        total: null,
        hasMore: true
      };

      return storage;
    }, {});
  }
}
