<div class="login-container"><div class="login-container__logo">
  <img hidden *ngIf="namespace" [src]="namespace?.url" #logo (load)="onLoadLogo()">
  <div class="logo-container" [ngClass]="{'landscape': isLandscapeLogo}">
    <img *ngIf="namespace" [src]="namespace?.url" class="namespace__logo">
  </div>
</div>
  <div class="login-container__login login">
    <div class="login__title">{{'login.smbLogin.signUp' | translate}}</div>
    <div class="login__subtitle" *ngIf="namespace.isEnabled">{{'login.subtitle' | translate}} {{ namespace?.name | translate }} </div>
    <div class="login__subtitle disabled" *ngIf="!namespace.isEnabled">{{ namespace?.name | translate }} {{'welcomePage.namespaceDisabled' | translate}}</div>
    <form class="login__form login-form" [formGroup]="loginForm">
      <mat-form-field class="login-form__field" appearance="outline">
        <mat-label>{{'login.smbLogin.businessName' | translate}}</mat-label>
        <input matInput
               formControlName="businessName"
               name="businessName"
               placeholder="{{'login.smbLogin.businessName' | translate}}"
               type="text"
               (focus)="errorLogin = ''"
               maxlength="255">
        <mat-error *ngIf="loginForm.controls['businessName'].hasError('required')">
          {{'login.required' | translate}}
        </mat-error>
        <mat-error *ngIf="loginForm.controls['businessName'].hasError('pattern')">
          {{'login.smbLogin.errors.patternError' | translate}}
        </mat-error>
      </mat-form-field>
      
      <mat-form-field class="login-form__field" appearance="outline">
        <mat-label>{{'login.smbLogin.firstName' | translate}}</mat-label>
        <input matInput
               formControlName="firstName"
               name="firstName"
               placeholder="{{'login.smbLogin.firstName' | translate}}"
               type="text"
               (focus)="errorLogin = ''"
               maxlength="255">
        <mat-error *ngIf="loginForm.controls['firstName'].hasError('required')">
          {{'login.required' | translate}}
        </mat-error>
        <mat-error *ngIf="loginForm.controls['firstName'].hasError('pattern')">
          {{'login.smbLogin.errors.patternError' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="login-form__field" appearance="outline">
        <mat-label>{{'login.smbLogin.lastName' | translate}}</mat-label>
        <input matInput
               formControlName="lastName"
               name="lastName"
               placeholder="{{'login.smbLogin.lastName' | translate}}"
               type="text"
               (focus)="errorLogin = ''"
               maxlength="255">
        <mat-error *ngIf="loginForm.controls['lastName'].hasError('required')">
          {{'login.required' | translate}}
        </mat-error>
        <mat-error *ngIf="loginForm.controls['lastName'].hasError('pattern')">
          {{'login.smbLogin.errors.patternError' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="login-form__field" appearance="outline">
        <mat-label>{{'login.email' | translate}}</mat-label>
        <input matInput
               formControlName="email"
               name="email"
               placeholder="{{'login.email' | translate}}"
               type="email"
               (focus)="errorLogin = ''"
               maxlength="255">
        <mat-error *ngIf="loginForm.controls['email'].hasError('required')">
          {{'login.required' | translate}}
        </mat-error>
        <mat-error *ngIf="loginForm.controls['email'].hasError('pattern')">
          {{'login.emailError' | translate}}
        </mat-error>
      </mat-form-field>
      
      <div class="login-form__field mat-form-field-wrapper">
        <dooh-phone-number-input [control] = "phoneNumber"></dooh-phone-number-input>
        <div class="mat-form-field-subscript-wrapper">
          <mat-error *ngIf="phoneNumber.hasError('required') && phoneNumber.touched">
            {{'login.required' | translate}}
          </mat-error>
          <mat-error *ngIf="phoneNumber.hasError('validatePhoneNumber') && phoneNumber.touched">
            {{'login.phoneInvalid2' | translate}}
          </mat-error>
        </div>
      </div>

      <span
        class="checkbox-container"
        (click)="errorLogin = ''"
      >
        <mat-checkbox [disableRipple]="true"
        (click)="openTermOfServiceDialog($event)"
          class="example-margin"
          formControlName="terms"
        ></mat-checkbox>
        <a (click)="openTermOfServiceDialog()"> {{'login.smbLogin.terms' | translate}}</a>
      </span>
      <div class='login-form__errors'>
          <mat-error *ngIf="errorLogin">
            {{'login.incorrectData' | translate}}
          </mat-error>
          <mat-error *ngIf="loginForm.controls['terms'].invalid && showTermsError">
            You have to accept the terms and conditions
          </mat-error>
      </div>

      <span
        class="checkbox-container marketing-checkbox"
      >
        <mat-checkbox [disableRipple]="true"
          class="example-margin"
          formControlName="marketing"
        ></mat-checkbox>
        <a href="javascript:void(0)"> {{'login.smbLogin.marketing' | translate}}</a>
    </span>

      <div class="login-form__button" >
        <button mat-raised-button
                color="primary"
                [disabled]="loginForm.invalid"
                (click)="onSubmit()">{{'login.smbLogin.signUp' | translate}}
        </button>
        <a routerLink = "/login" class="btn--inline">Log In</a>

      </div>

    </form>
  </div>
  <div class="login-footer" *ngIf="namespace?.byNeuron">{{'login.footer' | translate}}</div>
</div>
