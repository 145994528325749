import { Component, ComponentRef, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertDialogData } from '@dooh/models';
import { TranslateService } from '@ngx-translate/core';
import { ActiveNamespaceService, TermsService } from '@dooh/common-services';
import { Subject } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';
import { NamespaceService } from 'apps/admin-panel/src/app/namespaces-management/services/namespace.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { DialogService } from '../dialog.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'dooh-term-of-service-dialog',
  templateUrl: './term-of-service-dialog.component.html',
  styleUrls: ['./term-of-service-dialog.component.scss']
})
export class TermOfServiceDialogComponent implements OnInit, OnDestroy {

  contentRef: ComponentRef<any>;
  unsubscriber$ = new Subject<void>();

  agreed = false;
  termsOfService: any;
  isLoading: boolean;
  constructor(
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<TermOfServiceDialogComponent>,
    private namespaceService: NamespaceService,
    public dialogService: DialogService,
    private domSanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.translateService.setDefaultLang('en');
    this.agreed = data?.isAgreed;
  }

  ngOnInit(): void {
    this.dialogRef.updateSize('1080px');
    this.getTermsOfService()
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  getTermsOfService(): void {
    this.isLoading = true;
    this.namespaceService
      .getLatestTermsOfService(ActiveNamespaceService.getNamespaceId())
      .pipe(
        takeUntil(this.unsubscriber$),
        switchMap((result) => {
          return this.namespaceService.downloadTermsOfService(
            result?.url
          );
        })
      )
      .subscribe(
        (res: HttpResponse<Blob>) => {
          res.body?.text().then((val: any) => {
          this.termsOfService = this.domSanitizer.bypassSecurityTrustHtml(val);
          this.isLoading = false;
          });
        },
        (error) => {
          this.isLoading = false;
          this.openErrorDialog(error);
        }
      );
  }

  openErrorDialog(error: HttpErrorResponse): void {
    this.translateService
      .get('adminPanel.generalInfo.dialog')
      .subscribe((dialog) => {
        const message =
          error.status !== 401
            ? dialog.message.error[error.error?.message] || error.error?.message
            : this.getBadRequestError(error);

        this.dialogService.alert({
          type: 'error',
          message,
        });
      });
  }

  getBadRequestError(errorResponse: HttpErrorResponse): string {
    const { error } = errorResponse;

    if ((!error.subErrors || !error.subErrors.length) && !error.systemMessage) {
      return error.status.toString();
    }

    if (!error.subErrors || !error.subErrors.length) {
      return error.systemMessage;
    }

    return error.subErrors.map((subError) => subError.message).join('; ');
  }

  onSubmitClick(): void {
    this.agreed = true;
    this.dialogRef.close(this.agreed)
  }

  ngOnDestroy(): void {
    if (this.contentRef) {
      this.contentRef.destroy();
    }
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

}
