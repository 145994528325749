import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

export default function noHttpPrefixValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value;

    if (!!value && (value.startsWith('http://') || value.startsWith('https://'))) {
      return { 'httpPrefix': true };
    } else {
      return null;
    }
  };
}