import { Component, OnInit, Input, ComponentRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AddDynamicComponentService } from '@dooh/common-services';
import { ActiveHourWeeklyTableService } from '../hour-weekly-table/services/active-hour-weekly-table.service';

enum Errors {
  'REQUIRED' = 'The field hour can not be empty',
  'BIGGER' = 'Start time can not be greater than end time'
};

const MISSED_FROM_STR = '--:--';
const MISSED_TO_STR = '--:--';

@Component({
  selector: 'dooh-hour-range-field',
  templateUrl: './hour-range-field.component.html',
  styleUrls: ['./hour-range-field.component.scss']
})
export class HourRangeFieldComponent implements OnInit, OnDestroy {

  @Input()
  disabled?: boolean;

  @Input()
  canClose?: boolean;

  @Input()
  componentRef?: ComponentRef<any>;

  @Output()
  closeEvent: EventEmitter<number> = new EventEmitter<number>();

  error: string;
  errorCount = 0;
  closeButton: boolean;
  values: Map<string, string>;
  @Input()
  fromValue?: string;

  @Input()
  toValue?: string;

  timeRange: FormGroup;
  times: string[];
  timesToField: string[];

  @Output()
  changeValue: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private removeComponent: AddDynamicComponentService,
    public translateService: TranslateService,
    public activeHourWeeklyTableService: ActiveHourWeeklyTableService
  ) {
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.times = [];
    this.timesToField = [];
    for (let i = 0; i < 24; i++) {
      this.times.push(this.setHourFormat(i, ':00'));
      this.timesToField.push(this.setHourFormat(i, ':59'));
    }
    this.timeRange = new FormGroup({
      from: new FormControl(this.fromValue),
      to: new FormControl(this.toValue)
    });
    if (this.fromValue === null || this.fromValue === undefined) {
      this.fromValue = MISSED_FROM_STR;
      this.toValue = MISSED_TO_STR;
    }
  }

  updateFromValue(value?: string) {
    if (value) {
      this.fromValue = value;
    } else {
      this.fromValue = this.timeRange.controls['from'].value;
      this.getValue();
      this.checkErrorsToEmit();
    }
    this.setFromValue(this.fromValue);
    this.changeValue.emit(true);
  }

  updateToValue(value?: string) {
    if (value) {
      this.toValue = value;
    } else {
      this.toValue = this.timeRange.controls['to'].value;
      this.getValue();
      this.checkErrorsToEmit();
    }
    this.setToValue(this.toValue);
    this.changeValue.emit(true);
  }

  checkErrorsToEmit() {
    if (this.error) {
      this.errorCount++;
      this.activeHourWeeklyTableService.setHourRangeErrorCount(this.errorCount);
    } else {
      this.errorCount = -Math.abs(this.errorCount);
      this.activeHourWeeklyTableService.setHourRangeErrorCount(this.errorCount);
      this.errorCount = 0;
    }

  }

  activeClose() {
    this.closeButton = true;
  }

  deactiveClose() {
    this.closeButton = false;
  }

  remove() {
    this.errorCount = -Math.abs(this.errorCount);
    this.closeEvent.emit(-1);
    if (this.error) {
      this.activeHourWeeklyTableService.setHourRangeErrorCount(this.errorCount);
      this.error = null;
      this.errorCount = 0;
    }
    this.removeComponent.destroyComponent(this.componentRef);
  }

  private checkInput() {
    this.error = null;
    if (this.fromValue === MISSED_FROM_STR || this.toValue === MISSED_TO_STR) {
      this.error = Errors.REQUIRED;
      return false;
    } else if (this.fromValue > this.toValue) {
      this.error = Errors.BIGGER;
      return false;
    }

    return true;
  }

  getValue() {
    if (!this.timeRange?.controls['from'].dirty) {
      this.timeRange?.controls['from'].setValue(this.fromValue);
    }
    if (!this.timeRange?.controls['to'].dirty) {
      this.timeRange?.controls['to'].setValue(this.toValue);
    }
    if (this.checkInput())
      return this.timeRange;
    return null;
  }

  setFromValue(value: string) {
    this.fromValue = value;
    this.timeRange.value['from'] = value;
  }

  setToValue(value: string) {
    this.toValue = value;
    this.timeRange.value['to'] = value;
  }

  private setHourFormat(value: number, formatField: string): string {
    if (value <= 9)
      return ('0' + value + formatField);
    else if (value > 9)
      return (value + formatField);
  }

  onBlur() {
    this.getValue();
    this.changeValue.emit(this.getValue())
  }

  ngOnDestroy(): void {
    this.errorCount = 0;
    this.error = null;
  }
}
