<mat-form-field
  appearance="outline"
  class="money-field form-field-common"
  [formGroup]="form"
>
  <mat-label>
    {{ label }}
  </mat-label>
  <span matPrefix>{{currency}}</span>
  <input
    type="text"
    matInput
    [maxLength]="maxLength"
    [formControl]="control"
    [errorStateMatcher]="errorStateMatcher"
    (blur)="onBlurInput()"
  >
  <ng-content></ng-content>
  <mat-hint *ngIf="hint" class="hint"><mat-icon>info</mat-icon>{{hint}}</mat-hint>
  <mat-error *ngIf="control.invalid">
    {{ getControlError() | async }}
  </mat-error>
  <mat-error *ngIf="form.invalid">
    {{ getCrossError() | async }}
  </mat-error>
</mat-form-field>
