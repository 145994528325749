import Amplify, { Auth } from 'aws-amplify';
import { Router, CanActivate, CanLoad } from '@angular/router';
import { Injectable } from '@angular/core';
import { User } from '@dooh/models';

import { INIT_STATUS } from '../../../../apps/sso-webapp/src/app/services/namespace-init.service'
import { createAmplifyConfig } from './amplify/amplify.config';
import { ActiveNamespaceService } from '@dooh/common-services';

const REPEAT_INTERVAL = 500;

@Injectable()
export class AuthService implements CanActivate, CanLoad {
  constructor(
    private router: Router,
  ) {}
  login(user: User) {
    return Auth.signIn(user.email, user.password);
  }

  changePassword = async (oldPassword, newPassword): Promise<any> => {
    return new Promise((resolve, reject)=>{
      (async ()=>{
        try{
          const CognitoUser = await Auth.currentAuthenticatedUser();
          const passwordChanged = await Auth.changePassword(CognitoUser, oldPassword, newPassword)
          resolve(passwordChanged)
        }
        catch(error){
          reject(error)
        }
      })()
    })
  }

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const id = setInterval(
        () => {
          const status = localStorage.getItem('initializationStatus');
          if (status === INIT_STATUS.RESOLVED) {
            clearInterval(id);
            Auth.currentAuthenticatedUser()
              .then(() => {
                const config = createAmplifyConfig(ActiveNamespaceService.getNamespace());
                Amplify.configure(config);
                return resolve(true)
              })
              .catch(() => reject(false));
          } else if (status === INIT_STATUS.REJECTED) {
            clearInterval(id);
            localStorage.clear();
            this.router.navigateByUrl('/');
            reject(false);
          }
        },
        REPEAT_INTERVAL,
      );
    }).catch(() => {
      this.router.navigate(['/']);
      window.location.reload(); 
    }) as Promise<boolean>;
  }

  canLoad(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const id = setInterval(
        () => {
          const status = localStorage.getItem('initializationStatus');
          if (status === INIT_STATUS.RESOLVED) {
            clearInterval(id);
            Auth.currentAuthenticatedUser()
              .then(() => {
                const config = createAmplifyConfig(ActiveNamespaceService.getNamespace());
                Amplify.configure(config);
                return resolve(true)
              })
              .catch(() => reject(false));
          } else if (status === INIT_STATUS.REJECTED) {
            clearInterval(id);
            localStorage.clear();
            this.router.navigateByUrl('/');
            reject(false);
          }
        },
        REPEAT_INTERVAL,
      );
    }).catch(() => {
      this.router.navigate(['/']);
      window.location.reload(); 
    }) as Promise<boolean>;
  }


  async getToken() {
    return Auth.currentAuthenticatedUser()
      .then((userInfo) => {
        const key = `${userInfo?.keyPrefix}.${userInfo?.username}.idToken`;
        const idToken = userInfo?.storage?.[key];

        if (idToken) {
          return idToken;
        } else {
          this.router.navigate(['/']);
        }
      })
      .catch(() => this.router.navigate(['/']));
  }

  newPassword(currentUser: any, password: string, options: any = {}) {
    return Auth.completeNewPassword(currentUser, password, options)
      .then(() => {
        localStorage.clear();
        return "OK";
      })
      .catch(err => Promise.reject(err));
  }

  logout() {
    this.clearLocalStorage();
    return Auth.signOut().then(
      () => {
        this.router.navigate(['/'])
        window.location.reload()
      });
  }

  clearLocalStorage(): void {
    const instanceObj = localStorage.getItem('instance');
    const termsVersion = localStorage.getItem('termsVersion');
    const namespace = localStorage.getItem('namespace');
    const initStatus = localStorage.getItem('initializationStatus');
    const cognitoPool = localStorage.getItem('cognitoPool');

    localStorage.clear();

    localStorage.setItem('initializationStatus', initStatus);
    localStorage.setItem('instance', instanceObj);
    localStorage.setItem('termsVersion', termsVersion);
    localStorage.setItem('namespace', namespace);
    localStorage.setItem('cognitoPool', cognitoPool);
  }

  isAuthenticated() {
    return new Promise((resolve, reject) => {
      const id = setInterval(
        () => {
          const status = localStorage.getItem('initializationStatus');
          if (status === INIT_STATUS.RESOLVED) {
            clearInterval(id);
            Auth.currentAuthenticatedUser()
              .then((userInfo) => resolve(userInfo))
              .catch(() => reject());
          } else if (status === INIT_STATUS.REJECTED) {
            clearInterval(id);
            reject();
          }
        },
        REPEAT_INTERVAL,
      );
    });
  }

  resetPassword(email: string) {
    localStorage.setItem('username', email);
    return Auth.forgotPassword(email);
  }

  sendResetPassword(username: string, {code, password}) {
    return Auth.forgotPasswordSubmit(username, code, password);
  }
}
