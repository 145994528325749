import {Injectable} from '@angular/core';
import {ApiService} from '@dooh/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmbCreateUserService {

  constructor(
    private apiService: ApiService
  ) {
  }

  getSMBUser(): Observable<any> {
    return this.apiService.get('admin/smb/users')
  }

  createSMBUser(user: {
    companyName: string,
    email: string,
    companyWebsite: string,
    firstName: string,
    lastName: string,
    phone: String,
    termsOfServiceVersion: string,
    subscribeNewsLetter: boolean }) {
    return this.apiService.post('admin/smb/users', {
      companyName: user.companyName,
      email: user.email,
      companyWebsite: user.companyWebsite,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      termsOfServiceVersion: user.termsOfServiceVersion,
      subscribeNewsLetter: user.subscribeNewsLetter
    });
  }

  updateUserTermsVersion(user: { termsOfServiceVersion: string }) {
    return this.apiService.put(`admin/users/terms-of-service/${user.termsOfServiceVersion}`);
  }
}
