import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

const DEFAULT_FROM_STR = '00:00';
const DEFAULT_TO_STR = '23:59';
const END_HOUR_SECONDS = ':59.999999999';
const MINS_IN_A_DAY = 1439;
enum Errors {
  DISABLED = 'Day of the week is disabled.',
  OVERLAP = 'There is a time overlap with another selected time.',
  FULLDAY = 'Copying will overwrite the full day schedule.',
}
@Component({
  selector: 'dooh-copy-time',
  templateUrl: './copy-time.component.html',
  styleUrls: ['./copy-time.component.scss'],
})
export class CopyTimeComponent implements OnInit {
  startTime: any;
  endTime: any;
  selectedDay: string;
  weekDays: string[] = [];
  weekDaysWithData: any[] = [];
  selectedDaysToCopyTo = new SelectionModel(true, []);

  constructor(
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<CopyTimeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.translateService.setDefaultLang('en');
    if (this.data?.data) {
      this.weekDays = this.data?.data?.weekDays;
      this.weekDaysWithData = this.data?.data?.weekDaysWithData;
      this.selectedDay = this.data?.data?.selectedDay;

      const startTime = this.data?.data?.time['startTime'];
      const endTime = this.data?.data?.time['endTime'];

      this.startTime = `${startTime.split(':')[0]}:${startTime.split(':')[1]}`;
      this.endTime = `${endTime.split(':')[0]}:${endTime.split(':')[1]}`;
    }
  }

  ngOnInit(): void {}

  private checkOverlap(
    dayData: any[],
    newStartTime: string,
    newEndTime: string
  ): boolean {
    if (dayData?.length === 0) return false;
    const timeSegments = [];
    dayData.forEach((item) => {
      const startTime = item.startTime;
      const endTime = item.endTime;
      const timeArr = [startTime, endTime];
      timeSegments.push(timeArr);
    });
    timeSegments.push([newStartTime, newEndTime]);
    if (timeSegments.length === 1) return false;

    timeSegments.sort((timeSegment1, timeSegment2) =>
      timeSegment1[0].localeCompare(timeSegment2[0])
    );

    for (let i = 0; i < timeSegments.length - 1; i++) {
      const currentEndTime = timeSegments[i][1];
      const nextStartTime = timeSegments[i + 1][0];

      if (currentEndTime > nextStartTime) {
        return true;
      }
    }

    return false;
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  toggleDayToCopyTo(day: string): void {
    this.selectedDaysToCopyTo.toggle(day.toLowerCase());
  }

  isDayDisabledCheck(day: string): boolean {
    if (day.toLowerCase() === this.selectedDay) return true;

    const dayData = this.weekDaysWithData?.find(
      (weekDay) => weekDay?.day === day.toLowerCase()
    )?.segment;
    if (dayData?.length === 0) return true;

    let busyTimeInMins = 0;
    dayData.forEach((data) => {
      busyTimeInMins += this.getTimeDiffInMins(data);
    });

    if (busyTimeInMins === MINS_IN_A_DAY) return false;

    return this.checkOverlap(dayData, this.startTime, this.endTime);
  }

  getError(day: string): string {
    if (day.toLowerCase() === this.selectedDay) return null;
    const dayData = this.weekDaysWithData?.find(
      (weekDay) => weekDay?.day === day.toLowerCase()
    )?.segment;
    if (dayData?.length === 0) return Errors.DISABLED;

    let busyTimeInMins = 0;
    dayData.forEach((data) => {
      busyTimeInMins += this.getTimeDiffInMins(data);
    });

    if (busyTimeInMins === MINS_IN_A_DAY) return Errors.FULLDAY;

    return this.checkOverlap(dayData, this.startTime, this.endTime)
      ? Errors.OVERLAP
      : null;
  }

  onSubmitClick(): void {
    if (this.selectedDaysToCopyTo.selected?.length > 0) {
      this.dialogRef.close({
        selectedDaysToCopyTo: this.selectedDaysToCopyTo.selected,
      });
    }
  }

  formatTime(time: any): string {
    const formatTime = moment(time, 'hh:mm');
    return formatTime.format('hh:mm a');
  }

  getTimeDiffInMins(data: { startTime: string; endTime: string }): number {
    const startTime = moment(data?.startTime + ':00', 'hh:mm:ss a');
    const endTime = moment(data?.endTime + END_HOUR_SECONDS, 'hh:mm:ss a');
    const duration = endTime.diff(startTime, 'minutes');
    return duration;
  }
}
