import { CreativeForm, CreativeView, IabCategoryView } from '@dooh/models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { pluck, exhaustMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { noWhitespaceValidator, valueTypeValidator, xregexpValidator } from '@dooh/validation';
import { CreativeService } from './creative.service';
import { FILE_TYPE, findIncorrectType } from 'libs/models/src/model/fileType';

export abstract class CreativeSideNav {
  file: File = null;
  loading = false;
  public formGroupMain: FormGroup;
  isLargeFile = false;
  incorrectType = false;
  isFileRequired = false;
  private readonly MAX_FILE_SIZE = 2500000000;
  hasSaved = false;
  constructor(
    public formBuilder: FormBuilder,
    public translateService: TranslateService,
    public creativeManagementService: CreativeService,
    public dialogService
  ) {
    this.translateService.setDefaultLang('en');
  }

  init() {
  }

  getNameValidator() {
    return ['', [
      Validators.required,
      xregexpValidator("^[\\p{L}a-zA-Z0-9\\s\\{\\}\\?!@#$%^&*()\\-_.+=|:'~\\[\\]]+$"),
      noWhitespaceValidator()
    ]];
  }

  createForms() {
    this.formGroupMain = this.formBuilder.group({
      name: this.getNameValidator(),
      length:  new FormControl({ value: '', disabled: false }),
      categories: new FormControl([], [
        Validators.required,
        valueTypeValidator('object')
      ]),
    });
  }

  extractCategoryValue(option) {
    return option.description;
  }

  getCategoriesValueArr() {
    const categories = this.formGroupMain.get('categories').value;
    return Array.isArray(categories) ? categories.map(option => option.value) : [];
  }

  handleFileInput($event: Event) {
    this.file = $event.target['files'][0];
    this.isLargeFile = this.file.size > this.MAX_FILE_SIZE;
    this.incorrectType = findIncorrectType(this.file.type.split('/')[0]);
    this.isFileRequired = false;
  }

  handleFileDropped(fileList: FileList) {
    this.file = fileList[0];
    this.isLargeFile = this.file.size > this.MAX_FILE_SIZE;
    this.incorrectType = findIncorrectType(this.file.type.split('/')[0]);
    this.isFileRequired = false;
  }

  removeFile() {
    this.formGroupMain.controls.file.setValue('');
    this.file = null;
    this.isFileRequired = false;
    this.isLargeFile = false;
    this.incorrectType = false;
    this.file = null;
  }

  resetForm() {
    this.formGroupMain.reset({name: '', length: '', categories: []});
    this.removeFile();
    this.formGroupMain.enable();
    Object.keys(this.formGroupMain.controls).forEach(key => {
      this.formGroupMain.get(key).setErrors(null);
      this.formGroupMain.get(key).updateValueAndValidity();
    });
  }

  openErrorDialog(error: HttpErrorResponse): void {
    this.translateService
      .get('dsp.creativeManagement.createCreative.dialog')
      .subscribe(dialog => {
        const message = error.status !== 400
          ? (dialog.message.error[error.status] || error.status)
          : 'dsp.creativeManagement.createCreative.form.validationError.fileIncorrectTypeDialog'
        this.dialogService.alert({
          type: 'error',
          message
        });
      });
  }

  getAccept() : string{
    let accept: string = '';
      for (let typeItem in FILE_TYPE) {
        if (typeItem !== '0' && typeItem !== '1' && typeItem !== '2') {
          accept += typeItem+"/*,";
        }
      }
      return accept;
    }

  uploadCreative(body: CreativeForm) {
    this.loading = true;
    this.formGroupMain.disable();

    this.creativeManagementService.createCreativeUpload()
      .pipe(
        exhaustMap(upload => {
          body.uploadKey = upload.uploadKey;
          return this.creativeManagementService.uploadCreativeFile(upload.uploadUrl, this.file, this.file.type);
        }),
        exhaustMap(() => this.creativeManagementService.saveCreative(body))
      )
      .subscribe(
        creative => {
          this.loading = false;
          this.resetForm();
          this.emitAndClose(creative);
          this.hasSaved = false;
          this.formGroupMain.reset();
        },
        error => {
          this.loading = false;
          this.formGroupMain.enable();
          this.openErrorDialog(error);
          this.hasSaved = false;
        }
      );
  }

  updateCreative(creativeId: string, body: CreativeForm, updateMedia?: boolean) {
    this.loading = true;
    this.formGroupMain.disable();
    const apiCall = updateMedia
      ? this.creativeManagementService.createCreativeUpload()
        .pipe(
          exhaustMap(upload => {
            body.uploadKey = upload.uploadKey;
            return this.creativeManagementService.uploadCreativeFile(upload.uploadUrl, this.file, this.file.type);
          }),
          exhaustMap(() => this.creativeManagementService.updateCreative(creativeId, body))
        )
      : this.creativeManagementService.updateCreative(creativeId, body);

    apiCall.subscribe(
      creative => {
        this.loading = false;
        this.resetForm();
        this.emitAndClose(creative);
        this.hasSaved = false;
      },
      error => {
        this.loading = false;
        this.hasSaved = false;
        this.formGroupMain.enable();
        this.openErrorDialog(error);
      }
    );
  }

  abstract emitAndClose(creative: CreativeView);
}
