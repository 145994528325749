<div class="update-user-data">
  <ng-container *ngIf="!isUpdated">
    <img
      class="payment-dialog__result"
      src="../../../../assets/images/warning.svg"
    />
    <div class="update-user-data__message">
      {{ 'login.instanceCreate.message.warning' | translate}}
    </div>
  </ng-container>

  <ng-container *ngIf="isUpdated">
    <img
      class="payment-dialog__result"
      src="../../../../assets/images/green_check.png"
    />
    <div class="update-user-data__message">
      {{ 'login.instanceCreate.message.success' | translate}}
    </div>
  </ng-container>

  <div class="update-user-data__footer">
    <button
      *ngIf="!isUpdated"
      class="update-user-data__button"
      (click)="updateUserData()"
      [disabled]="isLoading"
      mat-raised-button
      color="primary"
    >
      <span *ngIf="!isLoading">
        {{ 'login.instanceCreate.button.reload' | translate}}
      </span>
      <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
    </button>
    <button
      *ngIf="isUpdated"
      class="update-user-data__button"
      (click)="onNext()"
      mat-raised-button
      mat-dialog-close
      color="primary"
    >
      {{ 'login.instanceCreate.button.next' | translate}}
    </button>
  </div>
</div>
