import { Injectable } from '@angular/core';
import { ReplaySubject, BehaviorSubject, Subject, Observable } from 'rxjs';

import { CustomHttpUrlEncodingCodec, FieldFilterRequest, LimitsSet, ScreensTagsSet } from '@dooh/models';
import { RESPONSE_TAGS_MAP, ScreenTagsPagination, SCREEN_TAGS } from '../screens-filters/screens-filters';
import { ApiService } from '@dooh/api';
import { HttpParams } from '@angular/common/http';

const PAGE_SIZE = 50;

@Injectable({
  providedIn: 'root'
})
export class TargetingFiltersService {
  private limitsSource = new ReplaySubject<LimitsSet>(1);

  private isPoiActiveSource = new ReplaySubject<boolean>(1);
  private isScreenPropertiesActiveSource = new ReplaySubject<boolean>(1);

  private poiFiltersSource = new ReplaySubject<any>(1);
  private screenPropertiesFiltersSource = new ReplaySubject<any>(1);
  private dmaFiltersSource = new ReplaySubject<any>(1);
  private placeIsFiltersSource = new ReplaySubject<any>(1);

  private isPoiOpenSource = new ReplaySubject<any>(1);
  private isScreenPropertiesOpenSource = new ReplaySubject<any>(1);

  limits$ = this.limitsSource.asObservable();

  isPoiActive$ = this.isPoiActiveSource.asObservable();
  isScreenPropertiesActive$ = this.isScreenPropertiesActiveSource.asObservable();

  poiFilters$ = this.poiFiltersSource.asObservable();
  screenPropertiesFilters$ = this.screenPropertiesFiltersSource.asObservable();
  dmaFilters$ = this.dmaFiltersSource.asObservable();
  placeIqFilters$ = this.placeIsFiltersSource.asObservable();

  isPoiOpen$ = this.isPoiOpenSource.asObservable();
  isScreenPropertiesOpen$ = this.isScreenPropertiesOpenSource.asObservable();

  private tagsPagination: ScreenTagsPagination = this._getDefaultTagsPagination();
  private tagsPaginationSource = new BehaviorSubject<ScreenTagsPagination>(this.tagsPagination);
  tagsPagination$ = this.tagsPaginationSource.asObservable();

  private getTagsNextPageSource = new Subject<string>();
  getTagsNextPage$ = this.getTagsNextPageSource.asObservable();

  private tagsData = this._getDefaultTagsData();
  private tagsSource = new BehaviorSubject<ScreensTagsSet>(this.tagsData);
  tags$ = this.tagsSource.asObservable();

  filterByFieldSource = new BehaviorSubject<FieldFilterRequest>(null);
  filterByFieldSource$ = this.filterByFieldSource.asObservable();


  constructor(private apiService: ApiService) { }

  setLimits(limits: LimitsSet) {
    this.limitsSource.next(limits);
  }

  setPoiStatus(isScreenPropertiesActive: boolean) {
    this.isPoiActiveSource.next(isScreenPropertiesActive);
  }

  setScreenPropertiesStatus(isPoiActive: boolean) {
    this.isScreenPropertiesActiveSource.next(isPoiActive);
  }

  setPoiFilters(poiFilters: any) {
    this.poiFiltersSource.next(poiFilters);
  }

  setScreenPropertiesFilters(screenPropertiesFilters: any) {
    this.screenPropertiesFiltersSource.next(screenPropertiesFilters);
  }

  setDmaFilters(dmaIds: any): void {
    this.dmaFiltersSource.next(dmaIds);
  }

  setPlaceIqFilters(dmaIds: any): void {
    this.placeIsFiltersSource.next(dmaIds);
  }

  togglePoi(isOpen: boolean) {
    this.isPoiOpenSource.next(isOpen);
  }

  toggleScreenProperties(isOpen: boolean) {
    this.isScreenPropertiesOpenSource.next(isOpen);
  }

  setTags(tags: ScreensTagsSet) {
    const tagsData = {};
    this.resetTagsPages();
    tags = Object.keys(tags).reduce((storage, key) => {
      if (!RESPONSE_TAGS_MAP[key]) {
        return storage;
      }
      
      storage[RESPONSE_TAGS_MAP[key]] = tags[key];
      return storage;
    }, {});

    const refresh = Object.keys(SCREEN_TAGS).every(key => {
      return this.tagsPagination[SCREEN_TAGS[key]].page === 1;
    });

    Object.keys(SCREEN_TAGS).forEach(key => {
      const tagKey = SCREEN_TAGS[key];
      const tagPagination = this.tagsPagination[tagKey];
      const tagData = this.tagsData[tagKey];
      if (!tags[tagKey]) {
        return;
      }
      
      if (refresh) {
        tagsData[tagKey] = {
          ...tags[tagKey],
          hasMore: tags[tagKey].values.length >= PAGE_SIZE
        };
        
        return;
      }
      
      if (tagData.values.length < tagPagination.page * tagPagination.pageSize && tagData.hasMore) {
        tagsData[tagKey] = {
          ...tagData,
          values: [...tagData.values, ...tags[tagKey].values],
          hasMore: tags[tagKey].values.length >= PAGE_SIZE
        };
      } else {
        tagsData[tagKey] = tagData;
      }
    });

    this.tagsData = tagsData;

    this.tagsSource.next(this.tagsData);
  }

  setFilterByFieldSource(filter: FieldFilterRequest): void {
    if (filter) {
      let filterQueryParam = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
      filterQueryParam = filterQueryParam.set('page', <any>1);
      filterQueryParam = filterQueryParam.set('page_size', <any>1);
      const filterBody = JSON.parse(JSON.stringify(filter?.body));
      filterBody.isNeededAggregation = true;

      const filterRequest: FieldFilterRequest = {
        body: filterBody, queryParams: filterQueryParam, queryUrl: filter?.queryUrl
      }
      this.filterByFieldSource.next(filterRequest);
    }
  }

  nextTagsPage(tagKey: string) {
    if (!this.tagsPagination[tagKey] || !this.tagsData[tagKey].hasMore) {
      return;
    }

    this.tagsPagination = {
      ...this.tagsPagination,
      [tagKey]: {
        ...this.tagsPagination[tagKey],
        page: this.tagsPagination[tagKey].page + 1
      }
    };

    this.tagsPaginationSource.next(this.tagsPagination);
    this.getTagsNextPageSource.next(tagKey);
  }

  resetTagsPages() {
    this.tagsPagination = this._getDefaultTagsPagination();

    this.tagsPaginationSource.next(this.tagsPagination);
  }

  filterByField(filter: FieldFilterRequest): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    queryParameters = queryParameters.set('page', <any>1);
    queryParameters = queryParameters.set('page_size', <any>1);
    return this.apiService.post(filter.queryUrl, filter.body, queryParameters);
  }
  

  private _getDefaultTagsPagination(): ScreenTagsPagination {
    return Object.keys(SCREEN_TAGS).reduce((storage, key) => {
      storage[SCREEN_TAGS[key]] = {
        page: 1,
        pageSize: PAGE_SIZE
      };

      return storage;
    }, {});
  }

  private _getDefaultTagsData(): ScreensTagsSet {
    return Object.keys(SCREEN_TAGS).reduce((storage, key) => {
      storage[SCREEN_TAGS[key]] = {
        values: [],
        total: null,
        hasMore: true
      };

      return storage;
    }, {});
  }
}

