import { ComboChartData } from '@dooh/components-library';
import { CHART_LABELS, ChartResponse, BREAKDOWN } from './typings';

function getBarColor(name: CHART_LABELS, breakdown?: BREAKDOWN): string {
  switch (breakdown) {
    case BREAKDOWN.SCREEN_IMP_REV: return '#2969B0';
    case BREAKDOWN.SCREEN_IMP_PLA: return '#F2C947';
    case BREAKDOWN.PUBLISHER: return '#1CB848';
    case BREAKDOWN.CREATIVE: return '#F2C947';
    default: break;
  }

  switch (name) {
    case CHART_LABELS.IMPRESSIONS: return '#1CB848';
    case CHART_LABELS.REVENUE: return '#2969B0';
    case CHART_LABELS.ECMP: return '#013E6D';
    default: return '#1CB848';
  }
}

function transformToChartData(
  data: ChartResponse,
  leftLabel: CHART_LABELS,
  rightLabel?: CHART_LABELS,
  breakdown?: BREAKDOWN,
): ComboChartData {
  if (!data.content[0]) {
    return null;
  }

  const results = [];
  const lineChart = data.content[0]?.value.line && rightLabel ?
    [{
      name: rightLabel,
      series: [],
    }] : null;
  const customColors = [{
    name: leftLabel,
    value: getBarColor(leftLabel, breakdown),
  }];

  data.content.map(entity => {
    results.push({
      name:  entity.id ? `${entity.name} (${entity.id})` : entity.name,
      series: [{
        name: leftLabel,
        value: entity.value.bar,
      }],
    });

    if (lineChart) {
      lineChart[0].series.push({
        name: entity.id ? `${entity.name} (${entity.id})` : entity.name,
        value: entity.value.line,
      });
    }
  });

  if (lineChart) {
    customColors.push({
      name: rightLabel,
      value: '#EE7947',
    });
  }

  return {
    xAxisLabel: leftLabel,
    yAxisLabelLeft: leftLabel,
    yAxisLabelRight: rightLabel,
    lineChart,
    results,
    customColors,
  };
};

export default transformToChartData;
