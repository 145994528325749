import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { AlertDialogData } from '@dooh/models';

@Component({
  selector: 'dooh-default-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent {
  constructor(
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogData
  ) {
    this.translateService.setDefaultLang('en');
  }
}
