import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

// This value corresponds to the height in the css file used to represent 1 hour cell.
const HOUR_TO_PX = 35.6;
const END_HOUR_SECONDS = ':59.999999999';

@Component({
  selector: 'dooh-week-day',
  templateUrl: './week-day.component.html',
  styleUrls: ['./week-day.component.scss']
})
export class WeekDayComponent implements OnInit, OnChanges {
  @Input() data?: any;
  @Input() editable?: boolean;
  timing: number[] = []

  @Output() addTime: EventEmitter<any>;
  @Output() toggleDay: EventEmitter<boolean>;
  @Output() isEditing: EventEmitter<any>;
  @Output() removeTime: EventEmitter<number>;
  @Output() copyTime: EventEmitter<number>;
  
  constructor() { 
    this.addTime = new EventEmitter<any>();
    this.removeTime = new EventEmitter<number>();
    this.copyTime = new EventEmitter<number>();
    this.toggleDay = new EventEmitter<boolean>();
    this.isEditing = new EventEmitter<any>();
  }

  ngOnInit(): void {
    for (let i = 0; i < 24; i++) {
      this.timing.push(i);
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
      if (changes['data'] && changes['data']?.currentValue) {
        this.data = changes['data']?.currentValue;
      }
      if (changes['editable'] && changes['editable']?.currentValue) {
        this.editable = changes['editable']?.currentValue;
      }
  }

  addTimeClick(time?: any): void {
    if (!this.editable || this.data?.disabled) return;
    const data = {
      day: this.data?.day
    }
    if (time) data['timeSlot'] = time;
    this.addTime.emit(data);
  }
  editTimeClick(index: number): void {
    if (this.editable) this.isEditing.emit(index);
  }
  copyTimeClick(index: number): void {
    this.copyTime.emit(index);
  }
  removeTimeClick(index: number): void {
    this.removeTime.emit(index);
  }
  preventDefault(e: any): void {
    e.stopPropagation();
  }

  toggleAvailability(): void {
    setTimeout(() => {
      this.data.disabled = !this.data.disabled;
      this.toggleDay.emit(!this.data.disabled);
    });
  }

  getSegmentHeight(data: {startTime: string, endTime: string}): string {
    const startTime = moment(data?.startTime + ':00', 'hh:mm:ss a')
    const endTime = moment(data?.endTime + END_HOUR_SECONDS, 'hh:mm:ss a')
    const duration = endTime.diff(startTime, 'minutes');
    const durationToHours = duration / 60;
    
    return `${+durationToHours.toFixed(1) * HOUR_TO_PX}px`
  }

  getSegmentOffset(time: any): string {
    const startOfDay = moment('00:00:00', 'hh:mm:ss a')
    const offsetTime = moment(time + END_HOUR_SECONDS, 'hh:mm:ss a')
    const duration = offsetTime.diff(startOfDay, 'minutes');
    const durationToHours = duration / 60;
    return `${+durationToHours.toFixed(1) * HOUR_TO_PX}`
  }

  formatTime(time: any, format: string): string {
    const formatTime = moment(time, 'hh:mm:ss a');
    return formatTime.format('hh:mm a')
  }

}
