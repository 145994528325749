/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LocalTime } from './localTime';

export interface DayScheduleTimeForm{
  endTime?: LocalTime;
  startTime?: LocalTime;
}
export interface DayScheduleForm {
    day?: string;
    time?: DayScheduleTimeForm[];
}
export namespace DayScheduleForm {
    export type DayEnum = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
    export const DayEnum = {
        MONDAY: 'MONDAY' as DayEnum,
        TUESDAY: 'TUESDAY' as DayEnum,
        WEDNESDAY: 'WEDNESDAY' as DayEnum,
        THURSDAY: 'THURSDAY' as DayEnum,
        FRIDAY: 'FRIDAY' as DayEnum,
        SATURDAY: 'SATURDAY' as DayEnum,
        SUNDAY: 'SUNDAY' as DayEnum
    };
}
