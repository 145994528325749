import {
  EventEmitter,
  Injectable,
  Injector,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import {
  MapService,
  PointOfInterestService,
  ScreenTagsPagination,
  TargetingFiltersService,
  TargetingsService,
} from '@dooh/common-services';
import {
  BboxObject,
  FilterScreenObject,
  ScreenTagView,
  ScreenView,
  VenueType,
} from '@dooh/models';
import { AudienceStateService } from 'libs/audience-library/src';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, first, take } from 'rxjs/operators';
import { DialogService } from '../../dialogs/dialog.service';
import { DmaStateService } from '../../services/dma-dialog.service';
import { ScreensApi } from '../screens-view.component.models';
import { ScreensViewService } from './screens-view.service';
import { HttpErrorResponse } from '@angular/common/http';

export const PAGE_SIZE = 50;
type SearchState = 'screenSearch' | 'areaSearch';
export const MAP_PAGE_SIZE = 1000;
@Injectable({
  providedIn: 'root',
})
export abstract class ScreensParentService {
  protected readonly SCREENS_TAB = 1;
  protected readonly PREVIEW_SCREENS_TAB = 0;

  public readonly SCREENS = {
    PREVIEW: 'preview',
    ADD: 'add',
  };
  protected readonly SCREENS_MAP = {
    [this.PREVIEW_SCREENS_TAB]: this.SCREENS.PREVIEW,
    [this.SCREENS_TAB]: this.SCREENS.ADD,
  };

  filtersForm: FormGroup;
  screenTagSearchField = new FormControl();

  isAddScreensRemoving = false;
  isAllSelected:boolean;

  addedScreens: ScreenView[] = [];
  deletedScreens: ScreenView[] = [];
  filterScreenObject: FilterScreenObject;
  tagsPagination: ScreenTagsPagination;
  currentTab: number = this.SCREENS_TAB;
  venueIconsMap = {
    '1': 'transit',
    '2': 'retail',
    '3': 'outdoor',
    '4': 'health',
    '5': 'care',
    '6': 'education',
    '7': 'offices',
    '8': 'leisure',
    '9': 'government',
    '10': 'financial',
    '11': 'residential',
  };
  activeScreenTags: ScreenTagView[];
  loadMoreTimer: any;
  disableSearch: boolean = true;
  selectedElements: Subject<{ action: string; items: string[] }> = new Subject<{
    action: string;
    items: string[];
  }>();
  disablingElements: Subject<{
    action: string;
    items: string[];
  }> = new Subject<{ action: string; items: string[] }>();
  searchState: SearchState = 'screenSearch';
  @Input()
  screensApi: ScreensApi;

  @Input()
  isCreating: boolean;

  @Input()
  isMapView?: boolean;

  @Output()
  isPaginated?: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @Output()
  expectedReachUpdated?: EventEmitter<number> = new EventEmitter<number>();

  mapPagination = {
    totalElements: 0,
    pageIndex: 0,
    pageSize: 0,
  };
  isMapPaginated: boolean;

  previewScreensChange?: EventEmitter<ScreenView[]> = new EventEmitter<
    ScreenView[]
  >();
  deletedScreensChange?: EventEmitter<ScreenView[]> = new EventEmitter<
    ScreenView[]
  >();
  addedScreensChange?: EventEmitter<ScreenView[]> = new EventEmitter<
    ScreenView[]
  >();
  @Output()
  packageSelectionChanged?: EventEmitter<string> = new EventEmitter<string>();
  audienceStateService: AudienceStateService;
  cancel: EventEmitter<void> = new EventEmitter<void>();
  save: EventEmitter<void> = new EventEmitter<void>();
  screenService: TargetingsService;
  screensViewService: ScreensViewService;
  expectedReach: number;
  firstRemoveFromAdd = -1;
  currentArea: any;
  hasAudience: boolean;
  dmaIds: string[] = [];
  screenType:string = 'preview'
  disableScreenPropertiesButton: boolean;
  allVenueTypes: VenueType[] = [];
  flatVenueType: any;
  selectedScreenIds: any[];
  addedScreensIds: any;
  packageId: string;
  isPackageScreenSearched: boolean;
  offerConditions: any;
  constructor(
    public mapService: MapService,
    public targetingFiltersService: TargetingFiltersService,
    public dialogService: DialogService,
    private injector: Injector,
    public dmaService: DmaStateService,
  ) {
    this.screenService = this.injector.get<TargetingsService>(
      TargetingsService
    );
    this.screensViewService = this.injector.get<ScreensViewService>(
      ScreensViewService
    );
    this.audienceStateService = this.injector.get<AudienceStateService>(
      AudienceStateService
    );
  }

  instantiateSharedData(): void {
    const sharedAddedScreen$ = this.screensViewService.sharedAddedScreen$;
    const sharedDeletedScreen$ = this.screensViewService.sharedDeletedScreen$;
    combineLatest([sharedAddedScreen$, sharedDeletedScreen$])
      .pipe(take(1))
      .subscribe(([addedScreens, deletedScreens]) => {
        if (addedScreens.length) {
          this.addScreens(addedScreens, this.SCREENS.ADD);
        }
        if (deletedScreens.length) {
          if (!this.isMapView) {
            this.removeScreens(deletedScreens, this.SCREENS.PREVIEW);
          } else {
            this.removeScreens(deletedScreens, this.SCREENS.ADD);
          }
        }
      });
  }
  private getTableConfig() {
    return {
      tableCustomizer: null,
      data: [],
      dataSource: new MatTableDataSource<ScreenView>(),
      currentPage: 1,
      totalElements: 0,
      totalPages: 0,
      sort: ['name,asc'],
      isLoading: false,
      subscription: null,
      selectedItems: [],
      isResetTable: false,
    };
  }

  selectAllMaster($event: { checked: boolean }, type: string) {
    this.isAllSelected = $event.checked;
    this.screens[type].selectedItems = this.isAllSelected ? this.screens[type].dataSource.data : [];
  }

  removeScreens(screens: ScreenView[], type: string) {
    const selectedScreenMap = screens.reduce((storage, screen) => {
      const id = screen?.screenId || screen?.id;
      storage[id] = true;
      return storage;
    }, {});
    if (this.screens.add.data.length === 0 && type === this.SCREENS.ADD) {
      this.screens.add.data = [...this.screens.preview.data];
    }
    this.screens[type].selectedItems = this.screens[type].selectedItems.filter(
      (screen) => !selectedScreenMap[screen.screenId || screen.id]
    );
    this.addedScreens = this.addedScreens.filter((screen) => {
      const id = screen.screenId || screen.id;
      if (selectedScreenMap[id]) {
        return false;
      }
      return true;
    });

    this.deletedScreens = [
      ...this.deletedScreens,
      ...screens.filter((screen, index) => {
        const id = screen?.screenId || screen?.id;
        return selectedScreenMap[id] && screen.hasRelation;
      }),
    ];

    if (type === 'preview' && !this.isMapView) {
      this.screens[type].dataSource.data.map((screen, index) => {
        this.deletedScreens.map((deletedScreen) => {
          if (screen.id === deletedScreen.id) {
            this.screens[type].dataSource.data.splice(index, 1);
          }
        });
      });
    }
    this.addedScreensChange.emit(this.addedScreens);
    this.deletedScreensChange.emit(this.deletedScreens);

    this.screensViewService.setSharedAddedScreen(this.addedScreens);
    this.screensViewService.setSharedDeletedScreen(this.deletedScreens);

    this.screens[this.SCREENS.PREVIEW].syncDataSource();
    this.screens[this.SCREENS.ADD].syncDataSource();

    this.screens[type].updateMarkerIcons();
  }

  getScreenFilters() {
    this.targetingFiltersService.tagsPagination$
      .pipe(first())
      .subscribe((tagsPagination) => {
        this.tagsPagination = tagsPagination;
      });

      const screenTags: ScreenTagView[] = JSON.parse(JSON.stringify(this.activeScreenTags));

      this.filterScreenObject.tags.forEach(tagItem => {
        const screenTag: ScreenTagView = {
          tag: tagItem.name,
          id: tagItem.properties
        }
        screenTags.push(screenTag);
      });

    this.screensApi
      .getScreenFilters({
        screenTags: screenTags,
        pois: this.filterScreenObject.pois,
        pagination: this.tagsPagination,
        bboxes: this.filterScreenObject.bboxes,
        placeIq: this.filterScreenObject.placeIq,
        postalCodes:this.filterScreenObject.postalCodes
      })
      .subscribe((result) => {
        this.targetingFiltersService.setLimits(result.limits);
        this.targetingFiltersService.setTags(result.tags);

        this.checkScreenPropertiesButton(result?.tags);

      });
  }

  addScreens(screens: ScreenView[], type: string) {
    const selectedScreenMap = screens.reduce((storage, screen) => {
      storage[screen.id] = true;
      return storage;
    }, {});
    const previewScreenMap = this.screens.preview.data.reduce(
      (storage, screen) => {
        storage[screen.id] = true;
        return storage;
      },
      {}
    );

    const screensThatwasDeletedButAddedBack = {};
    this.screens[type].selectedItems = this.screens[type].selectedItems.filter(
      (screen) => !selectedScreenMap[screen.id]
    );
    this.deletedScreens = this.deletedScreens.filter((screen) => {
      const id = screen?.screenId || screen?.id;
      if (selectedScreenMap[id]) {
        screensThatwasDeletedButAddedBack[id] = true;
        selectedScreenMap[id] = false;
        return false;
      }
      return true;
    });

    this.addedScreens = [
      ...this.addedScreens,
      ...screens.filter((screen) => {
        const id = screen?.screenId || screen?.id;
        if (!previewScreenMap[id] && !screensThatwasDeletedButAddedBack[id]) {
          return true;
        }
        return false;
      }),
    ];
    this.screensViewService.setSharedAddedScreen(this.addedScreens);
    this.screensViewService.setSharedDeletedScreen(this.deletedScreens);
    this.addedScreensChange.emit(this.addedScreens);
    this.deletedScreensChange.emit(this.deletedScreens);
    this.screens[this.SCREENS.PREVIEW].syncDataSource();
    this.screens[this.SCREENS.ADD].syncDataSource();

    this.screens[type].updateMarkerIcons();
  }

  screensConfiguration(location?: any, removeFilter?: boolean) {
    this.activeScreenTags = this.filtersForm.get('screenTagSearchField').value;
    this.checkPackageSelect(this.activeScreenTags);
    this.onFiltersReset();
    this.targetingFiltersService.resetTagsPages();

    let placeBbox: BboxObject[] = null;
    const checkIfPlaceSelected = this.activeScreenTags.some(
      (item) => item?.tag === 'Place'
    );
    if (checkIfPlaceSelected) {
      placeBbox = [];
      this.activeScreenTags.forEach((item) => {
        if (item?.tag === 'Place') {
          placeBbox.push(item?.area);
        }
      });
    }

    const tagsMapByName = this.activeScreenTags.reduce((obj: Object, item) => {
      if (!item) {
        return obj;
      }

      if (item.tag === 'Place') {
        const countryTag = 'country';
        if (!obj[countryTag]) {
          obj[countryTag] = {
            name: countryTag,
            properties: [],
          };
          obj[countryTag].properties.push(item.country);
        }
        return obj;
      }
      const tag = item.tag;
      if (!obj[tag]) {
        obj[tag] = {
          name: tag,
          properties: [],
        };
      }
      obj[tag].properties.push(item.id);
      return obj;
    }, {});

    const filtersTags = [];
    if (tagsMapByName) filtersTags.push(Object.values(tagsMapByName));
    if (location) filtersTags.push([location]);

    this.filterScreenObject = {
      tags: Object.values(tagsMapByName),
      bboxes: placeBbox
    };

    this.audienceStateService.resetAudienceData();
    if (this.offerConditions) {
      const { publishers, resolutions, venueTypes } = this.offerConditions

      if (publishers) {
        const publisherNames = [];
        const sspNames = [];
        publishers.forEach(publisher => {
          publisherNames.push(publisher.name);
          sspNames.push(publisher.sspName);
        })
        const publisherTag = {name: 'publisher', properties: [...publisherNames]}
        this.filterScreenObject?.tags.push(publisherTag);

        const sspTag = {name: 'ssp', properties: [...sspNames]}
        this.filterScreenObject?.tags.push(sspTag);
      }
      if (resolutions) {
        const resolutionStrs = [];
        resolutions.forEach(resolution => {
          resolutionStrs.push(`${resolution?.width}x${resolution?.height}`);
        })
        const resolutionTag = {name: 'resolution', properties: [...resolutionStrs]}
        this.filterScreenObject?.tags.push(resolutionTag);
      }
      if (venueTypes) {
        const venueTypeIds = [];
        let venueTypeWithChildren: VenueType[] = [];
        venueTypes.forEach(venueType => {
          venueTypeWithChildren = [...venueTypeWithChildren, ...this.getVenueTypeChildren(venueType?.id)]
        });
        venueTypeWithChildren.forEach(venue => {
          venueTypeIds.push(venue?.enumeration_id);
        })

        const nonDuplicateVenueTypeId = [... new Set(venueTypeIds)]
        const venueTypeTag = { name: 'venue', properties: [...nonDuplicateVenueTypeId] }
        this.getVenueTypeChildren(2);
        this.filterScreenObject.tags.push(venueTypeTag)
      }

      if (removeFilter) {
        this.targetingFiltersService.screenPropertiesFilters$
        .pipe(take(1))
        .subscribe(
          (filters: {
            limits: {
              floorPrice: { min: number; max: number };
              audiencePerWeek: { min: number; max: number };
            };
            tags: Array<{ name: string; properties: string[] }>;
          }) => {
            if (filters) {
              const filterTagsMapByName = (filters?.tags || []).reduce(
                (obj, item) => {
                  if (!item) {
                    return obj;
                  }
                  obj[item.name] = { ...item };
                  return obj;
                },
                {}
              );
              const tagsMapByName = (this.activeScreenTags || []).reduce(
                (obj, item) => {
                  if (!item || item.tag === 'Place') {
                    return obj;
                  }
                  const tag = item.tag;
                  if (!obj[tag]) {
                    obj[tag] = {
                      name: tag,
                      properties: [],
                    };
                  }
                  obj[tag].properties.push(item.id);
                  return obj;
                },
                filterTagsMapByName
              );

              Object.keys(tagsMapByName).forEach((tagName) => {
                const index = this.filterScreenObject?.tags.findIndex(
                  (tag) => tag.name === tagName
                );

                if (index === -1) {
                  this.filterScreenObject.tags.push(tagsMapByName[tagName]);
                } else {
                  this.filterScreenObject.tags[index] = tagsMapByName[tagName];
                }
              });

              if (this.dmaIds?.length > 0) {
                const index = this.filterScreenObject?.tags.findIndex(
                  (tag) => tag.name === 'dmaIds'
                );
                if (index === -1) {
                  this.filterScreenObject.tags.push({
                    name: 'dmaId',
                    properties: this.dmaIds,
                  });
                } else {
                  this.filterScreenObject.tags[index] = {
                    name: 'dmaId',
                    properties: this.dmaIds,
                  };
                }
              }
              this.filterScreenObject.limits = filters.limits;
            }
          }
        );
        this.targetingFiltersService.placeIqFilters$.pipe(take(1)).subscribe(res => {
          this.filterScreenObject['placeIq'] = res;
        })

        this.targetingFiltersService.poiFilters$.pipe(take(1)).subscribe(
          (filters: { type: string; pois: any; radius: number; zipCodes: any }) => {
            if (filters) {
              this.filterScreenObject.pois = {
                points: [...filters.pois],
                radius: filters.radius,
              };
            }


          }
        );
      }


    }

    location = null;
    this.checkPackageSelect(this.activeScreenTags);
    this.getScreens(this.SCREENS.ADD, true, true);
  }

  onSearchByTags() {
    this.filterScreenObject = null;
    this.targetingFiltersService.setPoiStatus(false);
    this.targetingFiltersService.setScreenPropertiesStatus(false);
    this.mapService.setPoisMapData([]);
    this.mapService.syncSearchField(
      {data: this.filtersForm.get('screenTagSearchField').value, removeFilter: true}
    );
    this.dmaIds = [];
    this.targetingFiltersService.setDmaFilters(null);
  }

  getScreens(
    type: string,
    refresh?: boolean,
    updateFilters?: boolean,
    pageStrategy = false,
    changePage?: boolean
  ) {
    this.screenType = type;
    if (this.screens[type].isLoading) {
      this.screens[type].subscription.unsubscribe();
    }

    this.screens[type].isLoading = true;

    if (refresh || this.searchState === 'areaSearch') {
      if (!changePage) {
        this.screens[type].currentPage = 1;
      }
      this.screens[type].isResetTable = true;
    }
    // first remove markers on screen
    this.mapService.setData([]);
    this.screens[type].apiCall(refresh, updateFilters, pageStrategy,type);
    this.searchState = 'screenSearch';
  }

  onFiltersReset() {
    // this.targetingFiltersService.setPoiStatus(false);
    // this.targetingFiltersService.setScreenPropertiesStatus(false);
    // this.mapService.setPoisMapData([]);
    this.screens[this.SCREENS.ADD].selectedItems = [];
  }

  checkScreenPropertiesButton(tags: any[]): void {
    const hasVenueType = tags['venue_agg_result']?.values?.length > 0
    const hasPublisher = tags['publisher_agg_result']?.values?.length > 0
    const hasResolution = tags['resolution.name_agg_result']?.values?.length > 0

    if (!hasVenueType && !hasPublisher && !hasResolution) {
      this.disableScreenPropertiesButton = true;
    } else {
      this.disableScreenPropertiesButton = false
    }
  }

  changeTab($event) {
    this.currentTab = $event.index;
  }

  resetSharedData(): void {
    this.screensViewService.resetSharedData();
  }

  onReset() {
    this.activeScreenTags = [];
    this.filtersForm.reset();
    this.filtersForm.patchValue({screenTagSearchField: []});

    this.mapService.syncSearchField({data: this.filtersForm.get('screenTagSearchField').value, removeFilter: true});
    this.onFiltersReset();
    this.onAddScreensReset();

    this.mapService.setData(this.screens[this.SCREENS.PREVIEW].dataSource.data);

    this.addedScreens = [];
    this.deletedScreens = [];
    this.addedScreensChange.emit(this.addedScreens);
    this.deletedScreensChange.emit(this.deletedScreens);
  }

  onAddScreensReset() {
    this.screens[this.SCREENS.ADD] = {
      ...this.screens[this.SCREENS.ADD],
      currentPage: 1,
      pageSize: this.isMapView ? MAP_PAGE_SIZE : PAGE_SIZE,
      totalElements: 0,
      totalPages: 0,
      isLoading: false,
      selectedItems: [],
    };
    this.screens[this.SCREENS.ADD].dataSource.data = [];
    this.mapService.setData([]);
  }

  onCancel() {
    if (this.canDeactivate()) {
      this.onCancelConfirm();
    } else {
      this.dialogService
        .alert({
          type: 'warning',
          message: 'screensTab.dialog.cancel.message',
          submitButtonText: 'screensTab.dialog.cancel.submitButton',
          cancelButtonText: 'screensTab.dialog.cancel.cancelButton',
        })
        .afterClosed()
        .subscribe((res) => {
          if (!res) return;
          this.onCancelConfirm();
        });
    }
  }

  onCancelConfirm() {
    this.onReset();
    this.resetSharedData();
    this.mapService.setPoisMapData([]);
    this.cancel.emit();
    this.getScreens(this.SCREENS.PREVIEW, true);
  }

  canDeactivate(): boolean {
    return !this.addedScreens.length && !this.deletedScreens.length;
  }

  screens = {
    [this.SCREENS.PREVIEW]: {
      ...this.getTableConfig(),
      pageSize: PAGE_SIZE,
      apiCall: (
        refresh?: boolean,
        _updateFilters?: boolean,
        pageStrategy = false,
        type?:any
      ) => {
        this.screens.preview.subscription = this.screensApi
          .getPreviewScreens(
            this.screens.preview.currentPage,
            this.screens.preview.pageSize !== null
              ? this.screens.preview.pageSize
              : this.isMapView
              ? MAP_PAGE_SIZE
              : PAGE_SIZE,
            this.screens.preview.sort
          )
          .subscribe((result) => {
            this.screens.preview.data =
              refresh || pageStrategy
                ? result.content
                : [...this.screens.preview.data, ...result.content];
            this.screens.preview.totalElements = result.totalElements;
            this.screens.preview.data.forEach((screen) => {
              screen.hasRelation = true;
            });
            this.expectedReach = this.screens.preview.data.reduce(
              (sum, screen) => {
                sum += screen['audiencePerWeek'];
                return sum;
              },
              0
            );
            this.expectedReachUpdated.emit(this.expectedReach);
            this.screens.preview.totalPages = result.totalPages;
            const pageSize = this.isMapView ? MAP_PAGE_SIZE : PAGE_SIZE;

            if (result.totalElements > pageSize) {
              this.isMapPaginated = true;
              this.setMapPagination(
                result.totalElements,
                this.screens.preview.currentPage,
                this.screens.preview.pageSize
              );
            } else {
              this.isMapPaginated = false;
              this.setMapPagination(0, 0, 0);
            }
            this.isPaginated.emit(this.isMapPaginated);
            this.screens.preview.syncDataSource();
            this.mapService.setData(this.screens.preview.dataSource.data);
            this.screens.preview.updateMarkerIcons();
            this.screens.preview.isResetTable = false;
            this.screens.preview.isLoading = false;
            this.mapService.setActiveGeoSearchButton(false);

            const check = {checked:this.isAllSelected};
            this.selectAllMaster(check,this.SCREENS.PREVIEW);
          },
          (err)=>{
            this.screens[type].isLoading = false;
            let message = 'Something went wrong. Please try again.'
            if(!navigator.onLine) {
              message ='Lost connection';
            }
            if (err?.error?.systemMessage) {
              message = err?.error?.systemMessage;
            }
            this.dialogService.alert({
              type: 'error',
              message
            });
          });
      },
      syncDataSource: () => {
        this.selectedElements.next({
          action: 'RESET',
          items: [],
        });
        const deletedScreensMap = this.deletedScreens.reduce(
          (storage, screen) => {
            storage[screen.screenId] = true;
            return storage;
          },
          {}
        );
        const alreadySavedPreviewScreens = this.screens.preview.data.reduce(
          (storage, screen) => {
            storage[screen.id] = true;
            return storage;
          },
          {}
        );
        this.screens.preview.dataSource.data = [
          ...this.addedScreens.filter(
            (screen) => !alreadySavedPreviewScreens[screen.relationId]
          ),
          ...this.screens.preview.data.filter(
            (screen) => !deletedScreensMap[screen.screenId]
          ),
        ];
        this.previewScreensChange.emit(this.screens.preview.dataSource.data);
      },
      updateMarkerIcons: () => {
        this.mapService.setMarkerIconMap({
          ...this.screens.preview.data.reduce((storage, screen) => {
            if (screen.venueType && screen.venueType.length) {
              if (screen.venueType[0].id.length % 2 === 0) {
                storage[screen.id] = `/assets/icons/ico-marker-${
                  this.venueIconsMap[screen.venueType[0].id.substring(0, 2)]
                }-added.svg`;
              } else {
                storage[screen.id] = `/assets/icons/ico-marker-${
                  this.venueIconsMap[screen.venueType[0].id[0]]
                }-added.svg`;
              }
            } else {
              storage[screen.id] = '/assets/icons/ico-marker-added-new.svg';
            }
            return storage;
          }, {}),
          ...this.addedScreens.reduce((storage, screen) => {
            if (screen.venueType && screen.venueType.length) {
              if (screen.venueType[0].id.length % 2 === 0) {
                storage[screen.id] = `/assets/icons/ico-marker-${
                  this.venueIconsMap[screen.venueType[0].id.substring(0, 2)]
                }-added.svg`;
              } else {
                storage[screen.id] = `/assets/icons/ico-marker-${
                  this.venueIconsMap[screen.venueType[0].id[0]]
                }-added.svg`;
              }
            } else {
              storage[screen.id] = '/assets/icons/ico-marker-added-new.svg';
            }
            return storage;
          }, {}),
          ...this.screens.preview.selectedItems.reduce((storage, screen) => {
            if (screen.venueType && screen.venueType.length) {
              if (screen.venueType[0].id.length % 2 === 0) {
                storage[screen.id] = `/assets/icons/ico-marker-${
                  this.venueIconsMap[screen.venueType[0].id.substring(0, 2)]
                }-selected.svg`;
              } else {
                storage[screen.id] = `/assets/icons/ico-marker-${
                  this.venueIconsMap[screen.venueType[0].id[0]]
                }-selected.svg`;
              }
            } else {
              storage[screen.id] = '/assets/icons/ico-marker-selected-new.svg';
            }
            return storage;
          }, {}),
        });
        this.mapService.setMarkerTypeMap({
          ...this.screens.preview.data.reduce((storage, screen) => {
            const id = screen?.screenId || screen?.id;
            storage[id] = 'added';
            return storage;
          }, {}),
          ...this.addedScreens.reduce((storage, screen) => {
            const id = screen?.screenId || screen?.id;
            storage[id] = 'added';
            return storage;
          }, {}),
          ...this.screens.preview.selectedItems.reduce((storage, screen) => {
            const id = screen?.screenId || screen?.id;
            storage[id] = 'added';
            return storage;
          }, {}),
        });
      },
      addScreenFromMap: () => {},
      removeScreenFromMap: (removedScreen) => {
        this.removeScreens([removedScreen], this.SCREENS.PREVIEW);
      },
    },
    [this.SCREENS.ADD]: {
      ...this.getTableConfig(),
      pageSize: MAP_PAGE_SIZE,
      apiCall: (
        refresh?: boolean,
        updateFilters?: boolean,
        pageStrategy = false
      ) => {
        this.screens.add.subscription = this.screensApi
        .getAvailableScreens(
          this.filterScreenObject,
          this.screens.add.currentPage,
          this.screens.add.pageSize !== null
            ? this.screens.add.pageSize
            : this.isMapView
            ? MAP_PAGE_SIZE
            : PAGE_SIZE,
          this.screens.add.sort
        )
          .subscribe((result) => {
            if (refresh) this.screens.add.data = [];
            this.screens.add.data =
              refresh || pageStrategy
                ? result?.screens.content
                : [...this.screens.add.data, ...result?.screens.content];
            this.screens.add.totalElements = result?.screens.totalElements;
            this.screens.add.totalPages = result?.screens.totalPages;
              if(this.filterScreenObject?.placeIq && refresh){
                this.screens.add.data.forEach(screen=>screen.fromPlaceIQ = true);
              // SET DMA AGGREGATE DATA
              if(this.dmaIds?.length < 1) {
                this.dmaService.setDmaAggData(result?.tags['dma_agg_result']?.values);
              }
              if(!this.filterScreenObject?.placeIq){
                this.hasAudience = result?.tags['place_iq.key_agg_result']?.values.length > 0;
                this.audienceStateService.setAudienceAggData(
                  result?.tags['place_iq.key_agg_result']?.values
                );
              }
              if (this.filterScreenObject?.placeIq) {
                Object.keys(this.filterScreenObject.placeIq).forEach((key: string) => {
                  if (this.filterScreenObject.placeIq[key]?.length > 0) {
                    this.hasAudience = true;
                  }
                })
              }
}
            // SET DMA AGGREGATE DATA
            if(this.dmaIds.length < 1 && this.screens.add.currentPage === 1) {
              this.dmaService.setDmaAggData(result.tags['dma_agg_result']?.values);
            }
            if(!this.filterScreenObject.placeIq && this.screens.add.currentPage === 1){
              this.hasAudience = result.tags['place_iq.key_agg_result']?.values.length > 0;
              this.audienceStateService.setAudienceAggData(
                result.tags['place_iq.key_agg_result']?.values
              );
            }
            if (this.filterScreenObject.placeIq && this.screens.add.currentPage === 1) {
              Object.keys(this.filterScreenObject.placeIq).forEach((key: string) => {
                if (this.filterScreenObject.placeIq[key]?.length > 0) {
                  this.hasAudience = true;
                }
              })
            }
            if (this.isPackageScreenSearched) {
              setTimeout(() => {
                this.screens.add.data.forEach( screen => screen.packageId = this.packageId);
              });
            }

            const pageSize = this.isMapView ? MAP_PAGE_SIZE : PAGE_SIZE;
            if (result?.screens.totalElements > pageSize) {
              this.isMapPaginated = true;
              this.setMapPagination(
                result?.screens.totalElements,
                this.screens.add.currentPage,
                this.screens.add.pageSize
              );
            } else {
              this.isMapPaginated = false;
              this.setMapPagination(0, 0, 0);
            }

            // this.addedScreens = this.screens.add.data.filter(screen=>screen.hasRelation);
            this.isPaginated.emit(this.isMapPaginated);
            this.screens.add.syncDataSource();
            this.screens.add.isLoading = false;
            this.mapService.setData(this.screens.add.dataSource.data);
            this.mapService.setAddScreensonVisibleMap(
              this.screens.add.dataSource.data
            );
            this.screens.add.updateMarkerIcons();
            this.screens.add.isResetTable = false;
            this.mapService.setActiveGeoSearchButton(true);
            this.mapService.addScreensOnVisibleMap$
              .pipe(take(1))
              .subscribe((displayData: any) => {
                this.screens.add.dataSource.data = [];
                this.screens.add.dataSource.data = displayData;
                this.screens.add.isLoading = false;
              });

              if (!this.screensApi.getScreenFilters || !updateFilters) {
                return;
              }
              this.targetingFiltersService.setTags(result?.tags);
              this.targetingFiltersService.setLimits(result.limits);
          },(err)=>{
            this.screens.add.isLoading = false;
            let message = 'Something went wrong. Please try again.'
            if(!navigator.onLine) {
              message ='Lost connection';
            }
            if (err?.error?.systemMessage) {
              message = err?.error?.systemMessage;
            }
            this.dialogService.alert({
              type: 'error',
              message
            });
          });

        // if (!this.screensApi.getScreenFilters || !updateFilters) {
        //   return;
        // }

        // this.getScreenFilters();
      },
      syncDataSource: () => {
        this.screens.add.dataSource.data = [];
        this.screens.add.dataSource.data = [...this.screens.add.data];
        this.selectedElements.next({
          action: 'RESET',
          items: [],
        });
        this.disablingElements.next({
          action: 'RESET',
          items: [],
        });

        const {
          deletedScreenIds,
          deletedScreensMap,
        } = this.deletedScreens.reduce(
          (storage, screen) => {
            storage.deletedScreenIds.push(screen.screenId);
            storage.deletedScreensMap[screen.screenId] = true;
            return storage;
          },
          { deletedScreenIds: [], deletedScreensMap: {} }
        );

        this.selectedElements.next({
          action: 'DESELECT',
          items: deletedScreenIds,
        });
        this.disablingElements.next({
          action: 'ENABLE',
          items: deletedScreenIds,
        });

        this.addedScreensIds = this.screens.add.data.reduce(
          (storage, screen) => {
            if (screen.hasRelation && !deletedScreensMap[screen.id]) {
              storage.push(screen.id);
            }
            return storage;
          },
          this.addedScreens.map((screen) => screen.id)
        );
        this.selectedScreenIds = [
          ...this.addedScreensIds,
          ...this.screens[this.SCREENS.ADD].selectedItems.map(
            (screen) => screen.id
          ),
        ];

        this.selectedElements.next({
          action: 'SELECT',
          items: this.selectedScreenIds,
        });
        this.disablingElements.next({
          action: 'DISABLE',
          items: this.addedScreensIds,
        });
      },
      updateMarkerIcons: () => {
        const deletedScreensMap = this.deletedScreens.reduce(
          (storage, screen) => {
            const id = screen?.screenId || screen?.id;
            storage[id] = true;
            return storage;
          },
          {}
        );
        this.mapService.setMarkerIconMap({
          ...this.screens.add.data.reduce((storage, screen) => {
            const id = screen?.screenId || screen?.id;
            if (screen.hasRelation && !deletedScreensMap[id]) {
              // if screens saved
              if (screen.venueType && screen.venueType.length) {
                // check for venue type to add icon on the marker
                if (screen.venueType[0].id.length % 2 === 0) {
                  // if venue type is 10 or 11
                  storage[screen.id] = `/assets/icons/ico-marker-${
                    this.venueIconsMap[screen.venueType[0].id.substring(0, 2)]
                  }-added.svg`;
                } else {
                  // if venue type between 1-9
                  storage[screen.id] = `/assets/icons/ico-marker-${
                    this.venueIconsMap[screen.venueType[0].id[0]]
                  }-added.svg`;
                }
              } else {
                storage[screen.id] = '/assets/icons/ico-marker-added-new.svg';
              }
            } else {
              // if screens not saved
              if (screen.venueType && screen.venueType.length) {
                if (screen.venueType[0].id.length % 2 === 0) {
                  storage[screen.id] = `/assets/icons/ico-marker-${
                    this.venueIconsMap[screen.venueType[0].id.substring(0, 2)]
                  }.svg`;
                } else {
                  storage[screen.id] = `/assets/icons/ico-marker-${
                    this.venueIconsMap[screen.venueType[0].id[0]]
                  }.svg`;
                }
              } else {
                storage[screen.id] = `/assets/icons/ico-marker-new.svg`;
              }
            }
            return storage;
          }, {}),
          ...this.addedScreens.reduce((storage, screen) => {
            if (screen.venueType && screen.venueType.length) {
              if (screen.venueType[0].id.length % 2 === 0) {
                storage[screen.id] = `/assets/icons/ico-marker-${
                  this.venueIconsMap[screen.venueType[0].id.substring(0, 2)]
                }-added.svg`;
              } else {
                storage[screen.id] = `/assets/icons/ico-marker-${
                  this.venueIconsMap[screen.venueType[0].id[0]]
                }-added.svg`;
              }
            } else {
              storage[screen.id] = '/assets/icons/ico-marker-added-new.svg';
            }
            return storage;
          }, {}),
          ...this.screens.add.selectedItems.reduce((storage, screen) => {
            if (screen.venueType && screen.venueType.length) {
              if (screen.venueType[0].id.length % 2 === 0) {
                storage[screen.id] = `/assets/icons/ico-marker-${
                  this.venueIconsMap[screen.venueType[0].id.substring(0, 2)]
                }-selected.svg`;
              } else {
                storage[screen.id] = `/assets/icons/ico-marker-${
                  this.venueIconsMap[screen.venueType[0].id[0]]
                }-selected.svg`;
              }
            } else {
              storage[screen.id] = '/assets/icons/ico-marker-selected-new.svg';
            }
            return storage;
          }, {}),
        });
        this.mapService.setMarkerTypeMap({
          ...this.screens.add.data.reduce((storage, screen) => {
            const id = screen?.screenId || screen?.id;
            if (screen.hasRelation && !deletedScreensMap[id]) {
              storage[id] = 'added';
            }
            return storage;
          }, {}),
          ...this.addedScreens.reduce((storage, screen) => {
            const id = screen?.screenId || screen?.id;
            storage[id] = 'added';
            return storage;
          }, {}),
        });
      },
      addScreenFromMap: (addedScreen) => {
        if (addedScreen) {
          this.addScreens([addedScreen], this.SCREENS.ADD);
        }
      },
      removeScreenFromMap: (removedScreen) => {
        if (removedScreen) {
          if (removedScreen?.hasRelation) {
            removedScreen =
              this.screens[this.SCREENS.PREVIEW].data.find(
                (screen) => screen.id === removedScreen?.relationId
              ) || removedScreen;
          }
          this.isAddScreensRemoving = true;
          this.removeScreens([removedScreen], this.SCREENS.ADD);
        }
      },
    },
  };

  changePage($event) {
    if(this.screenType === 'add'){
      this.screens.add.currentPage = $event.currentPage;
      const PageSizeChanged = this.screens.add.pageSize !== $event.pageSize;
      this.screens.add.pageSize = $event.pageSize;
      if (this.searchState === 'screenSearch') {
        this.getScreens(this.SCREENS.ADD, PageSizeChanged, false, true);
      } else {
        this.areaSearch(this.currentArea, $event.currentPage);
      }
    }else if( this.screenType === 'preview'){
      const pageSize = this.isMapView ? MAP_PAGE_SIZE : PAGE_SIZE;
      this.screens.preview.currentPage = $event.currentPage;
      const PageSizeChanged =  this.screens.preview.pageSize !== $event.pageSize;
      this.screens.preview.pageSize = $event.pageSize;
      this.getScreens(this.SCREENS.PREVIEW, PageSizeChanged, false, true);
    }
  }

  areaSearch($event, page = 1) {
    this.searchState = 'areaSearch';
    this.currentArea = $event;
    this.filtersForm.reset();
    this.filtersForm.patchValue({ screenTagSearchField: [] });
    this.screens.add.isLoading = true;

    this.screenService
      .getScreensByArea($event, page, this.screens.add.pageSize)
      .subscribe(
        (data) => {
          this.mapService.setData(data.screens.content);
          if (data.screens.totalElements > this.screens.add.pageSize) {
            this.isMapPaginated = true;
            this.setMapPagination(
              data.screens.totalElements,
              page,
              this.screens.add.pageSize
            );
          }
          this.mapService.setAddScreensonVisibleMap(data.screens.content);
          this.screens.add.isLoading = false;
        },
        (error) => {
          this.screens.add.isLoading = false;
        }
      );
  }
  public setMapPagination(total, pageIndex, pageSize): void {
    this.mapPagination = {
      totalElements: total,
      pageIndex: pageIndex,
      pageSize: pageSize,
    };
  }
  checkAllowSearch($event): void {
    this.disableSearch = !$event;
  }

  openScreenProperties() {
    this.targetingFiltersService.toggleScreenProperties(true);
  }

  updateIsMapView(value): void {
    this.isMapView = value;
    this.getTableConfig();
  }

  getAllVenueTypes(): void {
    this.screenService
      .getAllVenueTypes()
      .subscribe((res) => {
        this.allVenueTypes = res.venueTypes.categories || [];
        this.flatVenueType = this.flattenAllVenueTypes(this.allVenueTypes);
      });
  }

  flattenAllVenueTypes(members) {
    let children = [];

    return members.map(m => {
      if (m.children && m.children.length) {
        children = [...children, ...m.children];
      }
      return m;
    }).concat(children.length ? this.flattenAllVenueTypes(children) : children);
  };

  getVenueTypeName(id){
    let parent = this.getVenueTypeParentName(id);
    return parent
  }

  getVenueTypeParentName(id){
    if(id){
      if(id.length % 2 == 0){
        const first2Str = String(id).slice(0, 2);
        const first2Num = Number(first2Str);
        let venueType = this.allVenueTypes?.filter((venue)=>{
          return venue.enumeration_id === first2Num
        })
        let venueTypeChild = this.flatVenueType?.filter((venue)=>{
          return venue.enumeration_id == id
        })
        if(venueType){
          if(!venueTypeChild){
            return `${venueType[0]?.name?.replace(/&amp;/g, "&")}`;
          }
          return `${venueType[0]?.name?.replace(/&amp;/g, "&")}/${venueTypeChild[0]?.name}`;

        }else{
          return ''
        }
      }else if(id.length % 2 !== 0){
        const firstNum =  Number(String(id)[0])
        let venueType = this.allVenueTypes?.filter((venue)=>{
          return venue.enumeration_id === firstNum
        })
        let venueTypeChild2 = this.flatVenueType?.filter((venue2)=>{
          return venue2.enumeration_id == id
        })

        if(venueType){
          if(!venueTypeChild2){
            return `${venueType[0]?.name?.replace(/&amp;/g, "&")}`;
          }
          return `${venueType[0]?.name?.replace(/&amp;/g, "&")}/${venueTypeChild2[0]?.name}`;

        }else{
          return ''
        }

      }
      return '--'
    }

  }

  setPackageId(event: any): void {
    this.packageId = event;
  }
  setDetachPackageId(event: any): void {
    this.screensViewService.setPackageToDetach(event);
    if (event) this.removeSelectedPackageSelectedScreens(event);
  }
  setPackageToSearchScreen(event: any): void {
    const selectedPackage = event;
    selectedPackage.id = this.packageId;
    selectedPackage['tag'] = 'Package';
    this.isPackageScreenSearched = true;
    this.filtersForm.get('screenTagSearchField').patchValue([event]);
    this.currentTab = 1;
    this.onSearchByTags();
    this.screensViewService.setSharedPackageId(this.packageId);
  }

  checkPackageSelect(selectedOptions: any[]): void {
    const selectedPackages = selectedOptions.filter(val => val?.tag === 'Package');
    if (selectedPackages?.length > 1) {
      const firstPackegeIndex = selectedOptions.findIndex(val => val?.id === selectedPackages[0]?.id);
      const secondPackegeIndex = selectedOptions.findIndex(val => val?.id === selectedPackages[1]?.id);
      this.openPackageConfirmModal().afterClosed().pipe(take(1)).subscribe(res => {

        selectedOptions.splice(res ? secondPackegeIndex : firstPackegeIndex, 1);
        this.filtersForm.get('screenTagSearchField').patchValue([...selectedOptions]);
        this.packageId = selectedOptions.find(val => val?.tag === 'Package')?.id;
        this.isPackageScreenSearched = true;
        this.screensViewService.setSharedPackageId(this.packageId);
      })
    } else if (selectedPackages?.length === 1) {
      this.isPackageScreenSearched = true;
      this.packageId = selectedPackages[0]?.id;
      this.screensViewService.setSharedPackageId(this.packageId);
    } else {
      this.isPackageScreenSearched = false;
      this.screensViewService.setSharedPackageId(null);
    }
  }

  openPackageConfirmModal(): any {
    return this.dialogService.alert({
      type: 'warning',
      title: 'dialog.alert.title.sure',
      message: 'You can only use one package per campaign. Do you want to continue searching in the original package?',
      submitButtonText: 'dialog.alert.submitButtonText.yes',
      cancelButtonText: 'dialog.alert.cancelButtonText.no',
    })
  }

  removeSelectedPackageSelectedScreens(event: string): void {
     // Remove selected package screens
     const selected = this.addedScreens.filter(screen => screen?.packageId === event);

     if (selected?.length === 0) return

     this.addedScreens = this.addedScreens.filter(screen => screen?.packageId !== event);
     this.addedScreensChange.emit(this.addedScreens);

    this.screensViewService.setSharedAddedScreen(this.addedScreens);

    this.screens[this.SCREENS.PREVIEW].syncDataSource();
    this.screens[this.SCREENS.ADD].syncDataSource();

    this.screens[this.SCREENS.PREVIEW].updateMarkerIcons();
    this.screens[this.SCREENS.ADD].updateMarkerIcons();


    this.dialogService.alert({
      type: 'success',
      title: 'Screens removed',
      message: 'Previously selected package screens has been removed.',
      submitButtonText: 'Ok',
      isCentered: true
    })
  }

  getVenueTypeChildren(venueTypeId: number): VenueType[] {
    const venueType = this.flatVenueType.find(venue => venue?.enumeration_id === +venueTypeId);
    return venueType?.children?.length > 0 ? this.flattenAllVenueTypes(venueType?.children) : [venueType];
  }
}
