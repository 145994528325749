import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService, CustomHttpUrlEncodingCodec } from '@dooh/api';
import { WebsocketidView } from '@dooh/models';

@Injectable({
  providedIn: 'root'
})
export class NotificationChannelIdService {

  static channelId = '';

  constructor(
    private apiService: ApiService,
  ) { }

  getListQueryParams = (page: number, pageSize: number, sort?: Array<string>, filters?: Array<string>) => {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }

    if (sort && sort.length) {
      queryParameters = queryParameters.set('sort', <any>sort.join(';'));
    }

    // if (filters && filters.length) {
    //   queryParameters = queryParameters.set('filters', <any>filters.join(';'));
    // } 

    return queryParameters;
  };

  static isInited() {
    return NotificationChannelIdService.channelId != '';
  }
  static setChannelId(channelId){
    NotificationChannelIdService.channelId = channelId;
  }

  getNotificationChannelId(){
    return this.apiService.get('dsp/notifications/channel_id');
  }
  getNotifications(sort?: Array<string>){
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (sort && sort.length) {
      queryParameters = queryParameters.set('sort', <any>sort.join(';'));
    }
    return this.apiService.get('dsp/notifications/groups/' + NotificationChannelIdService.channelId, queryParameters);
  }
  getNotificationsPage(page: number, pageSize: number, sort?: Array<string>){
    const queryParameters = this.getListQueryParams(page, pageSize, sort);
    return this.apiService.get('dsp/notifications/groups/' + NotificationChannelIdService.channelId, queryParameters);
  }
  unReadNotification(body){
    return this.apiService.post(`dsp/notifications/read`,body);
  }
  getUnseenCount(){
    return this.apiService.get('dsp/notifications/unseenCount');
  }
  postSeenAll(){
    return this.apiService.post(`dsp/notifications/seen_all`);
  }
  hasNew(){
    return this.apiService.get(`dsp/notifications/channel/` + NotificationChannelIdService.channelId + `/has_new`);
  }
  
}
