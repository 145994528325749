import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@dooh/auth';
import { PasswordMatchValidator } from "@dooh/validation";
import { DialogService } from '@dooh/components-library';
import { NamespaceView } from '@dooh/models';
import { ActiveNamespaceService } from '@dooh/common-services';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dooh-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit, OnDestroy {
  newPassword: FormGroup;
  isLoading: boolean;
  visitedPassword: boolean;
  namespace: NamespaceView;
  logoWidth: string;

  unsubscriber$ = new Subject<void>()

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private dialogService: DialogService,
    private activeNamespaceService: ActiveNamespaceService
  ) { 
    this.namespace = ActiveNamespaceService.getNamespace();
    this.activeNamespaceService.loadNamespace()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(value => this.namespace = value);
  }

  ngOnInit() {
    this.logoWidth = this.getContainerWidth(this.namespace?.url);
    this.newPassword = this.fb.group(
      {
        password: new FormControl('', [
          Validators.required,
          Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-~.,|]).{8,}$')
        ]),
        passwordAgain: new FormControl(''),
      },
      { validators: PasswordMatchValidator.MatchPassword },
    );
    this.newPassword.get('password').hasError('pattern');
  }

  blurPassword() {
    this.visitedPassword = true;
  }

  onSubmit() {
    this.isLoading = true;

    const username = localStorage.getItem("username");
    const currentUser = localStorage.getItem("session");
    const cognitoPool = JSON.parse(localStorage.getItem('cognitoPool'));

    const userPool = new CognitoUserPool({
      UserPoolId: cognitoPool.userPoolId,
      ClientId: cognitoPool.oauthClientId
    })
    const userData = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    userData['Session'] = currentUser;
    this.authService.newPassword(userData, this.newPassword.getRawValue()['passwordAgain'],{}).then(resp => {
      this.isLoading = false;
      this.dialogService.alert({
        type: "success",
        message: "The password was set successfully! Please login with the new password"
      }).afterClosed().subscribe(()=> {
        localStorage.clear();
        window.location.href = '/';
      });
    }).catch(error => {
      this.isLoading = false;
      if (error.code === 'NotAuthorizedException') {
        this.dialogService.alert({
          type: 'error',
          message: error && error.message || 'Not authorized exception',
          submitButtonText: 'login.setPassword.GoToLoginPage'
        }).afterClosed().subscribe(res => {
          window.location.href = '/';
        });
      } else {
        this.dialogService.alert({
          type: 'error',
          message: error && error.message || 'Password does not conform to policy'
        });
      }
    });
  }

  preloadImage(url: string) {
    const img = new Image();
    img.src = url;
    return img
  }

  getContainerWidth(url: string): string {
    if (url) {
      const image = this.preloadImage(url);
      const width = image?.width;
      const height = image?.height;

      const ratio = width / height;
      if (ratio > (4/3)) {
        return '200px';
      } else {
        return '125px';
      } 
    } else {
      return '';
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
