<ngx-intl-tel-input
  [cssClass]="cssClass"
  [preferredCountries]="preferredCountries"
  [enableAutoCountrySelect]="true"
  [enablePlaceholder]="true"
  [searchCountryFlag]="searchCountryFlag"
  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
  [selectFirstCountry]="false"
  [selectedCountryISO]="selectedCountryISO"
  [maxLength]="15"
  [phoneValidation]="true"
  [separateDialCode]="separateDialCode"
  type="tel"
  autocomplete="off"
  [numberFormat]="PhoneNumberFormat.National"
  name="phone"
  [formControl]="control"
>
</ngx-intl-tel-input>
