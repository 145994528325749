// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  api_url: "https://dooh-api.staging.theneuron.com",
  VERSION: "1.4.0",
  stripe_key: 'pk_test_51HuFKAAMeoy9uOmWWSCjVApGxfQCjk46QCWNIzmRFXcqmLFU1bAoMjNQHjFCihmjh8T0LNfHY3RDAVtESIopUEkF00HAy7A8KO',
  hyperPayUrl: 'https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=',
  isProd: false,
  defaultNamespaceId: '9c1d1e2f-152e-4f35-84a4-ae1deb490b01',
  seagullsNamespaceId: '26bed650-8958-4cac-b79e-b4e8a0969545',
  intersectionNamespaceId: 'a1ffad0c-01fd-40b2-bf03-f6f631918133',
  daxNamespaceId: 'c8780c65-adbc-476e-8cd8-df1e33b6da0a',
  mapbox: {
    accessToken: 'pk.eyJ1IjoidG5hZG1pbmRldiIsImEiOiJjbDhjdXR4bWUwM3cyM3FuenptcjNtOXEwIn0.3-n9cFilRsStqog6AK6s1g',
    style: 'mapbox://styles/tnadmindev/clcrh3nws006r14t9f5o50bap'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
