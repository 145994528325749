import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  BboxObject,
  ColumnCustomizer,
  ColumnType,
  PageSizes,
  ScreenTagView,
  ScreenView,
  TableColumn,
  TableCustomizer,
  VenueType,
} from '@dooh/models';
import { TranslateService } from '@ngx-translate/core';
import { ScreensApi } from '../../screens-tab/screens-tab.component.models';
import { DialogService } from '../dialog.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable, of as observableOf, pipe ,Subscription,Subject} from 'rxjs';
import { IScreenBatchBody } from 'apps/smb-app/src/app/campaign-management/services/screens.service';
import { SearchFilterService } from './service/search-filter.service';
import { first, map, repeatWhen, takeUntil } from 'rxjs/operators';
import {
  ActiveNamespaceService,
  MapService,
  RESPONSE_SCREEN_TAGS,
  SCREEN_TAGS,
  ScreenPropertiesAutocompleteLineItemService,
  ScreenPropertiesAutocompleteTargetingService,
  ScreenTagsPagination,
} from '@dooh/common-services';
import { PointOfInterestDialogComponent } from './filter-components/point-of-interest/point-of-interest.component';
import { ScreenPropertiesDialogComponent } from './filter-components/screen-properties/screen-properties.component';
import { AudienceStateService } from '@dooh/audience-library';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AudienceComponent } from './filter-components/audience/audience.component';
import { MapsAPILoader } from '@agm/core';
import { DmaStateService } from '../../services/dma-dialog.service';
import { DmaComponent } from './filter-components/dma/dma.component';
import { environment } from 'apps/sso-webapp/src/environments/environment';
import {
  BulkSelectService,
  BULK_REQUEST_LIMIT,
} from '../../services/bulk-select.service';
import formatScreenFilterObject from 'libs/utils/src/lib/screens/formatScreenFilterObject';
import { TargetingsService } from '@dooh/common-services';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MapBoxComponent } from '../../map-box/map-box.component';

const PAGE_SIZE = 50;
export const MAP_PAGE_SIZE = 1000;

export interface Data {
  id: string;
  name: string;
  countScreensAttached: number;
  countScreensTotal: number;
  weeklyAudience:number;
  weeklyImpressions:number;
  dateCreated:string
}

@Component({
  selector: 'dooh-screen-search-dialog',
  templateUrl: './screen-search-dialog.component.html',
  styleUrls: ['./screen-search-dialog.component.scss'],
})
export class ScreenSearchDialogComponent
  implements OnInit, OnDestroy, AfterViewInit {
  config: any;
  showMap: boolean;
  filtersForm: FormGroup;
  screenPropertyArg: any;

  unsubscriber$ = new Subject<void>();
  subscriptionStarter$ = new Subject<void>();

  activeScreenTags: ScreenTagView[];
  screenTagSearchField = new FormControl();
  filterScreenObject: any;

  screensFilters: any;
  showChart: boolean;

  @ViewChild('formats') formatTemp: any;

  venueIconsMap = {
    '1': 'transit',
    '2': 'retail',
    '3': 'outdoor',
    '4': 'health',
    '5': 'care',
    '6': 'education',
    '7': 'offices',
    '8': 'leisure',
    '9': 'government',
    '10': 'financial',
    '11': 'residential',
  };

  screensApi: ScreensApi;
  disableSearch: boolean;
  showSearchResult: boolean;

  isPoiEnabled: boolean;
  disableScreenPropertiesButton = true;
  screens = {
    ...this.getTableConfig(),
    apiCall: (
      refresh?: boolean,
      _updateFilters?: boolean,
      pageStrategy = false,
      updateSummary = true
    ) => {
      this.screens.subscription = this.screensApi
        .getAvailableScreens(
          this.filterScreenObject,
          this.screens.currentPage,
          this.screens.pageSize,
          this.screens.sort
        )
        .subscribe((result) => {
          this.screens.data =
            refresh || pageStrategy
              ? result.screens.content
              : [...this.screens.data, ...result.screens.content];
          this.screens.totalElements = result.screens.totalElements;
          this.markers = [...this.screens.data];
          if (this.filterScreenObject.placeIq && refresh) {
            this.screens.data.forEach((screen) => (screen.fromPlaceIQ = true));
          }

          if (this.dmaIds.length < 1 && this.screens.currentPage === 1) {
            this.dmaService.setDmaAggData(
              result?.tags[RESPONSE_SCREEN_TAGS[SCREEN_TAGS.DMAID]]?.values
            );
          }

          if (!this.filterScreenObject.placeIq && this.screens.currentPage === 1) {
            this.hasAudience =
              result.tags['place_iq.key_agg_result']?.values.length > 0;
            this.audienceStateService.setAudienceAggData(
              result.tags['place_iq.key_agg_result']?.values
            );
          }
          this.screens.syncDataSource();
          this.screens.updateMapIcons();

          if (updateSummary) {
            this.screenDataSummary = result?.sums;
            this.screenDataSummary['screenCount'] = result?.screens.totalElements;
            this.screenDataSummary['venueTypesCount'] = result?.tags[RESPONSE_SCREEN_TAGS[SCREEN_TAGS.VENUETYPE]]?.values.length;
            this.screenDataSummary['minPrice'] = result?.limits?.floorPrice?.minPossible;
            this.screenDataSummary['maxPrice'] = result?.limits?.floorPrice?.maxPossible;
          }

          this.screens.isResetTable = false;
          this.screens.isLoading = false;
          this.disableShowResult = false;
          this.loadingSummary = false;

          if ((this.screensApi.getScreenFilters || _updateFilters) && this.screens.currentPage === 1) {
            this.searchFilterService.setLimits(result.limits);
            this.searchFilterService.setTags(result.tags);

            this.checkScreenPropertiesButton(result?.tags);
            // return;
          }
        }, (err: any) => {
          this.screens.isLoading = false;
          let message = 'Something went wrong. Please try again.'
          if (err?.error?.systemMessage) {
            message = err?.error?.systemMessage;
          }
          this.dialogService.alert({
            type: 'error',
            message
          });
        });
    },
    syncDataSource: () => {
      this.screens.dataSource.data = [...this.screens.data];

      this.isPoiEnabled = true;

      this.selectedElements.next({
        action: 'RESET',
        items: [],
      });
      this.disablingElements.next({
        action: 'RESET',
        items: [],
      });

      this.addedScreensIds = this.screens.data.reduce((storage, screen) => {
        if (screen.hasRelation) {
          storage.push(screen.id);
        }
        return storage;
      }, []);
      

      this.selectedScreenIds = [
        ...this.addedScreensIds,
        ...this.screens.selectedItems.map((screen) => screen.id),
      ];

      this.selectedElements.next({
        action: 'SELECT',
        items: this.selectedScreenIds,
      });
      this.disablingElements.next({
        action: 'DISABLE',
        items: this.addedScreensIds,
      });
    },
    updateMapIcons: () => {
      this.mapService.setMarkerIconMap({
        ...this.screens.data.reduce((storage, screen) => {
          if (screen.hasRelation) {
            if (screen.venueType && screen.venueType.length) {
              if (screen.venueType[0].id.length % 2 === 0) {
                storage[screen.id] = `/assets/icons/ico-marker-${
                  this.venueIconsMap[screen.venueType[0].id.substring(0, 2)]
                }-added.svg`;
              } else {
                storage[screen.id] = `/assets/icons/ico-marker-${
                  this.venueIconsMap[screen.venueType[0].id[0]]
                }-added.svg`;
              }
            } else {
              storage[screen.id] = '/assets/icons/ico-marker-added-new.svg';
            }
          } else {
            if (screen.venueType && screen.venueType.length) {
              if (screen.venueType[0].id.length % 2 === 0) {
                storage[screen.id] = `/assets/icons/ico-marker-${
                  this.venueIconsMap[screen.venueType[0].id.substring(0, 2)]
                }.svg`;
              } else {
                storage[screen.id] = `/assets/icons/ico-marker-${
                  this.venueIconsMap[screen.venueType[0].id[0]]
                }.svg`;
              }
            } else {
              storage[screen.id] = `/assets/icons/ico-marker-new.svg`;
            }
          }
          return storage;
        }, {}),
        ...this.screens.selectedItems.reduce((storage, screen) => {
          if (screen.venueType && screen.venueType.length) {
            if (screen.venueType[0].id.length % 2 === 0) {
              storage[screen.id] = `/assets/icons/ico-marker-${
                this.venueIconsMap[screen.venueType[0].id.substring(0, 2)]
              }-selected.svg`;
            } else {
              storage[screen.id] = `/assets/icons/ico-marker-${
                this.venueIconsMap[screen.venueType[0].id[0]]
              }-selected.svg`;
            }
          } else {
            storage[screen.id] = '/assets/icons/ico-marker-selected-new.svg';
          }
          return storage;
        }, {}),
      });
      this.mapService.setMarkerTypeMap({
        ...this.screens.data.reduce((storage, screen) => {
          if (screen.hasRelation) {
            storage[screen.id] = 'added';
          }
          return storage;
        }, {}),
        ...this.screens.selectedItems.reduce((storage, screen) => {
          storage[screen.id] = 'added';
          return storage;
        }, {}),
      });
    },
  };

  selectedElements: Subject<{ action: string; items: string[] }> = new Subject<{
    action: string;
    items: string[];
  }>();
  disablingElements: Subject<{
    action: string;
    items: string[];
  }> = new Subject<{ action: string; items: string[] }>();

  isSaving: boolean;
  screenDataSummary: any;
  tagsPagination: ScreenTagsPagination;

  campaignId: any;
  lineItemId: any;
  targetingId:any;
  sort_: string[] = ['name,asc'];

  @ViewChild('mapBox') mapBox: MapBoxComponent;

  @ViewChild('poiDialog') poiDialog: PointOfInterestDialogComponent;
  @ViewChild('screenPropertyDialog')
  screenPropertyDialog: ScreenPropertiesDialogComponent;
  @ViewChild('audienceDialog') audienceDialog: AudienceComponent;
  @ViewChild('dmaDialog') dmaDialog: DmaComponent;

  @ViewChild('poiPanel') poiPanel: MatExpansionPanel;
  @ViewChild('screenPropertiesPanel') screenPropertiesPanel: MatExpansionPanel;
  @ViewChild('audiencePanel') audiencePanel: MatExpansionPanel;
  @ViewChild('dmaPanel') dmaPanel: MatExpansionPanel;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  hasAudience: boolean;
  dmaIds: string[] = [];
  showDma: boolean;

  playerId = environment.isProd
    ? '13bfbc8d-630b-4f3e-b6d7-c1b97321417d'
    : '0824e78d-7479-470d-995b-230c5c70cf11';
  screenPerformanceLoading: boolean;
  mapSelectedMarkerPerformance: any[];
  // Chart options
  view: any[] = [350, 150];
  showXAxis = true;
  showYAxis = false;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = '';
  showYAxisLabel = true;
  yAxisLabel = '';

  colorScheme = {
    domain: ['#2969b0'],
  };

  location = {};
  isMapPaginated: boolean;
  mapPagination = {
    totalElements: 0,
    pageIndex: 0,
    pageSize: 0,
  };
  isMapLoading: boolean;
  mapScreenSubscription: Subscription;
  totalScreens: any;
  markers: any[] = [];
  isFullScreen: boolean;
  showingMapInfo: boolean;
  markerPopUpInfo: any;

  isBulkAdding: boolean;
  bulkCount$: Observable<number>;
  bulkRequestLimit: number;
  isBulkSelect$ = null;
  allVenueTypes: VenueType[] = [];
  flatVenueType: any;
  //targeting sets variables
  isLoading = false;
  tableCustomizer: TableCustomizer;
  targetingPageSize = 50;
  targetingTotalElements = 0;
  targetingCurrentPage = 1;
  isTableSetUp: boolean;
  pageSizes = PageSizes;
  pagingStrategy:'scroll' | 'byPage' = 'byPage';
  displayedColumns: string[] = ['select','name','countScreensTotal','weeklyAudience','dateCreated'];
  dataSource = new MatTableDataSource<any>([]);
  selection = new SelectionModel<any>(true, []);
  tabSelected:number = 0;
  totalPages = 0;
  hideTargetingSet: boolean;

  disableShowResult: boolean;
  loadingSummary: boolean;
  filterCount: number;
  screenPropertiesAutocompleteService: ScreenPropertiesAutocompleteLineItemService | ScreenPropertiesAutocompleteTargetingService;

  poiPlaceCountry: any;
  isFiltering: boolean;
  markerGroup: any[] = [];
  showingMarkerGroupTable: boolean;
  addedScreensIds: any;
  selectedScreenIds: any[];
  showMarkerGroup = true;

  constructor(
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<ScreenSearchDialogComponent>,
    public dialogService: DialogService,
    private searchFilterService: SearchFilterService,
    // public screenPropertiesAutocompleteSmbCampaignService: ScreenPropertiesAutocompleteLineItemService,
    private audienceStateService: AudienceStateService,
    private mapApi: MapsAPILoader,
    private dmaService: DmaStateService,
    private mapService: MapService,
    private bulkSelectService: BulkSelectService,
    private targetingsService: TargetingsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.translateService.setDefaultLang('en');
    this.config = data?.config;
    this.screensApi = data?.screensApi;
    this.screenPropertyArg = data?.screenPropertyArg;
    this.screenPropertiesAutocompleteService = data?.screenPropertyService;
    this.campaignId = data?.campaignId;
    this.lineItemId = data?.lineItemId;
    this.targetingId =  data?.targetingSetId;
    this.getAverageScreenData();
    this.isBulkSelect$ = this.bulkSelectService.isTotalSelected$;
    this.bulkCount$ = this.bulkSelectService.totalCount$;
    this.bulkRequestLimit = BULK_REQUEST_LIMIT;
    this.hideTargetingSet = data?.hideTargetingSet ? data?.hideTargetingSet : false
    this.targetingsService
      .getAllVenueTypes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        const allVenueTypes: VenueType[] = res?.venueTypes?.categories || [];

        this.allVenueTypes = allVenueTypes.map((venueType) => ({
          ...venueType,
          isSelected: false,
        }));
        this.allVenueTypes.sort((a, b) => a.name.localeCompare(b.name));
        this.sortVenueType(this.allVenueTypes)
        this.flatVenueType = this.flattenAllVenueTypes(this.allVenueTypes);
      });
  }

  ngOnInit(): void {
    this.disableShowResult = true;
    this.filtersForm = new FormGroup({
      screenTagSearchField: new FormControl([]),
    });
    this.loadConfiguration();
    this.watchSearchFilters();

    this.bulkSelectService.isTotalSelected$
      .pipe(
        takeUntil(this.unsubscriber$),
        repeatWhen(() => this.subscriptionStarter$)
      )
      .subscribe((isTotalSelected) => {
        if (isTotalSelected) {
          const queryObj = formatScreenFilterObject(this.filterScreenObject);
          this.bulkSelectService.setQuery(queryObj);
        } else this.bulkSelectService.setQuery(null)
      }
    )
    if (!this.hideTargetingSet) this.getTargetingSets();

    this.filtersForm.get('screenTagSearchField').valueChanges
      .pipe(takeUntil(this.unsubscriber$), repeatWhen(() => this.subscriptionStarter$))
      .subscribe(res => {
        if (typeof res === 'object') {
          if (res?.length < 1) {
            this.disableShowResult = true;
            this.disableSearch = true;
          } else {
            this.disableSearch = false;
          }
        } else {
          this.disableShowResult = true;
        }
      })
  }

  ngAfterViewInit(): void {
    this.loadConfiguration();
    this.mapApi.load();
  }

  getTargetingSets(refresh: boolean = false ) {
    this.isLoading = true;
    this.isTableSetUp = false;

    if (refresh) {
      this.targetingCurrentPage = 1;
      this.targetingTotalElements = 0;

    }

    this.targetingsService.getAllAvailable(this.campaignId,this.lineItemId, this.targetingCurrentPage,this.targetingPageSize,this.sort_).pipe(takeUntil(this.unsubscriber$)).subscribe(resp => {

      this.dataSource.data = refresh ? resp.content : [...this.dataSource.data, ...resp.content]; ;
      this.targetingTotalElements = resp.totalElements;
      this.totalPages = resp.totalPages;
      this.isLoading = false;
      this.isTableSetUp = true;
      this.dataSource.data.forEach((item)=>{
       if(item.countScreensAttached === item.countScreensTotal){
        this.selection.select(item);
       }
       let namespaceName = ActiveNamespaceService.getNamespace().name;
       if(item.isPreset) {
        item['namespace'] = namespaceName;
       }
      })
    },
    (err) => {
      this.openErrorDialog(err);
      this.isLoading = false;
    });
  }

  isDisable(obj: any){
    if (obj.countScreensAttached === obj.countScreensTotal) {
      return true;
    }
    else {
      return false;
    }

  }
  onPageHandler($event) {
    this.targetingPageSize = $event.pageSize;
    this.targetingCurrentPage = $event.pageIndex + 1;
    this.getTargetingSets();
  }

  openErrorDialog(error: HttpErrorResponse): void {
    this.translateService
      .get('dsp.campaignManagement.campaign.dialog')
      .subscribe((dialog) => {
        const message =
          error.status !== 400
            ? dialog.message.error[error.status] ||
              `${error.status} ${error.statusText}`
            : this.getBadRequestError(error);

        this.dialogService.alert({
          type: 'error',
          message,
        });
      });
  }

  getBadRequestError(errorResponse: HttpErrorResponse): string {
    const { error } = errorResponse;

    if ((!error.subErrors || !error.subErrors.length) && !error.message) {
      return error.status.toString();
    }

    if (!error.subErrors || !error.subErrors.length) {
      return error.message;
    }

    return error.subErrors.map((subError) => subError.message).join('; ');
  }

  checkElement(element, $event: MouseEvent) {
    if (!$event) {
      return;
    }

    const isSelected = this.isAllSelected ? !this.selection.isSelected(element) : this.selection.isSelected(element);

    let action = 'SELECT';
    if (isSelected) {
      action = 'DESELECT';
    }
    const value = { element: element, action: action };

  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  onTargetingSortChange($event: Sort) {
    this.sort_ = [`${$event['active']},${$event['direction']}`];
    this.targetingCurrentPage = 1;
    this.targetingTotalElements = 0;
    this.getTargetingSets(true);
  }

  public saveSelectedTargetingSets(){
    this.isSaving = true;
    const addedTargetingSets=[]
    this.selection.selected.forEach(
      (set) => {
        if(set.countScreensAttached !== set.countScreensTotal){
          addedTargetingSets.push({'targetingSetId':set.id})
        }
      }
    );


    this.targetingsService
      .postTargetingSetsBatch(this.campaignId,this.lineItemId,addedTargetingSets)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        () => {
          this.dialogRef.close({ numOfScreens: addedTargetingSets.length, type:"Targeting Sets" });
        },
        (err) => {
          this.isSaving = false;
          if(err.status == 504){
            this.dialogRef.close({ numOfScreens: addedTargetingSets.length, type:"Targeting Sets" });
          }else{
            this.openErrorDialog(err);
          }
        }
      );

  }

  private getTableConfig() {
    return {
      tableCustomizer: null,
      data: [],
      dataSource: new MatTableDataSource<ScreenView>(),
      currentPage: 1,
      pageSize: PAGE_SIZE,
      totalElements: 0,
      sort: ['name,asc'],
      isLoading: false,
      subscription: null,
      selectedItems: [],
      isResetTable: false,
    };
  }

  private loadConfiguration(): void {
    const columnCustomizers = this.loadColumnCustomizers();
    this.screens.tableCustomizer = new TableCustomizer(
      'screens',
      columnCustomizers,
      false,
      null,
      null
    );
  }

  private loadColumnCustomizers(): Array<ColumnCustomizer> {
    let columns: TableColumn[] = [
      {
        name: 'name',
        heading: 'Screen Name',
        valuePath: ['name'],
        type: ColumnType.CHECKBOX_GROUP,
      },
      {
        name: 'formats',
        heading: 'Formats',
        valuePath: ['formats'],
        type: ColumnType.CUSTOM,
        sortDisabled: true,
        customTemplate: this.formatTemp
      },
      {
        name: 'publisher_name',
        heading: 'Publisher Name',
        valuePath: ['publisher', 'name'],
      },
      {
        name: 'ssp_name',
        heading: 'SSP',
        valuePath: ['ssp', 'name'],
      },
      {
        name: 'address_country',
        heading: 'Country',
        valuePath: ['address', 'country'],
      },
      {
        name: 'weekly_audience',
        heading: 'Audience',
        valuePath: ['audiencePerWeek'],
        type: ColumnType.ROUND_NUMBER,
        toolTipMessage: `“Audience size” is Audience size be achieved per week.`,
        toolTipIcon: 'help_outline',
      },
      {
        name: 'floor_price',
        heading: 'Floorprice',
        valuePath: ['convertedPrice', 'floorPrice'],
        type: ColumnType.MONEY,
        toolTipMessage: `“Floorprice” is on CPM, it costs to reach 1000 in that audience of that screen.`,
        toolTipIcon: 'help_outline',
      },
    ];

    return columns.map((column) => new ColumnCustomizer(column));
  }

  ngOnDestroy(): void {
    this.onReset();
    this.onFiltersReset(true);
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  onSearchByTags(): void {
    this.screens.currentPage = 1;
    this.screens.pageSize = 1;

    this.audiencePanel?.close?.();
    this.screenPropertiesPanel?.close?.();
    this.poiPanel?.close?.();
    this.dmaPanel?.close?.();

    if (!this.filtersForm || !this.filtersForm.get('screenTagSearchField')) {
      return;
    }
    this.bulkSelectService.resetBulkService();
    this.activeScreenTags = this.filtersForm.get('screenTagSearchField').value;

    this.showDma = this.activeScreenTags.some(
      (screenInfo) => screenInfo.countryCode === 'us'
    );
    this.setPoiPlaceCountry();
    if (!this.activeScreenTags || !this.activeScreenTags.length) {
      this.onFiltersReset();
      // this.onAddScreensReset();
      return;
    }

    const searchData = this.filtersForm.get('screenTagSearchField').value;
    this.searchFilterService.syncSearchField(searchData);
    this.loadingSummary = true;

    this.onFiltersReset();
    this.searchFilterService.resetTagsPages();

    let placeBbox: BboxObject[] = null;
    const checkIfPlaceSelected = this.activeScreenTags.some(
      (item) => item?.tag === 'Place'
    );
    if (checkIfPlaceSelected) {
      placeBbox = [];
      this.activeScreenTags.forEach((item) => {
        if (item?.tag === 'Place') {
          placeBbox.push(item?.area);
        }
      });
    }

    const tagsMapByName = this.activeScreenTags.reduce((obj, item) => {
      if (!item) {
        return obj;
      }
      if (item.tag === 'Place') {
        const countryTag = 'country';
        if (!obj[countryTag]) {
          obj[countryTag] = {
            name: countryTag,
            properties: [],
          };
          obj[countryTag].properties.push(item.country);
        }
        return obj;
      }
      const tag = item.tag;
      if (!obj[tag]) {
        obj[tag] = {
          name: tag,
          properties: [],
        };
      }
      if (obj[tag].properties === '') {
        obj[tag].properties = [];
      }
      if (Array.isArray(obj[tag].properties)) {
        obj[tag].properties.push(item.id);
      }
      return obj;
    }, {});
    if (!this.filterScreenObject) {
      this.filterScreenObject = {
        tags: [],
        pois: null,
      };
    }

    Object.keys(tagsMapByName).forEach((tagName) => {
      const index = this.filterScreenObject?.tags.findIndex(
        (tag) => tag.name === tagName
      );
      if (index === -1) {
        this.filterScreenObject.tags.push(tagsMapByName[tagName]);
      } else {
        this.filterScreenObject.tags[index] = tagsMapByName[tagName];
      }
    });
    this.filterScreenObject.bboxes = placeBbox;

    this.audienceStateService.resetAudienceData();

    this.getScreens(true, true);
  }

  setPoiPlaceCountry(): void {
    const placeTag = this.activeScreenTags.find((item) => item?.tag === 'Place');
    if (placeTag) {
      this.poiPlaceCountry = {
        code: placeTag?.countryCode,
        name: placeTag?.country
      };
    } else {
      this.poiPlaceCountry = null;
    }
  }

  onTablePaging($event: any): void {
    this.screens.currentPage = $event?.pageNumber || 1;
    this.screens.pageSize = $event?.pageSize || this.screens.pageSize;
    this.getScreens(false, false, true, false);
  }

  onReset(): void {
    this.activeScreenTags = [];
    this.isFiltering = false;
    this.filtersForm.reset();
    this.filtersForm.patchValue({ screenTagSearchField: [] });
    this.screensApi.setCountry?.(null);
    this.poiPlaceCountry = null;
    this.onFiltersReset();
  }

  getScreens(
    refresh?: boolean,
    updateFilters?: boolean,
    pageStrategy = false,
    updateSummary = true
  ): void {
    if (this.screens.isLoading) {
      this.screens.subscription.unsubscribe();
    }
    this.screens.isLoading = true;
    if (refresh) {
      this.screens.currentPage = 1;
    }
    this.screens.apiCall(refresh, updateFilters, pageStrategy, updateSummary);
  }

  onFiltersReset(closeModal = false): void {
    this.screens.selectedItems = [];
    this.unsubscriber$.next();
    this.dmaIds = [];

    this.showMarkerGroup = false;

    setTimeout(() => {
      this.showMarkerGroup = true;
    })

    this.poiDialog?.onReset?.(false);
    this.screenPropertyDialog?.onReset?.(false);
    this.audienceDialog?.resetForm?.(false);
    this.dmaDialog?.reset?.(false);

    this.isMapPaginated = false;
    this.setMapPagination(0, 0, 0);

    this.filterScreenObject = {
      tags: [],
      pois: null,
    };

    !closeModal ? this.subscriptionStarter$.next() : null;
  }

  onSortChange($event: any): void {
    const sort = [`${$event.name},${$event.direction}`];
    if (sort.join() === this.screens.sort.join()) return;
    this.screens.sort = sort;
    this.getScreens(true, false, false, false);
  }

  triggerAction($event: { element: ScreenView; action: string }): void {
    const screen = $event.element;
    if ($event.action === 'SELECT') {
      this.screens.selectedItems.push(screen);
    } else if ($event.action === 'DESELECT') {
      this.screens.selectedItems = this.screens.selectedItems.filter(
        (item) => item.id !== screen.id
      );
    }
    this.screens.syncDataSource();
    this.screens.updateMapIcons();
  }

  selectAllMaster($event: { checked: boolean }) {
    const isAllSelected = $event.checked;
    this.screens.selectedItems = isAllSelected
      ? [
          ...this.screens.selectedItems,
          ...this.screens.dataSource.data
            .filter((screen) => !screen.hasRelation)
            .filter(
              ({ id: screenId }) =>
                !this.screens.selectedItems.some(
                  ({ id: selectedId }) => screenId === selectedId
                )
            ),
        ]
      : [...this.screens.selectedItems];

    if (!isAllSelected) {
      this.screens.selectedItems = this.screens.selectedItems.filter(
        ({ id: screenId }) =>
          !this.screens.dataSource.data.some(
            ({ id: selectedId }) => screenId === selectedId
          )
      );
    }

    this.screens.syncDataSource();
    this.screens.updateMapIcons();
  }

  checkAllowSearch($event: boolean): void {
    this.disableSearch = !$event;
  }

  showSearchFilters(): void {
    this.resetTable();
    this.showSearchResult = false;
    this.bulkSelectService.resetBulkService();
  }

  showSearchResults(): void {
    this.screens.currentPage = 1;
    this.screens.pageSize = PAGE_SIZE;
    this.showSearchResult = true;
    this.getScreens(true, true, false, false);
  }

  async saveSelectedScreens() {
    const hasPlaceIqsScreens = this.screens.selectedItems.some(screen => screen.fromPlaceIQ);
    if(hasPlaceIqsScreens){
      const canProceed = await this.openPlaceIQWarningDialog().afterClosed().toPromise();
      if(!canProceed) return;
    }

    this.isSaving = true;
    const addedScreens: IScreenBatchBody[] = this.screens.selectedItems.reduce(
      (storage, screen) => {
        storage.push({
          isPlaceiqDemography: screen.fromPlaceIQ || false,
          isPlaceiqLocation: screen.fromPlaceIQ || false,
          screenId: screen.id,
        });
        return storage;
      },
      []
    );

    this.screensApi
      .addScreens(addedScreens)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        () => {
          this.dialogRef.close({ numOfScreens: addedScreens.length, type:"screens" });
        },
        (err) => {
          this.isSaving = false;
          let message = 'Something went wrong. Please try again.'
          if (err?.error?.systemMessage) {
            message = err?.error?.systemMessage;
          }
          this.dialogService.alert({
            type: 'error',
            message
          });
        }
      );
  }

  onCloseClick(): void {
    this.onFiltersReset(true);
    this.dialogRef.close();
  }

  getAverageScreenData(): void {
    this.loadingSummary = true;
    this.screensApi.getAllScreenAverageData()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        this.screenDataSummary = data?.sums;
        this.screenDataSummary['screenCount'] = data?.screens.totalElements;
        this.screenDataSummary['venueTypesCount'] = data?.tags[RESPONSE_SCREEN_TAGS[SCREEN_TAGS.VENUETYPE]]?.values.length;
        this.screenDataSummary['minPrice'] = data?.limits?.floorPrice?.minPossible;
        this.screenDataSummary['maxPrice'] = data?.limits?.floorPrice?.maxPossible;
        this.loadingSummary = false;
      }, err => {
        this.loadingSummary = false;
      });
  }

  getScreenFilters() {
    this.searchFilterService.tagsPagination$
      .pipe(first())
      .subscribe((tagsPagination) => {
        this.tagsPagination = tagsPagination;
      });

    const screenTags: ScreenTagView[] = JSON.parse(
      JSON.stringify(this.activeScreenTags)
    );

    this.filterScreenObject.tags.forEach((tagItem) => {
      if (tagItem?.properties.length > 0) {
        const screenTag: ScreenTagView = {
          tag: tagItem.name,
          id: tagItem.properties,
        };
        screenTags.push(screenTag);
      }
    });

    this.screensApi
      .getScreenFilters({
        screenTags: screenTags,
        pagination: this.tagsPagination,
        ...this.filterScreenObject,
      })
      .pipe(first())
      .subscribe((result) => {
        this.searchFilterService.setLimits(result.limits);
        this.searchFilterService.setTags(result.tags);

        this.checkScreenPropertiesButton(result?.tags);
      });
  }

  checkScreenPropertiesButton(tags: any[]): void {
    const hasVenueType = tags[RESPONSE_SCREEN_TAGS[SCREEN_TAGS.VENUETYPE]]?.values?.length > 0;
    const hasPublisher = tags[RESPONSE_SCREEN_TAGS[SCREEN_TAGS.PUBLISHERS]]?.values?.length > 0;
    const hasResolution =
      tags[RESPONSE_SCREEN_TAGS[SCREEN_TAGS.RESOLUTIONS]]?.values?.length > 0;

    if (!hasVenueType && !hasPublisher && !hasResolution && !this.isFiltering) {
      this.disableScreenPropertiesButton = true;
    } else {
      this.disableScreenPropertiesButton = false;
    }
  }

  watchSearchFilters(): void {
    this.searchFilterService.placeIqFilters$
      .pipe(
        takeUntil(this.unsubscriber$),
        repeatWhen(() => this.subscriptionStarter$)
      )
      .subscribe(({ filters, trigger }) => {
        if (trigger) this.loadingSummary = true;
        const placeIq = filters;
        if (!this.filterScreenObject) {
          this.filterScreenObject = {
            tags: [],
            placeIq: null,
          };
        }
        this.filterScreenObject.placeIq = placeIq;
        this.screens.currentPage = 1;
        this.screens.pageSize = 1;
        trigger ? this.getScreens(true, true) : null;
        this.audiencePanel?.close?.();
      });
    this.searchFilterService.poiFilters$
      .pipe(
        takeUntil(this.unsubscriber$),
        repeatWhen(() => this.subscriptionStarter$)
      )
      .subscribe(({ filters, trigger }) => {
        if (trigger) this.loadingSummary = true;
        if (!this.filterScreenObject) {
          this.filterScreenObject = {
            tags: [],
          };
        }
        if (filters) {
          this.searchFilterService.resetTagsPages();
          this.filterScreenObject.pois = {
            points: filters.pois,
            radius: filters.radius,
          };
          this.filterScreenObject.postalCodes = filters.zipCodes;

        } else {
          this.filterScreenObject.pois = null;
          this.filterScreenObject.postalCodes = null;
        }
        this.screens.currentPage = 1;
        this.screens.pageSize = 1;
        trigger ? this.getScreens(true, true) : null;
        this.poiPanel?.close?.();
      });


    this.searchFilterService.screenPropertiesFilters$
      .pipe(
        takeUntil(this.unsubscriber$),
        repeatWhen(() => this.subscriptionStarter$)
      )
      .subscribe(({ filters, trigger }) => {
        if (trigger) this.loadingSummary = true;
        if (!filters) {
          if (!this.filterScreenObject) {
            this.filterScreenObject = {
              tags: [],
              pois: null,
            };
          } else {
            const propertyArray = ['venue', 'publisher', 'resolution', 'mimeType']

            let checkSearchedPublisher;

            this.searchFilterService.currentSearchField$.pipe(first()).subscribe((res: any[]) => {
              checkSearchedPublisher = res.filter(data => data?.tag === 'publisher');
            })
            this.filterScreenObject.tags.forEach((tag: any) => {
              if (propertyArray.some(property => property === tag?.name)) {
                tag.properties = [];
              }
            });
            if (checkSearchedPublisher && checkSearchedPublisher?.length > 0) {
              const publisherIndex = this.filterScreenObject.tags.findIndex(tag => tag?.name === 'publisher')
              if (publisherIndex > -1) {
                checkSearchedPublisher.forEach(publisher => {
                  this.filterScreenObject.tags[publisherIndex].properties.push(publisher?.id)
                })
              }
            }
            this.filterScreenObject.limits = null;
            this.isFiltering = false;
          }
          this.screens.currentPage = 1;
          this.screens.pageSize = 1;
          trigger ? this.getScreens(true, true) : null;
          return;
        }
        this.isFiltering = true;
        const filterTagsMapByName = (filters?.tags || []).reduce(
          (obj, item) => {
            if (!item) {
              return obj;
            }
            obj[item.name] = { ...item };
            return obj;
          },
          {}
        );
        const tagsMapByName = (this.activeScreenTags || []).reduce(
          (obj, item) => {
            if (!item) {
              return obj;
            }
            const tag = item.tag;
            if (!obj[tag]) {
              obj[tag] = {
                name: tag,
                properties: [],
              };
            }
            if (obj[tag].properties === '') {
              obj[tag].properties = [];
            }
            if (Array.isArray(obj[tag].properties)) {
              obj[tag].properties.push(item.id);
            }
            return obj;
          },
          filterTagsMapByName
        );
        if (!this.filterScreenObject) {
          this.filterScreenObject = {
            tags: [],
            pois: null,
          };
        }
        Object.keys(tagsMapByName).forEach((tagName) => {
          const index = this.filterScreenObject?.tags.findIndex(
            (tag) => tag.name === tagName
          );
          if (index === -1) {
            this.filterScreenObject.tags.push(tagsMapByName[tagName]);
          } else {
            this.filterScreenObject.tags[index] = tagsMapByName[tagName];
          }
        });
        this.filterScreenObject.limits = filters?.limits;

        this.screens.currentPage = 1;
        this.screens.pageSize = 1;
        trigger ? this.getScreens(true, true) : null;
        this.screenPropertiesPanel?.close?.();
      });

    this.searchFilterService.dmaIds$
      .pipe(
        takeUntil(this.unsubscriber$),
        repeatWhen(() => this.subscriptionStarter$)
      )
      .subscribe(({ filters, trigger }) => {
        if (trigger) this.loadingSummary = true;
        if (!this.filterScreenObject) {
          this.filterScreenObject = {
            tags: [],
          };
        }
        if (filters && filters?.dmaId) {
          this.dmaIds = filters?.dmaId;
          const tag = {
            name: 'dmaId',
            properties: this.dmaIds,
          };
          this.filterScreenObject.tags.push(tag);
        }
        if (this.dmaIds.length > 0 && !filters) {
          if (this.filterScreenObject.tags.length > 0) {
            this.filterScreenObject.tags = this.filterScreenObject.tags.filter(
              (tag) => tag.name !== 'dmaId'
            );
          }
          this.dmaIds = [];
        }
        this.screens.currentPage = 1;
        this.screens.pageSize = 1;
        trigger ? this.getScreens(true, true) : null;
        this.dmaPanel?.close?.();
      });
  }

  isSeagulls(id: string) {
    return this.playerId === id;
  }
  showMarkerDetails(screenInfo: any): void {
    if (this.markerGroup?.length > 2 ) {
      const groupLocation = this.markerGroup[0]?.location;
      if (
        screenInfo?.marker?.location?.latitude === groupLocation?.latitude &&
        screenInfo?.marker?.location?.longitude === groupLocation?.longitude
        ) {
          this.showingMapInfo = false;
          this.showingMarkerGroupTable = true;
          return;
        }
    }
    const markerGroup = this.markers
      .filter(
        (marker) =>
          marker?.location?.latitude === screenInfo?.marker?.location?.latitude
      )
      .filter(
        (marker) =>
          marker?.location?.longitude ===
          screenInfo?.marker?.location?.longitude
      );
      this.markerGroup = markerGroup;
      if (markerGroup?.length > 2) {
        this.showingMapInfo = false;
        this.showingMarkerGroupTable = true;
        return;
      }


      this.markerGroup = [];

    this.SetScreenInfo(screenInfo);
  }

  SetScreenInfo(screenInfo: any): void {
    this.showingMarkerGroupTable = false;
    this.showingMapInfo = true;
    this.showChart = false;
    setTimeout(() => {
      this.markerPopUpInfo = screenInfo.marker;
      this.mapBox?.resizeMap();
      this.setMarkerImage();
    })
    setTimeout(() => {
      this.showChart = true;
    }, 500);
  }

  setMarkerImage(): void {
    if (!this.markerPopUpInfo?.images) return;

    const {sspImages, defaultImages, salamanderImages } = this.markerPopUpInfo?.images;

    if (sspImages?.length > 0) {
      this.markerPopUpInfo['screenImages'] = sspImages;
      return;
    }
    if (salamanderImages?.length > 0) {
      this.markerPopUpInfo['screenImages'] = salamanderImages;
      return;
    }
    if (defaultImages?.length > 0) {
      this.markerPopUpInfo['screenImages'] = defaultImages;
      return;
    }
  }

  get images(): string[] {
    const images = [];
    if (this.markerPopUpInfo?.images?.sspImages) {
      images.push(...this.markerPopUpInfo?.images?.sspImages)
    }
    if (this.markerPopUpInfo?.images?.defaultImages) {
      images.push(...this.markerPopUpInfo?.images?.defaultImages)
    }

    return images;
  }

  getMapScreens(pageNum: number, pageSize: number): void {
    if (this.isMapLoading) {
      this.mapScreenSubscription.unsubscribe();
    }

    this.isMapLoading = true;

    this.mapScreenSubscription = this.screensApi
      .getAvailableScreens(
        this.filterScreenObject,
        pageNum,
        pageSize,
        this.screens.sort
      )
      .pipe(map((result) => result?.screens))
      .subscribe(
        (res) => {
          this.totalScreens = res?.totalElements;
          const markers = res?.content;
          if (this.filterScreenObject.placeIq) {
            markers.forEach((screen) => (screen.fromPlaceIQ = true));
          }
          this.markers = [...markers];
          this.isMapLoading = false;
          this.setMapPagination(this.totalScreens, pageNum, pageSize);

          if (this.totalScreens > MAP_PAGE_SIZE) {
            this.isMapPaginated = true;
          } else {
            this.isMapPaginated = false;
            this.setMapPagination(0, 0, 0);
          }
        },
        (err) => {
          this.isMapLoading = false;
          let message = 'Something went wrong. Please try again.';
          if (err?.error?.systemMessage) {
            message = err?.error?.systemMessage;
          }
          this.dialogService.alert({
            type: 'error',
            message
          });
        }
      );
  }

  changePage(event: any): void {
    this.getMapScreens(event?.currentPage, event?.pageSize);
  }

  public setMapPagination(
    total: number,
    pageIndex: number,
    pageSize: number
  ): void {
    this.mapPagination = {
      totalElements: total,
      pageIndex: pageIndex,
      pageSize: pageSize,
    };
  }



  clearInfo(): void {
    this.showingMapInfo = false;
    this.showingMarkerGroupTable = false;
    this.mapBox?.resizeMap();
  }

  backToMarkerTable(): void {
    this.showingMapInfo = false;
    this.showingMarkerGroupTable = true;
    this.mapBox?.resizeMap();
  }

  toggleMap(): void {
    if (this.showMap) {
      this.dialogRef.updateSize('90%', '100%');
      this.getMapScreens(1, MAP_PAGE_SIZE);
    } else {
      this.dialogRef.updateSize(this.config?.width, this.config?.height);
    }
    this.clearInfo();
    this.mapBox.resizeMap();
  }

  toggleFullScreen(): void {
    this.isFullScreen = !this.isFullScreen;

    if (this.isFullScreen) {
      this.dialogRef.updateSize('100%', '100%');
    } else {
      this.dialogRef.updateSize('90%', '100%');
    }

    this.mapBox?.resizeMap();
  }

  toggleScreenFromMap(screen: any): void {
    if (this.isScreenSelected(screen)) {
      this.screens.selectedItems = this.screens.selectedItems.filter(
        (item) => item.id !== screen.id
      );
    } else {
      this.screens.selectedItems.push(screen);
    }
    this.screens.syncDataSource();
    this.screens.updateMapIcons();
  }

  isScreenSelected(screen: any): boolean {
    return this.screens.selectedItems.some((item) => item.id === screen.id);
  }

  toggleAllMarkerGroupSelection(event: {type: string}): void {
    const type = event?.type;
    this.markerGroup.forEach(screen => {
      if (type === 'SELECT') {
        if (!this.isScreenSelected(screen) && !screen?.hasRelation) {
          this.screens.selectedItems.push(screen);
        }
      } else if (type === 'DESELECT') {
        if (this.isScreenSelected(screen)) {
          this.screens.selectedItems = this.screens.selectedItems.filter(
            (item) => item.id !== screen.id
          );
        }
      }
    });

    this.screens.syncDataSource();
    this.screens.updateMapIcons();
  }

  toggleAsideDialog(isOpened: boolean): void {
    this.dialogRef.updatePosition(
      isOpened ? { right: '361px' } : { right: '0' }
    );
  }

  async addBulkScreens() {
    const bulkQuery = this.bulkSelectService.getQueryCurrentValue();
    if (bulkQuery?.placeIq) {
      const canProceed = await this.openPlaceIQWarningDialog().afterClosed().toPromise();
      if(!canProceed) return;
    }
    this.isBulkAdding = true;
    this.isSaving = true;
    this.screensApi
      .addBulkScreens(bulkQuery)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (result) => {
          this.isBulkAdding = false;
          this.bulkSelectService.resetBulkService();
          this.onReset();
          this.dialogRef.close({ numOfScreens: this.screens.totalElements, type:"screens" });
        },
        (err) => {
          this.isBulkAdding = false;
          this.isSaving = false;
          let message = 'Something went wrong. Please try again.'
          if (err?.error?.systemMessage) {
            message = err?.error?.systemMessage;
          }
          this.dialogService.alert({
            type: 'error',
            message
          });
        }
      );
  }

  getLocaleString(value: any): any {
    return value.toLocaleString(navigator.language);
  }

  getVenueTypeName(id) {
    let parent = this.getVenueTypeParentName(id);
    return parent;
  }
  getVenueTypeParentName(id) {
    if (id) {
      if (id.length % 2 == 0) {
        const first2Str = String(id).slice(0, 2);
        const first2Num = Number(first2Str);
        let venueType = this.allVenueTypes?.filter((venue) => {
          return venue.enumeration_id === first2Num;
        });
        let venueTypeChild = this.flatVenueType?.filter((venue) => {
          return venue.enumeration_id == id;
        });
        if (venueType) {
          if (!venueTypeChild) {
            return `${venueType[0]?.name?.replace(/&amp;/g, '&')}`;
          }
          return `${venueType[0]?.name?.replace(/&amp;/g, '&')}/${
            venueTypeChild[0]?.name
          }`;
        } else {
          return '';
        }
      } else if (id.length % 2 !== 0) {
        const firstNum = Number(String(id)[0]);
        let venueType = this.allVenueTypes?.filter((venue) => {
          return venue.enumeration_id === firstNum;
        });
        let venueTypeChild2 = this.flatVenueType?.filter((venue2) => {
          return venue2.enumeration_id == id;
        });
        if (venueType) {
          if (!venueTypeChild2) {
            return `${venueType[0]?.name?.replace(/&amp;/g, '&')}`;
          }
          return `${venueType[0]?.name?.replace(/&amp;/g, '&')}/${
            venueTypeChild2[0]?.name
          }`;
        } else {
          return '';
        }
      }
      return '--';
    }
  }
  flattenAllVenueTypes(members) {
    let children = [];

    return members.map(m => {
      if (m.children && m.children.length) {
        children = [...children, ...m.children];
      }
      return m;
    }).concat(children.length ? this.flattenAllVenueTypes(children) : children);
  };
  onTabChanged($event) {
    this.tabSelected = $event.index;
  }
  sortVenueType(venueTypeArr: VenueType[]): void {
    venueTypeArr.forEach((venueType: VenueType) => {
      if (venueType.children) {
        venueType.children.sort((a, b) => a.name.localeCompare(b.name));
        this.sortVenueType(venueType.children);
      } else {
        return;
      }
    })
  }
  openPlaceIQWarningDialog() {
    return this.dialogService.alert({
      type: 'warning',
      title: 'Warning',
      message: "Selecting screens with this data partner will incur an additional cost. Continue?",
      submitButtonText: 'dialog.alert.submitButtonText.yes',
      cancelButtonText: 'dialog.alert.cancelButtonText.no',
    })
  }

  resetTable(): void {
    this.screens.isResetTable = true;
    setTimeout(() => {
      this.screens.isResetTable = false;
    }, 1000);
  }
}

