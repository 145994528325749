import { Component, Input, OnInit } from '@angular/core';
import { ScreenFormat } from './screen-format.model';

@Component({
  selector: 'dooh-screens-formats',
  templateUrl: './screens-formats.component.html',
  styleUrls: ['./screens-formats.component.scss']
})
export class ScreensFormatsComponent implements OnInit {

  @Input() formats: ScreenFormat[];
  constructor() { }

  ngOnInit(): void {
  }

}
