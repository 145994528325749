import { Injectable } from '@angular/core';
import { ApiService } from '@dooh/api';
import { ScreenPropertiesAutocompleteService } from '@dooh/components-library';
import { Observable } from 'rxjs';
import { PageDto } from '@dooh/models';

@Injectable({
  providedIn: 'root'
})
export class ScreenPropertiesAutocompleteTargetingService extends ScreenPropertiesAutocompleteService {
  public static apiUrl = "dsp";

  constructor(private apiService: ApiService) {
    super();
  }

  getApiService(): ApiService {
    return this.apiService;
  }

  getUrl(urlArgsArr?: any): string {
    const targetingId: string = Array.isArray(urlArgsArr) ? urlArgsArr[0] : null;
    if (targetingId === null || targetingId === undefined) {
      throw new Error('Required parameter targetingId was null or undefined when calling getAvailableScreens.');
    }

    return `${ScreenPropertiesAutocompleteTargetingService.apiUrl}/screens/targeting_sets/${encodeURIComponent(targetingId)}/available_screens`;
  }
}
