import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmExistDialogService {
  private confirmDialogSubject = new BehaviorSubject<boolean>(true);
  confirmDialog$ = this.confirmDialogSubject.asObservable();

  constructor() { }

  public setConfirmDialog(state: boolean): void {
    this.confirmDialogSubject.next(state);
  }

}
