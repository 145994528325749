import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.scss'],
})
export class DemographicsComponent implements OnInit {
  sortables = new Set(["Age", "HouseholdSize", "Income"])
  gender: string;
  genders: string[] = ['ALL', 'MALE', 'FEMALE'];
  selection: any;
  demographics: any[];
  constructor(
    private dialogRef: MatDialogRef<DemographicsComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      checkedData: string[];
      demographics: string[];
    }
  ) {}

  ages = [
    { control: 'control18', text: '18-24' },
    { control: 'control25', text: '25-34' },
    { control: 'control35', text: '35-44' },
    { control: 'control45', text: '45-54' },
    { control: 'control55', text: '55-64' },
    { control: 'control65', text: '65+' },
  ];

  ngOnInit(): void {
    const initialSelection = this.data.checkedData;
    const allowMultiSelect = true;
    this.demographics = this.data.demographics;
    this.demographics.sort(this.sortFunctionName);
    this.demographics.forEach(value=>{
      if(this.sortables.has(value.name)){
        value.children = Array.from(value.children).sort(this.sortFunction)
      }
      else{
        value.children = Array.from(value.children).sort(this.sortFunctionName)
      }
    })
    
    this.selection = new SelectionModel<any>(
      allowMultiSelect,
      initialSelection
    );
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.ages.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.ages.forEach((row) => this.selection.select(row));
  }

  closeDialog() {
    this.dialogRef.close();
  }
  
  sortFunction(a,b){
    const aValue = a.replace(/([A-Z])/g, ' $1').trim().split(/[\s,_]+/)[0]
    const bValue = b.replace(/([A-Z])/g, ' $1').trim().split(/[\s,_]+/)[0]
    return aValue - bValue;
  }
  
  sortFunctionName( a, b ){
    if ( (a.name || a).toLowerCase() < (b.name || b).toLowerCase()){
      return -1;
    }
    if ( (a.name || a).toLowerCase() > (b.name || b).toLowerCase()){
      return 1;
    }
    return 0;
  }

  submit() {
    const value = this.selection.selected;
    this.dialogRef.close(value);
  }
}
