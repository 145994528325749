export * from './basicErrorController.service';
import { BasicErrorControllerService } from './basicErrorController.service';
export * from './campaigns.service';
import { CampaignsService } from './campaigns.service';
export * from './creatives.service';
import { CreativesService } from './creatives.service';
export * from './instances.service';
import { InstancesService } from './instances.service';
export * from './inventory.service';
import { InventoryService } from './inventory.service';
export * from './targeting.service';
import { TargetingService } from './targeting.service';
export const APIS = [BasicErrorControllerService, CampaignsService, CreativesService, InstancesService, InventoryService, TargetingService];
