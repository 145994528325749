<div class="login-container">
  <div class="logo-container"   [style.width]="logoWidth">
    <img *ngIf="namespace" [src]="namespace?.url" class="namespace__logo">
  </div>
  <div class="card" [ngClass]="{'large-message-container': newPassword.get('password').hasError('pattern') && visitedPassword}">
      <div class="login-title">{{'login.setPassword.title' | translate}}</div>
      <form [formGroup]="newPassword">
          <mat-form-field appearance="outline" [ngClass]="{'large-message': newPassword.get('password').hasError('pattern') && visitedPassword}">
              <mat-label>{{'login.setPassword.newPassword' | translate}}</mat-label>
              <input matInput
                    formControlName="password"
                     name="newPassword"
                     placeholder="{{'login.setPassword.newPassword' | translate}}"
                     type="password"
                     (blur)="blurPassword()">

              <mat-error *ngIf="newPassword.get('password').hasError('required')">
                This field is required
              </mat-error>
              <mat-error *ngIf="newPassword.get('password').hasError('pattern')">
                Password must contain a lower case letter, an upper case letter, !@#$%^&*()_+~, 0,1,..,9, and at least 8 characters.
              </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
              <mat-label>{{'login.setPassword.newPasswordAgain' | translate}}</mat-label>
              <input matInput

                    formControlName="passwordAgain"
                     name="password"
                     placeholder="{{'login.setPassword.newPasswordAgain' | translate}}"
                     type="password">
            <mat-error *ngIf="newPassword.get('passwordAgain').hasError('required')">
              This field is required
            </mat-error>
              <mat-error *ngIf="newPassword.get('passwordAgain').hasError('ConfirmPassword')">
                Passsword and confirm password didn't match.
              </mat-error>
          </mat-form-field>

          <span class="container-button">
            <button 
              mat-raised-button
              color="primary"
              (click)="onSubmit()"
              [disabled]="newPassword.invalid || isLoading"
            >
              <span>{{'login.setPassword.button' | translate}}</span>
            </button>
          </span>
      </form>
  </div>
  <div class="login-footer" *ngIf="namespace?.byNeuron">{{'login.footer' | translate}}</div>
</div>


