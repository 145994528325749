import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'remove-separator'
})
export class RemoveSeparatorPipe implements PipeTransform {

  transform(value: string, separator: string): string {
    const separatorIndex = value.indexOf(separator);
    if (separatorIndex !== -1) {
      return value.substring(0, separatorIndex);
    }
    return value;
  }

}
