import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { ToastService } from '@dooh/common-services';
import { IToastData } from '@dooh/models';

import { AuthService } from '@dooh/auth';
import { SelectedInstanceService, ActiveNamespaceService } from '@dooh/common-services';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService,public toastService:ToastService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if(navigator.onLine){
      return from(this.handleAccess(request, next));
    }else{
      const data:IToastData = {
        title: 'Error occurred',
        text: 'Lost Connection',
      }
      this.toastService.showNotification(data, 'error', 20000);
    }
    return throwError({status:"0"});
  }

  async setAuthToken(request: HttpRequest<any>) {
    const token = await this.auth.getToken();
    const withAuth = request.headers.get('With-Auth');
    if(request.url.includes('/admin/smb/users') && request.method === "POST" && withAuth !== 'true')
      return request;
    if(withAuth){
      const headers = request.headers.delete('With-Auth');
      request = request.clone({ headers });
    }
    return request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  setSelectedInstanceId(request: HttpRequest<any>) {
    let instanceId = SelectedInstanceService.getInstanceId();
    if (!instanceId){
      instanceId = localStorage.getItem('smbInstance');
      if (!instanceId)
        return request;
    }
    const exceptions = [
      '/dsp/instances',
      '/ssp/instances',
      '/smb/instance',
    ]
    const urlHasException = exceptions.some(item => {
      return request.url.endsWith(item);
    })

    if (urlHasException) return request;

    return request.clone({
      setHeaders: {
        'Neuron-Selected-Instance': instanceId
      }
    })

  }

  setActiveNamespaceId(request: HttpRequest<any>) {
    let namespaceId = ActiveNamespaceService.getNamespaceId();
    let temporaryNamespace = ActiveNamespaceService.getTemporaryNamespace();
    
    if (temporaryNamespace) {
      namespaceId = temporaryNamespace.id;
    }
    
    if (!namespaceId) {
      let namespace = ActiveNamespaceService.getNamespace();
      namespaceId = namespace?.id;
      if (!namespaceId)
        return request;
    }
    const exceptions = [
      '/admin/namespace',
      '/dsp/googlemaps',
    ]
    const urlHasException = exceptions.some(item => {
      return request.url.includes(item);
    })

    if (urlHasException) return request;

    return request.clone({
      setHeaders: {
        'Neuron-Selected-Namespace': namespaceId
      }
    })

  }

  private continueWithoutAuth(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    const headers = request.headers.delete('Without-Auth');

    request = request.clone({ headers });
    return next.handle(request).toPromise();
  }

  private async handleAccess(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    const withoutAuth = request.headers.get('Without-Auth');
    if (withoutAuth || window.location.href.includes('smb-signup') || request.url.startsWith('./')) {
      return this.continueWithoutAuth(request, next);
    }
    request = await this.setAuthToken(request);
    request = this.setSelectedInstanceId(request);
    request = this.setActiveNamespaceId(request);
    return next.handle(request).toPromise();
  }
}
