import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { AuthService } from 'libs/auth/src/lib/auth.service';
import { WhoamiService } from 'libs/common-services/src/lib/whoami/whoami.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CanActivateLoginGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router){};
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService
      .isAuthenticated()
      .then(() => {
        if (WhoamiService.getCurrentUser().dspRoles[0] === 'SMB_MANAGER') {
          this.router.navigateByUrl('/instance-create');
        } else {
          this.router.navigateByUrl('/selection-panel');
        }
        return false;
      })
      .catch(() => {
        this.authService.clearLocalStorage();
        return true;
      });
  }
}
