<div class="day-container">
  <div class="day-checkbox">
    <mat-checkbox class="custom-frame" [checked]="checked" (change)="toggleRange()">
      <span>{{ day | titlecase }}</span>
    </mat-checkbox>
  </div>
  <div class="container-dynamic-hours" #container >
  </div>
  <span
    class="add-button"
    (click)="addComponent()"
    *ngIf="addActive"
  >
    <mat-icon svgIcon="plus"></mat-icon>
    <span>
      {{ 'dayHourRange.add' | translate }}
    </span>
  </span>
</div>
