import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DayScheduleForm } from '@dooh/models';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'dooh-hour-range-edit-table',
  templateUrl: './hour-range-edit-table.component.html',
  styleUrls: ['./hour-range-edit-table.component.scss']
})
export class HourRangeEditTableComponent implements OnInit {

  @Input()
  data: any;

  days: any;

  constructor(
    public translateService: TranslateService
  ) {
    this.translateService.setDefaultLang('en');
  }

  ngOnInit() {
    this.days = [];
    if (this.data === undefined) {
      return;
    }
    this.data.forEach((element: DayScheduleForm) => {
      if (!this.days[element.day]) {
        this.days[element.day] = [];
      }

      this.days[element.day].push({
        startTime: element['startTime'],
        endTime: element['endTime']
      });
    });
  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  isBothNotEmpty(item: { startTime: string, endTime: string }) {
    return !!item.startTime && !!item.endTime;
  }

}
