<div mat-dialog-title>
  <span>Copy time from {{ data?.heading | titlecase }}</span>
  <div>
    <button mat-icon-button color="primary" title="Close" (click)="onCloseClick()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
</div>

<div mat-dialog-content class="dialog-alert__content">
  <p>Copy current time ({{formatTime(startTime)}} - {{formatTime(endTime)}}) to:</p>
  <div class="days" *ngFor="let day of weekDays">
    <mat-checkbox
      color="primary"
      [checked]="
        selectedDaysToCopyTo.isSelected(day) ||
        day.toLowerCase() === selectedDay
      "
      [disabled]="isDayDisabledCheck(day)"
      [indeterminate]="isDayDisabledCheck(day) && day.toLowerCase() !== selectedDay"
      (change)="toggleDayToCopyTo(day)"
      >{{ day | titlecase }}</mat-checkbox
    >
    <p class="day__error" *ngIf="getError(day)">{{ getError(day) }}</p>
  </div>
</div>

<div
  mat-dialog-actions
  class="dialog-custom__buttons-container"
  *ngIf="data?.submitButtonText"
>
  <div class="dialog-custom__button">
    <button
      mat-flat-button
      type="button"
      color="primary"
      [mat-dialog-close]="true"
      [disabled]="selectedDaysToCopyTo.selected?.length === 0"
      (click)="onSubmitClick()"
      cdkFocusInitial
    >
      {{ data?.submitButtonText | translate }}
    </button>
  </div>
</div>
