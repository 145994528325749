import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'dooh-new-supported-formats',
  templateUrl: './new-supported-formats.component.html',
  styleUrls: ['./new-supported-formats.component.scss'],
})
export class NewSupportedFormatsComponent implements OnInit, OnChanges {
  @Input() mimeTypes =  [];
  @Input() creativeTypes =  [];
  @Input() resolution : string;
  @Input() minDuration: number;
  @Input() maxDuration: number;
  type = 'any';
  supportedFormat: any;
  imageTypes: string [] = [];
  videoTypes: string [] = [];
  otherTypes: string [] = [];
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.mimeTypes.previousValue !== changes.mimeTypes.currentValue &&
      this.mimeTypes
    ) {
      this.mimeTypes.forEach((mimeType) => {
        if (mimeType.split('/')[0] == 'image') {
          this.imageTypes.push(mimeType);
        } else if(mimeType.split('/')[0] == 'video'){
          this.videoTypes.push(mimeType);
        }
        else{
          this.creativeTypes.push('others')
          this.otherTypes.push(mimeType)
        }
      });
    }
  }

  showDuration(): string | boolean {
    if ((this.minDuration && this.minDuration > 0) && (this.maxDuration && this.maxDuration > 0)) {
      if (this.minDuration < this.maxDuration)  return `Duration: ${this.minDuration} sec to ${this.maxDuration} sec`;
      if (this.minDuration === this.maxDuration) return `Duration: ${this.minDuration} sec`;
    } else if (this.minDuration && this.minDuration > 0) {
      return `Duration: at least ${this.minDuration} sec`
    } else if (this.maxDuration && this.maxDuration > 0) {
      return `Duration: up to ${this.maxDuration} sec`
    } else {
      return false;
    }
  }
}
