
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

@NgModule({
    declarations:[],
    imports:[
      CommonModule,
      HttpClientModule,
      TranslateModule.forRoot({
          loader: { 
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
          }
      })
    ],
    exports: [TranslateModule]
})
export class InternalizationModule{
    static forRoot(): ModuleWithProviders<InternalizationModule>{
        return {
            ngModule: InternalizationModule
        };
    }
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}