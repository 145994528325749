import { CreativeView, TableCustomizer } from '@dooh/models';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';

export abstract class CreativeObject {
  selectedCreatives: CreativeView[] = [];
  deselectedCreatives: CreativeView[] = [];
  tableCustomizer: TableCustomizer;
  tableName = 'creatives';
  page = 1;
  pageSize = 50;
  sortValue = ['created_at, desc'];
  totalElements: number;
  creatives: MatTableDataSource<CreativeView> = new MatTableDataSource<CreativeView>();
  selectedElements: Subject<{ action: string; items: string[] }> = new Subject<{
    action: string;
    items: string[];
  }>();

  idExtractor(element) {
    return element.id;
  }

  triggerAction($event: { element: CreativeView; action: string }) {
    const creative = $event.element;
    if ($event.action === 'SELECT') {
      this.selectedCreatives.push(creative);
      this.deselectedCreatives = this.deselectedCreatives.filter(
        (item) => this.idExtractor(item) !== this.idExtractor(creative)
      );
    } else if ($event.action === 'DESELECT') {
      this.selectedCreatives = this.selectedCreatives.filter(
        (item) => this.idExtractor(item) !== this.idExtractor(creative)
      );
      this.deselectedCreatives.push(creative);
    }
    if ($event.action === 'DELETE') {
      this.deleteSingleItem($event.element);
    } else if ($event.action === 'ARCHIVE') {
      this.archiveSingleItem($event.element);
    } else if ($event.action === 'UNARCHIVE') {
      this.unarchiveSingleItem($event.element);
    }
  }

  selectAllMaster($event: { checked: boolean }) {
    this.selectedCreatives = $event.checked ? this.creatives.data : [];
    this.deselectedCreatives = [];
  }

  abstract archiveSingleItem(element: CreativeView);

  abstract deleteSingleItem(element: CreativeView);

  unarchiveSingleItem(element: CreativeView) {
  }
}
