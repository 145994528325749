import { Injectable, ComponentFactoryResolver, ViewContainerRef, ComponentRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AddDynamicComponentService {

  constructor(private factoryResolver: ComponentFactoryResolver) {}

  addDynamicComponent(component, container: ViewContainerRef) {
    if (!container || !component) {
      return null;
    }

    const componentFactory = this.factoryResolver.resolveComponentFactory(component);

    const componentRef = container.createComponent(componentFactory);
    componentRef.instance['componentRef'] = componentRef;
    return componentRef;
  }

  removeAllDynamicChildren(container: ViewContainerRef) {
    if (!container) {
      return;
    }
    while (container.length) {
      container.remove(container.length - 1);
    }
  }

  destroyComponent(component: ComponentRef<any>) {
    if (component) {
      component.destroy();
    }
  }

}
