import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@dooh/api';
import { AutocompleteService } from '@dooh/components-library';
import { CustomHttpUrlEncodingCodec } from '@dooh/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublishersService{

  constructor(
    private API: ApiService
  ) { }

  getAll(page = 1, pageSize = 50, search): Observable<any[]>{
    const queryUrl = 'dsp/filtered_screens_tag';
    let searchTag = '';
    if(search[0]?.split(',')[2]?.split('%')[1])
      searchTag = search[0]?.split(',')[2]?.split('%')[1]?.toLowerCase() ;

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search_string', <any>searchTag);
    }
    queryParameters = queryParameters.set('tags', 'publisher,ssp')

    return this.API.get(queryUrl, queryParameters);
  }

  public searchScreens(search, page = 1, pageSize = 50): Observable<any[]> {
    const queryUrl = `dsp/screens_tag`;

    const searchTag = !!search ? '*' + search.toLowerCase() + '*': '**';
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search_string', <any>searchTag);
    }

    return this.API.get(queryUrl, queryParameters);
  }
}
