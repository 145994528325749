import {
  Component,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  ViewChild,
  ContentChild,
  TemplateRef,
  ElementRef,
  NgZone,
  ChangeDetectorRef,
} from '@angular/core';

import { curveMonotoneX } from 'd3-shape';
import { scaleBand, scaleLinear, scalePoint, scaleTime } from 'd3-scale';
import {
  BaseChartComponent,
  LineSeriesComponent,
  ViewDimensions,
  ColorHelper,
  calculateViewDimensions,
} from '@swimlane/ngx-charts';

import { AbbreviatePipe } from '@dooh/common-pipes';
import { DecimalPipe } from '@angular/common';
import { SelectedInstanceService } from '@dooh/common-services';
import { PLACEHOLDER } from '@dooh/utils';

export type ComboChartData = {
  xAxisLabel: string,
  yAxisLabelLeft: string,
  yAxisLabelRight?: string,
  lineChart?: {
    name: string,
    series: {
      name: string,
      value: number,
    }[],
  }[],
  results: {
    name: string,
    series: {
      name: string,
      value: number,
    }[],
  }[],
  customColors: {
    name: string,
    value: string,
  }[],
};

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dooh-combo-chart',
  templateUrl: './combo-chart.component.html',
  styleUrls: ['./combo-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ComboChartComponent extends BaseChartComponent {
  @Input() curve: any = curveMonotoneX;
  @Input() showLegend = false;
  @Input() legendTitle = 'Legend';
  @Input() legendPosition = 'right';
  @Input() xAxis = true;
  @Input() yAxis = true;
  @Input() showXAxisLabel = false;
  @Input() showLeftYAxisLabel = false;
  @Input() showRightYAxisLabel = false;
  @Input() xAxisLabel;
  @Input() yAxisLabelLeft;
  @Input() yAxisLabelRight;
  @Input() showGridLines = true;
  @Input() activeEntries: any[] = [];
  @Input() scheme = 'ocean';
  @Input() schemeType: string;
  @Input() xAxisTickFormatting: any;
  @Input() yAxisTickFormatting: any;
  @Input() yRightAxisTickFormatting: any;
  @Input() maxXAxisTickLength: number = 16;
  @Input() roundDomains = true;
  @Input() autoScale;
  @Input() lineChart: any;
  @Input() yLeftAxisScaleFactor: any;
  @Input() yRightAxisScaleFactor: any;
  @Input() rangeFillOpacity: number;
  @Input() animations = true;
  @Input() noBarWhenZero = true;
  @Input() chartHeight = 350;
  @Input() leftOffset: number = 0;
  @Input() isCurrencyChart: boolean = false;
  @Input() lineChartIsCurrency: boolean = false;
  @Input() barChartIsCurrency: boolean = false;
  @Input() trimXAxisTicks: boolean = true;

  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Output() deactivate: EventEmitter<any> = new EventEmitter();

  @ContentChild('tooltipTemplate') tooltipTemplate: TemplateRef<any>;

  @ViewChild(LineSeriesComponent) lineSeriesComponent: LineSeriesComponent;

  dims: ViewDimensions;
  xScale: any;
  yScale: any;
  xDomain: any;
  yDomain: any;
  transform: string;
  colors: ColorHelper;
  margin: any[] = [10, 32, 10, -20];
  xAxisHeight = 0;
  yLeftAxisWidth = 0;
  yRightAxisWidth = 0;
  legendOptions: any;
  scaleType = 'linear';
  xScaleLine;
  yScaleLine;
  xDomainLine;
  yDomainLine;
  seriesDomain;
  scaledAxis;
  combinedSeries;
  xSet;
  yOrientLeft = 'left';
  yOrientRight = 'right';
  legendSpacing = 0;
  bandwidth;
  barPaddingOuter = 0.2;
  barPaddingInner = 0.6;
  maxLeftYValue;
  maxRightYValue;
  axisYLeftTicks;
  axisYRightTicks;
  abbreviatePipe: AbbreviatePipe = new AbbreviatePipe();
  instanceCurrency: string;
  currencyPlaceholder = PLACEHOLDER.CURRENCY;
  
  constructor(private _decimalPipe: DecimalPipe, public chartElement: ElementRef, public zone: NgZone, public cd: ChangeDetectorRef) {
    super(chartElement,zone,cd);
    this.instanceCurrency = SelectedInstanceService.getInstanceCurrency();
  }

  toTwoDecimalPlace(num){
    return parseFloat(this._decimalPipe.transform(num, '1.2-2'));
  }

  floorValue(value: any): any {
    if (typeof value === 'number') {
      return Math.round(value);
    } else {
      return value;
    }
  }

  trackBy(index, item): string {
    return item.name;
  }
  
  update(): void {
    super.update();
    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin,
      showXAxis: this.xAxis,
      showYAxis: this.yAxis,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yLeftAxisWidth + this.yRightAxisWidth,
      showXLabel: this.showXAxisLabel,
      showYLabel: this.showLeftYAxisLabel,
      showLegend: this.showLegend,
      legendType: this.schemeType,
      legendPosition: this.legendPosition,
    });
    if (!this.yAxis) {
      this.legendSpacing = 0;
    } else if (this.showLeftYAxisLabel && this.yAxis) {
      this.legendSpacing = 100;
    } else {
      this.legendSpacing = 40;
    }

    this.initScalesBar();
    this.initScalesLine();

    this.syncLines();
    // this.syncTicks();

    this.seriesDomain = this.getSeriesDomain();

    this.setColors();

    this.legendOptions = this.getLegendOptions();

    this.transform = `translate(${this.dims.xOffset + this.leftOffset} , ${this.margin[0]})`;
  }
  

  getContainerDims(): any {
    let width;
    let height;
    const hostElem = this.chartElement.nativeElement;
    const wrapper = hostElem.querySelector('.combo-chart');

    if (wrapper !== null) {
      // Get the container dimensions
      const dims = wrapper.getBoundingClientRect();
      width = dims.width;
      height = dims.height;
    }

    if (width && height) {
      return { width, height };
    }

    return null;
  }

  getAxisYRightLabelColor() {
    if (!this.colors || !this.colors.getColor) return;
    return '#ee7947'
  }

  initSizes() {
    if (this.view) {
      this.width = this.view[0];
      this.height = this.view[1];
    } else {
      const dims = this.getContainerDims();
      if (dims) {
        this.width = dims.width;
        this.height = dims.height;
      }
    }

    // default values if width or height are 0 or undefined
    if (!this.width) {
      this.width = 600;
    }

    if (!this.height) {
      this.height = 400;
    }
  }

  initScalesBar() {
    this.xDomain = this.getXDomain();
    this.yDomain = this.getYDomain();

    this.scaleBar();
  }

  initScalesLine() {
    if (this.lineChart) {
      this.xDomainLine = this.getXDomainLine();
      this.yDomainLine = this.getYDomainLine();
      this.scaleLines();
    }
  }

  abbreviateTick = (tick: number): string => this.abbreviatePipe.transform(tick);

  syncTicks() {
    const maxScaleTicks = Math.floor(this.dims.height / 50);
    const yLeftTicks = this.yScale.ticks();
    const yLeftRenderedTicks = this.yScale.ticks(maxScaleTicks);
    const yRightTicks = this.yScaleLine.ticks();
    const yRightRenderedTicks = this.yScaleLine.ticks(maxScaleTicks);

    const _sync = (ticks, renderedTicks) => {
      if (ticks[ticks.length - 1] === renderedTicks[renderedTicks.length - 1]) return;
      return [
        ...renderedTicks,
        this.getMaxValue(
          renderedTicks[renderedTicks.length - 1],
          renderedTicks[1],
          1
        )
      ]
    }
    this.axisYLeftTicks = _sync(yLeftTicks, yLeftRenderedTicks);
    this.axisYRightTicks = _sync(yRightTicks, yRightRenderedTicks);
  }

  getMaxValue(value, step, count) {
    return value + step * count;
  }

  syncLines() {
    if (this.lineChart) {
      const leftYlines = this.yScale.ticks();
      const rightYlines = this.yScaleLine.ticks();
      if (leftYlines.length === rightYlines.length) return;
      if (leftYlines.length > rightYlines.length) {
        this.maxRightYValue = this.getMaxValue(
          rightYlines[rightYlines.length - 1],
          rightYlines[1],
          leftYlines.length - rightYlines.length);
        this.initScalesLine();
      } else {
        this.maxLeftYValue = this.getMaxValue(
          leftYlines[leftYlines.length - 1],
          leftYlines[1],
          rightYlines.length - leftYlines.length);
        this.initScalesBar();
      }
    }
  }

  scaleBar() {
    this.xScale = this.getXScale(this.xDomain, this.dims.width);
    this.yScale = this.getYScale(this.yDomain, this.dims.height);
  }

  scaleLines() {
    if (this.lineChart) {
      this.xScaleLine = this.getXScaleLine(this.xDomainLine, this.dims.width);
      this.yScaleLine = this.getYScaleLine(this.yDomainLine, this.dims.height);
    }
  }

  getSeriesDomain(): any[] {
    // Data for tooltips

    this.combinedSeries = [];
    const result = this.results.reduce((res, item) => {
      const name = item.name;
      item.series.forEach(i => {
        res[i.name] = res[i.name] || [];
        res[i.name].push({
          name,
          value: i.value
        })
      })
      return res;
    }, {});
    Object.keys(result).forEach(key => {
      this.combinedSeries.push({
        name: key,
        series: result[key]
      })
    })
    this.combinedSeries = [
      ...this.combinedSeries,
      ...this.lineChart ? this.lineChart.slice(0) : [],
    ];

    return this.combinedSeries.map((d) => d.name);
  }

  isDate(value): boolean {
    return value instanceof Date;
  }

  getScaleType(values): string {
    let date = true;
    let num = true;

    for (const value of values) {
      if (!this.isDate(value)) {
        date = false;
      }

      if (typeof value !== 'number') {
        num = false;
      }
    }

    if (date) return 'time';
    if (num) return 'linear';
    return 'ordinal';
  }

  getXDomainLine(): any[] {
    let values = [];

    for (const results of this.lineChart) {
      for (const d of results.series) {
        if (!values.includes(d.name)) {
          values.push(d.name);
        }
      }
    }

    this.scaleType = this.getScaleType(values);
    let domain = [];

    if (this.scaleType === 'time') {
      const min = Math.min(...values);
      const max = Math.max(...values);
      domain = [min, max];
    } else if (this.scaleType === 'linear') {
      values = values.map((v) => Number(v));
      const min = Math.min(...values);
      const max = Math.max(...values);
      domain = [min, max];
    } else {
      domain = values;
    }

    this.xSet = values;
    return domain;
  }

  getYDomainLine(): any[] {
    // right Y min/max values
    const domain = [];

    this.lineChart.forEach((results, index)=>{
      results.series.forEach((d,dIndex)=>{
        if(!this.isCurrencyChart){
          const value:number = parseFloat(this.lineChart[index].series[dIndex].value.toString().replace(/,/g, ""));
          if(this.lineChartIsCurrency){this.lineChart[index].series[dIndex].value = this.toTwoDecimalPlace(value)}
          else{this.lineChart[index].series[dIndex].value = Math.round(value)}
        }
        if (domain.indexOf(d.value) < 0) {
          domain.push(d.value);
        }
        if (d.min !== undefined) {
          if (domain.indexOf(d.min) < 0) {
            domain.push(d.min);
          }
        }
        if (d.max !== undefined) {
          if (domain.indexOf(d.max) < 0) {
            domain.push(d.max);
          }
        }
      })
    })
    let min = Math.min(...domain);
    const max = this.maxRightYValue ? this.maxRightYValue : Math.max(...domain);
    if (this.yRightAxisScaleFactor) {
      const minMax = this.yRightAxisScaleFactor(min, max);
      return [Math.min(0, minMax.min), minMax.max];
    } else {
      min = Math.min(0, min);
      return [min, max];
    }
  }

  getXScaleLine(domain, width): any {
    let scale;
    const offset = 1 + (this.xScale.step() * this.barPaddingOuter)

    if (this.scaleType === 'time') {
      scale = scaleTime().range([0, width]).domain(domain);
    } else if (this.scaleType === 'linear') {
      scale = scaleLinear().range([0, width]).domain(domain);

      if (this.roundDomains) {
        scale = scale.nice();
      }
    } else if (this.scaleType === 'ordinal') {
      scale = scalePoint()
        .range([
          offset + this.bandwidth / 2,
          width - offset - this.bandwidth / 2,
        ])
        .domain(domain);
    }

    return scale;
  }

  getYScaleLine(domain, height): any {
    // right Y lines
    const scale = scaleLinear().range([height, 0]).domain(domain);

    return this.roundDomains ? scale.nice() : scale;
  }

  getXDomain(): any[] {
    return this.results.map((d) => d.name);
  }

  getYDomain() {
    // left Y min/max values
    const values = this.results.map((d, dIndex) => {
      let summ = 0
      d.series.forEach((s,sIndex) =>{
        summ = summ + s.value;

        if(!this.isCurrencyChart){
          const value:number = parseFloat(this.results[dIndex].series[sIndex].value.toString().replace(/,/g, ""));
          if(this.barChartIsCurrency){this.results[dIndex].series[sIndex].value = this.toTwoDecimalPlace(value)}
          else{this.results[dIndex].series[sIndex].value = Math.round(value)}
        }

      })
      return summ
    });
    
    const min = Math.min(0, ...values);
    const max = this.maxLeftYValue ? this.maxLeftYValue : Math.max(...values);
    if (this.yLeftAxisScaleFactor) {
      const minMax = this.yLeftAxisScaleFactor(min, max);
      return [Math.min(0, minMax.min), minMax.max];
    } else {
      return [min, max];
    }
  }

  getXScale(domain, width): any {
    return scaleBand()
      .range([0, width])
      .paddingInner(this.barPaddingInner)
      .paddingOuter(this.barPaddingOuter)
      .domain(domain)
      .round(true);
  }

  getYScale(domain, height): any {
    // left Y lines
    const scale = scaleLinear()
      .range([height, 0])
      .domain(domain);
    return this.roundDomains ? scale.nice() : scale;
  }

  setColors(): void {
    let domain;
    if (this.schemeType === 'ordinal') {
      domain = this.xDomain;
    } else {
      domain = this.yDomain;
    }
    this.colors = new ColorHelper(
      this.scheme,
      this.schemeType,
      domain,
      this.customColors
    );
  }

  getLegendOptions() {
    const opts = {
      scaleType: this.schemeType,
      colors: undefined,
      domain: [],
      title: undefined,
      position: this.legendPosition,
    };
    if (opts.scaleType === 'ordinal') {
      opts.domain = this.seriesDomain;
      opts.colors = this.colors;
      opts.title = this.legendTitle;
    } else {
      opts.domain = this.seriesDomain;
      opts.colors = this.colors.scale;
    }
    return opts;
  }

  updateLineWidth(width): void {
    this.bandwidth = width;
    this.scaleLines();
  }

  updateYLeftAxisWidth({ width }): void {
    this.yLeftAxisWidth = width;
    this.update();
  }

  updateYRightAxisWidth({ width }): void {
    this.yRightAxisWidth = width;
    this.update();
  }

  updateXAxisHeight({ height }): void {
    this.xAxisHeight = height;
    this.update();
  }

  groupTransform(group) {
    return `translate(${this.xScale(group.name) || 0}, 0)`;
  }

  onClick(data) {
    this.select.emit(data);
  }

  onActivate(item) {
    const idx = this.activeEntries.findIndex((d) => {
      return (
        d.name === item.name &&
        d.value === item.value &&
        d.series === item.series
      );
    });
    if (idx > -1) {
      return;
    }

    this.activeEntries = [item, ...this.activeEntries];
    this.activate.emit({ value: item, entries: this.activeEntries });
  }

  onDeactivate(item) {
    const idx = this.activeEntries.findIndex((d) => {
      return (
        d.name === item.name &&
        d.value === item.value &&
        d.series === item.series
      );
    });

    this.activeEntries.splice(idx, 1);
    this.activeEntries = [...this.activeEntries];

    this.deactivate.emit({ value: item, entries: this.activeEntries });
  }
}
