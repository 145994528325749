<div class="login-container">
  <div class="login-container__login login">
    <div class="login__title">Create Instance</div>
    <div class="login__subtitle">You need to create an instance to attach the campaings to it</div>
    <form class="login__form login-form" [formGroup]="instanceForm">
      <div class="businessName">{{businesName}}</div>
      <dooh-autocomplete-field
        class="login-form__field"
        formControlName="countryCode"
        searchBy="name"
        [resetOptionsOnFocus]="true"
        [label]="'adminPanel.generalInfo.form.field.country.label' | translate"
        [service]="countriesService"
        [errors]="autocompleteValidationErrors"
        [isLoading]="isLoadingCountry"
      ></dooh-autocomplete-field>

      <dooh-autocomplete-field
        class="login-form__field"
        formControlName="currencyCode"
        searchBy="code"
        [label]="'adminPanel.generalInfo.form.field.currency.label' | translate"
        [service]="smbCurrenciesService"
        [errors]="autocompleteValidationErrors"
      ></dooh-autocomplete-field>

      <dooh-autocomplete-field
        class="login-form__field"
        formControlName="timezone"
        searchBy="displayName"
        searchDelay="500"
        codeSearchBy="city"
        reloadOptionsOnFocus="true"
        groupValuesBy="offset"
        [label]="'adminPanel.generalInfo.form.field.timezone.label' | translate"
        [service]="timezonesService"
        [getGroupSeparator]="getTimezoneSeparator.bind(this)"
        [errors]="autocompleteValidationErrors"
      ></dooh-autocomplete-field>
      <div class="timezone-error" *ngIf="isSelectedTimezoneOutsideSelectedCountry">
        <mat-error>{{ 'adminPanel.generalInfo.form.field.timezone.error' | translate }}</mat-error>
      </div>

      <mat-form-field class="login-form__field" appearance="outline">
        <mat-label>{{'login.smbLogin.businessWebsite' | translate}}</mat-label>
        <input matInput
               formControlName="businessWebsite"
               name="businessWebsite"
               placeholder="{{'login.smbLogin.businessWebsite' | translate}}"
               type="text"
               maxlength="255">
        <mat-error *ngIf="instanceForm.controls['businessWebsite'].hasError('pattern') &&
        !(instanceForm.controls['businessWebsite'].hasError('minlength') || instanceForm.controls['businessWebsite'].hasError('maxlength'))">
        {{'login.smbLogin.errors.websiteError' | translate}}
        </mat-error>
        <mat-error *ngIf="instanceForm.controls['businessWebsite'].hasError('minlength')">
          {{'login.smbLogin.errors.minLength' | translate}}
        </mat-error>
        <mat-error *ngIf="instanceForm.controls['businessWebsite'].hasError('maxlength')">
          {{'login.smbLogin.errors.maxLength' | translate}}
        </mat-error>
      </mat-form-field>

      <div class="login-form__footer">
        <button
          mat-button
          (click)="logout()"
          type="button"
        >Back to Login</button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="instanceForm.invalid"
          (click)="onSubmit()"
          type="button"
        >Create</button>
      </div>
    </form>

  </div>
  <div class="login-footer">{{'login.footer' | translate}}</div>
</div>
