import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@dooh/api';
import { Observable } from 'rxjs';
import { apiUrl } from '../../api-url';
import { environment } from 'apps/sso-webapp/src/environments/environment';
import { CustomHttpUrlEncodingCodec, NamespaceView, PageDto } from '@dooh/models';

@Injectable({
  providedIn: 'root'
})
export class NamespaceService {

  sort = ['name,asc'];
  
  constructor(private apiService: ApiService, private http: HttpClient) { }

  getAll(page: number = 1, pageSize: number = 50, filters?: Array<string>): Observable<PageDto> {

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }

    this.sort.forEach((element) => {
      queryParameters = queryParameters.append('sort', <any>element);
    });

    if (filters !== undefined && filters !== null) {
      queryParameters = queryParameters.set('filters', <any>filters);
    }

    return this.apiService.get(`${apiUrl}/namespace`, queryParameters);
  }

  createNamespace(form: any): Observable<any> {
    return this.apiService.post(`${apiUrl}/namespace`, form);
  }
  updateNamespaceGeneralInfo(namespaceId: any, form: any): Observable<any> {
    return this.apiService.patch(`${apiUrl}/namespace/${namespaceId}`, form);
  }

  uploadLogo(data: FormData): Observable<any> {
    const headersConfig = {
      'With-Auth': 'true',
      Accept: "*/*",
    };
    const headers = new HttpHeaders(headersConfig);
    return this.http.post(`${environment.api_url}/${apiUrl}/logo_uploads`, data, {headers});
  }

  updateUploadUrl(uploadUrl: string, file: File, contentType: string): Observable<any> {
    return this.apiService.putThridPartyServer(uploadUrl, file, contentType);
  }

  addPaymentSettingsToNamespace(settingsForm: any, namespaceId: any): Observable<any> {
    return this.apiService.post(`${apiUrl}/namespace/${namespaceId}/payment_settings/stripe`, settingsForm);
  }
  updateNamespacePaymentSettings(settingsForm: any, namespaceId: any): Observable<any> {
    return this.apiService.put(`${apiUrl}/namespace/${namespaceId}/payment_settings/stripe`, settingsForm);
  }

  getNamespacePaymentSettings(namespaceId: any): Observable<any> {
    return this.apiService.get(`${apiUrl}/namespace/${namespaceId}/payment_settings/stripe`);
  }

  getNamespacePublicKey(): Observable<any> {
    return this.apiService.get(`${apiUrl}/namespace/payment_settings/stripe/public_key`);
  }

  deleteNamespace(namespaceId: string): Observable<NamespaceView> {
    return this.apiService.delete(`${apiUrl}/namespace/${namespaceId}`);
  }
  getNamespaceGeneralInfo(namespaceId: string): Observable<any> {
    return this.apiService.get(`${apiUrl}/namespace/${namespaceId}`)
  }
  getNamespaceInvntory(namespaceId: string): Observable<any> {
    return this.apiService.get(`dsp/namespace_inventory/${namespaceId}`)
  }

  getAllSSPs(page: number = 1, pageSize: number = 50, sort?: Array<string>, filters?: string): Observable<PageDto> {

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }

    if (sort && sort.length) {
      queryParameters = queryParameters.set('sort', <any>sort.join(';'));
    }

    if (filters !== undefined && filters !== null) {
      queryParameters = queryParameters.set('filters', <any>filters);
    }

    return this.apiService.get(`dsp/ssps`, queryParameters);
  }

  addInventoriesToNamespace(ssps: any, namespaceId: any): Observable<any> {
    return this.apiService.post(`dsp/namespace_inventory/${namespaceId}/`, ssps);
  }

  getLatestTermsOfService(namespaceId: any): Observable<any> {
    return this.apiService.get(`${apiUrl}/terms-of-service/latest/${namespaceId}`);
  }

  uploadTermsOfService(version: any): Observable<any> {
    const headersConfig = {
      'With-Auth': 'true',
      Accept: "*/*",
    };
    const headers = new HttpHeaders(headersConfig);
    return this.http.get(`${environment.api_url}/${apiUrl}/term_uploads/${version}`, {headers});
  }

  addTermsOfServiceToNamespace(model: any, namespaceId: any): Observable<any> {
    return this.apiService.post(`${apiUrl}/terms-of-service/${namespaceId}`, model);
  }

  uploadTermsOfServiceFileUrl(uploadUrl: string, file: File, contentType: string): Observable<any> {
    return this.apiService.putThridPartyServer(uploadUrl, file, contentType);
  }

  downloadTermsOfService(url: any): Observable<any> {
    const headersConfig = {
      'Without-Auth': 'true',
      Accept: "*/*",
    };
    const headers = new HttpHeaders(headersConfig);
    return this.http.get(url, {responseType: 'blob', observe: 'response', headers});
  }

  uploadSpinner(data: FormData): Observable<any> {
    const headersConfig = {
      'With-Auth': 'true',
      Accept: "*/*",
    };
    const headers = new HttpHeaders(headersConfig);
    return this.http.post(`${environment.api_url}/${apiUrl}/spinner_uploads`, data, {headers});
  }
}
