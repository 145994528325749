import { ErrorStateMatcher } from "@angular/material/core";
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';

export default class CrossFieldErrorMatcher implements ErrorStateMatcher {
  private errors: string[];

  constructor(errors?) {
    this.errors = errors || [];
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;

    if (this.errors.length) {
      return (
        control &&
        (control.dirty || control.touched || isSubmitted)
        && (
          control.invalid
          || (form?.errors && Object.keys(form?.errors).some(key => this.errors.indexOf(key) !== -1))
        )
      );
    }

    return control && (control.dirty || control.touched || isSubmitted) && (control.invalid || form?.invalid);
  }
}
