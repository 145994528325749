import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  OnDestroy,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Options } from 'ng5-slider';

import {
  TargetingFiltersService,
  ScreensFilters,
  SCREEN_TAGS,
  MapService,
  TargetingsService,
  ActiveNamespaceService,
} from '@dooh/common-services';
import {
  FieldFilterRequest,
  LimitsSet,
  ScreensTagsSet,
  VenueType,
} from '@dooh/models';
import { ScreenPropertiesAutocompleteService } from './screen-properties.component.models';
import { Subject } from 'rxjs-compat/Subject';
import { debounceTime, map, take, takeUntil } from 'rxjs/operators';
import { ScreensService } from 'apps/smb-app/src/app/campaign-management/services/screens.service';
import { noWhitespaceValidator } from '@dooh/validation';

enum SLIDERS {
  AUDIENCE = 'audience',
  PRICE = 'price',
}

@Component({
  selector: 'dooh-screen-properties',
  templateUrl: './screen-properties.component.html',
  styleUrls: ['./screen-properties.component.scss'],
})
export class ScreenPropertiesComponent implements OnInit, OnDestroy, OnChanges {
  limits: LimitsSet;

  @Input()
  urlArgsArr: any[];

  @Input()
  screenPropertiesAutocompleteService: ScreenPropertiesAutocompleteService;

  @Input()
  screenPropertiesAutocompleteServiceArgs?: any;

  @Input()
  screensFilters: ScreensFilters;

  @Input()
  offerCondition: any;

  @Output()
  private toggleSideNav: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('venueTree') venueTree;
  public formGroupMain: FormGroup;

  sizes: string[] = [];
  publishers: string[] = [];
  venueTypes: any[] = [];
  mimeTypes: any[] = [];
  filteredVenueTypes: VenueType[] = [];

  loadingPublishers: boolean;
  loadingResolutions: boolean;
  loadingMimeTypes: boolean;

  allVenueTypes: VenueType[] = [];

  optionsPrice: Options = {
    hideLimitLabels: true,
    hidePointerLabels: true,
    animate: true,
    step: 0.1,
  };
  optionsAudience: Options = {
    hideLimitLabels: true,
    hidePointerLabels: true,
    animate: true,
  };

  isPoiLoading: boolean;

  SCREEN_TAGS = SCREEN_TAGS;

  activeFilters: any;
  tags: ScreensTagsSet;

  SLIDERS: typeof SLIDERS = SLIDERS;
  isAudienceDragging: boolean = false;
  isPriceDragging: boolean = false;
  filterByFieldSource: any = null;
  initialFieldDataSource: any = null;

  isFilteringByVenue: boolean;
  isFilteringByPublisher: boolean;
  isFilteringByResolution: boolean;
  unsubscriber$ = new Subject<void>();

  isEnabledPublisherFilter: boolean = true;
  isEnabledVenueTypeFilter: boolean = true;

  videoDurations: any[] = [ 
    { value: 6, isSelected: false},
    { value: 7, isSelected: false},
    { value: 8, isSelected: false},
    { value: 10, isSelected: false},
    { value: 15, isSelected: false}
   ];

   duration = {
    minDuration: {
      max: null,
      includeNulls: true
    },
    maxDuration: {
      min: null,
      includeNulls: true
    }
   }
   isDurationSelected: boolean;
   isFilteringByFormat: boolean;
  disablePublisher: boolean;
  disableVenueTypes: boolean;
  disableResolution: boolean;

  constructor(
    private formBuilder: FormBuilder,
    public targetingFiltersService: TargetingFiltersService,
    private mapService: MapService,
    private targetingsService: TargetingsService
  ) {}

  onDrag(name: SLIDERS, isDragging: boolean): void {
    name === SLIDERS.AUDIENCE
      ? (this.isAudienceDragging = isDragging)
      : (this.isPriceDragging = isDragging);
  }

  ngOnInit() {
    this.getAllVenueTypes();
    this.createForms();
    this.mapService.isPoiLoading$.subscribe((loading) => {
      this.isPoiLoading = loading;
    });

    this.targetingFiltersService.isScreenPropertiesActive$.subscribe(
      (isActive) => {
        const screenPropertiesStatus = this.getFiltersStatus();

        if (!isActive && screenPropertiesStatus) {
          this.onReset(true);
        }
      }
    );

    this.targetingFiltersService.tags$.subscribe((tags) => {
      this.tags = tags;

      if (!this.isFilteringByPublisher && !this.isFilteringByVenue && !this.isFilteringByResolution && !this.isFilteringByFormat) {
        this.updateFiltersValues();
      } else if (this.isFilteringByVenue && !this.isFilteringByPublisher  && !this.isFilteringByResolution) {
        this.updateFiltersByVenue();
      } else if (this.isFilteringByPublisher && !this.isFilteringByResolution){
        this.updateFiltersByPublisher();
      } else if (this.isFilteringByResolution) {
        this.updateFiltersByResolution();
      }
    });



    this.targetingFiltersService.limits$.subscribe((limits) => {
      const getSliderValue = (sliderValue) => {
        if (
          !sliderValue ||
          (typeof sliderValue === 'string' &&
            (sliderValue as string).toLowerCase().includes('infinity')) ||
          (typeof sliderValue === 'string' &&
            (sliderValue as string).toLowerCase().includes('null'))
        ) {
          return 0;
        }
        return sliderValue;
      };

      this.limits = limits;
      this.limits.audiencePerWeek.min = getSliderValue(
        this.limits.audiencePerWeek.minPossible
      );
      this.limits.audiencePerWeek.max = getSliderValue(
        this.limits.audiencePerWeek.maxPossible
      );
      this.limits.floorPrice.min = getSliderValue(this.limits.floorPrice.minPossible);
      this.limits.floorPrice.max = getSliderValue(this.limits.floorPrice.maxPossible);

      this.optionsPrice = {
        ...this.optionsPrice,
        floor: this.limits.floorPrice.min,
        ceil: this.limits.floorPrice.max,
      };
      this.optionsAudience = {
        ...this.optionsAudience,
        floor: this.limits.audiencePerWeek.min,
        ceil: this.limits.audiencePerWeek.max,
      };

      const sliderAudienceValue = [
        this.limits.audiencePerWeek.min,
        this.limits.audiencePerWeek.max,
      ];
      const sliderFloorPriceValue = [
        this.limits.floorPrice.min,
        this.limits.floorPrice.max,
      ];

      /**
       * TODO:: Without setTimeout there is a problem when slider values isn't updating.
       * It occurs because we update slider's options (min max limits) and form values at the same time.
       * Need to investigate ng5-slider docs and issues to find better solution.
       */
      setTimeout(() => {
        this.addOrUpdateSlider('sliderAudienceControl', sliderAudienceValue);
        this.addOrUpdateSlider(
          'sliderFloorPriceControl',
          sliderFloorPriceValue
        );
      });
    });

    this.targetingFiltersService.screenPropertiesFilters$.subscribe(
      (activeFilters) => {
        this.activeFilters = {
          ...activeFilters,
          tags: activeFilters.tags.reduce((storage, item) => {
            storage[item.name] = item.properties;
            return storage;
          }, {}),
        };
      }
    );

    this.targetingFiltersService.filterByFieldSource$.subscribe((res) => {
      this.filterByFieldSource = res;
    });

    this.formGroupMain
      .get('venueTypes')
      .valueChanges.pipe(
        takeUntil(this.unsubscriber$),
        debounceTime(700),
        map((value) => {
          if (typeof value === 'object') {
            if (value.length) {
              this.isFilteringByVenue = true;
              this.isFilteringByPublisher = false;
              this.isFilteringByResolution = false;
            } else {
              this.isFilteringByVenue = false;
            }

            if (!this.disableResolution) {
              this.formGroupMain.get('size').patchValue([], { onlySelf: true, emitEvent: false });
            }
            this.formGroupMain.get('mimeType').patchValue([], { onlySelf: true, emitEvent: false });
            if (!this.disablePublisher) {
              this.formGroupMain
              .get('publisher')
              .patchValue([], { onlySelf: true, emitEvent: false });
            }
            this.loadingPublishers = true;
            this.loadingResolutions = true;
            this.loadingMimeTypes = true;
            this.clearDuration();
            this.filterByField();
          }
        })
      )
      .subscribe();

    this.formGroupMain
      .get('publisher')
      .valueChanges.pipe(
        takeUntil(this.unsubscriber$),
        debounceTime(700),
        map((value) => {
          if (typeof value === 'object') {
            const isFormTouched = this.formGroupMain.get('publisher').dirty;
            if (value.length) {
              this.isFilteringByPublisher = true;
              this.isFilteringByResolution = false;
              this.isFilteringByFormat = false;
            } else {
              this.isFilteringByPublisher = false;
            }
            if (isFormTouched) {
              if (!this.disableResolution) {
                this.formGroupMain.get('size').patchValue([], { onlySelf: true, emitEvent: false });
              }
              this.loadingResolutions = true;
              this.formGroupMain.get('mimeType').patchValue([], { onlySelf: true, emitEvent: false });
              this.loadingMimeTypes = true;
              this.clearDuration();
              this.filterByField();
            }
          }

        })
      )
      .subscribe();

      this.formGroupMain
      .get('size')
      .valueChanges.pipe(
        takeUntil(this.unsubscriber$),
        debounceTime(700),
        map((value) => {
          if (typeof value === 'object') {
            const isFormTouched = this.formGroupMain.get('size').dirty;
            if (value.length) {
              this.isFilteringByResolution = true;
              this.isFilteringByFormat = false;
              // this.disableFilterButton = false;
            }else {
              this.isFilteringByResolution = false;
            }


            if (isFormTouched) {
              this.formGroupMain.get('mimeType').patchValue([], { onlySelf: true, emitEvent: false });
              this.loadingMimeTypes = true;
              this.clearDuration();
              this.filterByField();
            }
          }
        })
      )
      .subscribe();

      this.formGroupMain
      .get('mimeType')
      .valueChanges.pipe(
        takeUntil(this.unsubscriber$),
        debounceTime(700),
        map((value) => {
          if (typeof value === 'object') {
            const isFormTouched = this.formGroupMain.get('mimeType').dirty;
            if (value.length) {
              this.isFilteringByFormat = true;
            } else {
              this.isFilteringByFormat = false;
            }
            if (!value?.some(type => type === 'video')) {
              this.clearDuration();
            }

            if (isFormTouched) {
              this.filterByField();
            }
          }
        })
      )
      .subscribe();

      this.formGroupMain.get('customDurationControl')
      .valueChanges.pipe(
        takeUntil(this.unsubscriber$),
        debounceTime(700)
      ).subscribe(res => {
        const duration = res;

        if (duration && duration > 0) {
          this.videoDurations.forEach((item: any) => {
            item.isSelected = false;
          });
          this.duration.minDuration.max = duration;
          this.duration.minDuration.includeNulls = false;
          this.duration.maxDuration.min = duration;
          this.duration.maxDuration.includeNulls = false;
          this.isDurationSelected = true;
          const selectedMimeType: string[] = this.formGroupMain.get('mimeType').value;
          if (!selectedMimeType.some(type => type === 'video')) {
            selectedMimeType.push('video');
            this.formGroupMain.get('mimeType').patchValue(selectedMimeType, { onlySelf: true, emitEvent: false });
            this.isFilteringByFormat = true;
          }
        } else {
          this.duration.minDuration.includeNulls = true;
          this.duration.maxDuration.includeNulls = true;
          this.duration.minDuration.max = null;
          this.duration.maxDuration.min = null;
          this.isDurationSelected = false;
        }
        this.filterByField();
      })
      this.isEnabledPublisherFilter = ActiveNamespaceService.getNamespace().isEnabledPublisherFilter;
      this.isEnabledVenueTypeFilter = ActiveNamespaceService.getNamespace().isEnabledVenueTypeFilter;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.screensFilters = changes?.screensFilters?.currentValue;
    this.offerCondition = changes?.offerCondition?.currentValue;
    this.setOfferCondition()
  }

  updateFiltersValues() {
    this.sizes = this.tags[SCREEN_TAGS.RESOLUTIONS]
      ? this.tags[SCREEN_TAGS.RESOLUTIONS]['values']
      : [];
    this.publishers = this.tags[SCREEN_TAGS.PUBLISHERS]
      ? this.tags[SCREEN_TAGS.PUBLISHERS]['values']
      : [];
    this.venueTypes = this.tags[SCREEN_TAGS.VENUETYPE]
      ? this.tags[SCREEN_TAGS.VENUETYPE]['values']
      : [];
    this.mimeTypes = this.tags[SCREEN_TAGS.MIMETYPE]
    ? this.tags[SCREEN_TAGS.MIMETYPE]['values']
    : [];

    this.loadingPublishers = false;
    this.loadingResolutions = false;
    this.loadingMimeTypes = false;
    this.isFilteringByFormat = false;
  }

  updateFiltersByVenue() {
    this.sizes = this.tags[SCREEN_TAGS.RESOLUTIONS]
      ? this.tags[SCREEN_TAGS.RESOLUTIONS]['values']
      : [];
    this.publishers = this.tags[SCREEN_TAGS.PUBLISHERS]
      ? this.tags[SCREEN_TAGS.PUBLISHERS]['values']
      : [];
    this.mimeTypes = this.tags[SCREEN_TAGS.MIMETYPE]
    ? this.tags[SCREEN_TAGS.MIMETYPE]['values']
    : [];

    this.loadingPublishers = false;
    this.loadingResolutions = false;
    this.loadingMimeTypes = false;
    this.isFilteringByFormat = false;
  }

  updateFiltersByPublisher() {
    this.sizes = this.tags[SCREEN_TAGS.RESOLUTIONS]
      ? this.tags[SCREEN_TAGS.RESOLUTIONS]['values']
      : [];
    this.mimeTypes = this.tags[SCREEN_TAGS.MIMETYPE]
    ? this.tags[SCREEN_TAGS.MIMETYPE]['values']
    : [];

    this.loadingPublishers = false;
    this.loadingResolutions = false;
    this.loadingMimeTypes = false;
    this.isFilteringByFormat = false;
  }
  updateFiltersByResolution(): void {
    this.mimeTypes = this.tags[SCREEN_TAGS.MIMETYPE]
      ? this.tags[SCREEN_TAGS.MIMETYPE]['values']
      : [];

    this.loadingPublishers = false;
    this.loadingResolutions = false;
    this.loadingMimeTypes = false;
    this.isFilteringByFormat = false;
  }

  createForms() {
    this.formGroupMain = this.formBuilder.group({
      size: { value: [], disabled: false },
      publisher: { value: [], disabled: false },
      venueTypes: { value: [], disabled: false },
      mimeType: { value: [], disabled: false },
      customDurationControl: new FormControl(null, [noWhitespaceValidator, Validators.min(1)])
    });
  }

  onNoClick() {
    this.toggleSideNav.emit(true);
  }

  filterProperties(refresh?: boolean) {
    const propertyArray = [
      {
        name: 'resolution',
        properties: [...this.formGroupMain.get('size').value],
      },
      {
        name: 'publisher',
        properties: [...this.formGroupMain.get('publisher').value],
      },
      {
        name: 'venue',
        properties: [...this.formGroupMain.get('venueTypes').value],
      },
      {
        name: 'mimeType',
        properties: [...this.formGroupMain.get('mimeType').value],
      },
    ];

    const limitsToSend: any = {};

    if (!refresh) {
      const floorPriceMin = this.formGroupMain.get('sliderFloorPriceControl')?.value[0];
      const floorPriceMax = this.formGroupMain.get('sliderFloorPriceControl')?.value[1];

      if (floorPriceMax - floorPriceMin > 0) {
        limitsToSend.floorPrice = {
          min: floorPriceMin,
          max: floorPriceMax,
        };
      }

      const audienceMin = this.formGroupMain.get('sliderAudienceControl')?.value[0];
      const audienceMax = this.formGroupMain.get('sliderAudienceControl')?.value[1];

      if (audienceMax - audienceMin > 0) {
        limitsToSend.audiencePerWeek = {
          min: audienceMin,
          max: audienceMax,
        };
      }
    } else {
      limitsToSend.floorPrice = {};
      limitsToSend.audiencePerWeek = {};
    }

    if (this.isDurationSelected) {
      limitsToSend['minDuration'] = this.duration.minDuration;
      limitsToSend['maxDuration'] = this.duration.maxDuration;
      const mimeTypeIndex = propertyArray.findIndex(prop => prop.name === 'mimeType');

      if (mimeTypeIndex > -1) {
        const isVideoMimTypeSelected = propertyArray[mimeTypeIndex].properties.some(type => type === 'video');
        if (!isVideoMimTypeSelected) {
          propertyArray[mimeTypeIndex].properties.push('video');
        }
      }
    }

    const objectToEmit = { limits: limitsToSend, tags: propertyArray };
    this.targetingFiltersService.setScreenPropertiesFilters(objectToEmit);

    const screenPropertiesStatus = this.getFiltersStatus();
    this.targetingFiltersService.setScreenPropertiesStatus(
      screenPropertiesStatus
    );
    this.targetingFiltersService.toggleScreenProperties(false);
  }

  filterByField(): void {
    if (!this.filterByFieldSource) {
      this.loadingPublishers = false;
      this.loadingResolutions = false;
      this.loadingMimeTypes = false;
      this.isFilteringByFormat = false;
      return;
    }
    const propertyArray = [
      {
        name: 'mimeType',
        properties: [...this.formGroupMain.get('mimeType').value],
      },
      {
        name: 'resolution',
        properties: [...this.formGroupMain.get('size').value],
      },
      {
        name: 'publisher',
        properties: [...this.formGroupMain.get('publisher').value],
      },
      {
        name: 'venue',
        properties: [...this.formGroupMain.get('venueTypes').value],
      },
    ];

    const limitsToSend: any = {};

    const floorPriceMin = this.formGroupMain.get('sliderFloorPriceControl')
      ?.value[0];
    const floorPriceMax = this.formGroupMain.get('sliderFloorPriceControl')
      ?.value[1];

    if (floorPriceMax - floorPriceMin > 0) {
      limitsToSend.floorPrice = {
        min: floorPriceMin,
        max: floorPriceMax,
      };
    }

    const audienceMin = this.formGroupMain.get('sliderAudienceControl')
      ?.value[0];
    const audienceMax = this.formGroupMain.get('sliderAudienceControl')
      ?.value[1];

    if (audienceMax - audienceMin > 0) {
      limitsToSend.audiencePerWeek = {
        min: audienceMin,
        max: audienceMax,
      };
    }

    if (this.isDurationSelected) {
      limitsToSend['minDuration'] = this.duration.minDuration;
      limitsToSend['maxDuration'] = this.duration.maxDuration;
      const mimeTypeIndex = propertyArray.findIndex(prop => prop.name === 'mimeType');

      if (mimeTypeIndex > -1) {
        const isVideoMimTypeSelected = propertyArray[mimeTypeIndex].properties.some(type => type === 'video');
        if (!isVideoMimTypeSelected) {
          propertyArray[mimeTypeIndex].properties.push('video');
        }
      }
    }

    const objectToEmit = { limits: limitsToSend, tags: propertyArray };

    let filterField: FieldFilterRequest = {
      body: this.filterByFieldSource?.body,
      queryUrl: this.filterByFieldSource?.queryUrl,
      queryParams: this.filterByFieldSource?.queryParams,
    };

    filterField.body['limits'] = objectToEmit?.limits ?? null;
    for (const tag of objectToEmit?.tags) {
      if (tag.properties.length > 0) {
        filterField.body['params'][tag.name] = {
          filterTerms: [...tag.properties],
        };
      } else {
        delete filterField.body['params'][tag.name];
      }
    }

    this.targetingFiltersService
      .filterByField(filterField)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.targetingFiltersService.setLimits(res.limits);
        this.targetingFiltersService.setTags(res.tags);
      });
  }

  getFiltersStatus() {
    if (!this.formGroupMain.dirty) {
      return false;
    }

    const status = [
      'size',
      'publisher',
      'venueTypes',
      'mimeType'
    ].some((field) => {
      return this.formGroupMain.get(field).value.length;
    });

    if (status) {
      return status;
    }

    return (
      this.formGroupMain.get('sliderFloorPriceControl').value[0] !==
        this.limits.floorPrice.min ||
      this.formGroupMain.get('sliderFloorPriceControl').value[1] !==
        this.limits.floorPrice.max ||
      this.formGroupMain.get('sliderAudienceControl').value[0] !==
        this.limits.audiencePerWeek.min ||
      this.formGroupMain.get('sliderAudienceControl').value[1] !==
        this.limits.audiencePerWeek.max
    );
  }

  onReset(skipResetApi?: boolean) {
    if (!this.disableVenueTypes) this.venueTree?.resetSelection();
    this.formGroupMain.patchValue({
      size: this.disableResolution ? this.formGroupMain.get('size').value : [],
      mimeType:[],
      publisher: this.disablePublisher ? this.formGroupMain.get('publisher').value : [],
      venueTypes: this.disableVenueTypes ? this.formGroupMain.get('venueTypes').value : [],
      sliderAudienceControl: [
        this.limits.audiencePerWeek.min,
        this.limits.audiencePerWeek.max,
      ],
      sliderFloorPriceControl: [
        this.limits.floorPrice.min,
        this.limits.floorPrice.max,
      ],
    }, { onlySelf: true, emitEvent: false });
    this.isDurationSelected = false;

    if (!skipResetApi) this.filterProperties(true);


    this.clearDuration();

    this.loadingPublishers = false;
    this.loadingResolutions = false;
    this.loadingMimeTypes = false;
    this.isFilteringByVenue = false;
    this.isFilteringByPublisher = false;
    this.isFilteringByResolution = false;
  }

  addOrUpdateSlider(key: string, value: any) {
    if (!this.formGroupMain.get(key)) {
      this.formGroupMain.addControl(key, new FormControl(value));
    } else {
      this.formGroupMain.patchValue({
        [key]: value,
      });
    }
  }

  onLoadMore($event: any, tagName: string) {
    if ($event && !$event.visible) {
      return;
    }

    this.targetingFiltersService.nextTagsPage(tagName);
  }

  filterSelected(selectedTags: string[], tags: string[]) {
    if (!selectedTags.length) {
      return;
    }

    const tagsMap = tags.reduce((storage, tag) => {
      storage[tag] = true;
      return storage;
    }, {});

    return selectedTags.filter((tag) => !tagsMap[tag]);
  }

  getAllVenueTypes(): void {
    this.targetingsService
      .getAllVenueTypes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        const allVenueTypes: VenueType[] = res?.venueTypes?.categories || [];


        this.allVenueTypes = allVenueTypes.map((venueType) => ({
          ...venueType,
          isSelected: false,
        }));


        this.allVenueTypes.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  changeVenueType(event: any): void {
    const allVenueTypeId = [];
    event.forEach((item) => {
      allVenueTypeId.push(item?.id);
    });
    this.formGroupMain.get('venueTypes').patchValue(allVenueTypeId);
  }

  filterNestedObj(arr: VenueType[], filterVal: string): VenueType[] {
    let newArr = arr.filter((item: VenueType) => {
      let containsValue;
      if (item?.enumeration_id === +filterVal) return item;

      if (item.children) {
        const children = this.filterNestedObj(item.children, filterVal);
        containsValue = children.length > 0;
        item.children = containsValue ? children : null;
      }
      return containsValue;
    });
    return newArr;
  }

  roundedFigure(value: number): number {
    return Math.round(value);
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();

    this.targetingFiltersService.setFilterByFieldSource(null);
  }


  toggleDuration(index: any): void {
    this.videoDurations[index].isSelected = !this.videoDurations[index].isSelected;

    this.videoDurations.forEach((item: any, i: number) => {
      if (i !== index) {
        item.isSelected = false;
      }
    });

    const activeSeconds = this.videoDurations.find(x => x.isSelected)?.value;
    this.clearCustomDuration();
    if (activeSeconds) {
      this.duration.minDuration.max = activeSeconds;
      this.duration.minDuration.includeNulls = false;
      this.duration.maxDuration.min = activeSeconds;
      this.duration.maxDuration.includeNulls = false;
      this.isDurationSelected = true;
      const selectedMimeType: string[] = this.formGroupMain.get('mimeType').value;
      if (!selectedMimeType.some(type => type === 'video')) {
        selectedMimeType.push('video');
        this.formGroupMain.get('mimeType').patchValue(selectedMimeType, { onlySelf: true, emitEvent: false });
        this.isFilteringByFormat = true;
      }
    } else {
      this.duration.minDuration.includeNulls = true;
      this.duration.maxDuration.includeNulls = true;
      this.duration.minDuration.max = null;
      this.duration.maxDuration.min = null;
      this.isDurationSelected = false;
    }
    this.filterByField();
  }

  clearCustomDuration(reset?: boolean): void {
    this.formGroupMain.get('customDurationControl').patchValue(null, { emitEvent: false, onlySelf: true });
    this.isDurationSelected = false;
    if(reset) this.filterByField();
  }

  clearDuration(): void {
    this.duration = {
      minDuration: {
        max: null,
        includeNulls: true
      },
      maxDuration: {
        min: null,
        includeNulls: true
      }
     };
     this.clearCustomDuration();
     this.videoDurations.forEach((item: any) => {
      item.isSelected = false;
     });
     this.isDurationSelected = false;

  }

  isVideoMimeTypeAvailable(): boolean {
    return this.mimeTypes.some(mime => mime === 'video') || this.isDurationSelected;
  }

  setOfferCondition(): void {
    if (!this.offerCondition) return;
      const { publishers, resolutions, venueTypes } = this.offerCondition;
      if (publishers) {
        const publisherNames = publishers.map(publisher => `${publisher?.name}`);
        this.formGroupMain?.get('publisher').patchValue([...publisherNames], { emitEvent: false, onlySelf: true });
        this.disablePublisher = true;
      }
      if (resolutions) {
        const resolutionsStr = resolutions.map(resolution => `${resolution?.width}x${resolution?.height}`);
        this.formGroupMain?.get('size').patchValue([...resolutionsStr], { emitEvent: false, onlySelf: true });
        this.disableResolution = true
      }
      if (venueTypes && venueTypes?.length > 0) {
        this.disableVenueTypes = true;
        const venueTypeIds = [];
        venueTypes.forEach(venueType => {
          venueTypeIds.push(venueType?.id)
        })
        this.formGroupMain?.get('venueTypes')?.patchValue(venueTypeIds, { emitEvent: false, onlySelf: true });
      }
  }
}
