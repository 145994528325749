<div class="inventory-wrapper">
  <p class="inventory-title">Addes SSPs</p>

  <div class="inventory-table">
    <div class="inventory-table-container__overlay" *ngIf="isLoading">
      <dooh-custom-loader></dooh-custom-loader>
    </div>
    <form [formGroup]="sspForm">
      <div formArrayName="ssps">
        <table class="table" *ngIf="allSSPs.length > 0">
          <tbody>
            <tr class="bordered header">
              <td>SSP</td>
              <td><span>Exclude Publishers</span></td>
              <td><span>Include Only Publishers</span></td>
            </tr>
            <ng-container  *ngFor="let ssp of ssps.controls; let i = index">
              <ng-container [formGroup]="ssp">
                <tr
                class="bordered"
                (click)="setSspId(ssp.get('id').value)"
              >
                <td>
                  <mat-checkbox color="primary" [labelPosition]="'after'" formControlName="selected" (change)="toggleSSPSelection(ssp.get('id').value, i)">
                    {{ ssp.get('name').value }}
                  </mat-checkbox>
                </td>
                <td>
                  <mat-radio-button [value]="namespaceInventoryMode.Exclude" [checked]="ssp.get('mode').value === namespaceInventoryMode.Exclude" color="primary" [name]="ssp.get('id').value" [disabled]="!isSSPSelected(ssp.get('id').value)" (change)="toggleSspMode($event, i)">
                    <dooh-autocomplete-field
                      class="publisher__field"
                      searchBy="name"
                      [label]="
                        'adminPanel.namespaces.form.labels.publisher' | translate
                      "
                      [multi]="true"
                      formControlName="exclude"
                      [service]="namespaceSspService"
                      [readonly]="!isSSPSelected(ssp.get('id').value) || ssp.get('mode').value !== namespaceInventoryMode.Exclude"
                      [loadDataOnBlur]="false"
                      (selectedValue)="publisherChange($event, i, 'exclude')"
                    ></dooh-autocomplete-field>
                  </mat-radio-button>
                </td>
                <td>
                  <mat-radio-button [value]="namespaceInventoryMode.Include" [checked]="ssp.get('mode').value === namespaceInventoryMode.Include" color="primary" [name]="ssp.get('id').value" [disabled]="!isSSPSelected(ssp.get('id').value)" (change)="toggleSspMode($event, i)">
                      <dooh-autocomplete-field
                        class="publisher__field"
                        searchBy="name"
                        [label]="
                          'adminPanel.namespaces.form.labels.publisher' | translate
                        "
                        [multi]="true"
                        formControlName="include"
                        [service]="namespaceSspService"
                        [readonly]="!isSSPSelected(ssp.get('id').value) || ssp.get('mode').value !== namespaceInventoryMode.Include"
                        (selectedValue)="publisherChange($event, i, 'include')"
                        [loadDataOnBlur]="false"
                      ></dooh-autocomplete-field>
                    </mat-radio-button>
                </td>
              </tr>
              </ng-container>
              
            </ng-container>
            
          </tbody>
        </table>
      </div>
      
    </form>
    
  </div>
</div>
