import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ScreensApi } from '../screens-view/screens-view.component.models';
import { Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { DialogService } from '../dialogs/dialog.service';
import {
  ColumnCustomizer,
  ColumnType,
  PageSizes,
  ScreenView,
  TableColumn,
  TableCustomizer,
} from '@dooh/models';
import { SelectionModel } from '@angular/cdk/collections';
import { TargetingsService } from '@dooh/common-services';

const PAGE_SIZE = 10;
@Component({
  selector: 'dooh-campaign-packages',
  templateUrl: './campaign-packages.component.html',
  styleUrls: ['./campaign-packages.component.scss'],
})
export class CampaignPackagesComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  screensApi: ScreensApi;
  @Output()
  allowSave: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  packageId: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  detachPackageRelationalId: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  detachPackageScreensRelationalId: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  selectedPackageToView: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('formats') formatTemp: any;
  unsubscriber$ = new Subject<void>();

  selection = new SelectionModel<any>(false, []);
  selectedPackage: any;
  selectedElements: Subject<{ action: string; items: string[] }> = new Subject<{
    action: string;
    items: string[];
  }>();
  packages = {
    ...this.getTableConfig(),
    apiCall: () => {
      (this.packages.subscription = this.screensApi
        .getAvailablePackages(
          this.packages.currentPage,
          this.packages.pageSize,
          this.packages.sort
        )
        .subscribe((res) => {
          this.packages.dataSource.data = res?.content;
          this.packages.totalElements = res?.totalElements;

          this.packages.isResetTable = false;
          this.packages.isLoading = false;

          const relationPackage = this.packages.dataSource.data.find(
            (item) => item?.hasRelation
          );

          if (relationPackage) {
            this.togglePackageSelection(relationPackage);
          }
        })),
        (err: any) => {
          this.packages.isLoading = false;
          let message = 'Something went wrong. Please try again.';
          if (err?.error?.systemMessage) {
            message = err?.error?.systemMessage;
          }
          this.dialogService.alert({
            type: 'error',
            message,
          });
        };
    },
  };
  packageScreens = {
    ...this.getTableConfig(),
    apiCall: (refresh?: boolean) => {
      (this.packages.subscription = this.screensApi
        .getPackageScreens(
          this.selectedPackage?.packageId,
          this.packageScreens.currentPage,
          this.packageScreens.pageSize,
          this.packageScreens.sort
        )
        .subscribe((res) => {
          this.packageScreens.dataSource.data = refresh
            ? res?.content
            : [...this.packageScreens.dataSource.data, ...res?.content];
          this.packageScreens.totalElements = res?.totalElements;
          this.packageScreens.syncData()

          this.packageScreens.isResetTable = false;
          this.packageScreens.isLoading = false;
        })),
        (err: any) => {
          this.packageScreens.isLoading = false;
          let message = 'Something went wrong. Please try again.';
          if (err?.error?.systemMessage) {
            message = err?.error?.systemMessage;
          }
          this.dialogService.alert({
            type: 'error',
            message,
          });
        };
    },
    syncData: () => {
      this.packageScreens.data = [...this.packageScreens.dataSource.data]
      this.selectedElements.next({
        action: 'RESET',
        items: [],
      });

      const selectedScreenIds = this.packageScreens.selectedItems.map(screen => screen?.id);
      // const selectedScreenIds = allScreens.filter(screen => deselectedScreens.indexOf(screen) < 0);
      this.selectedElements.next({
        action: 'SELECT',
        items: selectedScreenIds,
      });
      // this.removedPackageScreenIds.emit(deselectedScreens?.length > 0 ? deselectedScreens : null);
    },
  };
  isShowingPackageScreens: boolean;
  flatVenueType: any;
  allVenueTypes: any;
  constructor(private dialogService: DialogService, private screenService: TargetingsService) {}

  ngOnInit(): void {
    this.loadConfiguration();
    this.getPackages();
    this.getAllVenueTypes();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
  ngAfterViewInit(): void {
    this.loadConfiguration();
  }

  getPackages(): void {
    if (this.packages.isLoading) {
      this.packages.subscription.unsubscribe();
    }
    this.packages.isLoading = true;
    this.packages.apiCall();
  }

  getPackageScreens(refresh?: boolean): void {
    if (this.packageScreens.isLoading) {
      this.packageScreens.subscription.unsubscribe();
    }
    this.packageScreens.isLoading = true;
    if (refresh) {
      this.packageScreens.currentPage = 1;
    }
    this.packageScreens.apiCall(refresh);
  }

  private getTableConfig() {
    return {
      tableCustomizer: null,
      data: [],
      dataSource: new MatTableDataSource<any>(),
      currentPage: 1,
      pageSize: PAGE_SIZE,
      totalElements: 0,
      sort: [],
      isLoading: false,
      subscription: null,
      selectedItems: [],
      isResetTable: false,
    };
  }

  private loadConfiguration(): void {
    const columnCustomizers = this.loadColumnCustomizers(true);
    this.packageScreens.tableCustomizer = new TableCustomizer(
      'package screens',
      columnCustomizers,
      false,
      null,
      null
    );
  }

  private loadColumnCustomizers(hasCheckbox: boolean): Array<ColumnCustomizer> {
    const columns: TableColumn[] = [
      {
        name: 'name',
        heading: 'Screen Name',
        valuePath: ['name'],
        type: hasCheckbox ? ColumnType.CHECKBOX_GROUP : ColumnType.STRING,
      },
      {
        name: 'formats',
        heading: 'Formats',
        valuePath: ['formats'],
        type: ColumnType.CUSTOM,
        sortDisabled: true,
        customTemplate: this.formatTemp,
      },
      {
        name: 'publisher_name',
        heading: 'Publisher Name',
        valuePath: ['publisher', 'name'],
      },
      {
        name: 'ssp_name',
        heading: 'SSP',
        valuePath: ['ssp', 'name'],
      },
      {
        name: 'venue_type',
        heading: 'Venue Type',
        valuePath: ['venueType', 'name'],
        valueExtractor: (data: any) => {
          let venueId = data?.venueType?.id || data?.venueTypeId;
          if (venueId) {
            return this.getVenueTypeName(venueId);
          } else {
            return '-';
          }
        },
      },
      {
        name: 'address_country',
        heading: 'Country',
        valuePath: ['address', 'country'],
      },
    ];

    return columns.map((column) => new ColumnCustomizer(column));
  }

  isDisable(item: any): boolean {
    return (
      this.selection.selected.length > 0 && !this.selection.isSelected(item)
    );
  }

  togglePackageSelection(data: any): void {
    this.selection.toggle(data);
    if (this.selection.isSelected(data)) {
      this.selectedPackage = data;
      if (data?.hasRelation) {
        this.detachPackageRelationalId.emit(null);
        this.packageId.emit(null);
      } else {
        this.packageId.emit(data?.packageId);
        this.viewScreens(data);
      }
    } else {
      this.selectedPackage = null;
      this.packageId.emit(null);
      if (data?.hasRelation) {
        this.detachPackageRelationalId.emit(data?.id);
      } else {
        this.detachPackageScreensRelationalId.emit(data?.id);
      }
    }
  }

  onSortChange(event: any): void {
    let sort = `${event?.name},${event?.direction}`;
    this.packageScreens.sort = [sort];
    this.getPackageScreens(true);
  }

  backToPackages(): void {
    this.isShowingPackageScreens = false;
    this.packageScreens.dataSource.data = [];
    this.packageScreens.data = [];
    this.packageScreens.totalElements = 0;
  }

  onPackagesPageChange(event: any): void {
    this.packages.pageSize = event?.pageSize;
    this.packages.currentPage = event?.pageIndex + 1;
    this.getPackages();
  }

  viewScreens(selectedPackage: any): void {
    this.selectedPackageToView.emit(selectedPackage);
  }

  triggerAction($event: { element: ScreenView; action: string }) {
    const screen = $event.element;
    if ($event.action === 'SELECT') {
      this.packageScreens.selectedItems.push(screen);
    } else if ($event.action === 'DESELECT') {
      this.packageScreens.selectedItems = this.packageScreens.selectedItems.filter(item => item.id !== screen.id);
    }
    this.packageScreens.syncData();
  }

  onTableScroll() {
    if (this.packageScreens.isLoading) {
      return;
    }
    this.packageScreens.currentPage += 1;
    this.getPackageScreens();
  }

  getVenueTypeName(id) {
    let parent = this.getVenueTypeParentName(id);
    return parent;
  }

  protected getVenueTypeParentName(id) {
    if (id) {
      if (id.length % 2 == 0) {
        const first2Str = String(id).slice(0, 2);
        const first2Num = Number(first2Str);
        let venueType = this.allVenueTypes?.filter((venue) => {
          return venue.enumeration_id === first2Num;
        });
        let venueTypeChild = this.flatVenueType?.filter((venue) => {
          return venue.enumeration_id == id;
        });
        if (venueType) {
          if (!venueTypeChild) {
            return `${venueType[0]?.name?.replace(/&amp;/g, '&')}`;
          }
          return `${venueType[0]?.name?.replace(/&amp;/g, '&')}/${
            venueTypeChild[0]?.name
          }`;
        } else {
          return '';
        }
      } else if (id.length % 2 !== 0) {
        const firstNum = Number(String(id)[0]);
        let venueType = this.allVenueTypes?.filter((venue) => {
          return venue.enumeration_id === firstNum;
        });
        let venueTypeChild2 = this.flatVenueType?.filter((venue2) => {
          return venue2.enumeration_id == id;
        });

        if (venueType) {
          if (!venueTypeChild2) {
            return `${venueType[0]?.name?.replace(/&amp;/g, '&')}`;
          }
          return `${venueType[0]?.name?.replace(/&amp;/g, '&')}/${
            venueTypeChild2[0]?.name
          }`;
        } else {
          return '';
        }
      }
      return '--';
    }
  }

  protected getAllVenueTypes(): void {
    this.screenService
      .getAllVenueTypes()
      .subscribe((res) => {
        this.allVenueTypes = res.venueTypes.categories || [];
        this.flatVenueType = this.flattenAllVenueTypes(this.allVenueTypes);
      });
  }

  protected flattenAllVenueTypes(members) {
    let children = [];

    return members.map(m => {
      if (m.children && m.children.length) {
        children = [...children, ...m.children];
      }
      return m;
    }).concat(children.length ? this.flattenAllVenueTypes(children) : children);
  };
}
