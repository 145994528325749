import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dooh-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent implements OnInit {
  placeHolderMessage: string = '';
  title: string;
  value: string;

  constructor(
    private dialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { placeHolder: string, title: string, name: string }
    ) { }

  ngOnInit(): void {
    this.placeHolderMessage = this.data.placeHolder;
    this.title = this.data.title;
    this.value = this.data.name;
  }

  proceed(): void {
    this.dialogRef.close(this.value)
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
