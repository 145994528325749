<div class="mandatory-options">

  <div class="mandatory-options__title">{{ titleKey | translate }}</div>

  <div class="mandatory-options__list mandatory-options-list-container">
    <div class="mandatory-options-list">
      <ng-container *ngFor="let option of options">
        <dooh-mandatory-option
          class="mandatory-options-list__item"
          [name]="option.name"
          [checked]="option.checked"
          [option]="option"
        >
        </dooh-mandatory-option>
      </ng-container>
    </div>
  </div>

  <dooh-error-message
    class="mandatory-options__error"
    *ngIf="hasError"
    [message]="optionsErrorMessageKey | translate"
  >
  </dooh-error-message>

</div>
