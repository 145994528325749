import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { VenueType } from '@dooh/models';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs/operators';

const SEARCH_DELAY = 300;

export class FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  isHidden: boolean;
  id: number;
  disabled: boolean;
}

@Component({
  selector: 'dooh-autocomplete-with-treeview',
  templateUrl: './autocomplete-with-treeview.component.html',
  styleUrls: ['./autocomplete-with-treeview.component.scss'],
})
export class AutocompleteWithTreeviewComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input()
  classList?: string;

  @Input()
  label: string;

  @Input()
  searchDelay = SEARCH_DELAY;

  @Input()
  searchMinLength = 0;

  @Input()
  isNeedMultiResetButton? = false;

  @Input()
  treeData: VenueType[] = [];

  @Input()
  venueTypeFilter?: string[] = [];

  @Input()
  selectedVenueTypes?: any[] = [];

  @Input()
  readonly? = false;

  @Output()
  selectedValue: EventEmitter<any[]> = new EventEmitter<any[]>();

  selectedOptions: any[] = [];
  isPanelOpened: boolean = false;
  formControl = new FormControl([]);

  filterMode: boolean;
  filteredSelectedNodes: FlatNode[] = [];

  unsubscriber$ = new Subject<void>();

  private _transformer = (node: VenueType, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      id: node.enumeration_id,
      isHidden: node.isHidden,
      disabled: node.disabled,
    };
  };

  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  checklistSelection = new SelectionModel<FlatNode>(true);

  flatNodeMap = new Map<FlatNode, VenueType>();
  allSelectedVenues: FlatNode[] = [];

  nestedNodeMap = new Map<VenueType, FlatNode>();
  noFilters: boolean;

  @HostListener('document:click', ['$event'])
  clickout(event: any): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isPanelOpened = false;
    } else {
      if (!this.readonly) this.openPanel();
    }
  }

  constructor(private eRef: ElementRef) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<FlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
  }

  ngOnInit(): void {
    this.dataSource.data = this.treeData;
    this.formControl.valueChanges
      .pipe(
        debounceTime(this.searchDelay),
        distinctUntilChanged(),
        map((value) => {
          if (typeof value === 'object') {
            return;
          }
          if (!value || value.length === 0) {
            this.filterMode = false;
            this.clearFilter();
          }
          if (
            this.searchMinLength &&
            (!value || value.length < this.searchMinLength)
          ) {
            return;
          }
          this.filterNestedObj(value);
          this.filterMode = true;
          this.treeControl.expandAll();
        }),
        takeUntil(this.unsubscriber$)
      )
      .subscribe();
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change.treeData) {
      if (change.treeData.currentValue !== change.treeData.previousValue) {
        this.dataSource.data = this.treeData;
        if (this.selectedVenueTypes) {
          this.updatePreselectedNodes();
        }
      }
    }

    if (change.venueTypeFilter) {
      if (change.venueTypeFilter.currentValue) {
        this.filterByTagIds(change.venueTypeFilter.currentValue);
        this.treeControl.collapseAll();
      }
    }

    if (change.selectedVenueTypes) {
      if (change.selectedVenueTypes.currentValue) {
        this.updatePreselectedNodes();
      }
    }
  }

  transformer = (node: VenueType, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.name === node.name
        ? existingNode
        : new FlatNode();
    flatNode.name = node.name;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    flatNode.id = node.enumeration_id;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  isExpandable = (node: FlatNode) => node.expandable;
  getLevel = (node: FlatNode) => node.level;
  getChildren = (node: VenueType): VenueType[] => node.children;
  hasChild = (_: number, node: FlatNode) => node.expandable;
  isVisible = (_: number, node: FlatNode) => !node.isHidden;

  isNeedMultiResetBlock(): boolean {
    return (
      this.isNeedMultiResetButton &&
      this.selectedOptions &&
      !!this.selectedOptions.length
    );
  }

  descendantsAllSelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  descendantsPartiallySelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants
      .filter((x) => !x.disabled)
      .some((child) => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  hasSubVenueType(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((x) => !x.disabled);

    return result;
  }

  todoItemSelectionToggle(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    if (this.checklistSelection.isSelected(node)) {
      this.checklistSelection.select(...descendants);
      this.formControl.reset();
      this.clearFilter();
    } else {
      this.checklistSelection.deselect(...descendants);
    }

    descendants.forEach((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  todoLeafItemSelectionToggle(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.formControl.reset();
    this.clearFilter();
  }

  openPanel(): void {
    if (this.noFilters) return;
    this.isPanelOpened = true;
  }

  checkAllParentsSelection(node: FlatNode): void {
    let parent: FlatNode | null = this._getParentNode(node);
    while (parent) {
      this._checkRootNodeSelection(parent);
      parent = this._getParentNode(parent);
    }

    const selected = this.checklistSelection.selected;
    selected.forEach((item) => {
      const isPresent = this.filteredSelectedNodes.some(
        (x) => x.id === item.id
      );
      if (!isPresent) {
        this.filteredSelectedNodes.push(item);
      }
    });

    this.updateSelectedOptions();
  }

  doRemoveByChip(node: FlatNode): void {
    if (node.expandable) {
      this.todoItemSelectionToggle(node);
    } else {
      this.todoLeafItemSelectionToggle(node);
    }
  }

  filterByTagIds(tagId: any[]): void {
    if (tagId.length === 0) {
      this.noFilters = true;
      return;
    }
    this.noFilters = false;

    const idSet = new Set([...tagId]);

    this.treeControl.dataNodes.forEach((node) => {
      let exists = idSet.has(`${node.id}`);
      if (exists) {
        node.disabled = false;
      } else {
        node.disabled = true;
      }
    });

    this.updateNodeVisibility('screen');
  }

  filterNestedObj(filterVal: string): void {
    const filteredItems = this.treeControl.dataNodes.filter(
      (x) => x.name.toLowerCase().indexOf(filterVal.toLowerCase()) === -1
    );
    filteredItems.map((x) => {
      x.isHidden = true;
    });

    this.updateNodeVisibility('screen');
  }

  showDropdownbutton(node: FlatNode): boolean {
    const descendants = this.treeControl
      .getDescendants(node)
      .filter((node) => !node.disabled);
    if (descendants?.length > -1) {
      return true;
    }
    return false;
  }

  private markParent(node: FlatNode, fitlerType: string): void {
    const parentNode = this._getParentNode(node);
    if (parentNode) {
      const parent = this.treeControl.dataNodes.find(
        (treeNode) => treeNode.id === parentNode.id
      );
      if (fitlerType === 'text') {
        if (!parent || !parent.isHidden) return;
        parent.isHidden = false;
        this.markParent(parent, 'text');
      } else if (fitlerType === 'screen') {
        if (!parent || !parent.disabled) return;
        parent.disabled = false;
        this.markParent(parent, 'screen');
      }
    }
  }

  private updateNodeVisibility(type: string): void {
    if (type === 'text') {
      const allVisible = this.treeControl.dataNodes.filter((x) => !x.isHidden);
      allVisible.forEach((x) => {
        this.markParent(x, 'text');
      });
    } else if (type === 'screen') {
      const allVisible = this.treeControl.dataNodes.filter((x) => !x.disabled);
      allVisible.forEach((x) => {
        this.markParent(x, 'screen');
      });
    }
  }

  private clearFilter(): void {
    this.treeControl.dataNodes.forEach((x) => (x.isHidden = false));
    // this.treeControl.collapseAll();
  }

  protected _checkRootNodeSelection(node: FlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  protected _getParentNode(node: FlatNode): FlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  updateSelectedOptions(doNotEmit?: boolean): void {
    this.selectedOptions = [];
    const allSelected = [];

    const allNodes = this.dataSource._flattenedData.getValue();

    allNodes
      .filter((x) => !x.disabled)
      .forEach((node) => {
        if (this.checklistSelection.isSelected(node)) allSelected.push(node);
      });

    const parentSet = new Set();
    allSelected.forEach((item) => {
      const parent = this._getParentNode(item);
      if (!parent) {
        parentSet.add(item.name);
        this.selectedOptions.push(item);
      } else if (parentSet.has(parent.name)) {
        parentSet.add(item.name);
      } else {
        parentSet.add(item.name);
        this.selectedOptions.push(item);
      }
    });
    
    if (doNotEmit) {
      return;
    }
    this.selectedValue.emit(allSelected.filter((node) => !node.disabled));
  }

  resetSelection(): void {
    const allSelected = this.checklistSelection.selected;
    this.checklistSelection.deselect(...allSelected);

    if (this.selectedVenueTypes) this.updatePreselectedNodes();
    this.updateSelectedOptions(true);
  }

  private updatePreselectedNodes(): void {
    if (!this.treeData || this.treeControl?.dataNodes?.length === 0) return;
    this.selectedVenueTypes.forEach((item) => {
      const node = this.treeControl.dataNodes.find((x) => x.id === +item?.id);
      if (node) {
        this.checklistSelection.select(node);
        const descendants = this.treeControl.getDescendants(node);
        if (this.checklistSelection.isSelected(node)) {
          this.checklistSelection.select(...descendants);
          this.formControl.reset();
          this.clearFilter();
        } else {
          this.checklistSelection.deselect(...descendants);
        }

        descendants.forEach((child) =>
          this.checklistSelection.isSelected(child)
        );
        this.checkAllParentsSelection(node);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
