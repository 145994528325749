import { Injectable } from '@angular/core';
import { ApiService } from '@dooh/api';
import { ScreenPropertiesAutocompleteService } from '@dooh/components-library';
import { Observable } from 'rxjs';
import { PageDto } from '@dooh/models';

@Injectable({
  providedIn: 'root'
})
export class ScreenPropertiesAutocompleteDealsService extends ScreenPropertiesAutocompleteService {
  public static apiUrl = "ssp";

  constructor(private apiService: ApiService) {
    super();
  }

  getApiService(): ApiService {
    return this.apiService;
  }

  getUrl(urlArgsArr?: any): string {
    const dealId: string = Array.isArray(urlArgsArr) ? urlArgsArr[0] : null;
    if (dealId === null || dealId === undefined) {
      throw new Error('Required parameter dealId was null or undefined when calling getAvailableScreens.');
    }

    const dId = encodeURIComponent(dealId);
    return `${ScreenPropertiesAutocompleteDealsService.apiUrl}/deals/${dId}/available_screens`;
  }
}
