import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WhoamiService } from './whoami/whoami.service';
import { DspInstancesService } from './instances/dsp-instances.service';
import { TargetingFiltersService } from '..';

@NgModule({
  imports: [CommonModule],
  providers: [
    WhoamiService,
    DspInstancesService
  ]
})
export class CommonServicesModule {}
