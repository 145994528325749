import * as moment from 'moment';
import { SelectedInstanceService } from '@dooh/common-services';

let DATETIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';

export default function formatDatetime(datetimeStr: string, formatStr = DATETIME_FORMAT): any {
  if(SelectedInstanceService){
    let instance = SelectedInstanceService.getInstance();
    if(instance && instance.country.code === "US"){
      DATETIME_FORMAT = 'MM.DD.YYYY HH:mm:ss';
    }
  }
  const m = moment(datetimeStr);
  if (!m || !m.isValid()) {
    return null;
  }
  return m.format(formatStr);
}
