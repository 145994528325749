import { ValidatorFn, AbstractControl } from '@angular/forms';

export default function termOfServiceVersionValidator(recentVersion: string): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    let value = control.value;
    
    if (typeof value !== 'string' || !recentVersion) {
      return null;
    }
    value = value.replace(/^[^\p{L}\p{N}]+|[^\p{L}\p{N}]+$/gu, '');
   const comparison = value.localeCompare(recentVersion, undefined, { numeric: true, sensitivity: 'base' });

   return comparison === -1 || comparison === 0 ? {'notRecentVersion': true} : null;
  };
}
