import * as moment from 'moment';

const UNIT_BY_TYPE = {
  HOURS: 'hour',
  DAYS: 'day',
  WEEKS: 'isoWeek',
  MONTHS: 'month',
  YEARS: 'years'
};

const FORMAT_BY_TYPE = {
  HOURS: 'YYYY-MM-DD[T]HH[:00:00]',
  DAYS: 'YYYY-MM-DD',
  WEEKS: 'YYYY-[W]WW',
  MONTHS: 'YYYY-MM',
};

function getMom(datetimeStr) {
  const m = moment(datetimeStr).utc();
  if (!m || !m.isValid()) {
    return null;
  }
  return m;
}

function getStartOf(datetimeStr, unit) {
  const m = getMom(datetimeStr);
  return m ? m.startOf(unit) : null;
}

function getEndOf(datetimeStr, unit) {
  const m = getMom(datetimeStr);
  return m ? m.second(0).minute(0).endOf(unit) : null;
}

export function getMaxNumberPage(
  fromDatetimeStr,
  toDatetimeStr,
  distanceType,
  maxPeriodsAmount
) {
  let numberElem = 0;
  const unit = UNIT_BY_TYPE[distanceType] || UNIT_BY_TYPE.MONTHS;
  let momCur = getStartOf(fromDatetimeStr, unit);
  const momLast = getEndOf(toDatetimeStr, unit);

  for (let i = 0; !momCur.isAfter(momLast); i++) {
    momCur = momCur.add(1, unit);
    numberElem++;
  }

  if (numberElem % maxPeriodsAmount > 0) {
    return Math.floor(numberElem / maxPeriodsAmount) + 1;
  } else {
    return numberElem / maxPeriodsAmount;
  }
}

export default function generateDatetimePeriods(
  fromDatetimeStr,
  toDatetimeStr,
  distanceType,
  page
) {
  const arr = [];
  const isYear = distanceType.toLowerCase() === UNIT_BY_TYPE.YEARS;
  if(isYear) {
    let startDate = new Date(fromDatetimeStr).getFullYear();
    let endDate = new Date(toDatetimeStr).getFullYear();

    if(startDate === endDate) {
      arr.push(startDate);
    } else {
      startDate = Number(startDate);
      endDate = Number(endDate);
      for(let i = startDate; i <= endDate; i++) {
        arr.push(i);
      }
    }
    return arr;
  }

  const unit = UNIT_BY_TYPE[distanceType] || UNIT_BY_TYPE.MONTHS;
  let momCur = getStartOf(fromDatetimeStr, unit);
  const momLast = getEndOf(toDatetimeStr, unit);
  if (!momCur || !momLast) {
    return arr;
  }

  const resultFormat = FORMAT_BY_TYPE[distanceType] || FORMAT_BY_TYPE.MONTHS;

  let amount = 0;
  for (let i = 0; momCur.isBefore(momLast); i++) {
    arr.push(momCur.format(resultFormat));
    momCur = momCur.add(1, unit);
  }

  return arr;
}
