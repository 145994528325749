import {
  AfterContentInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { TermsService } from '../../../../libs/common-services/src/lib/terms/terms.service';
import { ActiveNamespaceService, WhoamiService } from '@dooh/common-services';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { takeUntil, throttleTime } from 'rxjs/operators';
import {
  INIT_STATUS,
  NamespaceInitService,
} from './services/namespace-init.service';
import Amplify from 'aws-amplify';
import { createAmplifyConfig } from '@dooh/auth';
import { NamespaceView, UserCredentials } from '@dooh/models';
import { Subject, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';

const PREDOOH_URL = 'predooh.theneuron.com';
const PROD_URL = 'dooh.theneuron.com';
const STAGE_URL = 'dooh.staging.theneuron.com';
@Component({
  selector: 'dooh-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit {
  showTermsOverlay = false;
  termsOfServiceObj: any;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  unsubscriber$ = new Subject<void>();

  currentUserSubscription: Subscription;

  constructor(
    private translateService: TranslateService,
    private iconRegistry: MatIconRegistry,
    private termService: TermsService,
    private sanitizer: DomSanitizer,
    private namespaceInitService: NamespaceInitService,
    private activeNamespaceService: ActiveNamespaceService,
    private titleService: Title,
    private router: Router,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.initNamespace();
    this.translateService.setDefaultLang('en');
    this.loadIcons();
  }

  ngAfterContentInit() {
    let domain = window.origin;
    if (window.origin.includes('localhost')) {
      domain = STAGE_URL;
    } else {
      domain = window.origin.replace(/(^\w+:|^)\/\//, '');
    }
    if (domain === PREDOOH_URL) {
      domain = PROD_URL;
    }

    if ((domain = PROD_URL)) {
      // Hotjar Tracking Code for https://dooh.theneuron.com/
      ((h, o, t, j, a, r) => {
        h.hj =
          h.hj ||
          /* tslint:disable:only-arrow-functions */
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 2925425, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(
        window as any,
        this.doc,
        'https://static.hotjar.com/c/hotjar-',
        '.js?sv='
      );
    }
  }

  ngOnInit(): void {
    this.activeNamespaceService.activeNamespace
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        if (res) {
          this.favIcon.href = res?.iconUrl ? res?.iconUrl : res?.url;
          this.titleService.setTitle(res?.name);
        }
      });
  }

  loadIcons() {
    this.iconRegistry.addSvgIcon(
      'chevron-down',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/ico-chevron-down.svg'
      )
    );

    this.iconRegistry.addSvgIcon(
      'chevron-left',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/ico-chevron-left.svg'
      )
    );

    this.iconRegistry.addSvgIcon(
      'chevron-right',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/ico-chevron-right.svg'
      )
    );

    this.iconRegistry.addSvgIcon(
      'close',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/ico-close.svg'
      )
    );

    this.iconRegistry.addSvgIcon(
      'whitelabel',
      this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ico-whitelabel.svg')
    )
    this.iconRegistry.addSvgIcon(
      'workspace',
      this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ico-workspace.svg')
    )
  }

  private checkTermsVersion(namespaceId: string, creds: UserCredentials): void {
    if (
      namespaceId &&
      creds?.id &&
      !creds?.systemAdmin &&
      !creds?.namespaceAdmin
    ) {
      this.termService.getLatestTermsVersion(namespaceId).subscribe((res) => {
        this.termsOfServiceObj = res;
        const localVersion = localStorage.getItem('termsVersion');
        if (!localVersion || res.version !== localVersion) {
          this.showTermsOverlay = true;
        }
      });
    } else {
      this.showTermsOverlay = false;
    }
  }

  initNamespace(): void {
    localStorage.setItem('initializationStatus', INIT_STATUS.IN_PROGRESS);
    let domain = window.origin;
    if (window.origin.includes('localhost')) {
      domain = STAGE_URL;
    } else {
      domain = window.origin.replace(/(^\w+:|^)\/\//, '');
    }
    if (domain === PREDOOH_URL) {
      domain = PROD_URL;
    }
    this.namespaceInitService.initNamespaceByDomain(domain).subscribe(
      (namespaceInfo: NamespaceView) => {
        const config = createAmplifyConfig(namespaceInfo);
        Amplify.configure(config);
        localStorage.setItem('initializationStatus', INIT_STATUS.RESOLVED);

        this.updateWhiteLabel(namespaceInfo);
        this.currentUserSubscription = WhoamiService.subscribeCurrentUser(
          (creds) => this.checkTermsVersion(namespaceInfo.id, creds)
        );
      },
      () => localStorage.setItem('initializationStatus', INIT_STATUS.REJECTED)
    );
  }

  updateWhiteLabel(namespace?: any): void {
    if (WhoamiService.getCurrentUser().systemAdmin) {
      const temporaryNamespace = ActiveNamespaceService.getTemporaryNamespace();

      if (temporaryNamespace) {
        let namespace = ActiveNamespaceService.getNamespace();
        namespace.id = temporaryNamespace.id;
        namespace.name = temporaryNamespace.name;
        namespace.url = temporaryNamespace.url;
        namespace.spinnerUrl = temporaryNamespace.spinnerUrl;
        namespace.isEnabled = temporaryNamespace.isEnabled;

        this.activeNamespaceService.setNamespace(namespace);
        this.activeNamespaceService.clearTempNamespace();
      } else {
        this.activeNamespaceService.setNamespace(namespace);
      }
    } else {
      this.activeNamespaceService.setNamespace(namespace);
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
    this.currentUserSubscription.unsubscribe();
  }
}
