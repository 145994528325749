import { Component, ComponentRef, Input, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from 'saturn-datepicker';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Platform } from '@angular/cdk/platform';
import {
  dateRangeValidator
} from '@dooh/validation';

import { CustomDateAdapter, CustomDateFormats } from '@dooh/common-services';
import * as moment from 'moment';
import { ActiveDateRangeService } from '../services/active-date-range.service';

type DateRange = {endDate: string,startDate: string};
@Component({
  selector: 'dooh-range-datepicker',
  templateUrl: './range-datepicker.component.html',
  styleUrls: ['./range-datepicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: CustomDateFormats
    }
  ]
})
export class RangeDatepickerComponent {
  @Input()
  componentRef?: ComponentRef<any>;

  @Input()
  dynamicDelete?: boolean;

  @Input()
  placeholder?: string;

  @Input()
  excludedDateRanges: DateRange[] = [];

  _initData: { begin: string, end: string };
  _max: string;
  _min: string;
  errorCount = 0;
  errorMax = false;
  errorMin = false;
  excludeIndex = null;

  @Output()
  closeEvent: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  updateMin: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  changeValue: EventEmitter<any> = new EventEmitter<any>();

  closeButton: boolean;
  range = new FormGroup({
      begin: new FormControl('', Validators.required),
      end: new FormControl('', Validators.required)
    },
    {
      validators: [
        dateRangeValidator('begin', 'end')
      ]
    });

    @HostListener('document:click', ['$event'])
    clickOut(event: any) {
      if (this.eRef.nativeElement.contains(event.target)) {
        this.updateMinValue();
      }
    }

  constructor(
    public activeDateRangeService: ActiveDateRangeService,
    public translateService: TranslateService,
    private eRef: ElementRef
  ) {
    this.translateService.setDefaultLang('en');
    this.filterFunction = this.filterFunction.bind(this);
  }
  @Input()
  set initData(value: { begin: string, end: string }) {
    this._max = value.end;
    this._min = value.begin;
    this._initData = value;
  }

  get initData(): { begin: string, end: string } {
    return this._initData;
  }

  @Input()
  set max(value: string) {
    this.errorCount = this.getErrorMaxEdgeCount(value);
    this._max = value;
  }

  get max(): string {
    return this._max;
  }

  @Input()
  set min(value: string) {
    this.errorCount = this.getErrorMinEdgeCount(value);
    this._min = value;
  }

  get min(): string {
    return this._min;
  }

  activeClose() {
    this.closeButton = true;
  }

  deactiveClose() {
    this.closeButton = false;
  }

  remove() {
    this.errorCount = -Math.abs(this.errorCount);
    this.activeDateRangeService.setErrorCount(this.errorCount);
    this.errorCount = 0;
    this.componentRef.destroy();
    this.closeEvent.emit(-1);
  }

  getDateValue(): { begin: string, end: string } {
    const begin = this.range.get('begin').value;
    const end = this.range.get('end').value;
    return {
      begin,
      end
    };
  }

  closeRangePicker() {
    let fullDate = this.getDateValue();
    const isFullDate = !!fullDate.begin && !!fullDate.end;
    if (!isFullDate) {
      this.range.reset();
      fullDate = this.getDateValue();
    }

    this.getErrorMinEdgeCount(this._min);
    this.getErrorMaxEdgeCount(this._max);
    this.changeValue.emit(fullDate);
  }

  updateMinValue() {
    this.updateMin.emit();
  }

  filterFunction(date: Date) {
    for(let i=0; i < this.excludedDateRanges.length; i++){
      if(i === this.excludeIndex) continue;
      let range = this.excludedDateRanges[i];
      let startDateStr = new Date(range.startDate.split('T')[0]).toDateString();
      let endDateStr = new Date(range.endDate.split('T')[0]).toDateString();
      const startTime = new Date(startDateStr).getTime();
      const endTime = new Date(endDateStr).getTime();
      if(date.getTime() >= startTime && date.getTime() <= endTime){
        return false
      }
    }
    return true
  }

  private getErrorMaxEdgeCount(maxValue: string) {
    if (moment(this.range.value.end).isAfter(moment(maxValue)) || !maxValue) {
      if (!this.errorMax) {
        this.errorCount++;
        this.activeDateRangeService.setErrorCount(1);
        this.errorMax = true;
      }
    } else {
      if (this.errorMax) {
        this.errorCount--;
        this.activeDateRangeService.setErrorCount(-1);
        this.errorMax = false;
      }
    }
    return this.errorCount;
  }

  private getErrorMinEdgeCount(minValue: string) {
    if (moment(this.range.value.begin).isBefore(moment(minValue)) || !minValue) {
      if (!this.errorMin) {
        this.errorCount++;
        this.activeDateRangeService.setErrorCount(1);
        this.errorMin = true;
      }
    } else {
      if (this.errorMin) {
        this.errorCount--;
        this.activeDateRangeService.setErrorCount(-1);
        this.errorMin = false;
      }
    }
    return this.errorCount;
  }
}
