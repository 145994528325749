import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, CustomHttpUrlEncodingCodec } from '@dooh/api';
import { PageDto } from '@dooh/models';
import { Observable } from 'rxjs';
import { CreativeService } from './creative.service';

const apiUrl = 'dsp/smb';
const defaultSorting = [ 'created_at,desc' ];

@Injectable({
  providedIn: 'root'
})
export class SmbCreativeService extends CreativeService {

  getSpecificApiUrl() {
    return apiUrl;
  }

  getAll(sort: string[] = defaultSorting, filters?: Array<string>, page?: number, pageSize?: number): Observable<PageDto> {
    return this.getList(sort, filters, page, pageSize);
  }

}
