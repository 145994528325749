import { ValidatorFn, AbstractControl } from '@angular/forms';

export default function notANumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let value: string = String(control.value);
    if (value && value.includes('Sec')) {
      value = value.replace('Sec', '');
    }
    value = value.replace(/ /g, '');
    if (value.trim() === '') {
      return null;
    }
    const key = Number(value);
    const isNotNumeric = isNaN(key);
    if (!isNotNumeric && key < 0) {
      return { 'negativenumber': true };
    }

    if (!isNotNumeric && !Number.isInteger(key) || value.includes('.')) {
      return { 'decimalnumber': true };
    }

    return !isNotNumeric ? null : { 'nonumber': true };
  };
}
