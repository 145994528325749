import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ComponentRef,
  Input,
  AfterViewInit,
  Output, EventEmitter, OnDestroy
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AddDynamicComponentService } from '@dooh/common-services';
import { RangeDatepickerComponent } from '../range-datepicker/range-datepicker.component';
import { toEndOfDay } from '@dooh/utils';
import { ActiveDateRangeService } from '../services/active-date-range.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const MAX_ELEMENTS_AMOUNT = 4;
const ISO_TIME_END_OF_DAY = 'T23:59:59.999999999Z';
const ISO_TIME_PREFIX = 'T';

@Component({
  selector: 'dooh-exclude-range-dates',
  templateUrl: './exclude-range-dates.component.html',
  styleUrls: ['./exclude-range-dates.component.scss']
})
export class ExcludeRangeDatesComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('excludeDates', { read: ViewContainerRef }) container: ViewContainerRef;

  @Input()
  initData?: any[];

  @Output()
  isExcludeDateValidEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  _max: string;
  _min: string;
  addCount: number;
  components: ComponentRef<any>[];
  addActive: boolean;
  excludedDateRanges: any[] = [];
  unsubscriber$ = new Subject<void>();
  constructor(
    private dynamic: AddDynamicComponentService,
    public translateService: TranslateService,
    public activeDateRangeService: ActiveDateRangeService
  ) {
    this.translateService.setDefaultLang('en');
  }

  @Input()
  set max(value: string) {
    this._max = value;
    this.activeDateRangeService.setActiveEndDate(value);
  }

  get max(): string {
    return this._max;
  }

  @Input()
  set min(value: string) {
    this._min = value;
    this.activeDateRangeService.setActiveBeginDate(value);
}

  get min(): string {
    return this._min;
  }

  @Output()
  changeValue: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    this.components = [];
    this.addCount = 0;
    this.addActive = this.checkActive();

  }

  ngAfterViewInit(): void {
    if (this.initData) {
      this.initComponentWithData();
    }
  }

  checkActive(): boolean {
    return (this.addCount < MAX_ELEMENTS_AMOUNT);
  }

  addComponent() {
    const component = this.dynamic.addDynamicComponent(RangeDatepickerComponent, this.container);
    ++this.addCount;
    this.addActive = this.checkActive();
    this.components.push(component);


    const index = this.components.indexOf(component);

    const instance = (<RangeDatepickerComponent>component.instance);
    instance.excludedDateRanges = this.excludedDateRanges
    instance.excludeIndex = this.components.length - 1;
    
    this.activeDateRangeService.activeBeginDate$.pipe(takeUntil(this.unsubscriber$)).subscribe(min => {
      instance.min = min;
    });
    
    this.activeDateRangeService.activeEndDate$.pipe(takeUntil(this.unsubscriber$)).subscribe(max => {
      instance.max = max;
    });

    instance.closeEvent.pipe(takeUntil(this.unsubscriber$)).subscribe((data) => {
      this.addCount += data;
      this.addActive = this.checkActive();
      const index = this.components.indexOf(component);
      if (index !== -1) {
        this.excludedDateRanges.splice(index, 1);
        this.components.splice(index, 1);
        this.components.forEach((component,index)=>component.instance.excludeIndex = index);
      }
    });

    instance.changeValue.pipe(takeUntil(this.unsubscriber$)).subscribe((data) => {
      this.excludedDateRanges = this.getValue();
      this.components.forEach(component=>component.instance.excludedDateRanges = this.excludedDateRanges);
      this.changeValue.emit(this.excludedDateRanges);
    });

  }

  initComponentWithData() {
    this.initData.forEach((element) => {
      if (!element || !element.startDate || !element.endDate) {
        return;
      }
      const component = this.dynamic.addDynamicComponent(RangeDatepickerComponent, this.container);
      ++this.addCount;
      this.addActive = this.checkActive();
      this.components.push(component);

      component.changeDetectorRef.detectChanges();

      const instance = (<RangeDatepickerComponent>component.instance);
      instance.initData = {
        begin: element.startDate + 'Z',
        end: element.endDate.split('T')[0] + 'T00:00:00.000Z'
      };
      instance.excludedDateRanges = this.initData
      instance.excludeIndex = this.components.length - 1;

      this.activeDateRangeService.activeEndDate$.pipe(takeUntil(this.unsubscriber$)).subscribe(max => {
        instance.max = max;
      });

      this.activeDateRangeService.activeBeginDate$.pipe(takeUntil(this.unsubscriber$)).subscribe(min => {
        instance.min = min;
      });
      instance.closeEvent.pipe(takeUntil(this.unsubscriber$)).subscribe((data) => {
        this.addCount += data;
        this.addActive = this.checkActive();
        const index = this.components.indexOf(component);
        if (index !== -1) {
          this.excludedDateRanges.splice(index, 1);
          this.components.splice(index, 1);
          this.components.forEach((component,index)=>component.instance.excludeIndex = index);
        }
      });
      instance.changeValue.pipe(takeUntil(this.unsubscriber$)).subscribe((data) => {
        this.excludedDateRanges = this.getValue();
        this.components.forEach(component=>component.instance.excludedDateRanges = this.excludedDateRanges);
        this.changeValue.emit(this.excludedDateRanges);
      });
    });
  }

  transformDate(date: string) {
    const newDate = new Date(date);
    const newDateFormatted = Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
    return new Date(newDateFormatted).toISOString();
  }

  getValue() {
    const arr = [];
    this.components.forEach((element) => {
      const instance = (<RangeDatepickerComponent>element.instance);
      if (!instance) {
        return;
      }
      const { begin, end } = instance.getDateValue();
      if (!begin || !end) {
        return;
      }

      const newEndDate = toEndOfDay(this.transformDate(end));

      arr.push({
        endDate: newEndDate.toString().replace('.999999999',''),
        startDate: this.transformDate(begin)
      });
    });

    return arr;
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

}
