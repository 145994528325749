export enum FILE_TYPE {
  image,
  video,
  application
}
export function findIncorrectType(fileType: string): boolean {
  let isIncorrectType = true;
  for (const typeItem in FILE_TYPE) {
    if (fileType === typeItem) {
      return (isIncorrectType = false);
    }
  }
  return isIncorrectType;
}

export const uploadFile = (files: any, limit: number): Promise<Ifile> => {
  // check if it has file
  return new Promise((resolve, reject) => {
    if (files && files[0]) {
      const file = files[0];
      const isImage = file && file['type'].split('/')[0] === 'image';
      const isVideo = file && file['type'].split('/')[0] === 'video';
      let format = file.type.split('/')[1];
      const fileSizeInMb = file.size / (1024 * 1024);
      if (fileSizeInMb < limit) {
        const previewReader = new FileReader();
        previewReader.readAsDataURL(file);
        previewReader.onload = async (e: any) => {
          let dimension = null;
          let duration = 0;
          if (isImage) {
            dimension = await processImageDimensions(e);
          }
          if (isVideo) {
            dimension = await processVideoDimensions(e);
            duration = await processVideoDuration(e);
          }
          const fileBinary = e.target.result.split('base64,')[1];
          const fileName = file.name;
          const fileSize = fileSizeInMb;
          const newFile = {
            fileName,
            fileBinary,
            format,
            fileSize,
            type: file['type'].split('/')[0],
            width: dimension?.width || null,
            height: dimension?.height || null,
            result: previewReader.result,
            duration
          };
          resolve({ ...newFile });
        };
      } else if (fileSizeInMb > limit) {
        resolve({});
      }
    }
  });
};

const processImageDimensions = (event): Promise<IImageDimension> => {
  return new Promise((resolve, reject) => {
    let image = new Image();
    image.src = event.target.result;
    image.onload = function () {
      let width = image.naturalWidth;
      let height = image.naturalHeight;
      resolve({ width, height });
    };
    image.onerror = reject;
  });
};

const processVideoDimensions = (event): Promise<IImageDimension> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.addEventListener(
      'loadedmetadata',
      function () {
        // retrieve dimensions
        const height = this.videoHeight;
        const width = this.videoWidth;
        // send back result
        resolve({ height, width });
      },
      false
    );
    video.src = event.target.result;
  });
};

const processVideoDuration = (event): Promise<number> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.addEventListener(
      'loadedmetadata',
      function () {
        const duration = video.duration
        // send back result
        resolve(duration);
      },
      false
    );
    video.src = event.target.result;
  });
};
export interface Ifile {
  fileBinary?: string;
  fileName?: string;
  format?: string;
}
export interface IImageDimension {
  width: any;
  height: any;
}
