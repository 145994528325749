import { CanActivate, Router } from '@angular/router';
import { Injectable} from '@angular/core';
import { WhoamiService } from "@dooh/common-services";

@Injectable()
export class SspBuyerGuard implements CanActivate{

  constructor(
    private router: Router
  ){}

    canActivate() {
        if (WhoamiService.getCurrentUser().sspRoles[0] !== 'BUYER') {
          return true
        } else {
          this.router.navigateByUrl('/ssp/deals-management');
          return false;
        }
    }
  

}
