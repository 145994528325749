export const DICTONARY_POI = {
  all_results: { text: "All results", icon: "search", group: "ALL_RESULTS", groupName: "All results" },
  accounting: { text: "Accounting", icon: "bank-finance", group: "BANK_FINANCE", groupName: "Bank, Finance" },
  airport: { text: "Airport", icon: "transport", group: "TRANSPORT", groupName: "Transport" },
  amusement_park: { text: "Amusement Park", icon: "entertainment", group: "ENTERTAINMENT", groupName: "Entertainment" },
  aquarium: { text: "Aquarium", icon: "entertainment", group: "ENTERTAINMENT", groupName: "Entertainment" },
  art_gallery: { text: "Art Gallery", icon: "entertainment", group: "ENTERTAINMENT", groupName: "Entertainment" },
  atm: { text: "ATM", icon: "bank-finance", group: "BANK_FINANCE", groupName: "Bank, Finance"  },
  bakery: { text: "Bakery", icon: "business", group: "BUSINESS", groupName: "Business" },
  bank: { text: "Bank", icon: "bank-finance", group: "BANK_FINANCE", groupName: "Bank, Finance"  },
  bar: { text: "Bar", icon: "cafes-and-restaurants", group: "CAFE_AND_RESTAURANT", groupName: "Cafe and Restaurants" },
  beauty_salon: { text: "Beauty Salon", icon: "health-and-beauty", group: "HEALTH-AND-BEAUTY", groupName: "Health and Beauty" },
  bicycle_store: { text: "Bicycle Store", icon: "trade-and-shops", group: "TRADE-AND-SHOPS", groupName: "Trade and Shops"  },
  book_store: { text: "Book Store", icon: "trade-and-shops", group: "TRADE-AND-SHOPS", groupName: "Trade and Shops"  },
  bowling_alley: { text: "Bowling Alley", icon: "entertainment", group: "ENTERTAINMENT", groupName: "Entertainment" },
  bus_station: { text: "Bust Station", icon: "transport", group: "TRANSPORT", groupName: "Transport" },
  cafe: { text: "Cafe", icon: "cafes-and-restaurants", group: "CAFE_AND_RESTAURANT", groupName: "Cafe and Restaurants" },
  campground: { text: "Campground", icon: "housing", group: "HOUSING", groupName: "Housing" },
  car_dealer: { text: "Car Dealer", icon: "business", group: "BUSINESS", groupName: "Business" },
  car_rental: { text: "Car Rental", icon: "business", group: "BUSINESS", groupName: "Business" },
  car_repair: { text: "Car Repair", icon: "business", group: "BUSINESS", groupName: "Business" },
  car_wash: { text: "Car Wash", icon: "business", group: "BUSINESS", groupName: "Business" },
  casino: { text: "Casino", icon: "entertainment", group: "ENTERTAINMENT", groupName: "Entertainment" },
  cemetery: { text: "Cemetery", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society" },
  church: { text: "Church", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society" },
  city_hall: { text: "City Hall", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society" },
  clothing_store: { text: "Clothing Store", icon: "trade-and-shops", group: "TRADE-AND-SHOPS", groupName: "Trade and Shops" },
  convenience_store: { text: "Convenience Store", icon: "trade-and-shops", group: "TRADE-AND-SHOPS", groupName: "Trade and Shops" },
  courthouse: { text: "Court House", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society" },
  dentist: { text: "Dentist", icon: "health-and-beauty", group: "HEALTH-AND-BEAUTY", groupName: "Health and Beauty"  },
  department_store: { text: "Department Store", icon: "trade-and-shops", group: "TRADE-AND-SHOPS", groupName: "Trade and Shops" },
  doctor: { text: "Doctor", icon: "health-and-beauty", group: "HEALTH-AND-BEAUTY", groupName: "Health and Beauty"  },
  drugstore: { text: "DrugStore", icon: "trade-and-shops", group: "TRADE-AND-SHOPS", groupName: "Trade and Shops" },
  electrician: { text: "Electician", icon: "business", group: "BUSINESS", groupName: "Business"  },
  electronics_store: { text: "Electronics Store", icon: "trade-and-shops", group: "TRADE-AND-SHOPS", groupName: "Trade and Shops" },
  establishment: { text: "Establishment", icon: "business", group: "BUSINESS", groupName: "Business" },
  embassy: { text: "Embassy", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society" },
  fire_station: { text: "Fire Station", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society" },
  finance: {text: "Finance",  icon: "bank-finance", group: "BANK_FINANCE", groupName: "Bank, Finance" },
  food: { text: "Food", icon: "cafes-and-restaurants", group: "CAFE_AND_RESTAURANT", groupName: "Cafe and Restaurants" },
  florist: { text: "Florist", icon: "trade-and-shops", group: "TRADE-AND-SHOPS", groupName: "Trade and Shops" },
  funeral_home: { text: "Funeral Home", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society" },
  furniture_store: { text: "Furniture Store", icon: "trade-and-shops", group: "TRADE-AND-SHOPS", groupName: "Trade and Shops" },
  gas_station: { text: "Gast Station", icon: "business", group: "BUSINESS", groupName: "Business" },
  general_contractor: { text: "General Contractor", icon: "business", group: "BUSINESS", groupName: "Business" },
  grocery_or_supermarket: { text: "Grocery or Supermarket", icon: "trade-and-shops", group: "TRADE-AND-SHOPS", groupName: "Trade and Shops" },
  gym: { text: "Gym", icon: "sport-and-fitness", group: "SPORT-AND-FITNESS", groupName: "Sports and Fitness" },
  hair_care: { text: "Hair Care", icon: "health-and-beauty", group: "HEALTH-AND-BEAUTY", groupName: "Health and Beauty"  },
  hardware_store: { text: "Hardware Store", icon: "business", group: "BUSINESS", groupName: "Business" },
  health: { text: "Health", icon: "health-and-beauty", group: "HEALTH-AND-BEAUTY", groupName: "Health and Beauty"  },
  hindu_temple: { text: "Hindu Temple", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society" },
  home_goods_store: { text: "Home Goods Store", icon: "trade-and-shops", group: "TRADE-AND-SHOPS", groupName: "Trade and Shops" },
  hospital: { text: "Hostpital", icon: "health-and-beauty", group: "HEALTH-AND-BEAUTY", groupName: "Health and Beauty"  },
  insurance_agency: { text: "Insurance Agency", icon: "bank-finance", group: "BANK_FINANCE", groupName: "Bank, Finance"  },
  jewelry_store: { text: "Jewerly Store", icon: "trade-and-shops", group: "TRADE-AND-SHOPS", groupName: "Trade and Shops" },
  laundry: { text: "Laundry", icon: "business", group: "BUSINESS", groupName: "Business"  },
  lawyer: { text: "Lawyer", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society" },
  library: { text: "Library", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society" },
  light_rail_station: { text: "Light Rail Station", icon: "transport", group: "TRANSPORT", groupName: "Transport" },
  liquor_store: { text: "Liquor Store", icon: "trade-and-shops", group: "TRADE-AND-SHOPS", groupName: "Trade and Shops"  },
  local_government_office: { text: "Local Government Office", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society" },
  locksmith: { text: "Locksmith", icon: "business", group: "BUSINESS", groupName: "Business"  },
  lodging: { text: "Lodging", icon: "housing", group: "HOUSING", groupName: "Housing" },
  locality: { text: "Locality", icon: "business", group: "BUSINESS", groupName: "Business" },
  meal_delivery: { text: "Meal Delivery", icon: "cafes-and-restaurants", group: "CAFE_AND_RESTAURANT", groupName: "Cafe and Restaurants" },
  meal_takeaway: { text: "Meal Takeaway", icon: "cafes-and-restaurants", group: "CAFE_AND_RESTAURANT", groupName: "Cafe and Restaurants" },
  mosque: { text: "Mosque", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society" },
  movie_rental: { text: "Movie Rental", icon: "business", group: "BUSINESS", groupName: "Business" },
  movie_theater: { text: "Movie Theather", icon: "entertainment", group: "ENTERTAINMENT", groupName: "Entertainment"  },
  moving_company: { text: "Moving Company", icon: "business", group: "BUSINESS", groupName: "Business"  },
  museum: { text: "Museum", icon: "entertainment", group: "ENTERTAINMENT", groupName: "Entertainment" },
  neighborhood: { text: "Neighborhood", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society" },
  night_club: { text: "Night Club", icon: "entertainment", group: "ENTERTAINMENT", groupName: "Entertainment"},
  painter: { text: "Painter", icon: "business", group: "BUSINESS", groupName: "Business" },
  park: { text: "Park", icon: "entertainment", group: "ENTERTAINMENT", groupName: "Entertainment" },
  parking: { text: "Parking", icon: "transport", group: "TRANSPORT", groupName: "Transport" },
  pet_store: { text: "Pet Store", icon: "trade-and-shops", group: "TRADE-AND-SHOPS", groupName: "Trade and Shops" },
  pharmacy: { text: "Pharmacy", icon: "health-and-beauty", group: "HEALTH-AND-BEAUTY", groupName: "Health and Beauty" },
  physiotherapist: { text: "Physiothreapist", icon: "health-and-beauty", group: "HEALTH-AND-BEAUTY", groupName: "Health and Beauty" },
  place_of_worship: { text: "Place of Worship", icon: "business", group: "BUSINESS", groupName: "Business" },
  point_of_interest: { text: "Point of interest", icon: "business", group: "BUSINESS", groupName: "Business" },
  plumber: { text: "Plumber", icon: "business", group: "BUSINESS", groupName: "Business" },
  police: { text: "Police", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society" },
  post_office: { text: "Post Office", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society"},
  political: { text: "Political", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society" },
  primary_school: { text: "Primary School", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society"  },
  premise: { text: "Premise", icon: "housing", group: "HOUSING", groupName: "Housing" },
  real_estate_agency: { text: "Real State Agency", icon: "housing", group: "HOUSING", groupName: "Housing" },
  restaurant: { text: "Restaurant", icon: "cafes-and-restaurants", group: "CAFE_AND_RESTAURANT", groupName: "Cafe and Restaurants" },
  roofing_contractor: { text: "Roofing Contractor", icon: "business", group: "BUSINESS", groupName: "Business" },
  rv_park: { text: "RV Park", icon: "housing", group: "HOUSING", groupName: "Housing" },
  school: { text: "School", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society" },
  secondary_school: { text: "Secondary School", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society" },
  shoe_store: { text: "Shoe Store", icon: "trade-and-shops", group: "TRADE-AND-SHOPS", groupName: "Trade and Shops"  },
  shopping_mall: { text: "Airport", icon: "trade-and-shops", group: "TRADE-AND-SHOPS", groupName: "Trade and Shops" },
  spa: { text: "SPA", icon: "health-and-beauty", group: "HEALTH-AND-BEAUTY", groupName: "Health and Beauty" },
  stadium: { text: "Stadium", icon: "entertainment", group: "ENTERTAINMENT", groupName: "Entertainment" },
  storage: { text: "Storage", icon: "business", group: "BUSINESS", groupName: "Business" },
  store: { text: "Store", icon: "trade-and-shops", group: "TRADE-AND-SHOPS", groupName: "Trade and Shops"  },
  subway_station: { text: "Subway Station", icon: "transport", group: "TRANSPORT", groupName: "Transport" },
  supermarket: { text: "Supermarket", icon: "trade-and-shops", group: "TRADE-AND-SHOPS", groupName: "Trade and Shops"  },
  synagogue: { text: "Synagogue", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society" },
  taxi_stand: { text: "Taxi Stand",  icon: "transport", group: "TRANSPORT", groupName: "Transport" },
  tourist_attraction: { text: "Tourist Attraction", icon: "entertainment", group: "ENTERTAINMENT", groupName: "Entertainment" },
  train_station: { text: "Train Station", icon: "transport", group: "TRANSPORT", groupName: "Transport" },
  transit_station: { text: "Transit Station",icon: "transport", group: "TRANSPORT", groupName: "Transport" },
  travel_agency: { text: "Travel Agency", icon: "business", group: "BUSINESS", groupName: "Business" },
  university: { text: "University", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society" },
  veterinary_care: { text: "Veterinary Care", icon: "business", group: "BUSINESS", groupName: "Business" },
  zoo: { text: "ZOO", icon: "entertainment", group: "ENTERTAINMENT", groupName: "Entertainment" },
  administrative_area_level_1: { text: "Administrative Area Level 1", icon: "housing", group: "HOUSING", groupName: "Housing" },
  administrative_area_level_2 : { text: "Administrative Area Level 2", icon: "housing", group: "HOUSING", groupName: "Housing" },
  administrative_area_level_3 : { text: "Administrative Area Level 3", icon: "housing", group: "HOUSING", groupName: "Housing" },
  administrative_area_level_4 : { text: "Administrative Area Level 4", icon: "housing", group: "HOUSING", groupName: "Housing" },
  administrative_area_level_5 : { text: "Administrative Area Level 5", icon: "housing", group: "HOUSING", groupName: "Housing" },
  archipelago : { text: "Archipelago", icon: "entertainment", group: "ENTERTAINMENT", groupName: "Entertainment" },
  colloquial_area : { text: "Colloquial Area", icon: "housing", group: "HOUSING", groupName: "Housing" },
  continent : { text: "Continent", icon: "entertainment", group: "ENTERTAINMENT", groupName: "Entertainment" },
  country : { text: "Country", icon: "entertainment", group: "ENTERTAINMENT", groupName: "Entertainment" },
  floor : { text: "Floor", icon: "housing", group: "HOUSING", groupName: "Housing" },
  geocode : { text: "Geocode", icon: "entertainment", group: "ENTERTAINMENT", groupName: "Entertainment" },
  intersection : { text: "Intersection", icon: "entertainment", group: "ENTERTAINMENT", groupName: "Entertainment" },
  natural_feature : { text: "Natural Feature", icon: "entertainment", group: "ENTERTAINMENT", groupName: "Entertainment" },
  post_box : { text: "Post Box",icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society" },
  postal_code : { text: "Postal Code",icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society" },
  postal_code_prefix : { text: "Postal Code Prefix", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society"},
  postal_code_suffix : { text: "Postal Code Suffix", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society"},
  postal_town : { text: "Postal Town", icon: "state-and-society", group: "STATE-AND-SOCIETY", groupName: "State and Society"},
  room : { text: "Room", icon: "housing", group: "HOUSING", groupName: "Housing" },
  route : { text: "Route", icon: "entertainment", group: "ENTERTAINMENT", groupName: "Entertainment" },
  street_address : { text: "Street Address", icon: "housing", group: "HOUSING", groupName: "Housing" },
  street_number : { text: "Street Number", icon: "housing", group: "HOUSING", groupName: "Housing" },
  sublocality : { text: "Sublocality", icon: "housing", group: "HOUSING", groupName: "Housing" },
  sublocality_level_1 : { text: "Sublocality Level 1", icon: "housing", group: "HOUSING", groupName: "Housing" },
  sublocality_level_2 : { text: "Sublocality Level 2", icon: "housing", group: "HOUSING", groupName: "Housing" },
  sublocality_level_3 : { text: "Sublocality Level 3", icon: "housing", group: "HOUSING", groupName: "Housing" },
  sublocality_level_4 : { text: "Sublocality Level 4", icon: "housing", group: "HOUSING", groupName: "Housing" },
  sublocality_level_5 : { text: "Sublocality Level 5", icon: "housing", group: "HOUSING", groupName: "Housing" },
  subpremise : { text: "Subpremise", icon: "housing", group: "HOUSING", groupName: "Housing" },
  town_square : { text: "Town Square", icon: "housing", group: "HOUSING", groupName: "Housing" },
}
