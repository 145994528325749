import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { UserCredentials } from '@dooh/models';
import { SelectedInstanceService } from '../instances/selected-instance.service';

@Injectable()
export class WhoamiService {
  static currentUser$: BehaviorSubject<UserCredentials> = new BehaviorSubject(null);

  static _tryParse(value: string): string[] {
    return value ? value.split(',') : [];
  }

  static getCurrentUser(): UserCredentials {
    return {
      id: localStorage.getItem('userId'),
      instances: this._tryParse(SelectedInstanceService.getInstanceId()),
      agencies: this._tryParse(localStorage.getItem('agencyId')),
      email: localStorage.getItem('email'),
      firstName: localStorage.getItem('firstName'),
      lastName: localStorage.getItem('lastName'),
      systemAdmin: localStorage.getItem('isAdmin') === 'true',
      namespaceAdmin: localStorage.getItem('isNamespaceAdmin') === 'true',
      dspRoles: this._tryParse(localStorage.getItem('dspRole')),
      sspRoles: this._tryParse(localStorage.getItem('sspRole')),
      oohRoles: this._tryParse(localStorage.getItem('oohRole'))
    };
  }

  static refreshCurrentUser(): void {
    this.currentUser$.next(this.getCurrentUser());
  }

  static subscribeCurrentUser(observer: (value: UserCredentials) => void): Subscription {
    // if (!this.currentUser$) {
    this.refreshCurrentUser();
    // }
    return this.currentUser$.subscribe(observer);
  }
}
