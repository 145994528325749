import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  ViewChild,
  OnChanges,
  SimpleChanges, NgZone, OnDestroy, ChangeDetectionStrategy
} from '@angular/core';
import { InstanceView, LocationMap } from '@dooh/models';
import { MapsAPILoader, LatLngBounds, AgmInfoWindow, LatLng } from "@agm/core";
import { mapStyles } from './map-styles';
import { ApiService } from '@dooh/api'
import { HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '@dooh/api';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { MapService, SelectedInstanceService } from '@dooh/common-services';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { GoogleMap } from '@agm/core/services/google-maps-types';

declare var google: any;

const SEARCH_DELAY = 600;
const DEFAULT_ZOOM = 2;
const MOVES_TO_ACTIVE_GEOSEARCH = 5;
const INITIAL_GEOCODE_ZOOM = 7;
const PAGE_OPTIONS = [500, 1000, 1500]
const MAP_BOUND_PADDING = 100;
@Component({
  selector: 'dooh-map',
  templateUrl: './map.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnChanges, OnDestroy {

  geocoder: any;

  @Input()
  location: LocationMap;

  @Input()
  markers?: any;

  @Input()
  isPaginated?: boolean;

  @Input()
  isLoading?: boolean;

  @Input()
  pagination?: any;

  @Input()
  infoWindowTemplate?: TemplateRef<any>;
  @Input()
  canGeoSearch: boolean;

  styles: any[] = mapStyles;

  pois: any = [];
  searchPOIData = '';
  defaultZoom = DEFAULT_ZOOM;
  pageOptions = PAGE_OPTIONS;
  latMin: any;
  latMax: any;
  longMin: any;
  longMax: any;
  newBounds: LatLngBounds;

  boundsChangeSubject = new Subject();

  markerIconMap = {};
  markerTypeMap = {};

  infoWindow: AgmInfoWindow;
  isInfoWindowOpen: boolean;

  searchPoiSubscription$: Subscription
  mapMoves = 0;
  zoom = 0;

  geoSearchButton = false;
  activeGeoSearch: boolean;
  map: any;
  mapObject$ = new BehaviorSubject<any>(null);
  unsubscriber$: Subject<void> = new Subject<void>();


  @Output()
  markerClick?: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  mapClick: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  pageToggle: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  searchOnTheMapArea?: EventEmitter<any> = new EventEmitter<any>();

  constructor(public mapsApiLoader: MapsAPILoader,
    private API: ApiService,
    private mapService: MapService
  ) {
    this.mapsApiLoader = mapsApiLoader;
    this.mapsApiLoader.load().then(() => {
      this.setInstanceLocation();
    });


    this.mapService.markerIconMap$.pipe(takeUntil(this.unsubscriber$)).subscribe(markerIconMap => {
      this.markerIconMap = markerIconMap;

    });

    this.mapService.markerTypeMap$.pipe(takeUntil(this.unsubscriber$)).subscribe(markerTypeMap => {
      this.markerTypeMap = markerTypeMap;
    });
  }

  ngOnInit() {
    this.mapObject$.pipe(takeUntil(this.unsubscriber$)).subscribe((res) => {
      if (res) {
        this.map = res;
        this.fitBoundsBasedOnMarker();
      }
    });
    const instanceLocation = SelectedInstanceService.getInstance().location;
    if (this.markers?.length <= 0 && instanceLocation) {
      if (!this.location) {
        this.location = {
          lat: 0,
          lng: 0,
          zoom: INITIAL_GEOCODE_ZOOM
        }
      }
      this.location.lat = parseFloat(instanceLocation.lat);
      this.location.lng = parseFloat(instanceLocation.lng);
      this.location.zoom = INITIAL_GEOCODE_ZOOM
    }
    this.mapService.getPoisMapData().pipe(takeUntil(this.unsubscriber$)).subscribe((data) => {
      if (String(typeof data) !== 'function') {
        this.pois = data;

      }
    });
    this.mapService.getSearchPOIData().pipe(takeUntil(this.unsubscriber$)).subscribe((data: string) => {
      this.searchPOIData = data;
    });

    this.mapService.activeGeoSearchButton$.pipe(takeUntil(this.unsubscriber$)).subscribe(isActiveGeoSearch => {
      this.activeGeoSearch = isActiveGeoSearch;
    })

    this.boundsChangeSubject.pipe(
      debounceTime(SEARCH_DELAY)
    ).subscribe((event: LatLngBounds) => {
      this.searchInBounds(event);
      if(this.mapMoves >= MOVES_TO_ACTIVE_GEOSEARCH && this.activeGeoSearch){
        this.geoSearchButton = true;
      }
      else{
        this.mapMoves++;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.location && this.markers.length === 0 && !changes?.isLoading?.currentValue) {
      this.map?.panTo?.(changes.location?.currentValue);
      this.map?.setZoom?.(changes.location?.currentValue?.zoom);
    }
    if(changes['markers'] && changes.markers){
      this.fitBoundsBasedOnMarker();
    }
  }

  onMarkerClick(marker: any, infoWindow?: AgmInfoWindow) {
    this.closeInfoWindow();
    this.infoWindow = infoWindow;
    this.markerClick.emit(marker);
  }

  onMapClick() {
    this.closeInfoWindow();
    this.mapClick.emit()
  }

  closeInfoWindow() {
    if (this.infoWindow) {
      try {
        this.infoWindow.close();
      } catch (e) {
        this.infoWindow = null;
      }
    }
  }

  resizeMap(){
    setTimeout(function(){
      window.dispatchEvent(new Event("resize"));
      }, 1);
  }

  boundsChange(event: LatLngBounds) {
    if(event){
      this.resizeMap();
      this.latMin = event.getSouthWest().lat();
      this.latMax = event.getNorthEast().lat();
      this.longMin = event.getSouthWest().lng();
      this.longMax = event.getNorthEast().lng();
      this.newBounds = event;
      this.boundsChangeSubject.next(event);
      this.clusterChange();
    }
  }

  searchInBounds(bounds?: LatLngBounds) {
    if(this.markers?.length > 0) {
      const bounds: LatLngBounds = new google.maps.LatLngBounds();
      for (const marker of this.markers) {
        bounds.extend(new google.maps.LatLng(marker.location.latitude, marker.location.longitude));
      }
      this.mapService.setMapBoundForPoi(bounds);
    }

    
    // this.mapService.setPoiLoading(true);

    // let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    // queryParameters = queryParameters.set('latMin', <any>this.latMin);
    // queryParameters = queryParameters.set('latMax', <any>this.latMax);
    // queryParameters = queryParameters.set('longMin', <any>this.longMin);
    // queryParameters = queryParameters.set('longMax', <any>this.longMax);
    // if (search && String(typeof search) === 'string')
    //   queryParameters = queryParameters.set('search', <any>'*' + search + '*');
    // if (!this.latMin) return;

    // if (this.searchPoiSubscription$) {
    //   this.searchPoiSubscription$.unsubscribe();
    // }
    // this.searchPoiSubscription$ = this.API.get('poi/search', queryParameters).subscribe((data) => {
    //   this.mapService.setPoisData(data);
    //   this.mapService.setPoiLoading(false);
    // });
  }

  clusterChange(){
    const displayedMarkers = this.markers.filter(marker => {
      if(marker !== null && marker.location !== null){
        if(marker.location.latitude !== null && marker.location.longitude !== null){
          marker['LatLng'] = { lat: marker.location.latitude, lng: marker.location.longitude } as LatLng;
          return this.newBounds.contains(marker.LatLng);
        }
      }
    });
    this.mapService.setAddScreensonVisibleMap(displayedMarkers);
  }

  zoomChange($event){
    this.mapMoves++;
    this.zoom = $event;

  }

  geoSearch(){
    this.searchOnTheMapArea.emit({minLatitude: this.latMin, minLongitude: this.longMin, maxLatitude: this.latMax, maxLongitude: this.longMax});
    this.mapMoves = 2;
    this.geoSearchButton = false;
  }

  onPageHandler($event) {
    const data = {
      currentPage: $event.pageIndex + 1,
      pageSize: $event.pageSize
    }
    this.pageToggle.emit(data);
  }

  setInstanceLocation():void{
    this.geocoder = new google.maps.Geocoder();
    let instance:any = localStorage.getItem('instance');
    instance = JSON.parse(instance);
    if (!this.location) {
      this.location = {
        lat: 0,
        lng: 0,
        zoom: INITIAL_GEOCODE_ZOOM
      }
    }

    if (instance?.location) {
      this.location.lat = instance?.location?.lat;
      this.location.lng = instance?.location?.lng;
    } else if (instance.country.name) {
      this.geocoder.geocode({address: instance['country'].name}, (results) => {
        if (this.markers?.length <= 0 && results?.length > 0) {
          this.location.lat = results[0]?.geometry?.location?.lat();
          this.location.lng = results[0]?.geometry?.location?.lng()
          this.location.zoom = INITIAL_GEOCODE_ZOOM;
        }
      })
    }
  }

  logMapReady(event) {
    this.map = event;
    this.mapObject$.next(this.map);
  }

  fitBoundsBasedOnMarker(){
    setTimeout(() => {
      const markersHasLocation = this.markers.some(marker =>{
        return marker.location.latitude || marker.location.longitude
      });
      if(this.markers?.length > 0 && markersHasLocation) {
        const bounds: LatLngBounds = new google.maps.LatLngBounds();
        for (const marker of this.markers) {
          bounds.extend(new google.maps.LatLng(marker.location.latitude, marker.location.longitude));
        }

        this.map.fitBounds(bounds, MAP_BOUND_PADDING);
      }
      else{
        this.markers = [];
      }
    });
  }
}