import { Injectable } from '@angular/core';
import {ApiService} from "@dooh/api";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TermsService {

  constructor(private apiService: ApiService) { }

  public getLatestTermsVersion(namespaceId: string): Observable<any>{
    return this.apiService.get(`admin/terms-of-service/latest/${namespaceId}`);
  }
}
