<div class="container-exclude-dates" >
  <div class="dynamic-container" #excludeDates></div>
  <span
    class="add-button"
    (click)="addComponent()"
    *ngIf="addActive"
  >
    <mat-icon svgIcon="plus"></mat-icon>
    <span>
      {{ 'excludeRangeDates.add' | translate }}
    </span>
  </span>
</div>
