import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { ToastComponent } from 'libs/components-library/src/lib/toast/toast.component';
import { IToastData, ToastType } from '@dooh/models';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private snackBar: MatSnackBar) {}

  showNotification(data: IToastData, type: ToastType, duration:number = null): void {
    this.snackBar.openFromComponent(ToastComponent, {
      data,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: type,
      duration
    });
  }
  dismiss(){
    this.snackBar.dismiss();
  }
}
