<header class="breadcrumbs">
  <mat-icon class="breadcrumbs__icon" [svgIcon]="icon"></mat-icon>
  <h1 class="breadcrumbs__title">
    <ng-content></ng-content>
  </h1>
  <mat-icon
    *ngIf="showOfferIconInfo"
    class="breadcrumbs__icon"
    [svgIcon]="'marketplace'"
    matTooltip="There is an Offer attached to this Campaign. The Screen Publisher, Venue Types, Resolution, Start Date, End Date, and Budget will depend on the Offer selected"
  ></mat-icon>
</header>
