import { Injectable } from '@angular/core';
import { ApiService } from '@dooh/api';
import { ScreenPropertiesAutocompleteService } from '@dooh/components-library';

@Injectable({
  providedIn: 'root'
})
export class ScreenPropertiesAutocompleteSmbCampaignService extends ScreenPropertiesAutocompleteService {
  public static apiUrl = "dsp/smb";

  constructor(private apiService: ApiService) {
    super();
  }

  getApiService(): ApiService {
    return this.apiService;
  }

  getUrl(urlArgsArr?: any): string {
    const campaignId: string = Array.isArray(urlArgsArr) ? urlArgsArr[0] : null;
    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getAvailableScreens.');
    }

    const cId = encodeURIComponent(campaignId);
    return `${ScreenPropertiesAutocompleteSmbCampaignService.apiUrl}/screens/campaigns/${cId}/available_screens`;
  }
}