import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { noWhitespaceValidator, xregexpValidator } from '@dooh/validation';

@Component({
  selector: 'dooh-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input()
  url?: string;

  @Input()
  label: string;

  @Input()
  editLabel: string;

  @Input()
  labelEditable: boolean;

  @Input()
  isLoading: boolean;

  @Output()
  saveBreadcrumbName: EventEmitter<any> = new EventEmitter<any>();

  isEditing: boolean;

  formControl = new FormControl(null, [
    Validators.required,
    noWhitespaceValidator(),
    Validators.minLength(3),
    xregexpValidator("^[\\p{L}a-zA-Z0-9\\s\\{\\}\\?!@#$%^&*()\\-_.+=|:'~\\[\\]]+$"),
  ]);

  constructor() {}

  ngOnInit() {}

  startEditing(): void {
    this.formControl.patchValue(this.label);
    this.isEditing = true;
  }

  cancelEdit(): void {
    this.isEditing = false;
    this.formControl.patchValue(null);
  }

  save(): void {
    if (this.formControl.pristine) {
      this.cancelEdit();
      return;
    }
    const data = this.formControl.value;
    this.saveBreadcrumbName.emit(data);
  }


  showOnlyFirstError(errorKey: string): boolean {
    const allErrors = this.formControl.errors;

    if (allErrors) {
      const firstError = Object.keys(allErrors)[0];

      if (firstError === errorKey) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
}
