import {AfterViewInit, Component, OnInit} from '@angular/core';
import {TutorialsService} from "./tutorials.service";
import { ActiveNamespaceService, WhoamiService } from '@dooh/common-services';
import { NamespaceView, UserCredentials } from '@dooh/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'dooh-tutorials-panel',
  templateUrl: './tutorials-panel.component.html',
  styleUrls: ['./tutorials-panel.component.scss']
})
export class TutorialsPanelComponent implements AfterViewInit {

  public tutorialList = [];
  public currentSelectedVideo: any;
  public isLoading = true;
  logoWidth: string;
  namespace: NamespaceView;

  constructor(public tutorialsService: TutorialsService) {
    this.namespace = ActiveNamespaceService.getNamespace();
  }

  ngAfterViewInit() {
    this.logoWidth = this.getContainerWidth(this.namespace?.url);
    this.initiateFirstVideo();
  }

  private initiateFirstVideo(): void {
    this.getVideoTutorials().subscribe(
      (res) => {
        if (res?.length > 0) {
          this.tutorialList = res;
          this.currentSelectedVideo = res[0];
        }
      this.isLoading = false;
    }, (err) => {
        this.isLoading = false;
      });
  }

  markVideoAsWatched(videoId): void {
    localStorage.setItem(`video-${videoId}`, 'watched');
  }

  getVideoStatus(videoId): boolean {
    return localStorage.getItem(`video-${videoId}`) === 'watched';
  }

  sanitizeTutorialTranscript(): void {
    this.currentSelectedVideo.Metadata['neuron-trans'] =
      this.currentSelectedVideo.Metadata['neuron-trans'].toString().replaceAll('\n','<br/>');
  }

  getVideoTutorials(): Observable<any> {
    if (WhoamiService.getCurrentUser()?.dspRoles[0] === 'SMB_MANAGER') {
      return this.tutorialsService.getSmbVideoTutorials();
    }
    return this.tutorialsService.getDspVideoTutorials();
  }

  preloadImage(url: string) {
    const img = new Image();
    img.src = url;
    return img
  }

  getContainerWidth(url: string): string {
    if (url) {
      const image = this.preloadImage(url);
      const width = image?.width;
      const height = image?.height;

      const ratio = width / height;
      if (ratio > (4/3)) {
        return '60px';
      } else {
        return '30px';
      } 
    } else {
      return '';
    }
  }

}
