import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AudienceData } from '../../components/audience/audience.component';

@Injectable({
  providedIn: 'root',
})
export class AudienceStateService {
  audienceDefaultData: BehaviorSubject<AudienceData> = new BehaviorSubject(
    null
  );
  audienceAggData: BehaviorSubject<string[]> = new BehaviorSubject([]);

  setAudienceData(data): void {
    this.audienceDefaultData.next(data);
  }

  getAudienceData(): Observable<AudienceData> {
    return this.audienceDefaultData.asObservable();
  }

  setAudienceAggData(data): void {
    this.audienceAggData.next(data);
  }

  getAudienceAggData(): Observable<string[]> {
    return this.audienceAggData.asObservable();
  }

  resetAudienceData(): void {
    this.audienceDefaultData.next(null);
  }

  constructor() {}
}
