import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class PasswordMatchValidator{
  static MatchPassword(control: AbstractControl) {
    const password = control.get('password').value;
    const confirmPassword = control.get('passwordAgain').value;
    if (password !== confirmPassword) {
      control.get('passwordAgain').setErrors({ ConfirmPassword: true });
    }
    else {
      const err = control.get('passwordAgain').errors;
      if(err) {
        delete err['ConfirmPassword'];
      }
      if (err && !Object.keys(err).length) {
        control.get('passwordAgain').setErrors(null);
      } else {
        control.get('passwordAgain').setErrors(err);
      }
    }
  }
}


export class CustomPasswordValidator {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      const valid = regex.test(control.value);

      return valid ? null : error;
    };
  }
}
