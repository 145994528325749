import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@dooh/api';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActiveOfferService {
  private selectedOffer = new BehaviorSubject<any>(null);
  selectedOffer$ = this.selectedOffer.asObservable();
  constructor() {}

  setOfferForCampaign(offer: any): void {
    this.selectedOffer.next(offer);
  }
}
