import { Injectable } from '@angular/core';
import { ApiService } from '@dooh/api';
import { Observable } from 'rxjs';
import {
  PageDto,
  CustomHttpUrlEncodingCodec,
  InstanceForm,
  InstanceView,
} from '@dooh/models';
import { HttpParams } from '@angular/common/http';
interface filter {
  key: string;
  value: string;
}
@Injectable({
  providedIn: 'root',
})
export class InstancesService {
  constructor(private apiService: ApiService) {}

  getInstanceFromApp(
    application: 'SSP' | 'DSP' | 'OOH' | 'ADMIN_PANEL',
    filters?: Array<filter>
  ): Observable<PageDto> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    if (filters) {
      filters.forEach((element) => {
        queryParameters = queryParameters.append(
          element.key,
          <any>element.value
        );
      });
    }

    return this.apiService.get(
      `${application.toLowerCase()}/instances`,
      queryParameters
    );
  }

  createSMBInstance(instance: InstanceForm) {
    if (instance === null || instance === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling create instance.'
      );
    }
    return this.apiService.post('admin/smb/instances', {
      name: instance.name,
      businessName: instance.name,
      countryCode: instance.countryCode['code'],
      currencyCode: instance.currencyCode['code'],
      timezone: instance.timezone['code'],
      domain: instance.businessWebsite || null,
      isArchived: false,
      deactivated: false,
      markup: 0,
    });
  }

  getInstanceInfo(instanceId): Observable<InstanceView> {
    const endpoint = `admin/instances/${instanceId}`;
    return this.apiService.get(endpoint);
  }

  getInstanceUsers(instanceId): Observable<any> {
    const endpoint = `admin/users`;
    const params = new HttpParams({ fromObject: { instance_id: instanceId } });
    return this.apiService.get(endpoint, params);
  }

  updateInstance(
    instanceId,
    instanceObj: Partial<InstanceForm>
  ): Observable<any> {
    const endpoint = `admin/instances/${instanceId}`;
    return this.apiService.put(endpoint, instanceObj);
  }
}
