import {Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { DialogCommonData } from '@dooh/models';
import { SelectedInstanceService } from '@dooh/common-services';
import { noWhitespaceValidator } from '@dooh/validation';

import { DialogService } from '../dialog.service';
import { SspAutocompleteService } from './ssp-autocomplete.service';

@Component({
  selector: 'dooh-dialog-new-deal-id',
  templateUrl: './dialog-new-deal-id.component.html',
  providers: [
    SspAutocompleteService
  ]
})
export class DialogNewDealIdComponent implements OnInit {
  instanceId: string

  public formGroupMain: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public sspAutocompleteService: SspAutocompleteService,
    public dialogRef: MatDialogRef<DialogNewDealIdComponent>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: DialogCommonData
  ) {}

  createForms() {
    this.formGroupMain = this.formBuilder.group({
      dealIdName: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z|\\-_\\d\\s]+'),
        noWhitespaceValidator()
      ]),
      sspName: new FormControl('', [
        Validators.required
      ]),
      dealIdCode: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z\\-_\\d]+'),
        Validators.minLength(2)
      ])
    });
  }

  sspExtractValue(option) {
    return option.ssp.name;
  }

  onNoClick(): void {
    this.dialogRef.close({isOk: false});
  }

  onSubmitMainForm(): void {
    this.dialogRef.close({
      isOk: true,
      newDeal: {
        advertisers: [{advertiserId: this.data.advertiserId}],
        code: this.formGroupMain.get('dealIdCode').value,
        name: this.formGroupMain.get('dealIdName').value,
        sspId: this.formGroupMain.get('sspName').value.ssp.id
      }
    })
  }

  ngOnInit() {
    this.instanceId = SelectedInstanceService.getInstanceId();
    this.createForms();
  }
}
