import { ValidatorFn, AbstractControl } from '@angular/forms';

export default function notANumberValidatorSimple(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let value: string = String(control.value);
    value = value.replace(/ /g, '');
    if (value.trim() === '') {
      return null;
    }
    const key = Number(value);
    const isNotNumeric = isNaN(key);
    return !isNotNumeric ? null : { 'nonumber': true };
  };
}
