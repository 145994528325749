<div class="mandatory-option" [ngClass]="{'mandatory-option_checked': option?.checked, 'mandatory-option_unchecked': !option?.checked}">
  <section *ngIf="!(acceptedImageFormat ||  acceptedVideoFormat)">
    <ng-template [ngTemplateOutlet]="videoIcon"></ng-template>
    <ng-template [ngTemplateOutlet]="imageIcon"></ng-template>
  </section>
  <p class="mime" *ngIf = "acceptedImageFormat"> 
    <ng-template [ngTemplateOutlet]="imageIcon"></ng-template>
    <span>{{acceptedImageFormat}}</span> 
  </p>
  <p class="mime" *ngIf = "acceptedVideoFormat"> 
    <ng-template [ngTemplateOutlet]="videoIcon"></ng-template>
    <span>{{acceptedVideoFormat}}</span> 
  </p>
  <p class="texts">
    <b class="resolution-text">{{ name }}</b>
    <span class="video_duration" *ngIf="acceptsVideo && (option.maxDuration > 0 || option.minDuration > 0)">
      &nbsp;|&nbsp;<span>{{option.maxDuration > 0 ? option.maxDuration + ' sec' : option.minDuration + ' sec'}}</span>
    </span>
  </p>
</div>

<ng-template #imageIcon>
  <mat-icon color="primary" class="mandatory-option__icon" svgIcon="creative_image"
  *ngIf="acceptsImg"></mat-icon> 
</ng-template>

<ng-template #videoIcon>
  <mat-icon color="primary" class="mandatory-option__icon" svgIcon="creative_video"
  *ngIf="acceptsVideo"></mat-icon> 
</ng-template>