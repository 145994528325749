import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'dooh-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent implements OnInit {

  @Input() images: string[] = [];

  customOptions: OwlOptions = {
    loop: false,
    rewind: true,
    mouseDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 1000,
    items: 1,
    lazyLoad: false,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    nav: true,
    navText: ['<', '>'],
    responsive: {
      0 : {
        items: 1
      },
      940: {
        items: 1
      }
    }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
