import { Component, ComponentFactoryResolver, ComponentRef, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PointOfInterestService } from '@dooh/common-services';
import { AlertDialogData } from '@dooh/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dooh-zip-code-import-dialog',
  templateUrl: './zip-code-import-dialog.component.html',
  styleUrls: ['./zip-code-import-dialog.component.scss']
})
export class ZipCodeImportDialogComponent implements OnInit {

  contentRef: ComponentRef<any>;
  excelFile: any = null;
  fileName: string;
  constructor(
    private translateService: TranslateService,
    private resolver: ComponentFactoryResolver,
    private dialogRef: MatDialogRef<ZipCodeImportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogData,
    private poiService: PointOfInterestService
  ) {
    this.translateService.setDefaultLang('en');
  }

  ngOnInit() {
    
  }

  ngOnDestroy() {
    if (this.contentRef) {
      this.contentRef.destroy();
    }
  }

  onSubmitClick() {
    this.poiService.setZipCodeFileUploadStatus(true);
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  setFile(event: any) {
    this.fileName = this.excelFile.split(/(\\|\/)/g).pop();
    this.poiService.setCustomZipCodeFile(event.target.files);    
  }
  

}
