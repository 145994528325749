/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { DEAL_TYPE } from "../lib/LineItems/deal-type.model";


export interface LineItemView {
  bidPrice?: number;
  budget?: number;
  campaignId?: string;
  dailyBudget?: number;
  endDate?: Date;
  id?: string;
  isArchived?: boolean;
  name?: string;
  pacing?: LineItemView.PacingEnum;
  startDate?: Date;
  deactivated?: boolean;
  canBeDeleted?: boolean;
  excluded_toggle?: boolean;
  status?: string;
  timezone?: string;
  dealType?: DEAL_TYPE
}

export namespace LineItemView {
  export type PacingEnum = 'EVEN' | 'ASAP';
  export const PacingEnum = {
    EVEN: 'EVEN' as PacingEnum,
    ASAP: 'ASAP' as PacingEnum
  };
}

export enum LINEITEM_STATE {
  DRAFT =  'DRAFT',
  PAUSED =  'PAUSED',
  ENDED =  'ENDED',
  ACTIVE =  'ACTIVE',
  REFUNDED =  'REFUNDED',
  CANCELED =  'CANCELED',
}

export enum RATIO_STATE {
  CRITICAL =  'Critical',
  EXTREME =  'Extreme',
  HIGH =  'High',
  MIDDLE =  'Middle',
  LOW =  'Low',
  NORMAL =  'Normal',
}

export enum PACING_STATE {
  UNDERSPENDING=  'Underspending',
  OVERSPENDING =  'Overspending',
  NORMAL =  'Normal',
}
