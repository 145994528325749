<div class="paginator">
  <mat-paginator
    *ngIf="isPaginated"
    [length]="pagination.totalElements"
    [pageIndex]="pagination.pageIndex - 1"
    [pageSize]="pagination.pageSize"
    [pageSizeOptions]="pageOptions"
    (page)="onPageHandler($event)"
    [disabled]="isLoading"
  ></mat-paginator>
</div>

<div class="wrapper" [ngClass]="{ lower: isPaginated }">
  <div
    class="map-container__overlay map-container__overlay_colored loader-center--flex"
    *ngIf="isLoading"
  >
    <dooh-custom-loader></dooh-custom-loader>
  </div>
  <button *ngIf="geoSearchButton && canGeoSearch" mat-raised-button class="geo-search__button" (click)="geoSearch()">
    <mat-icon>search</mat-icon>
    Search on this area
  </button>

  <div class="mapbox-gl" id="map"></div>
</div>
