<div class="tutorial-page__container">
  <div *ngIf="isLoading" class="tutorial__loading--container">
    <mat-spinner></mat-spinner>
  </div>
  <div class="tutorial-page__header">
    <div class="logo-container" [routerLink]="'/'" [style.width]="logoWidth">
      <img *ngIf="namespace" [src]="namespace?.url" class="namespace__logo">
    </div>
    <p class="tutorial-page__title">Video Tutorials</p>
  </div>
  <div class="tutorial-content__container">
    <ng-container *ngIf="!isLoading">
      <div class="left-section">
        <div class="tutorial-card" *ngFor="let tutorial of tutorialList; let i = index">
          <span class="tutorial-card__watched" *ngIf="getVideoStatus(tutorial?.Key)">Watched</span>
          <p [class.watched]="getVideoStatus(tutorial?.Key)" class="tutorial-card__title" (click)="currentSelectedVideo = tutorial">
            {{i+1}}. {{tutorial?.Metadata["neuron-title"]}}
          </p>
          <p class="tutorial-card__desc">
            {{tutorial?.Metadata["neuron-description"]}}
          </p>
          <p class="tutorial-card__duration">
            {{tutorial?.Metadata["duration"]}}
          </p>
        </div>
      </div>
    </ng-container>
    <div class="right-section" *ngIf="!isLoading && currentSelectedVideo">
      <div class="video__container">
        <vg-player class="video__player">
          <vg-overlay-play></vg-overlay-play>
          <vg-buffering></vg-buffering>

          <vg-scrub-bar>
            <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
            <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
          </vg-scrub-bar>

          <vg-controls>
            <vg-play-pause></vg-play-pause>
            <vg-playback-button></vg-playback-button>

            <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

            <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>

            <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>
            <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
            <vg-mute></vg-mute>

            <vg-volume></vg-volume>

            <vg-fullscreen></vg-fullscreen>
          </vg-controls>

          <video (ended)="markVideoAsWatched(currentSelectedVideo.Key)" [vgMedia]="media" #media [src]="currentSelectedVideo?.Key" id="singleVideo" preload="auto">
            <source [src]="currentSelectedVideo.Key" type="video/mp4">
          </video>
        </vg-player>
      </div>
      <div class="transcript__container">
        <p class="video__player--title">
          {{currentSelectedVideo?.Metadata["neuron-title"]}}
        </p>
        <!-- <p class="video__player--transcript" [innerHTML]="currentSelectedVideo?.Metadata['neuron-trans']">
        </p> -->
      </div>
    </div>
  </div>
</div>
