import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@dooh/auth';
import { ActiveNamespaceService } from '@dooh/common-services';
import { ToastService } from '@dooh/common-services';
import { DialogService } from '@dooh/components-library';
import { AlertDialogType, NamespaceView } from '@dooh/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dooh-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  resetPassword: FormGroup;
  namespace: NamespaceView;
  logoWidth: string;

  unsubscriber$ = new Subject<void>()

  constructor(
    private fb: FormBuilder,
    private Auth: AuthService,
    private router: Router,
    private toastService: ToastService,
    private dialogService: DialogService,
    private activeNamespaceService: ActiveNamespaceService
  ) {
    this.namespace = ActiveNamespaceService.getNamespace();
    this.activeNamespaceService.loadNamespace()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(value => { this.namespace = value})
  }

  ngOnInit(): void {
    this.logoWidth = this.getContainerWidth(this.namespace?.url);
    this.resetPassword = this.fb.group({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
      ]),
    });
  }

  onSubmit() {
    if (this.namespace.isNewUserSendEmail != null && this.namespace.isNewUserSendEmail == false) {
      this.toastService.showNotification({title: '', text: 'Password changes are managed by the administrator. Please contact them for assistance.'}, 'warning', 20000);
      return;
    }
    this.Auth.resetPassword(this.resetPassword.getRawValue()['email'].toString().toLowerCase()).then(resp => {
        this.openConfirmDialog("success");
      } ).catch(error => {
        this.dialogService.alert({
          type: 'error',
          message: error.message
        })
    })
  }

  openConfirmDialog(action: AlertDialogType): void  {
    this.dialogService
        .alert({
          type: action,
          message: 'login.resetPassword.successDialog'
        })
        .afterClosed()
        .subscribe(res => {
          if (!res) return;
          this.router.navigateByUrl('/set-new-password');
        });
    }

    preloadImage(url: string) {
      const img = new Image();
      img.src = url;
      return img
    }
  
    getContainerWidth(url: string): string {
      if (url) {
        const image = this.preloadImage(url);
        const width = image?.width;
        const height = image?.height;
  
        const ratio = width / height;
        if (ratio > (4/3)) {
          return '200px';
        } else {
          return '125px';
        } 
      } else {
        return '';
      }
    }

    ngOnDestroy(): void {
        this.unsubscriber$.next();
        this.unsubscriber$.complete();
    }
}
