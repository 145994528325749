import { NgModule } from '@angular/core';

import { MoneyPipe } from './money/money.pipe';
import { TooltipListPipe } from './tooltip-list/tooltip-list.pipe';
import { AbbreviatePipe } from './abbreviate/abbreviate.pipe';
import { ReplacePlaceholderPipe } from './replace-placeholder/replace-placeholder.pipe';
import { UsDatePipe } from './us-date-format/us-date-format.pipe';
import { PascalCaseToTextPipe } from './pascal-case-to-text/pascal-case-to-text.pipe';

@NgModule({
  declarations: [
    MoneyPipe,
    TooltipListPipe,
    AbbreviatePipe,
    ReplacePlaceholderPipe,
    UsDatePipe,
    PascalCaseToTextPipe,
  ],
  providers: [UsDatePipe],
  exports: [
    MoneyPipe,
    TooltipListPipe,
    AbbreviatePipe,
    ReplacePlaceholderPipe,
    UsDatePipe,
    PascalCaseToTextPipe,
  ],
})
export class CommonPipesModule {}
