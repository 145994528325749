import { RemoveSeparatorPipe } from './../../../../common-pipes/src/lib/remove-separator/remove-separator.pipe';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ActiveNamespaceService, InstancesService, SelectedInstanceService } from '@dooh/common-services';
import { ScreensService } from 'apps/smb-app/src/app/campaign-management/services/screens.service';
import { Observable } from 'rxjs';
import { map, mergeMap, retry } from 'rxjs/operators';

export enum APPLICATION_TYPE {
  DSP = 'DSP',
  SSP = 'SSP',
  OOH = 'OOH',
  ADMIN_PANEL = 'ADMIN_PANEL'
}

interface filter {
  key: string;
  value: string;
}

const APPLICATION_CONFIG = {
  [APPLICATION_TYPE.DSP]: {
    type: APPLICATION_TYPE.DSP,
    url: '/dsp',
  },
  [APPLICATION_TYPE.SSP]: {
    type: APPLICATION_TYPE.SSP,
    url: '/ssp',
  },
};

@Component({
  selector: 'dooh-workspace-dropdown',
  templateUrl: './workspace-dropdown.component.html',
  styleUrls: ['./workspace-dropdown.component.scss'],
  providers: [RemoveSeparatorPipe] 
})
export class WorkspaceDropdownComponent implements OnInit, OnChanges {
  @Input() application: APPLICATION_TYPE;
  @Input() whiteLabelControl = new FormControl();

  @Input() workspaceControl = new FormControl();
  workspaceOptions: any[] = [];
  debounceTimer: any;
  characterSeparator = '__';

  constructor(
    private instanceService: InstancesService,
    private activeNamespaceService: ActiveNamespaceService,
    private screensService: ScreensService,
    private router: Router,
    private removeSeparatorPipe: RemoveSeparatorPipe
  ) { }

  ngOnInit() {
    const defaultValue = SelectedInstanceService.getInstance();
    if (defaultValue) {
      defaultValue.name = this.removeSeparatorPipe.transform(defaultValue.name , this.characterSeparator);
      this.workspaceControl.setValue(defaultValue || '');
    }
    this.getInstances();
    if (this.application === APPLICATION_TYPE.ADMIN_PANEL) {
      this.application = APPLICATION_TYPE.DSP
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    changes['whiteLabelControl']?.currentValue?.valueChanges.subscribe((value) => {
      if (value) {
        this.workspaceControl.setValue(undefined);
        this.getInstances();
      }
    });
  }

  private getInstances(isAdminPanel?: boolean): void {
    this.instanceService.getInstanceFromApp(this.application).subscribe({
      next: (response) => {
        this.workspaceOptions = response.content.map(option => ({ ...option , name : this.removeSeparatorPipe.transform(option.name , this.characterSeparator) }));
      },
    });
  }

  optionSelected(option: any): void {
    option.name = this.removeSeparatorPipe.transform(option.name , this.characterSeparator);
    this.workspaceControl.setValue(option);

    this.storeRolesLocalStorage(option);

    const temporaryNamespace = ActiveNamespaceService.getTemporaryNamespace();

    if (temporaryNamespace) {
      this.activeNamespaceService.setNamespace(temporaryNamespace);
    }

    if (!option) {
      SelectedInstanceService.clearInstance();
      this.fnOnInstanceChange(APPLICATION_CONFIG[this.application]);
      return;
    }

    const handleInstance = (instance) => {
      instance.name = this.removeSeparatorPipe.transform(instance.name , this.characterSeparator);
      SelectedInstanceService.setInstance(instance);
      this.fnOnInstanceChange(APPLICATION_CONFIG[this.application]);
    };

    if (option?.country) {
      this.getInstanceCountry(option.country)
        .subscribe({
          next: (res) => {
            option.location = res;
            handleInstance(option);
          },
          error: (error) => {
            console.error('Error fetching instance country:', error);
          }
        });
    }
    else {
      handleInstance(option);
    }
  }

  storeRolesLocalStorage(selectedOption) {
    const instancesWithRoles = JSON.parse(localStorage.getItem('instancesWithRoles'));
    const selectedInstance = instancesWithRoles?.find(instance => instance.instance === selectedOption.id);
    if(selectedInstance) {
      localStorage.setItem('dspRole', selectedInstance.dspRole);
      localStorage.setItem('sspRole', selectedInstance.sspRole);
      localStorage.setItem('agencyId', selectedInstance.agencies);
    }
  }

  onInput(value: string): void {
    this.debounceTimer = clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      this.searchInstance(value);
    }, 300);
  }
  
  searchInstance(value: string): void {
    const filters: Array<filter> = [];

    if (value.length >= 2) {
      filters.push({ key: 'filters', value: `name,like,%${value}%` });

      this.instanceService
        .getInstanceFromApp(this.application, filters)
        .subscribe({
          next: (resp) => {
            this.workspaceOptions = resp.content.map(option => ({ ...option,name : this.removeSeparatorPipe.transform(option.name , this.characterSeparator) }));
          },
        });
    }
    else {
      this.getInstances();
    }
  }

  fnOnInstanceChange = (appTypeConfig) => {
    if (!appTypeConfig) {
      return;
    }

    const currentUrl = this.router.url;
    const baseModuleUrl = currentUrl.split('/').slice(0, 3).join('/');
    this.router.navigateByUrl(baseModuleUrl).then(() => {
      window.location.reload();
    });
    
  };

  protected getInstanceCountry(instance): Observable<any> {
    const instanceName = instance?.name;
    return this.screensService.getPlacesFromGoogle(instanceName, null)
      .pipe(
        retry(1),
        map(googleRes => {
          let placeId;
          placeId = googleRes?.predictions.find(result => result.description.toLowerCase() === instanceName.toLowerCase()).place_id;
          return placeId;
        }),mergeMap((googlePlaceId: string) =>
          this.screensService.getGooglePlaceInfo(googlePlaceId)
            .pipe(
              retry(1),
              map(googlePlaceRes => {
                let location;
                location = googlePlaceRes?.results[0]?.geometry?.location;
                return location;
              })
            )
        )
      );
  }

}
