<div class="map-container">
  <div class="action-buttons">
    <button
      mat-icon-button
      color="primary"
      class="action-button"
      (click)="toggleFullScreen()"
    >
      <mat-icon *ngIf="!isFullScreen" class="icon">fullscreen</mat-icon>
      <mat-icon *ngIf="isFullScreen" class="icon">fullscreen_exit</mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      (click)="onCloseClick()"
      class="action-button"
    >
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>

  <div class="map-area">
    <div class="marker-group-table w-50" *ngIf="showingMarkerGroupTable">
      <dooh-marker-multi-screen-table (selectedScreen)="SetScreenInfo($event)" [markers]="markerGroup" (close)="clearInfo()"></dooh-marker-multi-screen-table>
    </div>
    <div class="w-30 info-window-container" *ngIf="showingMapInfo">
      <div class="buttons">
        <div class="back-button" *ngIf="markerGroup?.length > 2">
          <button (click)="backToMarkerTable()">
            <mat-icon class="icon">chevron_left</mat-icon>
            Back
          </button>
        </div>
        <div class="close-button">
          <button
            mat-icon-button
            color="primary"
            (click)="clearInfo()"
            class="action-button"
          >
            <mat-icon svgIcon="close"></mat-icon>
          </button>
        </div>
      </div>
      <div class="marker-info-window" *ngIf="markerPopUpInfo">
        <div
          class="marker-window__cover"
          [ngClass]="{'multiple' : images.length > 1}"
        >
          <ng-container
            [ngTemplateOutlet]="images.length > 0 ? imageSlideshow : noImage"
          ></ng-container>
        </div>
        <div class="marker-window__body">
          <div class="marker-window__title">
            {{ markerPopUpInfo?.name }}
          </div>

          <div class="marker-window__field-list" *ngIf="markerPopUpInfo?.formats">
            <div class="marker-window__field_format">
              <span class="marker-window__field-label">
                {{
                  'smb.campaignManagement.campaign.tab.screens.resolution'
                    | translate
                }}
              </span>
              <ng-template ngFor let-format [ngForOf]="markerPopUpInfo.formats">
                <div class="format-title">
                  <p>{{format.name}}</p>
                  <ng-template ngFor let-mimeType [ngForOf]="format.mimeTypes" let-j="index">
                    <span>
                      {{mimeType}}{{j === format.mimeTypes.length -1 ? '' : ', ' }}
                    </span>
                  </ng-template>
                </div>
              </ng-template>
            </div>
          </div>

          <div class="marker-window__field-list">
            <div class="marker-window__field venueType-wrapper" *ngIf="markerPopUpInfo?.venueType">
              <span class="marker-window__field-label">
                {{
                  'smb.campaignManagement.campaign.tab.screens.venueType'
                    | translate
                }}
              </span>

              <span class="marker-window__field-value"   *ngIf="markerPopUpInfo?.venueType">
                {{  markerPopUpInfo?.venueType?.id ?
                      (markerPopUpInfo?.venueType?.id !== undefined ? getVenueTypeName(markerPopUpInfo?.venueType?.id) : '-')
                    : '-' }}
              </span>
              <span
                class="marker-window__field-value"
                *ngIf="!markerPopUpInfo?.venueType"
              >
                -
              </span>
            </div>
            <div class="marker-window__field venueType-wrapper" *ngIf="markerPopUpInfo?.venueTypeId">
              <span class="marker-window__field-label">
                {{
                  'smb.campaignManagement.campaign.tab.screens.venueType'
                    | translate
                }}
              </span>

              <span class="marker-window__field-value"   *ngIf="markerPopUpInfo?.venueTypeId">
                {{  markerPopUpInfo?.venueTypeId ?
                      (markerPopUpInfo?.venueTypeId !== undefined ? getVenueTypeName(markerPopUpInfo?.venueTypeId) : '-')
                    : '-' }}
              </span>
              <span class="marker-window__field-value"   *ngIf="!markerPopUpInfo?.venueTypeId">
                -
              </span>

            </div>
            <div class="marker-window__field">
              <div
                class="marker-window__field"
                matTooltip="{{ 'Expected reach per week' | translate }}"
              >
                <span class="marker-window__field-label">
                  <span>{{ 'Audience' | translate }}</span>
                  <sup class="sup__new">New</sup>
                </span>
                <span class="marker-window__field-value" *ngIf="markerPopUpInfo?.audiencePerWeek">{{
                  markerPopUpInfo?.audiencePerWeek | number: '1.0-0'
                }}</span>
                <span *ngIf="!markerPopUpInfo?.audiencePerWeek "> - </span>
              </div>
            </div>
          </div>

          <div class="marker-window__field-list">
            <div
              class="marker-window__field marker-window__field_street"
              *ngIf="markerPopUpInfo?.address?.street"
            >
              <span class="marker-window__field-label">
                {{
                  'smb.campaignManagement.campaign.tab.screens.address'
                    | translate
                }}
              </span>
              <span class="marker-window__field-value">
                {{ markerPopUpInfo?.address?.street }}
              </span>
            </div>
            <div
              class="marker-window__field marker-window__field_street"
              *ngIf="markerPopUpInfo?.ssp?.name"
            >
              <span class="marker-window__field-label">
                {{ 'adminPanel.instanceInfo.tab.ssp' | translate }}
              </span>
              <span class="marker-window__field-value">
                {{ markerPopUpInfo?.ssp?.name }}
              </span>
            </div>
            <div
              class="marker-window__field marker-window__field_street"
              *ngIf="markerPopUpInfo?.publisher?.name"
            >
              <span class="marker-window__field-label">
                {{
                  'ssp.screensManagement.screens.table.column.publisher'
                    | translate
                }}
              </span>
              <span class="marker-window__field-value">
                {{ markerPopUpInfo?.publisher?.name }}
              </span>
            </div>
            <div
              class="marker-window__field marker-window__field_street"
              *ngIf="markerPopUpInfo?.convertedPrice"
            >
              <span class="marker-window__field-label">
                {{
                  'ssp.screensManagement.screens.table.column.floorPrice'
                    | translate
                }}
              </span>
              <span class="marker-window__field-value">
                {{ markerPopUpInfo?.convertedPrice?.floorPrice | money }}
              </span>
            </div>
          </div>

          <div class="marker-window__reach-container">
            <p class="marker__reach--title">
              <span>{{
                'smb.campaignManagement.campaign.tab.screens.chartTitle'
                  | translate
              }}</span>
              <sup class="sup__new">New</sup>
            </p>
            <ng-container *ngIf="markerPopUpInfo.dayAudience?.length > 0 && showChart">
              <ngx-charts-bar-vertical
                [view]="view"
                [scheme]="colorScheme"
                [results]="markerPopUpInfo.dayAudience"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [tooltipDisabled]="true"
              >
              </ngx-charts-bar-vertical>
            </ng-container>
            <p
              class="marker__performance--empty"
              *ngIf="
                markerPopUpInfo.dayAudience?.length === 0 ||
                !markerPopUpInfo.dayAudience
              "
            >
              {{
                'smb.campaignManagement.campaign.tab.screens.emptyScreen'
                  | translate
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="map" [ngClass]="showingMapInfo ? 'w-70' : showingMarkerGroupTable ? 'w-50' : 'w-100'">
      <dooh-map-box
        #mapBox
        [markers]="markers"
        [location]="location"
        [isPaginated]="isMapPaginated"
        [pagination]="mapPagination"
        [isLoading]="isMapLoading"
        (markerClick)="showMarkerDetails($event)"
        (pageToggle)="changePage($event)"
        (mapClick)="clearInfo()"
      ></dooh-map-box>
    </div>
  </div>
</div>

<ng-template #imageSlideshow>
  <dooh-image-carousel [images]="images"></dooh-image-carousel>
</ng-template>

<ng-template #noImage>
  <img
    src="../../../assets/images/no-image.svg"
    alt="drop file"
    class="no-image"
  />
</ng-template>
