<div class="container">
    <div class="loader-center" *ngIf="loadingDmaItems">
      <dooh-custom-loader></dooh-custom-loader>
    </div>
    <ng-container *ngIf="!loadingDmaItems">
      <div class="container__wrapper">
        <section>
          <dooh-search-field class="transactions-content__search" [debounceTime]="500"
            [placeHolderMessage]="'Search by DMA name'" (inputValue)="filterDma($event)"></dooh-search-field>
        </section>
        <div class="inner-loader" *ngIf="loadingFilterResult">
          <dooh-custom-loader [diameter]="60"></dooh-custom-loader>
        </div>
        <ng-container *ngIf="!loadingFilterResult">
          <form class="container__form" [formGroup]="dmaFormGroup">
            <ul class="container__listGroup">
              <li *ngFor="let dma of dmaDatasource.data">
                <label [for]="dma.dmaName + dma.id">
                  <input type="checkbox" [checked]="dmaUniqueSet.has(dma.id)" name="dmaCheckbox" [id]="dma.dmaName + dma.id" (change)="onCheckboxChange($event)"
                    [value]="dma.id" />
                  <span>{{ dma.dmaName }}</span>
                </label>
              </li>
            </ul>
          </form>
        </ng-container>
  
        <div class="container__action">
          <button color="primary-inverse"
          class="button-container__button stroked" mat-button (click)="reset(true)">
            {{ 'generic.reset' | translate }}
          </button>
          <button class="btn" mat-raised-button color="primary" [disabled]="dmaUniqueSet.size < 1" (click)="applyFilter()">
            Apply Filter
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  