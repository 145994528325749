import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { ApiService, CustomHttpUrlEncodingCodec } from '@dooh/api';

import { ScreensFilters } from './screens-filters';
import { FieldFilterRequest } from '@dooh/models';
import { SearchFilterService } from 'libs/components-library/src/lib/dialogs/screen-search-dialog/service/search-filter.service';

const PAGE = 1;
const PAGE_SIZE = 1;

@Injectable({
  providedIn: 'root'
})
export class ScreensFiltersService {
  constructor(private apiService: ApiService, private searchFilterService: SearchFilterService) {}

  getScreensFilters(url: string, filters: ScreensFilters) {
    const { screenTags, pois, pagination = {}, bboxes ,postalCodes} = filters;

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    queryParameters = queryParameters.set('page', <any>PAGE);
    queryParameters = queryParameters.set('page_size', <any>PAGE_SIZE);

    let screenTagsArr = [];
    if (screenTags) {
      screenTagsArr = Array.isArray(screenTags) ? screenTags : [screenTags];
    }
    const params = screenTagsArr.reduce(
      (obj, item) => {
        if (!item || !obj) {
          return obj;
        }
        if (item.tag === 'Place' || item.tag.toLowerCase() === 'country' || item.tag === 'ssp') {
          return obj
        }
        if (!obj[item.tag]) {
          obj[item.tag] = {};
        }
        if (!obj[item.tag].filterTerms) {
          obj[item.tag].filterTerms = [];
        }
        Array.isArray(item.id) ? obj[item.tag].filterTerms = item.id : obj[item.tag].filterTerms.push(item.id);
        if (item.id) {
          obj[item.tag].page = pagination[item.tag]?.page;
          obj[item.tag].pageSize = pagination[item.tag]?.pageSize;
        }
        if (!obj[item.tag].filterTerms.length) {
          // TNS-3906 Having empty array instead of not having this field leads to not correct backend resposes
          delete obj[item.tag].filterTerms;
        }
        return obj;
      },
      {...pagination}
    );

    const body: any = {
      params: params,
      poi: pois ? {
        distance: pois.radius,
        points: pois.points
      } : null,
      postalCodes:postalCodes? postalCodes: null,
      bboxes: bboxes ? bboxes : null,
    };

    const targetingFilter: FieldFilterRequest = {
      body, queryParams: queryParameters, queryUrl: url
    }
    
    // this.searchFilterService.setFilterByFieldSource(targetingFilter);
    return this.apiService.post(url, body, queryParameters);
  }
}
