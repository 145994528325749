import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { InstanceView } from '@dooh/models';
import { SelectedInstanceService } from '@dooh/common-services';
import { filter } from 'rxjs/operators';

import { numberStringValidator } from '@dooh/validation';
import { MoneyFormatter } from '../../../../common-services/src/lib/utils/money-formatter.component';

const CHANGES_DELAY = 300;
const NUMBER_PATTERN = '^\\d+(\\.\\d*)?$';
const ALMOST_CORRECT_NUMBER_PATTERNS = [
  /^(\d+\.\d*[1-9]+)[0]+$/,
  /^(\d+)\.[0]*$/
];

@Component({
  selector: 'dooh-budget-picker',
  templateUrl: './budget-picker.component.html',
  styleUrls: ['./budget-picker.component.scss']
})
export class BudgetPickerComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  plannedBudgetVal?: string;

  @Input()
  dailyBudgetVal?: string;
  @Input()
  isBasedOnDailyBudget?: any;
  @Input()
  offer?: any;

  budgetGroup: FormGroup;
  instance: InstanceView;
  isChecked = false;
  isCheckedCampaign = false;
  isCheckedDaily = false;
  currency: string;
  @ViewChild('campaignB') campaignB: ElementRef;
  @ViewChild('dailyB') dailyB: ElementRef;
  changesTimeout = null;

  @Output()
  budgetEvent: EventEmitter<{ type: string, value: string }> = new EventEmitter<{ type: string, value: string }>();

  @Output()
  isBudgetValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  isDaySelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  fieldValueCampaign?: string;
  fieldValueDaily?: string;
  constructor(private fb: FormBuilder, private translateService: TranslateService) {
    this.translateService.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.budgetGroup = this.initForm();
    this.currency = SelectedInstanceService.getInstanceCurrency();

    this.budgetGroup.statusChanges
      .pipe(
        filter((status: string) => {
          return !this.budgetGroup.valid;
        })
      )
      .subscribe(() => this.isBudgetValid.emit(false));


    this.budgetGroup.statusChanges
      .pipe(
        filter((status: string) => {
          return this.budgetGroup.valid;
        })
      )
      .subscribe(() => this.isBudgetValid.emit(true));
  }

  ngAfterViewInit(): void {
    if (this.isCheckedCampaign) {
      this.campaignB.nativeElement.focus();
      return;
    }
    if (this.isCheckedDaily) {
      this.dailyB.nativeElement.focus();
      return;
    }
    if (this.offer) {
      this.fieldValueCampaign = this.offer?.minimumBudget;
      this.budgetGroup.controls['campaignBudget'].enable();
      this.budgetGroup.controls['campaignBudget'].patchValue(this.fieldValueCampaign);
      this.onActiveCampaignBudget();
      this.onChangeInput();
      if (this.budgetGroup.valid) this.isBudgetValid.emit(true);
    }
  }

  updateIsCheckedAndValuesByInputVals() {
    this.isCheckedCampaign = this.isBasedOnDailyBudget === undefined ? undefined : !this.isBasedOnDailyBudget;
    this.isCheckedDaily = this.isBasedOnDailyBudget === undefined ? undefined : this.isBasedOnDailyBudget;
    this.isChecked = this.isCheckedCampaign || this.isCheckedDaily;
    this.fieldValueCampaign = this.isCheckedCampaign ? this.plannedBudgetVal : '';
    this.fieldValueDaily = this.isCheckedDaily ? this.dailyBudgetVal : '';
  }

  removeUselessParts(regex: RegExp, numStr?: string) {
    if (!numStr || !regex || !regex.test(numStr)) {
      return numStr;
    }
    const m = numStr.match(regex);
    return m[1];
  }

  formatNumberStr(numStr?: string) {
    if (!Array.isArray(ALMOST_CORRECT_NUMBER_PATTERNS)) {
      return numStr;
    }
    return ALMOST_CORRECT_NUMBER_PATTERNS.reduce(
      (res, curRegEx) => {
        return this.removeUselessParts(curRegEx, res);
      },
      numStr
    );
  }

  ngOnChanges(changes) {
    if (
      (changes['plannedBudgetVal'] && !changes['plannedBudgetVal'].firstChange) ||
      (changes['dailyBudgetVal'] && !changes['dailyBudgetVal'].firstChange)
    ) {
      this.updateIsCheckedAndValuesByInputVals();
      if (!this.budgetGroup) {
        return;
      }

      if (this.isCheckedCampaign === true) {
        this.budgetGroup.controls['campaignBudget'].enable();
        this.budgetGroup.controls['campaignBudget'].patchValue(this.fieldValueCampaign);
        this.onActiveCampaignBudget();
        return;
      }
      if (this.isCheckedDaily === true) {
        this.budgetGroup.controls['dailyBudget'].enable();
        this.budgetGroup.controls['dailyBudget'].patchValue(this.fieldValueDaily);
        this.onActiveDailyBudget();
        return;
      }
      this.budgetGroup.controls['budgetOption'].patchValue('');
      this.budgetGroup.controls['campaignBudget'].reset();
      this.budgetGroup.controls['campaignBudget'].disable();
      this.budgetGroup.controls['dailyBudget'].reset();
      this.budgetGroup.controls['dailyBudget'].disable();
    }
  }

  initForm() {
    this.updateIsCheckedAndValuesByInputVals();

    return this.fb.group({
      budgetOption: new FormControl(
        this.getRadioValue(this.isCheckedCampaign, this.isCheckedDaily),
        [ Validators.required ]
      ),
      campaignBudget: new FormControl(
        {
          value: this.fieldValueCampaign,
          disabled: !this.isCheckedCampaign
        },
        [
          Validators.required,
          Validators.pattern(NUMBER_PATTERN),
          numberStringValidator({ min: this.offer ? (this.offer?.minimumBudget - 0.01) : 0 })
        ]
      ),
      dailyBudget: new FormControl(
        {
          value: this.fieldValueDaily,
          disabled: !this.isCheckedDaily
        },
        [
          Validators.required,
          Validators.pattern(NUMBER_PATTERN),
          numberStringValidator({ min: 0 })
        ]
      )
    });
  }

  getRadioValue(isCheckedCampaign, isCheckedDaily) {
    if (isCheckedCampaign === true) {
      return 'campaignBudget';
    }
    if (isCheckedDaily === false || isCheckedDaily === true) {
      return 'dailyBudget';
    }
    return '';
  }

  onChangeInput(isDaily = false) {
    if (this.changesTimeout) {
      clearTimeout(this.changesTimeout);
    }
    this.changesTimeout = setTimeout(
      () => {
        if (this.budgetGroup.invalid) {
          return;
        }
        const type = isDaily ? 'dailyB' : 'campaignB';
        const value = isDaily ? this.fieldValueDaily : this.fieldValueCampaign;
        this.budgetEvent.emit({ type: type, value: value });
      },
      CHANGES_DELAY
    );
  }

  onBlurInput(isDaily = false) {
    if (this.changesTimeout) {
      clearTimeout(this.changesTimeout);
    }
    this.changesTimeout = setTimeout(
      () => {
        if (this.budgetGroup.invalid) {
          return;
        }
        const type = isDaily ? 'dailyB' : 'campaignB';
        const value = isDaily ? this.fieldValueDaily : this.fieldValueCampaign;
        let formattedValue = this.formatNumberStr(value);
        formattedValue = MoneyFormatter.transform(formattedValue);
        if (formattedValue !== value) {
          if (isDaily) {
            this.budgetGroup.controls['dailyBudget'].patchValue(formattedValue);
          } else {
            this.budgetGroup.controls['campaignBudget'].patchValue(formattedValue);
          }
        }
        this.budgetEvent.emit({ type: type, value: formattedValue });
      },
      CHANGES_DELAY
    );
  }

  onActiveCampaignBudget() {
    this.isChecked = true;
    this.isCheckedCampaign = true;
    this.isCheckedDaily = false;

    this.budgetGroup.controls['budgetOption'].patchValue('campaignBudget');
    this.budgetGroup.controls['dailyBudget'].reset();
    this.budgetGroup.controls['dailyBudget'].disable();
    this.budgetGroup.controls['campaignBudget'].enable();
    // this.campaignB.nativeElement.focus();
    this.isDaySelected.emit(false);
  }

  onActiveDailyBudget() {
    if (this.offer) return;
    this.isChecked = true;
    this.isCheckedCampaign = false;
    this.isCheckedDaily = true;

    this.budgetGroup.controls['budgetOption'].patchValue('dailyBudget');
    this.budgetGroup.controls['campaignBudget'].reset();
    this.budgetGroup.controls['campaignBudget'].disable();
    this.budgetGroup.controls['dailyBudget'].enable();
    // this.dailyB.nativeElement.focus();
    this.isDaySelected.emit(true);
  }

}
