import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiService} from '@dooh/api';
import { ActiveNamespaceService } from '@dooh/common-services';
import { UserCredentials } from '@dooh/models';
import { environment } from 'apps/sso-webapp/src/environments/environment';

type UserData = {
  companyName: string,
  email: string,
  companyWebsite: string,
  firstName: string,
  lastName: string,
  phone: string,
  termsOfServiceVersion: string,
  subscribeNewsLetter: boolean,
};

import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmbCreateUserService {

  private profilePicUpload = new ReplaySubject<boolean>(1);
  profilePicUpload$ = this.profilePicUpload.asObservable();

  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient
  ) {
  }

  getSMBUser(): Observable<any> {
    return this.apiService.get('admin/smb/users')
  }

  getUserInfo(): Observable<any> {
    return this.apiService.get('admin/users/self')
  }

  createSMBUser(user: UserData) {
    return this.apiService.post('admin/smb/users', {
      ...user,
      namespaceId: ActiveNamespaceService.getNamespaceId(),
    });
  }

  updateSmbUser(userObj:Partial<UserCredentials>):Observable<UserCredentials>{
    return this.apiService.patch('admin/smb/users', userObj)
  }
  updateUser(userObj:Partial<UserCredentials>):Observable<UserCredentials>{
    return this.apiService.patch('admin/users', userObj)
  }

  updateCompanyInfo(companyObj:Partial<UserCredentials>, instanceId):Observable<UserCredentials>{
    return this.apiService.patch(`admin/instances/${instanceId}`, companyObj)
  }

  uploadProfileImage(userId:string, data:FormData):Observable<any>{
    const endpoint = 'admin/smb/users/avatar';
    const headersConfig = {
      'With-Auth': 'true',
      Accept: "*/*",
    };
    const headers = new HttpHeaders(headersConfig);
    return this.httpClient.post(`${environment.api_url}/${endpoint}`,data,{headers});
  }
  setImageUploaded(val){
    this.profilePicUpload.next(val);
  }

  updateUserTermsVersion(user: { termsOfServiceVersion: string }) {
    return this.apiService.put(`admin/users/terms-of-service/${user.termsOfServiceVersion}`);
  }
}
