import { ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';

export default function dateRangeValidator(start: string, end: string): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const formGroup: FormGroup = control as FormGroup;

    if (!formGroup.get(start).value || !formGroup.get(end).value) {
      return;
    }

    const startDate = new Date(formGroup.get(start).value).toISOString();
    const endDate = new Date(formGroup.get(end).value).toISOString();

    return startDate <= endDate ? null : { 'dateRange': true };
  };
}