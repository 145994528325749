import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

@Component({
  selector: 'dooh-update-user-data-dialog',
  templateUrl: './update-user-data-dialog.component.html',
  styleUrls: ['./update-user-data-dialog.component.scss'],
})
export class UpdateUserDataDialogComponent implements OnInit {
  context: any;

  isLoading = false;
  isUpdated = false;

  constructor(
    private router: Router,
    private translateService: TranslateService
  ) {
    this.translateService.setDefaultLang('en');
  }

  getContext() {
    return this.context;
  }

  setContext(context) {
    this.context = context;
  }

  async updateUserData() {
    this.isLoading = true;

    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    const refreshToken = currentSession.getRefreshToken();
    cognitoUser.refreshSession(
      refreshToken,
      (err, session: CognitoUserSession) => {
        const currentToken = session.getIdToken();
        const instanceId = currentToken.payload['custom:instances'];
        const agencyId = currentToken.payload['custom:agencies'];

        this.isLoading = false;
        this.isUpdated = !!instanceId && !!agencyId;
        if (!this.isUpdated) return;

        localStorage.setItem('agencyId', agencyId);
        localStorage.setItem('smbInstance', instanceId);
      }
    );
  }

  onNext() {
    this.router.navigate(['smb', 'dashboard'], { replaceUrl: true });
  }

  ngOnInit(): void {
    this.updateUserData();
  }
}
