import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import {
  CountriesService,
  SmbCurrenciesService,
  InstancesService,
  SelectedInstanceService,
  TimezonesService
} from "@dooh/common-services";
import { AuthService } from '@dooh/auth';
import { TimezoneView, User } from '@dooh/models';
import { valueTypeValidator } from '@dooh/validation';
import { DialogService, ValidationError } from '@dooh/components-library';
import { UpdateUserDataDialogComponent } from "./update-user-data-dialog/update-user-data-dialog.component";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'dooh-instance-create',
  templateUrl: './instance-create.component.html',
  styleUrls: ['./instance-create.component.scss']
})
export class InstanceCreateComponent implements OnInit, OnDestroy {

  public user: User = new User();

  businesName = '';
  instanceForm: FormGroup;
  version: string;
  bannerOppened = true;
  autocompleteValidationErrors: ValidationError[] = [];
  unsubscriber$ = new Subject<void>();
  userTimezoneCode: string;

  isLoadingCountry: boolean;

  geocoder: google.maps.Geocoder;
  selectedCountryISO: string;

  selectedCountryTimezones: any[] = [];

  isSelectedTimezoneOutsideSelectedCountry: boolean;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    public countriesService: CountriesService,
    public smbCurrenciesService: SmbCurrenciesService,
    public timezonesService: TimezonesService,
    public dialogService: DialogService,
    private instanceService: InstancesService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    public mapsApiLoader: MapsAPILoader
  ) {
    this.translateService.setDefaultLang('en');
    this.mapsApiLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
      this.getCurrentCountry();
    })
  }

  ngOnInit() {
    this.init();
    this.instanceForm = new FormGroup({
      countryCode: new FormControl('', [
        Validators.required,
        valueTypeValidator('object')
      ]),
      currencyCode: new FormControl('', [
        Validators.required,
        valueTypeValidator('object')
      ]),
      timezone: new FormControl('', [
        Validators.required,
        valueTypeValidator('object')
      ]),
      businessWebsite: new FormControl(null, [
        Validators.minLength(2),
        Validators.maxLength(255),
        Validators.pattern(/^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i),
      ]),
    });


    this.getUserTimezone();
    this.instanceForm.get('countryCode').valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
      if (res && typeof res !== 'string') {
        this.timezonesService.setTimezoneCountry(res?.code);
        this.getSelectedCountryTimezones(res?.code);
      }
    })

    this.instanceForm.get('timezone').valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
      if (res && typeof res !== 'string') {
        this.checkSelectedTimezoneAndCountry();
      }
    });


    this.autocompleteValidationErrors.push({
      key: 'required',
      value: this.translateService.get('adminPanel.usersInfo.form.validationError.required')
    });

    this.activatedRoute.data
    .pipe(takeUntil(this.unsubscriber$))
    .subscribe(({user}) => {
      this.user = user;
      if(user?.companyName ){
        this.businesName = user?.companyName;
      }
    });
  }

  init() {
    const instanceId = localStorage.getItem('smbInstance');
    if (!instanceId) return;
    this.openDialog();
  }

  openDialog() {
    this.dialogService.openDialog({
      component: UpdateUserDataDialogComponent,
      heading: '',
      submitButtonText: '',
      cancelButtonText: '',
    }, {
      disableClose: true,
    })
  }

  getTimezoneSeparator(value: TimezoneView) {
    return this.translateService.get(
      'adminPanel.generalInfo.form.field.timezone.offset',
      { offset: value.offset }
    );
  }

  public onSubmit(): void {
    this.instanceService.createSMBInstance(
      { name: this.businesName, ...this.instanceForm.getRawValue() }
    ).subscribe(
      (resp) => {
        SelectedInstanceService.setInstance(resp)
        this.openDialog();
      },
      error => {
        this.dialogService.alert({
          type: 'error',
          message: error.error.message
        });
      });
  }

  logout() {
    this.authService.logout().then(() => window.location.href = '/');
  }

  getUserTimezone(): void {
    this.userTimezoneCode = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const normalisedUserTz = this.userTimezoneCode.split('/')[1].split('_').join(' ');
    this.timezonesService.findUserTimezoneData(1, 50, normalisedUserTz).pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
      const allZones: TimezoneView[] = res?.content;
      const currentTimezone = allZones.find(tz => tz.code === this.userTimezoneCode);

      this.instanceForm.get('timezone').patchValue(currentTimezone);
    })

  }

  getCurrentCountry(){
    this.isLoadingCountry = true;
    navigator.geolocation.getCurrentPosition((position)=>{
      this.getCurrentCountrySuccess(position);
    }, () => {
      this.isLoadingCountry = false;
    })
  }

  getCurrentCountrySuccess(position: any): void {
    let lat = position.coords.latitude;
    let lng = position.coords.longitude;
    this.getCountryName(lat, lng)
  }
  getCountryName(lat: number, lng: number): void {
    let latlng = new google.maps.LatLng(lat, lng);
    this.geocoder.geocode({location: latlng}, (results) => {
      const country =  results[0].address_components.find( value => value.types.includes("country"));
      const countryName = country.short_name.toLocaleLowerCase();
      this.timezonesService.setTimezoneCountry(countryName);
      this.selectedCountryISO = countryName;

      this.preselectCountry(this.selectedCountryISO);
    })
  }

  preselectCountry(country: string): void {
    this.countriesService.getAll(1, 10, [`code,like,%${country}%`])
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res: any) => {
        const userCountry = res?.content[0];
        this.instanceForm.get('countryCode').patchValue({code: userCountry?.code, name: userCountry?.name});
        this.instanceForm.controls['countryCode'].markAsTouched();
        this.instanceForm.controls['countryCode'].markAsDirty();
        this.isLoadingCountry = false;
      }, () => {
        this.isLoadingCountry = false
      })
  }

  getSelectedCountryTimezones(code: string): void {
    this.selectedCountryTimezones = [];
    this.timezonesService.getAll(1, 50)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(res => {
        this.selectedCountryTimezones = res?.content;
      }, () => {}, () => {
        this.checkSelectedTimezoneAndCountry();
      });
  }

  checkSelectedTimezoneAndCountry(): void {
    const timezone = this.instanceForm.get('timezone').value;
    const country = this.instanceForm.get('countryCode').value;
    if (timezone && typeof timezone !== 'string' && country && typeof country !== 'string') {
      this.isSelectedTimezoneOutsideSelectedCountry = !this.selectedCountryTimezones.some(value => value.code === timezone.code);
    }
  }

  ngOnDestroy():void{
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
    this.timezonesService.setTimezoneCountry(null);
  }
}
