import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MapService, PointOfInterestService, SelectedInstanceService, TargetingFiltersService } from '@dooh/common-services';
import { DICTONARY_POI } from '../map/map-poi.dictionary';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertDialogData, CustomContentDialogData, InstanceView } from '@dooh/models';
import { Subject } from 'rxjs-compat/Subject';
import { map, take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { LatLng, LatLngBounds, MapsAPILoader } from '@agm/core';
import { DialogService } from '@dooh/components-library';
import { PoiImportDialogComponent } from '../dialogs/poi-import-dialog/poi-import-dialog.component';
import { ZipCodeImportDialogComponent } from '../dialogs/zip-code-import-dialog/zip-code-import-dialog.component';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { GoogleMap } from '@agm/core/services/google-maps-types';
import * as mapboxgl from 'mapbox-gl';

const KEYWORD_SEARCH_DELAY = 300;
const MIN_LETTERS_TO_SEARCH = 3;
const DEFAULT_POI_RADIUS = 5;
const DEFAULT_POI_RADIUS_US = 5;
// const DEFAULT_POI_RADIUS_US_DSP = 3;
const COUNTRY_US = 'US';
const MILES_TO_METERS = 1609;
const KILOMETERS_TO_METERS = 1000;
const ALL_RESULTS_GROUP = 'all_results';
const RADIANS = 180 / 3.14159265;
const EARTH_RAD = 3958.8


declare var google: any;

@Component({
  selector: 'dooh-point-of-interest',
  templateUrl: './point-of-interest.component.html',
  styleUrls: ['./point-of-interest.component.scss']
})
export class PointOfInterestComponent implements OnInit, OnDestroy {

  @Output()
  cancel: EventEmitter<void> = new EventEmitter<void>();

  list = [];
  filteredList = [];
  types: string[] = [];
  poiErrorMessage: string;
  dictornary = DICTONARY_POI;
  selectedPOIs = [];
  activeStyle = [];
  auxPois: any;
  mapCurrentBound: mapboxgl.LngLatBounds

  isPoiLoading: boolean;
  customPoiFile: any = null;
  customZipCodesFile: any = null;

  searchValue: string;
  textSearchValue: string;
  selectedCategories: string[] = [];
  keywordSearchTimeout = null;
  textSearchTimeout = null;
  searchRadius: number;
  isUSCitizen = false;
  isDsp = false;
  unsubscriber$ = new Subject<any>();
  fileSubscription: Subscription;
  uploadStatusSubscription: Subscription;
  isCustomPois = false;
  isCustomZipCode = false;
  allCustomPois: any[] = [];
  allCustomZipCodes: any[] = [];
  allowShowButton = false;
  radiusChangeTimeout: NodeJS.Timeout;
  mapBoundSubscription: Subscription;
  poisSearchCountry: string;
  placeForm: FormGroup;
  isCountryOnly: boolean;
  isResetting: boolean;
  allPlacePoi: any[] = [];
  zipCodesCount:number = 0;

  constructor(
    private mapService: MapService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private targetingFiltersService: TargetingFiltersService,
    private router: Router,
    private poisService: PointOfInterestService,
    private dialogService: DialogService,
    private ngxCsvParser: NgxCsvParser,
    private fb: FormBuilder
  ) {
    this.isDsp = this.router.url.split('/').some(x => x.toLocaleLowerCase() === 'dsp');
  }

  ngOnInit(): void {
    this.loadCategories();
    this.initPlaceForm();

    const instance = SelectedInstanceService.getInstance();
    this.searchRadius = DEFAULT_POI_RADIUS;
    if (instance.country.code === COUNTRY_US) {
      this.isUSCitizen = true;
      this.searchRadius = DEFAULT_POI_RADIUS_US;
    }
    this.targetingFiltersService.isPoiOpen$.pipe(takeUntil(this.unsubscriber$)).subscribe(isOpen => {
      this.getBounds();
    })
    this.mapService.isPoiLoading$.pipe(takeUntil(this.unsubscriber$)).subscribe((loading) => {
      this.isPoiLoading = loading;
    });

    this.mapService.getPoisData().pipe(takeUntil(this.unsubscriber$)).subscribe(data => {

      if (!this.isEmptyData(data)) {
        this.auxPois = data;
      }
    });
    this.targetingFiltersService.isPoiActive$.pipe(takeUntil(this.unsubscriber$)).subscribe(isActive => {
      const poiStatus = this.getFiltersStatus();
      if (!isActive && poiStatus) {
        this.onReset();
      }
    });

    this.poisService.getFileUploadStatus().pipe(takeUntil(this.unsubscriber$)).subscribe(status => {
      if (status) {
        this.parseImportFile();
      }
    });

    this.poisService.getZipCodeFileUploadStatus().pipe(takeUntil(this.unsubscriber$)).subscribe(status => {
      if (status) {
        this.parseImportZipCodeFile();
      }
    });

    this.poisService.component$.pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
      if (res) {
        this.poisSearchCountry = res
      }
    });

    this.mapService.currentSearchField$.pipe(takeUntil(this.unsubscriber$)).subscribe((res: any) => {
      this.isCountryOnly = false;
      if (res?.data) {
      if  (this.isCustomPois || this.allPlacePoi.length > 0 || this.checkIfListCalled()) {
        this.onReset();
      }
      if (res?.length === 1) {
        const searchItem = res[0];
        if (searchItem?.types?.some(x => x === 'country')) {
          this.isCountryOnly = true;
        }
      }
      this._createBoundForSmb(res);
      }
    });
  }

  isEmptyData(data) {
    return !data || Object.keys(data).length === 0;
  }

  getText(key: string) {
    return DICTONARY_POI[key];
  }

  getIcon(key: string) {
    return '/assets/icons/map/' + key + '-white.svg';
  }

  selectPOI(poi: any, list: any) {
    this.activeStyle[poi.id] = !this.activeStyle[poi.id];

    if (this.activeStyle[poi.id]) {
      Object.keys(this.list).forEach(itemKey => {
        const element = this.list[itemKey].list.find(item => item.id === poi.id);
        if (element) {
          this.selectedPOIs[itemKey].push(element);
        }
      });
    } else {
      Object.keys(this.selectedPOIs).forEach(key => {
        this.selectedPOIs[key] = this.selectedPOIs[key].filter(item => item.id !== poi.id);
      });
    }

    this.checkShowButton();
  }

  selectGroup(group, $event) {
    const changesMap = this.list[group].list.reduce((storage, poi) => {
      if (this.activeStyle[poi.id] !== $event.checked) {
        storage[poi.id] = true;
      }

      this.activeStyle[poi.id] = $event.checked;

      return storage;
    }, {});

    if ($event.checked) {
      Object.keys(this.list).forEach(key => {
        this.list[key].list.forEach(poi => {
          if (changesMap[poi.id]) {
            this.selectedPOIs[key].push(poi);
          }
        });
      });
    } else {
      Object.keys(this.selectedPOIs).forEach(key => {
        this.selectedPOIs[key] = this.selectedPOIs[key].filter(poi => !changesMap[poi.id]);
      });
    }



    this.checkShowButton();
  }


  selectPoiGroup(group: string, $event: any): void {
    const index = this.selectedCategories.findIndex(category => category === group);
    if ($event.checked) {
      if (index === -1) {
        this.selectedCategories.push(group);
      }
    } else {
      this.selectedCategories.splice(index, 1);
    }
    if (!this.list[group].called && $event.checked) {
      if (this.textSearchValue && this.textSearchValue?.length >= MIN_LETTERS_TO_SEARCH ) {
        this.googleSearchPoisByText([group], $event);
      } else {
        this.googleSearchPoisNearBy([group], false, null, $event);
      }
    } else if (this.list[group].called && $event.checked) {
      if (this.textSearchValue && this.textSearchValue?.length >= MIN_LETTERS_TO_SEARCH ) {
        this.googleSearchPoisByText([group], $event);
      } else {
        this.googleSearchPoisNearBy([group], false, null, $event);
      }

    }
      else {
      this.selectGroup(group, $event)
    }
  }

  loadIcons(key: string) {
    this.iconRegistry.addSvgIcon(
      key,
      this.sanitizer.bypassSecurityTrustResourceUrl('..' + this.getIcon(key)));

  }

  collectAllResults() {
    const { group, groupName, icon } = DICTONARY_POI[ALL_RESULTS_GROUP];

    this.list[group] = { icon, text: groupName, list: [] };
    this.selectedPOIs[group] = [];

    Object.keys(this.list).reduce((map, key) => {
      this.list[key].list.forEach(poi => {
        if (map[poi.id]) {
          return;
        }

        this.list[group].list.push(poi);
        if (this.activeStyle[poi.id]) {
          this.selectedPOIs[group].push(poi);
        }

        map[poi.id] = true;
      });

      return map;
    }, {});
  }


  sortListByGroups(list: any[]) {
    this.list = [];

    Object.keys(list).forEach(key => {
      const { group, groupName, icon } = DICTONARY_POI[key];

      if (!this.list[group]) {
        this.loadIcons(icon);
      }

      this.selectedPOIs[group] = this.selectedPOIs[group] || [];
      this.list[group] = this.list[group] || { icon, text: groupName, list: [...this.selectedPOIs[group]] };

      for (const item of list[key]) {
        const { lat, lng } = item.location;

        const isDuplicate = this.list[group].list?.some(
          element => element.location.lat === lat && element.location.lng === lng
        );

        if (!isDuplicate) {
          this.list[group].list.push(item);
        }
      }
    });

  }

  onShow() {
    const onAfterSend = () => this.targetingFiltersService.togglePoi(false);
    this.sendPOIs(onAfterSend);
  }


  sendPOIs(onAfterSend?: () => void) {
    const searchRadiusInMeter = this.isUSCitizen ? this.searchRadius * MILES_TO_METERS : this.searchRadius * KILOMETERS_TO_METERS;
    if (this.searchRadius > 0) {
      const pois = [];
      const poisMap = [];
      const zipCodes = [];
      let selectedPOIs = this.selectedPOIs;

      if (this.isCustomPois) {
        selectedPOIs['custom'] = [];
        this.allCustomPois.forEach(poi => {
          selectedPOIs['custom'].push(poi);
        });
      } else {
        selectedPOIs['custom'] = []
      }

      if (this.allPlacePoi.length > 0) {
        selectedPOIs['places'] = [];
        this.allPlacePoi.forEach(poi => {
          selectedPOIs['places'].push(poi);
        });
      } else {
        selectedPOIs['places'] = []
      }
      Object.keys(selectedPOIs).map(key => {
        if (key === DICTONARY_POI[ALL_RESULTS_GROUP].group) {
          return;
        }

        selectedPOIs[key].map(element => {
          const addElement = poisMap.find(poi => element?.id === poi.id);
          if (!addElement) {
            pois.push([element.location.lat, element.location.lng]);
            Object.keys(DICTONARY_POI).map(type => {
              if (type === key) {
                element['iconSvg'] = '/assets/icons/map/' + DICTONARY_POI[type].icon + '.svg';
                return;
              }
              element['radius'] = searchRadiusInMeter;
            });
            poisMap.push(element);
          }
        });
      });

      this.allCustomZipCodes.forEach(code => {
        zipCodes.push(code);
      });
      const poiFilter = {
        type: 'poi',
        pois: [...pois],
        zipCodes: [...zipCodes],
        radius: searchRadiusInMeter
      }
      this.targetingFiltersService.setPoiFilters(poiFilter);
      this.mapService.setPoisMapData(poisMap);

      const poiStatus = this.getFiltersStatus();
      this.targetingFiltersService.setPoiStatus(poiStatus);
      if (onAfterSend) {
        onAfterSend();
      }
    } else {
      this.poiErrorMessage = 'You must set the radius of some value different to 0';
    }
  }

  searchByKeyword() {
    if (this.keywordSearchTimeout) {
      clearTimeout(this.keywordSearchTimeout);
    }

    this.keywordSearchTimeout = setTimeout(() => {
      this.filteredList = [];

      Object.keys(this.list).forEach(item => {
        if (item.includes(this.searchValue)) {

          this.filteredList[item] = this.list[item];
        }
      })
    }, KEYWORD_SEARCH_DELAY);
  }

  onKeywordChange() {
    if (
      this.searchValue?.length !== 0
    ) {
      this.searchByKeyword();
    } else {
      this.filteredList = [];
      Object.keys(this.list).forEach(item => {
        this.filteredList[item] = this.list[item]
      })
    }
  }

  onTextKeywordChange() {

    if (this.textSearchTimeout) {
      clearTimeout(this.textSearchTimeout);
    }

    this.textSearchTimeout = setTimeout(() => {
      const searchRadius = this.isUSCitizen ? this.searchRadius * MILES_TO_METERS : this.searchRadius * KILOMETERS_TO_METERS;
      const event = {
        'checked': true
      }
      if (
        this.textSearchValue && this.textSearchValue?.length !== 0
      ) {
        if (this.textSearchValue?.length >= MIN_LETTERS_TO_SEARCH) {
          this.googleSearchPoisByText(this.selectedCategories, event);
        }

      } else {
        this.googleSearchPoisNearBy(this.selectedCategories, false, null, event);
      }

    }, KEYWORD_SEARCH_DELAY)
  }

  onRadiusChange(): void {
    if (this.searchRadius > 0) {
      this.poiErrorMessage = null;
    }
    if (this.searchRadius <= 0 || this.searchRadius === null) {
      this.poiErrorMessage = 'You must set the radius of some value greater than 0';
      this.allowShowButton = false;
      return;
    }
    if (this.isUSCitizen && this.searchRadius > 18) {
      this.poiErrorMessage = 'Search radius cannot be greater than 18 miles.'
      this.allowShowButton = false;
      return ;
    } else if (!this.isUSCitizen && this.searchRadius > 30) {
      this.poiErrorMessage = 'Search radius cannot be greater than 30 km.'
      this.allowShowButton = false;
      return;
    }
    this.checkShowButton();

      if (this.radiusChangeTimeout) {
        clearTimeout(this.radiusChangeTimeout);
      }
      this.radiusChangeTimeout = setTimeout(() => {
        const searchRadius = this.isUSCitizen ? this.searchRadius * MILES_TO_METERS : this.searchRadius * KILOMETERS_TO_METERS;
        const event = {
          'checked': true
        }

        if (
          this.textSearchValue && this.textSearchValue?.length !== 0 &&
          this.textSearchValue?.length >= MIN_LETTERS_TO_SEARCH
        ) {
          this.googleSearchPoisByText(this.selectedCategories, event, false, null, true);
        } else {
          this.googleSearchPoisNearBy(this.selectedCategories, false, null, event, true);
        }
      }, KEYWORD_SEARCH_DELAY);
  }

  onReset() {
    this.isResetting = true;
    setTimeout(() => {
    this.isResetting = false;
    }, 2000);
    Object.keys(this.selectedPOIs).map(itemKey => {
      this.selectedPOIs[itemKey] = [];
    });
    this.activeStyle = [];
    this.searchRadius = this.isUSCitizen ? DEFAULT_POI_RADIUS_US : DEFAULT_POI_RADIUS;

    this.isCustomPois = false;
    this.isCustomZipCode = false
    this.customPoiFile = null;
    this.customZipCodesFile = null
    this.selectedCategories = [];
    this.allCustomPois = [];
    this.allCustomZipCodes = [];
    this.allowShowButton = false;
    this.poiErrorMessage = null;
    if (this.fileSubscription) {
      this.fileSubscription.unsubscribe();
    }
    this.searchValue = '';
    this.textSearchValue = '';
    this.placeForm.get('placesSearched').patchValue([]);
    this.allPlacePoi = [];
    this.loadCategories();
    this.mapService.setPoisMapData([]);
    this.targetingFiltersService.setPoiFilters(null);
    const poiStatus = this.getFiltersStatus();
    this.targetingFiltersService.setPoiStatus(poiStatus);
    this.onShow()
  }

  getBounds(): void {
    if (this.mapBoundSubscription) {
      this.mapBoundSubscription.unsubscribe();
    }
    this.mapBoundSubscription = this.mapService.getMapBoundForPoi().subscribe(bound => {
      this.mapCurrentBound = bound;
    });
  }

  loadCategories() {
    this.list = [];
    this.filteredList = [];
    const catgories = DICTONARY_POI;

    Object.keys(catgories).forEach(category => {
      if (category === ALL_RESULTS_GROUP) return;
      if (!this.list[category]) {
        this.loadIcons(DICTONARY_POI[category].icon);
      }
      this.list[category] = {
        name: category,
        icon: DICTONARY_POI[category].icon,
        text: this.normaliseName(category),
        list: [],
        selected: [],
        called: false,
        isLoading: false,
        resultRadius: 0
      };
      this.selectedPOIs[category] = this.list[category].selected;
    });

    Object.keys(this.list).forEach(item => {
      this.filteredList[item] = this.list[item]
    });
  }

  googleSearchPoisNearBy(type: string[], nextPage?: boolean, token?: string, $event?: any, radiusChange?: boolean) {
    if (this.allPlacePoi.length > 0) {
      this.mapCurrentBound = this._createBound(this.allPlacePoi);
    } else {
      this.getBounds();
    }

    const location = {
      lat: +this.mapCurrentBound.getCenter().lat.toFixed(4),
      lng: +this.mapCurrentBound.getCenter().lng.toFixed(4)
    };

    let range = this.calculateBoundRadius(this.mapCurrentBound);



    type.forEach(category => {
      if (radiusChange || !nextPage) {
        this._clearActiveStyle(this.list[category].list);
        this.list[category].list = [];

        Object.keys(this.selectedPOIs).forEach(key => {
          if (key === category) {
            this.selectedPOIs[key] = [];
          }
        })
      }
      if (this.list[category].called && !nextPage && this.list[category].list.length > 0 && !radiusChange) {
        return;
      }
      this.list[category].isLoading = true;


      this.filteredList[category] = this.list[category];

    });

    this._multiNearBy(location, range, type, token).pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
      Object.keys(res).forEach(item => {
       const pois = res[item];
        if (pois?.results.length > 0) {
          pois?.results.forEach(result => {
          result.id = result?.reference;
          result.address = result?.vicinity ?? result?.formatted_address;
          result.location = result?.geometry?.location;
        });
        this.list[item].list = nextPage ? [...this.list[item].list, ...pois?.results] : pois?.results;

        this.filteredList[item].list = nextPage ? [...this.list[item].list, ...pois?.results] : pois?.results;

      }
      this.list[item].called = true;

      this.list[item].resultRadius = range;

      this.list[item].isLoading = false;


      this.filteredList[item] = this.list[item];


      if ($event) {
        this.selectGroup(item, $event);
      }

      });
    });

  }

  googleSearchPoisByText(type: string[], $event?: any, nextPage?: boolean, token?: string, radiusChange?: boolean) {
    if (this.allPlacePoi.length > 0) {
      this.mapCurrentBound = this._createBound(this.allPlacePoi);
    } else {
      this.getBounds();
    }
    const location = {
      lat: +this.mapCurrentBound.getCenter().lat.toFixed(4),
      lng: +this.mapCurrentBound.getCenter().lng.toFixed(4)
    };


    let range = this.calculateBoundRadius(this.mapCurrentBound);


    type.forEach(category => {
      if (radiusChange || !nextPage) {
        this._clearActiveStyle(this.list[category].list);
        this.list[category].list = [];

        Object.keys(this.selectedPOIs).forEach(key => {
          if (key === category) {
            this.selectedPOIs[key] = [];
          }
        })
      }
      if (this.list[category].called && !nextPage && this.list[category].list.length > 0 && !radiusChange) {
        return;
      }
      this.list[category].isLoading = true;


    this.filteredList[category] = this.list[category];
    });




    this._multiTextSearch(location, range, type, this.textSearchValue, token).pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
      Object.keys(res).forEach(item => {
       const pois = res[item];
        if (pois?.results.length > 0) {
          pois?.results.forEach(result => {
          result.id = result?.reference;
          result.address = result?.vicinity ?? result?.formatted_address;
          result.location = result?.geometry?.location;
        });
        this.list[item].list = nextPage ? [...this.list[item].list, ...pois?.results] : pois?.results;

      }
      this.list[item].called = true;

      this.list[item].resultRadius = range;

      this.list[item].isLoading = false;

      this.filteredList[item] = this.list[item];


      if ($event) {
        this.selectGroup(item, $event);
      }

      });
    });
  }

  calculateBoundRadius(bound: mapboxgl.LngLatBounds): number {
    let radius = 0
    const center = bound.getCenter();
    const corner = bound.getSouthWest()

    const lt1 = center.lat / RADIANS;
    const lg1 = center.lng / RADIANS;
    const lt2 = corner.lat / RADIANS;
    const lg2 = corner.lng / RADIANS;

    radius = (3958.8 * MILES_TO_METERS) * Math.acos(Math.sin(lt1) * Math.sin(lt2) + Math.cos(lt1) * Math.cos(lt2) * Math.cos(lg2 - lg1));
    return radius;
  }

  loadMorePois(type: string): void {
    const pageToken = this.list[type].nextPageToken;
    const searchRadius = this.isUSCitizen ? this.searchRadius * MILES_TO_METERS : this.searchRadius * KILOMETERS_TO_METERS;
    const event = {
      'checked': true
    }

    if (
      this.textSearchValue?.length !== 0 &&
      this.textSearchValue?.length >= MIN_LETTERS_TO_SEARCH
    ) {
      this.googleSearchPoisByText([type], event, true, pageToken);
    } else {
      this.googleSearchPoisNearBy([type], true, pageToken, event);
    }
  }

  onCancel() {
    this.cancel.emit();
  }

  getFiltersStatus() {
    return Object.values(this.activeStyle).some(value => value);
  }

  normaliseName(name: string): string {
    const nameArr = name.split('_');
    nameArr[0] = nameArr[0].charAt(0).toUpperCase() + nameArr[0].slice(1);
    return nameArr.join(' ');
  }

  openImportInstructionDialog() {
    this.poisService.setFileUploadStatus(false);
    if (this.fileSubscription) {
      this.fileSubscription.unsubscribe();
      this.poisService.setCustomPoiFile(null);
    }
    this.getCustomFile();
    const dialogData: AlertDialogData = {
      type: 'default',
      heading: 'screenProperties.poi.dialog.heading',
      submitButtonText: 'screenProperties.poi.dialog.submitButton',
      message: 'screenProperties.poi.dialog.message',
      isHtml: true
    }
    this.dialogService.openCustomComponentModal(dialogData, PoiImportDialogComponent);
  }

  openImportZipCodeInstructionDialog(){

    this.poisService.setZipCodeFileUploadStatus(false);
    if (this.fileSubscription) {
      this.fileSubscription.unsubscribe();
      this.poisService.setCustomZipCodeFile(null);
    }
    this.getZipCodeCustomFile();
    const dialogData: AlertDialogData = {
      type: 'default',
      heading: 'screenProperties.zipCode.dialog.heading',
      submitButtonText: 'screenProperties.zipCode.dialog.submitButton',
      message: 'screenProperties.zipCode.dialog.message',
      isHtml: true,
    };
    this.dialogService.openCustomComponentModal(
      dialogData,
      ZipCodeImportDialogComponent
    );

  }

  getCustomFile() {
    this.fileSubscription = this.poisService.getCustomPoiFile().subscribe(customFile => {
      this.customPoiFile = customFile;
    });
  }

  parseImportFile() {
    if (this.customPoiFile) {
      let allPois = [];
      this.ngxCsvParser.parse(this.customPoiFile[0], {header: true, delimiter: ','}).pipe().subscribe((pois: any[]) => {
        if (pois.length > 0) {
          if (this.nullCheck(pois)) {
            this.alertFileDataError();
          } else if (pois.length > 4000) {
            this.alertLimitExceeded();
          } else {
            allPois = pois;
            this.isCustomPois = true;
          }

        } else {
          this.alertEmptyFile();
          this.isCustomPois = false;
        }
      }, (error: NgxCSVParserError) => {
        this.alertUploadError();
      }, () => {
        this.remapCustomPoisKeys(allPois);
        this.checkShowButton();
      });
    }
  }

  getZipCodeCustomFile() {
    this.fileSubscription = this.poisService
      .getCustomZipCodeFile()
      .subscribe((customFile) => {
        this.customZipCodesFile = customFile;
      });
  }

  parseImportZipCodeFile() {
    if (this.customZipCodesFile) {
      let allZipCodes = [];
      this.ngxCsvParser
        .parse(this.customZipCodesFile[0], { header: true, delimiter: ',' })
        .pipe()
        .subscribe(
          (zipCodes: any[]) => {
            if (zipCodes.length > 0) {
              if (this.nullZipCodeCheck(zipCodes)) {
                this.alertFileDataError();
              } else {
                allZipCodes = zipCodes;
                this.isCustomZipCode = true;
              }
            } else {
              this.alertEmptyFile();
              this.isCustomZipCode = false;
            }
          },
          (error: NgxCSVParserError) => {
            this.alertUploadError();
          },
          () => {
            this.remapCustomZipCodesKeys(allZipCodes);
            this.checkShowButton();
          }
        );
    }
  }

  alertUploadError(): void {
    const dialogData: AlertDialogData = {
      type: 'error',
      submitButtonText: 'screenProperties.poi.dialog.error.button',
      message: 'screenProperties.poi.dialog.error.message',
    }
    this.dialogService.alert(dialogData)
  }

  alertEmptyFile(): void {
    const dialogData: AlertDialogData = {
      type: 'error',
      submitButtonText: 'screenProperties.poi.dialog.emptyFile.button',
      message: 'screenProperties.poi.dialog.emptyFile.message',
    }
    this.dialogService.alert(dialogData)
  }

  alertFileDataError(): void {
    const dialogData: AlertDialogData = {
      type: 'error',
      submitButtonText: 'screenProperties.poi.dialog.fileError.button',
      message: 'screenProperties.poi.dialog.fileError.message',
    }
    this.dialogService.alert(dialogData)
  }

  alertLimitExceeded(): void {
    const dialogData: AlertDialogData = {
      type: 'error',
      submitButtonText: 'screenProperties.poi.dialog.limitExceeded.button',
      message: 'screenProperties.poi.dialog.limitExceeded.message',
    }
    this.dialogService.alert(dialogData)
  }

  removeCustomPoiFile() {
    this.customPoiFile = null;
    this.isCustomPois = false;
    this.allCustomPois = [];
    this.poisService.setFileUploadStatus(false);
    this.checkShowButton();
  }



  removeCustomZipCodeFile() {
    this.customZipCodesFile = null;
    this.isCustomZipCode = false;
    this.allCustomZipCodes = [];
    this.poisService.setZipCodeFileUploadStatus(false);
    this.checkShowButton();
  }

  checkShowButton(): void {
    this.allowShowButton = false;
    Object.keys(this.selectedPOIs).forEach(type => {
      if (this.selectedPOIs[type].length > 0 && this.searchRadius > 0) {
        this.allowShowButton = true;
      }
    });

    if (this.isCustomPois && this.searchRadius > 0) {
      this.allowShowButton = true;
    }

    if (this.allPlacePoi.length > 0) {
      this.allowShowButton = true
    }
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  private initPlaceForm(): void {
    this.placeForm = this.fb.group({
      placesSearched: new FormControl([])
    });
  }

  getScreensApi(): any {
    return {
      getAll: (page: number, pageSize: number, filters: any, args: any, keyword: string) =>
        this.poisService.getScreenTags(keyword, this.poisSearchCountry),
      reverseGeocodeFromGoogle: (placeId: string) => this.poisService.getGooglePlaceInfo(placeId).pipe(map(res => res.results))
    };
  }

  getPoiFromPlaces(): void {
    const allSelectedPlaces = this.placeForm.get('placesSearched').value;
    if (Array.isArray(allSelectedPlaces)) {
      this.allPlacePoi = []
      if (allSelectedPlaces?.length > 0) {
        allSelectedPlaces?.forEach(item => {
          item.location = item?.geoCode?.geometry?.location;
          this.allPlacePoi.push(item);
        });
      }

      this.checkShowButton();
    }
  }

  checkIfListCalled(): boolean {
    let isCalled = false;
    Object.keys(this.list).forEach(item => {
      if (this.list[item].isCalled) {
        isCalled = true;
        return;
      }
    })

    return isCalled;
  }

  protected remapCustomPoisKeys(allPois: any[]): void {
    this.allCustomPois = [];
    let id = 0
    allPois.forEach(poi => {
      let data = {}
      data['location'] = {
        lat: +poi['latitude'],
        lng: +poi['longitude']
      }
      data['id'] = id++;
      this.allCustomPois.push(data);
    });
  }

  protected remapCustomZipCodesKeys(allZipCodes: any[]): void {
    this.zipCodesCount = 0;
    this.allCustomZipCodes= [];
    allZipCodes.forEach((code) => {
      if(code?.country && code?.postalCode){
        if(this.allCustomZipCodes?.filter((item)=>item.country == code?.country).length > 0){
          if(this.allCustomZipCodes[this.allCustomZipCodes.findIndex((x)=>x.country == code?.country)]?.codes.filter((item)=> item === code?.postalCode).length == 0){
            this.allCustomZipCodes[this.allCustomZipCodes.findIndex((x)=>x.country == code?.country)]?.codes.push(code?.postalCode);
          }
        }else{
            let data = {};
            data = {
              "country": code['country'],
              "codes": [code['postalCode']]
            };
            this.allCustomZipCodes.push(data);
        }
      }
    });
    this.allCustomZipCodes.forEach((country)=>{
      country.codes.forEach((code)=>{
          this.zipCodesCount++
      })
    })
  }

  protected _clearActiveStyle(list: any[]): void {
    list.forEach(poi => {
      if (this.activeStyle[poi.id]) {
        this.activeStyle[poi.id] = false;
      }
    });
  }

  protected _createBound(places: any[]): mapboxgl.LngLatBounds {
    const bounds: mapboxgl.LngLatBounds = new mapboxgl.LngLatBounds();
    places?.forEach(place => {
      bounds.extend(new mapboxgl.LngLat(place?.location?.lng, place?.location?.lat));
    });
    return bounds;
  }

  protected _multiNearBy(location: any, range: number, types: string[], token: any): Observable<any> {
    const allTypeObservables: Observable<any>[] = [];
    types.forEach(type => {
      const typeNearby$ = this.poisService.nearbySearch(location, range, [type], token);
      allTypeObservables.push(typeNearby$);
    });
    return forkJoin(allTypeObservables).pipe(
      map((response: any[]) => {
        let orderedResults = [];
        response.forEach((res, index) => {
          orderedResults[types[index]] = res;
        });
      return orderedResults;
      }))
  }


  protected _multiTextSearch(location: any, range: number, types: string[], query: string, token: any): Observable<any> {
    const allTypeObservables: Observable<any>[] = [];
    types.forEach(type => {
      const typeNearby$ = this.poisService.textSearch(location, range, [type], query, token);
      allTypeObservables.push(typeNearby$);
    });
    return forkJoin(allTypeObservables).pipe(
      map((response: any[]) => {
        let orderedResults = [];
        response.forEach((res, index) => {
          orderedResults[types[index]] = res;
        });
      return orderedResults;
      }))
  }

  protected _createBoundForSmb(places: any[]): void {
    const allPlaces: any[] = [];
    places.forEach(place => {
      if (place?.geoCode?.geometry?.location || place?.location) {
        allPlaces.push({location: place?.geoCode?.geometry?.location ?? place?.location});
      }
    });
    this.mapService.setMapBoundForPoi(this._createBound(allPlaces));
  }

  protected nullCheck(customPoisArr: any[]): boolean {
    let skipToEnd: boolean = false;
    let containsEmptyItem: boolean = false;

    customPoisArr.forEach(item => {
      if (!item?.latitude || !item?.longitude) {
        containsEmptyItem = true;
        return;
      }

      if (skipToEnd) return;
      containsEmptyItem = Object.values(item)?.some(value => {
        if (value === null || value === undefined || value === '' || value === 'null') {
          return true;
        } else if (isNaN(+value)) {
          return true;
        } else if (+value < -180 || +value > 180) {
          return true;
        }
        return false;
      });

      if(containsEmptyItem) {
        skipToEnd = true
      }
    });

    return containsEmptyItem;
  }

  protected nullZipCodeCheck(customZipCodesArr: any[]): boolean {
    let atLeastOnePostalCode: number = 0;

    customZipCodesArr.forEach((item) => {
      if(item?.postalCode && item.country){
        atLeastOnePostalCode++
      }
    });
    return atLeastOnePostalCode <= 0;
  }

}
