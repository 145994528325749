import { Injectable } from '@angular/core';
import { ApiService, CustomHttpUrlEncodingCodec } from '@dooh/api';
import { AutocompleteService } from '@dooh/components-library';
import { Observable } from 'rxjs';
import { PageDto } from '@dooh/models';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IabCategoryService implements AutocompleteService {
  sort = [ 'name,asc' ];

  constructor(
    private apiService: ApiService
  ) { }

  getAll(page: number = 1, pageSize: number = 20, filters?: string[], api?: string): Observable<PageDto> {
    const filtersArr: string[] = filters && filters.length ? filters : [];

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    if (this.sort && this.sort.length) {
      queryParameters = queryParameters.set('sort', <any>this.sort.join(';'));
    }
    if (filters && filters.length) {
      queryParameters = queryParameters.set('filters', filtersArr.join(';'));
    }

    return this.apiService.get(`${api}/iab_categories`, queryParameters);
  }
}
