<div
  class="image-cover"
  matTooltip="This image might not represent the actual screen, It’s a representation of the publisher’s screens"
  *ngIf="images?.length === 1"
>
  <img [src]="images[0]" alt="Screen image" />
</div>
<owl-carousel-o [options]="customOptions" *ngIf="images?.length > 1">
  <ng-container *ngFor="let image of images; let i = index">
    <ng-template carouselSlide [id]="'image-' + i">
      <div
        class="image-cover"
        matTooltip="This image might not represent the actual screen, It’s a representation of the publisher’s screens"
      >
        <img [src]="image" alt="Screen image" />
      </div>
    </ng-template>
  </ng-container>
</owl-carousel-o>
