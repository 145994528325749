import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export default function customRegexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
        if (!control.value) {
            return null;
        }
        const valid = regex.test(control.value);
        return valid ? null : error;
    }
}
