import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { MaterialModule, AddDynamicComponentService, TargetingFiltersService } from "@dooh/common-services";
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { MapComponent } from './map/map.component';
import { NavigationManagementComponent } from './navigation-management/navigation-management.component';
import { RouterModule } from '@angular/router';
import { InternalizationModule } from '@dooh/internalization';
import { GenericTableComponent } from './generic-table/generic-table.component';
import { HourRangeFieldComponent } from './hour-range-field/hour-range-field.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HourWeeklyTableComponent } from './hour-weekly-table/hour-weekly-table.component';
import { DayHourRangeComponent } from './day-hour-range/day-hour-range.component';
import { ExcludeRangeDatesComponent } from './exclude-range-dates/exclude-range-dates.component';
import { RangeDatepickerComponent } from './range-datepicker/range-datepicker.component';
import { HourRangeEditTableComponent } from './hour-range-edit-table/hour-range-edit-table.component';
import { InViewportModule } from 'ng-in-viewport';
import { CustomContentDialogComponent } from './dialogs/customContentDialog/custom-content-dialog.component';
import { AlertDialogComponent } from './dialogs/alertDialog/alert-dialog.component';
import { DialogNewDealIdComponent } from './dialogs/dialog-new-deal-id/dialog-new-deal-id.component';
import { DialogService } from './dialogs/dialog.service';
import { AutocompleteFieldComponent } from './autocomplete-field/autocomplete-field.component';
import { ScreenPropertiesComponent } from './screen-properties/screen-properties.component';
import { Ng5SliderModule } from 'ng5-slider';
import { PointOfInterestComponent } from './point-of-interest/point-of-interest.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { BreadcrumbComponent } from './breadcrumbs/breadcrumb/breadcrumb.component';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { CommonPipesModule } from '@dooh/common-pipes';
import { MoneyFieldComponent } from './money-field/money-field.component';
import { AutocompleteMoneyFieldComponent } from './autocomplete-money-field/autocomplete-money-field.component';
import { SearchFieldComponent } from './search-field/search-field.component';
import { TabInfoComponent } from './tab-info/tab-info.component';
import { MandatoryOptionsComponent } from './mandatory-options/mandatory-options.component';
import { MandatoryOptionComponent } from './mandatory-option/mandatory-option.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { BudgetPickerComponent } from './budget-picker/budget-picker.component';
import { MatRadioModule } from '@angular/material/radio';
import { ScreensViewComponent } from './screens-view/screens-view.component';
import { AgmMarkerClustererModule } from "@agm/markerclusterer";
import { ScreensMapComponent } from './screens-view/components/screens-map/screens-map.component';
import { ScreensViewTabComponent } from './screens-view/components/screens-view-tab/screens-view-tab.component';
import { ScreensTabComponent } from './screens-tab/screens-tab.component';
import { BarChartModule, ChartCommonModule, LineChartModule } from "@swimlane/ngx-charts";
import { ComboChartComponent, ComboSeriesVerticalComponent, CustomCircleSeriesComponent } from './combo-chart';
import { AudienceReachComponent } from './screens-tab/audience-reach/audience-reach.component';
import { ExpectedReachFieldComponent } from './expected-reach-field/expected-reach-field.component';
import { ToastComponent } from './toast/toast.component';
import { NgxFilesizeModule } from 'ngx-filesize';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { PoiImportDialogComponent } from './dialogs/poi-import-dialog/poi-import-dialog.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { CustomLoaderComponent } from './custom-loader/custom-loader.component';
import { AutocompleteWithTreeviewComponent } from './autocomplete-with-treeview/autocomplete-with-treeview.component';
import { PhoneNumberInputComponent } from './phone-number-input/phone-number-input.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SupportedFormatsComponent } from './supported-formats/supported-formats.component';
import { TermOfServiceDialogComponent } from './dialogs/term-of-service-dialog/term-of-service-dialog.component';
import { NamespaceInventoryComponent } from './namespace-inventory/namespace-inventory.component';
import { NewSupportedFormatsComponent } from './new-supported-formats/new-supported-formats.component';
import { AudienceLibraryModule } from 'libs/audience-library/src/lib/audience-library.module';
import { DmaDialogComponent } from './dialogs/dma-dialog/dma-dialog.component';
import { InputDialogComponent } from './input-dialog/input-dialog.component';
import { MapDialogComponent } from './dialogs/map-dialog/map-dialog.component';
import { ScreenSearchDialogComponent } from './dialogs/screen-search-dialog/screen-search-dialog.component';
import { AudienceComponent } from './dialogs/screen-search-dialog/filter-components/audience/audience.component';
import { PointOfInterestDialogComponent } from './dialogs/screen-search-dialog/filter-components/point-of-interest/point-of-interest.component';
import { ScreenPropertiesDialogComponent } from './dialogs/screen-search-dialog/filter-components/screen-properties/screen-properties.component';
import { DmaComponent } from './dialogs/screen-search-dialog/filter-components/dma/dma.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MtxTooltipModule } from '@ng-matero/extensions/tooltip';

// Map box POC
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { MapBoxComponent } from './map-box/map-box.component';
import { ZipCodeImportDialogComponent } from './dialogs/zip-code-import-dialog/zip-code-import-dialog.component';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import { ComboChartComponent2,ComboSeriesVerticalComponent2 } from './combo-chart-dashboard';
import { MarkerMultiScreenTableComponent } from './map-box/marker-multi-screen-table/marker-multi-screen-table.component';
import { ScreensFormatsComponent } from './screens-formats/screens-formats.component';
import { NotificationChannelIdService } from './services/notification-channel-id.service';
import { CalendarSchedulerComponent } from './calendar-scheduler/calendar-scheduler.component';
import { WeekDayComponent } from './calendar-scheduler/components/week-day/week-day.component';
import { WeekComponent } from './calendar-scheduler/components/week/week.component';
import { AddTimeComponent } from './calendar-scheduler/components/add-time/add-time.component';
import { CopyTimeComponent } from './calendar-scheduler/components/copy-time/copy-time.component';
import { LottiePlayer } from '@lottiefiles/lottie-player';
import { CampaignPackagesComponent } from './campaign-packages/campaign-packages.component';
import { NewSmbDialogComponent } from './dialogs/new-smb-dialog/new-smb-dialog.component';
import { ProposalDialogComponent } from './dialogs/proposal-dialog/proposal-dialog.component';
import { WhitelabelDropdownComponent } from './whitelabel-dropdown/whitelabel-dropdown.component';
import { WorkspaceDropdownComponent } from './workspace-dropdown/workspace-dropdown.component';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations:[
    MapComponent,
    NavigationManagementComponent,
    GenericTableComponent,
    HourWeeklyTableComponent,
    HourRangeFieldComponent,
    DayHourRangeComponent,
    ExcludeRangeDatesComponent,
    RangeDatepickerComponent,
    HourRangeEditTableComponent,
    DialogNewDealIdComponent,
    CustomContentDialogComponent,
    AlertDialogComponent,
    AutocompleteFieldComponent,
    ScreenPropertiesComponent,
    PointOfInterestComponent,
    BreadcrumbsComponent,
    BreadcrumbComponent,
    PageLayoutComponent,
    WhitelabelDropdownComponent,
    WorkspaceDropdownComponent,
    AutocompleteMoneyFieldComponent,
    MoneyFieldComponent,
    SearchFieldComponent,
    TabInfoComponent,
    MandatoryOptionsComponent,
    MandatoryOptionComponent,
    ErrorMessageComponent,
    BudgetPickerComponent,
    ScreensViewComponent,
    ScreensMapComponent,
    ScreensViewTabComponent,
    ScreensTabComponent,
    ComboChartComponent,
    ComboSeriesVerticalComponent,
    CustomCircleSeriesComponent,
    AudienceReachComponent,
    ExpectedReachFieldComponent,
    ToastComponent,
    CustomLoaderComponent,
    AutocompleteWithTreeviewComponent,
    PhoneNumberInputComponent,
    SupportedFormatsComponent,
    CustomLoaderComponent,
    PoiImportDialogComponent,
    TermOfServiceDialogComponent,
    NewSupportedFormatsComponent,
    DmaDialogComponent,
    InputDialogComponent,
    NamespaceInventoryComponent,
    MapDialogComponent,
    ScreenSearchDialogComponent,
    AudienceComponent,
    PointOfInterestDialogComponent,
    ScreenPropertiesDialogComponent,
    DmaComponent,
    MapBoxComponent,
    ZipCodeImportDialogComponent,
    ImageCarouselComponent,
    ComboChartComponent2,
    ComboSeriesVerticalComponent2,
    MarkerMultiScreenTableComponent,
    ScreensFormatsComponent,
    CalendarSchedulerComponent,
    WeekDayComponent,
    WeekComponent,
    AddTimeComponent,
    CampaignPackagesComponent,
    CopyTimeComponent,
    NewSmbDialogComponent,
    ProposalDialogComponent,
  ],
  imports: [
    CommonModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDUZA7za4PxxsNQPZP0D9I-Z8w22RKNdB0",
      libraries: ['places'],
      language: 'en'
    }),
    InternalizationModule,
    RouterModule,
    MaterialModule,
    ReactiveFormsModule,
    ScrollingModule,
    InViewportModule,
    FormsModule,
    Ng5SliderModule,
    CommonPipesModule,
    MatRadioModule,
    AgmMarkerClustererModule,
    ChartCommonModule,
    LineChartModule,
    BarChartModule,
    CommonModule,
    NgxFilesizeModule,
    NgxIntlTelInputModule,
    MatToolbarModule,
    NgxCsvParserModule,
    LottieModule.forRoot({ player: playerFactory }),
    NgxMapboxGLModule.withConfig({
      accessToken: 'sk.eyJ1IjoiYWJpdG9ndW5hIiwiYSI6ImNsNTR6dnhyczE0ZzYzY21hcTd2bno1bTgifQ.5xWgOIT04EIqA440sfb3rg'
    }),
    CarouselModule,
    MtxTooltipModule
  ],
  exports: [
    MapComponent,
    InternalizationModule,
    NavigationManagementComponent,
    GenericTableComponent,
    HourWeeklyTableComponent,
    HourRangeFieldComponent,
    ExcludeRangeDatesComponent,
    RangeDatepickerComponent,
    CustomContentDialogComponent,
    AlertDialogComponent,
    AutocompleteFieldComponent,
    ScreenPropertiesComponent,
    PointOfInterestComponent,
    BreadcrumbsComponent,
    BreadcrumbComponent,
    PageLayoutComponent,
    ScreensTabComponent,
    AutocompleteMoneyFieldComponent,
    MoneyFieldComponent,
    SearchFieldComponent,
    TabInfoComponent,
    MandatoryOptionsComponent,
    BudgetPickerComponent,
    ScreensViewComponent,
    ComboChartComponent,
    AudienceReachComponent,
    ExpectedReachFieldComponent,
    CustomLoaderComponent,
    AutocompleteWithTreeviewComponent,
    PhoneNumberInputComponent,
    SupportedFormatsComponent,
    NamespaceInventoryComponent,
    NewSupportedFormatsComponent,
    ImageCarouselComponent,
    ComboChartComponent2,
    ComboSeriesVerticalComponent2,
    MarkerMultiScreenTableComponent,
    ScreensFormatsComponent,
    CalendarSchedulerComponent,
    ProposalDialogComponent,

  ],
  providers:[
    GoogleMapsAPIWrapper,
    AddDynamicComponentService,
    DialogService,
    NotificationChannelIdService,
    DecimalPipe,
    DatePipe,
    TitleCasePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsLibraryModule {
  private ref = LottiePlayer;
}
