import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { MandatoryOptionComponent } from '../mandatory-option/mandatory-option.component';
import { ErrorMessageComponent } from '../error-message/error-message.component';

@Component({
  selector: 'dooh-mandatory-options',
  templateUrl: './mandatory-options.component.html',
  styleUrls: ['./mandatory-options.component.scss']
})
export class MandatoryOptionsComponent implements OnInit {

  @Input()
  options: any[] = [];

  @Input()
  optionsErrorMessageKey: string = '';

  @Input()
  titleKey: string = ''

  @Input()
  hasError: boolean = false;

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

}
