import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dooh-supported-formats',
  templateUrl: './supported-formats.component.html',
  styleUrls: ['./supported-formats.component.scss']
})
export class SupportedFormatsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
