import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@dooh/auth';
import { ActiveNamespaceService } from '@dooh/common-services';
import { DialogService } from '@dooh/components-library';
import { NamespaceView } from '@dooh/models';
import { PasswordMatchValidator, CustomPasswordValidator } from '@dooh/validation';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dooh-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.scss']
})
export class SetNewPasswordComponent implements OnInit, OnDestroy {

  setPassword: FormGroup;
  namespace: NamespaceView;

  unsubscriber$ = new Subject<void>()

  constructor(
    private fb: FormBuilder,
    private Auth: AuthService,
    private dialogService: DialogService,
    private router: Router,
    private activeNamespaceService: ActiveNamespaceService
  ) {
    this.namespace = ActiveNamespaceService.getNamespace();
    this.activeNamespaceService.loadNamespace()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(value => { this.namespace = value})
  }

  ngOnInit(): void {
    this.setPassword = this.fb.group({
      code: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.required,
            CustomPasswordValidator.patternValidator(/\d/, {
              hasNumber: true,
            }),
            CustomPasswordValidator.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            CustomPasswordValidator.patternValidator(/[a-z]/, {
              hasSmallCase: true,
            }),
            CustomPasswordValidator.patternValidator(
              /[ !@#$%^&*~()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true,
              }
            ),
            Validators.minLength(8),
      ]),
      passwordAgain: new FormControl('', [Validators.required])
    }, {validators: PasswordMatchValidator.MatchPassword})
  }

  onSubmit() {
    const username = localStorage.getItem('username');

    this.Auth.sendResetPassword(username, this.setPassword.getRawValue()).then(resp=>{
      this.dialogService.alert({
        type: 'success',
        message: 'The password was successfully changed'
      }).afterClosed().subscribe(() => {
        localStorage.clear()
        window.location.href = '/';
      });
    }).catch(err => {
      this.dialogService.alert({
        type: 'error',
        message: err.message
      });
    })
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
}

}
