import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { PageDto, CustomHttpUrlEncodingCodec } from '@dooh/models';
import { ApiService } from '@dooh/api';
import { AutocompleteService } from '@dooh/components-library';


@Injectable({
  providedIn: 'root'
})
export class TimezonesService implements AutocompleteService {
  sort = ['tzOffset,asc'];
  apiUrl = "admin";

  countryCode$ = new BehaviorSubject<string>(null);

  constructor(private apiService: ApiService) {}

  getAll(page: number = 1, pageSize: number = 50, filters?: Array<string>): Observable<PageDto> {
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()})
      .set('page', <any>page)
      .set('page_size', <any>pageSize);

      const countryCode = this.countryCode$.value;
      if (countryCode) {
        queryParameters = queryParameters.append('country_code', <any>countryCode);
      }
      

    this.sort.forEach((element) => {
      queryParameters = queryParameters.append('sort', <any>element);
    });

    if (filters) {
      filters.forEach((element) => {
        const filterQueryParam = element.split(',')[0];
        const filterValue = element.split('%')[1];
        queryParameters = queryParameters.append(filterQueryParam, <any>filterValue);
      });

      let firstFilterValue = filters[0].split('%')[1];
      if (firstFilterValue !== '') {
        queryParameters = queryParameters.delete('country_code');
      } else {
        if (!queryParameters.get('country_code')) {
          queryParameters = queryParameters.append('country_code', <any>countryCode);
        }

      }
    }

    return this.apiService.get(`${this.apiUrl}/instances/timezones`, queryParameters);
  }

  findUserTimezoneData(page: number = 1, pageSize: number = 50, zoneName: string): Observable<PageDto> {
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()})
      .set('page', <any>page)
      .set('page_size', <any>pageSize)
      .set('city', <any>zoneName);

      const countryCode = this.countryCode$.value;

      if (countryCode && !zoneName) {
        queryParameters = queryParameters.append('country_code', <any>countryCode);
      }

    return this.apiService.get(`${this.apiUrl}/instances/timezones`, queryParameters);
  }

  setTimezoneCountry(value: string) {
      this.countryCode$.next(value);
  }
}
