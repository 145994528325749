/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AdvancedDealView } from '../model/advancedDealView';
import { DealForm } from '../model/dealForm';
import { NetworkView } from '../model/networkView';
import { PageDto } from '../model/pageDto';
import { ScreenNameView } from '../model/screenNameView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class InventoryService {

    protected basePath = 'https://dooh-api.staging.theneuron.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create new deal
     * Create new deal
     * @param body Deal Object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDeal(body: DealForm, observe?: 'body', reportProgress?: boolean): Observable<AdvancedDealView>;
    public createDeal(body: DealForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AdvancedDealView>>;
    public createDeal(body: DealForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AdvancedDealView>>;
    public createDeal(body: DealForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createDeal.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AdvancedDealView>(`${this.basePath}/dsp/deals`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete deal
     * Delete deal
     * @param dealId Deal Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDeal(dealId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteDeal(dealId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteDeal(dealId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteDeal(dealId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (dealId === null || dealId === undefined) {
            throw new Error('Required parameter dealId was null or undefined when calling deleteDeal.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/dsp/deals/${encodeURIComponent(String(dealId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get deal by id
     * Get deal by id
     * @param dealId Deal Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDeal(dealId: string, observe?: 'body', reportProgress?: boolean): Observable<AdvancedDealView>;
    public getDeal(dealId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AdvancedDealView>>;
    public getDeal(dealId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AdvancedDealView>>;
    public getDeal(dealId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (dealId === null || dealId === undefined) {
            throw new Error('Required parameter dealId was null or undefined when calling getDeal.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AdvancedDealView>(`${this.basePath}/dsp/deals/${encodeURIComponent(String(dealId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of deals
     * Get list of deals. Available fields for sorting: - &#39;deal_id&#39; - the ID of deal; - &#39;name&#39; - the name of deal; - &#39;ssp_name&#39; - the name of SSP; - &#39;advertiser_name&#39; - the name of advertiser.  Request example: /deals?page&#x3D;2&amp;page_size&#x3D;10&amp;sort&#x3D;deal_id,asc;name,desc.
     * @param page the number of page
     * @param pageSize the size of page
     * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDeals(page?: number, pageSize?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
    public getDeals(page?: number, pageSize?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
    public getDeals(page?: number, pageSize?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
    public getDeals(page?: number, pageSize?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('page_size', <any>pageSize);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageDto>(`${this.basePath}/dsp/deals`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get network by id
     * Get network by id
     * @param networkId Network Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNetwork(networkId: string, observe?: 'body', reportProgress?: boolean): Observable<NetworkView>;
    public getNetwork(networkId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NetworkView>>;
    public getNetwork(networkId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NetworkView>>;
    public getNetwork(networkId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (networkId === null || networkId === undefined) {
            throw new Error('Required parameter networkId was null or undefined when calling getNetwork.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<NetworkView>(`${this.basePath}/dsp/networks/${encodeURIComponent(String(networkId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of networks
     * Get list of networks. Available fields for sorting and filtering: - &#39;network_id&#39; - the ID of network; - &#39;name&#39; - the name of network.  Request example: /networks?page&#x3D;2&amp;page_size&#x3D;10&amp;sort&#x3D;network_id,asc;name,desc&amp;filters&#x3D;name,like,%airport%.
     * @param filters Filtering criteria in the format: field_name,operation,value. Supported operations: eq (equal, &#x3D;), neq (not equal, !&#x3D;), lt (less than, &lt;), lte (less than or equal, &lt;&#x3D;), gt (greater than, &gt;), gte (greater than or equal, &gt;&#x3D;), like.
     * @param page the number of page
     * @param pageSize the size of page
     * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNetworks(filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
    public getNetworks(filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
    public getNetworks(filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
    public getNetworks(filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters) {
            filters.forEach((element) => {
                queryParameters = queryParameters.append('filters', <any>element);
            })
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('page_size', <any>pageSize);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageDto>(`${this.basePath}/dsp/networks`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get screen name by screen id
     * Get screen name by screen id
     * @param screenId Screen Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getScreenName(screenId: string, observe?: 'body', reportProgress?: boolean): Observable<ScreenNameView>;
    public getScreenName(screenId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ScreenNameView>>;
    public getScreenName(screenId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ScreenNameView>>;
    public getScreenName(screenId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (screenId === null || screenId === undefined) {
            throw new Error('Required parameter screenId was null or undefined when calling getScreenName.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ScreenNameView>(`${this.basePath}/dsp/screen_names/${encodeURIComponent(String(screenId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of screen names
     * Get list of screen names. Available fields for sorting and filtering: - &#39;screen_id&#39; - the ID of screen; - &#39;name&#39; - the name of screen.  Request example: /screen_names?page&#x3D;2&amp;page_size&#x3D;10&amp;sort&#x3D;screen_id,asc;name,desc&amp;filters&#x3D;name,like,%airport%.
     * @param filters Filtering criteria in the format: field_name,operation,value. Supported operations: eq (equal, &#x3D;), neq (not equal, !&#x3D;), lt (less than, &lt;), lte (less than or equal, &lt;&#x3D;), gt (greater than, &gt;), gte (greater than or equal, &gt;&#x3D;), like.
     * @param page the number of page
     * @param pageSize the size of page
     * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getScreenNames(filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
    public getScreenNames(filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
    public getScreenNames(filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
    public getScreenNames(filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters) {
            filters.forEach((element) => {
                queryParameters = queryParameters.append('filters', <any>element);
            })
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('page_size', <any>pageSize);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageDto>(`${this.basePath}/dsp/screen_names`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of screens
     * Get list of screens. Allows to do several types of search queries: - parameter &#39;type&#39; is null - parameters &#39;lat&#39;, &#39;lon&#39;, &#39;distance&#39;, &#39;country&#39;, &#39;region&#39;, &#39;city&#39; and &#39;street&#39; are not supported; - parameter &#39;type&#39; is &#39;GEO&#39; - supported parameters &#39;lat&#39;, &#39;lon&#39;, &#39;distance&#39;; - parameter &#39;type&#39; is &#39;ADDRESS&#39; - supported parameters &#39;distance&#39;, &#39;country&#39;, &#39;region&#39;, &#39;city&#39; and &#39;street&#39;;
     * @param city city, used only if &#39;type&#39; parameter is &#39;ADDRESS&#39;
     * @param country country, used only if &#39;type&#39; parameter is &#39;ADDRESS&#39;
     * @param distance distance, used only if &#39;type&#39; parameter is &#39;GEO&#39;
     * @param lat latitude, used only if &#39;type&#39; parameter is &#39;GEO&#39;
     * @param lon longitude, used only if &#39;type&#39; parameter is &#39;GEO&#39;
     * @param page the number of page
     * @param pageSize the size of page
     * @param region region, used only if &#39;type&#39; parameter is &#39;ADDRESS&#39;
     * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param sspId ID of SSP
     * @param street street, used only if &#39;type&#39; parameter is &#39;ADDRESS&#39;
     * @param type type of search
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getScreens(city?: string, country?: string, distance?: number, lat?: number, lon?: number, page?: number, pageSize?: number, region?: string, sort?: Array<string>, sspId?: string, street?: string, type?: 'GEO' | 'ADDRESS', observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
    public getScreens(city?: string, country?: string, distance?: number, lat?: number, lon?: number, page?: number, pageSize?: number, region?: string, sort?: Array<string>, sspId?: string, street?: string, type?: 'GEO' | 'ADDRESS', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
    public getScreens(city?: string, country?: string, distance?: number, lat?: number, lon?: number, page?: number, pageSize?: number, region?: string, sort?: Array<string>, sspId?: string, street?: string, type?: 'GEO' | 'ADDRESS', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
    public getScreens(city?: string, country?: string, distance?: number, lat?: number, lon?: number, page?: number, pageSize?: number, region?: string, sort?: Array<string>, sspId?: string, street?: string, type?: 'GEO' | 'ADDRESS', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {













        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (city !== undefined && city !== null) {
            queryParameters = queryParameters.set('city', <any>city);
        }
        if (country !== undefined && country !== null) {
            queryParameters = queryParameters.set('country', <any>country);
        }
        if (distance !== undefined && distance !== null) {
            queryParameters = queryParameters.set('distance', <any>distance);
        }
        if (lat !== undefined && lat !== null) {
            queryParameters = queryParameters.set('lat', <any>lat);
        }
        if (lon !== undefined && lon !== null) {
            queryParameters = queryParameters.set('lon', <any>lon);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('page_size', <any>pageSize);
        }
        if (region !== undefined && region !== null) {
            queryParameters = queryParameters.set('region', <any>region);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }
        if (sspId !== undefined && sspId !== null) {
            queryParameters = queryParameters.set('ssp_id', <any>sspId);
        }
        if (street !== undefined && street !== null) {
            queryParameters = queryParameters.set('street', <any>street);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageDto>(`${this.basePath}/dsp/screens`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update deal
     * Update deal
     * @param body Deal Object
     * @param dealId Deal Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDeal(body: DealForm, dealId: string, observe?: 'body', reportProgress?: boolean): Observable<AdvancedDealView>;
    public updateDeal(body: DealForm, dealId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AdvancedDealView>>;
    public updateDeal(body: DealForm, dealId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AdvancedDealView>>;
    public updateDeal(body: DealForm, dealId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateDeal.');
        }

        if (dealId === null || dealId === undefined) {
            throw new Error('Required parameter dealId was null or undefined when calling updateDeal.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<AdvancedDealView>(`${this.basePath}/dsp/deals/${encodeURIComponent(String(dealId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public getSsps(instanceId: string, page?: number, pageSize?: number, sort?: Array<string>, filters?: Array<string>): Observable<PageDto>;
    public getSsps(instanceId: string, page?: number, pageSize?: number, sort?: Array<string>, filters?: Array<string>): Observable<HttpResponse<PageDto>>;
    public getSsps(instanceId: string, page?: number, pageSize?: number, sort?: Array<string>, filters?: Array<string>): Observable<HttpEvent<PageDto>>;
    public getSsps(instanceId: string, page?: number, pageSize?: number, sort?: Array<string>, filters?: Array<string>): Observable<any> {
        if (instanceId === null || instanceId === undefined) {
            throw new Error('Required parameter instanceId was null or undefined when calling getSsps.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }

        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('page_size', <any>pageSize);
        }

        if (sort) {
            queryParameters = queryParameters.set('sort', <any>sort.join(';'));
        }

        if (filters) {
            queryParameters = queryParameters.set('filters', <any>filters.join(';'));
        }

        let headers = this.defaultHeaders;

        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<PageDto>(`${this.basePath}/dsp/instances/${encodeURIComponent(instanceId)}/ssps`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
            }
        );
    }
}
