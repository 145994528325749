import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { DmaStateService } from 'libs/components-library/src/lib/services/dma-dialog.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DmaModel } from '../../../dma-dialog/dma.model';
import { SearchFilterService } from '../../service/search-filter.service';

@Component({
  selector: 'dooh-dma',
  templateUrl: './dma.component.html',
  styleUrls: ['./dma.component.scss'],
})
export class DmaComponent implements OnInit {
  @Input() dmaId: string[];
  dmaFormGroup: FormGroup;
  dmaUniqueSet = new Set();
  dmaWholeData: DmaModel[] = [];
  public dmaDatasource = new MatTableDataSource<DmaModel>([]);
  loadingDmaItems: boolean;
  loadingFilterResult: boolean;
  filterByFieldSource: any = null;
  unSubscriber$ = new Subject();
  constructor(
    private dmaService: DmaStateService,
    private searchFilterService: SearchFilterService,
    private fb: FormBuilder
  ) {
    this.dmaFormGroup = this.fb.group({
      dmaFormArray: this.fb.array([]),
    });
  }
  ngOnInit(): void {
    if (this.dmaId && this.dmaId.length > 0) {
      for (let dma of this.dmaId) {
        this.dmaUniqueSet.add(dma);
      }
    }

    this.loadingDmaItems = true;
    this.getDma();
  }

  onCheckboxChange(e: any) {
    if (this.dmaUniqueSet.has(e.target.value)) {
      this.dmaUniqueSet.delete(e.target.value);
    } else {
      this.dmaUniqueSet.add(e.target.value);
    }
  }

  filterDma(searchPhrase: string): void {
    if (searchPhrase.length > 2) {
      this.dmaDatasource.data = this.dmaWholeData.filter((dmaData) =>
        dmaData.dmaName.toLowerCase().includes(searchPhrase.toLowerCase())
      );
    } else {
      this.dmaDatasource.data = this.dmaWholeData;
    }
  }

  getDma(): void {
    this.dmaService
      .getDmaAggData()
      .pipe(takeUntil(this.unSubscriber$))
      .subscribe(
        (resp: any) => {
          if (resp && resp?.length > 0) {
            this.dmaDatasource.data = resp?.sort((a: any, b: any) =>
              a.dmaName.localeCompare(b.dmaName)
            );
            this.dmaWholeData = resp?.sort((a: any, b: any) =>
              a.dmaName.localeCompare(b.dmaName)
            );
            this.loadingDmaItems = false;
            this.loadingFilterResult = false;
          }
        },
        (err) => {
          this.loadingDmaItems = false;
          this.loadingFilterResult = false;
        }
      );
  }

  ngOnDestroy(): void {
    this.unSubscriber$.next();
    this.unSubscriber$.complete();
  }

  reset(trigger: boolean): void {
    this.searchFilterService.setDmaIds(null, trigger);
    this.dmaUniqueSet.clear();
  }

  applyFilter(): void {
    this.searchFilterService.setDmaIds({ dmaId: [...this.dmaUniqueSet] }, true);
  }
}
