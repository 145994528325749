<ng-template #defaultItemTemplate let-item="item">
  {{ _displayValue(item) }}
</ng-template>

<mat-form-field
  class="autocomplete-field form-field-common"
  [ngClass]="{'autocomplete-field_multi': multi && multiSelection?.length, 'autocomplete-field_suffix-big': isNeedMultiResetBlock()}"
  appearance="outline"
>
  <mat-label>
    {{ label }}
  </mat-label>
  <button
    *ngIf="multi && isNeedMultiSearchButton"
    class="autocomplete-field__prefix"
    mat-icon-button
    matPrefix
    type="submit"
    class="color-gray-2"
  >
    <mat-icon svgIcon="search" class="autocomplete-field__search-icon"></mat-icon>
  </button>
  <input
    *ngIf="!multi"
    #autocompleteInput
    type="text"
    matInput
    autocomplete="off"
    [matAutocomplete]="autocomplete"
    (focus)="onFocus($event)"
    (blur)="onBlur($event)"
    [formControl]="formControl"
    (input)="onInput($event)"
    [readonly]="readonly"
    [ngClass]="{'read_only': readonly }"
  />
  <mat-chip-list *ngIf="multi" class="autocomplete-field__chip-list" #chipList [selectable]="false">
    <mat-chip
      *ngFor="let option of multiSelection"
      class="autocomplete-field__chip"
      [removable]="true"
      (removed)="doRemoveMultiOption(option)"
      [ngClass]="{'read_only': readonly }"
    >
      {{ _displayValue(option) }}
      <ng-container *ngIf="!readonly">
        <mat-icon matChipRemove>cancel</mat-icon>
      </ng-container>
    </mat-chip>
    <input
      #autocompleteInput
      type="text"
      matInput
      autocomplete="off"
      [matAutocomplete]="autocomplete"
      (focus)="onFocus($event)"
      (blur)="onBlur($event)"
      [formControl]="formControl"
      (input)="onInput($event)"
      [readonly]="readonly"
      [ngClass]="{'read_only': readonly }"
      [matChipInputFor]="chipList"
      [maxlength]="maxInputChar"
    />
  </mat-chip-list>
  <mat-autocomplete
    #autocompletePanel
    #autocomplete="matAutocomplete"
    [displayWith]="_extractValue.bind(this)"
    (optionSelected)="onOptionSelect($event)"
    [panelWidth]="classList"
    (opened)="isPanelOpened()"
  >
    <mat-option
      *ngIf="isLoading === false && !foundValues?.length"
      [disabled]="true"
    >
      {{ 'autoCompleteField.noResult' | translate }}
    </mat-option>
    <ng-container *ngIf="foundValues && foundValues?.length">
      <mat-option
      *ngIf="multi && hasSelectAll"
      class="autocomplete-field__option autocomplete-field__option_multi"
      [ngClass]="{
        'autocomplete-field__option_multi-tall': !!itemTemplate,
        'autocomplete-field__option_multi-selected ': isChecked(option)
      }"
    >
      <mat-checkbox
        class="autocomplete-field__checkbox"
        (click)="$event.stopPropagation()"
        color="primary"
        (change)="$event ? masterToggle() : null"
        [checked]="this.multiSelection?.length > 0 && isAllSelected()"
        [indeterminate]="this.multiSelection?.length > 0 && !isAllSelected()"
      >
        Select All
      </mat-checkbox>
    </mat-option>
      <ng-container
        *ngFor="let option of foundValues; index as i"
      >
        <span
          *ngIf="groupValuesBy && (!i || foundValues[i - 1][groupValuesBy] !== option[groupValuesBy])"
          class="autocomplete-field__group-separator"
        >
          {{ getGroupSeparator(option) | async }}
        </span>
        <mat-option
          *ngIf="!multi"
          class="autocomplete-field__option"
          [ngClass]="{'autocomplete-field__option_active': currentValue && _extractValue(currentValue) === _extractValue(option||'')}"
          [value]="option"
          [matTooltip]="_displayValue(option)"
          [matTooltipDisabled]="checkToShowTooltip(option)"
        >
          <span class="autocomplete-field__text">{{ _displayValue(option) }}</span>
        </mat-option>

        <mat-option
          *ngIf="multi"
          class="autocomplete-field__option autocomplete-field__option_multi"
          [ngClass]="{
            'autocomplete-field__option_multi-tall': !!itemTemplate,
            'autocomplete-field__option_multi-selected ': isChecked(option)
          }"
          [value]="option"
          [matTooltip]="_displayValue(option)"
          [matTooltipDisabled]="checkToShowTooltip(option)"
        >
          <mat-checkbox
            class="autocomplete-field__checkbox"
            (click)="$event.stopPropagation()"
            (change)="toggleMultiOption($event, option)"
            color="primary"
            [checked]="checkBoxStatus(option)"
          >
            <ng-template
              [ngTemplateOutlet]="itemTemplate || defaultItemTemplate"
              [ngTemplateOutletContext]="{item: option}"
            >
            </ng-template>
          </mat-checkbox>
        </mat-option>
      </ng-container>
    </ng-container>
    <mat-option
      *ngIf="isLoading"
      [disabled]="true"
      class='auto-complete-loader'
    >
      {{ 'autoCompleteField.loading' | translate }}
    </mat-option>
  </mat-autocomplete>
  <div
    matSuffix
    class="autocomplete-field__suffix"
    [ngClass]="{'autocomplete-field__suffix-big': isNeedMultiResetBlock()}">
    <span
      class="autocomplete-field__amount-container"
      [ngClass]="{'autocomplete-field__hidden': !isNeedMultiResetBlock()}"
    >
      <span class="autocomplete-field__amount">
        {{ multiSelection && multiSelection?.length }}
      </span>
    </span>
    <button
      class="autocomplete-field__close-btn"
      [ngClass]="{'autocomplete-field__hidden': !isNeedMultiResetBlock()}"
      mat-icon-button
      matSuffix
      type="reset"
      (click)="onReset($event)"
    >
      <mat-icon class="autocomplete-field__close" svgIcon="close"></mat-icon>
    </button>
    <mat-icon
      *ngIf="!readonly && !autocompletePanel.isOpen && !isLoading"
      class="autocomplete-field__chevron"
      svgIcon="chevron-down"
      color="primary"
    ></mat-icon>
    <mat-icon
      *ngIf="!readonly && autocompletePanel.isOpen && !isLoading"
      class="autocomplete-field__chevron"
      color="primary"
    >expand_less</mat-icon>
    <mat-spinner
      class="autocomplete-field__chevron"
      color="primary" diameter="15" *ngIf="isLoading"></mat-spinner>
  </div>
  <mat-error *ngIf="formControl.invalid">
    {{ getError() | async }}
  </mat-error>
</mat-form-field>

