import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replacePlaceholder'
})
export class ReplacePlaceholderPipe implements PipeTransform {

  transform(value: string, placeHolder:string, placeHolderValue: string): unknown {
    return value?.replace(placeHolder, placeHolderValue);
  }

}
