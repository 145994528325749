/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ModelFile { 
    absolute?: boolean;
    absoluteFile?: any;
    absolutePath?: string;
    canonicalFile?: any;
    canonicalPath?: string;
    directory?: boolean;
    executable?: boolean;
    file?: boolean;
    freeSpace?: number;
    hidden?: boolean;
    lastModified?: number;
    name?: string;
    parent?: string;
    parentFile?: any;
    path?: string;
    readable?: boolean;
    totalSpace?: number;
    usableSpace?: number;
    writable?: boolean;
}
