import { Injectable } from '@angular/core';
import { InstanceView, InstancesViewRole } from '@dooh/models';
import { setFormat } from '../custom-date-adapter/custom-date-adapter';
@Injectable({
  providedIn: 'root'
})
export class SelectedInstanceService {
  constructor() {
  }

  static getInstance(): InstanceView {
    const selectedInstance = localStorage.getItem('instance');
    return selectedInstance ? JSON.parse(selectedInstance) : '';
  }

  static getInstanceSMB(): InstanceView {
    const selectedInstance = localStorage.getItem('smbInstance');
    return selectedInstance ? JSON.parse(selectedInstance) : '';
  }

  static getInstanceId(): string {
    const selectedInstance = this.getInstance();
    return selectedInstance && selectedInstance.id || null;
  }

  static getInstanceCurrency(isSMB = false): string {
    const selectedInstance = isSMB ? this.getInstanceSMB() : this.getInstance();
    if (selectedInstance && selectedInstance.currency && selectedInstance.currency.code) {
      return selectedInstance.currency.code;
    }
    return '';
  }

  static isSelectedInstance(instance: InstanceView): boolean {
    const selectedInstanceId = SelectedInstanceService.getInstanceId();
    return instance && !!instance.id && instance.id === selectedInstanceId;
  }

  static setInstance(instance: InstanceView) {
    if (instance) {
      localStorage.setItem('instance', JSON.stringify(instance));
      setFormat()
    }
  }

  static getInstanceForMultiRoles(): InstancesViewRole | null {
    const instancesWithRoles = JSON.parse(localStorage.getItem('instancesWithRoles'));
    return instancesWithRoles?.find((instance) => instance.instance === SelectedInstanceService.getInstanceId()) || null;
  }

  static clearInstance() {
    localStorage.removeItem('instance');
  }
}
