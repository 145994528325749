<div
  appearance="outline"
  [formGroup]="timeRange"
  class="time-range-container"
  [ngClass]="{'disabled': disabled}"
  (mouseover)="activeClose()"
  (mouseleave)="deactiveClose()">

  <mat-form-field class="time-range-picker" matTooltip="Click on the clock to toggle dropdown" [ngClass]="{'disabled': disabled}" appearance="outline">
    <mat-label>Select start time</mat-label>
    <input [disabled]="disabled"
           matInput type="time"
           formControlName="from" (change)="updateFromValue()" value="{{fromValue}}">
  </mat-form-field>

  <mat-form-field class="time-range-picker" matTooltip="Click on the clock to toggle dropdown" [ngClass]="{'disabled': disabled}" appearance="outline">
    <mat-label>Select end time</mat-label>
    <input [disabled]="disabled"
           matInput type="time"
           formControlName="to" (change)="updateToValue()" value="{{toValue}}">
  </mat-form-field>
  <mat-icon
    svgIcon="close"
    class="close-button"
    *ngIf="closeButton"
    (mousenter)="activeClose()"
    (click)="remove()"
  ></mat-icon>
</div>

<span class="time-range__error" *ngIf="!disabled">{{error}}</span>

<mat-menu #fromMenu="matMenu">
  <cdk-virtual-scroll-viewport itemSize="5" class="viewport-menu" *ngIf="!disabled">
    <button
      mat-menu-item
      *ngFor="let item of times"
      (click)="updateFromValue(item)"
      (blur)="onBlur()"
    >
      {{item}}
    </button>
  </cdk-virtual-scroll-viewport>
</mat-menu>

<mat-menu #toMenu="matMenu">
  <cdk-virtual-scroll-viewport itemSize="5" class="viewport-menu" *ngIf="!disabled">
    <button mat-menu-item *ngFor="let item of timesToField"
            (blur)="onBlur()"
            (click)="updateToValue(item)">{{item}}
    </button>
  </cdk-virtual-scroll-viewport>
</mat-menu>
