import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, CustomHttpUrlEncodingCodec } from '@dooh/api';
import { BehaviorSubject, forkJoin, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PointOfInterestService {
  private customPoiFile: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  customPoiFile$ = this.customPoiFile.asObservable();

  private uploadFile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  uploadFile$ = this.uploadFile.asObservable();

  private customZipCodeFile: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  customZipCodeFile$ = this.customZipCodeFile.asObservable();

  private uploadZipCodeFile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  uploadZipCodeFile$ = this.uploadZipCodeFile.asObservable();

  public component$: BehaviorSubject<string> = new BehaviorSubject<string>(null)

  constructor(private apiService: ApiService) { }

  nearbySearch(location: any, radius: number, types: string[], token?: string): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (location !== undefined && location !== null) {
      queryParameters = queryParameters.set('location', `${location.lat},${location.lng}`);
    }

    if (radius !== undefined && radius !== null && radius > 0) {
      queryParameters = queryParameters.set('radius', <any>radius);
    
    }
    if (types.length > 0) {
      types.forEach((element) => {
        queryParameters = queryParameters.append('type', <any>element);
      });
    }
    if (token !== undefined && token !== null) {
      queryParameters = queryParameters.set('pagetoken', <any>token);
    }


    const queryUrl = `dsp/googlemaps/nearbysearch`;
    return this.apiService.get(queryUrl, queryParameters);
  }

  textSearch(location: any, radius: number, types: string[], query?: string, token?: string): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (location !== undefined && location !== null) {
      queryParameters = queryParameters.set('location', `${location.lat},${location.lng}`);
    }

    if (query !== undefined && query !== null) {
      queryParameters = queryParameters.set('query', <any>query);
    }

    if (radius !== undefined && radius !== null && radius > 0) {
      queryParameters = queryParameters.set('radius', <any>radius);
    
    }
    if (types.length > 0) {
      types.forEach((element) => {
        queryParameters = queryParameters.append('type', <any>element);
      });
    }
    if (token !== undefined && token !== null) {
      queryParameters = queryParameters.set('pagetoken', <any>token);
    }


    const queryUrl = `dsp/googlemaps/textsearch`;
    return this.apiService.get(queryUrl, queryParameters);
  }

  setCustomPoiFile(file: any): void {
    this.customPoiFile.next(file);
  }

  getCustomPoiFile(): Observable<any> {
    return this.customPoiFile$;
  }

  setFileUploadStatus(status: boolean): void {
    this.uploadFile.next(status);
  }

  getFileUploadStatus(): Observable<boolean> {
    return this.uploadFile$;
  }


  setCustomZipCodeFile(file: any): void {
    this.customZipCodeFile.next(file);
  }

  getCustomZipCodeFile(): Observable<any> {
    return this.customZipCodeFile$;
  }

  setZipCodeFileUploadStatus(status: boolean): void {
    this.uploadZipCodeFile.next(status);
  }

  getZipCodeFileUploadStatus(): Observable<boolean> {
    return this.uploadZipCodeFile$;
  }

  setComponent(value: string) {
    this.component$.next(value);
  }

  getScreenTags(searchString: string, country: string): Observable<any> {
    const googleResults$ = this.getPlacesFromGoogle(searchString, country);

    return forkJoin([googleResults$])
    .pipe(
      map((result: any) => {
        const google = result[0].predictions;
        let allResult = {
          content: [],
          totalPages: 1,
          totalElements: 0
        }

        google.reverse().forEach((item: any) => {
          item.id = item.description,
          item.tag = 'Place'
          allResult.content.unshift(item);
        });
        allResult.totalElements = google.length;
        return allResult;
      })
    )
  }

  getPlacesFromGoogle(searchString: string, components?: string): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (searchString !== undefined && searchString !== null) {
      queryParameters = queryParameters.set('input', `${searchString}`);
    }
    if (components !== undefined && components !== null) {
      queryParameters = queryParameters.set('components', `country:${components}`);
    }
    const queryUrl = `dsp/googlemaps/autocomplete`;
    return this.apiService.get(queryUrl, queryParameters);
  }

  getGooglePlaceInfo(placeId: string): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    queryParameters = queryParameters.set('place_id', `${placeId}`);
    const queryUrl = `dsp/googlemaps/geocode`;
    return this.apiService.get(queryUrl, queryParameters);
  }
}
