import { MapsAPILoader } from '@agm/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DayScheduleForm } from '@dooh/models';
import * as moment from 'moment';
import { getWeekStartByRegion} from 'weekstart/full';

const DEFAULT_FROM_STR = '00:00';
const DEFAULT_TO_STR = '23:59';
const END_HOUR_SECONDS = ':59.999999999';

declare var google: any;
const DEFAULT_COUNTRY = 'uk';
@Component({
  selector: 'dooh-calendar-scheduler',
  templateUrl: './calendar-scheduler.component.html',
  styleUrls: ['./calendar-scheduler.component.scss'],
})
export class CalendarSchedulerComponent implements OnInit {
  @Input() editable: boolean;
  @Input() initData?: DayScheduleForm[];

  @Output() changeValue: EventEmitter<void> = new EventEmitter<void>();

  geocoder: google.maps.Geocoder;
  weekDays: string[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  fullInitData: DayScheduleForm[];
  normalisedInitData: {};

  scheduleValues: any[];
  selectedCountryISO = DEFAULT_COUNTRY;
  constructor(private mapsApiLoader: MapsAPILoader) {
    this.mapsApiLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
      this.getCurrentCountry();
    })
  }

  ngOnInit(): void {
    this.fullInitData = this.getFullInitData(this.initData);
    this.normalisedInitData = this.normaliseData(this.fullInitData);
    this.scheduleValues = [...this.fullInitData];
  }

  private getFullInitData(initData) {
    const fullInitData = Array.isArray(initData) ? [...initData] : [];
    const isMentionedSmth = Array.isArray(initData) && initData?.length;

    if (isMentionedSmth) {
      return fullInitData;
    }

    this.weekDays.forEach((weekDayStr: string) => {
      const key = this.toWeekdayKey(weekDayStr);
      fullInitData.push({
        day: key,
        startTime: DEFAULT_FROM_STR,
        endTime: DEFAULT_TO_STR,
      });
    });

    return fullInitData;
  }

  private toWeekdayKey(weekDayStr: string): string {
    return weekDayStr && weekDayStr.toUpperCase();
  }

  private normaliseData(data: DayScheduleForm[]): Object {
    const normData = {};
    data.forEach((element) => {
      normData[element.day] = normData[element.day] || [];
      normData[element.day].push({
        startTime: element['startTime'],
        endTime: element['endTime'],
      });
    });

    return normData;
  }

  setScheduleValues(event: any): void {
    const data = event;
    this.scheduleValues = [];
    Object.keys(data).forEach((key) => {
      data[key].forEach((item) => {
        const val = {
          day: key,
          startTime: item?.startTime,
          endTime:
            item?.endTime === DEFAULT_TO_STR
              ? this.toEndOfHour(item?.endTime)
              : item?.endTime,
        };
        this.scheduleValues.push(val);
      });
    });

    this.changeValue.emit();
  }

  getValue(): any {
    return this.scheduleValues;
  }

  toEndOfHour(val) {
    if (!val) {
      return null;
    }
    return val + END_HOUR_SECONDS;
  }

  getCurrentCountry(){
    navigator.geolocation.getCurrentPosition((position)=>{
      this.getCurrentCountrySuccess(position);
    },
    ()=>{
      this.getCurrentCountryFail();
    })
  }
  getCurrentCountrySuccess(position){
    let lat = position.coords.latitude;
    let lng = position.coords.longitude;
    this.getCountryName(lat, lng)
  }
  getCurrentCountryFail(){
    this.selectedCountryISO = DEFAULT_COUNTRY;
    moment.updateLocale('en', {
      week: {
        dow: getWeekStartByRegion(this.selectedCountryISO)
      }
    })
    this.weekDays = moment.weekdays(true);
  }
  getCountryName(lat:number, lng:number){
    let latlng = new google.maps.LatLng(lat, lng);
    this.geocoder.geocode({location: latlng}, (results) => {
      const country =  results[0].address_components.find(value=>value.types.includes("country"));
      const countryName = country.short_name.toLocaleLowerCase();
      this.selectedCountryISO = countryName || DEFAULT_COUNTRY;

      moment.updateLocale('en', {
        week: {
          dow: getWeekStartByRegion(this.selectedCountryISO)
        }
      })
      this.weekDays = moment.weekdays(true);
    })
  }
}
