import { Injectable } from '@angular/core';
import { ApiService, CustomHttpUrlEncodingCodec } from '@dooh/api';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { PageDto, TargetingForm, FilterScreenObject, FieldFilterRequest } from '@dooh/models';
import { HttpParams } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { Marker } from '@dooh/models';
import { ScreensFilters } from '../screens-filters/screens-filters';
import { ScreensFiltersService } from '../screens-filters/screens-filters.service';
import { ScreensService } from 'apps/smb-app/src/app/campaign-management/services/screens.service';
import {ActiveNamespaceService, TargetingFiltersService} from '@dooh/common-services';
import { IBulkSelectQuery } from 'libs/components-library/src/lib/services/bulk-select.service';
import { SearchFilterService } from 'libs/components-library/src/lib/dialogs/screen-search-dialog/service/search-filter.service';


@Injectable({
  providedIn: 'root'
})
export class TargetingsService {

  public static apiUrl = "dsp";
  public component$: BehaviorSubject<string> = new BehaviorSubject(null)
  public filters$: BehaviorSubject<FilterScreenObject> = new BehaviorSubject(null)
  public cachedVenueTypes$: Observable<any>;

  googleSub$: any;
  dspSub$: any;

  constructor(
    private apiService: ApiService,
    private screensService: ScreensService,
    private screensFiltersService: ScreensFiltersService,
    private targetingFilterService: TargetingFiltersService,
    private searchFilterService: SearchFilterService
  ) { }


  getAll(filters?: string[], apiUrl?: string, page: number = 1, pageSize: number = 50 ): Observable<PageDto> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    if (filters) {
      filters.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    return this.apiService.get(`${apiUrl}/targeting_sets`, queryParameters);

  }

  getAllAvailable(campaign_id,line_item_id, page: number = 1, pageSize: number = 50, sort? ): Observable<PageDto> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    return this.apiService.get(`dsp/campaigns/${campaign_id}/line_items/${line_item_id}/available_targeting_sets`, queryParameters);

  }

  deleteTargeting(id: string, apiUrl: string){
    const queryUrl = `${apiUrl}/targeting_sets/${id}`;

    return this.apiService.delete(queryUrl);
  }

  createTargeting(apiUrl: string){
    const queryUrl = `${apiUrl}/targeting_sets`;

    return this.apiService.post(queryUrl);

  }

  postTargetingSetsBatch(campaign_id,line_item_id,body, apiUrl?: string){
    const queryUrl = `dsp/campaigns/${campaign_id}/line_items/${line_item_id}/targeting_sets_batch`;

    return this.apiService.post(queryUrl, body);

  }

  getMarkers() {
    const markers: Marker[] = [];
    return this.apiService.get(`${TargetingsService.apiUrl}/screens`).pipe(
      map(resp => {
        const marker = {} as Marker;
        for (const item of resp['content']) {
          marker.id = item.id;
          marker.lat = item.lat;
          marker.lng = item.lng;
          marker.label = item.name;
          markers.push(marker);
        }
        return markers;

      })
    );
  }

  searchScreens(searchString: string, page: number, pageSize: number): Observable<PageDto> {
    const dspResults: PageDto = {
      totalElements: 0,
      totalPages: 0,
      content: []
    }
    const component = this.component$.getValue();
    const googleResults$ = this.getPlacesFromGoogle(searchString, component).map(result => result.pipe(map((val: any) => val?.predictions)));
    const dspResults$ = this.getScreensByPublisherAndSspTagsOnly(searchString, page, pageSize);

    if (page > 1) {
      return dspResults$;
    }
    const results$ = new Subject<PageDto>();

    if (this.googleSub$) {
      this.googleSub$.unsubscribe();
    }
    if (this.dspSub$) {
      this.dspSub$.unsubscribe();
    }

    this.googleSub$ = googleResults$.forEach(googleResult => googleResult.subscribe({
      next: (res: any) => {
        res.reverse().forEach((item: any) => {
          item.id = item.description,
          item.tag = 'Place'
          dspResults.content.unshift(item);
        });
        results$.next(dspResults);
      }
    }));

   this.dspSub$ = dspResults$.subscribe({
      next: (res: any) => {
        dspResults.content = [...dspResults?.content, ...res?.content];
        dspResults.totalElements = res?.totalElements;
        dspResults.totalPages = res?.totalPages;
        results$.next(dspResults);
      }
    });

    return results$
  }

  public getScreensByPublisherAndSspTagsOnly(search, page = 1, pageSize = 50): Observable<PageDto> {
    const queryUrl = `${TargetingsService.apiUrl}/filtered_screens_tag_v2`;
    const searchTag = '*' + search.toLowerCase() + '*';
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search_string', <any>searchTag);
    }

    queryParameters = queryParameters.set('tags', 'publisher,ssp');

    return this.apiService.get(queryUrl, queryParameters);
  }


  public getScreens(filterScreenObject: FilterScreenObject, page = 1, pageSize = 50): Observable<any> {
    const queryUrl = `${TargetingsService.apiUrl}/screens/search`;
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }

    const body = {
      'params': {},
      'limits': filterScreenObject.limits ? filterScreenObject.limits : null,
      'poi': filterScreenObject.pois ? {
        'distance': filterScreenObject.pois.radius,
        'points': filterScreenObject.pois.points
      } : null,
      'bboxes': filterScreenObject.bboxes ? filterScreenObject.bboxes : null
    };
    for (const tag of filterScreenObject.tags) {
      if (tag.name === 'network') {
          tag.name = 'networks';
        }
      const propertyList = [];
      for (const property of tag.properties) {
        propertyList.push(property);
      }
      if (propertyList.length > 0) {
        body['params'][tag.name] = { 'filterTerms': propertyList };
      }
    }
    return this.apiService.post(queryUrl, body, queryParameters);
  }

  public getPreviewScreens(campaign_id, line_item_id, page = 1, pageSize = 108, filters?: Array<string>, sort?: Array<string>): Observable<any> {
    const queryUrl = `${TargetingsService.apiUrl}/campaigns/${encodeURIComponent(campaign_id)}/line_items/${encodeURIComponent(line_item_id)}/screens`;
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    if (filters) {
      filters.forEach((element) => {
        queryParameters = queryParameters.append('filters', <any>element);
      });
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }
    return this.apiService.get(queryUrl, queryParameters);
  }

  public getTargetingPreviewScreens(targetingId, page = 1, pageSize = 50, filters?: Array<string>, sort?: Array<string>): Observable<any> {
    const queryUrl = `${TargetingsService.apiUrl}/targeting_sets/${encodeURIComponent(targetingId)}/screens`;
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    if (filters) {
      filters.forEach((element) => {
        queryParameters = queryParameters.append('filters', <any>element);
      });
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }
    return this.apiService.get(queryUrl, queryParameters);
  }

  public addScreens(campaignId, lineItemId, body) {
    return this.apiService.post(
      `${TargetingsService.apiUrl}/campaigns/${encodeURIComponent(campaignId)}/line_items/${encodeURIComponent(lineItemId)}/screens_batch`,
      body
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }
  public addTargetingSetScreens(targetingId, body) {

    return this.apiService.post(
      `${TargetingsService.apiUrl}/targeting_sets/${encodeURIComponent(targetingId)}/screens_batch`,
      body
    ).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  public getTarget(targetingId: string){
    return this.apiService.get(`${TargetingsService.apiUrl}/targeting_sets/${encodeURIComponent(targetingId)}`);

  }

  public updateName(name: string, targetingId: string){
    return this.apiService.put(`${TargetingsService.apiUrl}/targeting_sets/${encodeURIComponent(targetingId)}`, {name: name.trim()});
  }


  public deleteScreensTargetingSet(targetingId:string, body: Array<string>): Observable<any> {
    const queryUrl = `${TargetingsService.apiUrl}/targeting_sets/${encodeURIComponent(targetingId)}/screens_batch`;
    if (targetingId === null || targetingId === undefined) {
      throw new Error('Required parameter targetingId was null or undefined when calling deleteScreensTargetingSet.');
    }

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling deleteScreensTargetingSet.');
    }

    return this.apiService.delete(queryUrl, body);
  }

  getAvailableScreens(targetingId: string, filterScreenObject: FilterScreenObject, page: number, pageSize: number, sort?: string[]) {
    if (targetingId === null || targetingId === undefined) {
      throw new Error('Required parameter targetingId was null or undefined when calling getAvailableScreens.');
    }

    const queryUrl = `${TargetingsService.apiUrl}/screens/targeting_sets/${encodeURIComponent(targetingId)}/available_screens`;

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    if (sort && sort.length) {
      queryParameters = queryParameters.set('sort', <any>sort.join(';'));
    }


    this.setFilters(filterScreenObject);

    const body = {
      'params': {},
      'limits': filterScreenObject.limits ? filterScreenObject.limits : null,
      'poi': filterScreenObject.pois ? {
        'distance': filterScreenObject.pois.radius,
        'points': filterScreenObject.pois.points
      } : null,
      'postalCodes': filterScreenObject?.postalCodes?.length ? filterScreenObject?.postalCodes : null,
      'bboxes': filterScreenObject.bboxes ? filterScreenObject.bboxes : null,
      'placeIq': filterScreenObject.placeIq ? filterScreenObject.placeIq : null,
      'isNeededAggregation': page === 1 ? true : false
    };

    for (const tag of filterScreenObject.tags) {
      if (tag.properties.length > 0) {
        if (tag.name === 'network') {
          tag.name = 'networks';
        }
        body['params'][tag.name] = { 'filterTerms': [...tag.properties] };
      }
    }

    const targetingFilter: FieldFilterRequest = {
      body, queryParams: queryParameters, queryUrl
    }
    this.targetingFilterService.setFilterByFieldSource(targetingFilter);
    this.searchFilterService.setFilterByFieldSource(targetingFilter);


    return this.apiService.post(queryUrl, body, queryParameters);
  }

  getScreensFilters(targetingId: string, filters: ScreensFilters) {
    if (targetingId === null || targetingId === undefined) {
      throw new Error('Required parameter targetingId was null or undefined when calling getScreensFilters.');
    }

    const queryUrl = `${TargetingsService.apiUrl}/screens/targeting_sets/${encodeURIComponent(targetingId)}/available_screens`;

    return this.screensFiltersService.getScreensFilters(queryUrl, filters);
  }

  getScreensCSV(targetingId: string) {
    const queryUrl = `dsp/targeting_sets/${targetingId}/screens/csv`;
    return this.apiService.post(queryUrl);
  }

  getScreensByArea(area, page = 1, pageSize = 50) {
    const queryUrl = 'dsp/screens/search';
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }

    this.setComponent(null);

    let areaToSearch = [];
    areaToSearch.push(area);

    const body = {
      'params': {},
      'bboxes': areaToSearch
    };

    return this.apiService.post(queryUrl, body, queryParameters);
  }

  getScreensAverageData(targetingId: string, page: number, pageSize: number): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    const queryUrl = `${TargetingsService.apiUrl}/screens/targeting_sets/${encodeURIComponent(targetingId)}/available_screens`;

    const body = {
      'params': {},
      'isGlobal': true
    };
    return this.apiService.post(queryUrl, body, queryParameters);

  }

  getPlacesFromGoogle(searchString: string, country?: string): Observable<any[]>[] {
    const queryUrl = `dsp/googlemaps/autocomplete`;
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (searchString !== undefined && searchString !== null) {
      queryParameters = queryParameters.set('input', `${searchString}`);
    }

    const components = [];
    const enabledCountries = ActiveNamespaceService.getNamespace().enabledCountries;
    if (enabledCountries !== undefined && enabledCountries !== null) {
      enabledCountries.map(c => c.code).forEach(countryCode => components.push(countryCode));
    }
    if (country !== undefined && country !== null) {
      components.push(country)
    }

    const componentsSubLists = [];
    for (let i = 0; i < components.length; i += 5) {
      componentsSubLists.push(components.slice(i, i + 5));
    }

    const predictions = [];
    if (componentsSubLists.length !== 0) {
      componentsSubLists.forEach((currentSublist, index) => {
        const componentsStr = 'country:' + currentSublist.join('|country:')
        queryParameters = queryParameters.set('components', componentsStr);
        predictions.push(this.apiService.get(queryUrl, queryParameters));
      });
    } else {
      predictions.push(this.apiService.get(queryUrl, queryParameters));
    }

    return predictions;
  }

  getGooglePlaceInfo(placeId: string): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    queryParameters = queryParameters.set('place_id', `${placeId}`);
    const queryUrl = `dsp/googlemaps/geocode`;
    return this.apiService.get(queryUrl, queryParameters);
  }

  getAllVenueTypes(): Observable<any> {
    if (!this.cachedVenueTypes$) {
      const queryUrl = `dsp/venue_types`;
      this.cachedVenueTypes$ = this.apiService.get(queryUrl).pipe(shareReplay(1));
    }

    return this.cachedVenueTypes$;
  }

  setComponent(value: string) {
    this.component$.next(value);
  }
  setFilters(value: FilterScreenObject) {
    this.filters$.next(value);
  }

  getFilters(): FilterScreenObject {
    return this.filters$.getValue();
  }

  public addBulkScreensToTargetingSet(targetingId: string, query:IBulkSelectQuery):Observable<any>{
    const queryUrl = `dsp/targeting_sets/${targetingId}/attach_screens`;
    return this.apiService.post(queryUrl, query);
  }
}
