import { Component, Input, OnInit } from '@angular/core';
import { AdministratorItem } from '../../../services/administration-panel.service';

@Component({
  selector: 'dooh-grid-panel',
  templateUrl: './grid-panel.component.html',
  styleUrls: ['./grid-panel.component.scss']
})
export class GridPanelComponent implements OnInit {
  @Input() administratorItems: AdministratorItem[] = [];
  constructor() { }

  ngOnInit() {
  }
  showOtherItems() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.administratorItems.length; i++) {
      this.administratorItems[i].isVisible = true;
    }

  }

  serveAdminItem(i: number) {
    if (this.administratorItems[i].title === 'Others') {
      this.administratorItems.splice(i, 1);
      this.showOtherItems();
    }
  }
}
