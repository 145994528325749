import { Injectable } from '@angular/core';
import { NamespaceView } from '@dooh/models';
import { NamespaceInitService } from 'apps/sso-webapp/src/app/services/namespace-init.service';
import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ActiveNamespaceService {
  private activeNamespace$ = new ReplaySubject<NamespaceView>(1);
  activeNamespace = this.activeNamespace$.asObservable();

  constructor(private namespaceInitService: NamespaceInitService) {}

  static getNamespace(): NamespaceView {
    const activeNamespace = localStorage.getItem('namespace');
    return JSON.parse(activeNamespace);
  }
  
  static getTemporaryNamespace(): NamespaceView {
    const activeNamespace = localStorage.getItem('temp-namespace');
    return JSON.parse(activeNamespace);
  }

  static getNamespaceId(): string {
    const activeNamespace = this.getNamespace();
    return (activeNamespace && activeNamespace.id) || null;
  }

  static isSelectedNamespace(namespace: NamespaceView): boolean {
    const selectedNamespaceId = ActiveNamespaceService.getNamespaceId();
    return namespace && !!namespace.id && namespace.id === selectedNamespaceId;
  }

  static getNamespaceSpinner(): string {
    const namespaceSpinner = localStorage.getItem('namespaceSpinner');
    return JSON.parse(namespaceSpinner);
  }

   setNamespace(namespace: NamespaceView) {
     this.activeNamespace$.next(namespace);
    localStorage.setItem('namespace', JSON.stringify(namespace));

    if (namespace?.spinnerUrl) {
      this.namespaceInitService
      .getSpinnerJson(namespace?.spinnerUrl)
      .pipe(take(1))
      .subscribe((res) => {
        this.setNamespaceSpinner(res);
      });
    } else {
      localStorage.removeItem('namespaceSpinner');
    }
  }

   private setNamespaceSpinner(spinner: any) {
    localStorage.setItem('namespaceSpinner', JSON.stringify(spinner));
  }

  setTemporaryNamespace(namespace: NamespaceView) {
    localStorage.setItem('temp-namespace', JSON.stringify(namespace));
  }

  clearTempNamespace() {
    localStorage.removeItem('temp-namespace');
  }
  

  loadNamespace(): Observable<NamespaceView> {
    return this.activeNamespace
  }
}
