import { ApiService, CustomHttpUrlEncodingCodec } from '@dooh/api';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { PageDto, ScreenTagView } from '@dooh/models';
import { HttpParams } from '@angular/common/http';
import { ScreensFilters } from '@dooh/common-services';
import { RESPONSE_SCREEN_TAGS } from '@dooh/common-services';
import { AutocompleteService } from '@dooh/components-library';

const PAGE: string = '1';
const PAGE_SIZE: string = '1';

export abstract class ScreenPropertiesAutocompleteService implements AutocompleteService {

  constructor() {}

  abstract getApiService(): ApiService;
  abstract getUrl(urlArgsArr?: any): string;

  getAll(page: number, pageSize: number, filters?: Array<string>, args?: any): Observable<PageDto> {
    const screensFilter: ScreensFilters = args[0];
    
    const tag: string = args[1];
    const resultTag: string = RESPONSE_SCREEN_TAGS[tag] || '';

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    queryParameters.set('page', PAGE);
    queryParameters.set('page_size', PAGE_SIZE);

    const body = {
      'params': {},
      'limits': null,
      'poi': screensFilter && screensFilter.pois ? {
        'distance': screensFilter.pois.radius,
        'points': screensFilter.pois.points
      } : null,
      'bboxes': screensFilter && screensFilter?.bboxes ? screensFilter?.bboxes : null
    };

    const screenTag = screensFilter && screensFilter.screenTags;
    if (screenTag) {
      body['params'][screenTag[0].tag] = { 'filterTerms': [ screenTag[0].id ] };
    }

    const details = {};
    if (page !== undefined && page !== null) {
      details['page'] = page;
    }
    if (pageSize !== undefined && pageSize !== null) {
      details['page_size'] = pageSize;
    }
    body['params'][tag] = details;

    const apiService: ApiService = this.getApiService();
    if (!apiService) {
      throw new Error('Required parameter apiService was null or undefined when calling getAvailableScreens.');
    }

    const queryUrl = this.getUrl(args[2]);
    return apiService.post(queryUrl, body, queryParameters)
      .pipe(
        map((response: any) => {
          if (
            !response ||
            !response.tags ||
            !response.tags[resultTag] ||
            !Array.isArray(response.tags[resultTag].values)
          ) {
            return {
              content: [],
              totalElements: null,
              totalPages: null,
            };
          }
          return {
            content: response.tags[resultTag].values,
            totalElements: null,
            totalPages: null,
          };
        }),
        catchError(() => {
          return of({
            content: [],
            totalElements: null,
            totalPages: null,
          });
        })
      );
  }
};
