import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthService, NewPasswordGuard, SmbAuth, SmbUnauth } from "@dooh/auth";
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SetNewPasswordComponent } from './set-new-password/set-new-password.component';
import { SmbSignupComponent } from "./smb-signup/smb-signup.component";
import { InstanceCreateComponent } from "./instance-create/instance-create.component";
import { CanActivateInstanceCreate } from "./instance-create/can-activate-instance-create";
import { SmbUserResolverService } from "./resolvers/smb-user-resolver.service";
import {TutorialsPanelComponent} from "./tutorials-panel/tutorials-panel.component";
import {CanActivateLoginGuard} from "./login/can-activate-login.guard";
import { DownloadPageComponent } from './download-page/download-page.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [CanActivateLoginGuard]
  },
  {
    path: 'login/:email/:pass',
    component: LoginComponent,
    canActivate: [CanActivateLoginGuard]
  },
  { path: '',   redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'new-password',
    component: NewPasswordComponent,
    canActivate: [NewPasswordGuard]
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'set-new-password',
    component: SetNewPasswordComponent,
  },
  {
    path: 'selection-panel',
    component: WelcomePageComponent,
    canActivate: [AuthService, SmbUnauth]
  },
  {
    path: 'tutorials-panel',
    component: TutorialsPanelComponent,
    canActivate: [AuthService, SmbUnauth],
    canLoad: [AuthService],
  },
  {
    path: 'smb-signup',
    component: SmbSignupComponent,
    pathMatch: 'full'
  },
  {
    path: 'instance-create',
    component: InstanceCreateComponent,
    canActivate: [CanActivateInstanceCreate],
    resolve: { user: SmbUserResolverService }
  },
  {
    path: 'dsp',
    loadChildren:() => import('../../../dsp-webapp/src/app/project.module').then(mod => mod.ProjectModule),
    canActivate: [AuthService, SmbUnauth],
    canLoad: [AuthService]
  },
  {
    path: 'admin-panel',
    loadChildren:() => import('../../../admin-panel/src/app/project.module').then(mod => mod.ProjectModule),
    canActivate: [AuthService, SmbUnauth],
    canLoad: [AuthService]
  },
  {
    path: 'ssp',
    loadChildren:() => import('../../../ssp/src/app/project.module').then(mod => mod.ProjectModule),
    canActivate: [AuthService, SmbUnauth],
    canLoad: [AuthService]
  },
  {
    path: 'smb',
    loadChildren:() => import('../../../smb-app/src/app/project.module').then(mod => mod.ProjectModule),
    canActivate: [SmbAuth],
    canLoad: [AuthService]
  },
  {
    path: 'download',
    component: DownloadPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
  providers: [CanActivateInstanceCreate]
})
export class  AppRoutingModule { }
