import { ComboChartData } from '@dooh/components-library';

export enum PLACEHOLDER {
  CURRENCY = '%CURRENCY%',
  DATEGROUPING = "%DATEGROUPING%"
}

export enum REPORT_TYPE {
  IMPRESSIONS = 'impressions',
  PLAYOUTS = 'playouts',
  REVENUE = 'revenue',
  ECPM = 'ecpm',
  HEATMAP = 'heatmap',
  VENUE_TYPE = 'venueType',
};

export enum DateGrouping {
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
};

export enum ChartType {
  IMP_VS_REVENUE = 'IMP_VS_COST',
  IMP_VS_PLAYOUTS = 'IMP_VS_PLAYOUTS',
  IMP_PER_PUBLISHER = 'IMP_PER_PUBLISHER',
  IMP_PER_CREATIVE = 'IMP_PER_CREATIVE',
  IMP_PER_VENUE_TYPE = 'IMP_PER_VENUE_TYPE',
  PLAYOUTS = 'PLAYOUTS',
  REVENUE= 'SPENDS',
  ECPM = 'ECPM',
  HEATMAP = 'HEATMAP',
};

export enum ImpressionChartType {
  IMP_VS_COST = 'dsp.report.charts.impressionChartTypes.ImpVsCost',
  IMP_VS_PLAYOUTS = 'dsp.report.charts.impressionChartTypes.ImpVsPlayouts',
  IMP_PER_PUBLISHER = 'dsp.report.charts.impressionChartTypes.ImpPerPublisher',
  IMP_PER_CREATIVE = 'dsp.report.charts.impressionChartTypes.ImpPerCreative',
  IMP_PER_VENUE_TYPE = 'dsp.report.charts.impressionChartTypes.ImpPerVenueType',
}

export enum BREAKDOWN {
  SCREEN_IMP_REV = 'dsp.report.charts.breakdowns.impressionsVsRevenue',
  SCREEN_IMP_PLA = 'dsp.report.charts.breakdowns.impressionsVsPlayouts',
  PUBLISHER = 'dsp.report.charts.breakdowns.publisher',
  CREATIVE = 'dsp.report.charts.breakdowns.creative',
  VENUE_TYPE = 'dsp.report.charts.breakdowns.venueType',
};

export enum CHART_LABELS {
  IMPRESSIONS = 'dsp.report.charts.labels.impressions',
  REVENUE = 'dsp.report.charts.labels.revenue',
  REVENUE_IN_DOLLARS = 'dsp.report.charts.labels.revenueInDollars',
  PLAYOUTS = 'dsp.report.charts.labels.playouts',
  ECMP = 'dsp.report.charts.labels.ecpm',
};

export enum ReportEntityType {
  AGENCY = 'AGENCY',
  ADVERTISER = 'ADVERTISER',
  CAMPAIGN = 'CAMPAIGN',
  LINE_ITEM = 'LINE_ITEM',
  SCREEN = 'SCREEN',
  CREATIVE = 'CREATIVE',
  PUBLISHER = 'PUBLISHER',
  VENUE_TYPE = 'VENUE_TYPE',
  HEATMAP = 'HEATMAP',
};

export const fieldNameByType = {
  [ReportEntityType.AGENCY]: 'agencies',
  [ReportEntityType.ADVERTISER]: 'advertisers',
  [ReportEntityType.CAMPAIGN]: 'campaigns',
  [ReportEntityType.LINE_ITEM]: 'lineItems',
  [ReportEntityType.SCREEN]: 'screens',
  [ReportEntityType.CREATIVE]: 'creatives',
  [ReportEntityType.PUBLISHER]: 'publishers',
  [ReportEntityType.VENUE_TYPE]: 'venues',
};

export const GroupByType = {
  [ReportEntityType.AGENCY]: 'Agency',
  [ReportEntityType.ADVERTISER]: 'Advertiser',
  [ReportEntityType.CAMPAIGN]: 'Campaign',
  [ReportEntityType.LINE_ITEM]: 'Line Item',
  [ReportEntityType.SCREEN]: 'Screen',
  [ReportEntityType.CREATIVE]: 'Creative',
  [ReportEntityType.PUBLISHER]: 'Publisher',
  [ReportEntityType.VENUE_TYPE]: 'Venue Type',
};

export const tableSortingNameByType = {
  [ReportEntityType.AGENCY]: 'agency_name',
  [ReportEntityType.ADVERTISER]: 'advertiser_name',
  [ReportEntityType.CAMPAIGN]: 'campaign_name',
  [ReportEntityType.LINE_ITEM]: 'lineItem_name',
  [ReportEntityType.SCREEN]: 'screen_name',
  [ReportEntityType.CREATIVE]: 'creative_name',
  [ReportEntityType.PUBLISHER]: 'publishers',
  [ReportEntityType.VENUE_TYPE]: 'venue_type',
};

export const tableBaseColumnsOrder = [
  ReportEntityType.AGENCY,
  ReportEntityType.ADVERTISER,
  ReportEntityType.CAMPAIGN,
  ReportEntityType.LINE_ITEM,
  ReportEntityType.SCREEN,
  ReportEntityType.CREATIVE,
  ReportEntityType.PUBLISHER,
  ReportEntityType.VENUE_TYPE,
];

export type ChartRequestBody = {
  type: ChartType,
  startDate: string,
  endDate: string,
  dateGrouping: DateGrouping,
  playsVerified?: boolean,
  advertisers?: string[],
  agencies?: string[],
  campaigns?: string[],
  creatives?: string[],
  lineItems?: string[],
  screens?: string[],
  publishers?: string[],
};

export type CardsRequestBody = {
  startDate: string,
  endDate: string,
  playsVerified?: boolean,
  advertisers: string[],
  agencies?: string[],
  campaigns?: string[],
  creatives?: string[],
  lineItems?: string[],
  screens?: string[],
  publishers?: string[],
};

export type ChartResponse = {
  content: {
    id: string,
    externalId: string,
    name: string,
    value: {
      bar: number,
      line: number,
      lat?: number,
      lon?: number,
    }
  }[],
  totalPages: number,
  totalElements: number,
};

export type ChartResponseHeatmapItem = {
  id: string,
  externalId: string,
  name: string,
  value: {
    bar: number,
    line: number,
    lat: number,
    lon: number,
  }
}

export interface HeatMapMarker {
  lat: number;
  lng: number;
  impressions: number;
  playouts: number;
}

export interface HeatMapFeature {
  type: 'Feature';
  geometry: {
    type: 'Point';
    coordinates: number[];
  };
  properties: {
    weight: number;
  };
}

export type CardsResponse = {
  playouts: number,
  impressions: number,
  budgetSpent: number,
  budgetSpentWithMarkup: number,
  ecpm: number,
};

export type ChartsRenderConfig = {
  breakdown: BREAKDOWN,
  chartType: ImpressionChartType,
  data: ComboChartData,
  totalPages: number,
  update: (page?: number) => void,
};
