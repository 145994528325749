import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ControlContainer } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Observable, of } from 'rxjs';

import { SelectedInstanceService } from '@dooh/common-services';
import { ValidationError } from '../..';
import { MoneyFormatter } from '../../../../common-services/src/lib/utils/money-formatter.component';

const ALMOST_CORRECT_NUMBER_PATTERNS = [
  /^(\d+\.\d*[1-9]+)[0]+$/,
  /^(\d+)\.[0]*$/
];

@Component({
  selector: 'dooh-money-field',
  templateUrl: './money-field.component.html',
  styleUrls: ['./money-field.component.scss']
})
export class MoneyFieldComponent implements OnInit {
  public form: FormGroup;
  public control: FormControl;

  @Input()
  controlName: string;

  @Input()
  label: string;

  @Input()
  maxLength?: string | number = 255;

  @Input()
  currency?: string = SelectedInstanceService.getInstanceCurrency();

  @Input()
  errors?: ValidationError[] = [];

  @Input()
  errorStateMatcher?: ErrorStateMatcher;

  @Input()
  hint?: string;

  constructor(private controlContainer: ControlContainer) {
  }

  ngOnInit() {
    this.form = <FormGroup>this.controlContainer.control;
    this.control = <FormControl>this.form.get(this.controlName);
  }

  getError(control): string | Observable<string> {
    const error = this.errors.find((validationError) =>
      control.hasError(validationError.key)
    );

    if (!error) {
      return of('');
    }

    return typeof error.value === 'string' ? of(error.value) : error.value;
  }

  getCrossError(): string | Observable<string> {
    return this.getError(this.form);
  }

  getControlError(): string | Observable<string> {
    return this.getError(this.control);
  }

  formatNumberStr(numStr?: string) {
    if (!Array.isArray(ALMOST_CORRECT_NUMBER_PATTERNS)) {
      return numStr;
    }
    return ALMOST_CORRECT_NUMBER_PATTERNS.reduce(
      (res, curRegEx) => {
        return this.removeUselessParts(curRegEx, res);
      },
      numStr
    );
  }

  removeUselessParts(regex: RegExp, numStr?: string) {
    if (!numStr || !regex || !regex.test(numStr)) {
      return numStr;
    }
    const m = numStr.match(regex);
    return m[1];
  }

  onBlurInput() {
    const formattedValue = this.formatNumberStr(this.control.value);
    this.control.setValue(MoneyFormatter.transform(formattedValue));

  }
}
