import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ScreensService } from 'apps/smb-app/src/app/campaign-management/services/screens.service';
import { forkJoin, Subject } from 'rxjs';
import { retry, takeUntil } from 'rxjs/operators';
import { AudienceStateService } from '../../services/audience-state/audience-state.service';
import { targeting } from '../../targeting-sample-data';
import { BehaviouralTargetingComponent } from '../behavioural-targeting/behavioural-targeting.component';
import { DemographicsComponent } from '../demographics/demographics.component';

export interface AudienceData {
  formData: { categories: any[] };
  selectedCategories: Set<string>;
  reach: any;
}
@Component({
  selector: 'audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.scss'],
})
export class AudienceComponent implements OnInit {
  form: FormGroup;
  demographics: any[];
  targetingData: any[];
  isReachLoading: boolean;
  unSubscriber = new Subject();

  constructor(
    private dialogRef: MatDialogRef<AudienceComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      filterObj: any;
      campaignId: string;
      lineItemId: string;
    },
    private dialog: MatDialog,
    private fb: FormBuilder,
    private screensService: ScreensService,
    private audienceStateService: AudienceStateService,
  ) {}

  partners: string[] = ['Place IQ'];
  partner = 'Place IQ';
  verbs: any[] = [
    { value: 'include', viewValue: 'Include' },
    { value: 'exclude', viewValue: 'Exclude' },
  ];

  selectedCategories: Set<string> = new Set();
  reach = {};
  ngOnInit(): void {
    this.form = this.fb.group({
      categories: this.fb.array([]),
    });

    //SET AGGREGATE DATA
    this.audienceStateService
      .getAudienceAggData()
      .pipe(takeUntil(this.unSubscriber))
      .subscribe((data) => {
        if (data) {
          const [targetingData, demographics] = this.convertAggToTree(data);
          this.targetingData = targetingData;
          this.demographics = demographics;
        }
      });
    //SET DEFAULT STATE IF EXISTS
    this.audienceStateService
      .getAudienceData()
      .pipe(takeUntil(this.unSubscriber))
      .subscribe((data) => {
        if (data) {
          data.formData.categories.forEach(() => {
            this.addCategory();
          });
          this.form.patchValue({
            categories: data.formData.categories,
          });
          this.selectedCategories = data.selectedCategories;
          this.reach = data.reach;
        }
      });
  }
  get categories(): FormArray {
    return this.form.get('categories') as FormArray;
  }

  newCategory(category = ''): FormGroup {
    return this.fb.group({
      category: [category, Validators.required],
      verb: ['include', Validators.required],
      ranges: this.fb.group({
        high: [true],
        mid: [true],
        low: [true],
      }),
    });
  }

  addCategory(category = '') {
    this.categories.push(this.newCategory(category));
  }

  removeCategory(i: number) {
    this.selectedCategories.delete(this.categories.value[i].category);
    this.categories.removeAt(i);
  }

  get verb() {
    return this.form.get('verb');
  }

  closeDialog() {
    this.dialogRef.close();
  }
  onPartnerChange({ value }): void {
    this.partner = value;
  }

  openDemographics(): void {
    this.dialog
      .open(DemographicsComponent, {
        panelClass: 'audience-modal',
        data: {
          checkedData: Array.from(this.selectedCategories),
          demographics: this.demographics,
        },
        height: '100vh',
        width: '100%',
        maxWidth: '360px',
        position: {
          right: '0',
        },
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          data.forEach((value) => {
            if (!this.selectedCategories.has(value)) {
              this.addCategory(value);
              this.selectedCategories.add(value);
            }
          });
          this.fetchReach(data);
        }
      });
  }

  openTargeting(): void {
    this.dialog
      .open(BehaviouralTargetingComponent, {
        panelClass: 'audience-modal',
        data: {
          checkedData: Array.from(this.selectedCategories),
          targetingData: this.targetingData,
        },
        height: '100vh',
        width: '100%',
        maxWidth: '360px',
        position: {
          right: '0',
        },
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          data.forEach((value) => {
            if (!this.selectedCategories.has(value)) {
              this.addCategory(value);
              this.selectedCategories.add(value);
            }
          });
          this.fetchReach(data);
        }
      });
  }

  submit(values) {
    const result = values.categories.reduce(
      (acc, value) => {
        const ranges = Object.keys(value.ranges).reduce((acc, key) => {
          if (!value.ranges[key]) return acc;
          acc.push(key.toLocaleUpperCase());
          return acc;
        }, []);

        if (ranges?.length > 0) {
          const category = {
            category: value.category,
            ranges,
          };
  
          if (value.verb === 'include') {
            acc.include.push(category);
          } else acc.exclude.push(category);
        }

        return acc;
      },
      { include: [], exclude: [] }
    );
    this.dialogRef.close(result);
  }

  fetchReach(selectedCategories: any[]) {
    this.isReachLoading = true;
    const [categoryRequests, placeIqCategories] = selectedCategories.reduce(
      (acc, category) => {
        if (category in this.reach) return acc;
        const filterObj = { ...this.data.filterObj };
        filterObj['placeIqCategory'] = category;
        const request = this.getReach(filterObj);
        acc[0].push(request);
        acc[1].push(category);
        return acc;
      },
      [[], []]
    );

    forkJoin(categoryRequests)
      .pipe(retry(1))
      .subscribe(
        (data) => {
          this.isReachLoading = false;
          placeIqCategories.forEach(
            (val, index) => (this.reach[val] = data[index])
          );
        },
        (err) => {
          this.isReachLoading = false;
        }
      );
  }

  convertAggToTree(agg) {
    const treeObj = agg.reduce(
      (acc, branch) => {
        const values = branch.split('->');
        let parentName = values[0];
        const isDemoGraphics = parentName === 'Demographic';
        const store = isDemoGraphics ? acc[1] : acc[0];
        parentName = isDemoGraphics ? values[1] : parentName;
        const child = isDemoGraphics ? values[2] : values[1];
        if (!child) return acc;

        if (parentName in store) {
          store[parentName].children = store[parentName].children.add(child);
        } else {
          store[parentName] = { name: parentName, children: new Set([child]) };
        }
        return acc;
      },
      [{}, {}]
    );

    return [Object.values(treeObj[0]), Object.values(treeObj[1])];
  }

  getReach(filterObj){
    const campaignId = this.data.campaignId
    const lineItemId = this.data.lineItemId
    
    if(campaignId && lineItemId){
      return this.screensService.getScreenReach(filterObj, campaignId, lineItemId)
    }
    return this.screensService.getScreenReachSMB(filterObj, campaignId);
  }

  resetForm():void{
    this.clearFormArray(this.categories);
    this.selectedCategories = new Set();
    this.reach = {};
    this.audienceStateService.resetAudienceData();
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  ngOnDestroy(): void {
    this.unSubscriber.next();
    this.unSubscriber.complete();
    const audienceData: AudienceData = {
      formData: this.form.value,
      selectedCategories: this.selectedCategories,
      reach: this.reach,
    };

    this.audienceStateService.setAudienceData(audienceData);
  }
}
