export const ROLES = {
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  INSTANCE_ADMIN: 'INSTANCE_ADMIN',
  CAMPAIGN_MANAGER: 'CAMPAIGN_MANAGER',
  BUYER: 'BUYER',
  NO_ROLE: 'NO_ROLE',
  ADVERTISER: "ADVERTISER",
  SMB_MANAGER: "SMB_MANAGER",
  WHITELABEL_ADMIN:"WHITELABEL_ADMIN"
};

export const DEALS_CRUD_ALLOWED_FOR = [
  ROLES.SYSTEM_ADMIN,
  ROLES.INSTANCE_ADMIN,
  ROLES.CAMPAIGN_MANAGER
];

export const SCREEN_CRUD_ALLOWED_FOR = [
  ROLES.SYSTEM_ADMIN,
  ROLES.INSTANCE_ADMIN
];

export enum APPS_TYPE{
  OOH = "OOH",
  SSP = "SSP",
  DSP = "DSP"
}
