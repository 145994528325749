import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  Injector,
  ViewChild,
} from '@angular/core';

import { FormGroup, FormControl } from '@angular/forms';
import {
  TargetingFiltersService,
  MapService,
  TargetingsService,
  SelectedInstanceService,
  PointOfInterestService,
  ActiveOfferService,
} from '@dooh/common-services';
import {
  LocationMap,
  ScreenTagView,
  FilterScreenObject,
  InstanceView,
} from '@dooh/models';
import { DialogService } from '../../../dialogs/dialog.service';
import { ScreensApi } from '../../screens-view.component.models';
import {
  MAP_PAGE_SIZE,
  ScreensParentService,
} from '../../services/screens-parent.service';
import { environment } from '../../../../../../../apps/sso-webapp/src/environments/environment';
import { combineLatest, forkJoin, merge, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ScreensService } from 'apps/smb-app/src/app/campaign-management/services/screens.service';
import { InventoryPerformanceService } from '../../../../../../common-services/src/lib/inventory-performance/inventory-performance.service';
import { MatDialog } from '@angular/material/dialog';
import { AudienceComponent } from 'libs/audience-library/src/lib/components/audience/audience.component';
import { ActivatedRoute } from '@angular/router';
import { DmaDialogComponent } from '../../../dialogs/dma-dialog/dma-dialog.component';
import { DmaStateService } from '../../../services/dma-dialog.service';
import { MapBoxComponent } from '../../../map-box/map-box.component';
import { MarkerMultiScreenTableComponent } from '@dooh/components-library';

const PAGE_OPTIONS = [1000, 2000, 3000];
@Component({
  selector: 'dooh-screens-map',
  templateUrl: './screens-map.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./screens-map.component.scss'],
  outputs: [
    'addedScreensChange',
    'deletedScreensChange',
    'previewScreensChange',
    'cancel',
    'save',
  ],
})
export class ScreensMapComponent
  extends ScreensParentService
  implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  mapSelectedMarkerPerformance: any[] = [];
  screenPerformanceLoading = false;

  // Chart options
  view: any[] = [350, 150];
  showXAxis = true;
  showYAxis = false;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = '';
  showYAxisLabel = true;
  yAxisLabel = '';
  pageOptions = PAGE_OPTIONS;

  colorScheme = {
    domain: ['#2969b0'],
  };

  @Input()
  screensApi: ScreensApi;

  @Input()
  editable: boolean;

  @Input()
  isCreating: boolean;

  @Input()
  isMapView?: boolean;

  @Input()
  isDownloadingCSV?: boolean;

  @Output()
  emitScreensCollection: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  ontoggleView: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onEditClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  componentInit: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  isPaginated?: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @Output()
  onDownloadCsvClicked: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('multiMarkerTable') multiMarkerTable: MarkerMultiScreenTableComponent

  location: LocationMap;
  screenTagSearchField = new FormControl();
  activeScreenTag: ScreenTagView;
  filteredScreens: ScreenTagView[];
  activePoi = false;
  showDma = false;
  showDmaActive = false;
  activeScreenProperties = false;
  isLoading = false;

  filterScreenObject: FilterScreenObject;

  markers: any = [];
  previewScreensMode;
  addedScreensCollection = [];
  allVenueTypes;
  flatVenueType: any;
  showingMapInfo: boolean;
  markerPopUpInfo: any;
  markerType: any;
  showChart: boolean;

  protected unsubscriber$ = new Subject<void>();
  @ViewChild('mapBox') mapBox: MapBoxComponent;

  playerId = environment.isProd
    ? '13bfbc8d-630b-4f3e-b6d7-c1b97321417d'
    : '0824e78d-7479-470d-995b-230c5c70cf11';
  markerTypeMap = {};
  markerGroup: any[] = [];
  showingMarkerGroupTable: boolean;
  constructor(
    public targetingFiltersService: TargetingFiltersService,
    public mapService: MapService,
    public dialogService: DialogService,
    public screenService: TargetingsService,
    private screenApiService: ScreensService,
    injector: Injector,
    private inventoryPerformance: InventoryPerformanceService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public dmaService: DmaStateService,
    private activeOfferService: ActiveOfferService
  ) {
    super(
      mapService,
      targetingFiltersService,
      dialogService,
      injector,
      dmaService
    );
    this.screenService
      .getAllVenueTypes()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.allVenueTypes = res.venueTypes.categories || [];
        this.flatVenueType = this.flattenAllVenueTypes(this.allVenueTypes);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isMapView) {
      this.updateIsMapView(this.isMapView);
    }
    if (changes.editable) {
      this.clearInfo();
      if (this.editable && this.offerConditions) {
        this.previewScreensMode = false;
        this.activePoi = true;
        this.screensConfiguration()
      }
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.screens.add.pageSize = MAP_PAGE_SIZE;
    this.screens.preview.pageSize = MAP_PAGE_SIZE;
    if (this.isCreating) {
      this.editable = true;
      this.previewScreensMode = false;
      if (this.offerConditions) {
        this.activePoi = true;
        this.screensConfiguration()
      }
    }
    this.mapService.markerTypeMap$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((markerTypeMap) => {
        this.markerTypeMap = markerTypeMap;
      });
    this.mapService.removeScreenFromMap$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((screen) => {
        this.screens[this.SCREENS_MAP[this.currentTab]].removeScreenFromMap(
          screen
        );
      });
    this.mapService.addScreenFromMap$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((screen) => {
        this.screens[this.SCREENS_MAP[this.currentTab]].addScreenFromMap(
          screen
        );
      });
    this.filtersForm = new FormGroup({
      screenTagSearchField: new FormControl([]),
    });
    this.mapService.currentSearchField$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        if (this.editable) {
          this.clearInfo();
          if (res?.data) {
            this.filtersForm.get('screenTagSearchField').patchValue(res?.data);
            if (res?.data?.length > 0) {
              if (this.editable) {
                this.showDma = res?.data.some(
                  (_location) => _location.countryCode === 'us'
                );
              }
              this.activePoi = true;
              this.screensConfiguration(null, res?.removeFilter);
            }
          } else {
            this.screenApiService.setComponent(null);
          }
        }
      });

    this.targetingFiltersService.dmaFilters$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        this.dmaIds = res?.dmaId ? res?.dmaId : [];
      });

    this.targetingFiltersService.placeIqFilters$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res: any) => {
        if (res) {
          this.activePoi = true;
          this.filterScreenObject['placeIq'] = res;
          this.getScreens(this.SCREENS.ADD, true, true);
        }
      });

    this.targetingFiltersService.screenPropertiesFilters$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (filters: {
          limits: {
            floorPrice: { min: number; max: number };
            audiencePerWeek: { min: number; max: number };
          };
          tags: Array<{ name: string; properties: string[] }>;
        }) => {
          if (!filters) {
            return;
          }

          const filterTagsMapByName = (filters?.tags || []).reduce(
            (obj, item) => {
              if (!item) {
                return obj;
              }
              obj[item.name] = { ...item };
              return obj;
            },
            {}
          );
          const tagsMapByName = (this.activeScreenTags || []).reduce(
            (obj, item) => {
              if (!item || item.tag === 'Place') {
                return obj;
              }
              const tag = item.tag;
              if (!obj[tag]) {
                obj[tag] = {
                  name: tag,
                  properties: [],
                };
              }
              obj[tag].properties.push(item.id);
              return obj;
            },
            filterTagsMapByName
          );
          if (!this.filterScreenObject) {
            this.filterScreenObject = {
              tags: [],
              pois: null,
            };
          }

          Object.keys(tagsMapByName).forEach((tagName) => {
            const index = this.filterScreenObject?.tags.findIndex(
              (tag) => tag.name === tagName
            );

            if (index === -1) {
              this.filterScreenObject.tags.push(tagsMapByName[tagName]);
            } else {
              this.filterScreenObject.tags[index] = tagsMapByName[tagName];
            }
          });

          if (this.dmaIds?.length > 0) {
            const index = this.filterScreenObject?.tags.findIndex(
              (tag) => tag.name === 'dmaIds'
            );
            if (index === -1) {
              this.filterScreenObject.tags.push({
                name: 'dmaId',
                properties: this.dmaIds,
              });
            } else {
              this.filterScreenObject.tags[index] = {
                name: 'dmaId',
                properties: this.dmaIds,
              };
            }
          }

          this.filterScreenObject.limits = filters.limits;
          this.getScreens(this.SCREENS.ADD, true);
        }
      );

    this.targetingFiltersService.poiFilters$
    .pipe(takeUntil(this.unsubscriber$))
    .subscribe(
      (filters: { type: string; pois: any; radius: number; zipCodes: any }) => {
        if (!filters) {
          return;
        }

        this.targetingFiltersService.resetTagsPages();
        this.filterScreenObject.pois = {
          points: [...filters.pois],
          radius: filters.radius,
        };
        this.filterScreenObject.postalCodes = filters.zipCodes;
        this.getScreens(this.SCREENS.ADD, true, true);
      }
    );

    if (this.filtersForm.get('screenTagSearchField').value.length <= 0) {
      this.getScreens(this.SCREENS.PREVIEW);
    }

    this.mapService
      .getData()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (data: any[]) => {
          // if fetching data for map empty markers on screen first
          if (this.screens.add.isLoading && data.length === 0) {
            this.markers = data;
            return;
          }
          this.isLoading = true;
          this.markers = [];
          this.location = {} as LocationMap;
          const markersHasLocation = data.some((marker) => {
            return marker.location.latitude || marker.location.longitude;
          });
          if (data?.length > 0 && markersHasLocation) {
            this.markers = data;
          } else if (data.length <= 0) {
            this.markers = [];
            const searchFieldPlaces =
              this.activeScreenTags?.filter(
                (fields) => fields.tag === 'Place'
              ) ?? [];
            const lastPlaceSelected =
              searchFieldPlaces[searchFieldPlaces?.length - 1]?.geoCode
                ?.geometry?.location;
            if (lastPlaceSelected) {
              this.location = {} as LocationMap;
              this.location.lat = +lastPlaceSelected?.lat;
              this.location.lng = +lastPlaceSelected?.lng;
              this.location.zoom = 9;
            } else {
              this.moveMapToInstanceCountry();
            }
          } else {
            this.markers = [];
            this.moveMapToInstanceCountry();
          }
          this.isLoading = false;
        },
        () => {
          this.setUserCountryAsDefault();
        }
      );

      this.activeOfferService.selectedOffer$.pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
        this.offerConditions = res?.conditions;
        if (this.isCreating && this.offerConditions) {
          this.previewScreensMode = false;
          this.activePoi = true;
          this.screensConfiguration()
        }
      })


  }

  getDma(): void {
    this.dialog
      .open(DmaDialogComponent, {
        panelClass: 'customize',
        data: {
          dmaId: this.dmaIds,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((resp) => {
        this.showDmaActive = true;
        if (resp && resp.dmaId) {
          this.dmaIds = resp.dmaId;
          const tag = {
            name: 'dmaId',
            properties: this.dmaIds,
          };
          this.filterScreenObject.tags.push(tag);
          this.getScreens(this.SCREENS.ADD, true, true);
        }
        if (resp?.reset) {
          if (this.filterScreenObject.tags.length > 0) {
            this.filterScreenObject.tags = this.filterScreenObject.tags.filter(
              (tag) => tag.name !== 'dmaId'
            );
          }
          this.dmaIds = [];
          this.getScreens(this.SCREENS.ADD, true, true);
        }
      });
  }

  setUserCountryAsDefault(): void {
    navigator.geolocation.getCurrentPosition(
      (resp) => {
        this.location = {} as LocationMap;
        this.location.lat = resp.coords.latitude;
        this.location.lng = resp.coords.longitude;
        this.location.zoom = 10;
      },
      () => {
        this.location = {} as LocationMap;
        this.location.lat = 31.8354533;
        this.location.lng = 35.6674418;
        this.location.zoom = 10;
      }
    );

    this.mapService
      .getData()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        this.location = {} as LocationMap;
        const screen = data[0];
        if (screen) {
          this.location.lat = screen.location.latitude
            ? screen.location.latitude
            : screen.location.lat;
          this.location.lng = screen.location.longitude
            ? screen.location.longitude
            : screen.location.lon;
          this.location.zoom = 4;
          this.markers = data;
        } else {
          if (this.editable || this.isCreating) {
            const searchFieldPlaces =
              this.activeScreenTags?.filter(
                (fields) => fields.tag === 'Place'
              ) ?? [];
            const lastPlaceSelected =
              searchFieldPlaces[searchFieldPlaces?.length - 1]?.geoCode
                ?.geometry?.location;

            if (lastPlaceSelected) {
              this.location = {} as LocationMap;
              this.location.lat = +lastPlaceSelected?.lat;
              this.location.lng = +lastPlaceSelected?.lng;
              this.location.zoom = 9;
            }
          }
          this.markers = [];
        }
        this.isLoading = false;
      });
  }

  onActiveFilters($event) {
    this.activePoi = true;
    this.activeScreenProperties = true;
  }

  ngAfterViewInit() {
    this.instantiateSharedData();
    this.componentInit.emit(true);
    if (this.filtersForm.get('screenTagSearchField').value.length <= 0 && !this.editable) {
      this.getScreens(this.SCREENS.PREVIEW);
    }
    if ((this.editable || this.isCreating) && this.offerConditions) {
      this.clearInfo();
      this.previewScreensMode = false;
      this.activePoi = true;
      this.screensConfiguration()
    }
  }

  afterSave() {
    //this.onReset();
    this.deletedScreens = [];
    this.addedScreens = [];
    this.addedScreensChange.emit(this.addedScreens);
    this.deletedScreensChange.emit(this.deletedScreens);
    this.resetSharedData();
    this.screens.add.data = [];
    this.getScreens(this.SCREENS.PREVIEW, true);
  }

  onFiltersReset() {
    this.screens[this.SCREENS.ADD].selectedItems = [];
  }

  isAddedScreen(screenId: string) {
    return this.markerTypeMap[screenId] === 'added';
  }

  getMarkerId(marker: any) {
    if (marker?.packageId) return marker?.id;
    return marker?.screenId ?? marker?.id;
  }

  addScreen(screen) {
    this.mapService.setAddScreenFromMap(screen);
  }

  deleteScreen(screen) {
    this.mapService.setRemoveScreenFromMap(screen);
  }

  toggleScreens() {
    this.previewScreensMode = this.previewScreensMode ? false : true;

    this.currentTab = this.previewScreensMode ? 0 : 1;
    this.changeTab({ index: this.previewScreensMode ? 0 : 1 });
    this.previewScreensMode ? this.getScreens('preview', true, true) : null;
  }

  openPoi() {
    this.targetingFiltersService.togglePoi(true);
  }

  isSeagulls(id: string) {
    return this.playerId === id;
  }

  ngOnDestroy(): void {
    // this.onReset();
    this.unsubscriber$.next();
    this.unsubscriber$.complete();

    this.mapService.setAddScreenFromMap(null);
    this.mapService.setRemoveScreenFromMap(null);
    this.mapService.setAddScreensonVisibleMap([]);
  }

  toggleView({ checked }): void {
    if (this.editable) {
    }
    this.ontoggleView.emit(checked);
  }

  toggleAllMarkerGroupSelection(event: {type: string}): void {
    const type = event?.type;
    this.markerGroup.forEach(screen => {
      if (type === 'SELECT') {
        if (!this.isAddedScreen(this.getMarkerId(screen)) && !screen?.hasRelation) {
          this.addScreen(screen);
        }
      } else if (type === 'DESELECT') {
        if (this.isAddedScreen(this.getMarkerId(screen)) && !screen?.hasRelation) {
          this.deleteScreen(screen)
        }
      }
    });
  }

  protected moveMapToInstanceCountry(): void {
    const instanceLocation = SelectedInstanceService.getInstance().location;
    if (!instanceLocation) {
      this.setUserCountryAsDefault();
      return;
    }
    this.location = {} as LocationMap;
    this.location.lat = instanceLocation?.lat;
    this.location.lng = instanceLocation?.lng;
    this.location.zoom = 6;
  }

  onPageHandler($event) {
    const data = {
      currentPage: $event.pageIndex + 1,
      pageSize: $event.pageSize,
    };
    this.showingMapInfo = false;
    this.changePage(data);
  }

  openAudience() {
    const campaignId = this.route.parent.snapshot.params['id'];

    this.dialog
      .open(AudienceComponent, {
        panelClass: 'audience-modal',
        data: {
          filterObj: this.filterScreenObject,
          campaignId,
        },
        height: '100vh',
        width: '100%',
        maxWidth: '680px',
        position: {
          right: '0',
        },
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((value) => {
        if (value) {
          this.targetingFiltersService.setPlaceIqFilters(value);
        }
      });
  }

  getVenueTypeName(id) {
    let parent = this.getVenueTypeParentName(id);
    return parent;
  }
  getVenueTypeParentName(id) {
    if (id) {
      if (id.length % 2 == 0) {
        const first2Str = String(id).slice(0, 2);
        const first2Num = Number(first2Str);
        let venueType = this.allVenueTypes.filter((venue) => {
          return venue.enumeration_id === first2Num;
        });
        let venueTypeChild = this.flatVenueType.filter((venue) => {
          return venue.enumeration_id == id;
        });
        if (!venueTypeChild) {
          return `${venueType[0]?.name?.replace(/&amp;/g, '&')}`;
        }

        return `${venueType[0]?.name?.replace(/&amp;/g, '&')}/${
          venueTypeChild[0]?.name
        }`;
      } else if (id.length % 2 !== 0) {
        const firstNum = Number(String(id)[0]);
        let venueType = this.allVenueTypes.filter((venue) => {
          return venue.enumeration_id === firstNum;
        });
        let venueTypeChild2 = this.flatVenueType.filter((venue2) => {
          return venue2.enumeration_id == id;
        });
        if (!venueTypeChild2) {
          return `${venueType[0]?.name?.replace(/&amp;/g, '&')}`;
        }

        return `${venueType[0]?.name?.replace(/&amp;/g, '&')}/${
          venueTypeChild2[0]?.name
        }`;
      }
      return '--';
    }
  }
  flattenAllVenueTypes(members) {
    let children = [];

    return members
      .map((m) => {
        if (m.children && m.children.length) {
          children = [...children, ...m.children];
        }
        return m;
      })
      .concat(children.length ? this.flattenAllVenueTypes(children) : children);
  }

  clearInfo(): void {
    this.showingMapInfo = false;
    this.showingMarkerGroupTable = false;
    this.mapBox?.resizeMap();
    if (!this.editable) this.multiMarkerTable?.onCancelClick();
    // if(this.editable) this.mapBox?.clearMapMarkers();
  }

  backToMarkerTable(): void {
    this.showingMapInfo = false;
    this.showingMarkerGroupTable = true;
    this.mapBox?.resizeMap();
  }

  showMarkerDetails(screenInfo: any): void {
    if (this.markerGroup?.length > 2 ) {
      const groupLocation = this.markerGroup[0]?.location;
      if (
        screenInfo?.marker?.location?.latitude === groupLocation?.latitude &&
        screenInfo?.marker?.location?.longitude === groupLocation?.longitude
        ) {
          this.showingMapInfo = false;
          this.showingMarkerGroupTable = true;
          return;
        }
    }
    const markerGroup = this.markers
      .filter(
        (marker) =>
          marker?.location?.latitude === screenInfo?.marker?.location?.latitude
      )
      .filter(
        (marker) =>
          marker?.location?.longitude ===
          screenInfo?.marker?.location?.longitude
      );
      if (markerGroup?.length > 2) {
        this.markerGroup = markerGroup;
        this.showingMapInfo = false;
        this.showingMarkerGroupTable = true;
        return;
      }
      this.markerGroup = [];

    this.SetScreenInfo(screenInfo);
  }
  SetScreenInfo(screenInfo: any): void {
    this.showingMarkerGroupTable = false;
    this.showingMapInfo = true;
    this.showChart = false;
    setTimeout(() => {
      this.markerPopUpInfo = screenInfo;
      this.setMarkerImage();
      this.mapBox?.resizeMap();
    });
    setTimeout(() => {
      this.showChart = true;
    }, 500);
  }
  setMarkerImage(): void {
    if (!this.markerPopUpInfo?.marker.images) return;

    const {
      sspImages,
      defaultImages,
      salamanderImages,
    } = this.markerPopUpInfo?.marker?.images;

    if (sspImages?.length > 0) {
      this.markerPopUpInfo.marker['screenImages'] = sspImages;
      return;
    }
    if (salamanderImages?.length > 0) {
      this.markerPopUpInfo.marker['screenImages'] = salamanderImages;
      return;
    }
    if (defaultImages?.length > 0) {
      this.markerPopUpInfo.marker['screenImages'] = defaultImages;
      return;
    }
  }

  get images(): string[] {
    const images = [];
    if (this.markerPopUpInfo?.marker?.images?.sspImages) {
      images.push(...this.markerPopUpInfo?.marker?.images?.sspImages)
    }
    if (this.markerPopUpInfo?.marker?.images?.defaultImages) {
      images.push(...this.markerPopUpInfo?.marker?.images?.defaultImages)
    }

    return images;
  }


  toggleScreenFromMap(screen: any): void {
    if (this.isAddedScreen(this.getMarkerId(screen))) {
      this.deleteScreen(screen)
    } else {
      this.addScreen(screen)
    }
  }
}
