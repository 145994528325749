import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, CustomHttpUrlEncodingCodec } from '@dooh/api';
import { AutocompleteService } from '@dooh/components-library';
import { PageDto } from '@dooh/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService implements AutocompleteService{

  constructor(
    private apiService: ApiService
  ) { }

  getAll(page: number = 1, pageSize: number = 50, filters?: string[]):  Observable<PageDto>{
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    queryParameters = queryParameters.set('page', <any>page);
    queryParameters = queryParameters.set('page_size', <any>pageSize);

    if (filters) {
      filters.forEach((element) => {
        queryParameters = queryParameters.append('filters', <any>element);
      });
    }

    return this.apiService.get(`dsp/iab_categories`, queryParameters);
  }
}
