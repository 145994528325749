import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreensViewService {
  private sharedAddedScreen = new BehaviorSubject([]);
  sharedAddedScreen$ = this.sharedAddedScreen.asObservable();
  private sharedDeletedScreen = new BehaviorSubject([]);
  sharedDeletedScreen$ = this.sharedDeletedScreen.asObservable();
  private sharedPackageId = new BehaviorSubject(null);
  sharedPackageId$ = this.sharedPackageId.asObservable();
  private packageToDetach = new BehaviorSubject(null);
  packageToDetach$ = this.packageToDetach.asObservable();

  constructor() { }

  setSharedAddedScreen(adddedScreens):void{
    this.sharedAddedScreen.next(adddedScreens.map(screen=>{
      screen['excluded'] = true;
      return screen;
    }))
  }

  setSharedDeletedScreen(deletedScreens):void{
    this.sharedDeletedScreen.next([...deletedScreens])
  }

  setSharedPackageId(packageId: string) :void {
    this.sharedPackageId.next(packageId);
  }

  setPackageToDetach(relationId: string) :void {
    this.packageToDetach.next(relationId);
  }

  resetSharedData(): void{
    this.setSharedAddedScreen([]);
    this.setSharedDeletedScreen([]);
  }
}
