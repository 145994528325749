<div class="daterange-container" (mouseover)="activeClose()" (mouseleave)="deactiveClose()">
  <mat-form-field appearance="outline" class="date-input-container">
    <mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>
    <mat-label *ngIf="!placeholder">{{ 'rangeDatepicker.excludeDate' | translate }}</mat-label>
    <mat-date-range-input
      [formGroup]="range"
      [rangePicker]="rangepicker"
      [min]="min"
      [max]="max"
      [dateFilter]="filterFunction"
    >
      <input
        readonly
        matInput
        matStartDate
        placeholder="Start date"
        formControlName="begin"
        [value]="initData && initData.begin"

      >
      <input
        readonly
        matInput
        matEndDate
        placeholder="End date"
        formControlName="end"
        [value]="initData && initData.end"
      >
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="rangepicker"></mat-datepicker-toggle>
    <mat-date-range-picker #rangepicker (opened)="updateMinValue()" (closed)="closeRangePicker()"></mat-date-range-picker>
    <mat-error *ngIf="range.get('begin').hasError('required')">
      {{ 'smb.campaignManagement.campaign.tab.budgets.excludeDatesStartDateRequired' | translate }}
    </mat-error>
    <mat-error *ngIf="range.get('end').hasError('required')">
      {{ 'smb.campaignManagement.campaign.tab.budgets.excludeDatesEndDateRequired' | translate }}
    </mat-error>
    <mat-error *ngIf="range.hasError('dateRange')">
      {{ 'smb.campaignManagement.campaign.validationError.dateRangeLess' | translate }}
    </mat-error>
  </mat-form-field>
  <ng-container *ngIf="!dynamicDelete">
    <mat-icon svgIcon="close" class="close-button" *ngIf="closeButton" (mousenter)="activeClose()"
              (click)="remove()"></mat-icon>
  </ng-container>
</div>
