<div
  [ngClass]="{ breadcrumb: true, breadcrumb_link: !!url }"
  *ngIf="!isEditing"
>
  <a *ngIf="url" class="breadcrumb__label" [title]="label" [routerLink]="url">
    {{ label }}
  </a>
  <span *ngIf="!url" class="breadcrumb__label" [title]="label">
    {{ label }}
  </span>
  <span class="breadcrumb__separator"> / </span>
  <button mat-icon-button *ngIf="labelEditable" (click)="startEditing()">
    <mat-icon class="icon">edit_outline</mat-icon>
  </button>
</div>

<div class="edit-input" *ngIf="isEditing">
  <div class="elements">
    <mat-form-field appearance="outline" class="form-field-common">
      <mat-label>{{ editLabel }}</mat-label>
      <input type="text" matInput [formControl]="formControl" class="labelContainer" [readonly]="isLoading" (keyup.enter)="save()"/>
      <mat-error
        *ngIf="showOnlyFirstError('required')
          || showOnlyFirstError('whitespace')"
      >
        This field is required
      </mat-error>
      <mat-error *ngIf="showOnlyFirstError('minlength')">
        Minimum of 3 characters rquired
      </mat-error>
      <mat-error *ngIf="showOnlyFirstError('xregexp')">
        Enter without ",;<>\/ characters
      </mat-error>
    </mat-form-field>
    <mat-spinner color="primary" diameter="20" *ngIf="isLoading"></mat-spinner>
    <button mat-mini-fab color="primary" (click)="save()" [disabled]="!formControl.valid" *ngIf="!isLoading">
      <mat-icon class="icon-fab">done</mat-icon>
    </button>
    <button mat-icon-button color="warn" (click)="cancelEdit()" *ngIf="!isLoading">
      <mat-icon class="icon">clear</mat-icon>
    </button>
  </div>
</div>
