import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { GetDealsResponse } from '@dooh/models';

@Injectable({
  providedIn: 'root'
})
export class CreativesService {

  constructor(private apiService: ApiService) {

  }

  getDeals(): Observable<GetDealsResponse> {
    const queryUrl = `dsp/deals`;

    return this.apiService.get(queryUrl);
  }
}
