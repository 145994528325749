<div class="login-container">
  <div class="login-container__logo">
    <img hidden *ngIf="namespace" [src]="namespace?.url" #logo (load)="onLoadLogo()">
    <div class="logo-container" [ngClass]="{'landscape': isLandscapeLogo}">
      <img *ngIf="namespace" [src]="namespace?.url" class="namespace__logo">
    </div>
  </div>
  <div class="login-container__login login">
    <div class="login__title">{{'login.title' | translate}}</div>
    <div class="login__subtitle" *ngIf="namespace.isEnabled">{{'login.subtitle' | translate}} {{ namespace?.name | translate }} </div>
    <div class="login__subtitle disabled" *ngIf="!namespace.isEnabled">{{ namespace?.name | translate }} {{'welcomePage.namespaceDisabled' | translate}}</div>
    <form class="login__form login-form" [formGroup]="loginForm" >
      <mat-form-field class="login-form__field" appearance="outline">
        <mat-label>{{'login.email' | translate}}</mat-label>
        <input matInput
               formControlName="email"
               name="email"
               placeholder="{{'login.email' | translate}}"
               type="email"
                autocomplete="chrome-off"
               (focus)="errorLogin = ''">
        <mat-error *ngIf="loginForm.controls['email'].hasError('required')">
          {{'login.required' | translate}}
        </mat-error>
        <mat-error *ngIf="loginForm.controls['email'].hasError('pattern')">
          {{'login.emailError' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="login-form__field password__field" appearance="outline">
        <mat-label>{{'login.password' | translate}}</mat-label>
        <input matInput
               formControlName="password"
               name="password"
               placeholder="{{'login.password' | translate}}"
               [type]="hide ? 'password' : 'text'"
               autocomplete="new-password"
               (focus)="errorLogin = ''">
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="loginForm.controls['password'].hasError('required')">
          {{'login.required' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-error class="login-form__api-error" [ngClass]="{'login-form__api-error_visible': errorLogin}">
        {{'login.incorrectData' | translate}}
      </mat-error>
      <span class="login-form_forgotPassoword">
        <a routerLink="/reset-password" routerLinkActive="active">{{ 'login.forgotPassword' | translate }}</a>
      </span>
      <span class="login-form__button">
          <button mat-raised-button
                  color="primary"
                  [disabled]="!loginForm.valid || isLogin"
                  (click)="onSubmit()"
                  class="login-button">
                  <span *ngIf="!isLogin">{{'login.signIn' | translate}}</span>
                  <mat-spinner class="login-button__spinner" *ngIf="isLogin" diameter="20"></mat-spinner>
                </button>
      </span>
      <hr class="line" *ngIf="namespace?.isEnabledSmb === true && (namespace?.domainSmbCustom  || namespace?.domainSmb)">
      <p class="smb-redirect" *ngIf="namespace?.isEnabledSmb === true && (namespace?.domainSmbCustom  || namespace?.domainSmb)">
        <span>SMB. </span>
        Don't have an account?
        <a (click)="redirectToSignup()" routerLinkActive="active">{{ 'login.signup' | translate }}</a>
      </p>
    </form>
  </div>
  <div class="login-footer" *ngIf="namespace?.byNeuron">{{'login.footer' | translate}}</div>
</div>

<div class="version">{{'login.releaseVersion' | translate}}{{version}}</div>
