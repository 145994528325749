<div class="welcome-page-container">
  <div class="welcome-page-button">
    <button class="logout" (click)="logout()">
      <mat-icon class="exp">chevron_right</mat-icon>
      <span>{{ 'welcomePage.logout.text' | translate }}</span>
    </button>
  </div>
  <div class="welcome-page__logo">
    <div class="logo-container" [style.width]="logoWidth">
      <img *ngIf="namespace" [src]="namespace?.url" class="namespace__logo">
    </div>

    <div class="title" [ngClass]="{'mb-60': namespace.isEnabled}">
      <span class="light-blue">{{ 'welcomePage.category.text.title.welcome' | translate }} </span>
      <span class="dark-blue">{{ namespace?.name }}</span>
    </div>
    <div class="disabled-title mb-60" *ngIf="!namespace.isEnabled"> {{ namespace?.name }} {{ 'welcomePage.namespaceDisabled' | translate }} </div>
    <div class="sub-title mb-60">
      <span class="dark-blue">{{ namespace?.slogan }} </span>
    </div>
  </div>
  <div class="welcome-page-content">
    <div class="welcome-page__category category category_long">
      <div class="category__buttons">
        <!-- <ng-container *ngIf="canAccessAdminPanel()">
          <div class="category__link-button" (click)="redirect('admin-panel')">
            <img src="../../../assets/icons/user-icon.svg" alt="">
            <span class="text">{{ 'welcomePage.category.admin.admin' | translate }}</span>
          </div>
        </ng-container> -->
        <ng-container *ngIf="canAccessSSP()">
          <div class="category__link-button" (click)="redirect('ssp')">
            <img src="../../../assets/icons/ico-suitecase-badge.svg" alt="">
            <span class="text">{{ 'welcomePage.category.programmatic.ssp' | translate }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="canAccessDSP()">
          <div class="category__link-button" (click)="redirect('dsp')">
            <img src="../../../assets/images/side-bar-badge.svg" alt="">
            <span class="text">{{ 'welcomePage.category.programmatic.dsp' | translate }}</span>
          </div>
        </ng-container>
        <ng-container >
          <div class="category__link-button" (click)="redirect('tutorials-panel')">
            <mat-icon class="exp">videocam</mat-icon>
            <span class="text">{{ 'welcomePage.category.programmatic.tutorial' | translate }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
