import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'tooltipList' })

export class TooltipListPipe implements PipeTransform {

  transform(lines: string[]): string {

    let list: string = '';
    if(lines){
      lines.map((line, index) => {
          list +=  line['name'] + (index < lines.length ? ', ' : '');
      });
    }

    return list;
  }
}
