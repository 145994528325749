import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { LoginVars } from '../helper/helper';


@Injectable()
export class NewPasswordGuard implements CanActivate{

  constructor(
    private route: Router
  ){}

  canActivate() {
    const challengeName = localStorage.getItem('challengeName');
    if(challengeName === null){
      this.route.navigateByUrl('/');
      return false;

    }else if(challengeName === LoginVars.NEW_PASSWORD){
      return true;
    }else{
      this.route.navigateByUrl('/');
      return false;
    }
  }

}
