<div mat-dialog-title>
  <span>{{ data.heading | translate }}</span>
  <div>
    <button mat-icon-button color="primary" (click)="onCloseClick()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
</div>
<div
  mat-dialog-content
  class="dialog-alert__content"
  [ngClass]="{ 'dialog-alert__content_center': !!data.isCentered }"
>
  <div class="dialog-alert__type">
    <p>
      <b>{{ data.title | translate }}</b>
    </p>
  </div>
  <p
    [innerHTML]="data.message | translate: data.messageI18nArgs || {}"
    *ngIf="data.isHtml"
  ></p>
  <p>
    <input
      #importFileOpen
      type="file"
      class="hidden"
      (change)="setFile($event)"
      accept=".csv"
      [(ngModel)]="excelFile"
    />
  </p>
</div>

<div
  mat-dialog-actions
  class="dialog-custom__buttons-container"
  *ngIf="data.submitButtonText"
>
  <div class="filename" *ngIf="excelFile !== null">
    {{ fileName }}
  </div>
  <div class="dialog-custom__button right">
    <button
      mat-flat-button
      color="primary"
      (click)="importFileOpen.click()"
      *ngIf="excelFile === null"
      cdkFocusInitial
    >
      {{ 'Select File' | translate }}
    </button>
    <button
      mat-flat-button
      color="primary"
      [mat-dialog-close]="true"
      (click)="onSubmitClick()"
      *ngIf="excelFile !== null"
      cdkFocusInitial
    >
      {{ data.submitButtonText | translate }}
    </button>
  </div>
</div>
