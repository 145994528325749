export default function sortDatetimeStrings(dateStrings) {
  if (!Array.isArray(dateStrings)) {
    return [];
  }

  const sortedArr = [...dateStrings];
  let hasUnsorted = true;
  let i = sortedArr.length - 1;
  let tmp;
  while (hasUnsorted) {
    hasUnsorted = false;
    for (let j = sortedArr.length - 1; j >= 1; j--) {
      if (!sortedArr[j] || !sortedArr[j - 1]) {
        continue;
      }
      if (sortedArr[j].localeCompare(sortedArr[j - 1]) < 0) {
        tmp = sortedArr[j];
        sortedArr[j] = sortedArr[j - 1];
        sortedArr[j - 1] = tmp;
        hasUnsorted = true;
      }
    };
    --i;
  };

  return sortedArr;
}
