import {
  Component,
  Input,
  OnChanges,
  OnInit,
  OnDestroy,
  Optional,
  Self,
  Output,
  EventEmitter,
  AfterViewChecked,
  AfterViewInit,
  ViewChild,
  ElementRef,
  TemplateRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  FormControl,
  NgModel,
} from '@angular/forms';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { MatOption } from '@angular/material/core';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatChipList } from '@angular/material/chips';
import { fromEvent, Observable, of, Subscription } from 'rxjs';

import { PageDto } from '@dooh/models';

import {
  AutocompleteService,
  ValidationError,
} from './autocomplete-field.component.models';
import { DealIdService } from 'apps/dsp-webapp/src/app/deal-id-management/services/deal-id.service';

const SEARCH_DELAY = 300;
const PAGE_SIZE = 50;
const TOOLTIP_MIN_LENGTH = 30;

@Component({
  selector: 'dooh-autocomplete-field',
  templateUrl: './autocomplete-field.component.html',
  styleUrls: ['./autocomplete-field.component.scss'],
})
export class AutocompleteFieldComponent
  implements
    ControlValueAccessor,
    OnChanges,
    OnInit,
    OnDestroy,
    AfterViewChecked,
    AfterViewInit {
  formControl = new FormControl();
  foundValues: any[];
  currentValue: any;
  focusedItemIndex: number;
  multiSelection: any[] = [];

  needToFocus = false;

  allowLoadMore = false;
  currentSearchValue: string;

  serviceSubscription: Subscription;

  currentPage: number;
  totalPages: number;
  totalElements: number;

  @Input()
  pageSize: number = PAGE_SIZE;

  @ViewChild('autocomplete')
  autocomplete: MatAutocomplete;

  @ViewChild('autocompletePanel')
  autocompletePanel: MatAutocomplete;

  @ViewChild('autocompleteInput', { read: MatAutocompleteTrigger })
  autocompleteTrigger: MatAutocompleteTrigger;

  @ViewChild('autocompleteInput')
  autocompleteInput: ElementRef;

  @ViewChild('chipList', { read: MatChipList })
  chipList: MatChipList;

  @Input()
  classList?: string;

  @Input()
  isLoading?: boolean;

  @Input()
  hasSelectAll?: boolean;

  @Input()
  selectAll?: boolean;

  @Input()
  label: string;

  @Input()
  searchBy: string;

  @Input()
  searchDelay = SEARCH_DELAY;

  @Input()
  searchMinLength = 0;

  @Input()
  codeSearchBy?: string;

  @Input()
  service: AutocompleteService;

  @Input()
  serviceArgs?: any;

  @Input()
  options?: any[];

  @Input()
  hasMoreOptions? = false;

  @Input()
  extractValue?: (value: any) => string;

  @Input()
  displayValue?: (value: any) => string;

  @Input()
  groupValuesBy: string;

  @Input()
  getGroupSeparator?: (value: any) => string;

  @Input()
  errors?: ValidationError[] = [];

  @Input()
  readonly? = false;

  @Input()
  resetOptionsOnFocus? = false;

  @Output()
  selectedValue: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  allowSearch: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  multi? = false;

  @Input()
  loadDataOnBlur? = true;

  @Input()
  reloadOptionsOnFocus? = false;

  @Input()
  ifSortOptions? = false;

  @Input()
  isNeedMultiSearchButton? = false;

  @Input()
  isNeedMultiResetButton? = false;

  @Input()
  isNeedMultiLoadOnChange? = false;

  @Input()
  itemTemplate?: TemplateRef<any>;

  @Input()
  valuePrimitive: boolean = true;

  @Input()
  maxInputChar: number = 255;

  @Input()
  isSearchString?: boolean;

  writeValue(value: any) {
    if (this.multi) {
      this.writeMultiValue(value);
    }
  }

  onChange(_: any) {}

  onTouched() {}

  constructor(@Optional() @Self() public ngControl: NgControl, public dealIdService:DealIdService) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  onUpdateOptions() {
    this.currentPage =
      Array.isArray(this.options) && this.options.length > 0 ? 2 : 1;
    this.totalElements =
      Array.isArray(this.options) && this.hasMoreOptions
        ? this.options?.length + 1
        : 0;
    this.options = this.sortOptions();
    this.foundValues = Array.isArray(this.options)
      ? this.filterOptions('')
      : [];
  }

  ngOnInit() {
    this.allowSearch.emit(true);
    this.onUpdateOptions();

    if (this.ngControl) {
      this.formControl = this.ngControl.control as FormControl;
    }
    this.formControl.valueChanges
      .pipe(
        debounceTime(this.searchDelay),
        map((value) => {
          if (typeof value === 'object') {
            return;
          }
          if (
            this.multiSelection.length === 0 &&
            (!value || value.length === 0)
          ) {
            this.presetCountry();
          }
          if (
            this.searchMinLength &&
            (!value || value.length < this.searchMinLength)
          ) {
            return;
          }

          this.currentValue = null;
          this.currentPage = 1;
          this.searchFieldValues(value, true);
        })
      )
      .subscribe();
      this.dealIdService.sspId$.subscribe(()=>{
        this.currentPage = 1;
      })
  }

  ngAfterViewInit() {
    /*
     * Internal implementation of the component has built-in scroll logic when you change selected option using keyboard
     * This works bad when dropdown menu contains not only mat-options, but also group labels like we have for timezone autocomplete
     * To scroll to the current option, component calculates top offset using index of the current item, something like index * OPTION_HEIGHT
     * So default component's behaviout doesn't match with our requirements.
     *
     * Probably there are better way to achive it, but it needs some additional investigation.
     */
    // @ts-ignore
    this.autocompleteTrigger._scrollToOption = () => {};

    this.autocomplete._keyManager.change.subscribe((number) => {
      if (number <= 0) {
        this.focusedItemIndex = number;
        return;
      }

      const currentOption = this.autocomplete._keyManager
        .activeItem as MatOption;
      currentOption._getHostElement().scrollIntoView({
        block:
          this.focusedItemIndex < 0 || this.focusedItemIndex === undefined
            ? 'start'
            : 'nearest',
        behavior: 'auto',
      });
      this.focusedItemIndex = number;
    });

    if (this.multi) {
      this.chipList.focus = () => {
        this.autocompleteInput.nativeElement.focus();
      };

      const initialValue = Array.isArray(this.formControl.value)
        ? this.formControl.value
        : [this.formControl.value];
      this.writeMultiValue(initialValue);
      this.formControl.setValue(initialValue);
    }
  }

  ngOnDestroy() {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
  }

  ngAfterViewChecked() {
    if (
      !this.needToFocus ||
      !this.foundValues ||
      !this.foundValues.length ||
      !this.autocomplete.panel
    ) {
      return;
    }

    const autocompletePanel = this.autocomplete.panel.nativeElement;
    const selectedOption = autocompletePanel.querySelector(
      '.autocomplete-field__option_active'
    );

    if (selectedOption) {
      this.needToFocus = false;

      const options = [...autocompletePanel.children].filter((option) => {
        return option.classList.contains('autocomplete-field__option');
      });
      this.setActiveItem(options.indexOf(selectedOption));
    } else {
      this.setActiveItem(0);
      this.needToFocus = false;
    }
  }

  ngOnChanges(changes) {
    if (changes.options) {
      this.onUpdateOptions();
    }
    if(changes.selectAll && this.selectAll && this.isAllSelected() === false){
      this.masterToggle();
    }
  }

  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  _extractValue(value: string | object): string {
    if (!value) {
      return '';
    }

    if (this.extractValue && typeof value !== 'string') {
      return this.extractValue(value);
    }

    if (typeof value === 'string') {
      return value;
    }

    if (value['tag']?.startsWith('targeting') || value['tag']?.startsWith('Package')) {
      return value['name'];
    } else {
      return value[this.searchBy];
    }
  }

  private filterOptions(value: string) {
    if (!this.options) {
      return;
    }
    return this.options.filter((item) => {
      const val = typeof item === 'string' ? item : item[this.searchBy];
      if (!value) {
        return true;
      }
      return val.toLowerCase().indexOf((value as string).toLowerCase()) !== -1;
    });
  }

  private sortOptions() {
    if (!this.options || this.hasMoreOptions || !this.ifSortOptions) {
      return this.options;
    }
    return this.options.sort((a, b) => {
      if (!a) {
        return -1;
      }
      if (!b) {
        return 1;
      }
      return a.localeCompare(b);
    });
  }

  _displayValue(value: string | object): string {
    if (this.displayValue && typeof value !== 'string') {
      return this.displayValue(value);
    }
    return this._extractValue(value);
  }

  searchFieldValues(value: string, refresh?: boolean) {
    if (!!this.options && !this.hasMoreOptions) {
      this.foundValues = this.filterOptions(value);
      return;
    }

    if (
      !value &&
      this.currentSearchValue &&
      this.autocompletePanel.isOpen &&
      !refresh
    ) {
      value = this.currentSearchValue;
    }

    if (
      this.searchMinLength &&
      (!value || value.length < this.searchMinLength)
    ) {
      return;
    }

    if (this.isLoading && this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }

    if (this.totalPages && this.currentPage > this.totalPages) {
      this.isLoading = false;
      return;
    }
    const isNeedReset = !this.options && refresh;
    this.foundValues = isNeedReset ? [] : this.foundValues;
    this.isLoading = true;
    const wholeArrName = !this.options ? 'foundValues' : 'options';

    if (!this.service) {
      throw new Error(
        'Required parameter service was null or undefined while searching for autocomplete.'
      );
    }
    this.serviceSubscription = this.service
      .getAll(
        this.currentPage,
        this.pageSize,
        [
          `${
            this.isSearchString?`${value}`:this.codeSearchBy ? this.codeSearchBy : this.searchBy
          }${this.isSearchString? ``: `,like,%${value}%`}`,
        ],
        this.serviceArgs,
        value,
        this.multiSelection
      )
      .subscribe(
        (data: PageDto) => {
          this[wholeArrName] = Array.from(
            new Set([...this[wholeArrName], ...data.content])
          );
          if (!!this.options) {
            this.options = this.sortOptions();
            this.foundValues = this.filterOptions(value);
          }
          if (data.totalElements === null) {
            if (data.content.length > 0) {
              this.totalElements = this[wholeArrName].length + 1;
            } else {
              this.totalElements = this[wholeArrName].length;
            }
          } else {
            this.totalPages = data?.totalPages;
            this.totalElements = data?.totalElements;
          }
          if (data.content.length !== 0) {
            this.allowLoadMore = true;
          } else {
            this.allowLoadMore = false;
          }
          this.needToFocus = refresh;
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  onLoadMore() {
    if (this.isLoading || !this.allowLoadMore || this.totalPages < 2) {
      return;
    }

    this.currentPage += 1;
    let currentValue = this.formControl.value;
    if (typeof currentValue !== 'string') {
      currentValue = '';
    } else {
      currentValue = this._extractValue(currentValue);
    }
    this.searchFieldValues(currentValue);
  }

  isPanelOpened() {
    setTimeout(() => {
      if (
        this.autocompletePanel &&
        this.autocompleteTrigger &&
        this.autocompletePanel.panel
      ) {
        fromEvent(this.autocompletePanel.panel.nativeElement, 'scroll')
          .pipe(
            debounceTime(300),
            takeUntil(this.autocompleteTrigger.panelClosingActions)
          )
          .subscribe((res) => {
            const scrollTop = this.autocompletePanel.panel.nativeElement
              .scrollTop;
            const scrollHeight = this.autocompletePanel.panel.nativeElement
              .scrollHeight;
            const elementHeight = this.autocompletePanel.panel.nativeElement
              .clientHeight;
            const atBottom =
              Math.trunc(scrollTop) + elementHeight >= scrollHeight - 5;
            if (atBottom) {
              this.onLoadMore();
            }
          });
      }
    });
  }
  onReset($event) {
    this.formControl.markAsDirty();

    this.multiSelection = [];

    this.formControl.setValue(this.multiSelection);
    this.selectedValue.emit(this.multiSelection);
    if (this.isNeedMultiLoadOnChange) {
      this.searchFieldValues('', true);
    }
    this.presetCountry();
  }

  onFocus($event) {
    if(this.readonly){
      return;
    }
    this.formControl.markAsDirty();
    if (
      $event.relatedTarget &&
      ($event.relatedTarget.className.includes('mat-option') ||
        $event.relatedTarget.className.includes('mat-chip'))
    ) {
      return;
    }

    this.currentValue = this.formControl.value;
    this.needToFocus = true;


    if (
      (!Array.isArray(this.options) || !this.options?.length) && (
        !this.readonly && (!Array.isArray(this.foundValues) || !this.foundValues?.length) ||
        this.resetOptionsOnFocus
      ) && !this.foundValues?.length || this.reloadOptionsOnFocus
    ) {
      this.searchFieldValues('', true);
    }

    if (
      this.multiSelection.length === 0 &&
      this.currentSearchValue &&
      this.multi
    ) {
      const input = this.autocompleteInput.nativeElement as HTMLInputElement;
      input.value = this.currentSearchValue;
    }
  }

  onBlur($event) {
    if (
      $event.relatedTarget &&
      $event.relatedTarget.className.includes('mat-option')
    ) {
      return;
    }

    const currentValue = this.formControl.value;

    if (this.multi) {
      this.onBlurMulti($event);
    } else if (typeof currentValue === 'string' && currentValue) {
      this.formControl.setValue('');
    }
  }

  onInput($event: Event) {
    if (this.ngControl instanceof NgModel) {
      this.onChange((<HTMLInputElement>$event.target).value);
    }
  }

  onOptionSelect($event: MatAutocompleteSelectedEvent) {
    if (this.ngControl instanceof NgModel) {
      this.onChange($event.option.value);
    }

    if (!this.multi) {
      this.selectedValue.emit($event.option.value);
      return;
    }

    if (this.isChecked($event.option.value)) {
      this.removeMultiOption($event.option.value);
    } else {
      this.addMultiOption($event.option.value);
    }
    this.afterSelectMultiOption();
  }

  getError(): string | Observable<string> {
    const error = this.errors.find((validationError) =>
      this.formControl.hasError(validationError.key)
    );

    if (!error) {
      return of('');
    }

    return typeof error.value === 'string' ? of(error.value) : error.value;
  }

  isInvalid(): boolean {
    const error = this.errors.find((validationError) =>
      this.formControl.hasError(validationError.key)
    );

    return !!error;
  }

  setActiveItem(index: number) {
    setTimeout(() => this.autocomplete._keyManager.setActiveItem(index));
  }

  toggleMultiOption(event: MatCheckboxChange, option: any) {
    if (event.checked) {
      this.addMultiOption(option);
      this.afterSelectMultiOption();
    } else {
      this.removeMultiOption(option);
      this.afterRemoveMultiOption();
    }
  }

  addMultiOption(option: any) {
    if (option?.tag && option?.tag?.startsWith('targeting')) {
      let index = this.multiSelection.findIndex(item => item?.tag?.startsWith('targeting'));
      index > -1 && this.multiSelection.splice(index, 1);
    }
    this.multiSelection.push(option);

    if (option?.place_id) {
      this.allowSearch.emit(false);
      this.presetCountry();
      this.serviceSubscription = this.service
        .reverseGeocodeFromGoogle(option.place_id)
        .subscribe(
          async (res) => {
            option.geoCode = res[0];
            option.countryCode = this.getCountryCode(res[0])?.countryCode;
            option.country = this.getCountryCode(res[0])?.country;
            option.poi = this.getPlaceLatAndLng(res[0]);
            await this.drawBboxArea(
              option?.geoCode?.geometry?.viewport
            ).toPromise().then(value => {
              option.area = value;
            }).finally(() => {
              this.allowSearch.emit(true);
              this.checkIfSearchAllowed(this.multiSelection);
              this.presetCountry();
            });
          },
          (err: any) => {
            this.allowSearch.emit(false);
            this.checkIfSearchAllowed(this.multiSelection);
          }
        );
    }

    this.formControl.setValue(this.multiSelection);
    this.selectedValue.emit(this.multiSelection);
  }

  doRemoveMultiOption(option: any) {
    this.removeMultiOption(option);
    this.afterRemoveMultiOption();
  }

  removeMultiOption(option: any) {
    this.formControl.markAsDirty();
    if (this.valuePrimitive) {
      this.multiSelection = this.multiSelection.filter(
        (item) => item !== option
      );
    } else {
      this.multiSelection = this.multiSelection.filter(
        (item) => this._extractValue(item) !== this._extractValue(option)
      );
    }

    this.formControl.setValue(this.multiSelection);
    this.selectedValue.emit(this.multiSelection);
    this.presetCountry();
    this.checkIfSearchAllowed(this.multiSelection);
  }

  isChecked(option: any) {
    return this.multiSelection.find(
      (item) => this._extractValue(item) === this._extractValue(option)
    );
  }

  checkBoxStatus(option: any) {
    if (this.valuePrimitive) {
      return this.multiSelection.find((item) => item === option);
    }
    return this.isChecked(option);
  }

  afterSelectMultiOption() {
    const input = this.autocompleteInput.nativeElement as HTMLInputElement;

    if (this.isNeedMultiLoadOnChange || input.value) {
      this.currentSearchValue = '';
      this.searchFieldValues('', true);
    }

    input.value = '';
    if (this.isNeedMultiLoadOnChange) {
      return;
    }
    input.focus();
  }

  afterRemoveMultiOption() {
    if (this.multiSelection.length === 0) {
      const input = this.autocompleteInput.nativeElement as HTMLInputElement;
      input.value = this.currentSearchValue ?? '';
    }
    if (this.isNeedMultiLoadOnChange) {
      this.afterSelectMultiOption();
    }
  }

  writeMultiValue(value: any) {
    if (Array.isArray(value) && this.multiSelection !== value) {
      this.multiSelection = value;
    }
  }

  onBlurMulti(_$event: any) {
    const input = this.autocompleteInput.nativeElement as HTMLInputElement;

    if (input.value) {
      this.currentSearchValue = input.value;
    }
    if (this.loadDataOnBlur) {
      this.searchFieldValues('', true);
      input.value = '';
    }

    this.chipList.errorState = this.isInvalid();

    if (!Array.isArray(this.formControl.value)) {
      this.formControl.setValue(this.multiSelection);
    }
  }

  isNeedMultiResetBlock() {
    return (
      this.multi &&
      this.isNeedMultiResetButton &&
      this.multiSelection &&
      !!this.multiSelection.length
    );
  }

  getCountryCode(item: any) {
    let countryCode = '';
    let country = '';
    const address: any[] = item?.address_components;
    address.forEach((ad) => {
      if (ad?.types.some((item) => item === 'country')) {
        countryCode = ad?.short_name.toLowerCase();
        country = ad?.long_name;
      }
    });
    return { countryCode, country };
  }
  getPlaceLatAndLng(item: any): number[] {
    let poi: number[];
    const geo = item?.geometry?.location;
    poi = [geo?.lng, geo?.lat];
    return poi;
  }

  presetCountry() {
    if (this.multiSelection.length === 0) {
      this.service?.setCountry?.(null);
      return;
    } else {
      const checkIfPlaceSelected = this.multiSelection.find(
        (item) => item?.tag?.toLowerCase() === 'place'
      );
      if (checkIfPlaceSelected) {
        this.service.setCountry?.(checkIfPlaceSelected?.countryCode);
      } else {
        this.service.setCountry?.(null);
      }
    }
  }

  drawBboxArea(bounds: any): Observable<any> {
    let minLatitude, maxLatitude, minLongitude, maxLongitude;
    minLatitude = bounds?.southwest?.lat;
    maxLatitude = bounds?.northeast?.lat;
    minLongitude = bounds?.southwest?.lng;
    maxLongitude = bounds?.northeast?.lng;

    return of({ minLatitude, maxLatitude, minLongitude, maxLongitude });
  }

  checkIfSearchAllowed(options: any[]): void {
    options = options.filter((x) => x.tag === 'Place');

    if (options?.length === 0) {
      this.allowSearch.emit(true);
      return;
    }

    let allowSearch = true;
    options.forEach((item) => {
      if (!item.area) {
        allowSearch = false;
      }
    });

    this.allowSearch.emit(allowSearch);
  }

  checkToShowTooltip(value: string | object): boolean {
    let name;
    if (this.displayValue && typeof value !== 'string') {
      name = this.displayValue(value);
    } else {
      name = this._extractValue(value);
    }

    if (name?.length < TOOLTIP_MIN_LENGTH) {
      return true;
    }
    return false;
  }

  isAllSelected() {
    const wholeArrName = !this.options ? 'foundValues' : 'options';
    const numSelected = this.multiSelection.length;

    const numRows = this[wholeArrName].length;
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    const wholeArrName = !this.options ? 'foundValues' : 'options';
    this.isAllSelected()
      ? (this.multiSelection = [])
      : this[wholeArrName].forEach((row) => this.multiSelection.push(row));

    this.formControl.setValue(this.multiSelection);
    this.selectedValue.emit(this.multiSelection);
  }
}
