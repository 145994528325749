import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {shareReplay, catchError, map} from 'rxjs/operators';

import { ApiService } from '@dooh/api';
import {CustomHttpUrlEncodingCodec, InstanceView, PageDto} from "@dooh/models";
import {HttpParams} from "@angular/common/http";
// tslint:disable-next-line: nx-enforce-module-boundaries
import { AutocompleteService } from '@dooh/components-library';

const API_URL = 'dsp';

@Injectable({
  providedIn: 'root'
})

export class DspInstancesService implements AutocompleteService {
  currentInstance: Observable<InstanceView>;

  constructor(private apiService: ApiService) { }

  getAll(page: number = 1, pageSize: number = 50, filters?: string[]): Observable<PageDto> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    queryParameters = queryParameters.set('page', <any>page);
    queryParameters = queryParameters.set('page_size', <any>pageSize);

    if (filters) {
      filters.forEach((element) => {
        queryParameters = queryParameters.append('filters', <any>element);
      });
    }

    return this.apiService.get(`dsp/instances`, queryParameters);

  }
  getInstances(page: number, pageSize: number, sort?: Array<string>, filters?: Array<string>): Observable<PageDto> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }

    if (sort && sort.length) {
      queryParameters = queryParameters.set('sort', <any>sort.join(';'));
    }

    if (filters && filters.length) {
      queryParameters = queryParameters.set('filters', <any>filters.join(';'));
    }

    return this.apiService.get(`${API_URL}/instances`, queryParameters);
  }

  getCurrentInstance(): Observable<InstanceView> {
    this.currentInstance = this.getInstances(1, 1, ['name,asc'], ['archived,eq,false'])
      .pipe(
        map(result => result.content[0])
      );
    return this.currentInstance;
  }

  getCurrentInstanceCached(): Observable<InstanceView> {
    if (this.currentInstance) {
      return this.currentInstance;
    }

    this.currentInstance = this.getCurrentInstance().pipe(
      shareReplay(1),
      catchError(error => {
        this.currentInstance = undefined;
        throw error
      })
    );
    return this.currentInstance
  }
}
