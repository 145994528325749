import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActiveDateRangeService {
  private activeEndDateSource = new ReplaySubject<string>(1);
  activeEndDate$ = this.activeEndDateSource.asObservable();
  private activeBeginDateSource = new ReplaySubject<string>(1);
  activeBeginDate$ = this.activeBeginDateSource.asObservable();
  private errorCountSource = new ReplaySubject<number>(1);
  errorCount$ = this.errorCountSource.asObservable();

  setActiveEndDate(endDate: string) {
    this.activeEndDateSource.next(endDate);
  }
  setActiveBeginDate(beginDate: string) {
    this.activeBeginDateSource.next(beginDate);
  }
  setErrorCount(errorCount: number) {
    this.errorCountSource.next(errorCount);
  }

}
