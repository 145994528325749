/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface LineItemCreativeView { 
    creativeId?: string;
    hasRelation?: boolean;
    length?: number;
    lineItemId?: string;
    name?: string;
    size?: number;
    status?: Array<string>;
    type?: string;
}
