import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

const KEYWORD_SEARCH_DELAY = 300;
@Component({
  selector: 'behavioural-targeting',
  templateUrl: './behavioural-targeting.component.html',
  styleUrls: ['./behavioural-targeting.component.scss'],
})
export class BehaviouralTargetingComponent implements OnInit {
  venueTypes = [];
  allVenueTypes: any;
  filterdChildren:any;
  demographics: any;
  searchValue: string;
  selection: any;
  keywordSearchTimeout = null;
  filteredList = [];
  step;
  allExpandState:boolean = false;
  constructor(private dialogRef: MatDialogRef<BehaviouralTargetingComponent>, 
    @Inject(MAT_DIALOG_DATA)
    private data: {
      checkedData: string[];
      targetingData: any[]
    }) {}

  ngOnInit(): void {
    this.allVenueTypes = this.data.targetingData;
    this.filteredList = this.data.targetingData;
    const initialSelection = this.data.checkedData;
    const allowMultiSelect = true;
    this.selection = new SelectionModel<any>(
      allowMultiSelect,
      initialSelection
    );
  }

  changeVenueType(event: any): void {
    const allVenueTypeId = [];
    event.forEach((item) => {
      allVenueTypeId.push(item?.id);
    });
  }

  openSelected(indexed: number, step: number): boolean {
    return this.step > indexed ? true : false;
  }

  searchByKeyword() {
    this.allExpandState= true;
    this.step = 0;
    if (this.keywordSearchTimeout) {
      clearTimeout(this.keywordSearchTimeout);
    }

    this.keywordSearchTimeout = setTimeout(() => {
      this.filteredList = [];
      let children;
      this.allVenueTypes.filter((item,i) => {
        children = Object.values({...[...item.children]});
        
        if (item.name.toLowerCase().includes(this.searchValue.toLocaleLowerCase().trim())|| children.filter((res) => {return res.toLowerCase().includes(this.searchValue.toLocaleLowerCase().replace(/\s/g, ""))}).length > 0) {          
          let filter;
          if(item.name.toLowerCase().includes(this.searchValue.toLocaleLowerCase().trim())){
             filter = {
              name: item?.name,
              children: children
            }
          }else{
            filter = {
              name: item?.name,
              children: children.filter((res) => {return res.toLowerCase().includes(this.searchValue.toLocaleLowerCase().replace(/\s/g, ""))})
            }
          }
          
          if(filter.children.length > 0){
            this.filteredList.push(filter);
          }
          
        }
      })
    }, KEYWORD_SEARCH_DELAY);
    
  }

  onKeywordChange() {
    if (
      this.searchValue?.length !== 0 
    ) {
      this.searchByKeyword();
    } else {
      this.filteredList = [];
      Object.keys(this.allVenueTypes).forEach(item => {
        this.filteredList[item] = this.allVenueTypes[item]
      })
    }
  }
 
  closeDialog() {
    this.dialogRef.close();
  }
  submit() {
    const value = this.selection.selected;
    this.dialogRef.close(value);
  }
}
