<div class="navigation-management-container">

  <mat-toolbar class="header-toolbar-wrapper" *ngIf="namespace?.isUiShowNavbar">
    <div class="logo-wrapper">
      <a [routerLink]="homeUrl" class="logo-container">
        <img *ngIf="namespace" [src]="namespace?.url" class="namespace__logo" />
      </a>
      <!-- <dooh-whitelabel-dropdown
        [whiteLabelControl]="whiteLabelControl"
      ></dooh-whitelabel-dropdown> -->

      <dooh-workspace-dropdown
        [application]="application"
        [whiteLabelControl]="whiteLabelControl"
        [workspaceControl]="workspaceControl"
      ></dooh-workspace-dropdown>
    </div>
   <div class="buttons-list">
    <button *ngIf="isNavigation()" mat-button [matMenuTriggerFor]="notifications" mat-icon-button class="example-icon notification-btn" aria-label="Example icon-button with heart icon" (click)="clearCounter()">
      <mat-icon class="notification-icon">notifications_none</mat-icon>
      <div *ngIf="notificationCounter" class="notification" role="status">{{notificationCounter}}</div>
    </button>
    <mat-menu #notifications="matMenu" class="notifications-menu">
      <ng-container *ngIf="notificationitems?.length > 0">
        <li class="list-title"><mat-icon class="notification-icon-title">notifications_none</mat-icon> Notifications</li>
       <ng-template ngFor let-item [ngForOf]="notificationitems" let-i="index">

         <li mat-menu-item *ngIf="i < 5" class="list__item">
           <a (click)="unreadMsg(item?.ids,item?.url,item?.areRead);" class="list__item--link">
            <span class="user-image img-1" *ngIf="item.type === 'CREATIVE_PUBLISHER_DECISION'">
              <mat-icon class="material-icons" svgIcon="creative-decision"></mat-icon>
            </span>
            <span class="user-image img-2" *ngIf="item.type === 'LINE_ITEM_END_DATE_SOON'">
              <mat-icon class="material-icons" svgIcon="end-soon"></mat-icon>
          </span>
          <span class="user-image img-3" *ngIf="item.type === 'LINE_ITEM_START_DATE_SOON'">
              <mat-icon class="material-icons" svgIcon="start-soon"></mat-icon>
          </span>
          <span class="user-image img-4" *ngIf="item.type === 'LINE_ITEM_START_WINNING'">
              <mat-icon class="material-icons" svgIcon="start-winning"></mat-icon>
          </span>
          <span class="user-image img-1" *ngIf="item.type.includes('PACKAGE') || item.type.includes('OFFER')">
              <mat-icon class="material-icons" svgIcon="box"></mat-icon>
          </span>

             <span class="messages">{{formatString(item.text)}}</span>
             <span *ngIf="item?.areRead?.includes(false)" class="unread-icon"></span>
             <span *ngIf="!item?.areRead?.includes(false)" class="read-icon"></span>
           </a>
           <span *ngIf="!!item?.cpdFeedback" class="messages warning-message">{{'Reason: ' + item?.cpdFeedback}}</span>
         </li>
       </ng-template>
       <li>
         <a class="see_more_link" (click)="routeToNotifications();">
           <span>
             See more
           </span>
         </a>
       </li>
      </ng-container>
      <ng-container class="no-info-container" *ngIf="notificationitems?.length == 0" >
        <li class="list-title"><mat-icon class="notification-icon-title">notifications_none</mat-icon> Notifications</li>
       <li class="no-info">
           <span>
            No notifications yet
           </span>
       </li>
      </ng-container>

   </mat-menu>
    <div class= "vertical" *ngIf="isNavigation()"></div>
    <button mat-button [matMenuTriggerFor]="menu" mat-icon-button class="example-icon account-btn-img" aria-label="Example icon-button with share icon" *ngIf="!user?.avatar">
     <mat-icon class="account-icon">person</mat-icon>
   </button>
   <button mat-button [matMenuTriggerFor]="menu" mat-icon-button class="example-icon account-btn-img" aria-label="Example icon-button with share icon" *ngIf="user?.avatar">
     <img alt="{{ user.avatar }}" src="{{ user?.avatar }}" />
   </button>
   <mat-menu #menu="matMenu">
     <button mat-menu-item (click)="routeToPage(settingsUrl)">Settings</button>
     <button mat-menu-item *ngIf="application === 'SMB'" (click)="routeToPage(tutorialUrl)">Tutorials</button>
     <button mat-menu-item (click)="logout()">Sign Out</button>
   </mat-menu>

   </div>

 </mat-toolbar>

  <mat-sidenav-container class="sidenav-container navigation-side-nav" autosize>

    <mat-sidenav #sidenav mode="side" opened="true" disableClose *ngIf="namespace?.isUiShowSidebar">
      <!-- <a [routerLink]="homeUrl" class="logo-container">
        <img *ngIf="namespace" [src]="namespace?.url" class="namespace__logo" />
      </a> -->
    <div class="arrow-wrapper" [ngClass]="
    isOpened
      ? 'opened'
      : 'closed'
  " (click)="toggleMatSideNavState()">
      <i
        [ngClass]="
          isOpened
            ? 'arrow-button arrow-button--prev'
            : 'arrow-button arrow-button--next'
        "
      ></i>
    </div>
      <mat-nav-list class="nav-menu nav-menu_main">
        <ng-container *ngFor="let navManagement of navManagements">
          <ng-container *ngIf="!navManagement.hidden">
            <ng-container
              *ngIf="!navManagement.instanceMenu && !navManagement.disabled"
            >
              <a
                *ngIf="!navManagement.disabled"
                mat-list-item
                routerLinkActive="active"
                [routerLink]="navManagement.url"
              >
                <ng-container
                  *ngTemplateOutlet="
                    navItemContent;
                    context: { navManagement: navManagement }
                  "
                ></ng-container>
              </a>
              <a *ngIf="navManagement.disabled" mat-list-item>
                <ng-container
                  *ngTemplateOutlet="
                    navItemContent;
                    context: { navManagement: navManagement }
                  "
                ></ng-container>
              </a>
            </ng-container>
          </ng-container>
        </ng-container>
      </mat-nav-list>

      <mat-nav-list class="nav-menu nav-menu_secondary">
        <mat-list-item (click)="redirect('tutorials-panel')">
          <mat-icon svgIcon="question" class="nav-admin-icon"></mat-icon>
          <div class="nav-admin-title" *ngIf="isOpened">
            {{ 'Tutorials' | translate }}
          </div>
        </mat-list-item>

        <mat-list-item *ngIf="isInstanceAdmin()" (click)="routeToUserManagement(userManagementUrl)">
          <mat-icon svgIcon="users" class="nav-admin-icon"></mat-icon>
          <div class="nav-admin-title" *ngIf="isOpened">
            {{ 'User Management' | translate }}
          </div>
        </mat-list-item>
        <mat-list-item *ngIf="!isDspAdvertiser()" (click)="routeToPage(settingsUrl)">
          <mat-icon svgIcon="setting" class="nav-admin-icon"></mat-icon>
          <div class="nav-admin-title" *ngIf="isOpened">
            {{ 'Settings' | translate }}
          </div>
        </mat-list-item>
        <mat-list-item (click)="logout()">
          <mat-icon svgIcon="exit" class="nav-admin-icon exit"></mat-icon>
          <div class="nav-admin-title" *ngIf="isOpened">
            {{ 'Exit' | translate }}
          </div>
        </mat-list-item>

      </mat-nav-list>

      <div class="arrow-wrapper" [ngClass]="
      isOpened
        ? 'opened'
        : 'closed'
    "(click)="toggleMatSideNavState()">
        <i
          [ngClass]="
            isOpened
              ? 'arrow-button arrow-button--prev'
              : 'arrow-button arrow-button--next'
          "
        ></i>
      </div>

    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>

  </mat-sidenav-container>
</div>

<ng-template #navItemContent let-navManagement="navManagement">
  <mat-icon
    svgIcon="{{ navManagement.icon }}"
    class="nav-admin-icon"
  ></mat-icon>
  <div class="nav-admin-title" *ngIf="isOpened">
    {{ navManagement.title | translate }}
  </div>
</ng-template>
