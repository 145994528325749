import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActiveNamespaceService } from '@dooh/common-services';
import { NamespaceView } from '@dooh/models';
import { NamespaceService } from 'apps/admin-panel/src/app/namespaces-management/services/namespace.service';

@Component({
  selector: 'dooh-whitelabel-dropdown',
  templateUrl: './whitelabel-dropdown.component.html',
  styleUrls: ['./whitelabel-dropdown.component.scss']
})
export class WhitelabelDropdownComponent implements OnInit {
  @Input() whiteLabelControl = new FormControl();
  whitelabelOptions: NamespaceView[] = [];
  debounceTimer: any;

  constructor(
    private namespaceService: NamespaceService,
    private activeNamespaceService: ActiveNamespaceService,
  ) { }

  ngOnInit() {
    const defaultValue: NamespaceView = ActiveNamespaceService.getNamespace();
    this.whiteLabelControl.setValue(defaultValue || '');
    this.getNamespaces();
  }

  private getNamespaces(): void {
    this.namespaceService.getAll().subscribe({
      next: (response) => {
        this.whitelabelOptions = response.content;
      },
    });
  }

  optionSelected(option: NamespaceView): void {
    this.whiteLabelControl.setValue(option);

    this.activeNamespaceService.setTemporaryNamespace(option);

    this.namespaceService.getNamespaceGeneralInfo(option?.id).subscribe({
      next: (response) => {
        this.activeNamespaceService.setNamespace(response);
      },
    });
  }

  onInput(value: string): void {
    this.debounceTimer = clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      this.searchNamespace(value);
    }, 300);
  }
  
  searchNamespace(value: string): void {
    if (value.length >= 2) {
      this.namespaceService
        .getAll(1, 50, [`name, like, %${value}%`])
        .subscribe({
          next: (resp) => {
            this.whitelabelOptions = resp.content;
          },
        });
    }
    else {
      this.getNamespaces();
    }
  }

}
