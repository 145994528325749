<form
  *ngIf="formGroupMain"
  class="screen-properties__form"
  [formGroup]="formGroupMain"
  (ngSubmit)="filerProperties()"
>
  <fieldset class="screen-properties__fieldset">
    <dooh-autocomplete-with-treeview
      *ngIf="isEnabledVenueTypeFilter"
      class="form-field-common"
      [treeData]="allVenueTypes"
      [venueTypeFilter]="venueTypes"
      [label]="'Venue Types'"
      [searchMinLength]="3"
      (selectedValue)="changeVenueType($event)"
      #venueTree
    ></dooh-autocomplete-with-treeview>

    <dooh-autocomplete-field
      *ngIf="isEnabledPublisherFilter"
      class="form-field-common"
      formControlName="publisher"
      searchBy="publisher"
      [label]="
        !publishers?.length
          ? ('screenProperties.picker.publisher.notExists' | translate)
          : ('screenProperties.picker.publisher.exists' | translate)
      "
      [service]="screenPropertiesAutocompleteService"
      [serviceArgs]="[
        screensFilters,
        SCREEN_TAGS.PUBLISHERS,
        screenPropertiesAutocompleteServiceArgs
      ]"
      [options]="publishers"
      [hasMoreOptions]="tags[SCREEN_TAGS.PUBLISHERS]?.hasMore"
      [multi]="true"
      [ifSortOptions]="true"
      [isLoading]="loadingPublishers"
      [readonly]="
        (!publishers?.length && !activeFilters?.tags?.publisher?.length && !isFilteringByPublisher) ||
        loadingPublishers
      "
      [itemTemplate]="publisherItemTemplate"
      [loadDataOnBlur]="false"
    ></dooh-autocomplete-field>

    <dooh-autocomplete-field
      class="form-field-common"
      formControlName="size"
      searchBy="size"
      [label]="
        !sizes?.length
          ? ('screenProperties.picker.size.notExists' | translate)
          : ('screenProperties.picker.size.exists' | translate)
      "
      [service]="screenPropertiesAutocompleteService"
      [serviceArgs]="[
        screensFilters,
        SCREEN_TAGS.RESOLUTIONS,
        screenPropertiesAutocompleteServiceArgs
      ]"
      [options]="sizes"
      [hasMoreOptions]="tags[SCREEN_TAGS.RESOLUTIONS]?.hasMore"
      [multi]="true"
      [ifSortOptions]="true"
      [isLoading]="loadingResolutions"
      [readonly]="
        (!sizes?.length && !activeFilters?.tags?.size?.length && !isFilteringByResolution) ||
        loadingResolutions
      "
      [loadDataOnBlur]="false"
    ></dooh-autocomplete-field>
    <dooh-autocomplete-field
      class="form-field-common"
      formControlName="mimeType"
      searchBy="mimeType"
      [label]="
        !mimeTypes?.length
          ? ('screenProperties.picker.format.notExists' | translate)
          : ('screenProperties.picker.format.exists' | translate)
      "
      [service]="screenPropertiesAutocompleteService"
      [serviceArgs]="[
        screensFilters,
        SCREEN_TAGS.MIMETYPE,
        screenPropertiesAutocompleteServiceArgs
      ]"
      [options]="mimeTypes"
      [hasMoreOptions]="tags[SCREEN_TAGS.MIMETYPE]?.hasMore"
      [multi]="true"
      [ifSortOptions]="true"
      [isLoading]="loadingMimeTypes"
      [readonly]="
        (!mimeTypes?.length && !activeFilters?.tags?.mimeType?.length && !isFilteringByFormat) ||
        loadingMimeTypes
      "
      [loadDataOnBlur]="false"
    ></dooh-autocomplete-field>
  </fieldset>

  <div class="video-duration" *ngIf="isVideoMimeTypeAvailable()">
    <header>
      <h3 class="screen-properties__title">
        Video Duration
      </h3>
    </header>
    <div class="durations__container">
      <div class="durations">
        <span class="duration" [ngClass]="{ 'selected': item?.isSelected }" *ngFor="let item of videoDurations; let i = index" (click)="toggleDuration(i)"> {{ item?.value }}s</span>
      </div>
      <div class="duration__input">
        <mat-form-field appearance="outline">
          <mat-label>Duration (s)</mat-label>
          <input matInput formControlName="customDurationControl" type="number" min="1" class="no-number-wheel">
          <button mat-icon-button matSuffix type="button" color="primary" (click)="clearCustomDuration(true)" *ngIf="formGroupMain.get('customDurationControl').value">
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="sliders">
    <div
      class="slider-field"
      *ngIf="
        formGroupMain?.value?.sliderAudienceControl &&
        this.limits?.audiencePerWeek?.max - this.limits?.audiencePerWeek?.min > 0
      "
    >
      <header class="slider-field__header">
        <h3 class="screen-properties__title">
          {{ 'screenProperties.slider.title.availableAudience' | translate }}
        </h3>
      </header>
      <div
        class="custom-slider"
        [ngClass]="{ 'custom-slider--dragging': isAudienceDragging }"
      >
        <ng5-slider
          [options]="optionsAudience"
          formControlName="sliderAudienceControl"
          (userChangeStart)="onDrag(SLIDERS.AUDIENCE, true)"
          (userChangeEnd)="onDrag(SLIDERS.AUDIENCE, false)"
        ></ng5-slider>
      </div>
      <div class="range-values-container">
        <div class="range-value">
          <div class="label">
            {{ 'screenProperties.slider.from' | translate }}
          </div>
          <div class="value">
            {{ roundedFigure(formGroupMain?.value?.sliderAudienceControl[0]) }}
          </div>
        </div>
        <div class="range-value range-value_right">
          <div class="label">
            {{ 'screenProperties.slider.to' | translate }}
          </div>
          <div class="value">
            {{ roundedFigure(formGroupMain?.value?.sliderAudienceControl[1]) }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="slider-field"
      *ngIf="
        formGroupMain?.value?.sliderFloorPriceControl &&
        this.limits?.floorPrice?.max - this.limits?.floorPrice?.min > 0
      "
    >
      <header class="slider-field__header">
        <h3 class="screen-properties__title">
          {{ 'screenProperties.slider.title.floorPrice' | translate }}
        </h3>
      </header>
      <div
        class="custom-slider"
        [ngClass]="{ 'custom-slider--dragging': isPriceDragging }"
      >
        <ng5-slider
          [options]="optionsPrice"
          formControlName="sliderFloorPriceControl"
          (userChangeStart)="onDrag(SLIDERS.PRICE, true)"
          (userChangeEnd)="onDrag(SLIDERS.PRICE, false)"
        ></ng5-slider>
      </div>
      <div class="range-values-container">
        <div class="range-value">
          <div class="label">
            {{ 'screenProperties.slider.from' | translate }}
          </div>
          <div class="value">
            {{ roundedFigure(formGroupMain?.value?.sliderFloorPriceControl[0]) }}
          </div>
        </div>
        <div class="range-value range-value_right">
          <div class="label">
            {{ 'screenProperties.slider.to' | translate }}
          </div>
          <div class="value">
            {{ roundedFigure(formGroupMain?.value?.sliderFloorPriceControl[1]) }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer class="button-container">
    <button
      mat-button
      color="primary-inverse"
      class="button-container__button stroked"
      (click)="onReset()"
      type="button"
    >
      {{ 'screenProperties.footer.reset' | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      class="button-container__button"
      type="submit"
      [disabled]="disableFilterButton"
    >
      <span>Apply filter</span>
    </button>
  </footer>
</form>

<ng-template #publisherItemTemplate let-item="item">
  <span *ngIf="!!item" class="publisher-list">
      <span class="publisher-name">{{ item | titlecase }}</span>
  </span>
</ng-template>
