import { Component, Input, OnInit, ViewEncapsulation, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { InstanceForm, NamespaceView, NavManagementItem, UserCredentials, UserView } from '@dooh/models';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActiveNamespaceService, DspInstancesService, SelectedInstanceService, WhoamiService } from '@dooh/common-services';
import { FormControl, FormBuilder } from '@angular/forms';
import { MatSideNavToggleService } from '../services/mat-sidenav-listener.service';
import { ActivatedRoute, Router } from '@angular/router';
import { InstancesService, SmbCreateUserService } from '@dooh/common-services';
import { NotificationChannelIdService } from '../services/notification-channel-id.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

const DSP_DEAL_ID_MANAGEMENT_URL = '/dsp/deal-id-management';
const PREDOOH_URL = 'predooh.theneuron.com';
const PROD_URL = 'dooh.theneuron.com';
const STAGE_URL = 'dooh.staging.theneuron.com';

@Component({
  selector: 'dooh-navigation-management',
  templateUrl: './navigation-management.component.html',
  styleUrls: ['./navigation-management.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavigationManagementComponent implements OnInit, OnDestroy {
  whiteLabelControl = new FormControl();
  workspaceControl = new FormControl();
  private _navManagements: NavManagementItem[] = [];
  isAdmin: boolean;
  isSystemAdmin: boolean;
  @Input() application: 'DSP' | 'SSP' | 'OOH' | 'SMB' | 'ADMIN_PANEL';
  @Input() dspActive?: boolean;
  namespace: NamespaceView;
  namespaceInstanceMenu: boolean;
  user: Partial<UserCredentials>;
  get navManagements(): NavManagementItem[] {
    return this._navManagements;
  }
  @Output() logoutEvent?: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  set navManagements(navManagements: NavManagementItem[]) {
    this.deleteDealIdManagementIfNeed(navManagements);
    this._navManagements = navManagements;
    this.loadIcons(this.navManagements, this.matIconRegistry);
  }

  @Input() homeUrl: string;
  @Input() settingsUrl?: string;
  @Input() notificationsUrl?: string;
  @Input() tutorialUrl?: string;
  @Input() userManagementUrl?: string;
  isOpened = false;

  isShownInstanceMenuBody = false;
  isWhiteLableAdmin = false;
  notificationitems = [];
  isLoading = false;
  isSMBUser: boolean;
  instanceInfo: InstanceForm;
  unsubscriber$ = new Subject<void>();

  unsubscriber = new Subject<void>();
  notificationCounter:number = 0;
  sort: string[] = ['created_at,desc'];
  value: any;
  wsNotifUrl ='';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public intstanceService: DspInstancesService,
    private fb: FormBuilder,
    private router: Router,
    private _MatSideNavToggleService: MatSideNavToggleService,
    private globalNamespaceService: ActiveNamespaceService,
    private instanceService: InstancesService,
    private smbCreateUserService: SmbCreateUserService,
    private notificationChannelIdService: NotificationChannelIdService,
    private route: ActivatedRoute
  ) {
    this.namespace = ActiveNamespaceService.getNamespace();
    this.isSMBUser = this.router.url.includes('smb');
    if(this.isSMBUser){
      this.smbCreateUserService.getSMBUser().pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
        this.user= res;        
      })
      
    }else{
      this.smbCreateUserService.getUserInfo().pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
        this.user= res;
      })
    }
    
    this.globalNamespaceService.activeNamespace
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((res) => {
        this.namespace = res;
      });
      this.smbCreateUserService.profilePicUpload$.pipe(takeUntil(this.unsubscriber$)).subscribe(res=>{
        this.globalNamespaceService.activeNamespace
        .pipe(takeUntil(this.unsubscriber))
        .subscribe((res) => {
          
          if(this.isSMBUser){
            this.smbCreateUserService.getSMBUser().pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
              this.user= res;        
            })
            
          }else{
            this.smbCreateUserService.getUserInfo().pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
              this.user= res;
            })
          }
        });
      })

    if (
      localStorage.getItem('isAdmin') === 'true' ||
      localStorage.getItem('isNamespaceAdmin') === 'true'
    ) {
      this.isAdmin = true;
    }
    if (
      localStorage.getItem('isAdmin') === 'false' &&
      localStorage.getItem('isNamespaceAdmin') === 'true'
    ) {
      this.isWhiteLableAdmin = true;
    }

    if (localStorage.getItem('isAdmin') === 'true') this.isSystemAdmin = true;

    if(this.isNavigation()){
      this.getNotificationChannelId(); 
    }
  }

  ngOnInit() {
    this.isSMBUser = this.router.url.includes('smb');
    if(this.isSMBUser){
      this.smbCreateUserService.getSMBUser().pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
        this.user= res;        
      })
      
    }else{
      this.smbCreateUserService.getUserInfo().pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
        this.user= res;
      })
    }
    this.loadStaticIcons();
  }

  ngOnDestroy(): void {
      this.unsubscriber.next();
      this.unsubscriber.complete();
  }

  formatString(str){
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  deleteDealIdManagementIfNeed(navManagements: NavManagementItem[]): void {
    const even = (navItem, index) =>
      Array.isArray(navManagements) &&
      !!navItem &&
      navItem.url === DSP_DEAL_ID_MANAGEMENT_URL
        ? navManagements.splice(index, 1)
        : null;
    const instanceView = SelectedInstanceService.getInstance();
    if (!!instanceView && instanceView.isSmb && Array.isArray(navManagements)) {
      navManagements.some(even);
    }
  }

  private loadIcons(
    navManagementItems: NavManagementItem[],
    matIconRegistry: MatIconRegistry
  ) {
    for (const navItem of navManagementItems) {
      this.matIconRegistry.addSvgIcon(
        navItem.icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `../assets/images/navigation-management/${navItem.icon}`
        )
      );
    }
  }

  private loadStaticIcons() {
    this.matIconRegistry.addSvgIcon(
      'setting',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `../assets/images/navigation-management/setting-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      'exit',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `../assets/images/navigation-management/exit-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      'info',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `../assets/images/navigation-management/user-icon.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      'creative-decision',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/navigation-management/creative-decision.svg")
      
    );
    this.matIconRegistry.addSvgIcon(
      'end-soon',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/navigation-management/end-soon.svg")
      
    );
    this.matIconRegistry.addSvgIcon(
      'start-soon',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/navigation-management/start-soon.svg")
      
    );
    this.matIconRegistry.addSvgIcon(
      'start-winning',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/navigation-management/start-winning.svg")
      
    );
  }

  logout() {
    this.logoutEvent.emit(true);
  }

  isDspAdvertiser(): boolean {
    return WhoamiService.getCurrentUser().dspRoles[0] === 'ADVERTISER';
  }
  isNavigation(): boolean {    
    if(WhoamiService.getCurrentUser().dspRoles[0] === 'ADVERTISER' || WhoamiService.getCurrentUser().sspRoles[0] === 'INSTANCE_ADMIN' ||  WhoamiService.getCurrentUser().sspRoles[0] === 'CAMPAIGN_MANAGER' || WhoamiService.getCurrentUser().sspRoles[0] === 'BUYER'){
      return false;
    }
    return true;
  }

  isInstanceAdmin(): boolean {
    const isDSPInstanceAdmin = localStorage.getItem('dspRole') === 'INSTANCE_ADMIN';
    const isSSPInstanceAdmin = localStorage.getItem('sspRole') === 'INSTANCE_ADMIN';
    return isDSPInstanceAdmin || isSSPInstanceAdmin;
  }

  toggleMatSideNavState(): void {
    window.dispatchEvent(new Event('resize'));
    this.isOpened = !this.isOpened;
    this._MatSideNavToggleService.setMatSideNavState(this.isOpened);
  }

  public routeToPage(pageName: string): void {
    if (pageName) {
      this.router.navigate([pageName]);
    }
  }
  public routeToUserManagement(pageName: string): void {
    if (pageName) {
      this.router.navigate([pageName]);
    }
  }

  public routeToSettings(): void {
    if (this.settingsUrl) {
      this.router.navigate([this.settingsUrl]);
    }
  }
  public routeToNotifications(): void {
    if (this.notificationsUrl) {
      this.router.navigate([this.notificationsUrl]);
    }
  }

  private checkForNotificationUpdate() {
    var that = this;
    setTimeout(function() {
      that.notificationChannelIdService
        .hasNew().pipe(takeUntil(that.unsubscriber$)).subscribe(result => {
          if (result.hasNew) {
            that.getAllNotifications();
          }
        },
        (err) => {
        });

      that.checkForNotificationUpdate();          
    }, 60000)
  }

  private getNotificationChannelId() {
    if (NotificationChannelIdService.isInited()) {
      this.getAllNotifications();
      this.checkForNotificationUpdate();
    } else {
      this.notificationChannelIdService
        .getNotificationChannelId().pipe(takeUntil(this.unsubscriber$)).subscribe(channelId => {
          NotificationChannelIdService.setChannelId(channelId.uuid);
          this.getAllNotifications();
          this.checkForNotificationUpdate();
        },
        (err) => {
        });
    }
  }

  getAllNotifications(){
    this.notificationChannelIdService
      .getNotifications(this.sort).pipe(takeUntil(this.unsubscriber$)).subscribe(result => {
        this.isLoading = true;
        
        if (result.content) {
          this.notificationitems = result.content;
          this.notificationChannelIdService.getUnseenCount().pipe(takeUntil(this.unsubscriber$)).subscribe((res)=>{
            this.notificationCounter = res.count;
          })
          return;
        }
      },
      (err) => {
      });
  }


  public unreadMsg(ids,url,areRead) {
    let idsForm = {"ids": []};
    if(!areRead.includes(false)){
      if(url.includes('smb')){
        this.router.navigate([url])
      }else if(url.includes('dsp')){
        if (this.router.url.indexOf('line-item-management') > -1) {
          let urlIds = url.split("/"); 
          this.router.navigate(['dsp', 'line-item-management', urlIds[urlIds.length-2], urlIds[urlIds.length-1]]).then(() => {
          window.location.reload();
          });
        }else{
          let urlIds = url.split("/"); 
          this.router.navigate(['dsp', 'line-item-management', urlIds[urlIds.length-2], urlIds[urlIds.length-1]])
        }
        
      }
    }else{
      ids.forEach((id,index) => {
        if(!areRead[index]){
          idsForm.ids.push(id)
        }
      });
      this.notificationChannelIdService
      .unReadNotification(idsForm)
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(result => {
        if(url.includes('smb')){
          this.router.navigate([url])
        }else if(url.includes('dsp')){
          if (this.router.url.indexOf('line-item-management') > -1) {
            let urlIds = url.split("/"); 
            this.router.navigate(['dsp', 'line-item-management', urlIds[urlIds.length-2], urlIds[urlIds.length-1]]).then(() => {
            window.location.reload();
            });
          }else{
            let urlIds = url.split("/"); 
            this.router.navigate(['dsp', 'line-item-management', urlIds[urlIds.length-2], urlIds[urlIds.length-1]])
          }
        }
        this.getAllNotifications();
      })
    }
    
  }
  clearCounter(){
    if(this.notificationCounter > 0){
      this.notificationChannelIdService.postSeenAll().pipe(takeUntil(this.unsubscriber$)).subscribe(()=>{
        this.notificationCounter = 0;
      })
    }
  }

  redirect(pagename: string) {
    window.open('/#/' + pagename, '_blank');  }
}
