<form>
  <mat-form-field
    appearance="outline"
    class="search-field form-field-common"
  >
    <button mat-icon-button matPrefix type="submit" class="color-gray-2">
      <mat-icon svgIcon="search"></mat-icon>
    </button>
    <input
      matInput
      name="name"
      [placeholder]="placeHolderMessage"
      maxlength="255"
      [(ngModel)]="value"
      (ngModelChange)="valueUpdate.next($event)"
    />
    <button
      mat-icon-button
      matSuffix
      type="reset"
      [ngClass]="{'search-field__rest_hidden': !value}"
    >
      <mat-icon svgIcon="reset-value"></mat-icon>
    </button>
  </mat-form-field>
</form>
