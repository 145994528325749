<div class="grid-container">
  <div *ngFor="let adminItem of administratorItems; let i = index">
    <a *ngIf="adminItem.isVisible"
         class="admin-item-container"
         (click)="serveAdminItem(i)" [routerLink]="[adminItem.link]">
      <div class="admin-item-icon">
        <img src="../../../assets/images/{{adminItem.icon}}" alt="">
      </div>
      <div class="admin-item-title">{{adminItem.title}}</div>
    </a>
  </div>
</div>

