<a
  class="tab-info"
  [ngClass]="{'tab-info_active': isActive, 'tab-info_disabled': disabled}"
  [routerLink]="disabled ? '#' : routerLink"
  [queryParams]="queryParams"
  [tabindex]="disabled ? -1 : undefined"
>
  <span
    class="tab-info__icon tab-info-icon"
    [ngClass]="getIconClass()"
  >
    <mat-icon
      class="tab-info-icon__icon"
      [svgIcon]="getIcon()"
    ></mat-icon>
  </span>
  <span class="tab-info__text tab-info-text">
    <span *ngIf="count === null" class="tab-info-text__item tab-info-text__item_title">
      {{ title }}
    </span>
    <span *ngIf="count !== null" class="tab-info-text__item tab-info-text__item_title tab-info-text__item_title-count">
      {{ count }}
    </span>
    <span class="tab-info-text__item tab-info-text__item_description">
      {{ description }}
    </span>
  </span>
</a>
