const ISO_TIME_END_OF_DAY_WITHOUT_MILLISEC = 'T23:59:59';
const ISO_TIME_PREFIX = 'T';


export default function toEndOfDayCorrectTime(datetimeStr: string): any {
  if (!datetimeStr) {
    return null;
  }
  return datetimeStr.split(ISO_TIME_PREFIX)[0] + ISO_TIME_END_OF_DAY_WITHOUT_MILLISEC;
}
