import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TargetingFiltersService } from '@dooh/common-services';
import { DmaStateService } from '../../services/dma-dialog.service';
import { DmaModel } from './dma.model';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'dooh-dma-dialog',
  templateUrl: './dma-dialog.component.html',
  styleUrls: ['./dma-dialog.component.scss']
})
export class DmaDialogComponent implements OnInit, OnDestroy {
  dmaFormGroup: FormGroup;
  dmaUniqueSet = new Set();
  dmaWholeData: DmaModel[] = [];
  public dmaDatasource = new MatTableDataSource<DmaModel>([]);
  loadingDmaItems: boolean;
  loadingFilterResult: boolean;
  filterByFieldSource: any = null;
  unSubscriber$ =  new Subject();
  constructor(
    private dialogRef: MatDialogRef<DmaDialogComponent>,
    private dmaService: DmaStateService,
    private targetingService: TargetingFiltersService,
    @Inject(MAT_DIALOG_DATA) public data: { dmaId: string[] },
    private fb: FormBuilder,
    ) {
      this.dmaFormGroup = this.fb.group({
        dmaFormArray: this.fb.array([])
      })
     }

  ngOnInit(): void {
    if(this.data && this.data.dmaId.length > 0) {
      for(let dma of this.data.dmaId) {
        this.dmaUniqueSet.add(dma);
      }
    }

    this.loadingDmaItems = true;
    this.getDma();
  }

  onCheckboxChange(e: any) {
    if(this.dmaUniqueSet.has(e.target.value)) {
      this.dmaUniqueSet.delete(e.target.value)
    } else {
      this.dmaUniqueSet.add(e.target.value)
    }
  }

  filterDma(searchPhrase: string): void {
    if (searchPhrase.length > 2) {
      this.dmaDatasource.data = this.dmaWholeData.filter(
        dmaData => dmaData.dmaName.toLowerCase().includes(searchPhrase.toLowerCase()));
    } else {
      this.dmaDatasource.data = this.dmaWholeData;
    }
  }

  getDma(): void {
    this.dmaService.getDmaAggData().pipe(takeUntil(this.unSubscriber$)).subscribe(
      (resp: any) => {
        this.dmaDatasource.data = resp.sort( (a: any, b: any) => a.dmaName.localeCompare(b.dmaName));
        this.dmaWholeData = resp.sort( (a: any, b: any) => a.dmaName.localeCompare(b.dmaName));
        this.loadingDmaItems = false;
        this.loadingFilterResult = false;
      },
      (err) => {
        this.loadingDmaItems = false;
        this.loadingFilterResult = false;
      }
    )
  }

  continue(): void {
    this.targetingService.setDmaFilters({dmaId: [...this.dmaUniqueSet]});
    this.dialogRef.close({dmaId: [...this.dmaUniqueSet]});
  }

  closeDialog(): void {
    this.targetingService.setDmaFilters(null);
    this.dialogRef.close({reset: true});
  }

  ngOnDestroy(): void {
    this.unSubscriber$.next();
    this.unSubscriber$.complete();
  }

}
