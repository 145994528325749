import { HttpParams } from '@angular/common/http';

export const QUERY_PARAM_DELIMITER = {
  LOGICAL_AND: ';',
  LOGICAL_OR: '|'
};

// // TODO:: think
// const GetParamOperator = {
//   EQ: 'eq',
//   NOT_EQ: 'Neq',
//   LESS_THEN: 'lt',
//   LESS_THEN_EQ: 'lte',
//   GREATER_THEN: 'gt',
//   GREATER_THEN_EQ: 'gte',
//   LIKE: 'like'
// };

export const QUERY_PARAM_NAME = {
  PAGE: 'page',
  PAGE_SIZE : 'page_size',
  SORT: 'sort',
  FILTER: 'filters'
}

export class QueryParams {
  private static getQueryLogicalOp(op: string, arr?: Array<string>) {
    if (!arr || !arr.length || !op) {
      return '';
    }
    return <any>arr.join(op);
  }

  public static getSort(arr?: Array<string>) {
    return QueryParams.getQueryLogicalOp(QUERY_PARAM_DELIMITER.LOGICAL_AND, arr);
  }

  public static getFilterAnd(arr?: Array<string>) {
    return QueryParams.getQueryLogicalOp(QUERY_PARAM_DELIMITER.LOGICAL_AND, arr);
  }

  public static getFilterOr(arr?: Array<string>) {
    return QueryParams.getQueryLogicalOp(QUERY_PARAM_DELIMITER.LOGICAL_AND, arr);
  }

  public static getHttpParams(
    queryParameters: HttpParams,
    page: number = 1,
    pageSize: number = 50,
    sort?: string[],
    filters?: Array<string>
  ): HttpParams {

    let qp = queryParameters;

    if (page !== undefined && page !== null) {
      qp = qp.set(QUERY_PARAM_NAME.PAGE, <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      qp = qp.set(QUERY_PARAM_NAME.PAGE_SIZE, <any>pageSize);
    }
    if (Array.isArray(sort) && !!sort.length) {
      qp = qp.set(QUERY_PARAM_NAME.SORT, QueryParams.getSort(sort));
//      sort.forEach((element) => {
//        qp = qp.append('sort', <any>element);
//      });
    }
    if (Array.isArray(filters) && !!filters.length) {
      qp = qp.set(QUERY_PARAM_NAME.FILTER, QueryParams.getFilterAnd(filters));
    }

    return qp;
  }
}
