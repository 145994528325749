import { Component, Inject, OnInit } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from "@angular/material/snack-bar";

import { IToastData } from '@dooh/models';

@Component({
  selector: 'dooh-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: IToastData,
    public snackBarRef: MatSnackBarRef<ToastComponent>
  ) {}


  ngOnInit(): void {
  }

}
