import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NamespaceView, User } from '@dooh/models';
import { AuthService, LoginVars } from '@dooh/auth';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { environment } from '../../environments/environment';
import { LoginUserTypeService } from '../services/login-user-type.service';
import { ActiveNamespaceService, SelectedInstanceService, WhoamiService } from '@dooh/common-services';
import { Userpilot } from 'userpilot';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'dooh-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public user: User = new User();

  loginForm: FormGroup;
  version: string;
  bannerOppened = true;
  errorLogin: string;
  isLogin = false;
  namespace: NamespaceView;

  unsubscriber$ = new Subject<void>();
  hide: boolean = true;
  isLandscapeLogo: boolean;

  @ViewChild('logo') logo: ElementRef;
  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private loginUserTypeService: LoginUserTypeService,
    private activatedRoute: ActivatedRoute,
    private activeNamespaceService: ActiveNamespaceService
  ) {
    this.activeNamespaceService
      .loadNamespace()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((value) => {
        this.namespace = value;
      });
  }

    ngOnInit() {
    const email = this.activatedRoute.snapshot.paramMap.get('email');
    const pass = this.activatedRoute.snapshot.paramMap.get('pass');
    this.version = environment.VERSION;

    this.loginForm = this.fb.group({
      email: new FormControl(email ? email : '', [
        Validators.required,
        Validators.pattern(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
        Validators.maxLength(255),
      ]),
      password: new FormControl(pass ? pass : '', [Validators.required]),
    });
  }

  public onSubmit(): void {
    this.user = this.loginForm.getRawValue();
    this.user.email = this.user.email.toLowerCase();
    this.isLogin = true;
    this.authService
      .login(this.user)
      .then((resp) => {    
        if (resp.challengeName === LoginVars.NEW_PASSWORD) {
          localStorage.setItem('username', this.user.email);
          localStorage.setItem('session', resp.Session);
          localStorage.setItem('challengeName', resp.challengeName);
          this.router.navigateByUrl('/new-password');
        } else if (resp.attributes['custom:roles_dsp'] === LoginVars.SMB) {
          this.loginUserTypeService.setAttributes(resp);
          Userpilot.identify(localStorage.getItem('userId'), {
            name: localStorage.getItem('firstName'),
            email: localStorage.getItem('email'),
            dspRole: localStorage.getItem('dspRole'),
          });
          this.router.navigateByUrl('/instance-create');
        }else if (resp.attributes['custom:roles_dsp']) {
          this.loginUserTypeService.setAttributes(resp);
          Userpilot.identify(localStorage.getItem('userId'), {
            name: localStorage.getItem('firstName'),
            email: localStorage.getItem('email'),
            dspRole: localStorage.getItem('dspRole'),
            sspRole: localStorage.getItem('sspRole'),
            isAdmin: localStorage.getItem('isAdmin'),
          });
          this.router.navigateByUrl('/dsp/dashboard');
        } else if (resp.attributes['custom:roles_ssp']) {
          this.loginUserTypeService.setAttributes(resp);
          Userpilot.identify(localStorage.getItem('userId'), {
            name: localStorage.getItem('firstName'),
            email: localStorage.getItem('email'),
            dspRole: localStorage.getItem('dspRole'),
            sspRole: localStorage.getItem('sspRole'),
            isAdmin: localStorage.getItem('isAdmin'),
          });
          this.router.navigateByUrl('/ssp/screens-management');
        } else if (resp.attributes['custom:roles'] && JSON.parse(resp.attributes['custom:roles']).length > 0) {
          localStorage.setItem('instancesWithRoles', resp.attributes['custom:roles']);

          const instancesWithRoles = JSON.parse(resp.attributes['custom:roles']);
          let selectedInstance = instancesWithRoles.find((instance) => instance.instance === SelectedInstanceService.getInstanceId());
          
          if (!selectedInstance) {
            selectedInstance = instancesWithRoles[0];
          }

          if (selectedInstance.dspRole) {
            this.loginUserTypeService.setAttributesMultipleRoles(resp, selectedInstance);
            Userpilot.identify(localStorage.getItem('userId'), {
              name: localStorage.getItem('firstName'),
              email: localStorage.getItem('email'),
              dspRole: localStorage.getItem('dspRole'),
              sspRole: localStorage.getItem('sspRole'),
              isAdmin: localStorage.getItem('isAdmin'),
            });
            this.router.navigateByUrl('/dsp/dashboard');
          }
          else if (selectedInstance.sspRole) {
            this.loginUserTypeService.setAttributesMultipleRoles(resp, selectedInstance);
            Userpilot.identify(localStorage.getItem('userId'), {
              name: localStorage.getItem('firstName'),
              email: localStorage.getItem('email'),
              dspRole: localStorage.getItem('dspRole'),
              sspRole: localStorage.getItem('sspRole'),
              isAdmin: localStorage.getItem('isAdmin'),
            });
            this.router.navigateByUrl('/ssp/screens-management');
          }
          else {
            // not dsp or ssp with multiple roles
          }

        } else {
          this.loginUserTypeService.setAttributes(resp);
          Userpilot.identify(localStorage.getItem('userId'), {
            name: localStorage.getItem('firstName'),
            email: localStorage.getItem('email'),
            dspRole: localStorage.getItem('dspRole'),
            sspRole: localStorage.getItem('sspRole'),
            isAdmin: localStorage.getItem('isAdmin'),
          });
          const currentUser = WhoamiService.getCurrentUser();
          const isDspRole = [currentUser.dspRoles].some(roles => roles.indexOf('CAMPAIGN_MANAGER') !== -1 || roles.indexOf('ADVERTISER') !== -1  );
          if (!currentUser.systemAdmin || currentUser.namespaceAdmin || isDspRole) {
            this.router.navigateByUrl('/dsp/dashboard');
          }else{
            this.router.navigateByUrl('/selection-panel');
          }
        }
      })
      .catch((error) => {
        this.errorLogin = error.message;
        this.isLogin = false;
      })
      .finally(() => WhoamiService.refreshCurrentUser());
  }

  closeBanner() {
    this.bannerOppened = false;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  onLoadLogo() {
    const image = this.logo.nativeElement as HTMLImageElement;
    const width = image?.width;
    const height = image?.height;

    const ratio = width / height;
    if (ratio > 4 / 3) {
      this.isLandscapeLogo = true
    } else {
      this.isLandscapeLogo = false;
    }
  }

  redirectToSignup(): void {
    if (this.namespace?.domainSmbCustom) {
      window.location.href = "https://" + this.namespace?.domainSmbCustom;
    } else if (this.namespace?.domainSmb && !this.namespace?.domainSmbCustom) {      
      window.location.href = "https://" + this.namespace?.domainSmb;
    }
  }

}
