<div class="combo-chart-labels">
  <span>{{yAxisLabelLeft}}</span>
  <span *ngIf="yAxisLabelRight" [style.color]="getAxisYRightLabelColor()">
    {{yAxisLabelRight}}
  </span>
</div>
<div class="combo-chart-wrapper" [style.height.px]="chartHeight">
  <ngx-charts-chart
    class="combo-chart"
    [view]="[width + legendSpacing, height]"
    [showLegend]="showLegend"
    [legendOptions]="legendOptions"
    [activeEntries]="activeEntries"
    [animations]="animations"
    (legendLabelClick)="onClick($event)"
    (legendLabelActivate)="onActivate($event)"
    (legendLabelDeactivate)="onDeactivate($event)"
  >
    <svg:g [attr.transform]="transform" >
      <svg:g
        ngx-charts-x-axis
        *ngIf="xAxis"
        [xScale]="xScale"
        [dims]="dims"
        [showLabel]="showXAxisLabel"
        [labelText]="xAxisLabel"
        [tickFormatting]="xAxisTickFormatting"
        (dimensionsChanged)="updateXAxisHeight($event)"
        [trimTicks]="trimXAxisTicks"
        [maxTickLength]="maxXAxisTickLength"
      ></svg:g>
      <svg:g
        ngx-charts-y-axis
        *ngIf="yAxis"
        [yScale]="yScale"
        [ticks]="axisYLeftTicks"
        [dims]="dims"
        [yOrient]="yOrientLeft"
        [showGridLines]="showGridLines"
        [showLabel]="showLeftYAxisLabel"
        [labelText]="yAxisLabelLeft"
        [tickFormatting]="yAxisTickFormatting || abbreviateTick"
        (dimensionsChanged)="updateYLeftAxisWidth($event)"
      ></svg:g>
      <svg:g
        ngx-charts-y-axis
        *ngIf="yAxis"
        [yScale]="yScaleLine"
        [ticks]="axisYRightTicks"
        [dims]="dims"
        [yOrient]="yOrientRight"
        [showGridLines]="showGridLines"
        [showLabel]="showRightYAxisLabel"
        [labelText]="yAxisLabelRight"
        [tickFormatting]="yRightAxisTickFormatting || abbreviateTick"
        (dimensionsChanged)="updateYRightAxisWidth($event)"
      ></svg:g>
      <svg:g
        *ngFor="let group of results; trackBy: trackBy"
        [attr.transform]="groupTransform(group)"
      >
        <svg:g
          ngx-combo-charts-series-vertical
          [xScale]="xScale"
          [yScale]="yScale"
          [colors]="colors"
          [name]="group.name"
          [series]="group.series"
          [animations]="animations"
          [noBarWhenZero]="noBarWhenZero"
          (select)="onClick($event)"
          (activate)="onActivate($event)"
          (deactivate)="onDeactivate($event)"
          (bandwidth)="updateLineWidth($event)"
          [tooltipTemplate]="tooltipTemplate"
        ></svg:g>
      </svg:g>
    </svg:g>
    <svg:g [attr.transform]="transform" class="line-chart chart">
      <svg:g *ngFor="let group of lineChart; trackBy: trackBy">
        <svg:g
          ngx-charts-line-series
          [xScale]="xScaleLine"
          [yScale]="yScaleLine"
          [colors]="colors"
          [data]="group"
          [curve]="curve"
          [activeEntries]="activeEntries"
          [animations]="animations"
        />
        <svg:g
          ngx-custom-circle-series
          [xScale]="xScaleLine"
          [yScale]="yScaleLine"
          [colors]="colors"
          [combinedSeries]="combinedSeries"
          [name]="group.name"
          [data]="group.series"
          (select)="onClick($event)"
          (activate)="onActivate($event)"
          (deactivate)="onDeactivate($event)"
          [tooltipTemplate]="tooltipTemplate"
        />
      </svg:g>
    </svg:g>
    <ng-template #tooltipTemplate let-model="model">
      <div class="combo-chart-tooltip">
        <div class="combo-chart-tooltip__heading">
          {{model.name}}
        </div>
        <div
          *ngFor="let data of model.data"
          class="combo-chart-tooltip__line"
        >
          <div class="combo-chart-tooltip__line-color" [style]="{background: data.color}"></div>
          <div>
            <span class="combo-chart-tooltip__line-name">{{ data.name | translate | replacePlaceholder: currencyPlaceholder : instanceCurrency }}: </span>
            <span>{{ isCurrencyChart ? (data.value | number: '1.2-2' | money:null | abbreviate:true) : data.value }}</span>
          </div>
        </div>
      </div>
    </ng-template>
  </ngx-charts-chart>
</div>
