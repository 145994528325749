import { Injectable } from '@angular/core';
import { ApiService, CustomHttpUrlEncodingCodec } from '@dooh/api';
import { AutocompleteService } from '@dooh/components-library';
import { Observable } from 'rxjs';
import { PageDto } from '@dooh/models';
import { HttpParams } from '@angular/common/http';
import { SelectedInstanceService } from '../instances/selected-instance.service';

@Injectable({
  providedIn: 'root'
})
export class AdvertiserService implements AutocompleteService {
  sort = ['name,asc'];
  filters = ['is_archived,eq,false'];

  constructor(
    private apiService: ApiService
  ) { }

  getAll(page: number = 1, pageSize: number = 50, filters?: string[]): Observable<PageDto> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    const instanceId = SelectedInstanceService.getInstanceId();

    queryParameters = queryParameters.set('page', <any>page);
    queryParameters = queryParameters.set('page_size', <any>pageSize);
    queryParameters = queryParameters.set('sort', <any>this.sort.join(';'));

    filters = [ ...this.filters, ...(filters && filters.length ? filters : []) ];

    queryParameters = queryParameters.set('filters', <any>filters.join(';'));

    return this.apiService.get(`dsp/instances/${instanceId}/advertisers`, queryParameters);
  }
}
