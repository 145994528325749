/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { DealAdvertiserForm } from '..';

export interface DealForm {
    code?: string;
    name?: string;
    sspId?: string;
    advertiserId?: string;
    campaignId?: string;
    isArchived?: boolean;
    advertisers?: any;
    auctionType?:any,
    marketType?:any,
    dealType?:any,
    floorPrice?:any,
    publisherId?:any
}
