<svg:g *ngFor="let cir of circles">
  <defs>
    <svg:g
      ngx-charts-svg-linear-gradient
      orientation="vertical"
      [name]="gradientId"
      [stops]="cir.gradientStops"
    />
  </defs>
  <svg:rect
    *ngIf="cir.barVisible"
    [@animationState]="'active'"
    [attr.x]="cir.cx - cir.radius"
    [attr.y]="cir.cy"
    [attr.width]="cir.radius * 2"
    [attr.height]="cir.height"
    [attr.fill]="gradientFill"
    class="tooltip-bar"
  />
  <svg:circle
    *ngIf="!cir.barVisible"
    [attr.fill]="'#fff'"
    [attr.cx]="cir.cx"
    [attr.cy]="cir.cy"
    [attr.r]="cir.radius + 2"
  />
  <svg:g
    ngx-charts-circle
    class="circle"
    [cx]="cir.cx"
    [cy]="cir.cy"
    [r]="cir.radius"
    [fill]="cir.color"
    [pointerEvents]="'all'"
    [data]="cir.data"
    [classNames]="cir.classNames"
    (select)="onClick(cir)"
    (activate)="activateCircle(cir)"
    (deactivate)="deactivateCircle(cir)"
    ngx-tooltip
    [tooltipDisabled]="tooltipDisabled"
    [tooltipPlacement]="'right'"
    [tooltipType]="'tooltip'"
    [tooltipTitle]="tooltipTemplate ? undefined : getTooltipText(cir.data)"
    [tooltipTemplate]="tooltipTemplate"
    [tooltipContext]="getTooltipData(cir.data)"
  />
</svg:g>
