/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface InstanceForm {
    countryCode?: string | {code: string, name: string};
    currencyCode?: string | {code: string, name: string};
    timezone?: string | {code: string, name: string, offset: string};
    markup?: number;
    name?: string;
    businessName?: string;
    businessWebsite?: string;
    isArchived?: boolean;
    deactivated?: boolean;
    placeiqDemographyMarkup?: number;
    placeiqLocationMarkup?: number;

}
