/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AudienceTargetingForm { 
    demographics?: Array<AudienceTargetingForm.DemographicsEnum>;
    gender?: AudienceTargetingForm.GenderEnum;
}
export namespace AudienceTargetingForm {
    export type DemographicsEnum = '18-24' | '25-34' | '35-44' | '45-54' | '55-64' | '65+';
    export const DemographicsEnum = {
        _1824: '18-24' as DemographicsEnum,
        _2534: '25-34' as DemographicsEnum,
        _3544: '35-44' as DemographicsEnum,
        _4554: '45-54' as DemographicsEnum,
        _5564: '55-64' as DemographicsEnum,
        _65: '65+' as DemographicsEnum
    };
    export type GenderEnum = 'ALL' | 'MALE' | 'FEMALE';
    export const GenderEnum = {
        ALL: 'ALL' as GenderEnum,
        MALE: 'MALE' as GenderEnum,
        FEMALE: 'FEMALE' as GenderEnum
    };
}
