import { Injectable } from '@angular/core';
import { ApiService } from '@dooh/api';
import { HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '@dooh/models';

@Injectable({
  providedIn: 'root'
})
export class ProposalService {

constructor(
  private apiService: ApiService,
  ) { }

  createProposal(payload: any) {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    const queryUrl = `dsp/media_proposal/dooh`;
    return this.apiService.post(queryUrl,payload,queryParameters);
  }
}
