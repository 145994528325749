<div class="demographics">
  <header class="header">
    <p class="title">Demographics</p>
    <mat-icon svgIcon="close" class="close" (click)="closeDialog()"></mat-icon>
  </header>
  <div class="body">
    <section *ngFor="let demographic of demographics">
      <p class="sub-title">{{demographic.name | pascalCaseToText}}</p>
      <form class="checkbox-container">
        <mat-checkbox
          class="small-radio-button-box"
          color="primary"
          *ngFor="let value of demographic.children"
          [value]="value"
          (change)="$event ? selection.toggle('Demographic'+ '->' + demographic.name + '->' + value) : null"
          [checked]="selection.isSelected('Demographic'+ '->' + demographic.name + '->' + value)"
          >{{ value | pascalCaseToText }}</mat-checkbox
        >
      </form>
    </section>
  </div>
  <footer class="footer">
    <button class="btn btn--outline" (click)="closeDialog()">Cancel</button>
    <button class="btn btn--primary" (click)="submit()">Add</button>
  </footer>
</div>
