import { TemplateRef } from "@angular/core";

export enum MenuType {
  STATUS,
  EDITION,
  ARCHIVE,
  ARC_DEL_ACT,
  DELETE,
  COPY,
  EDIT,
  RESTORE,
  ACT_CANCEL_DEL,
  CAMPAIGN_LIST_SMB,
  DEAL_LIST_DSP
}

export interface StatusColumnProps {
  active?: string;
  inactive?: string;
}

export interface TableColumn {
  name: string;
  heading: string;
  valuePath?: string[];
  id?: string;
  type?: ColumnType;
  icon?: string;
  sortDisabled?: boolean;
  statusColumnProps?: StatusColumnProps;
  valueExtractor?: (element: any) => any;
  columnProps?: any;
  toolTipMessage?: string;
  toolTipIcon?: any;
  showIcon?: (element: any) => false;
  customTemplate?: TemplateRef<any>;
}

export interface ColumnIndexMap {
  [key: string]: number;
}

export enum ColumnType {
  DATE,
  CHECKBOX,
  STRING,
  ACTIVE_STATUS,
  ACTIVE_STATUS_BG,
  TRANSACTION_STATUS,
  APPROVAL_ARCHIVE,
  ICON_TEXT,
  ICON_TOOLTIP,
  MONEY,
  ACTIVE_TOGGLE,
  ACTIVE_TOGGLE_SILENT,
  ROUND_NUMBER,
  BUTTON,
  CHECKBOX_GROUP,
  UNIT_TEXT,
  NUMBER,
  DECIMAL,
  FILE_SIZE,
  DURATION,
  AMOUNT,
  STATUS_PILLS,
  CUSTOM
}

export class ColumnCustomizer {
  columnDef: string;
  headerName: string;
  prop: string[];
  type: ColumnType;
  icon?: string;
  sortDisabled?: boolean;
  statusColumnProps?: StatusColumnProps;
  valueExtractor?: (element: any) => void;
  columnProps?: any;
  toolTipMessage?: string;
  toolTipIcon?: string;
  showIcon?: (element: any) => false;
  customTemplate?: TemplateRef<any>;

  constructor(options: TableColumn) {
    const {
      name,
      heading,
      valuePath,
      valueExtractor,
      type = ColumnType.STRING,
      icon,
      sortDisabled,
      statusColumnProps,
      columnProps,
      toolTipMessage,
      toolTipIcon,
      showIcon,
      customTemplate
    } = options;

    this.columnDef = name;
    this.headerName = heading;
    this.prop = valuePath;
    this.type = type;
    this.icon = icon;
    this.sortDisabled = sortDisabled;
    this.statusColumnProps = statusColumnProps;
    this.valueExtractor = valueExtractor;
    this.columnProps = columnProps;
    this.toolTipIcon = toolTipIcon;
    this.toolTipMessage = toolTipMessage;
    this.showIcon = showIcon;
    this.customTemplate = customTemplate;
  }
}


export enum PillClass {
  GREEN = 'green',
  BLUE = 'blue',
  RED = 'red',
  GREY = 'grey',
  WHITE = 'white',
  GREY_DARK = 'grey-dark',
  GREEN_DARK = 'green-dark',
  YELLOW = 'yellow',
  RED_DARK = 'dark-red',
  RED_LIGHT = 'light-red',
  ORANGE = 'orange',
  BROWN = 'brown',
}
export interface IPillObj{
  class: PillClass;
  value: string;
}