import { Injectable } from '@angular/core';
import { ApiService, CustomHttpUrlEncodingCodec } from '@dooh/api';
import { Observable, from } from 'rxjs';
import { PageDto } from '@dooh/models';
import { HttpParams } from '@angular/common/http';
import { ActiveNamespaceService, SelectedInstanceService } from "@dooh/common-services";
import { ScreensService } from '../../../../smb-app/src/app/campaign-management/services/screens.service';
import { map, mergeMap, retry, switchMap, tap } from 'rxjs/operators';
import { ROLES } from 'apps/ssp/src/app/services/userPermissions/Roles';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class LoginUserTypeService {
  constructor(
    private apiService: ApiService,
    private screensService: ScreensService
  ) {}

  getInstances(filters?: string[]):  Observable<PageDto>{
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (filters) {
      filters.forEach((element) => {
        queryParameters = queryParameters.append('filters', <any>element);
      });
    }

    const isWlAdmin$ = from(Auth.currentSession()).pipe(
      map(session => session.getIdToken().payload['custom:namespace_admin'])
    );

    return isWlAdmin$.pipe(
      switchMap(isWlAdmin => {
        if ((localStorage.getItem('dspRole') === 'undefined' || localStorage.getItem('dspRole') === 'null') && localStorage.getItem('sspRole') !== null && localStorage.getItem('isAdmin') === 'false' && !isWlAdmin) {
          return this.apiService.get(`ssp/instances`, queryParameters);
        }
        else {
          return this.apiService.get(`dsp/instances`, queryParameters);
        }
      })
    );
  }

  setAttributes(resp){
    if(!resp.attributes['custom:sys_admin']){
      localStorage.setItem('isAdmin', 'false');

      localStorage.setItem('dspRole', resp.attributes['custom:roles_dsp']);
      localStorage.setItem('sspRole', resp.attributes['custom:roles_ssp']);
      localStorage.setItem('oohRole', resp.attributes['custom:roles_ooh']);

      localStorage.setItem('agencyId', resp.attributes['custom:agencies']);
    } else {
      localStorage.setItem('isAdmin', 'true')
    }

    localStorage.setItem('email', resp.attributes['email']);
    localStorage.setItem('firstName', resp.attributes['given_name']);
    localStorage.setItem('lastName', resp.attributes['family_name']);
    localStorage.setItem('termsVersion', resp.attributes['custom:agreed_terms_version']);

    localStorage.setItem('userId', resp.attributes['custom:user_id']);
    localStorage.setItem('isNamespaceAdmin', resp.attributes['custom:namespace_admin']);

    let instance;
    this.getInstances()
      .pipe(
        retry(1),
        tap((instances) => {
          if (instances?.content?.[0]) {
            SelectedInstanceService.setInstance(instances.content[0]);
          }
        }),
        map(instanceResult => {
          instance = instanceResult.content[0];
          return instanceResult.content[0]?.country?.name;
        }),
        mergeMap((instanceName: string) =>
          this.screensService.getPlacesFromGoogle(instanceName, null)
            .pipe(
              retry(1),
              map(googleRes => {
                let placeId;
                placeId = googleRes?.predictions.find(result => result.description.toLowerCase() === instanceName.toLowerCase()).place_id;
                return placeId;
              })
            )
        ),
        mergeMap((googlePlaceId: string) =>
          this.screensService.getGooglePlaceInfo(googlePlaceId)
            .pipe(
              retry(1),
              map(googlePlaceRes => {
                let location;
                location = googlePlaceRes?.results[0]?.geometry?.location;
                return location;
              })
            )
        )
      )
      .subscribe(instanceLocation => {
        instance.location = instanceLocation;
        SelectedInstanceService.setInstance(instance);
    })

    if(!resp.attributes['custom:sys_admin'] && !resp.attributes['custom:namespace_admin'] || resp.attributes['custom:roles_dsp'] === ROLES.INSTANCE_ADMIN ){
      localStorage.setItem('isAdmin', 'false');
      localStorage.setItem('isNamespaceAdmin', 'false');

      localStorage.setItem('dspRole', resp.attributes['custom:roles_dsp']);
      localStorage.setItem('sspRole', resp.attributes['custom:roles_ssp']);
      localStorage.setItem('oohRole', resp.attributes['custom:roles_ooh']);

      localStorage.setItem('agencyId', resp.attributes['custom:agencies']);
    } else if (resp.attributes['custom:sys_admin']) {
      localStorage.setItem('isAdmin', 'true');
      localStorage.setItem('isNamespaceAdmin', 'false');
    } else if (resp.attributes['custom:namespace_admin']) {
      localStorage.setItem('isAdmin', 'false');
      localStorage.setItem('isNamespaceAdmin', 'true');
    }
    localStorage.setItem('defaultNamespace', ActiveNamespaceService.getNamespaceId());

    localStorage.setItem('email', resp.attributes['email']);
    localStorage.setItem('firstName', resp.attributes['given_name']);
    localStorage.setItem('lastName', resp.attributes['family_name']);
    localStorage.setItem('termsVersion', resp.attributes['custom:agreed_terms_version']);

    localStorage.setItem('userId', resp.attributes['custom:user_id']);
    this.getInstances().subscribe(instances => {
      if (!localStorage.getItem('instance') || localStorage.getItem('isAdmin') === 'false') {
        SelectedInstanceService.setInstance(instances.content[0]);
      }
    })
  }

  setAttributesMultipleRoles(resp, selectedInstance) {
    if(!resp.attributes['custom:sys_admin']){
      localStorage.setItem('isAdmin', 'false');

      localStorage.setItem('dspRole', selectedInstance.dspRole);
      localStorage.setItem('sspRole', selectedInstance.sspRole);
      localStorage.setItem('oohRole', resp.attributes['custom:roles_ooh']);

      localStorage.setItem('agencyId', selectedInstance.agencies);
    } else {
      localStorage.setItem('isAdmin', 'true')
    }

    localStorage.setItem('email', resp.attributes['email']);
    localStorage.setItem('firstName', resp.attributes['given_name']);
    localStorage.setItem('lastName', resp.attributes['family_name']);
    localStorage.setItem('termsVersion', resp.attributes['custom:agreed_terms_version']);

    localStorage.setItem('userId', resp.attributes['custom:user_id']);
    localStorage.setItem('isNamespaceAdmin', resp.attributes['custom:namespace_admin']);

    let instance;
    this.getInstances()
      .pipe(
        retry(1),
        tap((instances) => {
          let selectedInstanceFullData = instances.content.find((instance) => instance.id === selectedInstance.instance);
          instance = selectedInstanceFullData;
          SelectedInstanceService.setInstance(selectedInstanceFullData);
        }),
        map(instanceResult => {
          return instance.country?.name;
        }),
        mergeMap((instanceName: string) =>
          this.screensService.getPlacesFromGoogle(instanceName, null)
            .pipe(
              retry(1),
              map(googleRes => {
                let placeId;
                placeId = googleRes?.predictions.find(result => result.description.toLowerCase() === instanceName.toLowerCase()).place_id;
                return placeId;
              })
            )
        ),
        mergeMap((googlePlaceId: string) =>
          this.screensService.getGooglePlaceInfo(googlePlaceId)
            .pipe(
              retry(1),
              map(googlePlaceRes => {
                let location;
                location = googlePlaceRes?.results[0]?.geometry?.location;
                return location;
              })
            )
        )
      )
      .subscribe(instanceLocation => {
        instance.location = instanceLocation;
        SelectedInstanceService.setInstance(instance);
    })

    if(!resp.attributes['custom:sys_admin'] && !resp.attributes['custom:namespace_admin'] || resp.attributes['custom:roles_dsp'] === ROLES.INSTANCE_ADMIN ){
      localStorage.setItem('isAdmin', 'false');
      localStorage.setItem('isNamespaceAdmin', 'false');

      localStorage.setItem('dspRole', selectedInstance.dspRole);
      localStorage.setItem('sspRole', selectedInstance.sspRole);
      localStorage.setItem('oohRole', resp.attributes['custom:roles_ooh']);

      localStorage.setItem('agencyId', selectedInstance.agencies);
    } else if (resp.attributes['custom:sys_admin']) {
      localStorage.setItem('isAdmin', 'true');
      localStorage.setItem('isNamespaceAdmin', 'false');
    } else if (resp.attributes['custom:namespace_admin']) {
      localStorage.setItem('isAdmin', 'false');
      localStorage.setItem('isNamespaceAdmin', 'true');
    }
    localStorage.setItem('defaultNamespace', ActiveNamespaceService.getNamespaceId());

    localStorage.setItem('email', resp.attributes['email']);
    localStorage.setItem('firstName', resp.attributes['given_name']);
    localStorage.setItem('lastName', resp.attributes['family_name']);
    localStorage.setItem('termsVersion', resp.attributes['custom:agreed_terms_version']);

    localStorage.setItem('userId', resp.attributes['custom:user_id']);
    this.getInstances().subscribe(instances => {
      if (!localStorage.getItem('instance') || localStorage.getItem('isAdmin') === 'false') {
        SelectedInstanceService.setInstance(instances.content[0]);
      }
    })
  }
}
