<table>
  <tbody>
    <tr *ngFor="let item of days | keyvalue: originalOrder">
      <td>
        {{item.key | titlecase}}
      </td>
      <td *ngFor="let index of [0,1,2,3]">
        <span class="divider"></span>
        <span *ngIf="item.value[index]">
          <ng-container *ngIf="isBothNotEmpty(item.value[index])">
            {{ item.value[index].startTime.split(':')[0] + ":" + item.value[index].startTime.split(':')[1] }}
            {{ 'hourRangeEditTable.to' | translate }}
            {{ item.value[index].endTime.split(':')[0] + ":" + item.value[index].endTime.split(':')[1] }}
          </ng-container>
        </span>
      </td>
    </tr>
  </tbody>
</table>
