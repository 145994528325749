<div class="wrapper">
    <div class="header">
        <div class="titles">
            <h4 class="title">{{ placeName }}</h4>
            <p class="sub-title">{{ placeAddress }}</p>
        </div>

        <div class="close-button">
            <button
            mat-icon-button
            color="primary"
            (click)="closeTable()"
            class="action-button"
          >
            <mat-icon svgIcon="close"></mat-icon>
          </button>
        </div>
    </div>
    <div class="screen-count">
      <p>
        Screens <span class="pill">{{ markers?.length | number }}</span>
      </p>
      
    </div>
  <div class="table-container" [ngClass]="{'shorter': editable}">
    <dooh-generic-table 
    *ngIf="!resetTable"
      class="child-table"
      [dataSource]="screens"
      [tableCustomizer]="tableCustomizer"
      [selectedElements]="selectedElements.asObservable()"
      [disablingElements]="disablingElements.asObservable()"
      [isLoading]="isLoading"
      (routingElement)="triggerAction($event)"
      (header)="sort($event)"
      (item)="toggleSelection($event)"
      (selectAll)="selectAllMaster($event)"
    >
    </dooh-generic-table>
  </div>
</div>
<ng-template #formats let-data="data">
  <dooh-screens-formats [formats]="data"></dooh-screens-formats>
</ng-template>
