export function getDateOfISOStartWeek(year: number, week: number): Date {
  const simple = new Date(year, 0, 1 + (week - 1) * 7);
  const dow = simple.getDay();
  const ISOweekStart = simple;
  if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
}

export function getDateOfISOEndWeek(year: number, week: number): Date {
  const _date = getDateOfISOStartWeek(year, week);
  _date.setDate(_date.getDate() + 6);
  return _date;
}
