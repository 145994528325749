import { Injectable } from '@angular/core';
import { ApiService } from '@dooh/api';
import { ScreenPropertiesAutocompleteService } from '@dooh/components-library';
import { Observable } from 'rxjs';
import { PageDto } from '@dooh/models';

@Injectable({
  providedIn: 'root'
})
export class ScreenPropertiesAutocompleteLineItemService extends ScreenPropertiesAutocompleteService {
  public static apiUrl = "dsp";

  constructor(private apiService: ApiService) {
    super();
  }

  getApiService(): ApiService {
    return this.apiService;
  }

  getUrl(urlArgsArr?: any): string {
    const campaignId: string = Array.isArray(urlArgsArr) ? urlArgsArr[0] : null;
    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getAvailableScreens.');
    }

    const lineItemId: string = Array.isArray(urlArgsArr) ? urlArgsArr[1] : null;
    if (lineItemId === null || lineItemId === undefined) {
      throw new Error('Required parameter lineItemId was null or undefined when calling getAvailableScreens.');
    }

    const cId = encodeURIComponent(campaignId);
    const liId = encodeURIComponent(lineItemId);
    return `${ScreenPropertiesAutocompleteLineItemService.apiUrl}/screens/campaigns/${cId}/line_items/${liId}/available_screens`;
  }
}
