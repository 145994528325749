<div class="login-container">
  <div class="logo-container">
    <img *ngIf="namespace" [src]="namespace?.url" class="namespace__logo">
  </div>
  <div class="card"  [ngClass]="{'large-message-container': setPassword.get('password').hasError('pattern')  || setPassword.get('passwordAgain').hasError('pattern')} ">
      <div class="login-title">{{ 'login.setNewPassword.title' | translate}}</div>
      <form [formGroup]="setPassword">

        <mat-form-field appearance="outline">
          <mat-label>{{ 'login.setNewPassword.code' | translate}}</mat-label>
          <input matInput
                formControlName="code"
                 name="code"
                 placeholder="{{ 'login.setNewPassword.code' | translate}}"
                 type="string">
          <mat-error *ngIf="setPassword.get('code').hasError('required')">
            {{'login.required' | translate}}
          </mat-error>
      </mat-form-field>

        <mat-form-field appearance="outline" [ngClass]="{'large-message': setPassword.get('password').hasError('pattern'), 'error_': setPassword.get('password').dirty && setPassword.get('password').errors != null}">
          <mat-label>{{'login.setPassword.newPassword' | translate}}</mat-label>
          <input matInput
                formControlName="password"
                 name="password"
                 placeholder="{{'login.setPassword.newPassword' | translate}}"
                 type="password">
          <mat-error *ngIf="setPassword.get('password').dirty && setPassword.get('password').invalid">
            Password must contain

            <span
              *ngIf="
                setPassword.get('password').hasError('hasSmallCase') ||
                setPassword.get('password').hasError('required')
              "
            >
            {{'login.passwordValidationErr.lowercase' | translate}}
            </span>
            <span
              *ngIf="
                setPassword.get('password').hasError('hasCapitalCase') ||
                setPassword.get('password').hasError('required')
              "
              >
                {{'login.passwordValidationErr.uppercase' | translate}}
              </span>

            <span
              *ngIf="
                setPassword.get('password').hasError('hasSpecialCharacters') ||
                setPassword.get('password').hasError('required')
              "
            >
              {{'login.passwordValidationErr.pattern' | translate}}
            </span>

          <span
            *ngIf="
              setPassword.get('password').hasError('hasNumber') ||
              setPassword.get('password').hasError('required')
            "
            >
            {{'login.passwordValidationErr.numbers' | translate}}
          </span>

            <span
              *ngIf="
                setPassword.get('password').hasError('minlength') ||
                setPassword.get('password').hasError('required')
              "
              >
              {{'login.passwordValidationErr.length' | translate}}
            </span>

          </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" [ngClass]="{'large-message': setPassword.get('passwordAgain').hasError('pattern') && !setPassword.get('password').hasError('pattern')}">
          <mat-label>{{'login.setPassword.newPasswordAgain' | translate}}</mat-label>
          <input matInput
                formControlName="passwordAgain"
                 name="passwordAgain"
                 placeholder="{{'login.setPassword.newPasswordAgain' | translate}}"
                 type="password">
        <mat-error *ngIf="setPassword.get('passwordAgain').hasError('required')">
          {{'login.required' | translate}}
        </mat-error>
        <mat-error *ngIf="setPassword.get('passwordAgain').hasError('pattern') && !setPassword.get('password').hasError('pattern')">
          {{'login.passwordPatternError' | translate}}
        </mat-error>
          <mat-error *ngIf="setPassword.get('passwordAgain').hasError('ConfirmPassword')">
            {{'login.passwordMatchError' | translate}}
          </mat-error>
      </mat-form-field>
          <span class="container-button" >
              <button mat-raised-button
                      color="primary"
                      [disabled]="!setPassword.valid"
                      (click)="onSubmit()">{{ 'login.setNewPassword.title' | translate}}</button>
          </span>
      </form>
  </div>
  <div class="login-footer" *ngIf="namespace?.byNeuron">{{'login.footer' | translate}}</div>
</div>

