import { ValidatorFn, AbstractControl } from '@angular/forms';

export default function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const value = control.value;
    if (typeof value !== 'string') {
      return null;
    }

    return value.trim().length !== 0 ? null : { 'whitespace': true };
  };
}
