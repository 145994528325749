import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NamespaceService } from '../../../../../apps/admin-panel/src/app/namespaces-management/services/namespace.service';
import { NamespaceSspService } from '@dooh/common-services';
import { NamespaceInventory, NamespaceInventoryMode } from '@dooh/models';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { valueTypeValidator } from '@dooh/validation';

@Component({
  selector: 'dooh-namespace-inventory',
  templateUrl: './namespace-inventory.component.html',
  styleUrls: ['./namespace-inventory.component.scss']
})
export class NamespaceInventoryComponent implements OnInit {
  allSSPs: any[] = [];
  isLoading: boolean;
  selectedSSPs: NamespaceInventory[] = [];

  sspForm: FormGroup;

  namespaceInventoryMode = NamespaceInventoryMode;

  @Input()
  preselectedInventory?: any[] = []


  @Output()
  sspInventory = new EventEmitter<any>();

  @Output()
  pristineFormInventory = new EventEmitter<any>();
  constructor(
    private namespaceService: NamespaceService,
    public namespaceSspService: NamespaceSspService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.getAllSSPs();
    this.initForm();
  }

  initForm(): void {
    this.sspForm = this.fb.group({
      ssps: this.fb.array([])
    })
  }

  get ssps() : FormArray {
    return this.sspForm.controls['ssps'] as FormArray
  }

  newSsp(ssp: NamespaceInventory): FormGroup {
    return this.fb.group({
      name: ssp.name,
      mode: new FormControl(NamespaceInventoryMode.All),
      publishers: [],
      exclude: new FormControl([]),
      include: new FormControl([]),
      selected: false,
      id: ssp.id
    })
  }


  getAllSSPs(): void {
    this.isLoading = true;
    this.namespaceService.getAllSSPs(1, 50).subscribe(res => {
      const allSSPs: any[] = res.content;
      allSSPs.forEach(val => {
        const ssp: NamespaceInventory = {
          id: val.id,
          name: val.name,
          mode: NamespaceInventoryMode.All,
          publishers: []
        }

        this.allSSPs.push(ssp);
        this.ssps.push(this.newSsp(ssp));
        this.updatePreselected();
      });
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
    })
  }

  setSspId(id: string): void {
    this.namespaceSspService.setSSPId(id);
  }

  toggleSSPSelection(sspId: any, formArrIndex: number): void {
    const index = this.selectedSSPs.findIndex(x => x.id === sspId);

    if (index === -1) {
      const item: NamespaceInventory = {
        id: sspId,
        mode: NamespaceInventoryMode.All,
        publishers: []
      }
      this.selectedSSPs.push(item);
    } else {
      this.selectedSSPs.splice(index, 1);
      const form = this.ssps.controls[formArrIndex] as FormGroup;
      form.get('include').patchValue([]);
      form.get('exclude').patchValue([]);
      form.get('mode').patchValue(NamespaceInventoryMode.All);
    }
    if (this.selectedSSPs.length > 0) {
      this.sspInventory.emit(this.selectedSSPs);
    } else {
      this.sspInventory.emit(null);
    }
  }
  

  isSSPSelected(sspId: any): boolean {
    return this.selectedSSPs.some(x => x.id === sspId);
  }

  toggleSspMode(event: any,  formArrIndex: number): void {
    const mode = event.value;
    const form = this.ssps.controls[formArrIndex] as FormGroup;
    form.get('mode').patchValue(mode);

    if (mode === NamespaceInventoryMode.Exclude) {
      form.get('include').patchValue([]);
    } else if (mode === NamespaceInventoryMode.Include) {
      form.get('exclude').patchValue([]);
    }
    form.get('publishers').patchValue([]);
    const sspObj = form.value;

    const index = this.selectedSSPs.findIndex(x => x.id === sspObj?.id);
    this.selectedSSPs[index] = {
      id: sspObj?.id,
      mode: mode,
      publishers: []
    }
    
    this.sspInventory.emit(this.selectedSSPs);
    
  }

  publisherChange(event: any[], arrayIndex: number, type: string): void {
    const form = this.ssps.controls[arrayIndex] as FormGroup;
    let setMode = NamespaceInventoryMode.All;
    if (type === 'include') {
      form.get('exclude').patchValue([]);
      if (event.length > 0) {
        setMode = NamespaceInventoryMode.Include;
      }
    } else if (type === 'exclude') {
      form.get('include').patchValue([]);
      if (event.length > 0) {
        setMode = NamespaceInventoryMode.Exclude;
      }
    }

    const sspObj = form.value;

    const index = this.selectedSSPs.findIndex(x => x.id === sspObj?.id);
    this.selectedSSPs[index] = {
      id: sspObj?.id,
      mode: setMode,
      publishers: event.map(x => x.id)
    }
    
    this.sspInventory.emit(this.selectedSSPs);
    
    
  }

  updatePreselected(): void {
    if (!this.preselectedInventory || this.preselectedInventory?.length === 0) return;

    this.preselectedInventory?.forEach(inventory => {
      const index = this.ssps.controls.findIndex(form => form.value?.id === inventory?.id);
      if (index > -1) {
        const form = this.ssps.controls[index];
        form.patchValue(inventory)
        form.get('selected').patchValue(true);
        if (inventory.mode === NamespaceInventoryMode.Exclude) {
          form.get('mode').patchValue(NamespaceInventoryMode.Exclude);
          form.get('exclude').patchValue(inventory?.publishers);
        } else if (inventory.mode === NamespaceInventoryMode.Include) {
          form.get('mode').patchValue(NamespaceInventoryMode.Include);
          form.get('include').patchValue(inventory?.publishers);
        } else {
          form.get('mode').patchValue(NamespaceInventoryMode.All);
        }

        const allPublishers = inventory?.publishers.map(x => x?.id);
        form.get('publishers').patchValue(allPublishers);

        const ssp: NamespaceInventory = {
          id: inventory?.id,
          mode: inventory?.mode,
          publishers: allPublishers,
          name: inventory?.name
        }
        const selectedIndex = this.selectedSSPs.findIndex(x => x.id === ssp.id);
        if (selectedIndex === -1) {
          this.selectedSSPs.push(ssp);
        } else {
          this.selectedSSPs[selectedIndex] = ssp;
        }
        this.pristineFormInventory.emit(this.selectedSSPs);
      }
    })
  }

}
