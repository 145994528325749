<div class="main-container">
  <div class="map-actions_container" *ngIf="editable">
    <div
      class="toggle-container"
      [ngClass]="{ pagination: isMapPaginated }"
      hidden
    >
      <mat-slide-toggle
        color="primary"
        [checked]="!previewScreensMode"
        (change)="toggleScreens()"
        ><span>Add Screens</span></mat-slide-toggle
      >
    </div>
  </div>

  <div
    *ngIf="!previewScreensMode && editable"
    class="screen-tabs-filters"
    [ngClass]="{ pagination: isMapPaginated }"
    hidden
  >
    <div class="poi-actions">
      <ng-container *ngIf="!previewScreensMode && editable">
        <button
          *ngIf="activePoi"
          mat-button
          (click)="openPoi()"
          class="filter-button"
          [ngClass]="{ 'filter-button_active': activePoi }"
        >
          <mat-icon svgIcon="place"></mat-icon>
          POI
        </button>
        <button
          *ngIf="activePoi"
          matTooltip="Properties are loaded based on the input entered in the search box"
          mat-button
          (click)="openScreenProperties()"
          class="filter-button"
          [ngClass]="{ 'filter-button_active': activeScreenProperties }"
        >
          <mat-icon svgIcon="filter"></mat-icon>
          Screen Properties
        </button>
      </ng-container>
    </div>
  </div>

  <ng-template #screenTabItemTemplate let-item="item">
    <span *ngIf="!!item" class="autocomplete-container">
      <span class="icon-autocomplete">
        <mat-icon
          svgIcon="map-pin"
          *ngIf="item?.place_id; else publisher"
        ></mat-icon>
        <ng-template #publisher>
          <mat-icon svgIcon="users" *ngIf="item?.tag !== 'Package'; else package"></mat-icon>
        </ng-template>
        <ng-template #package>
          <mat-icon svgIcon="package"></mat-icon>
        </ng-template>
      </span>
      <span class="autocomplete-text">
        <span class="country">{{ item?.tag === 'Package' ? item.name : item?.id }}</span>
        <span class="region">{{ item.tag }}</span>
      </span>
    </span>
  </ng-template>

  <div class="map--tool-bar">
    <div class="left-column" *ngIf="!previewScreensMode && editable">
      <form
        [formGroup]="filtersForm"
        class="search"
        (submit)="onSearchByTags()"
      >
        <dooh-autocomplete-field
          class="screen-search"
          formControlName="screenTagSearchField"
          searchBy="id"
          [label]="'screensTab.label.search' | translate"
          [service]="screensApi"
          [multi]="true"
          [searchDelay]="800"
          [searchMinLength]="3"
          (allowSearch)="checkAllowSearch($event)"
          (selectedValue)="checkPackageSelect($event)"
          [itemTemplate]="screenTabItemTemplate"
          matTooltip="Introduce 3 letters to search tags"
        ></dooh-autocomplete-field>
        <button
          mat-icon-button
          matPrefix
          type="submit"
          [disabled]="disableSearch"
          [ngClass]="{ disabled: disableSearch }"
          class="screen-tabs-filters__button"
          (click)="onActiveFilters($event)"
        >
          <mat-icon svgIcon="search"></mat-icon>
        </button>
      </form>
      <mat-select
        disableOptionCentering
        id="filterSelect"
        placeholder="Filters"
        *ngIf="
          !previewScreensMode &&
          editable &&
          (activePoi || (activePoi && hasAudience))">

        <ng-container *ngIf="!previewScreensMode && editable && showDma">
          <mat-option
            #matOption
            class="filter-button filter-dma-button"
            [ngClass]="{ 'filter-button_active': showDmaActive }"
            (click)="getDma()"
          >
            <mat-icon class="filter-icon">tv</mat-icon>
            DMA
          </mat-option>
        </ng-container>

        <ng-container *ngIf="!previewScreensMode && editable">
          <mat-option
            #matOption
            *ngIf="activePoi"
            (click)="openPoi()"
            class="filter-button"
            [ngClass]="{ 'filter-button_active': activePoi }"
          >
            <mat-icon class="filter-icon-svg" svgIcon="place"></mat-icon>
            POI
          </mat-option>

          <mat-option
            #matOption
            *ngIf="activePoi && hasAudience"
            (click)="openAudience()"
            class="filter-button"
            [ngClass]="{ 'filter-button_active': activePoi }"
          >
            <mat-icon class="filter-icon-svg" svgIcon="audience"></mat-icon>
            Audience
          </mat-option>

          <mat-option
            #matOption
            *ngIf="activePoi"
            [disabled]="disableScreenPropertiesButton"
            matTooltip="Properties are loaded based on the input entered in the search box"
            (click)="openScreenProperties()"
            class="filter-button"
            [ngClass]="{ 'filter-button_active': activeScreenProperties }"
          >
            <mat-icon class="filter-icon-svg" svgIcon="filter"></mat-icon>
            Screen Properties
          </mat-option>
        </ng-container>
      </mat-select>
    </div>
    <div class="middle">
      <div class="paginator">
        <mat-paginator
          *ngIf="isMapPaginated"
          [length]="mapPagination.totalElements"
          [pageIndex]="mapPagination.pageIndex - 1"
          [pageSize]="mapPagination.pageSize"
          [pageSizeOptions]="pageOptions"
          (page)="onPageHandler($event)"
          [disabled]="isLoading"
        ></mat-paginator>
      </div>
    </div>
    <div class="right-column">
      <dooh-audience-reach
        [expectedReach]="expectedReach"
        *ngIf="!editable || previewScreensMode"
      ></dooh-audience-reach>
      <div class="map-view-toggle">
        <mat-icon svgIcon="map-outline"></mat-icon>
        <p class="text">Map view</p>
        <mat-slide-toggle [checked]="isMapView" (change)="toggleView($event)"
          ><span></span
        ></mat-slide-toggle>
      </div>
      <button
        mat-button
        class="color-gray smb-view-actions__edit"
        *ngIf="!editable"
        (click)="onEditClicked.emit()"
      >
        <mat-icon svgIcon="edit" class="color-gray-2"></mat-icon>
        <span>{{
          'smb.campaignManagement.campaign.view-actions.edit' | translate
        }}</span>
      </button>
      <button
        class="btn btn--inline color-gray"
        *ngIf="!editable"
        [disabled]="isDownloadingCSV"
        (click)="onDownloadCsvClicked.emit()"
      >
        <mat-icon svgIcon="download-icon"></mat-icon>
        <span>Download CSV</span>
        <mat-spinner
          class=""
          diameter="20"
          *ngIf="isDownloadingCSV"
        ></mat-spinner>
      </button>
    </div>
  </div>

  <div class="map-area">
    <div class="marker-group-table w-50" [hidden]="!showingMarkerGroupTable">
      <dooh-marker-multi-screen-table
      #multiMarkerTable
      (selectedScreen)="SetScreenInfo($event)"
      [editable]="editable"
      [markers]="markerGroup"
      (close)="clearInfo()"
      [selectedScreens]="selectedScreenIds"
      [addedScreens]="[]"
      (toggleScreenSelection)="toggleScreenFromMap($event)"
      (toggleAllScreenSelection)="toggleAllMarkerGroupSelection($event)"
      ></dooh-marker-multi-screen-table>
    </div>
    <div class="w-30 info-window-container" *ngIf="showingMapInfo">
      <div class="buttons">
        <div class="back-button" *ngIf="markerGroup?.length > 2">
          <button (click)="backToMarkerTable()">
            <mat-icon class="icon">chevron_left</mat-icon>
            <span>Back</span>
          </button>
        </div>
        <div class="close-button">
          <button
            mat-icon-button
            color="primary"
            (click)="clearInfo()"
            class="action-button"
          >
            <mat-icon svgIcon="close"></mat-icon>
          </button>
        </div>
      </div>
      <div class="marker-info-window" *ngIf="markerPopUpInfo?.marker">
        <div
          class="marker-window__cover"
          [ngClass]="{'multiple' : images.length > 1}"
        >
          <ng-container
          [ngTemplateOutlet]="images.length > 0 ? imageSlideshow : noImage"
        ></ng-container>
        </div>
        <div class="marker-window__body">
          <div class="marker-window__title">
            {{ markerPopUpInfo?.marker?.name }}
          </div>

          <div
            class="marker-window__field-list"
            *ngIf="markerPopUpInfo?.marker?.formats"
          >
            <div class="marker-window__field_format">
              <span class="marker-window__field-label">
                {{
                  'smb.campaignManagement.campaign.tab.screens.resolution'
                    | translate
                }}
              </span>
              <ng-template ngFor let-format [ngForOf]="markerPopUpInfo.marker.formats">
                <div class="format-title">
                  <p>{{format.name}}</p>
                  <ng-template ngFor let-mimeType [ngForOf]="format.mimeTypes" let-j="index">
                    <span>
                      {{mimeType}}{{j === format.mimeTypes.length -1 ? '' : ', ' }}
                    </span>
                  </ng-template>
                </div>
              </ng-template>
            </div>
          </div>

          <div class="marker-window__field-list">
            <div class="marker-window__field venueType-wrapper" *ngIf="markerPopUpInfo?.marker?.venueTypeId">
              <span class="marker-window__field-label">
                {{
                  'smb.campaignManagement.campaign.tab.screens.venueType'
                    | translate
                }}
              </span>

              <span
                class="marker-window__field-value"
                *ngIf="markerPopUpInfo?.marker?.venueTypeId"
              >
                {{
                  markerPopUpInfo?.marker?.venueTypeId
                    ? markerPopUpInfo?.marker?.venueTypeId !== undefined
                      ? getVenueTypeName(markerPopUpInfo?.marker?.venueTypeId)
                      : '-'
                    : '-'
                }}
              </span>
              <span
                class="marker-window__field-value"
                *ngIf="!markerPopUpInfo?.marker?.venueTypeId"
              >
                -
              </span>
            </div>
            <div class="marker-window__field venueType-wrapper" *ngIf="markerPopUpInfo?.marker?.venueType">
              <span class="marker-window__field-label">
                {{
                  'smb.campaignManagement.campaign.tab.screens.venueType'
                    | translate
                }}
              </span>

              <span
                class="marker-window__field-value"
                *ngIf="markerPopUpInfo?.marker?.venueType?.id"
              >
                {{
                  markerPopUpInfo?.marker?.venueType?.id
                    ? markerPopUpInfo?.marker?.venueType?.id !== undefined
                      ? getVenueTypeName(markerPopUpInfo?.marker?.venueType?.id)
                      : '-'
                    : '-'
                }}
              </span>
              <span
                class="marker-window__field-value"
                *ngIf="!markerPopUpInfo?.marker?.venueType?.id"
              >
                -
              </span>
            </div>
            <div
              class="marker-window__field"
              matTooltip="{{ 'Expected reach per week' | translate }}"
            >
                <span class="marker-window__field-label">
                  {{ 'Audience' | translate }}
                  <sup class="sup__new">New</sup>
                </span>
              <span class="marker-window__field-value" *ngIf="markerPopUpInfo?.marker?.audiencePerWeek">{{
                markerPopUpInfo?.marker?.audiencePerWeek | number: '1.0-0'
                }}</span>
              <span class="marker-window__field-value" *ngIf="!markerPopUpInfo?.marker?.audiencePerWeek">
                  -
                </span>
            </div>
          </div>
          <div class="marker-window__field-list">
            <div
              class="marker-window__field marker-window__field_street"
              *ngIf="markerPopUpInfo?.marker?.address?.street"
            >
              <span class="marker-window__field-label">
                {{
                  'smb.campaignManagement.campaign.tab.screens.address'
                    | translate
                }}
              </span>
              <span class="marker-window__field-value">
                {{ markerPopUpInfo?.marker?.address?.street }}
              </span>
            </div>
            <div
              class="marker-window__field marker-window__field_street"
              *ngIf="markerPopUpInfo?.marker?.ssp?.name"
            >
              <span class="marker-window__field-label">
                {{ 'adminPanel.instanceInfo.tab.ssp' | translate }}
              </span>
              <span class="marker-window__field-value">
                {{ markerPopUpInfo?.marker?.ssp?.name }}
              </span>
            </div>
            <div
              class="marker-window__field marker-window__field_street"
              *ngIf="markerPopUpInfo?.marker?.publisher?.name"
            >
              <span class="marker-window__field-label">
                {{
                  'ssp.screensManagement.screens.table.column.publisher'
                    | translate
                }}
              </span>
              <span class="marker-window__field-value">
                {{ markerPopUpInfo?.marker?.publisher?.name }}
              </span>
            </div>
          </div>

          <div class="marker-window__reach-container">
            <p class="marker__reach--title">
              <span>{{
                'smb.campaignManagement.campaign.tab.screens.chartTitle'
                  | translate
              }}</span>
              <sup class="sup__new">New</sup>
            </p>
            <ng-container *ngIf="markerPopUpInfo?.marker.dayAudience?.length > 0 && showChart">
              <ngx-charts-bar-vertical
                [view]="view"
                [scheme]="colorScheme"
                [results]="markerPopUpInfo?.marker.dayAudience"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [tooltipDisabled]="true"
              >
              </ngx-charts-bar-vertical>
            </ng-container>
            <p
              class="marker__performance--empty"
              *ngIf="
                markerPopUpInfo?.marker.dayAudience?.length === 0 ||
                !markerPopUpInfo?.marker.dayAudience
              "
            >
              {{
                'smb.campaignManagement.campaign.tab.screens.emptyScreen'
                  | translate
              }}
            </p>
          </div>
          <div class="marker-window__button-container" *ngIf="editable">
            <button
              *ngIf="isAddedScreen(getMarkerId(markerPopUpInfo?.marker))"
              mat-raised-button
              class="color-primary"
              (click)="deleteScreen(markerPopUpInfo?.marker)"
            >
              Remove Screen
            </button>
            <button
              *ngIf="!isAddedScreen(getMarkerId(markerPopUpInfo?.marker))"
              mat-raised-button
              color="primary"
              (click)="addScreen(markerPopUpInfo?.marker)"
            >
              Add Screen
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="map" [ngClass]="showingMapInfo ? 'w-70' : showingMarkerGroupTable ? 'w-50' : 'w-100'">
      <dooh-map-box
        #mapBox
        *ngIf="location"
        class="screens-tabs__map"
        [location]="location"
        [markers]="markers"
        (searchOnTheMapArea)="areaSearch($event)"
        [canGeoSearch]="!previewScreensMode && editable"
        [isPaginated]="false"
        [pagination]="mapPagination"
        (pageToggle)="changePage($event)"
        (markerClick)="showMarkerDetails($event)"
        (mapClick)="clearInfo()"
        [isLoading]="
          screens?.add?.isLoading || isLoading || screens?.preview?.isLoading
        "
      ></dooh-map-box>
    </div>
  </div>
</div>

<ng-template #imageSlideshow>
  <dooh-image-carousel
    [images]="images"
  ></dooh-image-carousel>
</ng-template>

<ng-template #noImage>
  <img
    src="../../../assets/images/no-image.svg"
    alt="drop file"
    class="no-image"
  />
</ng-template>
