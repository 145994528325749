import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

import {AlertDialogData, CustomContentDialogData, DialogData} from '@dooh/models';
import {CustomContentDialogComponent} from './customContentDialog/custom-content-dialog.component';
import {AlertDialogComponent} from './alertDialog/alert-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(
    public customDialog: MatDialog,
    public defaultDialog: MatDialog
  ) { }

  private defaultConfig = {
    width: '375px',
  };

  private defaultAlertData = {
    error: {
      type: 'error',
      title: 'dialog.alert.title.error',
      submitButtonText: 'dialog.submitButtonText',
      isCentered: true,
    },
    success: {
      type: 'success',
      title: 'dialog.alert.title.success',
      submitButtonText: 'dialog.submitButtonText',
      isCentered: true,
    },
    warning: {
      type: 'warning',
      title: 'dialog.alert.title.warning',
      submitButtonText: 'dialog.submitButtonText',
      isCentered: true,
    },
    delete: {
      type: "delete",
      title: 'dialog.alert.title.delete',
      submitButtonText: 'dialog.alert.submitButtonText.delete',
      cancelButtonText: 'dialog.cancelButtonText',
      isCentered: true,
    },
    archive: {
      type: "archive",
      title: 'dialog.alert.title.archive',
      submitButtonText: 'dialog.alert.submitButtonText.archive',
      cancelButtonText: 'dialog.cancelButtonText',
      isCentered: true,
    },
    unarchive: {
      type: "unarchive",
      title: 'dialog.alert.title.unarchive',
      submitButtonText: 'dialog.alert.submitButtonText.unarchive',
      cancelButtonText: 'dialog.cancelButtonText',
      isCentered: true,
    },
    cancel: {
      type: "cancel",
      title: 'dialog.alert.title.cancel',
      submitButtonText: 'dialog.alert.submitButtonText.yes',
      cancelButtonText: 'dialog.alert.cancelButtonText.no',
      isCentered: true,
    },
    refund: {
      type: "refund",
      title: 'dialog.alert.title.refund',
      submitButtonText: 'dialog.alert.submitButtonText.yes',
      cancelButtonText: 'dialog.alert.cancelButtonText.no',
      isCentered: true,
    },
    default: {
      type: 'default',
      submitButtonText: 'dialog.submitButtonText',
      isCentered: false,
    }
  };

  private buildAlertDialogConfig(data: AlertDialogData, config: MatDialogConfig) {
    return {
      ...this.defaultConfig,
      ...config,
      data: {
        ...this.defaultAlertData[data.type || 'default'],
        ...data
      }
    }
  }

  public buildCustomContentDialogConfig(data: DialogData, config: MatDialogConfig) {
    return {
      ...this.defaultConfig,
      ...config,
      data: {
        ...data
      }
    }
  }

  public openDialog(data: CustomContentDialogData, config?: MatDialogConfig) {
    const preparedConfig = this.buildCustomContentDialogConfig(data, config || {});
    return this.customDialog.open(CustomContentDialogComponent, preparedConfig);
  }


  public alert(data: AlertDialogData, config?: MatDialogConfig) {
    const preparedConfig = this.buildAlertDialogConfig(data, config || {});
    return this.defaultDialog.open(AlertDialogComponent, preparedConfig);
  }

  public openCustomComponentModal(data: AlertDialogData, component: any, config?: MatDialogConfig) {
    const preparedConfig = this.buildAlertDialogConfig(data, config || {});
    return this.defaultDialog.open(component, preparedConfig);
  }


}
