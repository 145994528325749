import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdministrationPanelService {

  constructor(private http: HttpClient) {
  }

  public getJSON(): Observable<any> {
    return this.http.get('./assets/mocks/administratorItems.json');
  }
}

export interface AdministratorItem {
  icon: string;
  title: string;
  isVisible: boolean;
  link?: string;
}
