/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { PageDto } from '../model/pageDto';
import { TargetingForm } from '../model/targetingForm';
import { TargetingScreenForm } from '../model/targetingScreenForm';
import { TargetingScreenView } from '../model/targetingScreenView';
import { TargetingSetForm } from '../model/targetingSetForm';
import { TargetingSetView } from '../model/targetingSetView';
import { TargetingView } from '../model/targetingView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TargetingService {

    protected basePath = 'https://dooh-api.staging.theneuron.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create a new Targeting
     * Create a new targeting
     * @param body Targeting Object
     * @param targetingSetId Targeting Set Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTargeting(body: TargetingForm, targetingSetId: string, observe?: 'body', reportProgress?: boolean): Observable<TargetingView>;
    public createTargeting(body: TargetingForm, targetingSetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TargetingView>>;
    public createTargeting(body: TargetingForm, targetingSetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TargetingView>>;
    public createTargeting(body: TargetingForm, targetingSetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createTargeting.');
        }

        if (targetingSetId === null || targetingSetId === undefined) {
            throw new Error('Required parameter targetingSetId was null or undefined when calling createTargeting.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TargetingView>(`${this.basePath}/dsp/targeting_sets/${encodeURIComponent(String(targetingSetId))}/targetings`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new Targeting Screen
     * Create a new targeting screen
     * @param body Targeting Screen Object
     * @param targetingId Targeting Id
     * @param targetingSetId Targeting Set Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTargetingScreen(body: TargetingScreenForm, targetingId: string, targetingSetId: string, observe?: 'body', reportProgress?: boolean): Observable<TargetingScreenView>;
    public createTargetingScreen(body: TargetingScreenForm, targetingId: string, targetingSetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TargetingScreenView>>;
    public createTargetingScreen(body: TargetingScreenForm, targetingId: string, targetingSetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TargetingScreenView>>;
    public createTargetingScreen(body: TargetingScreenForm, targetingId: string, targetingSetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createTargetingScreen.');
        }

        if (targetingId === null || targetingId === undefined) {
            throw new Error('Required parameter targetingId was null or undefined when calling createTargetingScreen.');
        }

        if (targetingSetId === null || targetingSetId === undefined) {
            throw new Error('Required parameter targetingSetId was null or undefined when calling createTargetingScreen.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TargetingScreenView>(`${this.basePath}/dsp/targeting_sets/${encodeURIComponent(String(targetingSetId))}/targetings/${encodeURIComponent(String(targetingId))}/screens`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create new targeting set
     * Create new targeting set
     * @param body Targeting Set Object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTargetingSet(body: TargetingSetForm, observe?: 'body', reportProgress?: boolean): Observable<TargetingSetView>;
    public createTargetingSet(body: TargetingSetForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TargetingSetView>>;
    public createTargetingSet(body: TargetingSetForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TargetingSetView>>;
    public createTargetingSet(body: TargetingSetForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createTargetingSet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TargetingSetView>(`${this.basePath}/dsp/targeting_sets`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Targeting
     * Delete targeting
     * @param targetingId Targeting Id
     * @param targetingSetId Targeting Set Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTargeting(targetingId: string, targetingSetId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteTargeting(targetingId: string, targetingSetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteTargeting(targetingId: string, targetingSetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteTargeting(targetingId: string, targetingSetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (targetingId === null || targetingId === undefined) {
            throw new Error('Required parameter targetingId was null or undefined when calling deleteTargeting.');
        }

        if (targetingSetId === null || targetingSetId === undefined) {
            throw new Error('Required parameter targetingSetId was null or undefined when calling deleteTargeting.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/dsp/targeting_sets/${encodeURIComponent(String(targetingSetId))}/targetings/${encodeURIComponent(String(targetingId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Targeting Screen
     * Delete targeting screen
     * @param targetingId Targeting Id
     * @param targetingScreenId Targeting Screen Id
     * @param targetingSetId Targeting Set Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTargetingScreen(targetingId: string, targetingScreenId: string, targetingSetId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteTargetingScreen(targetingId: string, targetingScreenId: string, targetingSetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteTargetingScreen(targetingId: string, targetingScreenId: string, targetingSetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteTargetingScreen(targetingId: string, targetingScreenId: string, targetingSetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (targetingId === null || targetingId === undefined) {
            throw new Error('Required parameter targetingId was null or undefined when calling deleteTargetingScreen.');
        }

        if (targetingScreenId === null || targetingScreenId === undefined) {
            throw new Error('Required parameter targetingScreenId was null or undefined when calling deleteTargetingScreen.');
        }

        if (targetingSetId === null || targetingSetId === undefined) {
            throw new Error('Required parameter targetingSetId was null or undefined when calling deleteTargetingScreen.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/dsp/targeting_sets/${encodeURIComponent(String(targetingSetId))}/targetings/${encodeURIComponent(String(targetingId))}/screens/${encodeURIComponent(String(targetingScreenId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Targeting Set
     * Delete targeting set
     * @param targetingSetId Targeting Set Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTargetingSet(targetingSetId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteTargetingSet(targetingSetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteTargetingSet(targetingSetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteTargetingSet(targetingSetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (targetingSetId === null || targetingSetId === undefined) {
            throw new Error('Required parameter targetingSetId was null or undefined when calling deleteTargetingSet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/dsp/targeting_sets/${encodeURIComponent(String(targetingSetId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Targeting by id
     * Get targeting by id
     * @param targetingId Targeting Id
     * @param targetingSetId Targeting Set Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTargeting(targetingId: string, targetingSetId: string, observe?: 'body', reportProgress?: boolean): Observable<TargetingView>;
    public getTargeting(targetingId: string, targetingSetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TargetingView>>;
    public getTargeting(targetingId: string, targetingSetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TargetingView>>;
    public getTargeting(targetingId: string, targetingSetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (targetingId === null || targetingId === undefined) {
            throw new Error('Required parameter targetingId was null or undefined when calling getTargeting.');
        }

        if (targetingSetId === null || targetingSetId === undefined) {
            throw new Error('Required parameter targetingSetId was null or undefined when calling getTargeting.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TargetingView>(`${this.basePath}/dsp/targeting_sets/${encodeURIComponent(String(targetingSetId))}/targetings/${encodeURIComponent(String(targetingId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of Targeting
     * Get list of targetings, attributes: page&#x3D;2&amp;page_size&#x3D;10&amp;sort&#x3D;myfield,asc/desc;myfield2,asc/desc
     * @param targetingSetId Targeting Set Id
     * @param page the number of page
     * @param pageSize the size of page
     * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTargeting1(targetingSetId: string, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
    public getTargeting1(targetingSetId: string, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
    public getTargeting1(targetingSetId: string, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
    public getTargeting1(targetingSetId: string, page?: number, pageSize?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (targetingSetId === null || targetingSetId === undefined) {
            throw new Error('Required parameter targetingSetId was null or undefined when calling getTargeting1.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('page_size', <any>pageSize);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageDto>(`${this.basePath}/dsp/targeting_sets/${encodeURIComponent(String(targetingSetId))}/targetings`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Targeting screen by id
     * Get targeting screen by id
     * @param targetingId Targeting Id
     * @param targetingScreenId Targeting Screen Id
     * @param targetingSetId Targeting Set Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTargetingScreen(targetingId: string, targetingScreenId: string, targetingSetId: string, observe?: 'body', reportProgress?: boolean): Observable<TargetingScreenView>;
    public getTargetingScreen(targetingId: string, targetingScreenId: string, targetingSetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TargetingScreenView>>;
    public getTargetingScreen(targetingId: string, targetingScreenId: string, targetingSetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TargetingScreenView>>;
    public getTargetingScreen(targetingId: string, targetingScreenId: string, targetingSetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (targetingId === null || targetingId === undefined) {
            throw new Error('Required parameter targetingId was null or undefined when calling getTargetingScreen.');
        }

        if (targetingScreenId === null || targetingScreenId === undefined) {
            throw new Error('Required parameter targetingScreenId was null or undefined when calling getTargetingScreen.');
        }

        if (targetingSetId === null || targetingSetId === undefined) {
            throw new Error('Required parameter targetingSetId was null or undefined when calling getTargetingScreen.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TargetingScreenView>(`${this.basePath}/dsp/targeting_sets/${encodeURIComponent(String(targetingSetId))}/targetings/${encodeURIComponent(String(targetingId))}/screens/${encodeURIComponent(String(targetingScreenId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of Targeting Screens
     * Get list of targeting screens, attributes: page&#x3D;2&amp;page_size&#x3D;10&amp;sort&#x3D;myfield,asc/desc;myfield2,asc/desc
     * @param targetingId Targeting Id
     * @param targetingSetId Targeting Set Id
     * @param page the number of page
     * @param pageSize the size of page
     * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTargetingScreens(targetingId: string, targetingSetId: string, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
    public getTargetingScreens(targetingId: string, targetingSetId: string, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
    public getTargetingScreens(targetingId: string, targetingSetId: string, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
    public getTargetingScreens(targetingId: string, targetingSetId: string, page?: number, pageSize?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (targetingId === null || targetingId === undefined) {
            throw new Error('Required parameter targetingId was null or undefined when calling getTargetingScreens.');
        }

        if (targetingSetId === null || targetingSetId === undefined) {
            throw new Error('Required parameter targetingSetId was null or undefined when calling getTargetingScreens.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('page_size', <any>pageSize);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageDto>(`${this.basePath}/dsp/targeting_sets/${encodeURIComponent(String(targetingSetId))}/targetings/${encodeURIComponent(String(targetingId))}/screens`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Targeting Set by id
     * Get targeting set by id
     * @param targetingSetId Targeting Set Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTargetingSet(targetingSetId: string, observe?: 'body', reportProgress?: boolean): Observable<TargetingSetView>;
    public getTargetingSet(targetingSetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TargetingSetView>>;
    public getTargetingSet(targetingSetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TargetingSetView>>;
    public getTargetingSet(targetingSetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (targetingSetId === null || targetingSetId === undefined) {
            throw new Error('Required parameter targetingSetId was null or undefined when calling getTargetingSet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TargetingSetView>(`${this.basePath}/dsp/targeting_sets/${encodeURIComponent(String(targetingSetId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of Targeting Sets
     * Get list of targeting sets. Available fields for sorting and filtering: - &#39;targeting_set_id&#39; - the ID of targeting set; - &#39;name&#39; - the name of targeting set; - &#39;screens&#39; - number of targeting set screens; - &#39;audience&#39; - a summary audience of a targeting set.  Request example: /targeting_sets?page&#x3D;1&amp;page_size&#x3D;20&amp;sort&#x3D;screens,asc.
     * @param page the number of page
     * @param pageSize the size of page
     * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTargetingSets(page?: number, pageSize?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
    public getTargetingSets(page?: number, pageSize?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
    public getTargetingSets(page?: number, pageSize?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
    public getTargetingSets(page?: number, pageSize?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('page_size', <any>pageSize);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageDto>(`${this.basePath}/dsp/targeting_sets`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Targeting
     * Update targeting
     * @param body Targeting Object
     * @param targetingId Targeting Id
     * @param targetingSetId Targeting Set Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTargeting(body: TargetingForm, targetingId: string, targetingSetId: string, observe?: 'body', reportProgress?: boolean): Observable<TargetingView>;
    public updateTargeting(body: TargetingForm, targetingId: string, targetingSetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TargetingView>>;
    public updateTargeting(body: TargetingForm, targetingId: string, targetingSetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TargetingView>>;
    public updateTargeting(body: TargetingForm, targetingId: string, targetingSetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateTargeting.');
        }

        if (targetingId === null || targetingId === undefined) {
            throw new Error('Required parameter targetingId was null or undefined when calling updateTargeting.');
        }

        if (targetingSetId === null || targetingSetId === undefined) {
            throw new Error('Required parameter targetingSetId was null or undefined when calling updateTargeting.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<TargetingView>(`${this.basePath}/dsp/targeting_sets/${encodeURIComponent(String(targetingSetId))}/targetings/${encodeURIComponent(String(targetingId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Targeting Screen
     * Update targeting screen
     * @param body Targeting Screen Object
     * @param targetingId Targeting Id
     * @param targetingScreenId Targeting Screen Id
     * @param targetingSetId Targeting Set Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTargetingScreen(body: TargetingScreenForm, targetingId: string, targetingScreenId: string, targetingSetId: string, observe?: 'body', reportProgress?: boolean): Observable<TargetingScreenView>;
    public updateTargetingScreen(body: TargetingScreenForm, targetingId: string, targetingScreenId: string, targetingSetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TargetingScreenView>>;
    public updateTargetingScreen(body: TargetingScreenForm, targetingId: string, targetingScreenId: string, targetingSetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TargetingScreenView>>;
    public updateTargetingScreen(body: TargetingScreenForm, targetingId: string, targetingScreenId: string, targetingSetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateTargetingScreen.');
        }

        if (targetingId === null || targetingId === undefined) {
            throw new Error('Required parameter targetingId was null or undefined when calling updateTargetingScreen.');
        }

        if (targetingScreenId === null || targetingScreenId === undefined) {
            throw new Error('Required parameter targetingScreenId was null or undefined when calling updateTargetingScreen.');
        }

        if (targetingSetId === null || targetingSetId === undefined) {
            throw new Error('Required parameter targetingSetId was null or undefined when calling updateTargetingScreen.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<TargetingScreenView>(`${this.basePath}/dsp/targeting_sets/${encodeURIComponent(String(targetingSetId))}/targetings/${encodeURIComponent(String(targetingId))}/screens/${encodeURIComponent(String(targetingScreenId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Targeting Set
     * Update targeting set
     * @param body Targeting Set Object
     * @param targetingSetId Targeting Set Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTargetingSet(body: TargetingSetForm, targetingSetId: string, observe?: 'body', reportProgress?: boolean): Observable<TargetingSetView>;
    public updateTargetingSet(body: TargetingSetForm, targetingSetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TargetingSetView>>;
    public updateTargetingSet(body: TargetingSetForm, targetingSetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TargetingSetView>>;
    public updateTargetingSet(body: TargetingSetForm, targetingSetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateTargetingSet.');
        }

        if (targetingSetId === null || targetingSetId === undefined) {
            throw new Error('Required parameter targetingSetId was null or undefined when calling updateTargetingSet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<TargetingSetView>(`${this.basePath}/dsp/targeting_sets/${encodeURIComponent(String(targetingSetId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
