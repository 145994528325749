import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PageDto, InventoryService } from '@dooh/models';
import { AutocompleteService } from '@dooh/components-library';

@Injectable({
  providedIn: 'root'
})
export class SspAutocompleteService implements AutocompleteService {
  sort = ['ssp_name,asc'];

  constructor(private inventoryService: InventoryService) {}

  getAll(page: number = 1, pageSize: number = 15, filters?: Array<string>, instanceId?: string): Observable<PageDto> {
    return this.inventoryService.getSsps(
      instanceId,
      page,
      pageSize,
      this.sort,
      filters
    )
  }
}
