<div class="wrapper" [ngClass]="{ 'h-100': !showMap }">
  <div *ngIf="isSaving" class="custom__loader-wrapper">
    <dooh-custom-loader class="custom__loader"></dooh-custom-loader>
  </div>
  <div class="header">
    <p class="title">Add Screens</p>
    <div class="right-content">
      <mat-slide-toggle
        color="primary"
        [(ngModel)]="showMap"
        class="mat-slide-toggle"
        *ngIf="showSearchResult"
        (change)="toggleMap()"
      >
        <span class="color-gray">Show Map</span>
      </mat-slide-toggle>
      <button
        mat-icon-button
        color="primary"
        *ngIf="showSearchResult && showMap"
        class="action-button"
        (click)="toggleFullScreen()"
      >
        <mat-icon *ngIf="!isFullScreen" class="icon">fullscreen</mat-icon>
        <mat-icon *ngIf="isFullScreen" class="icon">fullscreen_exit</mat-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        (click)="onCloseClick()"
        class="action-button"
      >
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>
  </div>
  <div class="summary-bar animate-fade-in">
    <div class="summary-loader" *ngIf="loadingSummary">
      <div class="skeletal-loader">
        <div class="skeleton-icon"></div>
        <div class="skeleton-text"></div>
        <div class="skeleton-text"></div>
      </div>
      <div class="skeletal-loader">
        <div class="skeleton-icon"></div>
        <div class="skeleton-text"></div>
        <div class="skeleton-text"></div>
      </div>
      <div class="skeletal-loader">
        <div class="skeleton-icon"></div>
        <div class="skeleton-text"></div>
        <div class="skeleton-text"></div>
      </div>
      <div class="skeletal-loader">
        <div class="skeleton-icon"></div>
        <div class="skeleton-text"></div>
        <div class="skeleton-text"></div>
      </div>
    </div>
    <div class="summary-result animate-fade-in" *ngIf="!loadingSummary">
      <div class="summary__info">
        <mat-icon class="icon" svgIcon="layers-2"></mat-icon>
        <p class="count">
          {{
            !screenDataSummary?.screenCount
              ? 0
              : getLocaleString(screenDataSummary?.screenCount)
          }}
        </p>
        <p class="title">Number of screens</p>
      </div>
      <div class="summary__info">
        <mat-icon color="accent" class="icon" svgIcon="bar-chart-2"></mat-icon>
        <p class="count">
          {{
          !screenDataSummary?.audiencePerWeek?.sum
            ? 0
            : getLocaleString(
              screenDataSummary?.audiencePerWeek?.sum | number: '1.0-0'
            )
          }}
        </p>
        <p class="title">Weekly Audience</p>
      </div>
      <div class="summary__info">
        <mat-icon class="icon" svgIcon="database"></mat-icon>
        <p class="count">
          {{
            !screenDataSummary?.venueTypesCount
              ? 0
              : getLocaleString(screenDataSummary?.venueTypesCount)
          }}
        </p>
        <p class="title">Venue Types Available</p>
      </div>
      <div class="summary__info">
        <mat-icon class="icon" svgIcon="cash"></mat-icon>
        <p class="count">
          {{
            !screenDataSummary?.minPrice
              ? 0
              : getLocaleString(screenDataSummary?.minPrice | number: '1.0-0')
          }}
          -
          {{
            !screenDataSummary?.maxPrice
              ? 0
              : getLocaleString(screenDataSummary?.maxPrice | number: '1.0-0')
          }}
        </p>
        <p class="title">Bid Price Range</p>
      </div>
    </div>
  </div>
  <div class="table-view" [ngClass]="{ hidden: showMap }">
    <div class="search-wrapper">
      <mat-tab-group (selectedTabChange)="onTabChanged($event)">
        <mat-tab [label]="'Search'">
          <div class="search-filters" [ngClass]="{ hidden: showSearchResult }">
            <form
              class="screen-tabs-filters"
              [formGroup]="filtersForm"
              (submit)="onSearchByTags()"
            >
              <dooh-autocomplete-field
                class="screen-search"
                formControlName="screenTagSearchField"
                searchBy="id"
                [label]="'screensTab.label.search' | translate"
                [service]="screensApi"
                [multi]="true"
                [searchDelay]="800"
                [searchMinLength]="3"
                (allowSearch)="checkAllowSearch($event)"
                [itemTemplate]="screenTabItemTemplate"
                matTooltip="Introduce 3 letters to search tags"
              ></dooh-autocomplete-field>
              <button
                mat-icon-button
                matPrefix
                type="submit"
                [disabled]="disableSearch || screens.isLoading"
                [ngClass]="{ disabled: disableSearch }"
                class="screen-tabs-filters__button"
              >
                <mat-icon svgIcon="search"></mat-icon>
              </button>
            </form>

            <mat-accordion class="filters">
              <mat-expansion-panel [disabled]="!isPoiEnabled" #poiPanel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon svgIcon="filter" class="filter-icon"></mat-icon>
                    <span
                      class="filter-title"
                      [ngClass]="{ disabled: !isPoiEnabled }"
                      >Point of Interest</span
                    >
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <dooh-point-of-interest-dialog
                    #poiDialog
                    [poisSearchCountry]="poiPlaceCountry"
                  ></dooh-point-of-interest-dialog>
                </ng-template>
              </mat-expansion-panel>
              <mat-expansion-panel
                [disabled]="disableScreenPropertiesButton"
                #screenPropertiesPanel
                class="screenPropertiesP"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon svgIcon="filter" class="filter-icon"></mat-icon>
                    <span
                      class="filter-title"
                      [ngClass]="{ disabled: disableScreenPropertiesButton }"
                      >Screen Properties</span
                    >
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <dooh-screen-properties-dialog
                    #screenPropertyDialog
                    [screenPropertiesAutocompleteService]="
                      screenPropertiesAutocompleteService
                    "
                    [screenPropertiesAutocompleteServiceArgs]="
                      screenPropertyArg
                    "
                    [screensFilters]="filterScreenObject"
                    [allVenueTypes]="allVenueTypes"
                  ></dooh-screen-properties-dialog>
                </ng-template>
              </mat-expansion-panel>
              <mat-expansion-panel [disabled]="!hasAudience" #audiencePanel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon svgIcon="filter" class="filter-icon"></mat-icon>
                    <span
                      class="filter-title"
                      [ngClass]="{ disabled: !hasAudience }"
                      >Audience</span
                    >
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                  <dooh-audience-dialog
                    #audienceDialog
                    [campaignId]="campaignId"
                    [lineItemId]="lineItemId"
                    [filterObj]="filterScreenObject"
                    [targetingId]="targetingId"
                    (openAside)="toggleAsideDialog($event)"
                  ></dooh-audience-dialog>
                </ng-template>
              </mat-expansion-panel>
              <mat-expansion-panel *ngIf="showDma" #dmaPanel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon svgIcon="filter" class="filter-icon"></mat-icon>
                    <span
                      class="filter-title"
                      [ngClass]="{ disabled: !showDma }"
                      >DMA</span
                    >
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <dooh-dma [dmaId]="dmaIds" #dmaDialog></dooh-dma>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </div>

          <div class="search-results" [ngClass]="{ hidden: !showSearchResult }">
            <div class="result-header">
              <button
                color="primary"
                mat-stroked-button
                type="button"
                (click)="showSearchFilters()"
              >
                <mat-icon svgIcon="filter" class="button-icon"></mat-icon>
                Show filters
                <!-- <span class="filter-count" *ngIf="filterCount > 0">{{ filterCount }}</span> -->
              </button>
            </div>
            <div class="table-container">
              <dooh-generic-table
                class="child-table child-table_add"
                [dataSource]="screens.dataSource"
                [tableCustomizer]="screens.tableCustomizer"
                [totalElements]="screens.totalElements"
                [isReset]="screens.isResetTable"
                [isLoading]="screens.isLoading"
                (onPage)="onTablePaging($event)"
                (item)="triggerAction($event)"
                (selectAll)="selectAllMaster($event)"
                [selectedElements]="selectedElements.asObservable()"
                [disablingElements]="disablingElements.asObservable()"
                (header)="onSortChange($event)"
                canBulkSelect="true"
              ></dooh-generic-table>
            </div>
            <div class="selected-screens">
              <p class="title">
                Selected screens
                <span class="badge">
                  <ng-container *ngIf="!(isBulkSelect$ | async)">{{
                    screens?.selectedItems?.length | number
                  }}</ng-container>
                  <ng-container *ngIf="isBulkSelect$ | async">{{
                    screens.totalElements | number
                  }}</ng-container>
                </span>
              </p>
            </div>
          </div>
        </mat-tab>
        <mat-tab [label]="'Targeting Set'" *ngIf="!hideTargetingSet">
          <div
            class="empty-results"
            *ngIf="!dataSource.data.length && isLoading === false"
          >
            There are no Targeting Sets
          </div>

          <div class="table-container targeting-set-table loader-wrapper">
            <div
              class="info-container__overlay info-container__overlay_colored loader-center--flex"
              *ngIf="isLoading"
            >
              <dooh-custom-loader></dooh-custom-loader>
            </div>

            <table
              mat-table
              [hidden]="!dataSource.data.length"
              [dataSource]="dataSource.data"
              class="mat-elevation-z8"
              matSort
              matSortDisableClear
              (matSortChange)="onTargetingSortChange($event)"
            >
              <!-- Checkbox Column -->
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox
                    (change)="$event ? toggleAllRows() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()"
                    class="generic-table__group-checkbox"
                    [disabled]="true"
                  >
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox
                    [aria-label]="checkboxLabel(row)"
                    (click)="$event.stopPropagation()"
                    (change)="
                      $event ? selection.toggle(row) : null;
                      checkElement(row, $event)
                    "
                    [checked]="selection.isSelected(row)"
                    [disabled]="isDisable(row)"
                  >
                  </mat-checkbox>
                </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header="name"
                  sortActionDescription="Sort by Name"
                >
                  Name
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.name }}
                  <mat-icon
                    svgIcon="info-blue-icon"
                    *ngIf="element.isPreset"
                    matTooltip="Prepared by {{
                      element.namespace
                    }} to make your life easier"
                  >
                  </mat-icon>
                </td>
              </ng-container>

              <!-- Screens Column -->
              <ng-container matColumnDef="countScreensTotal">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header="screens_total"
                  sortActionDescription="Sort by Screens"
                >
                  Screens
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.countScreensTotal }}
                </td>
              </ng-container>

              <!-- Audience Column -->
              <ng-container matColumnDef="weeklyAudience">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header="weekly_audience"
                  sortActionDescription="Sort by Audience"
                >
                  Audience
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.weeklyAudience | number: '1.0-0' }}
                </td>
              </ng-container>

              <!-- Date Column -->
              <ng-container matColumnDef="dateCreated">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header="created_at"
                  sortActionDescription="Sort by Date"
                >
                  Date Created
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.dateCreated | date: 'MMM d, y' }}
                </td> </ng-container
              >>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                (click)="selection.toggle(row)"
              ></tr>
            </table>
          </div>
          <mat-paginator
            class="generic-table__paging mat-paginator-sticky"
            *ngIf="
              pagingStrategy === 'byPage' &&
              dataSource &&
              dataSource.data &&
              dataSource.data.length > 0
            "
            [length]="targetingTotalElements"
            [pageIndex]="targetingCurrentPage - 1"
            [pageSize]="targetingPageSize"
            [pageSizeOptions]="pageSizes"
            (page)="onPageHandler($event)"
          >
          </mat-paginator>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <div class="map-view" [ngClass]="{ hidden: !showMap }">
    <div class="map-area">
      <div class="marker-group-table w-50" [hidden]="!showingMarkerGroupTable">
        <dooh-marker-multi-screen-table
          *ngIf="showMarkerGroup"
          (selectedScreen)="SetScreenInfo($event)"
          (toggleAllScreenSelection)="toggleAllMarkerGroupSelection($event)"
          [markers]="markerGroup"
          [editable]="true"
          [selectedScreens]="selectedScreenIds"
          [addedScreens]="addedScreensIds"
          (close)="clearInfo()"
          (toggleScreenSelection)="toggleScreenFromMap($event)"
        ></dooh-marker-multi-screen-table>
      </div>
      <div class="w-30 info-window-container" *ngIf="showingMapInfo">
        <div class="buttons">
          <div class="back-button" *ngIf="markerGroup?.length > 2">
            <button (click)="backToMarkerTable()">
              <mat-icon class="icon">chevron_left</mat-icon>
              Back
            </button>
          </div>
          <div class="close-button">
            <button
              mat-icon-button
              color="primary"
              (click)="clearInfo()"
              class="action-button"
            >
              <mat-icon svgIcon="close"></mat-icon>
            </button>
          </div>
        </div>
        <div class="marker-info-window" *ngIf="markerPopUpInfo">
          <div
            class="marker-window__cover"
            [ngClass]="{
              multiple: images.length > 1
            }"
          >
            <ng-container
              [ngTemplateOutlet]="
                images.length > 0
                  ? imageSlideshow
                  : noImage
              "
            ></ng-container>
          </div>
          <div class="marker-window__body">
            <div class="marker-window__title">
              {{ markerPopUpInfo?.name }}
            </div>

            <div class="marker-window__field-list" *ngIf="markerPopUpInfo?.formats">
              <div class="marker-window__field_format">
                <span class="marker-window__field-label">
                  {{
                    'smb.campaignManagement.campaign.tab.screens.resolution'
                      | translate
                  }}
                </span>
                <ng-template
                  ngFor
                  let-format
                  [ngForOf]="markerPopUpInfo.formats"
                >
                  <div class="format-title">
                    <p>{{ format.name }}</p>
                    <ng-template
                      ngFor
                      let-mimeType
                      [ngForOf]="format.mimeTypes"
                      let-j="index"
                    >
                      <span>
                        {{ mimeType
                        }}{{ j === format.mimeTypes.length - 1 ? '' : ', ' }}
                      </span>
                    </ng-template>
                  </div>
                </ng-template>
              </div>
            </div>

            <div class="marker-window__field-list">
              <div
                class="marker-window__field venueType-wrapper"
                *ngIf="markerPopUpInfo?.venueType"
              >
                <span class="marker-window__field-label">
                  {{
                    'smb.campaignManagement.campaign.tab.screens.venueType'
                      | translate
                  }}
                </span>

                <span
                  class="marker-window__field-value"
                  *ngIf="markerPopUpInfo?.venueType"
                >
                  {{
                    markerPopUpInfo?.venueType?.id
                      ? markerPopUpInfo?.venueType?.id !== undefined
                        ? getVenueTypeName(markerPopUpInfo?.venueType?.id)
                        : '-'
                      : '-'
                  }}
                </span>
                <span
                  class="marker-window__field-value"
                  *ngIf="!markerPopUpInfo?.venueType"
                >
                  -
                </span>
              </div>
              <div
                class="marker-window__field venueType-wrapper"
                *ngIf="markerPopUpInfo?.venueTypeId"
              >
                <span class="marker-window__field-label">
                  {{
                    'smb.campaignManagement.campaign.tab.screens.venueType'
                      | translate
                  }}
                </span>

                <span
                  class="marker-window__field-value"
                  *ngIf="markerPopUpInfo?.venueTypeId"
                >
                  {{
                    markerPopUpInfo?.venueTypeId
                      ? markerPopUpInfo?.venueTypeId !== undefined
                        ? getVenueTypeName(markerPopUpInfo?.venueTypeId)
                        : '-'
                      : '-'
                  }}
                </span>
                <span
                  class="marker-window__field-value"
                  *ngIf="!markerPopUpInfo?.venueTypeId"
                >
                  -
                </span>
              </div>
              <div class="marker-window__field">
                <div
                  class="marker-window__field"
                  matTooltip="{{ 'Expected reach per week' | translate }}"
                >
                  <span class="marker-window__field-label">
                    <span>{{ 'Audience' | translate }}</span>
                    <sup class="sup__new">New</sup>
                  </span>
                  <span
                    class="marker-window__field-value"
                    *ngIf="markerPopUpInfo?.weeklyBidderAudience"
                    >{{
                      markerPopUpInfo?.weeklyBidderAudience | number: '1.0-0'
                    }}</span
                  >
                  <span
                    class="marker-window__field-value"
                    *ngIf="!markerPopUpInfo?.weeklyBidderAudience"
                  >
                    -
                  </span>
                </div>
              </div>
            </div>
            <div class="marker-window__field-list">
              <div
                class="marker-window__field marker-window__field_street"
                *ngIf="markerPopUpInfo?.address?.street"
              >
                <span class="marker-window__field-label">
                  {{
                    'smb.campaignManagement.campaign.tab.screens.address'
                      | translate
                  }}
                </span>
                <span class="marker-window__field-value">
                  {{ markerPopUpInfo?.address?.street }}
                </span>
              </div>
              <div
                class="marker-window__field marker-window__field_street"
                *ngIf="markerPopUpInfo?.ssp?.name"
              >
                <span class="marker-window__field-label">
                  {{ 'adminPanel.instanceInfo.tab.ssp' | translate }}
                </span>
                <span class="marker-window__field-value">
                  {{ markerPopUpInfo?.ssp?.name }}
                </span>
              </div>
              <div
                class="marker-window__field marker-window__field_street"
                *ngIf="markerPopUpInfo?.publisher?.name"
              >
                <span class="marker-window__field-label">
                  {{
                    'ssp.screensManagement.screens.table.column.publisher'
                      | translate
                  }}
                </span>
                <span class="marker-window__field-value">
                  {{ markerPopUpInfo?.publisher?.name }}
                </span>
              </div>
              <div
                class="marker-window__field marker-window__field_street"
                *ngIf="markerPopUpInfo?.convertedPrice"
              >
                <span class="marker-window__field-label">
                  {{
                    'ssp.screensManagement.screens.table.column.floorPrice'
                      | translate
                  }}
                </span>
                <span class="marker-window__field-value">
                  {{ markerPopUpInfo?.convertedPrice?.floorPrice | money }}
                </span>
              </div>
            </div>

            <div class="marker-window__reach-container">
              <p class="marker__reach--title">
                <span>{{
                  'smb.campaignManagement.campaign.tab.screens.chartTitle'
                    | translate
                }}</span>
                <sup class="sup__new">New</sup>
              </p>
              <ng-container
                *ngIf="markerPopUpInfo.dayAudience?.length > 0 && showChart"
              >
                <ngx-charts-bar-vertical
                  [view]="view"
                  [scheme]="colorScheme"
                  [results]="markerPopUpInfo.dayAudience"
                  [gradient]="gradient"
                  [xAxis]="showXAxis"
                  [yAxis]="showYAxis"
                  [legend]="showLegend"
                  [showXAxisLabel]="showXAxisLabel"
                  [showYAxisLabel]="showYAxisLabel"
                  [xAxisLabel]="xAxisLabel"
                  [yAxisLabel]="yAxisLabel"
                  [tooltipDisabled]="true"
                >
                </ngx-charts-bar-vertical>
              </ng-container>
              <p
                class="marker__performance--empty"
                *ngIf="
                  markerPopUpInfo.dayAudience?.length === 0 ||
                  !markerPopUpInfo.dayAudience
                "
              >
                {{
                  'smb.campaignManagement.campaign.tab.screens.emptyScreen'
                    | translate
                }}
              </p>
            </div>

            <div class="marker-window__button-container">
              <button
                class="marker-window__button"
                mat-raised-button
                [color]="!isScreenSelected(markerPopUpInfo) ? 'primary' : ''"
                *ngIf="!markerPopUpInfo?.hasRelation"
                (click)="toggleScreenFromMap(markerPopUpInfo)"
              >
                {{ isScreenSelected(markerPopUpInfo) ? 'Remove' : 'Add' }}
                Screen
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="map"
        [ngClass]="
          showingMapInfo ? 'w-70' : showingMarkerGroupTable ? 'w-50' : 'w-100'
        "
      >
        <dooh-map-box
          #mapBox
          [markers]="markers"
          [location]="location"
          [isPaginated]="isMapPaginated"
          [pagination]="mapPagination"
          [isLoading]="isMapLoading"
          (markerClick)="showMarkerDetails($event)"
          (mapClick)="clearInfo()"
          (pageToggle)="changePage($event)"
        ></dooh-map-box>
        <div class="map-action-button">
          <div class="selected-screens">
            <p class="title">
              Selected screens
              <span class="badge">{{ screens?.selectedItems?.length }}</span>
            </p>
          </div>
          <button
            class="screen-search__button"
            mat-raised-button
            color="primary"
            (click)="saveSelectedScreens()"
            [disabled]="screens?.selectedItems?.length === 0 || isSaving"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="footer" *ngIf="!showMap">
    <div class="screen-search__footer">
      <ng-container *ngIf="!(isBulkSelect$ | async)">
        <button
          class="screen-search__button button--stroked"
          mat-button
          color="primary"
          (click)="onCloseClick()"
        >
          {{ 'generic.cancel' | translate }}
        </button>
        <button
          *ngIf="!showSearchResult && tabSelected === 0"
          mat-raised-button
          color="primary"
          class="button-container__button"
          type="button"
          [disabled]="disableSearch || screens.isLoading || disableShowResult"
          (click)="showSearchResults()"
        >
          <span>Display Search results</span>
        </button>
        <button
          *ngIf="showSearchResult && tabSelected === 0"
          class="screen-search__button"
          mat-raised-button
          color="primary"
          (click)="saveSelectedScreens()"
          [disabled]="screens?.selectedItems?.length === 0 || isSaving"
        >
          Submit
        </button>
        <button
          *ngIf="tabSelected === 1"
          class="screen-search__button"
          mat-raised-button
          color="primary"
          (click)="saveSelectedTargetingSets()"
          [disabled]="this.selection.selected.length < 1 || this.isSaving"
        >
          Submit
        </button>
      </ng-container>
      <ng-container *ngIf="isBulkSelect$ | async">
        <div
          *ngIf="{ bulkCount: bulkCount$ | async } as val"
          class="tooltip-wrapper"
          matTooltip="{{ 'bulkActions.addLimitMessage' | translate }}"
          [matTooltipDisabled]="val.bulkCount < bulkRequestLimit"
        >
          <button
            mat-raised-button
            class="btn--bulk"
            color="primary"
            (click)="addBulkScreens()"
            [disabled]="isBulkAdding || val.bulkCount >= bulkRequestLimit"
          >
            <span>{{
              'smb.campaignManagement.campaign.tab.screens.addBulkScreens'
                | translate
            }}</span>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #screenTabItemTemplate let-item="item">
  <span *ngIf="!!item" class="autocomplete-container">
    <span class="icon-autocomplete">
      <mat-icon
        svgIcon="map-pin"
        *ngIf="item?.place_id; else dsp"
      ></mat-icon>
      <ng-template #dsp>
        <mat-icon svgIcon="targeting" *ngIf="item?.tag?.startsWith('targeting'); else publisher"></mat-icon>
        <ng-template #publisher>
          <mat-icon svgIcon="users"></mat-icon>
        </ng-template>
      </ng-template>
    </span>
    <span class="autocomplete-text">
      <span class="country">{{ item?.tag.startsWith('targeting') ? item.name : item.id }}</span>
      <span class="region">{{ item.tag }}</span>
    </span>
  </span>
</ng-template>

<ng-template #imageSlideshow>
  <dooh-image-carousel
    [images]="images"
  ></dooh-image-carousel>
</ng-template>

<ng-template #noImage>
  <img
    src="../../../assets/images/no-image.svg"
    alt="drop file"
    class="no-image"
  />
</ng-template>

<ng-template #formats let-data="data">
  <dooh-screens-formats [formats]="data"></dooh-screens-formats>
</ng-template>
