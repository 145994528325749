import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ActiveNamespaceService, WhoamiService } from '@dooh/common-services';
import { NamespaceView, UserCredentials } from '@dooh/models';
import { AuthService } from '@dooh/auth';
import { ROLES } from 'apps/ssp/src/app/services/userPermissions/Roles';

const NEURON_NAMESPACE_ID = '9c1d1e2f-152e-4f35-84a4-ae1deb490b01';

@Component({
  selector: 'dooh-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
})
export class WelcomePageComponent implements OnInit {
  currentUser: UserCredentials;
  namespace: NamespaceView;
  isDefaultNamespace = false;
  isSspAdmin:boolean;
  isDspAdmin:boolean;
  isSspRole:boolean;
  isDspRole:boolean;
  logoWidth: string;
  constructor(
    private router: Router,
    private translateService: TranslateService,
    private authService: AuthService
  ) {
    this.translateService.setDefaultLang('en');
    this.namespace = ActiveNamespaceService.getNamespace();
  }

  ngOnInit() {
    this.currentUser = WhoamiService.getCurrentUser();
    this.logoWidth = this.getContainerWidth(this.namespace?.url);
    const defaultNamespaceId = localStorage.getItem('defaultNamespace');
    if (defaultNamespaceId === ActiveNamespaceService.getNamespaceId()) {
      this.isDefaultNamespace = true;
    }
    this.isSspAdmin = [this.currentUser.sspRoles].some(roles => roles.indexOf('INSTANCE_ADMIN') !== -1);
    this.isDspAdmin = [this.currentUser.dspRoles].some(roles => roles.indexOf('INSTANCE_ADMIN') !== -1);
    this.isSspRole = [this.currentUser.sspRoles].some(roles => roles.indexOf('CAMPAIGN_MANAGER') !== -1 || roles.indexOf('BUYER') !== -1  )
    this.isDspRole = [this.currentUser.dspRoles].some(roles => roles.indexOf('CAMPAIGN_MANAGER') !== -1 || roles.indexOf('ADVERTISER') !== -1  )
  }

  redirect(pagename: string) {
    this.router.navigate(['/' + pagename]);
  }

  canAccessDSP(){
    if (!this.currentUser || (this.isSspAdmin && !this.isDspAdmin)) {
      return false;
    }

    if (this.currentUser.systemAdmin || this.currentUser.namespaceAdmin || this.isDspRole) {
      return true;
    }

    return [this.currentUser.dspRoles, this.currentUser.sspRoles].some(
      (roles) => {
        return roles.indexOf('INSTANCE_ADMIN') !== -1 || roles.indexOf('ADVERTISER') !== -1;
      }
    );
  }

  canAccessAdminPanel() {
    if (!this.currentUser) {
      return false;
    }

    if (this.currentUser.systemAdmin || this.currentUser.namespaceAdmin) {
      return true;
    }

    return [this.currentUser.dspRoles, this.currentUser.sspRoles].some(
      (roles) => {
        return roles.indexOf('INSTANCE_ADMIN') !== -1 ;
      }
    );
  }

  canAccessSSP() {
    const user = this.currentUser;
    if (!user) return false;

    if (this.currentUser.systemAdmin && this.isDefaultNamespace) {
      return true;
    }

    if( (this.isSspAdmin || this.isSspRole) || (this.isDspAdmin && this.isSspAdmin )  ){
      return true;
    }else{
      return false;
    }
  }

  canAccessTutorials() {
    if (!this.currentUser || this.namespace.id !== NEURON_NAMESPACE_ID) {
      return false;
    }
    if(this.isSspRole || (this.isSspAdmin && !this.isDspAdmin)){
      return false;
    }

    return ![
      this.currentUser.dspRoles
    ].some(roles => roles.indexOf('ADVERTISER') !== -1);
  }

  logout() {
    this.authService.logout().then(() => {
    window.location.href = '/'
  });
  }

  preloadImage(url: string) {
    const img = new Image();
    img.src = url;
    return img
  }

  getContainerWidth(url: string): string {
    if (url) {
      const image = this.preloadImage(url);
      const width = image?.width;
      const height = image?.height;

      const ratio = width / height;
      if (ratio > (4/3)) {
        return '200px';
      } else {
        return '125px';
      }
    } else {
      return '';
    }
  }
}
