<div class="empty" *ngIf="!formats || formats?.length === 0">—</div>
<div class="single-format" *ngIf="formats && formats?.length === 1">
  <span class="format-name">
    {{ formats[0]?.name }}
  </span>
  <span class="pills">
    <span class="mimeType-pill" *ngFor="let mimeType of formats[0]?.mimeTypes">
      {{ mimeType | titlecase }}
    </span>
  </span>
</div>
<div
  class="multiple-formats"
  *ngIf="formats && formats?.length > 1"
  [mtxTooltip]="tooltipTpl"
>
  <span class="format-name">
    {{ formats[0]?.name }}
  </span>
  <span class="pills">
    <span class="mimeType-pill" *ngFor="let mimeType of formats[0]?.mimeTypes">
      {{ mimeType | titlecase }}
    </span>
    
  <span>+{{ formats?.length - 1 }}</span>
  </span>
</div>
<ng-template #tooltipTpl>
  <div class="panel">
    <p class="title">Supported Resolutions and Formats</p>
    <div class="formats" *ngFor="let format of formats">
      <span class="format-name">{{ format.name }}</span>
      <span class="pills">
        <span class="mimeType-pill" *ngFor="let mimeType of format?.mimeTypes">
          {{ mimeType | titlecase }}
        </span>
      </span>
    </div>
  </div>
</ng-template>
