import {
  Component,
  ComponentRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../dialog.service';
import { environment } from 'apps/sso-webapp/src/environments/environment';
import { InventoryPerformanceService } from 'libs/common-services/src/lib/inventory-performance/inventory-performance.service';
import { ScreensApi } from '../../screens-tab/screens-tab.component.models';
import { Subject, Subscription } from 'rxjs';
import { TargetingsService } from '@dooh/common-services';
import { takeUntil } from 'rxjs/operators';
import { MapBoxComponent } from '../../map-box/map-box.component';

export const MAP_PAGE_SIZE = 1000;
@Component({
  selector: 'dooh-map-dialog',
  templateUrl: './map-dialog.component.html',
  styleUrls: ['./map-dialog.component.scss'],
})
export class MapDialogComponent implements OnInit, OnDestroy {
  contentRef: ComponentRef<any>;

  markers?: any[] = [];
  location?: any;
  screensService?: ScreensApi;

  playerId = environment.isProd
    ? '13bfbc8d-630b-4f3e-b6d7-c1b97321417d'
    : '0824e78d-7479-470d-995b-230c5c70cf11';
  screenPerformanceLoading: boolean;
  mapSelectedMarkerPerformance: any[];

  // Chart options
  view: any[] = [350, 150];
  showXAxis = true;
  showYAxis = false;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = '';
  showYAxisLabel = true;
  yAxisLabel = '';

  colorScheme = {
    domain: ['#2969b0'],
  };

  isFullScreen: boolean;
  config: any;

  mapPagination = {
    totalElements: 0,
    pageIndex: 0,
    pageSize: 0,
  };
  isMapPaginated: boolean;
  totalScreens: number;
  isMapLoading: boolean;
  allVenueTypes;
  flatVenueType: any;
  unsubscriber$: Subject<void> = new Subject<void>();

  screenSubscription: Subscription;
  showingMapInfo: boolean;
  markerPopUpInfo: any;

  @ViewChild('mapBox') mapBox: MapBoxComponent;
  showChart: boolean;
  markerGroup: any[] = [];
  showingMarkerGroupTable: boolean;

  constructor(
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<MapDialogComponent>,
    public dialogService: DialogService,
    private inventoryPerformance: InventoryPerformanceService,
    private targetingsService:TargetingsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.translateService.setDefaultLang('en');
    this.markers = data?.markers;
    this.location = {};
    this.config = data?.config;
    this.totalScreens = data?.totalScreens;
    this.screensService = data?.service;
    this.targetingsService.getAllVenueTypes().pipe(takeUntil(this.unsubscriber$))
    .subscribe((res) => {
      this.allVenueTypes = res.venueTypes.categories || [];
      this.flatVenueType = this.flattenAllVenueTypes(this.allVenueTypes);
    })
  }

  ngOnInit(): void {
    this.getPreviewScreens(1, MAP_PAGE_SIZE);
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  isSeagulls(id: string) {
    return this.playerId === id;
  }

  showMarkerDetails(screenInfo: any): void {
    if (this.markerGroup?.length > 2 ) {
      const groupLocation = this.markerGroup[0]?.location;
      if (
        screenInfo?.marker?.location?.latitude === groupLocation?.latitude &&
        screenInfo?.marker?.location?.longitude === groupLocation?.longitude
        ) {
          this.showingMapInfo = false;
          this.showingMarkerGroupTable = true;
          return;
        }
    }
    const markerGroup = this.markers
      .filter(
        (marker) =>
          marker?.location?.latitude === screenInfo?.marker?.location?.latitude
      )
      .filter(
        (marker) =>
          marker?.location?.longitude ===
          screenInfo?.marker?.location?.longitude
      );
      this.markerGroup = markerGroup;
      if (markerGroup?.length > 2) {
        this.showingMapInfo = false;
        this.showingMarkerGroupTable = true;
        return;
      }

      this.markerGroup = []

    this.SetScreenInfo(screenInfo);
  }

  SetScreenInfo(screenInfo: any): void {
    this.showingMarkerGroupTable = false;
    this.showingMapInfo = true;
    this.showChart = false;
    setTimeout(() => {
      this.markerPopUpInfo = screenInfo.marker;
      this.mapBox?.resizeMap();
      this.setMarkerImage();
    });
    setTimeout(() => {
      this.showChart = true;
    }, 500);
  }

  setMarkerImage(): void {
    if (!this.markerPopUpInfo?.images) return;

    const {sspImages, defaultImages, salamanderImages } = this.markerPopUpInfo?.images;

    if (sspImages?.length > 0) {
      this.markerPopUpInfo['screenImages'] = sspImages;
      return;
    }
    if (salamanderImages?.length > 0) {
      this.markerPopUpInfo['screenImages'] = salamanderImages;
      return;
    }
    if (defaultImages?.length > 0) {
      this.markerPopUpInfo['screenImages'] = defaultImages;
      return;
    }

  }

  get images(): string[] {
    const images = [];
    if (this.markerPopUpInfo?.images?.sspImages) {
      images.push(...this.markerPopUpInfo?.images?.sspImages)
    }
    if (this.markerPopUpInfo?.images?.defaultImages) {
      images.push(...this.markerPopUpInfo?.images?.defaultImages)
    }

    return images;
  }

  ngOnDestroy(): void {
    if (this.contentRef) {
      this.contentRef.destroy();
    }
  }

  toggleFullScreen(): void {
    this.isFullScreen = !this.isFullScreen;

    if (this.isFullScreen) {
      this.dialogRef.updateSize('100%', '100%');
    } else {
      this.dialogRef.updateSize(this.config?.width, this.config?.height);
    }

    this.mapBox?.resizeMap();
  }


  clearInfo(): void {
    this.showingMapInfo = false;
    this.showingMarkerGroupTable = false;
    this.mapBox?.resizeMap();
  }

  backToMarkerTable(): void {
    this.showingMapInfo = false;
    this.showingMarkerGroupTable = true;
    this.mapBox?.resizeMap();
  }

  public setMapPagination(
    total: number,
    pageIndex: number,
    pageSize: number
  ): void {
    this.mapPagination = {
      totalElements: total,
      pageIndex: pageIndex,
      pageSize: pageSize,
    };
  }

  changePage(event: any): void {
    this.showingMapInfo = false;
    this.getPreviewScreens(event?.currentPage, event?.pageSize);
  }

  getPreviewScreens(pageNum: number, pageSize: number): void {
    if (this.isMapLoading) {
      this.screenSubscription.unsubscribe();
    }

    this.isMapLoading = true;

    this.screenSubscription = this.screensService
      .getPreviewScreens(pageNum, pageSize, null)
      .subscribe((res) => {
        this.totalScreens = res?.totalElements;
        this.markers = res?.content;
        this.isMapLoading = false;
        this.setMapPagination(this.totalScreens, pageNum, pageSize);
        if (this.totalScreens > MAP_PAGE_SIZE) {
          this.isMapPaginated = true;
        } else {
          this.isMapPaginated = false;
          this.setMapPagination(0, 0, 0);
        }
      }, (err: any) => {
        this.isMapLoading = false;
        let message = 'Something went wrong. Please try again.';
        if (err?.error?.systemMessage) {
          message = err?.error?.systemMessage;
        }
        this.dialogService.alert({
          type: 'error',
          message
        });
      });
  }


  getVenueTypeName(id){
    let parent = this.getVenueTypeParentName(id);
    return parent
  }

  getVenueTypeParentName(id){
    if(id){
      if(id.length % 2 == 0){
        const first2Str = String(id).slice(0, 2);
        const first2Num = Number(first2Str);
        let venueType = this.allVenueTypes?.filter((venue)=>{
          return venue.enumeration_id === first2Num
        })
        let venueTypeChild = this.flatVenueType?.filter((venue)=>{
          return venue.enumeration_id == id
        })
        if(venueType){
          if(!venueTypeChild){
            return `${venueType[0]?.name?.replace(/&amp;/g, "&")}`;
          }
          return `${venueType[0]?.name?.replace(/&amp;/g, "&")}/${venueTypeChild[0]?.name}`;

        }else{
          return ''
        }
      }else if(id.length % 2 !== 0){
        const firstNum =  Number(String(id)[0])
        let venueType = this.allVenueTypes?.filter((venue)=>{
          return venue.enumeration_id === firstNum
        })
        let venueTypeChild2 = this.flatVenueType?.filter((venue2)=>{
          return venue2.enumeration_id == id
        })

        if(venueType){
          if(!venueTypeChild2){
            return `${venueType[0]?.name?.replace(/&amp;/g, "&")}`;
          }
          return `${venueType[0]?.name?.replace(/&amp;/g, "&")}/${venueTypeChild2[0]?.name}`;

        }else{
          return ''
        }

      }
      return '--'
    }

  }

  flattenAllVenueTypes(members){
    let children = [];

    return members.map(m => {
      if (m.children && m.children.length) {
        children = [...children, ...m.children];
      }
      return m;
    }).concat(children.length ? this.flattenAllVenueTypes(children) : children);
  };
}
