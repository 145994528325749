<div class="login-container">
  <div class="logo-container"  [style.width]="logoWidth">
    <img *ngIf="namespace" [src]="namespace?.url" class="namespace__logo">
  </div>
  <div class="card">
      <div class="login-title">{{'login.resetPassword.title' | translate }}</div>
      <form [formGroup]="resetPassword">
        <mat-form-field appearance="outline">
          <mat-label>{{'login.email' | translate}}</mat-label>
          <input matInput
                 formControlName="email"
                 name="email"
                 placeholder="{{'login.email' | translate}}"
                 type="email">
          <mat-error *ngIf="resetPassword.controls['email'].hasError('required')">
            {{'login.required' | translate}}
          </mat-error>
          <mat-error *ngIf="resetPassword.controls['email'].hasError('pattern')">
            {{'login.emailError' | translate}}
          </mat-error>
      </mat-form-field>
      <span class="container-button">
          <button mat-raised-button
                  color="primary"
                  [disabled]="!resetPassword.valid"
                  (click)="onSubmit()">{{'login.resetPassword.button' | translate }}</button>
      </span>
      </form>
  </div>
  <div class="login-footer" *ngIf="namespace?.byNeuron">{{'login.footer' | translate}}</div>
</div>
