<div mat-dialog-title>
  <span>Add New Deal Id for Extra Inventory</span>
  <button mat-icon-button color="primary" (click)="onNoClick()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
  <mat-divider></mat-divider>
</div>
<div mat-dialog-content>
  <form [formGroup]="formGroupMain">
    <div class="form-fieldset">
      <div class="form-group">
        <mat-form-field appearance="outline" class="form-field-common form-field-common--flex">
          <mat-label>Deal ID Name</mat-label>
          <input type="text" matInput maxlength="255" formControlName="dealIdName">
          <mat-error
            *ngIf="formGroupMain.controls['dealIdName'].hasError('required') ||
            formGroupMain.controls['dealIdName'].hasError('whitespace')"
          >
            {{ 'dsp.dealIdManagement.dealForm.validationError.required' | translate }}
          </mat-error>
          <mat-error *ngIf="formGroupMain.controls['dealIdName'].hasError('pattern')">
            {{ 'dsp.dealIdManagement.dealForm.validationError.inappropriateSymbols.name' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <dooh-autocomplete-field
          class="form-field-common"
          formControlName="sspName"
          searchBy="ssp_name"
          label="SSP Name"
          [service]="sspAutocompleteService"
          [pageSize]="15"
          [serviceArgs]="instanceId"
          [extractValue]="sspExtractValue"
        ></dooh-autocomplete-field>

        <mat-form-field appearance="outline" class="form-field-common form-field-common--flex">
          <mat-label>Deal ID Code</mat-label>
          <input type="text" matInput formControlName="dealIdCode">
          <mat-error *ngIf="formGroupMain.controls['dealIdCode'].hasError('required')">
            {{ 'dsp.dealIdManagement.dealForm.validationError.required' | translate }}
          </mat-error>
          <mat-error *ngIf="formGroupMain.controls['dealIdCode'].hasError('pattern')">
            {{ 'dsp.dealIdManagement.dealForm.validationError.inappropriateSymbols.code' | translate }}
          </mat-error>
          <mat-error
            *ngIf="formGroupMain.controls['dealIdCode'].hasError('minlength') &&
            !formGroupMain.controls['dealIdCode'].hasError('required') &&
            !formGroupMain.controls['dealIdCode'].hasError('pattern')"
          >
            {{ 'dsp.dealIdManagement.dealForm.validationError.minLength' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions class="buttons-container flex-justify-right">
  <div class="buttons-container__item">
    <button mat-flat-button color="primary-inverse" (click)="onNoClick()">
      Cancel
    </button>
  </div>
  <div class="buttons-container__item">
    <button mat-flat-button color="primary" cdkFocusInitial
            [disabled]="formGroupMain.pristine || formGroupMain.invalid"
            (click)="onSubmitMainForm()">
      Add
    </button>
  </div>
</div>
