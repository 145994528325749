<div mat-dialog-title *ngIf="data.heading">
  <span>{{data.heading | translate}}</span>
  <button mat-icon-button [mat-dialog-close]="false" color="primary">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
  <mat-divider></mat-divider>
</div>
<div
  mat-dialog-content
  class="dialog-alert__content"
  [ngClass]="{'dialog-alert__content_center': !!data.isCentered}"
>
  <div
    class="dialog-alert__type"
    [ngClass]="'dialog-alert__type_' + data.type"
    *ngIf="data.type !== 'default'"
  >
    <p><i></i></p>
    <p><b>{{data.title | translate}}</b></p>
  </div>
  <p [innerHTML]="data.message | translate:(data.messageI18nArgs || {})" *ngIf="data.isHtml"><p>
  <p *ngIf="!data.isHtml">{{data.message | translate:(data.messageI18nArgs || {})}}</p>
</div>
<div
  mat-dialog-actions
  class="dialog-alert__buttons-container"
  [ngClass]="{'dialog-alert__buttons-container_center': !!data.isCentered}"
  *ngIf="data.submitButtonText"
>
  <div class="dialog-alert__button" *ngIf="data.cancelButtonText">
    <button mat-flat-button color="primary-inverse" [mat-dialog-close]="false">
      {{data.cancelButtonText | translate}}
    </button>
  </div>
  <div class="dialog-alert__button">
    <button mat-flat-button  [color]="data?.type !== 'delete' ? 'primary' : 'warn'" [mat-dialog-close]="true" cdkFocusInitial>
      {{data.submitButtonText | translate}}
    </button>
  </div>
</div>
