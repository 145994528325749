import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { DialogService } from '@dooh/components-library';
import {SmbCreateUserService} from "../services/smb-create-user.service";
import { TermOfServiceDialogComponent } from 'libs/components-library/src/lib/dialogs/term-of-service-dialog/term-of-service-dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'dooh-terms-overlay',
  templateUrl: './terms-overlay.component.html',
  styleUrls: ['./terms-overlay.component.scss']
})
export class TermsOverlayComponent implements OnInit {

  @Input() termsObj: any;
  @Output()
  closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private userService: SmbCreateUserService, 
    private dialogService: DialogService) { }

  ngOnInit(): void {
  }

  public agreeToTerms(): void {
    const user = {
      "termsOfServiceVersion": this.termsObj.version
    };
    this.userService.updateUserTermsVersion(user).subscribe(
      () => {
        localStorage.setItem('termsVersion', this.termsObj.version);
        this.closeEvent.emit(true);
      }
    )
  }

  openTermOfServiceDialog(): void {
    const dialogData: any = {
      type: 'default',
      heading: 'login.smbLogin.termsOfService.heading',
      submitButtonText: 'login.smbLogin.termsOfService.submitButton',
      isAgreed: false,
    }

    const config: MatDialogConfig = {
      disableClose: true
    }

    this.dialogService
      .openCustomComponentModal(dialogData, TermOfServiceDialogComponent, config)
      .beforeClosed()
      .subscribe(res => {
        if (res) {
          this.agreeToTerms();
        }
      });
  }

}
