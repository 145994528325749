<div class="container">
  <div class="container__title">
    <h4>Select DMA</h4>
    <button (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="loader-center" *ngIf="loadingDmaItems">
    <dooh-custom-loader></dooh-custom-loader>
  </div>

  <ng-container *ngIf="!loadingDmaItems">
    <div class="container__wrapper">
      <section>
        <dooh-search-field class="transactions-content__search" [debounceTime]="500"
          [placeHolderMessage]="'Search by DMA name'" (inputValue)="filterDma($event)"></dooh-search-field>
      </section>
      <div class="inner-loader" *ngIf="loadingFilterResult">
        <dooh-custom-loader [diameter]="60"></dooh-custom-loader>
      </div>
      <ng-container *ngIf="!loadingFilterResult">
        <form class="container__form" [formGroup]="dmaFormGroup">
          <ul class="container__listGroup">
            <li *ngFor="let dma of dmaDatasource.data">
              <label [for]="dma.dmaName + dma.id">
                <input type="checkbox" [checked]="dmaUniqueSet.has(dma.id)" name="dmaCheckbox" [id]="dma.dmaName + dma.id" (change)="onCheckboxChange($event)"
                  [value]="dma.id" />
                <span>{{ dma.dmaName }}</span>
              </label>
            </li>
          </ul>
        </form>
      </ng-container>

      <div class="container__action">
        <button class="btn" mat-raised-button (click)="closeDialog()">
          {{ 'generic.reset' | translate }}
        </button>
        <button class="btn" mat-raised-button color="primary" [disabled]="dmaUniqueSet.size < 1" (click)="continue()">
          <span>{{ 'screenProperties.footer.display' | translate }}</span>
        </button>
      </div>
    </div>
  </ng-container>
</div>
