import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  FieldFilterRequest,
  LimitsSet,
  ScreensTagsSet,
  VenueType,
} from '@dooh/models';
import { Subject } from 'rxjs';
import { Options } from 'ng5-slider';
import {
  ScreenPropertiesAutocompleteLineItemService,
  ScreenPropertiesAutocompleteTargetingService,
  ScreensFilters,
  SCREEN_TAGS,
  TargetingsService,
  ActiveNamespaceService,
} from '@dooh/common-services';
import { SearchFilterService } from '../../service/search-filter.service';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { noWhitespaceValidator } from '@dooh/validation';

enum SLIDERS {
  AUDIENCE = 'audience',
  PRICE = 'price',
}

@Component({
  selector: 'dooh-screen-properties-dialog',
  templateUrl: './screen-properties.component.html',
  styleUrls: ['./screen-properties.component.scss'],
})
export class ScreenPropertiesDialogComponent
  implements OnInit, OnDestroy, OnChanges {
  unsubscriber$ = new Subject<void>();

  @Input()
  screenPropertiesAutocompleteService: ScreenPropertiesAutocompleteLineItemService | ScreenPropertiesAutocompleteTargetingService;

  @Input()
  screenPropertiesAutocompleteServiceArgs?: any;
  @Input()
  screensFilters: ScreensFilters;

  @Input()
  allVenueTypes: VenueType[] = [];

  @ViewChild('venueTree') venueTree;
  public formGroupMain: FormGroup;

  limits: LimitsSet;
  sizes: string[] = [];
  publishers: string[] = [];
  venueTypes: any[] = [];
  mimeTypes: any[] = [];
  filteredVenueTypes: VenueType[] = [];

  loadingPublishers: boolean;
  loadingResolutions: boolean;
  loadingMimeTypes: boolean;


  optionsPrice: Options = {
    hideLimitLabels: true,
    hidePointerLabels: true,
    animate: true,
    step: 0.1,
  };
  optionsAudience: Options = {
    hideLimitLabels: true,
    hidePointerLabels: true,
    animate: true,
  };

  SCREEN_TAGS = SCREEN_TAGS;

  activeFilters: any;
  tags: ScreensTagsSet;

  SLIDERS: typeof SLIDERS = SLIDERS;
  isAudienceDragging: boolean = false;
  isPriceDragging: boolean = false;
  filterByFieldSource: any = null;
  initialFieldDataSource: any = null;

  isFilteringByVenue: boolean;
  isFilteringByPublisher: boolean;
  isFilteringByResolution: boolean;
  disableFilterButton: boolean = true;

  isEnabledPublisherFilter: boolean = true;
  isEnabledVenueTypeFilter: boolean = true;

  floorPriceSliderTouched: boolean;
  audienceSliderTouched: boolean;
  searchedPublishers: any[] = [];
  searchedSsps: any[] = [];
  videoDurations: any[] = [
    { value: 2, isSelected: false},
    { value: 6, isSelected: false},
    { value: 7, isSelected: false},
    { value: 8, isSelected: false},
    { value: 10, isSelected: false},
    { value: 15, isSelected: false}
   ];

   duration = {
    minDuration: {
      max: null,
      includeNulls: true
    },
    maxDuration: {
      min: null,
      includeNulls: true
    }
   }
   isDurationSelected: boolean;
  isFilteringByFormat: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private searchFilterService: SearchFilterService,
    private targetingsService: TargetingsService
  ) {}

  onDrag(name: SLIDERS, isDragging: boolean): void {
    if (name === SLIDERS.AUDIENCE) {
      this.isAudienceDragging = isDragging;
      this.audienceSliderTouched = true;
    } else {
      this.isPriceDragging = isDragging;
      this.floorPriceSliderTouched = true;
    }
    this.disableFilterButton = false;
  }

  ngOnInit(): void {
    this.createForms();

    this.searchFilterService.tags$.pipe(takeUntil(this.unsubscriber$)).subscribe((tags) => {
      this.tags = tags;

      if (!this.isFilteringByPublisher && !this.isFilteringByVenue && !this.isFilteringByResolution && !this.isFilteringByFormat) {
        this.updateFiltersValues();
      } else if (this.isFilteringByVenue && !this.isFilteringByPublisher  && !this.isFilteringByResolution) {
        this.updateFiltersByVenue();
      } else if (this.isFilteringByPublisher && !this.isFilteringByResolution){
        this.updateFiltersByPublisher();
      } else if (this.isFilteringByResolution) {
        this.updateFiltersByResolution();
      }
    });

    this.searchFilterService.limits$.pipe(takeUntil(this.unsubscriber$)).subscribe((limits) => {
      const getSliderValue = (sliderValue) => {
        if (
          !sliderValue ||
          (typeof sliderValue === 'string' &&
            (sliderValue as string).toLowerCase().includes('infinity')) ||
          (typeof sliderValue === 'string' &&
            (sliderValue as string).toLowerCase().includes('null'))
        ) {
          return 0;
        }
        return sliderValue;
      };

      this.limits = limits;
      this.limits.audiencePerWeek.min = getSliderValue(
        this.limits.audiencePerWeek.minPossible
      );
      this.limits.audiencePerWeek.max = getSliderValue(
        this.limits.audiencePerWeek.maxPossible
      );
      this.limits.floorPrice.min = getSliderValue(
        this.limits.floorPrice.minPossible
      );
      this.limits.floorPrice.max = getSliderValue(
        this.limits.floorPrice.maxPossible
      );

      this.optionsPrice = {
        ...this.optionsPrice,
        floor: 0,
        ceil: this.limits.floorPrice.max,
      };
      this.optionsAudience = {
        ...this.optionsAudience,
        floor: 0,
        ceil: this.limits.audiencePerWeek.max,
      };

      const sliderAudienceValue = [0, this.limits.audiencePerWeek.max];
      const sliderFloorPriceValue = [0, this.limits.floorPrice.max];

      /**
       * TODO:: Without setTimeout there is a problem when slider values isn't updating.
       * It occurs because we update slider's options (min max limits) and form values at the same time.
       * Need to investigate ng5-slider docs and issues to find better solution.
       */
      setTimeout(() => {
        this.addOrUpdateSlider('sliderAudienceControl', sliderAudienceValue);
        this.addOrUpdateSlider(
          'sliderFloorPriceControl',
          sliderFloorPriceValue
        );
      });
    });

    this.searchFilterService.screenPropertiesFilters$.subscribe(
      (activeFilters) => {
        if (activeFilters && activeFilters?.filters) {
          this.activeFilters = {
            ...activeFilters,
            tags: activeFilters.filters.tags.reduce((storage, item) => {
              storage[item.name] = item.properties;
              return storage;
            }, {}),
          };
        }
      }
    );

    this.searchFilterService.filterByFieldSource$.subscribe((res) => {
      this.filterByFieldSource = res;
    });

    this.searchFilterService.currentSearchField$.pipe(takeUntil(this.unsubscriber$)).subscribe((res: any[]) => {
      this.searchedPublishers = res.filter(data => data?.tag === 'publisher');
      this.searchedSsps = res.filter(data => data?.tag === 'ssp');
    });

    this.formGroupMain
      .get('venueTypes')
      .valueChanges.pipe(
        takeUntil(this.unsubscriber$),
        debounceTime(700),
        map((value) => {
          if (typeof value === 'object') {
            if (value?.length) {
              this.isFilteringByVenue = true;
              this.isFilteringByPublisher = false;
              this.isFilteringByResolution = false;
            } else {
              this.isFilteringByVenue = false;
            }

            this.formGroupMain.get('size').patchValue([], { onlySelf: true, emitEvent: false });
            this.formGroupMain.get('mimeType').patchValue([], { onlySelf: true, emitEvent: false });
            this.formGroupMain
              .get('publisher')
              .patchValue([], { onlySelf: true, emitEvent: false });
            this.loadingPublishers = true;
            this.loadingResolutions = true;
            this.loadingMimeTypes = true;
            this.clearDuration();
            this.filterByField();
          }
        })
      )
      .subscribe();

    this.formGroupMain
      .get('publisher')
      .valueChanges.pipe(
        takeUntil(this.unsubscriber$),
        debounceTime(700),
        map((value) => {
          if (typeof value === 'object') {
            const isFormTouched = this.formGroupMain.get('publisher').dirty;

            if (value?.length) {
              this.isFilteringByPublisher = true;
              this.isFilteringByResolution = false;
              this.isFilteringByFormat = false;
            } else {
              this.isFilteringByPublisher = false;
            }
            if (isFormTouched) {
              this.formGroupMain.get('size').patchValue([], { onlySelf: true, emitEvent: false });
              this.loadingResolutions = true;
              this.formGroupMain.get('mimeType').patchValue([], { onlySelf: true, emitEvent: false });
              this.loadingMimeTypes = true;
              this.clearDuration();
              this.filterByField();
            }
          }
        })
      )
      .subscribe();

    this.formGroupMain
      .get('size')
      .valueChanges.pipe(
        takeUntil(this.unsubscriber$),
        debounceTime(700),
        map((value) => {
          if (typeof value === 'object') {
            const isFormTouched = this.formGroupMain.get('size').dirty;
            if (value?.length) {
              this.isFilteringByResolution = true;
              this.isFilteringByFormat = false;
              this.disableFilterButton = false;
            }else {
              this.isFilteringByResolution = false;
            }
            if (isFormTouched) {
              this.formGroupMain.get('mimeType').patchValue([], { onlySelf: true, emitEvent: false });
              this.loadingMimeTypes = true;
              this.clearDuration();
              this.filterByField();
            }
          }

        })
      )
      .subscribe();


      this.formGroupMain
      .get('mimeType')
      .valueChanges.pipe(
        takeUntil(this.unsubscriber$),
        debounceTime(700),
        map((value) => {
          if (typeof value === 'object') {
            const publishers = this.formGroupMain.get('publisher').value;
            const venueTypes = this.formGroupMain.get('venueTypes').value;
            const resolutions = this.formGroupMain.get('size').value;
            const isFormTouched = this.formGroupMain.get('mimeType').dirty;
            if (value?.length) {
              this.disableFilterButton = false;
              this.isFilteringByFormat = true;
            } else {
              this.isFilteringByFormat = false;
            }
            if (
              !value?.length &&
              !publishers?.length &&
              !venueTypes?.length &&
              !resolutions?.length &&
              !this.mimeTypes?.length &&
              !this.floorPriceSliderTouched &&
              !this.audienceSliderTouched
            ) {
              this.disableFilterButton = true;
            }
            if (!value?.some(type => type === 'video')) {
              this.clearDuration();
            }
            if (isFormTouched) {
              this.filterByField();
            }
          }

        })
      )
      .subscribe();
    this.formGroupMain.markAsPristine();

    this.formGroupMain.get('customDurationControl')
      .valueChanges.pipe(
        takeUntil(this.unsubscriber$),
        debounceTime(700)
      ).subscribe(res => {
        const duration = res;

        if (duration && duration > 0) {
          this.videoDurations.forEach((item: any) => {
            item.isSelected = false;
          });
          this.duration.minDuration.max = duration;
          this.duration.minDuration.includeNulls = false;
          this.duration.maxDuration.min = duration;
          this.duration.maxDuration.includeNulls = false;
          this.isDurationSelected = true;
          const selectedMimeType: string[] = this.formGroupMain.get('mimeType').value;
          if (!selectedMimeType.some(type => type === 'video')) {
            selectedMimeType.push('video');
            this.formGroupMain.get('mimeType').patchValue(selectedMimeType, { onlySelf: true, emitEvent: false });
            this.isFilteringByFormat = true;
          }
        } else {
          this.duration.minDuration.includeNulls = true;
          this.duration.maxDuration.includeNulls = true;
          this.duration.minDuration.max = null;
          this.duration.maxDuration.min = null;
          this.isDurationSelected = false;
        }
        this.filterByField();
      })
      this.isEnabledPublisherFilter = ActiveNamespaceService.getNamespace().isEnabledPublisherFilter;
      this.isEnabledVenueTypeFilter = ActiveNamespaceService.getNamespace().isEnabledVenueTypeFilter;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.screensFilters = changes?.screensFilters?.currentValue;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();

    this.searchFilterService.setFilterByFieldSource(null);
  }



  createForms() {
    this.formGroupMain = this.formBuilder.group({
      size: { value: [], disabled: false },
      publisher: { value: [], disabled: false },
      venueTypes: { value: [], disabled: false },
      mimeType: { value: [], disabled: false },
      customDurationControl: new FormControl(null, [noWhitespaceValidator, Validators.min(1)])
    });
  }

  updateFiltersValues(): void {
    this.sizes = this.tags[SCREEN_TAGS.RESOLUTIONS]
      ? this.tags[SCREEN_TAGS.RESOLUTIONS]['values']
      : [];
    this.publishers = this.tags[SCREEN_TAGS.PUBLISHERS]
      ? this.tags[SCREEN_TAGS.PUBLISHERS]['values']
      : [];
    this.venueTypes = this.tags[SCREEN_TAGS.VENUETYPE]
      ? this.tags[SCREEN_TAGS.VENUETYPE]['values']
      : [];
    this.mimeTypes = this.tags[SCREEN_TAGS.MIMETYPE]
    ? this.tags[SCREEN_TAGS.MIMETYPE]['values']
    : [];
    this.loadingPublishers = false;
    this.loadingResolutions = false;
    this.loadingMimeTypes = false;
    this.isFilteringByFormat = false;
  }

  updateFiltersByVenue(): void {
    this.sizes = this.tags[SCREEN_TAGS.RESOLUTIONS]
      ? this.tags[SCREEN_TAGS.RESOLUTIONS]['values']
      : [];
    this.publishers = this.tags[SCREEN_TAGS.PUBLISHERS]
      ? this.tags[SCREEN_TAGS.PUBLISHERS]['values']
      : [];
    this.mimeTypes = this.tags[SCREEN_TAGS.MIMETYPE]
    ? this.tags[SCREEN_TAGS.MIMETYPE]['values']
    : [];

    this.loadingPublishers = false;
    this.loadingResolutions = false;
    this.loadingMimeTypes = false;
    this.isFilteringByFormat = false;
  }

  updateFiltersByPublisher(): void {
    this.sizes = this.tags[SCREEN_TAGS.RESOLUTIONS]
      ? this.tags[SCREEN_TAGS.RESOLUTIONS]['values']
      : [];

    this.mimeTypes = this.tags[SCREEN_TAGS.MIMETYPE]
      ? this.tags[SCREEN_TAGS.MIMETYPE]['values']
      : [];
      this.loadingPublishers = false;
      this.loadingResolutions = false;
      this.loadingMimeTypes = false;
      this.isFilteringByFormat = false;
  }
  updateFiltersByResolution(): void {
    this.mimeTypes = this.tags[SCREEN_TAGS.MIMETYPE]
      ? this.tags[SCREEN_TAGS.MIMETYPE]['values']
      : [];

      this.loadingPublishers = false;
      this.loadingResolutions = false;
      this.loadingMimeTypes = false;
      this.isFilteringByFormat = false;
  }

  filterByField(): void {
    this.disableFilterButton = true;
    if (!this.filterByFieldSource) {
      this.loadingPublishers = false;
      this.loadingResolutions = false;
      this.loadingMimeTypes = false;
      this.isFilteringByFormat = false;
      return;
    }
    const propertyArray = [
      {
        name: 'resolution',
        properties: [...this.formGroupMain.get('size').value],
      },
      {
        name: 'publisher',
        properties: [...this.formGroupMain.get('publisher').value],
      },
      {
        name: 'venue',
        properties: [...this.formGroupMain.get('venueTypes').value],
      },
      {
        name: 'mimeType',
        properties: [...this.formGroupMain.get('mimeType').value],
      }
    ];

    const limitsToSend: any = {};

    const floorPriceMin = this.formGroupMain.get('sliderFloorPriceControl')
      ?.value[0];
    const floorPriceMax = this.formGroupMain.get('sliderFloorPriceControl')
      ?.value[1];

    if (floorPriceMax - floorPriceMin > 0) {
      if (this.floorPriceSliderTouched) {
        limitsToSend.floorPrice = {
          min: Math.round(floorPriceMin),
          max: Math.round(floorPriceMax),
        };
        if (Math.round(floorPriceMin) > 0) {
          limitsToSend.floorPrice['includeNulls'] = false;
        } else {
          limitsToSend.floorPrice['includeNulls'] = true;
        }
      }
    }

    const audienceMin = this.formGroupMain.get('sliderAudienceControl')
      ?.value[0];
    const audienceMax = this.formGroupMain.get('sliderAudienceControl')
      ?.value[1];

    if (audienceMax - audienceMin > 0) {
      if (this.audienceSliderTouched) {
        limitsToSend.audiencePerWeek = {
          min: Math.round(audienceMin),
          max: Math.round(audienceMax),
        };
        if (Math.round(audienceMin) > 0) {
          limitsToSend.audiencePerWeek['includeNulls'] = false;
        } else {
          limitsToSend.audiencePerWeek['includeNulls'] = true;
        }
      }
    }
    if (this.isDurationSelected) {
      limitsToSend['minDuration'] = this.duration.minDuration;
      limitsToSend['maxDuration'] = this.duration.maxDuration;
      const mimeTypeIndex = propertyArray.findIndex(prop => prop.name === 'mimeType');

      if (mimeTypeIndex > -1) {
        const isVideoMimTypeSelected = propertyArray[mimeTypeIndex].properties.some(type => type === 'video');
        if (!isVideoMimTypeSelected) {
          propertyArray[mimeTypeIndex].properties.push('video');
        }
      }
    }

    const objectToEmit = { limits: limitsToSend, tags: propertyArray };

    let filterField: FieldFilterRequest = {
      body: JSON.parse(JSON.stringify(this.filterByFieldSource?.body)),
      queryUrl: this.filterByFieldSource?.queryUrl,
      queryParams: this.filterByFieldSource?.queryParams,
    };

    filterField.body['limits'] = objectToEmit?.limits ?? null;
    for (const tag of objectToEmit?.tags) {
      if (tag.properties?.length > 0) {
        filterField.body['params'][tag.name] = {
          filterTerms: [...tag.properties],
        };
      } else {
        delete filterField.body['params'][tag.name];
      }
    }

    if (this.searchedPublishers?.length > 0) {
      if (!filterField.body['params']?.publisher?.filterTerms) {
        filterField.body['params'].publisher = {};
        filterField.body['params'].publisher.filterTerms = [];
      }
      this.searchedPublishers.forEach((publisher) => {
        if (
          !filterField.body['params']?.publisher?.filterTerms.some(
            (x) => x === publisher?.id
          )
        ) {
          filterField.body['params']?.publisher?.filterTerms.push(publisher?.id);
        }
      });
    }
    if (this.searchedSsps?.length > 0) {
      this.searchedSsps.forEach(ssp => {
        if (
          !filterField.body['params']?.ssp?.filterTerms.some(
            (x) => x === ssp?.id
          )
        ) {
          filterField.body['params']?.ssp?.filterTerms.push(ssp?.id);
        }
      })
    }

    this.searchFilterService
      .filterByField(filterField)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (res) => {
          this.searchFilterService.setLimits(res.limits);
          this.searchFilterService.setTags(res.tags);
          this.disableFilterButton = false;
        },
        () => (this.disableFilterButton = false)
      );
  }

  onLoadMore($event: any, tagName: string) {
    if ($event && !$event.visible) {
      return;
    }

    this.searchFilterService.nextTagsPage(tagName);
  }

  filterSelected(selectedTags: string[], tags: string[]) {
    if (!selectedTags?.length) {
      return;
    }

    const tagsMap = tags.reduce((storage, tag) => {
      storage[tag] = true;
      return storage;
    }, {});

    return selectedTags.filter((tag) => !tagsMap[tag]);
  }

  changeVenueType(event: any): void {
    const allVenueTypeId = [];
    event.forEach((item) => {
      allVenueTypeId.push(item?.id);
    });
    this.formGroupMain.get('venueTypes').patchValue(allVenueTypeId);
  }

  filterNestedObj(arr: VenueType[], filterVal: string): VenueType[] {
    let newArr = arr.filter((item: VenueType) => {
      let containsValue;
      if (item?.enumeration_id === +filterVal) return item;

      if (item.children) {
        const children = this.filterNestedObj(item.children, filterVal);
        containsValue = children.length > 0;
        item.children = containsValue ? children : null;
      }
      return containsValue;
    });
    return newArr;
  }
  roundedFigure(value: number): number {
    if (!value || typeof value !== 'number') return 0;
    return this.getLocaleString(Math.round(value));
  }

  getLocaleString(value: any): any {
    return value.toLocaleString(navigator.language);
  }

  onReset(triggerGetScreen = true) {
    this.venueTree?.resetSelection();
    this.formGroupMain.patchValue({
      size: [],
      publisher: [],
      venueTypes: [],
      mimeType:[],
      sliderAudienceControl: [
        this.limits.audiencePerWeek.min,
        this.limits.audiencePerWeek.max,
      ],
      sliderFloorPriceControl: [
        this.limits?.floorPrice?.min,
        this.limits?.floorPrice?.max,
      ],
    }, { onlySelf: true, emitEvent: false });
    this.clearDuration();
    this.disableFilterButton = true;
    this.loadingPublishers = false;
    this.loadingResolutions = false;
    this.loadingMimeTypes = false;
    this.isFilteringByVenue = false;
    this.isFilteringByPublisher = false;
    this.isFilteringByResolution = false;
    this.floorPriceSliderTouched = false;
    this.audienceSliderTouched = false;
    this.searchFilterService.setFilterByFieldSource(null);
    this.searchFilterService.setScreenPropertiesFilters(null, triggerGetScreen);
  }

  filerProperties() {
    const propertyArray = [

      {
        name: 'resolution',
        properties: [...this.formGroupMain.get('size').value],
      },
      {
        name: 'publisher',
        properties: [...this.formGroupMain.get('publisher').value],
      },
      {
        name: 'venue',
        properties: [...this.formGroupMain.get('venueTypes').value],
      },
      {
        name: 'mimeType',
        properties: [...this.formGroupMain.get('mimeType').value],
      }
    ];
    const limitsToSend: any = {};

    const floorPriceMin = this.formGroupMain.get('sliderFloorPriceControl')
      ?.value[0];
    const floorPriceMax = this.formGroupMain.get('sliderFloorPriceControl')
      ?.value[1];

    if (floorPriceMax - floorPriceMin > 0) {
      if (this.floorPriceSliderTouched) {
        limitsToSend.floorPrice = {
          min: Math.round(floorPriceMin),
          max: Math.round(floorPriceMax),
        };
        if (Math.round(floorPriceMin) > 0) {
          limitsToSend.floorPrice['includeNulls'] = false;
        } else {
          limitsToSend.floorPrice['includeNulls'] = true;
        }
      }
    }

    const audienceMin = this.formGroupMain.get('sliderAudienceControl')
      ?.value[0];
    const audienceMax = this.formGroupMain.get('sliderAudienceControl')
      ?.value[1];

    if (audienceMax - audienceMin > 0) {
      if (this.audienceSliderTouched) {
        limitsToSend.audiencePerWeek = {
          min: Math.round(audienceMin),
          max: Math.round(audienceMax),
        };
        if (Math.round(audienceMin) > 0) {
          limitsToSend.audiencePerWeek['includeNulls'] = false;
        } else {
          limitsToSend.audiencePerWeek['includeNulls'] = true;
        }
      }
    }

    if (this.isDurationSelected) {
      limitsToSend['minDuration'] = this.duration.minDuration;
      limitsToSend['maxDuration'] = this.duration.maxDuration;
      const mimeTypeIndex = propertyArray.findIndex(prop => prop.name === 'mimeType');

      if (mimeTypeIndex > -1) {
        const isVideoMimTypeSelected = propertyArray[mimeTypeIndex].properties.some(type => type === 'video');
        if (!isVideoMimTypeSelected) {
          propertyArray[mimeTypeIndex].properties.push('video');
        }
      }
    }

    const objectToEmit = { limits: limitsToSend, tags: propertyArray };
    this.searchFilterService.setScreenPropertiesFilters(objectToEmit);
  }

  addOrUpdateSlider(key: string, value: any) {
    if (!this.formGroupMain.get(key)) {
      this.formGroupMain.addControl(key, new FormControl(value));
      this.formGroupMain.get(key).markAsPristine();
    } else {
      this.formGroupMain.get(key).patchValue(value);
    }
  }

  sortVenueType(venueTypeArr: VenueType[]): void {
    venueTypeArr.forEach((venueType: VenueType) => {
      if (venueType.children) {
        venueType.children.sort((a, b) => a.name.localeCompare(b.name));
        this.sortVenueType(venueType.children);
      } else {
        return;
      }
    })
  }

  toggleDuration(index: any): void {
    this.videoDurations[index].isSelected = !this.videoDurations[index].isSelected;

    this.videoDurations.forEach((item: any, i: number) => {
      if (i !== index) {
        item.isSelected = false;
      }
    });

    const activeSeconds = this.videoDurations.find(x => x.isSelected)?.value;
    this.clearCustomDuration();
    if (activeSeconds) {
      this.duration.minDuration.max = activeSeconds;
      this.duration.minDuration.includeNulls = false;
      this.duration.maxDuration.min = activeSeconds;
      this.duration.maxDuration.includeNulls = false;
      this.isDurationSelected = true;
      const selectedMimeType: string[] = this.formGroupMain.get('mimeType').value;
      if (!selectedMimeType.some(type => type === 'video')) {
        selectedMimeType.push('video');
        this.formGroupMain.get('mimeType').patchValue(selectedMimeType, { emitEvent: false, onlySelf: true });
        this.isFilteringByFormat = true;
      }
    } else {
      this.duration.minDuration.includeNulls = true;
      this.duration.maxDuration.includeNulls = true;
      this.duration.minDuration.max = null;
      this.duration.maxDuration.min = null;
      this.isDurationSelected = false;
    }
    this.disableFilterButton = false;
    this.filterByField()
  }

  clearCustomDuration(reset?: boolean): void {
    this.formGroupMain.get('customDurationControl').patchValue(null, { emitEvent: false, onlySelf: true });
    this.isDurationSelected = false;
    if(reset) this.filterByField();
  }

  clearDuration(): void {
    this.duration = {
      minDuration: {
        max: null,
        includeNulls: true
      },
      maxDuration: {
        min: null,
        includeNulls: true
      }
     };
     this.clearCustomDuration();
     this.videoDurations.forEach((item: any) => {
      item.isSelected = false;
     });
     this.isDurationSelected = false;

  }

  isVideoMimeTypeAvailable(): boolean {
    return this.mimeTypes.some(mime => mime === 'video') || this.isDurationSelected;
  }
}
