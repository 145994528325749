import { Component, Input, OnInit } from '@angular/core';

const ERROR_ICON_TYPE = 'error';
const ERROR_ICON = 'alert-triangle';

@Component({
  selector: 'dooh-tab-info',
  templateUrl: './tab-info.component.html',
  styleUrls: ['./tab-info.component.scss']
})
export class TabInfoComponent implements OnInit {

  @Input()
  title?: string;

  @Input()
  count?: number;

  @Input()
  description: string;

  @Input()
  icon: string;

  @Input()
  iconType?: string;

  @Input()
  routerLink: string;

  @Input()
  queryParams?: Object;

  @Input()
  isActive?: boolean;

  @Input()
  disabled?: boolean;

  @Input()
  isError?: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  getIconClass() {
    const iconType = this.isError ? ERROR_ICON_TYPE : this.iconType
    return `tab-info__icon_${iconType}`;
  }

  getIcon() {
    return this.isError ? ERROR_ICON : this.icon;
  }
}
