<div class="description-container">
  <span>
    {{ 'hourWeeklyTable.daysOfWeek' | translate }}
  </span>
  <span>
    {{ 'hourWeeklyTable.time' | translate }}
  </span>
</div>
<ng-container *ngIf="editable" >
  <span #containerday></span>
</ng-container>
<ng-container *ngIf="!editable">
  <dooh-hour-range-edit-table [data]="fullInitData" (change)="changeValue.emit(true)"></dooh-hour-range-edit-table>
  <span #containerday></span>
</ng-container>
