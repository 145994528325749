import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfirmExistDialogService } from '../confirm-exist-dialog/confirm-exist-dialog.service';

@Injectable({
  providedIn: 'root'
})

export class CanDeactivateCampaignService implements CanDeactivate<CanExit> {
  isAccepted: boolean = true;

  constructor(private confirmExistDialogService: ConfirmExistDialogService) { }

  canDeactivate(component: CanExit): boolean {
    const canDeactivate = component.canDeactivate();
    if (!canDeactivate && this.isAccepted) {
      return component.openCancelConfirmDialog().afterClosed().pipe(
        map(res =>{
          if(res && component.screensViewService){
            component.screensViewService.resetSharedData();
          }
          if (!!res === false) {
            this.isAccepted = true;
          } else {
            this.isAccepted = false;
          }
          this.confirmExistDialogService.setConfirmDialog(!!res);
          return !!res}
        )
      );
    }
    this.confirmExistDialogService.setConfirmDialog(true);
    return true;
  }

}

export interface CanExit {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
  openCancelConfirmDialog: () => Observable<boolean> | Promise<boolean> | any;
  screensViewService: any
}
