<div class="demographics">
  <header class="header">
    <p class="title">Behavioral Targeting</p>
    <mat-icon svgIcon="close" class="close" (click)="closeDialog()"></mat-icon>
  </header>
  <div class="body">
    <form class="sidenav-search">
      <mat-form-field
        appearance="outline"
        class="search-field"
      >
        <input
          matInput
          name="name"
          placeholder="Search Categories"
          maxlength="255"
          [(ngModel)]="searchValue"
          (input)="onKeywordChange()"
        />
        <button class="search-field__button color-gray-2" mat-icon-button matSuffix type="submit">
          <mat-icon class="search-field__icon" matSuffix>search</mat-icon>
        </button>
      </mat-form-field>
    </form>

    <div class="poi-filters">
      <mat-accordion [multi]="true">
        <mat-expansion-panel *ngFor="let item of filteredList" [expanded]="openSelected(i, step)|| allExpandState">
          <mat-expansion-panel-header>
            <div class="poi-filter__group">
              <mat-panel-title>
                <!-- <mat-icon
                  class="poi-filter__icon"
                  [svgIcon]="item.name | lowercase"
                ></mat-icon> -->
                <span>
                  {{ item.name }}
                </span>
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>

          <div
            class="content-panel"
            *ngFor="let value of item.children.values()"
          >
            <span class="content-panel__check">
              <mat-checkbox
                class="poi-filter__selector"
                color="primary"
                [value]="value"
                (change)="$event ? selection.toggle(item.name + '->' + value) : null"
                [checked]="selection.isSelected(item.name + '->' + value)"
              ></mat-checkbox>
            </span>
            <span class="content-panel__content">
              <span class="content-panel__name">
                {{ value | pascalCaseToText }}
              </span>
            </span>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <footer class="footer">
    <button class="btn btn--outline" (click)="closeDialog()">Cancel</button>
    <button class="btn btn--primary" (click)="submit()">Add</button>
  </footer>
</div>
