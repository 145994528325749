import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dooh-audience-reach',
  templateUrl: './audience-reach.component.html',
  styleUrls: ['./audience-reach.component.scss']
})
export class AudienceReachComponent implements OnInit {

  expectedValue: number;
  @Input()
  set expectedReach (value: number){
    this.expectedValue = Math.round(value)
  }

  constructor() {
  }

  ngOnInit(): void {
  }

}
