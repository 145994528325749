import {
  Component,
  Input,
  OnChanges,
  OnInit
} from '@angular/core';

@Component({
  selector: 'dooh-mandatory-option',
  templateUrl: './mandatory-option.component.html',
  styleUrls: ['./mandatory-option.component.scss']
})
export class MandatoryOptionComponent implements OnInit{

  @Input()
  name: string = '';

  @Input()
  checked: boolean = false;

  @Input() option: any;
  acceptedImageFormat: string;
  acceptedVideoFormat: string;
  acceptsImg: boolean;
  acceptsVideo: boolean;

  constructor() { }

  ngOnInit(): void {
    this.generateAcceptedFormat(this.option.mimeTypes); 
  }
  public generateAcceptedFormat(mimeTypes:string[]):void{
    let formats = mimeTypes.reduce(
      (acc, mimeType)=>{
      let value = mimeType.split('/');
      if(value[0]=== 'video'){
        this.acceptsVideo = true;
      }
      if(value[0]=== 'image'){
        this.acceptsImg = true;
      }
      if(value[1] !== '*'){
        if(value[0] === 'image'){
          acc.image.push(value[1].toLocaleUpperCase());
        }
        if(value[0] === 'video'){
          acc.video.push(value[1].toLocaleUpperCase());
        }
      }
      else{
        if(value[0] === 'image'){
          acc.image.push(value[0].toLocaleUpperCase());
        }
        if(value[0] === 'video'){
          acc.video.push(value[0].toLocaleUpperCase());
        }  
      }
      return acc
    },{image:[], video:[]})
    
    this.acceptedImageFormat = formats.image.join(' | ');
    this.acceptedVideoFormat = formats.video.join(' | ');
  }
}
