import { Injectable } from '@angular/core';
import {
  UrlTree,
  CanActivate,
  Router
} from '@angular/router';
import { Auth } from 'aws-amplify';
import { Observable } from "rxjs";

@Injectable()
export class CanActivateInstanceCreate implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    return Auth.currentSession().then(currentSession => {
      const currentToken = currentSession.getIdToken();
      const agencyId = currentToken.payload['custom:agencies'];
      const instanceId = currentToken.payload['custom:instances'];
      if (agencyId) {
        localStorage.setItem('agencyId', agencyId);
      }
      if (instanceId) {
        localStorage.setItem('smbInstance', instanceId);
      }
      if (!agencyId || !instanceId) {
        return true;
      }
      this.router.navigate(['smb', 'dashboard'], {replaceUrl:true});
      return false;
    })
  }
}
