export interface NamespaceInventory {
    id: string;
    mode: NamespaceInventoryMode;
    publishers: NamespaceInventoryPublisher[] | string[];
    name?: string
}

export interface NamespaceInventoryPublisher {
    id: string;
    name: string;
}

export enum NamespaceInventoryMode {
    All = 'ALL',
    Exclude = 'ALL_WITHOUT_EXCLUDED',
    Include = 'ONLY_INCLUDED'
}