import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DmaStateService {
  dmaAggData: BehaviorSubject<string[]> = new BehaviorSubject([]);

  setDmaAggData(data): void {
    this.dmaAggData.next(data);
  }

  getDmaAggData(): Observable<string[]> {
    return this.dmaAggData.asObservable();
  }


  constructor() {}
}
