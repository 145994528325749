import { Injectable } from '@angular/core';
import { ScreenView } from '@dooh/models';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private tableMapData = new ReplaySubject(1);
  data = this.tableMapData.asObservable();

  private poisData = new ReplaySubject(1);
  pois = this.poisData.asObservable();

  private mapBoundForPoi = new ReplaySubject<any>(1);
  mapBoundForPoi$ = this.mapBoundForPoi.asObservable();

  private poisMapData = new ReplaySubject(1);
  poisMap = this.poisMapData.asObservable();

  private poisTableData = new ReplaySubject(1);
  poisTable = this.poisTableData.asObservable();

  private searchPOIData = new ReplaySubject(1);
  searchPoi = this.searchPOIData.asObservable();

  private markerIconMapSource = new ReplaySubject(1);
  markerIconMap$ = this.markerIconMapSource.asObservable();

  private markerTypeMapSource = new ReplaySubject(1);
  markerTypeMap$ = this.markerTypeMapSource.asObservable();

  private addScreenFromMapSource = new ReplaySubject<any>(1);
  addScreenFromMap$ = this.addScreenFromMapSource.asObservable();

  private removeScreenFromMapSource = new ReplaySubject<ScreenView>(1);
  removeScreenFromMap$ = this.removeScreenFromMapSource.asObservable();

  private addScreensOnVisibleMap = new ReplaySubject<any>(1);
  addScreensOnVisibleMap$ = this.addScreensOnVisibleMap.asObservable();

  private isPoiLoadingSource = new ReplaySubject<boolean>(1);
  isPoiLoading$ = this.isPoiLoadingSource.asObservable();

  private activeGeoSearchButton = new  ReplaySubject<boolean>();
  activeGeoSearchButton$ = this.activeGeoSearchButton.asObservable();

  private syncingSearchField = new BehaviorSubject<any>(null);
  currentSearchField$ = this.syncingSearchField.asObservable();

  constructor(
  ) { }


  setData(data: any){
    this.tableMapData.next(data);
  }

  getData(): Observable<any>{
    return this.data;
  }

  setActiveGeoSearchButton(isActive: boolean){
    this.activeGeoSearchButton.next(isActive);
  }

  setMapBoundForPoi(data: any): void {
    this.mapBoundForPoi.next(data);
  }

  getMapBoundForPoi(): Observable<any> {
    return this.mapBoundForPoi$;
  }

  setPoiLoading(loading: boolean) {
    this.isPoiLoadingSource.next(loading);
  }

  setPoisData(data: any){
    this.poisData.next(data);
  }

  getPoisData(): Observable<any>{
    return this.pois;
  }

  setPoisMapData(data: any){
    this.poisMapData.next(data);
  }

  getPoisMapData(){
    return this.poisMap;
  }

  setPoisTableData(data: any){
    this.poisTableData.next(data)
  }

  getPoisTableData(){
    return this.poisTable;
  }

  setSearchPOIData(data: any) {
    this.searchPOIData.next(data);
  }

  getSearchPOIData(){
    return this.searchPoi;
  }

  setMarkerIconMap(markerIconMap) {
    this.markerIconMapSource.next(markerIconMap);
  }

  setMarkerTypeMap(markerTypeMap) {
    this.markerTypeMapSource.next(markerTypeMap);
  }

  setAddScreenFromMap(screen) {
    this.addScreenFromMapSource.next(screen);
  }

  setRemoveScreenFromMap(screen) {
    this.removeScreenFromMapSource.next(screen);
  }

  setAddScreensonVisibleMap(screens){
    this.addScreensOnVisibleMap.next(screens);
  }

  syncSearchField(value: any) {
    this.syncingSearchField.next(value)
  }
}
