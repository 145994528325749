import { Observable } from 'rxjs';

import { PageDto } from '@dooh/models';

export interface AutocompleteService {
  getAll(
    page: number,
    pageSize: number,
    filters?: Array<string>,
    args?: any,
    keyword?: string,
    multiSelection?: any
  ): Observable<PageDto>;
  reverseGeocodeFromGoogle?(placeId: string): Observable<any>;
  setCountry?(value: string);
};

export interface ValidationError {
  key: string;
  value: string | Observable<string>;
};
