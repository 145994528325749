import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
// tslint:disable-next-line: nx-enforce-module-boundaries
import { environment } from '../../../../../apps/sso-webapp/src/environments/environment';
@Injectable()
export class ApiService {
  constructor(private http: HttpClient) {}

  get(url: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.api_url}/${url}`, {
      headers: this.headers,
      params
    });
  }

  post(url: string, data: Object = {}, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.post(
      `${environment.api_url}/${url}`,
      JSON.stringify(data),
      {
        headers: this.headers,
        params
      }
    );

  }

  postBlob(url: string, data: FormData , params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.post(
      `${environment.api_url}/${url}`,
      data,
      {
        headers: this.headersUpload,
        params
      }
    );

  }

  put(url: string, data: Object = {}): Observable<any> {
    return this.http.put(`${environment.api_url}/${url}`, JSON.stringify(data), {
      headers: this.headers
    });
  }

  getThridPartyServer(
    url: string
  ): Observable<any> {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Without-Auth': 'true'
    };
    const headers = new HttpHeaders(headersConfig);

    return this.http.get(url, { headers });
  }
  putThridPartyServer(
    url: string,
    data: Object = {},
    contentType: string
  ): Observable<any> {
    const headersConfig = {
      'Content-Type': contentType,
      'Without-Auth': 'true'
    };
    const headers = new HttpHeaders(headersConfig);

    return this.http.put(url, data, { headers });
  }



  delete(url: string, data: Object = {}): Observable<any> {
    const options = {
      headers: this.headers,
      body: data
    };
    return this.http.delete(`${environment.api_url}/${url}`, options);
  }

  patch(url: string, data: Object = {}): Observable<any> {
    return this.http.patch(
      `${environment.api_url}/${url}`,
      JSON.stringify(data),
      { headers: this.headers }
    );
  }

  get headers(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    return new HttpHeaders(headersConfig);
  }

  get headersUpload(): HttpHeaders {
    const headersConfig = {

      Accept: 'application/json'
    };

    return new HttpHeaders(headersConfig);
  }

  getCVS(url: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.api_url}/${url}`, {
      headers: this.headersCMS,
      responseType: 'blob',
      params
    });
  }

  getThirdPartyBlob(url: string, params: HttpParams = new HttpParams()): Observable<any> {
    const headersConfig = {
      Accept: 'text/csv',
      'Without-Auth': 'true'
    };

    return this.http.get(`${url}`, {
      headers: new HttpHeaders(headersConfig),
      responseType: 'blob',
      params
    });
  }

  get headersCMS(): HttpHeaders {
    const headersConfig = {
      Accept: 'text/csv',
      'Without-Auth': 'true'
    };

    return new HttpHeaders(headersConfig);
  }

  setHeaders(headers: [{key: string, value: string}]){
    headers.map((header) => {this.headers.append(header.key, header.value)});
  }
}
