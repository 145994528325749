import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { WhoamiService } from '@dooh/common-services';
import { Subscription } from 'rxjs';
import { Userpilot } from 'userpilot';
import { AdministrationPanelService, AdministratorItem } from '../services/administration-panel.service';

@Component({
  selector: 'dooh-selection-panel',
  templateUrl: './selection-panel.component.html',
  styleUrls: ['./selection-panel.component.scss']
})
export class SelectionPanelComponent implements OnInit {
  administratorItems: AdministratorItem[] = [];
  readonly MAX_NUMBER_TO_PRESENT_IN_PANEL = 5;

  userpilotSubscription: Subscription;
  constructor(
    private administrationPanelService: AdministrationPanelService,
    private router: Router
  ) {

    this.userpilotSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        Userpilot.reload();
      }
    });
    this.administrationPanelService.getJSON().subscribe((resp: AdministratorItem[]) => {
      this.administratorItems = resp;
      this.setVisibleItems(this.administratorItems);
      if (resp.length >= this.MAX_NUMBER_TO_PRESENT_IN_PANEL) {
        const othersItem: AdministratorItem = {
          icon: 'menu-dots-badge.svg',
          title: 'Others',
          isVisible: true
        };
        this.administratorItems.splice(this.MAX_NUMBER_TO_PRESENT_IN_PANEL, 0, othersItem);
      }

    });
  }

  ngOnInit() {
  }
  private setVisibleItems(administratorItems: AdministratorItem[]) {
    for (let i = 0; i < administratorItems.length; i++) {
      administratorItems[i].isVisible = i >= this.MAX_NUMBER_TO_PRESENT_IN_PANEL ? false : true;
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this.userpilotSubscription)
      this.userpilotSubscription.unsubscribe();
  }

}
