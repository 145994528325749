import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActiveHourWeeklyTableService {

  private hourRangeErrorNumber = new Subject<number>();
  private selectedWeekNumber = new Subject<number>();

  hasHourRangeError$ = this.hourRangeErrorNumber.asObservable();
  selectedWeekNumber$ = this.selectedWeekNumber.asObservable();

  setHourRangeErrorCount(errorCount: number) {
    this.hourRangeErrorNumber.next(errorCount);
  }

  setSelectedWeekNumber(count: number) {
    this.selectedWeekNumber.next(count);
  }

}
