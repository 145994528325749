import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as XRegExp from 'xregexp';

export default function xregexpValidator(pattern: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;
    const regex = XRegExp(pattern, 'u');
    
    if (!value || XRegExp.test(value, regex)) {
      return null;
    } else {
      return { xregexp: true };
    }
  };
}
