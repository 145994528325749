import { Injectable } from '@angular/core';
import { ApiService, CustomHttpUrlEncodingCodec } from '@dooh/api';
import { Observable } from 'rxjs';
import { PageDto } from '@dooh/models';
import { HttpParams } from '@angular/common/http';

const apiUrl = 'dsp';

@Injectable({
  providedIn: 'root'
})
export class TriggersService {

  filters = [];

  constructor(
    public apiService: ApiService
  ) { }

  getCommonApiUrl() {
    return apiUrl;
  }

  getSpecificApiUrl() {
    return this.getCommonApiUrl();
  }

  postPollutionTriggers(body:any):Observable<any>{
    const queryUrl = `${this.getSpecificApiUrl()}/air_triggers`;
    return this.apiService.post(queryUrl,body);
  }
  postWeatherTriggers(body:any):Observable<any>{
    const queryUrl = `${this.getSpecificApiUrl()}/weather_triggers`;
    return this.apiService.post(queryUrl,body);
  }

  postCryptoTriggers(body:any):Observable<any>{
    const queryUrl = `${this.getSpecificApiUrl()}/crypto_triggers`;
    return this.apiService.post(queryUrl,body);
  }

  postCustomTriggers(body:any) :Observable<any> {
    const queryUrl = `${this.getSpecificApiUrl()}/custom_triggers`;
    return this.apiService.post(queryUrl, body);
  }

  putPollutionTriggers(body:any,triggerId):Observable<any>{
    const queryUrl = `${this.getSpecificApiUrl()}/air_triggers/${triggerId}`;
    return this.apiService.put(queryUrl,body);
  }
  putWeatherTriggers(body:any,triggerId):Observable<any>{
    const queryUrl = `${this.getSpecificApiUrl()}/weather_triggers/${triggerId}`;
    return this.apiService.put(queryUrl,body);
  }
  //there is no crypto api for smb
  putCryptoTriggers(body:any,triggerId):Observable<any>{
    const queryUrl = `${this.getSpecificApiUrl()}/crypto_triggers/${triggerId}`;
    return this.apiService.put(queryUrl,body);
  }

  putCustomTriggers(body: any, triggerId: string):Observable<any>{
    const queryUrl = `${this.getSpecificApiUrl()}/custom_triggers/${triggerId}`;
    return this.apiService.put(queryUrl,body);
  }
  deletePollutionTriggers(triggerId,creativeId):Observable<any>{
    const queryUrl = `${this.getSpecificApiUrl()}/air_triggers/${triggerId}/${creativeId}`;
    return this.apiService.delete(queryUrl);
  }
  deleteWeatherTriggers(triggerId,creativeId):Observable<any>{
    const queryUrl = `${this.getSpecificApiUrl()}/weather_triggers/${triggerId}/${creativeId}`;
    return this.apiService.delete(queryUrl);
  }
  //therer is no crypto api for smb
  deleteCryptoTriggers(triggerId,creativeId):Observable<any>{
    const queryUrl = `dsp/crypto_triggers/${triggerId}/${creativeId}`;
    return this.apiService.delete(queryUrl);
  }

  deleteCustomTriggers(triggerId: string, creativeId: string): Observable<any>{
    const queryUrl = `${this.getSpecificApiUrl()}/custom_triggers/${triggerId}/${creativeId}`;
    return this.apiService.delete(queryUrl);
  }
  
  getAll(page: number = 1, pageSize: number = 50, filters?: string[]):  Observable<PageDto>{

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    // const searchTag = '*' + search.toLowerCase() + '*';
    queryParameters = queryParameters.set('page', <any>page);
    queryParameters = queryParameters.set('page_size', <any>pageSize);

    filters = [ ...this.filters, ...(filters && filters.length ? filters : []) ];    

    queryParameters = queryParameters.set('search_string', <any>filters.join(';'));

    return this.apiService.get(`${this.getSpecificApiUrl()}/crypto_currency`, queryParameters);
  }

}
