import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { SmbCreateUserService } from '../services/smb-create-user.service';

@Injectable({
  providedIn: 'root'
})
export class SmbUserResolverService implements Resolve<any> {

  constructor(private smbCreateUserService:SmbCreateUserService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.smbCreateUserService.getSMBUser().pipe(
      retry(1),
      catchError(error => {
        return of('No data');
      })
    );
  }
}
