import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SelectedInstanceService } from '@dooh/common-services';

@Pipe({ name: 'usDate' })
export class UsDatePipe implements PipeTransform {
  // adding a default format in case you don't want to pass the format
  // then 'dd/MM/yyyy' will be used
    
  transform(date: Date | string, format: string = 'dd/MM/yyyy'): string {
    if(date){
      if(SelectedInstanceService.getInstance() && format === "dd/MM/yyyy"){ //in case format is sent in US instance
          const instance = SelectedInstanceService.getInstance();
          if(instance && instance.country.code === "US"){
              format = 'MM/dd/yyyy';
          }
      }
      date = new Date(date);  // if orginal type was a string
      date.setDate(date.getDate());
      return new DatePipe('en-US').transform(date, format);
    }
  }
}