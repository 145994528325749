<div class="container">
  <form [formGroup]="shareForm" class="share-form">
    <div class="share-settings">
      <div class="share-settings-hiding">
        <h2 class="share-settings__title margin-top">Share Campaign Plan</h2>
        <div>
          <span mat-button (click)="closeDialog()" class="dialog-custom__close-button">
            <mat-icon mat-button svgIcon="close"></mat-icon>
          </span>
        </div>
      </div>
      <div class="share-settings__input-wrap">
        <p class="share-settings__description">Who would you like to share the Campaign with?</p>
        <input 
          type="text" 
          maxlength="255" 
          formControlName="email" 
          class="share-settings__input"
          placeholder="Enter comma separated emails" 
          (keydown.enter)="$event.preventDefault()"

          />
          <mat-error *ngIf="shareForm.get('email').hasError('required') && shareForm.get('email').touched">
            {{'Email is Required' | translate}}
          </mat-error>
          <mat-error *ngIf="shareForm.controls['email'].hasError('pattern') && shareForm.get('email').touched">
            {{'Email Not valid' | translate}}
          </mat-error>
      </div>
      
      <div class="share-settings__input-wrap">
        <p class="share-settings__description CPM">Advertiser CPM Markup</p>
        <label class="sr-only" for="exampleInputAmount">%</label>

        <input 
          type="text" 
          formControlName="advertiserMarkup" 
          class="share-settings__input"
          placeholder="Enter Markup"
          (blur)="updateAdMarkupValue($event)"
        />
      </div>
      <div>
        <mat-error *ngIf="shareForm.get('advertiserMarkup').hasError('pattern') && shareForm.get('advertiserMarkup').touched">
          {{ 'Invalid CPM format' | translate }}
        </mat-error>
        <mat-error *ngIf="shareForm.get('advertiserMarkup').hasError('min') && shareForm.get('advertiserMarkup').touched">
          {{ 'Minimum CPM is 0' | translate }}
        </mat-error>
        <mat-error *ngIf="shareForm.get('advertiserMarkup').hasError('max') && shareForm.get('advertiserMarkup').touched">
          {{ 'Maximum CPM is 100' | translate }}
        </mat-error>
       </div> 
      <div>
        <mat-checkbox formControlName="showCPM" class="share-settings__checkbox">Show CPM</mat-checkbox>
      </div>
      <div class="share-settings__input-wrap">
        <mat-checkbox formControlName="showStrategy">
          Show Strategy
        </mat-checkbox>
        <textarea 
        *ngIf="shareForm.get('showStrategy').value" 
        formControlName="strategy" 
        class="share-settings__input"
        (keydown.enter)="$event.preventDefault()"
        >
        </textarea>
      </div>
      <div class="share-settings__input-wrap">
        <mat-checkbox formControlName="showTactics">
          Show Tactics
        </mat-checkbox>
        <textarea 
        *ngIf="shareForm.get('showTactics').value" 
        formControlName="tactics" 
        class="share-settings__input"
        (keydown.enter)="$event.preventDefault()"
        >
        </textarea>
      </div>
    </div>
    <div class="dialog-custom-buttons margin-top">
      <button mat-button (click)="closeDialog()" class="dialog-custom__close-button">Close</button>
      <button mat-button type="submit" color="primary" class="dialog-custom__share-button" [disabled]="!shareForm.valid" (click)="shareCampaign()">Share Campaign Plan</button>
    </div>
  </form>
</div>