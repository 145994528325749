import { Component, OnInit, ViewContainerRef, ViewChild, Input, ComponentRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AddDynamicComponentService } from '@dooh/common-services';
import { HourRangeFieldComponent } from '../hour-range-field/hour-range-field.component';
import { ActiveHourWeeklyTableService } from '../hour-weekly-table/services/active-hour-weekly-table.service';

const DEFAULT_FROM_STR = '00:00';
const DEFAULT_TO_STR = '23:59';
const END_HOUR_SECONDS = ':59.999999999';

@Component({
  selector: 'dooh-day-hour-range',
  templateUrl: './day-hour-range.component.html',
  styleUrls: ['./day-hour-range.component.scss']
})
export class DayHourRangeComponent implements OnInit, AfterViewInit {

  @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;

  @Input()
  day: string;

  @Input()
  initData?: any;

  activeDay: boolean;

  addCount: number;

  components: ComponentRef<any>[];

  addActive: boolean;

  checked: boolean;

  @Output()
  changeValue: EventEmitter<any>  = new EventEmitter<any>();

  @Output()
  changeSelection: EventEmitter<any>  = new EventEmitter<any>();

  constructor(
    private dynamic: AddDynamicComponentService,
    public translateService: TranslateService,
    private activeHourWeeklyTableService: ActiveHourWeeklyTableService
  ) {
    this.translateService.setDefaultLang('en');
  }

  ngOnInit() {
    this.components = [];
    this.addActive = (this.checkActive() && !this.checked);
    this.checked = false;
    this.addCount = 0;
  }

  ngAfterViewInit(): void {
    if (this.initData != null) {
      this.initComponentWithData();
    }
  }

  checkActive(): boolean {
    return (this.addCount < 4);
  }

  addHourRangeComponent() {
    const isFirst = !this.addCount;
    const component = this.dynamic.addDynamicComponent(HourRangeFieldComponent, this.container);
    ++this.addCount;
    this.addActive = this.checkActive();
    this.components.push(component);

    const instance = (<HourRangeFieldComponent>component.instance);

    if (!this.checked) {
      instance.disabled = true;
    } else if (isFirst) {
      instance.fromValue = DEFAULT_FROM_STR;
      instance.toValue = DEFAULT_TO_STR;
    }

    instance.closeEvent.subscribe((data) => {
      this.addCount += data;
      this.addActive = this.checkActive();
      const index = this.components.indexOf(component);
      if (index !== -1)
        this.components.splice(index, 1);
    });

    instance.changeValue.subscribe(() => {
      this.changeValue.emit(this.getValue())
    });


  }

  addComponent() {
    this.addHourRangeComponent();
  }

  toggleRange() {
    this.checked = !this.checked;
    this.addActive = (this.checkActive() && this.checked);
    this.enableRanges(!this.checked);
    if (this.checked && !this.addCount) {
      this.addComponent();
    }

    if(this.checked) {
      this.activeHourWeeklyTableService.setSelectedWeekNumber(1);
    } else {
      this.activeHourWeeklyTableService.setSelectedWeekNumber(0);
      this.dynamic.removeAllDynamicChildren(this.container);
      this.addCount = 0;
      this.components.splice(0, this.components.length)
    }
    this.changeSelection.next(true);
  }

  enableRanges(disabled: boolean) {
    for (let i = 0; i < this.components.length; i++) {
      const instance = (<HourRangeFieldComponent>this.components[i].instance);
      instance.disabled = disabled;
    }
  }

  toEndOfHour(val) {
    if (!val) {
      return null;
    }
    return val + END_HOUR_SECONDS;
  }

  prepareValue(val) {
    const from = val.value['from'];
    const to = val.value['to'];

    return {
      isWholeDay: from === DEFAULT_FROM_STR && to === DEFAULT_TO_STR,
      startTime: from,
      endTime: this.toEndOfHour(to)
    };
  }


  getValue() {
    const values = [];

    let isWholeDay = false;
    let isValid = true;

    if (this.checked && !this.components.length) {
      isWholeDay = true;
      const prepVal = this.prepareValue({
        value: {
          from: DEFAULT_FROM_STR,
          to: DEFAULT_TO_STR,
        }
      });
      values.push(prepVal);
    }

    this.components.forEach(element => {
      const instance = (<HourRangeFieldComponent>element.instance);
      const val = instance.getValue();
      if (val) {
        const prepVal = this.prepareValue(val);
        values.push(prepVal);
        isWholeDay = isWholeDay || prepVal.isWholeDay;
      } else {
        values.push(val);
        isValid = false;
      }
    });

    if (!values.length) {
      return {
        isValid: isValid,
        isWholeDay: false,
        values: null
      };
    }

    return {
      isValid: isValid,
      isWholeDay: isWholeDay,
      values: values
    };
  }

  initComponentWithData() {
    this.initData.forEach(element => {
      if (element.day !== null) {
        this.checked = true;
        this.addActive = true;
        const component = this.dynamic.addDynamicComponent(HourRangeFieldComponent, this.container);
        component.changeDetectorRef.detectChanges();
        ++this.addCount;
        this.addActive = this.checkActive();
        this.components.push(component);

        let startTime = element.startTime;
        let endTime = element.endTime;
        if (!startTime && !endTime) {
          startTime = DEFAULT_FROM_STR;
          endTime = DEFAULT_TO_STR;
        }

        const instance = (<HourRangeFieldComponent>component.instance);

        instance.updateFromValue(startTime.split(':')[0] + ':' + startTime.split(':')[1]);
        instance.updateToValue(endTime.split(':')[0] + ':' + endTime.split(':')[1]);

        instance.closeEvent.subscribe((data) => {
          this.addCount += data;
          this.addActive = this.checkActive();
          const index = this.components.indexOf(component);
          if (index !== -1)
            this.components.splice(index, 1);
        });
        instance.changeValue.subscribe(() => {
          this.changeValue.emit(this.getValue())
        })
      }
    });


  }
}
