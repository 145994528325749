import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TargetingsService, WhoamiService } from '@dooh/common-services';
import {
  ColumnCustomizer,
  ColumnType,
  ScreenView,
  TableColumn,
  TableCustomizer,
} from '@dooh/models';
import { Subject } from 'rxjs';

@Component({
  selector: 'dooh-marker-multi-screen-table',
  templateUrl: './marker-multi-screen-table.component.html',
  styleUrls: ['./marker-multi-screen-table.component.scss'],
})
export class MarkerMultiScreenTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() markers?: any[] = [];
  @Input() editable: boolean;
  @ViewChild('formats') formatTemp: any;
  @Output() selectedScreen: EventEmitter<any>;
  @Output() close: EventEmitter<any>;
  @Output() toggleScreenSelection: EventEmitter<any>;
  @Output() toggleAllScreenSelection: EventEmitter<any>;

  @Input() addedScreens: any;
  @Input() selectedScreens: any;

  selectedElements: Subject<{ action: string; items: string[] }> = new Subject<{
    action: string;
    items: string[];
  }>();
  disablingElements: Subject<{
    action: string;
    items: string[];
  }> = new Subject<{ action: string; items: string[] }>();

  allVenueTypes: any[] = [];
  flatVenueType: any[] = [];
  currentPage = 1;
  tableCustomizer: TableCustomizer;
  screens: MatTableDataSource<ScreenView> = new MatTableDataSource<
    ScreenView
  >();
  placeName: string;
  placeAddress: string;
  isLoading = true;
  isSmbUser: boolean;
  resetTable: boolean;

  constructor(private screenService: TargetingsService) {
    this.selectedScreen = new EventEmitter<any>();
    this.close = new EventEmitter<any>();
    this.toggleScreenSelection = new EventEmitter<any>();
    this.toggleAllScreenSelection = new EventEmitter<any>();
    this.isSmbUser = WhoamiService.getCurrentUser().dspRoles.some(role => role === 'SMB_MANAGER');
  }

  ngOnInit(): void {
    this.getAllVenueTypes();
    this.loadConfiguration(this.editable);
    if (this.markers && this.markers.length > 0) {
      this.toggleSelectedAndDisabledElements();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['markers'] && changes['markers']?.currentValue) {
      this.isLoading = true;
      this.screens.data = changes['markers']?.currentValue;
      const firstItem = changes['markers']?.currentValue[0];
      this.placeName = firstItem?.address?.street;
      this.placeAddress = firstItem?.displayAddress;
      if (this.isSmbUser) {
        this.addedScreens = [];
        this.markers.forEach(item => {
          if (item?.hasRelation) {
            const id = item?.screenId ?? item?.id
            this.addedScreens.push(id)
          }
        })
      }


      setTimeout(() => {
        this.isLoading = false;
        this.toggleSelectedAndDisabledElements();
      }, 1500);
    }
    if (changes['editable'] && changes['editable']?.currentValue) {
      this.editable = changes['editable']?.currentValue;
      this.loadConfiguration(this.editable);
    }
    if (
      (changes['selectedScreens'] &&
        changes['selectedScreens']?.currentValue) ||
      (changes['addedScreens'] && changes['addedScreens']?.currentValue)
    ) {
      this.toggleSelectedAndDisabledElements();
    }
  }
  ngAfterViewInit(): void {
    this.loadConfiguration(this.editable);
  }

  triggerAction(event: any): void {
    const data = {
      marker: event,
    };
    this.selectedScreen.emit(data);
  }
  toggleSelection(event: any): void {
    this.toggleScreenSelection.emit(event?.element);
  }

  sort(event: any): void {
    const columns: ColumnCustomizer[] = this.loadColumnCustomizers();
    const key = columns
      .find((column) => column.columnDef === event?.name)
      .prop.join('.');
    const direction = event?.direction;

    if (direction === 'asc') {
      this.markers.sort((a: any, b: any) =>
        this.returnSortProp(a, key).localeCompare(this.returnSortProp(b, key))
      );
    } else {
      this.markers.sort((a: any, b: any) =>
        this.returnSortProp(b, key).localeCompare(this.returnSortProp(a, key))
      );
    }

    this.screens.data = this.markers;
  }

  closeTable(): void {
    this.close.emit();
  }

  private loadConfiguration(hasCheckbox = false) {
    const columnCustomizers = this.loadColumnCustomizers(hasCheckbox);

    this.tableCustomizer = new TableCustomizer(
      'screens',
      columnCustomizers,
      false,
      null,
      null
    );
  }

  private loadColumnCustomizers(hasCheckbox = false): Array<ColumnCustomizer> {
    const columns: TableColumn[] = [
      {
        name: 'name',
        heading: 'Screen Name',
        valuePath: ['name'],
        type: hasCheckbox ? ColumnType.CHECKBOX_GROUP : ColumnType.STRING,
      },
      {
        name: 'formats',
        heading: 'Formats',
        valuePath: ['formats'],
        type: ColumnType.CUSTOM,
        sortDisabled: true,
        customTemplate: this.formatTemp,
      },
      {
        name: 'venue_type',
        heading: 'Venue Type',
        valuePath: ['venueType'],
        valueExtractor: (data: any) => {
          let venueId = data?.venueType?.id || data?.venueTypeId;
          if (venueId) {
            return this.getVenueTypeName(venueId);
          } else {
            return '-';
          }
        },
      },
      {
        name: 'floor_price',
        heading: 'Floorprice',
        valuePath: ['convertedPrice', 'floorPrice'],
        type: ColumnType.MONEY,
      },
    ];

    return columns.map((column) => new ColumnCustomizer(column));
  }

  private getVenueTypeName(id: string): string {
    let parent = this.getVenueTypeParentName(id);
    return parent;
  }

  private getVenueTypeParentName(id: string): string {
    if (id) {
      if (id.length % 2 == 0) {
        const first2Str = String(id).slice(0, 2);
        const first2Num = Number(first2Str);
        let venueType = this.allVenueTypes?.filter((venue) => {
          return venue.enumeration_id === first2Num;
        });
        let venueTypeChild = this.flatVenueType?.filter((venue) => {
          return venue.enumeration_id == id;
        });
        if (venueType) {
          if (!venueTypeChild) {
            return `${venueType[0]?.name?.replace(/&amp;/g, '&')}`;
          }
          return `${venueType[0]?.name?.replace(/&amp;/g, '&')}/${
            venueTypeChild[0]?.name
          }`;
        } else {
          return '';
        }
      } else if (id.length % 2 !== 0) {
        const firstNum = Number(String(id)[0]);
        let venueType = this.allVenueTypes?.filter((venue) => {
          return venue.enumeration_id === firstNum;
        });
        let venueTypeChild2 = this.flatVenueType?.filter((venue2) => {
          return venue2.enumeration_id == id;
        });

        if (venueType) {
          if (!venueTypeChild2) {
            return `${venueType[0]?.name?.replace(/&amp;/g, '&')}`;
          }
          return `${venueType[0]?.name?.replace(/&amp;/g, '&')}/${
            venueTypeChild2[0]?.name
          }`;
        } else {
          return '';
        }
      }
      return '--';
    }
  }

  private getAllVenueTypes(): void {
    this.screenService.getAllVenueTypes().subscribe((res) => {
      this.allVenueTypes = res.venueTypes.categories || [];
      this.flatVenueType = this.flattenAllVenueTypes(this.allVenueTypes);
    });
  }

  private flattenAllVenueTypes(members: any[]): any[] {
    let children = [];

    return members
      .map((m) => {
        if (m.children && m.children.length) {
          children = [...children, ...m.children];
        }
        return m;
      })
      .concat(children.length ? this.flattenAllVenueTypes(children) : children);
  }

  protected returnSortProp(obj: any, key: any): string {
    const check: string[] = key.split('.');

    if (check?.length > 1) {
      let firstProp = check[0];
      let secProp = check[1];
      return String(obj[firstProp][secProp]);
    } else if (key === 'venueType') {
      let venueTypeId;
      if (obj?.venueType) {
        venueTypeId = obj?.venueType?.id;
      } else {
        venueTypeId = obj?.venueTypeId;
      }
      return this.getVenueTypeName(venueTypeId);
    }
    return obj[key];
  }

  selectAllMaster($event: { checked: boolean }) {
    const isAllSelected = $event.checked;
    const mode = {
      type: isAllSelected ? 'SELECT' : 'DESELECT'
    }
    this.toggleAllScreenSelection.emit(mode);
  }

  toggleSelectedAndDisabledElements(): void {
    this.selectedElements.next({
      action: 'RESET',
      items: [],
    });
    this.disablingElements.next({
      action: 'RESET',
      items: [],
    });

    this.selectedElements.next({
      action: 'SELECT',
      items: [...(this.selectedScreens || []), ...(this.addedScreens || [])],
    });
    this.disablingElements.next({
      action: 'DISABLE',
      items: this.addedScreens,
    });
  }

  public onCancelClick(): void {
    this.editable = false;
    this.resetTable = true;
    setTimeout(() => {
      	this.resetTable = false;
    }, 1500)
    this.loadConfiguration();
  }
}
