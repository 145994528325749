<div class="tabs-common" *ngIf="editable">
  <mat-tab-group
    class="screens-tab__tab-group"
    (selectedTabChange)="changeTab($event)"
    [selectedIndex]="currentTab"
  >
    <mat-tab
      class="screens-tab__tab"
      label="Preview Screens ({{
        screens.preview.totalElements +
          addedScreens.length -
          deletedScreens.length
      }})"
    >
      <dooh-generic-table
        class="child-table"
        [dataSource]="screens.preview.dataSource"
        [tableCustomizer]="screens.preview.tableCustomizer"
        [totalElements]="
          screens.preview.totalElements +
          addedScreens.length -
          deletedScreens.length
        "
        [isLoading]="screens.preview.isLoading"
        (item)="triggerAction($event, SCREENS.PREVIEW)"
        (selectAll)="selectAllMaster($event, SCREENS.PREVIEW)"
        (scrolling)="onTableScroll(SCREENS.PREVIEW)"
        (onPage)="onTablePaging($event, SCREENS.PREVIEW)"
        (header)="onSortChange($event, SCREENS.PREVIEW)"
        [selectedElements]="selectedElements.asObservable()"
        pagingStrategy="scroll"
      ></dooh-generic-table>

      <div class="buttons-container">
        <button
          class="color-primary"
          mat-raised-button
          [disabled]="!screens.preview.selectedItems.length"
          (click)="delete()"
        >
          Delete
        </button>
        <button
          *ngIf="!hideSaveButton"
          mat-raised-button
          color="primary"
          (click)="onSave()"
          [disabled]="!enableSave"
        >
          Save
        </button>
      </div>
    </mat-tab>
    <mat-tab class="screens-tab__tab" label="Add Screens">
      <div class="map-toolbar">
        <div class="first-column left-column" >
          <form
            class="screen-tabs-filters"
            [formGroup]="filtersForm"
            (submit)="onSearchByTags()"
          >
            <dooh-autocomplete-field
              class="screen-search"
              formControlName="screenTagSearchField"
              searchBy="id"
              [label]="'screensTab.label.search' | translate"
              [service]="screensApi"
              [multi]="true"
              [searchDelay]="800"
              [searchMinLength]="3"
              (allowSearch)="checkAllowSearch($event)"
              (selectedValue)="checkPackageSelect($event)"
              [itemTemplate]="screenTabItemTemplate"
              matTooltip="Introduce 3 letters to search tags"
            ></dooh-autocomplete-field>
            <button
              mat-icon-button
              matPrefix
              type="submit"
              [disabled]="disableSearch"
              [ngClass]="{ disabled: disableSearch }"
              class="screen-tabs-filters__button"
              (click)="onActiveFilters($event)"
            >
              <mat-icon svgIcon="search"></mat-icon>
            </button>
          </form>

        <mat-select
          disableOptionCentering
          id="filterSelect"
          placeholder="Filters"
          *ngIf="
          editable &&
          (activePoi || (activePoi && hasAudience))">
        <ng-container *ngIf="editable && showDma">
          <mat-option
            #matOption
            class="filter-button filter-dma-button"
            [ngClass]="{ 'filter-button_active': showDmaActive }"
            (click)="getDma()"
          >
            <mat-icon class="filter-icon">tv</mat-icon>
            DMA
          </mat-option>
        </ng-container>

          <mat-option
            #matOption
            *ngIf="activePoi"
            (click)="openPoi()"
            class="filter-button"
            [ngClass]="{ 'filter-button_active': isPoiActive }"
          >
            <mat-icon class="filter-icon-svg" svgIcon="place"></mat-icon>
            POI
          </mat-option>

          <mat-option
            #matOption
            *ngIf="activePoi && hasAudience"
            (click)="openAudience()"
            class="filter-button"
            [ngClass]="{ 'filter-button_active': isPoiActive }"
          >
            <mat-icon class="filter-icon-svg" svgIcon="audience"></mat-icon>
            Audience
          </mat-option>

          <mat-option
            #matOption
            *ngIf="activePoi"
            [disabled]="disableScreenPropertiesButton"
            matTooltip="Properties are loaded based on the input entered in the search box"
            (click)="openScreenProperties()"
            class="filter-button"
            [ngClass]="{ 'filter-button_active': isScreenPropertiesActive }"
          >
            <mat-icon class="filter-icon-svg" svgIcon="filter"></mat-icon>
            Screen Properties
          </mat-option>
      </mat-select>
        </div>
        <div class="right-column">
          <dooh-audience-reach
            [expectedReach]="expectedReach"
            *ngIf="!editable"
          ></dooh-audience-reach>
          <div class="map-view-toggle">
            <mat-icon
              svgIcon="map-outline-gray"
              class="color-gray-2"
            ></mat-icon>
            <p class="text">Map view</p>
            <mat-slide-toggle
              [checked]="isMapView"
              (change)="toggleView($event)"
              ><span></span
            ></mat-slide-toggle>
          </div>
          <button
            mat-button
            class="color-gray smb-view-actions__edit"
            *ngIf="!editable"
            (click)="onEditClicked.emit()"
          >
            <mat-icon svgIcon="edit" class="color-gray-2"></mat-icon>
            <span>{{
              'smb.campaignManagement.campaign.view-actions.edit' | translate
            }}</span>
          </button>
        </div>
      </div>

      <dooh-generic-table
        class="child-table child-table_add"
        [dataSource]="screens.add.dataSource"
        [tableCustomizer]="screens.add.tableCustomizer"
        [totalElements]="screens.add.totalElements"
        [isReset]="screens.add.isResetTable"
        [isLoading]="screens.add.isLoading"
        (scrolling)="onTableScroll(SCREENS.ADD)"
        (onPage)="onTablePaging($event, SCREENS.ADD)"
        (item)="triggerAction($event, SCREENS.ADD)"
        (selectAll)="selectAllMaster($event, SCREENS.ADD)"
        [selectedElements]="selectedElements.asObservable()"
        [disablingElements]="disablingElements.asObservable()"
        (header)="onSortChange($event, SCREENS.ADD)"
        canBulkSelect="true"
      ></dooh-generic-table>
      <div class="buttons-container" *ngIf="!(isBulkSelect$ | async)">
        <button
          class="color-primary"
          *ngIf="!hideCancelButton"
          mat-raised-button
          (click)="onCancel()"
        >
          {{ 'generic.cancel' | translate }}
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="add()"
          [disabled]="!screens.add.selectedItems.length"
        >
          Add
        </button>
      </div>
    </mat-tab>
    <mat-tab class="screens-tab__tab" label="Packages" *ngIf="!offerConditions">
      <dooh-campaign-packages
        [screensApi]="screensApi"
        (packageId)="setPackageId($event)"
        (detachPackageRelationalId)="setDetachPackageId($event)"
        (selectedPackageToView)="setPackageToSearchScreen($event)"
        (detachPackageScreensRelationalId)="removeSelectedPackageSelectedScreens($event)"
        ></dooh-campaign-packages>
    </mat-tab>
  </mat-tab-group>
</div>

<dooh-generic-table
  *ngIf="!editable"
  class="child-table view-table"
  [dataSource]="screens.preview.dataSource"
  [tableCustomizer]="screens.preview.tableCustomizer"
  [totalElements]="screens.preview.totalElements"
  [isLoading]="screens.preview.isLoading"
  (scrolling)="onTableScroll(SCREENS.PREVIEW)"
  (onPage)="onTablePaging($event, SCREENS.PREVIEW)"
  (header)="onSortChange($event, SCREENS.PREVIEW)"
></dooh-generic-table>

<ng-template #screenTabItemTemplate let-item="item">
  <span *ngIf="!!item" class="autocomplete-container">
    <span class="icon-autocomplete">
      <mat-icon
        svgIcon="map-pin"
        *ngIf="item?.place_id; else publisher"
      ></mat-icon>
      <ng-template #publisher>
        <mat-icon svgIcon="users" *ngIf="item?.tag !== 'Package'; else package"></mat-icon>
      </ng-template>
      <ng-template #package>
        <mat-icon svgIcon="package"></mat-icon>
      </ng-template>
    </span>
    <span class="autocomplete-text">
      <span class="country">{{ item?.tag === 'Package' ? item.name : item?.id }}</span>
      <span class="region">{{ item.tag }}</span>
    </span>
  </span>
</ng-template>

<ng-template #formats let-data="data">
  <dooh-screens-formats [formats]="data"></dooh-screens-formats>
</ng-template>
