<div class="screens-tabs">
  <mat-button-toggle-group #group="matButtonToggleGroup"
                           [ngClass]="{'pagination': isMapPaginated }"
                           class="toggle-group__buttons" value="map"hidden>
    <mat-button-toggle value="list" (click)="toggleView(false)" [checked]="!mapView">
      <mat-icon class="toggle-icon">list</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="map" (click)="toggleView(true)" [checked]="mapView">
      <mat-icon class="toggle-icon">map</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
  <div class="map-toolbar" *ngIf = "!mapView && !editable">
    <div class="first-column">
    </div>
    <div class="right-column">

      <dooh-audience-reach [expectedReach]="expectedReach" *ngIf="!editable"></dooh-audience-reach>
      <div class="map-view-toggle">
        <mat-icon svgIcon = "map-outline-gray" class="color-gray-2"></mat-icon>
        <p class="text">Map view</p>
        <mat-slide-toggle [checked]="mapView"  (change)="toggleView($event)"><span></span></mat-slide-toggle>
      </div>
      <button
        mat-button
        class="color-gray smb-view-actions__edit"
        *ngIf="!editable"
        (click) = "onEditClicked.emit()"
      >
        <mat-icon svgIcon="edit" class="color-gray-2"></mat-icon>
        <span>{{ 'smb.campaignManagement.campaign.view-actions.edit' | translate }}</span>
      </button>
      <button
      class="btn btn--inline color-gray"
      (click)="downloadCSV()"
      [disabled]="isDownloadingCSV"
      >
        <mat-icon svgIcon="download-icon"></mat-icon>
        <span>Download CSV</span>
        <mat-spinner class="" diameter="20" *ngIf="isDownloadingCSV"></mat-spinner>
      </button>
    </div>
  </div>
  <div class="screens-tabs__map-view" *ngIf="mapView">
    <dooh-screens-map #map
                      [screensApi]="screensApi"
                      [editable]="editable"
                      [isCreating]="isCreating"
                      [isMapView]="mapView"
                      (addedScreensChange)="onAddedScreensChange($event)"
                      (deletedScreensChange)="onDeletedScreensChange($event)"
                      (previewScreensChange)="onPreviewScreensChange($event)"
                      (ontoggleView) = "toggleView($event)"
                      (onEditClicked)="onEditClicked.emit()"
                      (isPaginated)="checkPagination($event)"
                      (componentInit)="map.cancel = cancel"
                      (onDownloadCsvClicked) = "downloadCSV()"
                      [offerConditions]="offerConditions"
    ></dooh-screens-map>
  </div>
  <div class="screen-tabs__table-view" *ngIf="!mapView">
    <dooh-screens-view-tab #tables
                      [editable]="editable"
                      [screensApi]="screensApi"
                      [isCreating]="isCreating"
                      [isMapView]="mapView"
                      [hideScreenProperties]="hideScreenProperties"
                      (ontoggleView) = "toggleView($event)"
                      (onEditClicked)="onEditClicked.emit()"
                      (expectedReachUpdated)="expectedReach= $event"
                      (addedScreensChange)="onAddedScreensChange($event)"
                      (deletedScreensChange)="onDeletedScreensChange($event)"
                      (previewScreensChange)="onPreviewScreensChange($event)"
                      [hideCancelButton]="true"
                      [hideSaveButton]="true"
                      [offerConditions]="offerConditions"
                      (componentInit)="tables.cancel = cancel"
                      ></dooh-screens-view-tab>
  </div>
</div>

