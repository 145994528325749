/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AdvertiserForm } from '../model/advertiserForm';
import { AdvertiserView } from '../model/advertiserView';
import { AgencyForm } from '../model/agencyForm';
import { AgencyView } from '../model/agencyView';
import { InstanceForm } from '../model/instanceForm';
import { InstanceView } from '../model/instanceView';
import { PageDto } from '../model/pageDto';
import { SspForm } from '../model/sspForm';
import { SspView } from '../model/sspView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class InstancesService {

    protected basePath = 'https://dooh-api.staging.theneuron.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create a new advertiser
     * Create a new advertiser
     * @param agencyId Agency Id
     * @param body Advertiser Object
     * @param instanceId Instance Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAdvertiser(agencyId: string, body: AdvertiserForm, instanceId: string, observe?: 'body', reportProgress?: boolean): Observable<AdvertiserView>;
    public createAdvertiser(agencyId: string, body: AdvertiserForm, instanceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AdvertiserView>>;
    public createAdvertiser(agencyId: string, body: AdvertiserForm, instanceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AdvertiserView>>;
    public createAdvertiser(agencyId: string, body: AdvertiserForm, instanceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyId === null || agencyId === undefined) {
            throw new Error('Required parameter agencyId was null or undefined when calling createAdvertiser.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createAdvertiser.');
        }

        if (instanceId === null || instanceId === undefined) {
            throw new Error('Required parameter instanceId was null or undefined when calling createAdvertiser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AdvertiserView>(`${this.basePath}/dsp/instances/${encodeURIComponent(String(instanceId))}/agencies/${encodeURIComponent(String(agencyId))}/advertisers`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new agency
     * Create a new agency
     * @param body Agency Object
     * @param instanceId Instance Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAgency(body: AgencyForm, instanceId: string, observe?: 'body', reportProgress?: boolean): Observable<AgencyView>;
    public createAgency(body: AgencyForm, instanceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyView>>;
    public createAgency(body: AgencyForm, instanceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyView>>;
    public createAgency(body: AgencyForm, instanceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createAgency.');
        }

        if (instanceId === null || instanceId === undefined) {
            throw new Error('Required parameter instanceId was null or undefined when calling createAgency.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AgencyView>(`${this.basePath}/dsp/instances/${encodeURIComponent(String(instanceId))}/agencies`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create new instance
     * Create new instance
     * @param body Instance Object
     * @param localeCode User Locale Code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createInstance(body: InstanceForm, localeCode?: string, observe?: 'body', reportProgress?: boolean): Observable<InstanceView>;
    public createInstance(body: InstanceForm, localeCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InstanceView>>;
    public createInstance(body: InstanceForm, localeCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InstanceView>>;
    public createInstance(body: InstanceForm, localeCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createInstance.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (localeCode !== undefined && localeCode !== null) {
            queryParameters = queryParameters.set('locale_code', <any>localeCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InstanceView>(`${this.basePath}/dsp/instances`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create new SSP
     * Create new SSP
     * @param body Ssp Object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSsp(body: SspForm, observe?: 'body', reportProgress?: boolean): Observable<SspView>;
    public createSsp(body: SspForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SspView>>;
    public createSsp(body: SspForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SspView>>;
    public createSsp(body: SspForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createSsp.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SspView>(`${this.basePath}/dsp/ssps`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete advertiser
     * Delete advertiser
     * @param advertiserId Advertiser Id
     * @param agencyId Agency Id
     * @param instanceId Instance Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAdvertiser(advertiserId: string, agencyId: string, instanceId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteAdvertiser(advertiserId: string, agencyId: string, instanceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteAdvertiser(advertiserId: string, agencyId: string, instanceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteAdvertiser(advertiserId: string, agencyId: string, instanceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (advertiserId === null || advertiserId === undefined) {
            throw new Error('Required parameter advertiserId was null or undefined when calling deleteAdvertiser.');
        }

        if (agencyId === null || agencyId === undefined) {
            throw new Error('Required parameter agencyId was null or undefined when calling deleteAdvertiser.');
        }

        if (instanceId === null || instanceId === undefined) {
            throw new Error('Required parameter instanceId was null or undefined when calling deleteAdvertiser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/dsp/instances/${encodeURIComponent(String(instanceId))}/agencies/${encodeURIComponent(String(agencyId))}/advertisers/${encodeURIComponent(String(advertiserId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete agency
     * Delete agency
     * @param agencyId Agency Id
     * @param instanceId Instance Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAgency(agencyId: string, instanceId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteAgency(agencyId: string, instanceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteAgency(agencyId: string, instanceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteAgency(agencyId: string, instanceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyId === null || agencyId === undefined) {
            throw new Error('Required parameter agencyId was null or undefined when calling deleteAgency.');
        }

        if (instanceId === null || instanceId === undefined) {
            throw new Error('Required parameter instanceId was null or undefined when calling deleteAgency.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/dsp/instances/${encodeURIComponent(String(instanceId))}/agencies/${encodeURIComponent(String(agencyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete instance
     * Delete instance
     * @param instanceId Instance Id
     * @param localeCode User Locale Code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteInstance(instanceId: string, localeCode?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteInstance(instanceId: string, localeCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteInstance(instanceId: string, localeCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteInstance(instanceId: string, localeCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (instanceId === null || instanceId === undefined) {
            throw new Error('Required parameter instanceId was null or undefined when calling deleteInstance.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (localeCode !== undefined && localeCode !== null) {
            queryParameters = queryParameters.set('locale_code', <any>localeCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/dsp/instances/${encodeURIComponent(String(instanceId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete SSP
     * Delete SSP
     * @param sspId SSP Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSsp(sspId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteSsp(sspId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteSsp(sspId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteSsp(sspId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sspId === null || sspId === undefined) {
            throw new Error('Required parameter sspId was null or undefined when calling deleteSsp.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/dsp/ssps/${encodeURIComponent(String(sspId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get advertiser by id
     * Get advertiser by id
     * @param advertiserId Advertiser Id
     * @param agencyId Agency Id
     * @param instanceId Instance Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAdvertiser(advertiserId: string, agencyId: string, instanceId: string, observe?: 'body', reportProgress?: boolean): Observable<AdvertiserView>;
    public getAdvertiser(advertiserId: string, agencyId: string, instanceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AdvertiserView>>;
    public getAdvertiser(advertiserId: string, agencyId: string, instanceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AdvertiserView>>;
    public getAdvertiser(advertiserId: string, agencyId: string, instanceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (advertiserId === null || advertiserId === undefined) {
            throw new Error('Required parameter advertiserId was null or undefined when calling getAdvertiser.');
        }

        if (agencyId === null || agencyId === undefined) {
            throw new Error('Required parameter agencyId was null or undefined when calling getAdvertiser.');
        }

        if (instanceId === null || instanceId === undefined) {
            throw new Error('Required parameter instanceId was null or undefined when calling getAdvertiser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AdvertiserView>(`${this.basePath}/dsp/instances/${encodeURIComponent(String(instanceId))}/agencies/${encodeURIComponent(String(agencyId))}/advertisers/${encodeURIComponent(String(advertiserId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of advertisers
     * Get list of advertisers. Available fields for sorting and filtering: - &#39;advertiser_id&#39; - the ID of advertiser; - &#39;agency_id&#39; - the ID of agency; - &#39;name&#39; - the name of advertiser.  Request example: /instances/1/agencies/1/advertisers?page&#x3D;2&amp;page_size&#x3D;10&amp;sort&#x3D;advertiser_id,asc;name,desc&amp;filters&#x3D;name,like,%airport%.
     * @param agencyId Agency Id
     * @param instanceId Instance Id
     * @param filters Filtering criteria in the format: field_name,operation,value. Supported operations: eq (equal, &#x3D;), neq (not equal, !&#x3D;), lt (less than, &lt;), lte (less than or equal, &lt;&#x3D;), gt (greater than, &gt;), gte (greater than or equal, &gt;&#x3D;), like.
     * @param page the number of page
     * @param pageSize the size of page
     * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAdvertisers(agencyId: string, instanceId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
    public getAdvertisers(agencyId: string, instanceId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
    public getAdvertisers(agencyId: string, instanceId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
    public getAdvertisers(agencyId: string, instanceId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyId === null || agencyId === undefined) {
            throw new Error('Required parameter agencyId was null or undefined when calling getAdvertisers.');
        }

        if (instanceId === null || instanceId === undefined) {
            throw new Error('Required parameter instanceId was null or undefined when calling getAdvertisers.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters) {
            filters.forEach((element) => {
                queryParameters = queryParameters.append('filters', <any>element);
            })
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('page_size', <any>pageSize);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageDto>(`${this.basePath}/dsp/instances/${encodeURIComponent(String(instanceId))}/agencies/${encodeURIComponent(String(agencyId))}/advertisers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of agencies
     * Get list of agencies. Available fields for sorting and filtering: - &#39;instance_id&#39; - the ID of instance; - &#39;agency_id&#39; - the ID of agency; - &#39;name&#39; - the name of agency.  Request example: /instances/1/agencies?page&#x3D;2&amp;page_size&#x3D;10&amp;sort&#x3D;agency_id,asc;name,desc&amp;filters&#x3D;name,like,%airport%.
     * @param instanceId Instance Id
     * @param filters Filtering criteria in the format: field_name,operation,value. Supported operations: eq (equal, &#x3D;), neq (not equal, !&#x3D;), lt (less than, &lt;), lte (less than or equal, &lt;&#x3D;), gt (greater than, &gt;), gte (greater than or equal, &gt;&#x3D;), like.
     * @param page the number of page
     * @param pageSize the size of page
     * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgencies(instanceId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
    public getAgencies(instanceId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
    public getAgencies(instanceId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
    public getAgencies(instanceId: string, filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (instanceId === null || instanceId === undefined) {
            throw new Error('Required parameter instanceId was null or undefined when calling getAgencies.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters) {
            filters.forEach((element) => {
                queryParameters = queryParameters.append('filters', <any>element);
            })
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('page_size', <any>pageSize);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageDto>(`${this.basePath}/dsp/instances/${encodeURIComponent(String(instanceId))}/agencies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get agency by id
     * Get agency by id
     * @param agencyId Agency Id
     * @param instanceId Instance Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgency(agencyId: string, instanceId: string, observe?: 'body', reportProgress?: boolean): Observable<AgencyView>;
    public getAgency(agencyId: string, instanceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyView>>;
    public getAgency(agencyId: string, instanceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyView>>;
    public getAgency(agencyId: string, instanceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyId === null || agencyId === undefined) {
            throw new Error('Required parameter agencyId was null or undefined when calling getAgency.');
        }

        if (instanceId === null || instanceId === undefined) {
            throw new Error('Required parameter instanceId was null or undefined when calling getAgency.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AgencyView>(`${this.basePath}/dsp/instances/${encodeURIComponent(String(instanceId))}/agencies/${encodeURIComponent(String(agencyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get instance by id
     * Get instance by id
     * @param instanceId Instance Id
     * @param localeCode User Locale Code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInstance(instanceId: string, localeCode?: string, observe?: 'body', reportProgress?: boolean): Observable<InstanceView>;
    public getInstance(instanceId: string, localeCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InstanceView>>;
    public getInstance(instanceId: string, localeCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InstanceView>>;
    public getInstance(instanceId: string, localeCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (instanceId === null || instanceId === undefined) {
            throw new Error('Required parameter instanceId was null or undefined when calling getInstance.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (localeCode !== undefined && localeCode !== null) {
            queryParameters = queryParameters.set('locale_code', <any>localeCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InstanceView>(`${this.basePath}/dsp/instances/${encodeURIComponent(String(instanceId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of instances
     * Get list of instances. Available fields for sorting and filtering: - &#39;instance_id&#39; - the ID of instance; - &#39;name&#39; - the name of instance.  Request example: /instances?page&#x3D;2&amp;page_size&#x3D;10&amp;sort&#x3D;instance_id,asc;name,desc&amp;filters&#x3D;name,like,%airport%.
     * @param filters Filtering criteria in the format: field_name,operation,value. Supported operations: eq (equal, &#x3D;), neq (not equal, !&#x3D;), lt (less than, &lt;), lte (less than or equal, &lt;&#x3D;), gt (greater than, &gt;), gte (greater than or equal, &gt;&#x3D;), like.
     * @param localeCode User Locale Code
     * @param page the number of page
     * @param pageSize the size of page
     * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInstances(filters?: Array<string>, localeCode?: string, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
    public getInstances(filters?: Array<string>, localeCode?: string, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
    public getInstances(filters?: Array<string>, localeCode?: string, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
    public getInstances(filters?: Array<string>, localeCode?: string, page?: number, pageSize?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters) {
            filters.forEach((element) => {
                queryParameters = queryParameters.append('filters', <any>element);
            })
        }
        if (localeCode !== undefined && localeCode !== null) {
            queryParameters = queryParameters.set('locale_code', <any>localeCode);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('page_size', <any>pageSize);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageDto>(`${this.basePath}/dsp/instances`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get page of SSP
     * Get list of SSP. Available fields for sorting and filtering: - &#39;ssp_id&#39; - the ID of SSP; - &#39;name&#39; - the name of SSP.  Request example: /ssps?page&#x3D;2&amp;page_size&#x3D;10&amp;sort&#x3D;ssp_id,asc;name,desc&amp;filters&#x3D;name,like,%airport%.
     * @param filters Filtering criteria in the format: field_name,operation,value. Supported operations: eq (equal, &#x3D;), neq (not equal, !&#x3D;), lt (less than, &lt;), lte (less than or equal, &lt;&#x3D;), gt (greater than, &gt;), gte (greater than or equal, &gt;&#x3D;), like.
     * @param page the number of page
     * @param pageSize the size of page
     * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPageOfSsp(filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
    public getPageOfSsp(filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
    public getPageOfSsp(filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
    public getPageOfSsp(filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters) {
            filters.forEach((element) => {
                queryParameters = queryParameters.append('filters', <any>element);
            })
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('page_size', <any>pageSize);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageDto>(`${this.basePath}/dsp/ssps`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get SSP by id
     * Get SSP by id
     * @param sspId SSP Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSsp(sspId: string, observe?: 'body', reportProgress?: boolean): Observable<SspView>;
    public getSsp(sspId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SspView>>;
    public getSsp(sspId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SspView>>;
    public getSsp(sspId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sspId === null || sspId === undefined) {
            throw new Error('Required parameter sspId was null or undefined when calling getSsp.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SspView>(`${this.basePath}/dsp/ssps/${encodeURIComponent(String(sspId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update advertiser
     * Update advertiser
     * @param advertiserId Advertiser Id
     * @param agencyId Agency Id
     * @param body Agency Object
     * @param instanceId Instance Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAdvertiser(advertiserId: string, agencyId: string, body: AdvertiserForm, instanceId: string, observe?: 'body', reportProgress?: boolean): Observable<AdvertiserView>;
    public updateAdvertiser(advertiserId: string, agencyId: string, body: AdvertiserForm, instanceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AdvertiserView>>;
    public updateAdvertiser(advertiserId: string, agencyId: string, body: AdvertiserForm, instanceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AdvertiserView>>;
    public updateAdvertiser(advertiserId: string, agencyId: string, body: AdvertiserForm, instanceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (advertiserId === null || advertiserId === undefined) {
            throw new Error('Required parameter advertiserId was null or undefined when calling updateAdvertiser.');
        }

        if (agencyId === null || agencyId === undefined) {
            throw new Error('Required parameter agencyId was null or undefined when calling updateAdvertiser.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateAdvertiser.');
        }

        if (instanceId === null || instanceId === undefined) {
            throw new Error('Required parameter instanceId was null or undefined when calling updateAdvertiser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<AdvertiserView>(`${this.basePath}/dsp/instances/${encodeURIComponent(String(instanceId))}/agencies/${encodeURIComponent(String(agencyId))}/advertisers/${encodeURIComponent(String(advertiserId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update agency
     * Update agency
     * @param agencyId Agency Id
     * @param body Agency Object
     * @param instanceId Instance Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAgency(agencyId: string, body: AgencyForm, instanceId: string, observe?: 'body', reportProgress?: boolean): Observable<AgencyView>;
    public updateAgency(agencyId: string, body: AgencyForm, instanceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyView>>;
    public updateAgency(agencyId: string, body: AgencyForm, instanceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyView>>;
    public updateAgency(agencyId: string, body: AgencyForm, instanceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyId === null || agencyId === undefined) {
            throw new Error('Required parameter agencyId was null or undefined when calling updateAgency.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateAgency.');
        }

        if (instanceId === null || instanceId === undefined) {
            throw new Error('Required parameter instanceId was null or undefined when calling updateAgency.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<AgencyView>(`${this.basePath}/dsp/instances/${encodeURIComponent(String(instanceId))}/agencies/${encodeURIComponent(String(agencyId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update instance
     * Update instance
     * @param body Instance Object
     * @param instanceId Instance Id
     * @param localeCode User Locale Code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateInstance(body: InstanceForm, instanceId: string, localeCode?: string, observe?: 'body', reportProgress?: boolean): Observable<InstanceView>;
    public updateInstance(body: InstanceForm, instanceId: string, localeCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InstanceView>>;
    public updateInstance(body: InstanceForm, instanceId: string, localeCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InstanceView>>;
    public updateInstance(body: InstanceForm, instanceId: string, localeCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateInstance.');
        }

        if (instanceId === null || instanceId === undefined) {
            throw new Error('Required parameter instanceId was null or undefined when calling updateInstance.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (localeCode !== undefined && localeCode !== null) {
            queryParameters = queryParameters.set('locale_code', <any>localeCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<InstanceView>(`${this.basePath}/dsp/instances/${encodeURIComponent(String(instanceId))}`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update SSP
     * Update SSP
     * @param body SSP Object
     * @param sspId SSP Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSsp(body: SspForm, sspId: string, observe?: 'body', reportProgress?: boolean): Observable<SspView>;
    public updateSsp(body: SspForm, sspId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SspView>>;
    public updateSsp(body: SspForm, sspId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SspView>>;
    public updateSsp(body: SspForm, sspId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateSsp.');
        }

        if (sspId === null || sspId === undefined) {
            throw new Error('Required parameter sspId was null or undefined when calling updateSsp.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<SspView>(`${this.basePath}/dsp/ssps/${encodeURIComponent(String(sspId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
