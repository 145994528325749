<div class="wrapper">
  <section>
    <section *ngIf="isLoading">
      <dooh-custom-loader></dooh-custom-loader>
    </section>
    <img src="/assets/images/check.svg" alt="success icon" *ngIf="successful">
    <img src="/assets/images/danger.svg" alt="error icon" *ngIf="!successful && !isLoading">
  </section>
  <h1>{{heading}}</h1>
  <h2 *ngIf="!successful && isLoading">{{subHeading}}</h2>
  <p *ngIf="successful">
    Can't find your file? Click <span (click)="retryDownload()">here</span> to download again.
  </p>
  <p *ngIf="!successful && !isLoading">
    An error occurred while trying to download CSV, Click <span (click)="retryDownload()">here</span> to try again.
  </p>
</div>
