
import { SelectedInstanceService } from '@dooh/common-services';
import * as currencyCodeLib from "currency-codes";


export class MoneyFormatter {
  static transform(val: any): string | null {
    if (!parseFloat(val)) return val;
    const value = Math.abs(val);
    const instanceCurrency = SelectedInstanceService.getInstanceCurrency();
    const digitsInformation = currencyCodeLib.code(instanceCurrency).digits;
    const roundedValue = this.roundToTwoDecimals(value);
    return roundedValue.toFixed(digitsInformation);
  }
  private static roundToTwoDecimals(value: number): number {
    return Math.round(value * 100) / 100;
  }
}


