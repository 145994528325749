import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActiveNamespaceService } from '@dooh/common-services';

@Component({
  selector: 'dooh-custom-loader',
  templateUrl: './custom-loader.component.html',
  styleUrls: ['./custom-loader.component.scss'],
})
export class CustomLoaderComponent implements OnInit, OnChanges, AfterViewInit {
  @Input('diameter') diameter: number = 150;

  defaultLogo: string = '/assets/lottie/spinner.json';

  styles: Partial<CSSStyleDeclaration> = {
    width: `${this.diameter}px`,
  };

  loaderLogo: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.styles.width = `${this.diameter}px`;
  }

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    const player: any = document.querySelector('lottie-player');
    player.addEventListener('rendered', (e) => {
      if (ActiveNamespaceService.getNamespace()?.spinnerUrl) {
        const spinner = ActiveNamespaceService.getNamespaceSpinner();
        player.load(spinner)
      } else {
        player.load(this.defaultLogo)
      }
      player.play();
    });
  }
}
