import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { AlertDialogType, NamespaceView, User } from "@dooh/models";
import { DialogService } from '@dooh/components-library';
import { ActiveNamespaceService, SmbCreateUserService, TermsService } from '@dooh/common-services';

import { TermOfServiceDialogComponent } from 'libs/components-library/src/lib/dialogs/term-of-service-dialog/term-of-service-dialog.component';

@Component({
  selector: 'dooh-smb-signup',
  templateUrl: './smb-signup.component.html',
  styleUrls: ['./smb-signup.component.scss']
})
export class SmbSignupComponent implements OnInit, OnDestroy {
  public user: User = new User();

  loginForm: FormGroup;
  version: string;
  bannerOppened = true;
  errorLogin: string;
  termsLatestVersion: any;
  showTermsError: boolean;
  namespace: NamespaceView;

  unsubscriber$ = new Subject<void>();
  isLandscapeLogo: boolean;
  @ViewChild('logo') logo: ElementRef;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private smbService: SmbCreateUserService,
    private dialogService: DialogService,
    private termsService: TermsService,
    private activeNamespaceService: ActiveNamespaceService
  ) {
    this.namespace = ActiveNamespaceService.getNamespace();
    this.activeNamespaceService
      .loadNamespace()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(value => this.namespace = value);
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      businessName: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
        Validators.pattern('[a-zA-Z|\\-_\\d\\s]+'),
      ]),
      firstName: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
        Validators.pattern('[a-zA-Z|\\-_\\d\\s]+'),
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
        Validators.pattern('[a-zA-Z|\\-_\\d\\s]+'),
      ]),
      phoneNumber: new FormControl('', {
        validators: [ Validators.required ],
      }),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
        Validators.maxLength(255)
      ]),
      terms: new FormControl(null, [
        (control) => control?.value ? null : { required: true },
      ]),
      marketing: new FormControl(true),
    });

    this.termsService.getLatestTermsVersion(ActiveNamespaceService.getNamespaceId()).pipe(takeUntil(this.unsubscriber$)).subscribe(
      (res) => {
        this.termsLatestVersion = res;
      }
    )

  }

  get phoneNumber(): FormControl {
    return this.loginForm.get("phoneNumber") as FormControl;
  }

  public onSubmit(): void {
    const userObj: any = {
      companyName: this.loginForm.controls.businessName.value,
      email: this.loginForm.controls.email.value.toString().toLowerCase(),
      firstName: this.loginForm.controls.firstName.value,
      lastName: this.loginForm.controls.lastName.value,
      phone: this.loginForm.controls.phoneNumber.value.e164Number.slice(1),
      termsOfServiceVersion: this.termsLatestVersion.version,
      subscribeNewsLetter: this.loginForm.controls.marketing.value,
    };

    this.smbService
      .createSMBUser(userObj)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        () => this.openConfirmDialog("success"),
        (error) => {
          this.errorLogin = error.message;
          if (error.status === 403) {
            this.errorLogin = "System does not support SMB sign up.";
            return;
          }
        }
      );
  }

  openConfirmDialog(action: AlertDialogType): void {
    this.dialogService
      .alert({
        type: action,
        message: 'login.smbLogin.successDialog'
      })
      .afterClosed()
      .subscribe(res => {
        if (!res) return;
        this.router.navigateByUrl('/');
      });
  }

  openTermOfServiceDialog(event?: any): void {
    if (event) {
      event.preventDefault();
      const terms = this.loginForm.get('terms').value;

      if (terms) {
        this.loginForm.get('terms').patchValue(false);
        return;
      }
    }

    const dialogData: any = {
      type: 'default',
      heading: 'login.smbLogin.termsOfService.heading',
      submitButtonText: 'login.smbLogin.termsOfService.submitButton',
      cancelButtonText: 'login.smbLogin.termsOfService.cancelButton',
      isAgreed: this.loginForm.get('terms').value,
    }

    this.dialogService
      .openCustomComponentModal(dialogData, TermOfServiceDialogComponent)
      .beforeClosed()
      .subscribe(res => {
        if (res) {
          this.showTermsError = false;
          this.loginForm.get('terms').patchValue(res);
        } else {
          this.showTermsError = true;
        }
      });
  }

  downloadPdf() {
    const pdfUrl = this.termsLatestVersion.s3Path;
    const pdfName = 'Terms of Service';
    saveAs(pdfUrl, pdfName);
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  onLoadLogo() {
    const image = this.logo.nativeElement as HTMLImageElement;
    const width = image?.width;
    const height = image?.height;

    const ratio = width / height;
    if (ratio > 4 / 3) {
      this.isLandscapeLogo = true
    } else {
      this.isLandscapeLogo = false;
    }
  }
}
