<div
  [ngClass]="{
    'error-message': true,
    'error-message_warning': type === 'warning'
  }"
>
  <mat-icon
    *ngIf="type === 'warning'"
    class="error-message__icon"
    svgIcon="alert-triangle"
    color="primary"
  ></mat-icon>
  <span>{{ message }}</span>
</div>
