<div mat-dialog-title>
  <span>{{ data.heading | translate }}</span>
  <div *ngIf="data.cancelButtonText">
    <button mat-icon-button color="primary" (click)="onCloseClick()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
</div>

<div class="info-container__overlay info-container__overlay_colored loader-center--flex" *ngIf="isLoading">
  <dooh-custom-loader></dooh-custom-loader>
</div>

<div 
mat-dialog-content
class="dialog-alert__content"
[ngClass]="{ 'dialog-alert__content_center': !!data.isCentered }" [innerHTML]="termsOfService" *ngIf="termsOfService && !isLoading"></div>

<div
*ngIf="!termsOfService && !isLoading"
  mat-dialog-content
  class="dialog-alert__content"
  [ngClass]="{ 'dialog-alert__content_center': !!data.isCentered }"
>
  <h3>Terms of Service and Privacy Policy</h3>
  <h4>Effective Date: 9-06-2021</h4>

  <ol>
    <li><b>INTRODUCTION</b></li>
    <p>
      Thank you for joining The Neuron! These Platform Terms of Use (the
      “Terms”) cover your rights and obligations relating to your access and use
      of our products and services, including but not limited to, The Neuron’s
      proprietary self-serve demand side platform (including any software and
      systems implemented by The Neuron to provide such platform and related
      services to enable you to make use of the platform as specified in these
      Terms) (collectively, the “Platform”) and The Neuron website
      (www.theneuron.com) (the “Website”) (collectively, the “Service”). All
      references to “we”, “us”, “our”, or “The Neuron” refer to The Neuron
      Holdings Inc., a Delaware corporation. All references to “you”, “your”, or
      “user” relate to all persons who use or access the Service, in their
      company’s capacity or in an individual capacity, including authorized
      users representing the company, its employees, or other persons using or
      accessing the Service. In addition to these Terms, please review The
      Neuron’s Privacy Policy https://theneuron.com/#/privacy, which describes
      our practices related to collection and use of your information to provide
      the Service. These Terms apply to our Privacy Policy as well and both
      these Terms and our Privacy Policy comply with the requirements of the EU
      General Data Protection Regulation (“GDPR”). By clicking the applicable
      button or checkbox to indicate your acceptance to these Terms and our
      Privacy Policy, or otherwise by using the Service, you represent and agree
      that you have read, understand, and agree to be bound by both these Terms
      and our Privacy Policy as binding agreements. You hereby represent and
      warrant that (i) you are lawfully able to enter into and perform a legally
      binding contract, (ii) if you are entering these Terms on behalf of an
      entity, including your employer, you are authorized to do so, and (iii)
      you agree to be bound by these Terms. Please print a copy of these Terms
      and retain it for your records. Further, you agree that these Terms and
      our Privacy Policy apply to your past use, if any, of the Service prior to
      the Effective Date.
      <br />
      <br />
      If you or the company you represent or work for enter into a separate DSP
      Platform Services Agreement with The Neuron (the “Services Agreement”),
      these Terms shall form part of the Services Agreement. To the extent these
      Terms conflict with the terms of the Services Agreement, the terms of the
      Services Agreement shall prevail.
      <br />
      <br />
      PLEASE READ THESE TERMS CAREFULLY, AS THEY CONTAIN IMPORTANT INFORMATION
      ABOUT YOUR RIGHTS AND RESPONSIBILITIES, INCLUDING LIMITATION OF OUR
      LIABILITY AND BINDING ARBITRATION. IF YOU DO NOT ACCEPT THESE TERMS IN ITS
      ENTIRETY, YOU MAY NOT ACCESS OR USE THE SERVICE.
      <br />
      <br />
      Do not hesitate to contact us at hello@theneuron.com if you have any
      questions or want to discuss either of these important documents.
    </p>

    <li><b>AGE POLICY; PERMITTED USERS</b></li>
    <p>
      We do not intend that the Service be used by anyone under 18 years old. If
      we learn or have reason to suspect that a user is under 18 years of age,
      we will promptly revoke the user’s access to the Service and delete any
      personally identifiable information submitted by that user. Further, you
      represent and warrant that your use of the Service does not and will not
      conflict with any pre-existing obligation in conflict or in any way
      inconsistent with the provisions of these Terms.
    </p>

    <li>
      <b>THE SERVICE</b>
    </li>
    <p>
      The Neuron offers the Platform, a self-serve demand side platform that
      allows you to buy, track and manage advertising campaigns (“Campaigns”) on
      digital out-of-home media (“Digital Media”) for the purposes of delivering
      your ads, including text, graphics, rich media, video and/or audio
      material, and any other content (or combination thereof) (collectively,
      the “Ads”). The Platform gives brands and agencies effortless,
      customizable access to all of the latest data and premium Digital Media in
      outdoor advertising. In addition, it allows for real-time reporting and
      optimization, meaning more cost-effective, agile campaigns. The Platform
      utilizes The Neuron’s proprietary technology to bid on Digital Media on
      your behalf, and may offer manual and automated methods for meeting your
      advertising objectives. The Platform allows you to be connected with
      owners of Digital Media (the “Digital Media Owners”) in order to
      facilitate the purchase of Digital Media.
      <br />
      <br />
      All Ads are subject to The Neuron’s review and approval. You understand
      and agree that you shall not deliver an Ad that is of poor technical
      quality, unlawful, harmful, threatening, abusive, harassing, tortious,
      defamatory, vulgar, pornographic, obscene, libelous, invasive of another's
      privacy, hateful, racially, ethnically or otherwise objectionable. If The
      Neuron determines, in its sole discretion, that an Ad submitted by you
      violates any of the foregoing, it reserves the right to refuse acceptance,
      the use or the publication of the offending Ad.
      <br />
      <br />
      You also understand that your Ads may be refused by third parties involved
      in the provision of the Service, including but not limited to Third-Part
      Ad Exchanges or SSPs (as those terms are defined below), and that The
      Neuron is not involved in making such decision. As such, in the event of
      such a decision by a third party, The Neuron shall not be liable to you
      and hereby disclaims responsibility with respect to any such decision by a
      third party.
      <br />
      <br />
      Conditioned upon your payment of all fees due hereunder and subject to the
      terms and conditions of these Terms, The Neuron grants you a limited,
      non-exclusive, non-sublicenseable, non-transferable, and revocable right
      to access and use the Platform.
    </p>

    <li>
      <b>ACCOUNTS</b>
    </li>
    <p>
      Although you do not need to register for an account to browse the Website,
      you will need an account (“Account”) to access the Platform. To register
      for an Account, you will need to provide The Neuron with your first and
      last name, company name, and your e-mail address (please see our Privacy
      Policy https://theneuron.com/#/privacy for more information on our
      practices related to collection and use of your data). In order to
      purchase Digital Media on the Platform, you will also need to provide us
      with your financial information, including but not limited to your credit
      card information (or any other payment information The Neuron requires, in
      its sole discretion) (collectively, your “Payment Method”). If you become
      aware that an unauthorized third party is using your information to
      interact with the Platform, you must promptly notify us of such use. You
      are solely responsible for maintaining and protecting your username and
      password. You agree that we will not be liable for any loss or harm that
      you incur if someone else uses your information to interact with the
      Platform, either with or without your knowledge, and that you will be
      solely responsible for such use, whether or not you authorized the use.
    </p>
    <li>
      <b>AUDIT RIGHTS</b>
    </li>
    <p>
      The Neuron reserves the right to monitor and audit your usage of the
      Platform for the purpose of ensuring compliance with these Terms. Any such
      audit may be carried out by The Neuron or a third party authorized by The
      Neuron, at The Neuron’s expense.
    </p>

    <li><b>RESTRICTIONS ON USE</b></li>
    <p>
      Any rights not granted herein are strictly reserved by The Neuron. You
      shall not, and you shall not permit any third party to: (a) re-license or
      sublicense, lease, loan or otherwise distribute the Service to any third
      person in violation of these Terms; (b) access content or data not
      intended for you, or log onto a server that you are not authorized to
      access; (c) attempt to probe, scan, or test the vulnerability of the
      Service, or any associated system or network, or to breach security or
      authentication measures without proper authorization; (d) interfering or
      attempting to interfere with service to any user, host, or network,
      including, without limitation, by means of submitting a virus to the
      Service, overloading, “flooding,” “spamming,” “mail bombing,” “crashing,”
      or undertaking similar activities; (e) resell the Service or permit third
      parties to use the Service without our prior written consent; (f) make
      unauthorized copies of any content in the Service; (g) reverse engineer,
      decompile, disassemble or translate any software used by The Neuron to
      deliver the Service (the “Software”), or otherwise attempt to view,
      display or print the Software's source code; (h) remove, modify or obscure
      any copyright, trademark or other proprietary notices contained in the
      Software; (i) advertise anything illegal, engage in any illegal, deceptive
      or fraudulent business practice, or take any other action that could
      result in claims, fees, fines, penalties or other liability to The Neuron
      or any of its affiliated companies, or to you; (j) spread, or facilitate
      the spread of, any viruses, worms or other malicious computer programs
      that may damage, detrimentally interfere with, surreptitiously intercept
      or expropriate any system, data or information; (k) infringe upon any
      party’s copyright, patent, trademark, trade secret or other intellectual
      property rights, or rights of publicity or privacy; (l) use any robot,
      spider, other computer program, or manual process, to monitor or copy the
      Service, or any portion of the Service; (m) scrape any ads that have been
      served using the Service, or use any other automated means of data
      extraction to access, query or otherwise collect any information related
      to ads that have been served using the Product; or (n) make any
      information derived from the Service available to others as a form of
      market research or competitive intelligence.
    </p>

    <li><b>INFORMATION WE MAKE AVAILABLE TO YOU</b></li>
    <p>
      As part of the Service, we may provide you with various information in
      furtherance of the Service. Our intention in doing so is to be helpful and
      to make the Service more useful to you. However, you agree that all
      information and suggestions that we provide to you through the Service is
      strictly for informational purposes and shall not be construed or relied
      upon in any way, and specifically shall not be construed or relied upon as
      professional advice of any kind. You agree that, to the fullest extent
      permitted by law, and as detailed below in the “Limitation of Liability”
      section of these Terms, we will not incur any liability at all whatsoever
      in the event that your reliance on any information provided by us results
      in harm or damage to you or your property.
    </p>

    <li><b>INFORMATION YOU PROVIDE TO US</b></li>
    <p>
      As a core aspect of the functionality of the Service, you may provide us
      with information about yourself, by giving us access to information
      collected by third parties, and by using the Service. By providing this
      information and content, you grant us the right to use the information and
      content for the purposes described in these Terms and our Privacy Policy
      https://theneuron.com/#/privacy. While we do not claim ownership over any
      such information and content that you provide, you agree that we have the
      right to use such information and content as specified in these Terms and
      our Privacy Policy. Further, by providing us with information and content
      through the Service, you represent and warrant that you own, or have the
      full legal authority to distribute, all information that you provide on
      the Service.
      <br />
      <br />
      In addition, we should not be relied upon as a means to store your data or
      information and we assume no responsibility for such activities.
    </p>

    <li><b>REPRESENTATIONS AND WARRANTIES</b></li>
    <p>
      In addition to any other representations made by you in these Terms, you
      represent, warrant and covenant that: (a) Ads delivered or served through
      the Platform will not include any materials or links to materials that are
      unlawful, harmful, spam, defamatory, libelous, abusive, threatening,
      pornographic or otherwise objectionable, or infringes or misappropriates
      the patents, copyrights, trademarks, trade secrets or other intellectual
      property rights of any person; and (b) none of the Ads will contain any
      viruses, Trojan horses, worms or other disabling devices or harmful
      components intended to damage, detrimentally interfere with, intercept, or
      expropriate any system.
    </p>

    <li><b>INAPPROPRIATE AND ILLEGAL CONTENT PROHIBITED</b></li>
    <p>
      You agree not to transmit any inappropriate content on the Service
      including, but not limited to, libelous, defamatory, obscene,
      pornographic, abusive, or threatening content; content that advocates or
      encourages conduct that could constitute a criminal offense, give rise to
      civil liability, or otherwise violate any applicable local, state,
      national, or foreign law or regulation; content that is misleading or not
      true; or advertise or otherwise solicits funds for goods or services. We
      may remove such content from our servers, and we may suspend or revoke
      your access to the Service, and we reserve the right to investigate, and
      seek applicable remedies for, violations of applicable law to the fullest
      extent of the law.
    </p>

    <li><b>FEES</b></li>
    <p>
      Definitions For the purposes of these Terms, the following terms shall be
      defined as follows:
    </p>
    <ol type="a">
      <li>
        “Campaign Budget” means the total amount you desire to spend on a given
        Campaign.
      </li>
      <li>“CPM” means cost per thousand Impressions.</li>
      <li>“Impression” means an opportunity to see a Playout.</li>
      <li>
        “Media Spend” means your aggregate purchases of Digital Media on the
        Platform for a given Campaign. Your exact Media Spend will depend on the
        numbers of Impressions and CPM for the relevant Ads and purchased
        Digital Media Inventory by you through the Platform.
      </li>
      <li>
        “Platform Fee” means the fee payable by you for the Platform. The
        Platform Fee will be calculated by The Neuron as a certain percentage of
        your Campaign Budget.
      </li>
      <li>
        “Playout” means a display of an Ad on a particular item of Digital Media
        inventory.
      </li>
      <li>
        “Third-Party Ad Exchanges” means a digital marketplace that enables
        users and Digital Media Owners to buy and sell Digital Media.
      </li>
      <li>
        “SSP” means a Third-Party Ad Exchange’s supply-side platform used to
        sell Digital Media in a programmatic or automated fashion. SSPs are used
        by Digital Media Owners to make available their Digital Media inventory
        to users.
      </li>
    </ol>

    <br />
    <p>
      <b>Campaign Budget</b>
      <br />
      Before executing a Campaign via the Platform, you will be prompted to
      enter a Campaign Budget for the Campaign, as well as other parameters for
      the Campaign, including but not limited to the time period during which
      you want the Campaign to run. The Campaign Budget will be total amount you
      want to spend on the Campaign. The Neuron will calculate the Media Spend
      and Platform Fee for a given Campaign based on the applicable Campaign
      Budget designated by you such that the total amount you pay in relation to
      executing a given Campaign is no more than the applicable Campaign Budget
      for that Campaign. You will have to prepay the Campaign Budget before
      executing the Campaign through the Platform. You will have to prepay the
      Platform Fee and Media Spend associated with the Campaigns you run on the
      Platform. You understand and agree that the Campaign Budget will be
      charged to the Payment Method associated with your Account, and you
      authorize The Neuron to charge the Campaign Budget to such Payment Method.
      If you terminate a Campaign before consuming the entirety of the Media
      Spend, you will be refunded such unused Media Spend. If you pause a
      Campaign, any unused amounts will not be refunded, but instead will remain
      available for use when you reactivate the Campaign.

      <br />
      <br />

      The Neuron obtains Impression counts from Third-Party Ad Exchanges, SSPs,
      and other third parties. These Impressions counts and record of the price
      per Impression will be controlling and final. You acknowledge and agree
      that The Neuron shall not be liable to you, and The Neuron hereby
      disclaims responsibility, with respect to any damages or claims
      surrounding Impression counts. You further acknowledge and agree that any
      claims surrounding Impression counts (except those relating to any issues
      with data or reports generated by The Neuron) shall be settled directly
      between you and the Third-Party Ad Exchanges, SSP, or any other third
      party who generated the Impression count. You acknowledge and agree that
      The Neuron is entitled to implement and modify appropriate tools, policies
      & procedures at any time in its sole discretion to reduce discrepancies of
      Impressions counted by Third-Party Ad Exchanges, SSPs, or other third
      parties.

      <br />
      <br />
      Any complaints relating to an invoice must be submitted to The Neuron in
      writing or by email to hello@theneuron.com within five (5) days of receipt
      of the invoice. If no such complaint has been made within such period, the
      invoice is deemed to be accepted. Payment is due within fifteen (15) days
      after the date of the invoice.

      <br />
      <br />
      Notwithstanding anything contained herein, The Neuron reserves the right
      to add fees for using the Platform in the future, including any fees tied
      to new functionality or services offered through the Platform. You will be
      notified before incurring any such newly added fees.
      <br />
      <br />
      <b>Taxes</b>
      <br />
      Except for taxes on The Neuron’s income, all payments to be made under
      these Terms by you shall be made free and clear of, and without deduction
      for or on account of, any sales, use, value-?added and withholding taxes,
      and any other taxes, duties, tariffs, charges, fees, and penalties of any
      nature now or hereafter imposed by any national, state or local government
      that are applicable to transactions under this Agreement (collectively,
      “Taxes”). You understand and agree that you are solely responsible for the
      payment of all Taxes. If you are compelled to make any such deduction, you
      will pay The Neuron such additional amounts as are necessary to ensure
      that The Neuron receives the full amount which The Neuron would have
      received but for the deduction.

      <br />
      <br />
      <b>Late and Delinquent Payments</b>
      <br />
      Late payments shall be subject to a rate of one and one-half percent
      (1.5%) per month or the maximum interest allowed by law, whichever is
      less. In the event of a late payment, The Neuron may suspend your use of
      the Platform until the all amounts due to The Neuron are paid.
      Additionally, The Neuron shall be entitled to recover from you any sums
      expended in connection with the collection of overdue amounts, including
      all collection agency fees, attorneys’ fees and expenses.
    </p>

    <li><b>DIGITAL MILLENIUM COPYRIGHT ACT</b></li>
    <p>
      We value your intellectual property rights. As such, we reserve the right
      to suspend and/or terminate any user’s account who is found to have
      infringed on the intellectual property rights of users, The Neuron, or
      third parties, or who violated any laws related to intellectual property.
      In the event that you have a good faith belief that your intellectual
      property rights or the rights of someone else have been violated on our
      Service, and you want us to delete, edit, or disable the material in
      question, you must provide us with all of the following information (as
      required by the Digital Millennium Copyright Act of 1998): (a) a physical
      or electronic signature of a person authorized to act on behalf of the
      owner of the exclusive right that is allegedly infringed; (b)
      identification of the copyrighted work claimed to have been infringed, or,
      if multiple copyrighted works are covered by a single notification, a
      representative list of such works; (c) identification of the material that
      is claimed to be infringed or to be the subject of infringing activity and
      that is to be removed or access to which is to be disabled, and
      information reasonably sufficient to permit us to locate the material; (d)
      information reasonably sufficient to permit us to contact you, such as an
      address, telephone number, and if available, an electronic mail address at
      which you may be contacted; (e) a statement that you have a good faith
      belief that use of the material in the manner complained of is not
      authorized by the copyright owner, its agent, or the law; and (f) a
      statement that the information in the notification is accurate, and under
      penalty of perjury, that you are authorized to act on behalf of the owner
      of an exclusive right that is allegedly infringed. For this notification
      to be effective, you must provide it to our designated agent at:
      <br />
      <br />
      Copyright Agent
      <br />
      The Neuron Holdings Inc.
      <br />
      9450 SW Gemini Dr, PMB 29549, Beaverton, Oregon 97008-7105 US
      <br />
      (323) 524-2158
      <br />
      Hello@theneuron.com

      <br />
      <br />
      To be effective, the notification must be a written communication that
      includes the following:
    </p>

    <ol type="a">
      <li>
        A physical or electronic signature of a person authorized to act on
        behalf of the owner of an exclusive right that is allegedly infringed;
      </li>
      <li>
        Identification of the copyrighted work claimed to have been infringed,
        or, if multiple copyrighted works are covered by a single notification,
        a representative list of such works at that site;
      </li>
      <li>
        Identification of the material that is claimed to be infringing or to be
        the subject of infringing activity and that is to be removed or access
        to which is to be disabled, and information reasonably sufficient to
        permit us to locate the material;
      </li>
      <li>
        Information reasonably sufficient to permit us to contact the
        complaining party, such as an address, telephone number and, if
        available, an electronic mail address at which the complaining party may
        be contacted;
      </li>
      <li>
        A statement that the complaining party has a good-faith belief that use
        of the material in the manner complained of is not authorized by the
        copyright owner, its agent or the law; and
      </li>
      <li>
        A statement that the information in the notification is accurate and,
        under penalty of perjury, that the complaining party is authorized to
        act on behalf of the owner of an exclusive right that is allegedly
        infringed.
      </li>
    </ol>

    <li><b>YOUR USE OF OTHERS’ INTELLECTUAL PROPERTY </b></li>
    <p>
      Although you may provide information and content to The Neuron as part of
      your use of the Service, you agree to be respectful of others’
      intellectual property rights. You may not upload, transmit, or otherwise
      distribute any information or content in violation of intellectual
      property laws or proprietary rights of any third parties. If you do not
      respect a third party’s intellectual property or proprietary rights, you
      are solely responsible for any violations of law. In addition, you are
      solely responsible for infringement of third party rights caused by any
      information that is generated or submitted through your use of the
      Service. We take claims of intellectual property infringement seriously.
      As such, we reserve the right to suspend and/or revoke access to the
      Service for any user who is found to have infringed on the intellectual
      property rights of third parties, or us, or otherwise is found to have
      violated any intellectual property laws.
      <br />
      You shall retain ownership of all intellectual property rights to the Ads
      and any ancillary advertising materials provided to us. You hereby grant
      to The Neuron the right and license to promote and market your Ads through
      the Platform. You hereby authorize and grant to The Neuron a license to
      use your Ads and any of your trademarks, tradenames, logos, and other
      intellectual property provided by you in connection with your use of the
      Platform.
    </p>

    <li><b>CONFIDENTIALITY</b></li>
    <p>
      Each party (as “Receiving Party”) agrees that all code, Customer Data,
      Feedback (as that term is defined below), inventions, know-how, business,
      technical and financial information it obtains from the disclosing party
      (“Disclosing Party”) constitute the confidential property of the
      Disclosing Party (“Confidential Information”), provided that it is
      identified as confidential at the time of disclosure or should be
      reasonably known by the Receiving Party to be confidential or proprietary
      due to the nature of the information disclosed and the circumstances
      surrounding the disclosure. The Service, pricing information, any The
      Neuron technology, performance information relating to the Service, and
      the terms and conditions of this Agreement shall be deemed Confidential
      Information of The Neuron without any marking or further designation.
      Except as expressly authorized herein, the Receiving Party shall (1) hold
      in confidence and not disclose any Confidential Information to third
      parties and (2) not use Confidential Information for any purpose other
      than fulfilling its obligations and exercising its rights under this
      Agreement. The Receiving Party may disclose Confidential Information to
      its employees, agents, contractors and other representatives having a
      legitimate need to know, provided that such representatives are bound to
      confidentiality obligations no less protective of the Disclosing Party
      than this Section 9 and that the Receiving Party remains responsible for
      compliance by any such representative with the terms of this Section 9.
      The Receiving Party’s confidentiality obligations shall not apply to
      information that the Receiving Party can document: (a) was rightfully in
      its possession or known to it prior to receipt of the Confidential
      Information; (b) is or has become public knowledge through no fault of the
      Receiving Party; (c) is rightfully obtained by the Receiving Party from a
      third party without breach of any confidentiality obligation; or (d) is
      independently developed by employees of the Receiving Party who had no
      access to such information. The Receiving Party may make disclosures to
      the extent required by law or court order, provided the Receiving Party
      notifies the Disclosing Party in advance and cooperates in any effort to
      obtain confidential treatment. The Receiving Party acknowledges that
      disclosure of Confidential Information would cause substantial harm for
      which damages alone would not be a sufficient remedy, and therefore that
      upon any such disclosure by the Receiving Party the Disclosing Party shall
      be entitled to seek appropriate equitable relief in addition to whatever
      other remedies it might have at law.
    </p>

    <li><b>THIRD PARTY SITES AND SERVICES</b></li>
    <p>
      Our Service is integrated with services provided by third parties as part
      of the functionality of the Service. Except as required by GDPR, we have
      no control over third parties and make no guarantees about, and assume no
      responsibility for, the information or services provided by third parties.
      <br />
      <br />
      Additionally, we may provide links to third-party websites and businesses
      and otherwise may provide information on the Service. Except to the extent
      required by GDPR, you acknowledge and agree that we are not responsible
      for the accuracy, content, or functionality of services or information
      provided on or by such third-party websites or businesses or through such
      information on the Service, and we do not endorse, nor assume any
      liability related to your use of, such websites, businesses or information
      in any way. Nothing on the Service shall be considered an endorsement,
      representation, or warranty of anything posted by other users on the
      Service.
    </p>

    <li><b>TERMINATION OF YOUR USE OF THE SERVICE</b></li>
    <p>
      At any time, you may discontinue your use of the Service. Please visit our
      Privacy Policy https://theneuron.com/#/privacy to understand how we treat
      your information when you discontinue your use of the Service. We reserve
      the right to take action to limit or prevent your access to our Service,
      if we, in our sole discretion, deem that such action is necessary based
      on: your use of the Service in a way that would potentially expose us to
      liability; disruption of the Service by you to others; your violation of
      these Terms or our Privacy Policy; your violation of any applicable laws,
      rules, regulations, and agreements that may apply to you; and your use of
      the Service in a way that could cause harm to any person.
      <br />
      <br />
      Upon any termination of these Terms: (a) all rights and licenses granted
      by The Neuron hereunder will automatically cease; (b) each party will
      promptly return to the other all of the other Party’s Confidential
      Information within its possession or control, if any; and, (c) the payment
      dates of all amounts due to The Neuron will automatically be accelerated
      so that they will become due and payable on the effective date of
      termination, even if longer terms had been provided previously.
    </p>

    <li><b>OUR INTELLECTUAL PROPERTY</b></li>
    <p>
      The Software, including all files and content contained in the Service
      created by The Neuron, and accompanying data (collectively “Our IP”) are
      the property of The Neuron or are used with permission from third parties.
      As part of these Terms, we grant you a nonexclusive, nontransferable, and
      revocable license to use Our IP solely for the purposes for which it is
      intended. And, specifically, you may not sell, redistribute, download,
      export, or reproduce Our IP in any way. You also may not decompile,
      reverse-engineer, disassemble, or otherwise convert Our IP without our
      permission. This Section does not apply to any component of Our IP that
      may be offered under an open source license.
      <br />
      <br />
      In the interest of clarity, this Agreement does not transfer from The
      Neuron to you any The Neuron or third party intellectual property. The
      Neuron, the The Neuron logo, the product names associated with the
      Service, and all other trademarks, service marks, graphics and logos used
      in connection with The Neuron, are trademarks of The Neuron (or its
      licensors, where applicable) and no right or license is granted to you.
    </p>

    <li><b>MAINTENANCE</b></li>
    <p>
      From time to time, it will be necessary for The Neuron to perform
      maintenance on its system. Such maintenance includes routine maintenance
      to ensure the continued provision of the Platform through the continued
      operation of The Neuron’s system or upgrading, updating or enhancing its
      system. The Neuron shall use its commercially reasonable efforts to
      perform such maintenance at such times to minimize the impact of any
      downtime of its system to you. To the extent The Neuron is able, The
      Neuron shall make an effort to notify you in advance of any scheduled
      maintenance.
    </p>

    <li><b>YOUR OBLIGATIONS</b></li>
    <p>
      In addition to its other obligations contained in these Terms, you shall:
      (a) be responsible for operating, supporting and maintaining your systems,
      including computer hardware and software, necessary for you to access the
      Platform; (b) be responsible for procuring and maintaining communication
      platforms, including high speed Internet connections between your systems
      and The Neuron’s system; (c) use the Service in accordance with the terms
      of this Agreement; (d) comply, at all times, with all applicable legal and
      regulatory requirements and with The Neuron’s conduct and security
      policies in respect of the use of the Platform; (e) enter and otherwise
      provide accurate and complete information to The Neuron and the Service;
      (f) comply with all of The Neuron’s posted policies for use of the
      Service; (g) secure the authorization necessary from the appropriate third
      parties (including web publishers and others) to distribute the Ads,
      content and materials through the Platform; and (h) ensure that the any
      information transmitted through the Service, including the Ads, do not
      violate applicable law (including the laws applicable to consumer
      protection, consumer credit, privacy, and intellectual property),
      regulations, and any applicable advertising codes and guidelines, and do
      not cause damage or injury to The Neuron or the Service.
    </p>

    <li><b>SECURITY</b></li>
    <p>
      The Neuron shall implement and maintain the appropriate safeguards and
      controls to deter and for the detection, prevention and correction of any
      unauthorized intrusion, access or use of the Platform. You acknowledge and
      agree that notwithstanding the security requirements, such methods and
      procedures may not prevent unauthorized electronic intruders to access the
      Platform. Except as may be required by law, The Neuron shall not be liable
      to you, and hereby disclaims responsibility, with respect to any action,
      destructive or otherwise, by any unauthorized intruder.
    </p>

    <li><b>ASSUMPTION OF RISK</b></li>
    <p>
      YOUR USE OF THE SERVICE IS ENTIRELY AT YOUR OWN RISK. YOU AGREE THAT IF
      YOU CHOOSE TO USE THE SERVICE, YOU ARE DOING SO VOLUNTARILY. YOU ASSUME
      ALL SUCH RISKS WITHOUT LIMITATION.
    </p>

    <li><b>DISCLAIMER OF WARRANTIES; “AS IS”</b></li>
    <p>
      WE ARE MAKING THE SERVICE AVAILABLE “AS IS” AND, TO THE MAXIMUM EXTENT
      PERMITTED BY LAW, WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, EXPRESS OR
      IMPLIED, REGARDING THE SERVICE, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED
      WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
      NONINFRINGEMENT. YOU ASSUME THE RISK OF ANY AND ALL DAMAGE OR LOSS FROM
      USE OF, OR INABILITY TO USE, THE SERVICE. WE DO NOT WARRANT THAT OPERATION
      OF THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE.
    </p>

    <li><b>LIMITATION OF LIABILITY</b></li>
    <p>
      TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE SHALL NOT BE LIABLE FOR DAMAGES
      OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, SPECIAL, INCIDENTAL, OR
      CONSEQUENTIAL DAMAGES, LOST PROFITS, OR LOST DATA, REGARDLESS OF THE
      FORESEEABILITY OF THOSE DAMAGES) ARISING OUT OF OR IN CONNECTION WITH YOUR
      USE OF THE SERVICE INCLUDING, BUT NOT LIMITED TO, DAMAGES ARISING FROM OR
      RELATED TO ANY OF THE FOLLOWING: ANY CLAIM RELATED TO PERSONAL OR BODILY
      INJURY IN CONNECTION WITH THE USE OF THE SERVICE; ANY HARM OR DAMAGE
      CAUSED BY, OR OTHERWISE RELATED TO, YOUR RELIANCE ON INFORMATION PROVIDED
      THROUGH THE SERVICE; ANY LOSS OR UNAUTHORIZED DISCLOSURE OF DATA; ANY HARM
      OR DAMAGE CAUSED BY, OR OTHERWISE RELATED TO, YOUR USE OF OR INTERACTION
      WITH ANY SERVICES OFFERED THROUGH THE SERVICE; AND ANY VIOLATIONS OF
      APPLICABLE LAWS, RULES, REGULATIONS, AND OTHER AGREEMENTS. THIS LIMITATION
      SHALL APPLY REGARDLESS OF THE LEGAL THEORY OR FORM OF ACTION.
      <br />
      <br />
      IN NO EVENT WILL OUR AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION
      WITH THESE TERMS AND YOUR USE OF THE SERVICE EXCEED ONE HUNDRED DOLLARS
      ($100.00).
      <br />
      <br />
      THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE
      BASIS OF THE BARGAIN BETWEEN YOU AND US.
      <br />
      SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY
      FOR CERTAIN TYPES OF DAMAGES OR CAUSES OF ACTION, SO THE ABOVE LIMITATION
      MAY NOT APPLY TO YOU.
    </p>

    <li><b>INDEMNIFICATION</b></li>
    <p>
      In the event that any third party brings a claim against us related to
      your actions, content, information, or any other use of the Service by
      you, you agree to indemnify, defend, and hold us and our agents harmless
      from and against any and all third party claims, losses, liability,
      damages, and/or costs (including reasonable attorney fees and costs)
      relating to such claim. We will notify you promptly of any such claim and
      will provide you with reasonable assistance, at your expense, in defending
      any such claim, provided, however, that our failure to so notify you shall
      not relieve you of your indemnity obligations, but instead shall reduce
      those obligations by the amount of damages or increased costs and expenses
      attributable to our failure to give notice. We reserve the right to
      approve counsel retained by you, to take control of the defense (at our
      expense) of any claim for which indemnity is required, and to participate
      in the defense of any claim (at our expense) for which indemnity is
      required. You may not settle any claim without our prior consent.
    </p>

    <li><b>DISPUTE RESOLUTION</b></li>
    <p>
      You agree to resolve any dispute, claim, or controversy with The Neuron
      arising out of or relating to your use in any way of the Service in the
      following manner. First, we both agree to attempt in good faith to resolve
      the dispute informally by contacting each other by email (you can email us
      at hello@theneuron.com). Second, if the dispute is not resolved through
      informal resolution, we both agree to attempt in good faith to resolve the
      dispute through mediation administered by JAMS, which shall take place in
      Kent County, Delaware, USA, and the costs of which shall be divided
      equally between you and The Neuron. Third, if the dispute is not resolved
      through informal resolution and mediation, we both agree to participate in
      binding arbitration administered by JAMS, which shall take place in Kent
      County, Delaware, USA.
      <br />
      <br />
      Either you or we may bring a lawsuit solely for injunctive relief without
      first engaging in the dispute resolution process described above.
      <br />
      <br />
      We both agree that, in the event of arbitration, or in the event of a
      lawsuit as permitted by this Section or otherwise, the prevailing party
      shall be entitled to costs and fees (including reasonable attorneys’
      fees). Arbitration pursuant to this Section shall be confidential, and
      neither you, nor The Neuron, nor the arbitrator may disclose the
      existence, content or results of any arbitration, except as may be
      required by law or for purposes of enforcement or appeal of the
      arbitration award. Judgment on any arbitration award may be entered in any
      court having proper jurisdiction. There shall be no right or authority for
      any claims subject to this arbitration clause to be arbitrated on a class
      action or consolidated basis or on bases involving claims brought in a
      purported representative capacity on behalf of the general public
      (including, but not limited to, as a private attorney general). If any
      portion of this arbitration clause is determined by a court to be
      inapplicable or invalid, then the remainder shall still be given full
      force and effect.
      <br />
      <br />
      YOU AND THE NEURON EACH HEREBY IRREVOCABLY WAIVE ANY AND ALL RIGHT TO
      TRIAL BY JURY IN ANY LEGAL PROCEEDING ARISING OUT OF OR RELATED TO THIS
      AGREEMENT OR THE TRANSACTIONS CONTEMPLATED HEREBY.
      <br />
      <br />
      Nothing contained in this Section shall limit our ability to take action
      related to your access to the Service as provided in these Terms.
    </p>

    <li><b>GOVERNING LAW, VENUE, & PERSONAL JURISDICTION</b></li>
    <p>
      These Terms shall be governed by the laws of the State of Delaware,
      without regard to conflict of law provisions. In the event that a lawsuit
      is filed where permitted under the provisions above, or in the event that
      the provisions above are found not to apply to you or to a given dispute,
      we both agree that any judicial proceeding will be brought in the federal
      or state courts of Delaware, USA. Both you and we consent to venue and
      personal jurisdiction there.
    </p>

    <li><b>SEVERABILITY WAIVER</b></li>
    <p>
      If, for whatever reason, any term or condition in these Terms is found
      unenforceable, all other terms and conditions will remain unaffected and
      in full force and effect. The failure to enforce any provision of these
      Terms is not a waiver of our right to do so later, and no waiver shall be
      effective unless made in writing and signed by an authorized
      representative of the waiving party.
    </p>

    <li><b>MARKETING & PUBLICITY</b></li>
    <p>
      Without The Neuron’s prior written consent, you shall not release any
      information regarding any Ads or your relationship with The Neuron or its
      customers, including, without limitation, in press releases or promotional
      or merchandising materials.
    </p>

    <li><b>FEEDBACK</b></li>
    <p>
      The Neuron shall own all right, title, and interest in and to any and all
      suggestions, ideas and/or feedback relating to the Service (collectively,
      “Feedback”) provided to by you.
    </p>

    <li><b>CHANGES TO THESE TERMS</b></li>
    <p>
      The Neuron reserves the right to change these Terms from time to time,
      with or without notice to you. If you continue to use the Service, you
      consent to the new Terms. Any changes to these Terms will become effective
      on the “Effective Date” indicated above. If you continue to use the
      Service after the Effective Date, you consent to the new Terms. The Neuron
      will always have the latest Terms posted on the Service. You agree that
      you will review these Terms periodically.
    </p>

    <li><b>CONTACT US </b></li>
    <p>
      If you have any questions about these Terms or our Service, please feel
      free to contact us by email at hello@theneuron.com.
    </p>

    <li><b>HEADINGS USED IN THESE TERMS</b></li>
    <p>
      The section headings contained in these Terms are for reference purposes
      only and shall not affect the meaning or interpretation of these Terms in
      any way.
    </p>
  </ol>
</div>

<div
  mat-dialog-actions
  class="dialog-custom__buttons-container"
  *ngIf="data.submitButtonText"
>
  <div class="dialog-custom__button left ml-auto">
    <div class="dialog-custom__button left" *ngIf="data.cancelButtonText">
      <button mat-flat-button color="primary-inverse" (click)="onCloseClick()">
        {{ data.cancelButtonText | translate }}
      </button>
    </div>
    <div class="dialog-custom__button left">
      <button
        mat-flat-button
        [disabled]="agreed"
        color="primary"
        [mat-dialog-close]="true"
        (click)="onSubmitClick()"
        cdkFocusInitial
      >
        {{ agreed ? 'Agreed' : 'I agree' }}
      </button>
    </div>
  </div>
</div>
