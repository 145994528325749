<div class="wrapper">
  <mat-form-field
    class="autocomplete-field form-field-common"
    [ngClass]="{
      'autocomplete-field_multi': selectedOptions.length > 0,
      'autocomplete-field_suffix-big': isNeedMultiResetBlock()
    }"
    appearance="outline"
  >
    <mat-label>{{ !noFilters ? label : 'screenProperties.picker.venueType.notExists' | translate }}</mat-label>
    <mat-chip-list
      class="autocomplete-field__chip-list"
      #chipList
      [selectable]="false"
    >
      <mat-chip
        *ngFor="let option of selectedOptions; let i = index"
        class="autocomplete-field__chip"
        [removable]="true"
        (removed)="doRemoveByChip(option)"
      >
        {{ option?.name }}
        <ng-container *ngIf="!readonly">
          <mat-icon matChipRemove>cancel</mat-icon>
        </ng-container>
      </mat-chip>
      <input
        [matChipInputFor]="chipList"
        [readonly]="readonly"
        [formControl]="formControl"
        [disabled]="noFilters || readonly"
        [ngClass]="{ read_only: readonly }"
        (focus)="openPanel()"
      />
    </mat-chip-list>
    <div
    *ngIf="!noFilters"
    matSuffix
    class="autocomplete-field__suffix"
    [ngClass]="{'autocomplete-field__suffix-big': isNeedMultiResetBlock()}"
  >
    <mat-icon
      *ngIf="!readonly && isPanelOpened"
      class="autocomplete-field__chevron"
      color="primary"
    >expand_less</mat-icon>
    <mat-icon
      *ngIf="!readonly && !isPanelOpened"
      class="autocomplete-field__chevron"
      svgIcon="chevron-down"
      color="primary"
    ></mat-icon>
  </div>
  </mat-form-field>

  <div class="panel" [ngClass]="isPanelOpened ? 'open' : 'closed'">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="autocomplete-tree" *ngIf="!noFilters">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [ngClass]="{'d-none': node.isHidden || node.disabled}">
        <button mat-icon-button disabled type="button"></button>
        <mat-checkbox class="checklist-leaf-node"
        color="primary"
                  [checked]="checklistSelection.isSelected(node)"
                  (change)="todoLeafItemSelectionToggle(node)">{{node.name}}</mat-checkbox>
      </mat-tree-node>
      <mat-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        matTreeNodePadding
        [ngClass]="{'d-none': node.isHidden || node.disabled}"
      >
        <button
          mat-icon-button
          matTreeNodeToggle
          type="button"
          [attr.aria-label]="'Toggle ' + node?.name"
          (click)="$event.stopPropagation()"
        >
          <mat-icon class="mat-icon-rtl-mirror" *ngIf="hasSubVenueType(node)">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>

        <mat-checkbox
          [checked]="descendantsAllSelected(node)"
          color="primary"
          [indeterminate]="descendantsPartiallySelected(node)"
          (change)="todoItemSelectionToggle(node)"
          >{{ node.name }}</mat-checkbox
        >
      </mat-tree-node>
    </mat-tree>
  </div>
</div>
