<div class="wrapper">
  <div class="time">
    <div class="head-icon">
      <mat-icon class="icon">access_time</mat-icon>
    </div>
    <div class="hour" *ngFor="let item of hours">
      <p class="hour-number">
        {{ item?.hour }}<sup>{{ item?.sup }}</sup>
      </p>
    </div>
  </div>
  <div class="week-wrapper">
    <div class="day" *ngFor="let data of weekDaysWithData; let i = index">
      <ng-container
        [ngTemplateOutlet]="weekDay"
        [ngTemplateOutletContext]="{ data: data, index: i }"
      >
      </ng-container>
    </div>
  </div>
</div>

<ng-template let-data="data" let-index="index" #weekDay>
  <dooh-week-day
    [data]="data"
    [editable]="editable"
    (addTime)="addTime($event)"
    (toggleDay)="toggleChecked($event, data?.day)"
    (removeTime)="removeTime(index, $event, data?.day)"
    (copyTime)="copyTime($event, data?.day)"
    (isEditing)="editTime(index, $event, data?.day)"
  ></dooh-week-day>
</ng-template>
