/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface CreativeForm {
    advertiserId?: string;
    categories?: Array<string>;
    length?: number;
    name?: string;
    uploadKey?: string;
    isArchived?: boolean;
    publisherIds?: string[];
    campaignId?: string;
    lineItemId?: string;
    weatherTrigger?: {
        conditions?: string[]
        temperature?:{
            max: number;
            min: number;
        }
        pollution?:string[]
    }
}
