<div class="overlay" *ngIf = "showAlert">
  <div class="content">
    <div
      mat-dialog-content 
      class="dialog-alert__content"
      [ngClass]="{'dialog-alert__content_center': !!data.isCentered}"
    >
      <div
        class="dialog-alert__type"
        [ngClass]="'dialog-alert__type_' + alertData.type"
        *ngIf="alertData.type !== 'default'"
      >
        <p><i></i></p>
        <p><b>{{alertData.title | translate}}</b></p>
      </div>
      <p>{{alertData.message | translate:(alertData.messageI18nArgs || {})}}</p>
    </div>
    <div
      mat-dialog-actions
      class="dialog-alert__buttons-container"
      [ngClass]="{'dialog-alert__buttons-container_center': !!data.isCentered}"
      *ngIf="alertData.submitButtonText"
    >
      <div class="dialog-alert__button" *ngIf="alertData.cancelButtonText">
        <button mat-flat-button color="primary-inverse" (click) = "showAlert = false">
          {{alertData.cancelButtonText | translate}}
        </button>
      </div>
      <div class="dialog-alert__button">
        <button mat-flat-button  color="primary" [mat-dialog-close]="false" cdkFocusInitial>
          {{alertData.submitButtonText | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-title *ngIf="data.heading">
  <span>{{data.heading | translate}}</span>
  <div>
    <button *ngIf="data.isDeleteButtonPresent" mat-flat-button color="primary-inverse" [mat-dialog-close]="false" (click)="onDeleteClick()">
      {{"generic.delete" | translate}}
    </button>
    <button mat-icon-button color="primary" (click)="onCancelClick()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
</div>
<div
  mat-dialog-content
  class="dialog-custom__content"
  [ngClass]="{'dialog-alert__content_center': !!data.isCentered}"
>
  <ng-template #target></ng-template>
</div>
<div
  mat-dialog-actions
  class="dialog-custom__buttons-container"
  [ngClass]="{'dialog-alert__buttons-container_center': !!data.isCentered}"
  *ngIf="data.submitButtonText"
>
  <div class="dialog-custom__button" *ngIf="data.cancelButtonText">
    <button mat-flat-button color="primary-inverse" (click)="onCancelClick()">
      {{data.cancelButtonText | translate}}
    </button>
  </div>
  <div class="dialog-custom__button">
    <button mat-flat-button color="primary" [mat-dialog-close]="true" [disabled]="data.submitButtonDisabled"
            (click)="onSubmitClick()" cdkFocusInitial>
      {{data.submitButtonText | translate }}
    </button>
  </div>
</div>
