import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiService } from '@dooh/api';
import { Observable } from 'rxjs';
const apiUrl = 'dsp';
@Injectable({
  providedIn: 'root'
})
export class WeatherService {

  constructor(
    private apiService: ApiService
  ) {
  }

  deleteWeather(creativeId: string, weatherId: string): Observable<any> {
    if (creativeId === null || creativeId === undefined) {
      throw new Error('Required parameter creativeId was null or undefined when calling deleteWeather.');
    }
    if (weatherId === null || weatherId === undefined) {
      throw new Error('Required parameter weatherId was null or undefined when calling deleteWeather.');
    }
    const queryUrl = `${apiUrl}/creatives/${encodeURIComponent(creativeId)}/weather_triggers/${encodeURIComponent(weatherId)}`;
    return this.apiService.delete(queryUrl);
  }

  addWeatherToCreative(body:IweatherTrigger, creativeId, triggerId = null) {
    let queryUrl = `${apiUrl}/creatives/${creativeId}/weather_triggers`;
    if (triggerId) {
      queryUrl = `${apiUrl}/creatives/${creativeId}/weather_triggers/${triggerId}`;
      return this.apiService.put(queryUrl, body);
    }
    return this.apiService.post(queryUrl, body);
  }
}
export interface IweatherTrigger{
  conditions?: string[],
  temperature?: {
    max: number,
    min: number
  }
}
