import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import * as currencyCodeLib from 'currency-codes';
import { SelectedInstanceService } from '@dooh/common-services';

const DEFAULT_INSTANCE_CURRENCY  = 'USD';
@Pipe({
  name: 'money'
})
export class MoneyPipe extends CurrencyPipe implements PipeTransform {
  getParts(formattedString: string) {
    const matched = formattedString.match(/(\W|[A-zA-Z]+|\d*.+\d*)/g);
    return {
      currency: matched[0] || '',
      value: matched[1] || ''
    }
  }

  transform(
    val: any,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): string | null {
    if (!parseFloat(val)) return val;

    const sign = val < 0 ? '-' : '';
    const value = Math.abs(val);
    const instanceCurrency = SelectedInstanceService.getInstanceCurrency() || DEFAULT_INSTANCE_CURRENCY;
    const digitsInformation = '1.0-'+ currencyCodeLib.code(instanceCurrency).digits;
    const formattedString = super.transform(
      value,
      currencyCode || instanceCurrency,
      display || 'code',
      digitsInformation,
      locale
    );
    const part = this.getParts(formattedString);
    const formattedValue = Number(part.value.replace(/,/g, ''));
    return `${part.currency} ${sign}${formattedValue}`
  }
}
