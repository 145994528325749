import { Injectable } from '@angular/core';
import { ApiService, CustomHttpUrlEncodingCodec } from '@dooh/api';
import { HttpParams } from '@angular/common/http';
import { DealForm, PageDto } from '@dooh/models';

import { apiUrl } from '../../api-url';
import { SelectedInstanceService } from '@dooh/common-services';
import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DealIdService {
  constructor(
    private apiService: ApiService
  ) { }

  sort = ['name,asc'];
  filters = ['is_archived,eq,false'];
  sspIdSource = new ReplaySubject<string>(1);
  sspId$ = this.sspIdSource.asObservable();

  setSspId(id:string){
    this.sspIdSource.next(id);
  }

  getDeals( sort?: string[], page: number = 1, pageSize: number = 50, filters?: string[]){
    const queryUrl = `${apiUrl}/deals/v2`;
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }
    if (filters && filters.length > 0) {
      filters.forEach((element) => {
        queryParameters = queryParameters.append('filters', <any>element);
      });
    }

    return this.apiService.get(queryUrl, queryParameters);
  }

  getInstanceSsps(instanceId: string, page: number = 1, pageSize: number = 50) {
    const queryUrl = `${apiUrl}/instances/${encodeURIComponent(instanceId)}/ssps`;
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    return this.apiService.get(queryUrl, queryParameters);
  }

  getAdvertisersByDealId(dealId: string, page: number = 1, pageSize: number = 50) {
    const queryUrl = `${apiUrl}/deals/v2/${dealId}/advertisers`;
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    return this.apiService.get(queryUrl, queryParameters);
  }


  saveDealId(dealId: DealForm){
    const queryUrl = `${apiUrl}/deals/v2/`;

    return this.apiService.post(queryUrl, dealId);
  }

  getDealById(id: string) {
    const queryUrl = `${apiUrl}/deals/v2/${id}`;

    return this.apiService.get(queryUrl);

  }

  updateDealId(id: string, dealId: any){
    const queryUrl = `${apiUrl}/deals/v2/${id}`;

    return this.apiService.put(queryUrl, dealId);
  }

  deleteDealId(id: string){
    const queryUrl = `${apiUrl}/deals/v2/${id}`;

    return this.apiService.delete(queryUrl);
  }

  getAll(page: number = 1, pageSize: number = 50, filters?: string[], args?: any, keyword?: string,): Observable<PageDto> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let sspId = null;
    this.sspId$.pipe(take(1)).subscribe(res =>{
      sspId = res;
    })
    

    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    const instanceId = SelectedInstanceService.getInstanceId();
    let queryUrl;
    if (keyword !== undefined && keyword !== null && keyword.length > 0 ) {
     queryUrl = `${apiUrl}/instances/${instanceId}/ssps/${sspId}/publishers?filters=name%2Clike%2C%25${keyword}%25`;
    }else{
       queryUrl = `${apiUrl}/instances/${instanceId}/ssps/${sspId}/publishers`;

    }
    return this.apiService.get(queryUrl, queryParameters);
  }

  archiveDealId(id: string, dealId: any){
    const queryUrl = `${apiUrl}/deals/v2/${id}`;

    return this.apiService.patch(queryUrl, dealId);
  }
}
  

