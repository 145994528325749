import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../apps/sso-webapp/src/environments/environment';

export enum INIT_STATUS {
  IN_PROGRESS = 'in_progress',
  RESOLVED = 'resolved',
  REJECTED = 'rejected',
};

@Injectable({
  providedIn: 'root'
})
export class NamespaceInitService {
  constructor(
    private http: HttpClient
  ) {}

  initNamespaceByDomain(domain: string): Observable<any> {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Without-Auth': 'true'
    };

    const headers = new HttpHeaders(headersConfig);
    
    return this.http.get(`${environment.api_url}/admin/init/${domain}`, { headers })
  }

  getSpinnerJson(url: string): Observable<any> {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Without-Auth': 'true'
    };
    const headers = new HttpHeaders(headersConfig);
    return this.http.get(url, { headers })
  }
}
