/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AdvertiserView } from './advertiserView';
import { IabCategoryView } from '..';
import { WeatherTriggerView,cryptoTriggerView,airTriggerView, CustomTriggerView } from './weatherTriggerView';

export interface CreativeView {
  advertiser?: AdvertiserView;
  id?: string;
  name?: string;
  size?: number,
  length?: number,
  width?: number;
  type?: CreativeView.TypeEnum;
  url?: string;
  canBeDeleted?: boolean;
  creativeId?: string,
  status: string[];
  mimeType?: string;
  categories?: IabCategoryView[];
  isArchived?: boolean;
  hasRelation?: boolean;
  totalRelationsWithLineItems?: number;
  totalRelationsWithActiveLineItems?: number;
  height?: number;
  weatherTrigger: WeatherTriggerView;
  cryptoTrigger: cryptoTriggerView;
  airTrigger: any;
  publishers?: {
    publisherId: string;    
    id: string;
    name: string;
    status: string;
  }[];
  customTrigger: CustomTriggerView;
}

export namespace CreativeView {
  export type TypeEnum = 'VIDEO' | 'IMAGE' | 'HTML';
  export const TypeEnum = {
    VIDEO: 'VIDEO' as TypeEnum,
    IMAGE: 'IMAGE' as TypeEnum,
    HTML: 'HTML' as TypeEnum
  };
}
