import { CanActivate, Router } from '@angular/router';
import { Injectable, ModuleWithComponentFactories } from '@angular/core';
import { LoginVars } from '../helper/helper';
import { WhoamiService } from "@dooh/common-services";

@Injectable()
export class SmbAuth implements CanActivate{

  constructor(
    private route: Router
  ){}

  canActivate() {
    const currentUser = WhoamiService.getCurrentUser().dspRoles[0];
    if(currentUser === "SMB_MANAGER")
      return true;
    else
      return false;
  }

}
