import { Component, ComponentRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../dialog.service';
import { AuthService } from '@dooh/auth';
@Component({
  selector: 'dooh-new-smb-dialog',
  templateUrl: './new-smb-dialog.component.html',
  styleUrls: ['./new-smb-dialog.component.scss']
})
export class NewSmbDialogComponent implements OnInit {

  contentRef: ComponentRef<any>;

  domainSmb = '';
  constructor(
    private dialogRef: MatDialogRef<NewSmbDialogComponent>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService
  ) {
    this.domainSmb = data.data?.domainSmb;
  }

  ngOnInit(): void {
    this.dialogRef.updateSize('760px');
  }


  goToNewSystem(): void {
    window.location.href = "https://" + this.domainSmb;
  }

  logout() {
    this.authService.logout().then(() => window.location.href = '/');
  }

  ngOnDestroy(): void {
    if (this.contentRef) {
      this.contentRef.destroy();
    }
  }
}
