import { ValidatorFn, AbstractControl } from '@angular/forms';

export default function simpleEmailValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const value = control.value;
    if (typeof value !== 'string') {
      return null;
    }
    return value.includes('.') && value.includes('@') ? null : { 'simpleEmail': true };
  };
}
