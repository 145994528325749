import { AfterViewInit, Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { of, forkJoin, Subject, Subscription, Observable } from 'rxjs';

import { ColumnCustomizer, ColumnType, ScreenView, TableCustomizer, TableColumn, FilterScreenObject } from '@dooh/models';
import { TargetingFiltersService, MapService, WhoamiService, ActiveOfferService } from '@dooh/common-services';
import { DialogService } from '../../../dialogs/dialog.service';

import { ScreensParentService } from '../../services/screens-parent.service';
import { ScreensApi } from '../../screens-view.component.models'
import { take, takeUntil } from 'rxjs/operators';
import { ScreensService } from 'apps/smb-app/src/app/campaign-management/services/screens.service';
import { BulkSelectService } from '../../../services/bulk-select.service';
import formatScreenFilterObject from 'libs/utils/src/lib/screens/formatScreenFilterObject';
import { MatDialog } from '@angular/material/dialog';
import { AudienceComponent } from 'libs/audience-library/src/lib/components/audience/audience.component';
import { ActivatedRoute } from '@angular/router';
import { DmaDialogComponent } from '../../../dialogs/dma-dialog/dma-dialog.component';
import { DmaStateService } from '../../../services/dma-dialog.service';
import { TitleCasePipe } from '@angular/common';

const PAGE_SIZE = 50;

@Component({
  selector: 'dooh-screens-view-tab',
  templateUrl: './screens-view-tab.component.html',
  styleUrls: ['./screens-view-tab.component.scss'],
  outputs: [
    'addedScreensChange',
    'deletedScreensChange',
    'previewScreensChange',
    'cancel',
    'save'
  ]
})
export class ScreensViewTabComponent extends ScreensParentService implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  isPoiActive: boolean;
  isScreenPropertiesActive: boolean;

  private _editable = false;
  activePoi: boolean;
  mapDataSub: Subscription;
  isBulkSelect$: Observable<boolean> = null;
  showDmaActive = false;
  showDma = false;

  @Input()
  set editable(value: boolean) {
    this._editable = value;
    this.loadConfiguration(this.SCREENS.PREVIEW, value, this.getPreviewScreenClass);
    this.loadConfiguration(this.SCREENS.ADD, true)
  }

  get editable(): boolean {
    return this._editable;
  }

  @Input()
  screensApi: ScreensApi;

  @Input()
  isCreating: boolean;

  @Input()
  enableSave?: boolean;

  @Input()
  hideSaveButton?: boolean;

  @Input()
  hideCancelButton?: boolean;

  @Input()
  isMapView?: boolean;

  @Input()
  hidePoi?: boolean;

  @Input()
  hideScreenProperties?: boolean;

  @Output()
  onEditClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  ontoggleView: EventEmitter<boolean> = new EventEmitter<boolean>();


  @Output()
  expectedReachUpdated?: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  componentInit: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('formats') formatTemp: any;

  protected unsubscriber$ = new Subject<void>();

  constructor(
    public mapService: MapService,
    public dialogService: DialogService,
    public targetingFiltersService: TargetingFiltersService,
    private screenApiService: ScreensService,
    injector: Injector,
    private bulkSelectService:BulkSelectService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public dmaService: DmaStateService,
    private titleCase: TitleCasePipe,
    private activeOfferService: ActiveOfferService
  ) {
    super(mapService, targetingFiltersService, dialogService,injector, dmaService);
    this.isBulkSelect$ = this.bulkSelectService.isTotalSelected$;
  }

  ngOnInit() {
    this.getAllVenueTypes();
    this.screens.add.pageSize = PAGE_SIZE;
    this.filtersForm = new FormGroup({
      screenTagSearchField: new FormControl([])
    });
    this.mapService.currentSearchField$.pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
      if (res?.data) {
        this.filtersForm.get('screenTagSearchField').patchValue(res?.data);
        if (res?.data?.length > 0) {
          if (this.editable) {
            this.showDma = res?.data.some( screenInfo => screenInfo?.countryCode === 'us' );
          }
          this.activePoi = true;
          this.screensConfiguration(null, res?.removeFilter);
        }
      } else {
        this.screenApiService.setComponent(null);
      }
    });
    if(this.enableSave === undefined)
      this.enableSave = true;

    this.targetingFiltersService.dmaFilters$
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe(res => {
          this.dmaIds = res?.dmaId ? res?.dmaId : [];
        });

        this.targetingFiltersService.placeIqFilters$
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe((res: any) => {
          if (res) {
            this.activePoi = true;
            this.filterScreenObject['placeIq'] = res;
            this.getScreens(this.SCREENS.ADD, true, true);
          }
        });

    this.targetingFiltersService.screenPropertiesFilters$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((filters: { limits: { floorPrice: { min: number, max: number }, audiencePerWeek: { min: number, max: number } }, tags: Array<{ name: string; properties: string[] }> }) => {
      if (!filters) {
        return;
      }

      const filterTagsMapByName = (filters?.tags || []).reduce(
        (obj, item) => {
          if (!item) {
            return obj;
          }
          obj[item.name] = {...item};
          return obj;
        },
        {}
      );
      const tagsMapByName = (this.activeScreenTags || []).reduce(
        (obj, item) => {
          if (!item || item.tag === 'Place') {
            return obj;
          }
          const tag = item.tag;
          if (!obj[tag]) {
            obj[tag] = {
              name: tag,
              properties: []
            };
          }
          obj[tag].properties.push(item.id);
          return obj;
        },
        filterTagsMapByName
      );

    if (!this.filterScreenObject) {
      this.filterScreenObject = {
        tags: [],
        pois: null,
        postalCodes:null
      }
    }

      Object.keys(tagsMapByName).forEach(tagName => {
        const index = this.filterScreenObject?.tags.findIndex(tag => tag.name === tagName);

        if (index === -1) {
          this.filterScreenObject.tags.push(tagsMapByName[tagName]);
        } else {
          this.filterScreenObject.tags[index] = tagsMapByName[tagName]
        }
      });

      if (this.dmaIds?.length > 0) {
        const index = this.filterScreenObject?.tags.findIndex(tag => tag.name === 'dmaIds');
        if (index === -1) {
          this.filterScreenObject.tags.push({name: 'dmaId', properties: this.dmaIds})
        } else {
          this.filterScreenObject.tags[index] = {name: 'dmaId', properties: this.dmaIds}
        }
      }
      this.filterScreenObject.limits = filters.limits;
      this.getScreens(this.SCREENS.ADD, true);
    });

    this.targetingFiltersService.isPoiActive$.pipe(takeUntil(this.unsubscriber$)).subscribe(isActive => {
      this.isPoiActive = isActive;
    });

    this.targetingFiltersService.poiFilters$.subscribe((filters: {type:string, pois: any, radius: number, zipCodes:any }) => {
      if (!filters) {
        return;
      }

      this.targetingFiltersService.resetTagsPages();
      this.filterScreenObject.pois = { points: [...filters.pois], radius: filters.radius };
      this.filterScreenObject.postalCodes = filters.zipCodes;
      this.getScreens(this.SCREENS.ADD, true, true);
    });

    this.targetingFiltersService.isScreenPropertiesActive$.pipe(takeUntil(this.unsubscriber$)).subscribe(isActive => {
      this.isScreenPropertiesActive = isActive;
    });

    this.targetingFiltersService.getTagsNextPage$.subscribe(() => {
      this.getScreenFilters();
    });


    this.bulkSelectService.isTotalSelected$.pipe(takeUntil(this.unsubscriber$)).subscribe(
      isTotalSelected=>{
        if (isTotalSelected) {
          const queryObj = formatScreenFilterObject(this.filterScreenObject);
          this.bulkSelectService.setQuery(queryObj);
        }
        else {
          this.bulkSelectService.setQuery(null);
        }
      }
    )

    this.activeOfferService.selectedOffer$.pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
      this.offerConditions = res?.conditions;
    })

    if (this.editable && this.offerConditions) {
      this.activePoi = true;
      this.screensConfiguration()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes.isCreating && changes.isCreating.currentValue) {
        this.currentTab = this.isCreating ? this.SCREENS_TAB : this.PREVIEW_SCREENS_TAB
      }
      if (changes.editable) {
        if (this.editable && this.offerConditions) {
          this.activePoi = true;
          this.screensConfiguration()
        }
      }
  }

  ngAfterViewInit() {
    this.instantiateSharedData();
    this.componentInit.emit(true)
    this.loadConfiguration(this.SCREENS.PREVIEW, this.editable, this.getPreviewScreenClass);
    this.loadConfiguration(this.SCREENS.ADD, true);

    this.getScreens(this.SCREENS.PREVIEW);
  }
  private loadConfiguration(type: string, hasCheckbox = false, getRowClass?: (row: any) => string) {
    const columnCustomizers = this.loadColumnCustomizers(hasCheckbox);

    if (type === this.SCREENS.PREVIEW && this.editable) {
      columnCustomizers.forEach((column: ColumnCustomizer) => {
        column.sortDisabled = true;
      })
    }
    this.screens[type].tableCustomizer = new TableCustomizer(
      'screens',
      columnCustomizers,
      false,
      null,
      null,
      getRowClass
    );
  }

  private loadColumnCustomizers(hasCheckbox: boolean): Array<ColumnCustomizer> {
    const columns: TableColumn[] = [
      {
        name: 'name',
        heading: 'Screen Name',
        valuePath: ['name'],
        type: hasCheckbox ? ColumnType.CHECKBOX_GROUP : ColumnType.STRING,
        icon: 'box',
        toolTipMessage: "The screen was added from a package",
        showIcon: (data: any) => (data.packages && data.packages.length !== 0) || data.packageId
      },
      {
        name: 'formats',
        heading: 'Formats',
        valuePath: ['formats'],
        type: ColumnType.CUSTOM,
        sortDisabled: true,
        customTemplate: this.formatTemp
      },
      {
        name: 'publisher_name',
        heading: 'Publisher Name',
        valuePath: ['publisher', 'name']
      },
      {
        name: 'ssp_name',
        heading: 'SSP',
        valuePath: ['ssp', 'name']
      },
      {
        name: 'venue_type',
        heading: 'Venue Type',
        valuePath: ['venueType', 'name'],
        valueExtractor: (data: any) => {
          let venueId = data?.venueType?.id || data?.venueTypeId;
          if (venueId) {
            return this.getVenueTypeName(venueId);
          } else {
            return '-';
          }
        }
      },
      {
        name: 'address_country',
        heading: 'Country',
        valuePath: ['address', 'country']
      }
    ];

    if (WhoamiService.getCurrentUser().dspRoles[0] !== 'SMB_MANAGER') {
      columns.push(
        {
        name: 'weekly_audience',
        heading: 'Audience',
        valuePath: ['audiencePerWeek'],
        type: ColumnType.ROUND_NUMBER
      },
      {
        name: 'floor_price',
        heading: 'Floorprice',
        valuePath: ['convertedPrice', 'floorPrice'],
        type: ColumnType.MONEY
      })
    }

    return columns.map(column => new ColumnCustomizer(column));
  }

  onTablePaging($event, type:string) {
      this.screens[type].currentPage = $event?.pageNumber || 1;
      this.screens[type].pageSize = $event?.pageSize || this.screens[type].pageSize;
      this.getScreens(type, false, false, true);
  }

  onTableScroll(type: string) {
    if (this.screens[type].isLoading) {
      return;
    }

    this.screens[type].currentPage += 1;
    this.getScreens(type);
  }

  onSortChange($event: any, type: string) {
    this.screens[type].sort = [`${$event.name},${$event.direction}`];
    this.getScreens(type, true);
  }

  triggerAction($event: { element: ScreenView; action: string }, type: string) {
    const screen = $event.element;
    if ($event.action === 'SELECT') {
      this.screens[type].selectedItems.push(screen);
    } else if ($event.action === 'DESELECT') {
      this.screens[type].selectedItems = this.screens[type].selectedItems.filter(item => item.id !== screen.id);
    }

    this.screens[type].updateMarkerIcons();
  }



  add() {
    this.addScreens(this.screens[this.SCREENS.ADD].selectedItems, this.SCREENS.ADD);
  }

  delete() {
    const cache_previous_datasource_data = JSON.stringify(this.screens[this.SCREENS.PREVIEW].dataSource.data);
    const cache_previous_deleted_data = JSON.stringify(this.deletedScreens);
    const cache_previous_selectedItems = JSON.stringify(this.screens[this.SCREENS.PREVIEW].selectedItems);
    this.removeScreens(this.screens[this.SCREENS.PREVIEW].selectedItems, this.SCREENS.PREVIEW);

    if (JSON.parse(cache_previous_selectedItems).length > 0) {
      let deleteCount = 0;
      const uniqueScreens = new Map();
      for(let screen_deleted of JSON.parse(cache_previous_selectedItems)) {
        const info = `${screen_deleted.resolution.width}_${screen_deleted.resolution.height}_${screen_deleted.constraintFakeId}`;
        if(screen_deleted.hasCreatives && !uniqueScreens.has(info)) {
          deleteCount = deleteCount + 1;
          uniqueScreens.set(info, `${screen_deleted.resolution.width}X${screen_deleted.resolution.height}`);
        }
      }

      for(let prevScreen of this.screens[this.SCREENS.PREVIEW].dataSource.data) {
        const info = `${prevScreen.resolution.width}_${prevScreen.resolution.height}_${prevScreen.constraintFakeId}`;
        if(prevScreen.hasCreatives && uniqueScreens.has(info)) {
          deleteCount = deleteCount - 1;
          uniqueScreens.delete(info);
        }
      }
      if (deleteCount > 0) {
        this.openCancelConfirmDialog(
          `
          If you remove this screen(s) <b>${[...uniqueScreens.values()].join(', ')}</b> from your campaign,
          the creatives associated only with this screen(s) will also be removed from this campaign,
          but they will be saved in Creative Management, so you may use them again. Are you sure,
          that you want to remove this screen from the current campaign?
          `,
          true
        ).afterClosed().subscribe(
          resp => {
            if (!resp) {
              this.screens[this.SCREENS.PREVIEW].dataSource.data = JSON.parse(cache_previous_datasource_data)
              this.deletedScreens = JSON.parse(cache_previous_deleted_data);
              this.deletedScreensChange.emit(JSON.parse(cache_previous_deleted_data));
            }
          }
        );
      }
    }
    if(this.screens.preview.totalElements + this.addedScreens.length - this.deletedScreens.length > 0){
      this.screens[this.SCREENS.PREVIEW].currentPage += 1;
      this.getScreens(this.SCREENS.PREVIEW, false, false, true);
    }
  }

  openCancelConfirmDialog(message: string, isHtml: boolean = false) {
    return this.dialogService.alert({
      type: 'warning',
      title: 'dialog.alert.title.sure',
      message,
      isHtml,
      submitButtonText: 'dialog.alert.submitButtonText.yes',
      cancelButtonText: 'dialog.alert.cancelButtonText.no',
    })
  }



  afterSave() {
    this.onReset();
    this.resetSharedData();
    this.getScreens(this.SCREENS.PREVIEW, true);
  }

  onSave() {
    const deletedScreenMap = this.deletedScreens.reduce((storage, screen) => {
      storage[screen.screenId] = true;
      return storage;
    }, {});

    const addedScreensIds = this.addedScreens.reduce((storage, screen) => {
      if (!deletedScreenMap[screen.id]) {
        storage.push(screen.id);
      } else {
        deletedScreenMap[screen.id] = false;
      }
      return storage;
    }, []);

    const deletedScreensIds = this.deletedScreens.reduce((storage, screen) => {
      if (deletedScreenMap[screen.screenId]) {
        storage.push(screen.id);
      }
      return storage;
    }, []);

    const postCall = addedScreensIds.length
      ? this.screensApi.addScreens(addedScreensIds)
      : of(null);
    const deleteCall = deletedScreensIds.length
      ? this.screensApi.deleteScreens(deletedScreensIds)
      : of(null);

    forkJoin([ deleteCall, postCall ])
      .subscribe(
        () => {
          this.onReset();
          this.save.emit();
          this.getScreens(this.SCREENS.PREVIEW, true);
        }
      );
  }

  getPreviewScreenClass(row: ScreenView) {
    return !row.screenId ? 'screens-tab__preview-screen' : '';
  }


  onActiveFilters($event){
    this.activePoi = true;
    this.bulkSelectService.resetBulkService();
  }

  openPoi() {
    this.targetingFiltersService.togglePoi(true);
  }


  toggleView({checked}):void{
    this.ontoggleView.emit(checked);
  }

  ngOnDestroy(): void {
    if (this.mapDataSub) {
      this.mapDataSub.unsubscribe();
    }
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }


  getPoiScreens() {
    if (this.isPoiActive) {
      this.mapService.getPoisMapData().pipe(takeUntil(this.unsubscriber$)).subscribe((data: any[]) => {
        if (String(typeof data) !== 'function' && data.length > 0) {
          this.screens.add.dataSource.data = data;
        }
      });
    }
  }


  openAudience(){
    const campaignId = this.route.parent.snapshot.params['id'];
    this.dialog.open(AudienceComponent, {
      panelClass: 'audience-modal',
      data: {
        filterObj: this.filterScreenObject,
        campaignId,
      },
      height: '100vh',
      width: '100%',
      maxWidth: '680px',
      position: {
        right: '0',
      },
      autoFocus: false,
    }).afterClosed().subscribe(
      value=>{
        if(value){
          this.targetingFiltersService.setPlaceIqFilters(value);
        }
      }
    )
  }
  getDma(): void {
    this.dialog.open(DmaDialogComponent, {
      panelClass: 'customize',
      data: {
        dmaId: this.dmaIds
      }
    }).afterClosed()
    .pipe(takeUntil(this.unsubscriber$))
    .subscribe(
      resp => {
        if (resp && resp.dmaId) {
          this.showDmaActive = true;
          this.dmaIds = resp.dmaId;
          const tag = {
            name: 'dmaId',
            properties: this.dmaIds
          }
          this.filterScreenObject.tags.push(tag);
          this.getScreens(this.SCREENS.ADD, true, true);
        }
        if(resp?.reset) {
          if (this.filterScreenObject.tags.length > 0) {
            this.filterScreenObject.tags = this.filterScreenObject.tags.filter(tag => tag.name !== 'dmaId');
          }
          this.dmaIds = [];
          this.getScreens(this.SCREENS.ADD, true, true);
        }
      }
    );
  }

}
