import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {WhoamiService} from "@dooh/common-services";

@Injectable({
  providedIn: 'root'
})
export class DspAdvertiserGuard implements CanActivate {
  constructor(private router: Router) {
  }
  canActivate(): boolean {
    if (WhoamiService.getCurrentUser().dspRoles[0] !== 'ADVERTISER') {
      return true
    } else {
      this.router.navigateByUrl('/dsp/reporting');
    }
  }

}
