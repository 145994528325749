import { NamespaceView } from "@dooh/models";

export type AppAmplifyConfig = {
  Auth: {
    region: string,
    userPoolId: string,
    userPoolWebClientId: string,
    authenticationFlowType: string,
    mandatorySignIn: boolean,
  },
};

export const createAmplifyConfig = ({
  userPoolId,
  oauthClientId,
}: NamespaceView): AppAmplifyConfig => {
  const cognitoPool = { userPoolId, oauthClientId };
  localStorage.setItem('cognitoPool', JSON.stringify(cognitoPool));

  return {
    Auth: {
      region: 'us-east-1',
      mandatorySignIn: false,
      userPoolId: userPoolId,
      userPoolWebClientId: oauthClientId,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
  }
};
