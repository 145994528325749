import {
  Component,
  Input,
  Output,
  SimpleChanges,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy, OnInit, TemplateRef
} from "@angular/core";
import { formatLabel, ColorHelper, id } from "@swimlane/ngx-charts";
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'g[ngx-custom-circle-series]',
  templateUrl: './custom-circle-series.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animationState', [
      transition(':enter', [
        style({
          opacity: 0
        }),
        animate(250, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class CustomCircleSeriesComponent implements OnChanges, OnInit {
  @Input() name: string;
  @Input() data;
  @Input() xScale;
  @Input() yScale;
  @Input() colors: ColorHelper;
  @Input() tooltipDisabled = false;
  @Input() combinedSeries:any[] = [];
  @Input() tooltipTemplate: TemplateRef<any>;

  @Output() select = new EventEmitter();
  @Output() activate = new EventEmitter();
  @Output() deactivate = new EventEmitter();

  circles: any;
  gradientId: string;
  gradientFill: string;

  ngOnInit() {
    this.gradientId = 'grad' + id().toString();
    this.gradientFill = `url(#${this.gradientId})`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  update(): void {
    this.circles = this.getCircles();
  }

  getCircles() {
    return this.data.map((item, index) => {
      return this.mapDataPointToCircle(item, index);
    })
  }

  mapDataPointToCircle(item: any, i: number): any {
    const cx = this.xScale(item.name)
    const cy = this.yScale(item.value);
    const radius = 5;
    const height = this.yScale.range()[0] - cy;
    const opacity = 1;
    const color = this.colors.getColor(this.name);

    return {
      classNames: [`circle-data-${i}`],
      data: item,
      cx,
      cy,
      radius,
      height,
      color,
      opacity,
      barVisible: false,
      gradientStops: this.getGradientStops(color),
    };
  }

  getSeries(name) {
    return this.combinedSeries.map(item => {
      const ser = item.series.find(s => name === s.name);
      return {
        name: item.name,
        value: ser && ser.value
      }
    })
  }

  getTooltipText({ name }): string {
    const series = this.getSeries(name);
    let toolTip = `<p>${formatLabel(name)}</p>`;
    series.forEach(ser => {
      toolTip += `<p>${ser.name} : ${ser.value}</p>`
    })
    return toolTip;
  }

  getTooltipData({ name }) {
    const series = this.getSeries(name)
    const data = series.map(item => ({
      ...item,
      color: this.colors.getColor(item.name)
    }))
    return {
      name: formatLabel(name),
      data
    }
  }

  getGradientStops(color) {
    return [
      {
        offset: 0,
        color,
        opacity: 0.2
      },
      {
        offset: 100,
        color,
        opacity: 1
      }
    ];
  }

  onClick(circle): void {
    this.select.emit(circle.data);
  }

  activateCircle(circle): void {
    circle.barVisible = true;
    this.activate.emit({ name: this.name });
  }

  deactivateCircle(circle): void {
    circle.barVisible = false;
    this.deactivate.emit({ name: this.name });
  }
}
