import { Component, EventEmitter, Input, OnInit, OnDestroy, Output, OnChanges, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject, of, forkJoin, Subscription, Observable } from 'rxjs';
import { first, mergeMap, takeUntil } from 'rxjs/operators';
import { debounceTime, map } from 'rxjs/operators';

import {
  ColumnCustomizer,
  ColumnType,
  ScreenView,
  TableCustomizer,
  TableColumn,
  ScreenTagView,
  FilterScreenObject,
  BboxObject
} from '@dooh/models';
import { TargetingFiltersService, MapService, ScreenTagsPagination, TargetingsService } from '@dooh/common-services';
import { DialogService } from '../dialogs/dialog.service';

import { ScreensApi } from './screens-tab.component.models';
import { BulkSelectService, BULK_REQUEST_LIMIT } from '../services/bulk-select.service';
import formatScreenFilterObject from 'libs/utils/src/lib/screens/formatScreenFilterObject';
import { ApiService } from '@dooh/api';
import { MatDialog } from '@angular/material/dialog';
import { AudienceComponent } from 'libs/audience-library/src/lib/components/audience/audience.component';
import { AudienceStateService } from 'libs/audience-library/src';
import { ActivatedRoute } from '@angular/router';
import { IScreenBatchBody } from 'apps/smb-app/src/app/campaign-management/services/screens.service';
import { DmaDialogComponent } from '../dialogs/dma-dialog/dma-dialog.component';
import { DmaStateService } from '../services/dma-dialog.service';

const PAGE_SIZE = 50;
const SEARCH_DELAY = 800;

@Component({
  selector: 'dooh-screens-tab',
  templateUrl: './screens-tab.component.html',
  styleUrls: ['./screens-tab.component.scss']
})
export class ScreensTabComponent implements OnInit, OnDestroy, OnChanges {

  @Output() selectedScreensOutput: EventEmitter<any[]> = new EventEmitter();
  private readonly SCREENS_TAB = 1;
  private readonly PREVIEW_SCREENS_TAB = 0;
  private readonly SCREENS = {
    PREVIEW: 'preview',
    ADD: 'add'
  };
  private readonly SCREENS_MAP = {
    [this.PREVIEW_SCREENS_TAB]: this.SCREENS.PREVIEW,
    [this.SCREENS_TAB]: this.SCREENS.ADD
  };

  currentTab: number = this.PREVIEW_SCREENS_TAB;
  isAllSelected:boolean;
  venueIconsMap={
    '1':"transit",
    '2':'retail',
    '3':'outdoor',
    '4':'health',
    '5':'care',
    '6':'education',
    '7':'offices',
    '8':'leisure',
    '9':'government',
    '10':'financial',
    '11':'residential'
  }
  screens = {
    [this.SCREENS.PREVIEW]: {
      ...this.getTableConfig(),
      apiCall: (refresh?: boolean, _updateFilters?: boolean, pageStrategy = false,type?:any) => {
        this.screens.preview.subscription = this.screensApi.getPreviewScreens(
          this.screens.preview.currentPage, this.screens.preview.pageSize, this.screens.preview.sort
        ).subscribe(result => {
          this.screens.preview.data = (refresh || pageStrategy)
            ? result.content
            : [...this.screens.preview.data, ...result.content];
          this.selectedScreensOutput.emit(this.screens.preview.data);
          this.screens.preview.totalElements = result.totalElements;
          this.expectedReach = this.screens.preview.data.reduce((sum, screen) => {
            sum += screen['audiencePerWeek'];
            return sum;
          }, 0);
          if(refresh){
            this.screens.preview.dataSource.data = this.screens.preview.data;
          }
          else{
            this.screens.preview.syncDataSource();
          }
          this.mapService.setData(this.screens.preview.dataSource.data);
          this.screens.preview.updateMarkerIcons();
          this.screens.preview.updateMarkerIcons();
          this.screens.preview.isResetTable = false;
          this.screens.preview.isLoading = false;
          this.isSearching.emit(false);
          this.mapService.setActiveGeoSearchButton(false);

          const check = {checked:this.isAllSelected};
          this.selectAllMaster(check,this.SCREENS.PREVIEW);
        },
        error=>{
          this.screens[type].isLoading =false;
        });
      },
      syncDataSource: () => {
        const deletedScreensMap = this.deletedScreens.reduce((storage, screen) => {
          storage[screen.screenId] = true;
          return storage;
        }, {});

        this.screens.preview.dataSource.data = [
          ...this.addedScreens,
          ...this.screens.preview.data.filter(screen => !deletedScreensMap[screen.screenId])
        ];
        this.previewScreensChange.emit(this.screens.preview.dataSource.data);
      },
      updateMarkerIcons: () => {
        this.mapService.setMarkerIconMap({
          ...this.screens.preview.data.reduce((storage, screen) => {
            if(screen.venueType && screen.venueType.length){
              if(screen.venueType[0].id.length % 2 === 0){
                storage[screen.id] = `/assets/icons/ico-marker-${this.venueIconsMap[screen.venueType[0].id.substring(0, 2)]}-added.svg`;
              }else{
                storage[screen.id] = `/assets/icons/ico-marker-${this.venueIconsMap[screen.venueType[0].id[0]]}-added.svg`;
              }
            }else{
              storage[screen.id] = '/assets/icons/ico-marker-added-new.svg';
            }
            return storage;
          }, {}),
          ...this.addedScreens.reduce((storage, screen) => {
            if (screen.venueType && screen.venueType.length) {
              if(screen.venueType[0].id.length % 2 === 0){
                storage[screen.id] = `/assets/icons/ico-marker-${this.venueIconsMap[screen.venueType[0].id.substring(0, 2)]}-added.svg`;
              }else{
                storage[screen.id] = `/assets/icons/ico-marker-${this.venueIconsMap[screen.venueType[0].id[0]]}-added.svg`;
              }
            }else{
              storage[screen.id] = '/assets/icons/ico-marker-added-new.svg';
            }
            return storage;
          }, {}),
          ...this.screens.preview.selectedItems.reduce((storage, screen) => {
            if(screen.venueType && screen.venueType.length){
              if(screen.venueType[0].id.length % 2 === 0){
                storage[screen.id] = `/assets/icons/ico-marker-${this.venueIconsMap[screen.venueType[0].id.substring(0, 2)]}-selected.svg`;
              }else{
                storage[screen.id] = `/assets/icons/ico-marker-${this.venueIconsMap[screen.venueType[0].id[0]]}-selected.svg`;
              }
            }else{
              storage[screen.id] = '/assets/icons/ico-marker-selected-new.svg';
            }
            return storage;
          }, {})
        });
        this.mapService.setMarkerTypeMap({
          ...this.screens.preview.data.reduce((storage, screen) => {
            storage[screen.id] = 'added';
            return storage;
          }, {}),
          ...this.addedScreens.reduce((storage, screen) => {
            storage[screen.id] = 'added';
            return storage;
          }, {}),
          ...this.screens.preview.selectedItems.reduce((storage, screen) => {
            storage[screen.id] = 'added';
            return storage;
          }, {})
        });
      },
      addScreenFromMap: (addedScreen) => {
          this.addScreens([addedScreen], this.SCREENS.ADD);
      },
      removeScreenFromMap: (removedScreen) => {
        this.removeScreens([removedScreen], this.SCREENS.PREVIEW);
      }
    },
    [this.SCREENS.ADD]: {
      ...this.getTableConfig(),
      apiCall: (refresh?: boolean, updateFilters?: boolean, pageStrategy = false) => {
        this.screens.add.subscription = this.screensApi.getAvailableScreens(
          this.filterScreenObject, this.screens.add.currentPage, this.screens.add.pageSize, this.screens.add.sort
        ).subscribe(result => {
          this.screens.add.data = (refresh || pageStrategy)
            ? result.screens.content
            : [...this.screens.add.data, ...result.screens.content];

          this.screens.add.totalElements = result.screens.totalElements;

          if(this.filterScreenObject.placeIq && refresh){
            this.screens.add.data.forEach(screen=>screen.fromPlaceIQ = true);
          }
          // SET DMA AGGREGATE DATA
          if(this.dmaIds.length < 1) {
            this.dmaService.setDmaAggData(result?.tags['dma_agg_result']?.values);
          }

          //SET PLACE IQ AGGREGATE DATA
          if(!this.filterScreenObject.placeIq){
            this.hasAudience = result.tags['place_iq.key_agg_result']?.values.length > 0;
            this.audienceStateService.setAudienceAggData(
              result.tags['place_iq.key_agg_result']?.values
            );
          }
          this.screens.add.syncDataSource();
          this.mapService.setData(this.screens.add.dataSource.data);
          this.screens.add.updateMarkerIcons();
          this.screens.add.isResetTable = false;
          this.screens.add.isLoading = false;
          this.isSearching.emit(false);
          this.isActiveAddScreensSearch.emit(true);
          this.mapService.setActiveGeoSearchButton(true);
        });

        if (!this.screensApi.getScreenFilters || !updateFilters) {
          return;
        }
        this.getScreenFilters();
      },
      syncDataSource: () => {
        this.screens.add.dataSource.data = [
          ...this.screens.add.data
        ];

        this.selectedElements.next({
          action: 'RESET',
          items: []
        });
        this.disablingElements.next({
          action: 'RESET',
          items: []
        });

        const { deletedScreenIds, deletedScreensMap } = this.deletedScreens.reduce((storage, screen) => {
          storage.deletedScreenIds.push(screen.screenId);
          storage.deletedScreensMap[screen.screenId] = true;
          return storage;
        }, { deletedScreenIds: [], deletedScreensMap: {} });

        this.selectedElements.next({
          action: 'DESELECT',
          items: deletedScreenIds
        });
        this.disablingElements.next({
          action: 'ENABLE',
          items: deletedScreenIds
        });

        const addedScreensIds = this.screens.add.data.reduce((storage, screen) => {
          if (screen.hasRelation && !deletedScreensMap[screen.id]) {
            storage.push(screen.id);
          }
          return storage;
        }, this.addedScreens.map(screen => screen.id));
        const selectedScreenIds = [
          ...addedScreensIds,
          ...this.screens[this.SCREENS.ADD].selectedItems.map(screen => screen.id)
        ];

        this.selectedElements.next({
          action: 'SELECT',
          items: selectedScreenIds
        });
        this.disablingElements.next({
          action: 'DISABLE',
          items: addedScreensIds
        });
      },
      updateMarkerIcons: () => {
        const deletedScreensMap = this.deletedScreens.reduce((storage, screen) => {
          storage[screen.screenId] = true;
          return storage;
        }, {});
        this.mapService.setMarkerIconMap({
          ...this.screens.add.data.reduce((storage, screen) => {
            if (screen.hasRelation && !deletedScreensMap[screen.id]) { //if screen saved
              if (screen.venueType && screen.venueType.length) {// check for venue type to add icon on the marker
                if(screen.venueType[0].id.length % 2 === 0){// if venue type is 10 or 11
                  storage[screen.id] = `/assets/icons/ico-marker-${this.venueIconsMap[screen.venueType[0].id.substring(0, 2)]}-added.svg`;
                }else{ // if venue type between 1-9
                  storage[screen.id] = `/assets/icons/ico-marker-${this.venueIconsMap[screen.venueType[0].id[0]]}-added.svg`;
                }
              }else{ // no venue type
                storage[screen.id] = '/assets/icons/ico-marker-added-new.svg';
              }
            }else{ // if screen is not saved
              if (screen.venueType && screen.venueType.length) {
                if(screen.venueType[0].id.length % 2 === 0){
                  storage[screen.id] = `/assets/icons/ico-marker-${this.venueIconsMap[screen.venueType[0].id.substring(0, 2)]}.svg`;
                }else{
                  storage[screen.id] = `/assets/icons/ico-marker-${this.venueIconsMap[screen.venueType[0].id[0]]}.svg`;
                }
              }else{
                storage[screen.id] = `/assets/icons/ico-marker-new.svg`;
              }
            }
            return storage;
          }, {}),
          ...this.addedScreens.reduce((storage, screen) => {
            if (screen.venueType && screen.venueType.length) {
              if(screen.venueType[0].id.length % 2 === 0){
                storage[screen.id] = `/assets/icons/ico-marker-${this.venueIconsMap[screen.venueType[0].id.substring(0, 2)]}-added.svg`;
              }else{
                storage[screen.id] = `/assets/icons/ico-marker-${this.venueIconsMap[screen.venueType[0].id[0]]}-added.svg`;
              }
            }else{
              storage[screen.id] = '/assets/icons/ico-marker-added-new.svg';
            }
            return storage;
          }, {}),
          ...this.screens.add.selectedItems.reduce((storage, screen) => {
            if (screen.venueType && screen.venueType.length) {
              if(screen.venueType[0].id.length % 2 === 0){
                storage[screen.id] = `/assets/icons/ico-marker-${this.venueIconsMap[screen.venueType[0].id.substring(0, 2)]}-selected.svg`;
              }else{
                storage[screen.id] = `/assets/icons/ico-marker-${this.venueIconsMap[screen.venueType[0].id[0]]}-selected.svg`;
              }
            }else{
              storage[screen.id] = '/assets/icons/ico-marker-selected-new.svg';
            }
            return storage;
          }, {}),

        });
        this.mapService.setMarkerTypeMap({
          ...this.screens.add.data.reduce((storage, screen) => {
            if (screen.hasRelation && !deletedScreensMap[screen.id]) {
              storage[screen.id] = 'added';
            }
            return storage;
          }, {}),
          ...this.addedScreens.reduce((storage, screen) => {
            storage[screen.id] = 'added';
            return storage;
          }, {})
        });
      },
      addScreenFromMap: (addedScreen) => {
        this.addScreens([addedScreen], this.SCREENS.ADD);
      },
      removeScreenFromMap: (removedScreen) => {
        if (removedScreen.hasRelation) {
          removedScreen = this.screens[this.SCREENS.PREVIEW].data.find(screen => screen.screenId === removedScreen.id);
        }
        if(this.currentTab === 0)
          this.removeScreens([removedScreen], this.SCREENS.PREVIEW);
        else if(this.currentTab === 1)
          this.removeScreens([removedScreen], this.SCREENS.ADD);
      }
    }
  };

  selectedElements: Subject<{ action: string, items: string[] }> = new Subject<{ action: string, items: string[] }>();
  disablingElements: Subject<{ action: string, items: string[] }> = new Subject<{ action: string, items: string[] }>();
  screenTagsSubscription: Subscription;

  activeScreenTags: ScreenTagView[];
  screenTagSearchField = new FormControl();

  filterScreenObject: FilterScreenObject;

  isLoadingTags: boolean;
  isPoiActive: boolean;
  isScreenPropertiesActive: boolean;

  addedScreens: ScreenView[] = [];
  deletedScreens: ScreenView[] = [];

  tagsPagination: ScreenTagsPagination;
  disableSearch: boolean = true;

  private _editable = false;
  filtersForm: FormGroup;
  isBulkAdding: boolean;
  bulkCount$: Observable<number>;
  bulkRequestLimit: number;
  isDownloadingCSV: boolean;
  hasAudience: boolean;
  disableScreenPropertiesButton: boolean;

  @Input()
  set editable(value: boolean) {
    this._editable = value;
    this.loadConfiguration(this.SCREENS.PREVIEW, value, this.getPreviewScreenClass);
  }

  get editable(): boolean {
    return this._editable;
  }

  @Input()
  isCreating: boolean;

  @Input()
  campaignName: string;

  @Input()
  screensApi: ScreensApi;

  @Input()
  enableSave?: boolean;

  @Input()
  hideSaveButton?: boolean;

  @Input()
  hideCancelButton?: boolean;

  @Input()
  hidePoi?: boolean;

  @Input()
  hideScreenProperties?: boolean;
  @Input()
  isPlatformTypeSSP?: boolean;
  @Input()
  searchAreaClicked: boolean;
  @Input()
  searchArea?: any;

  @Output()
  cancel = new EventEmitter<{action?:string}>();

  @Output()
  save: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  deletedScreensChange?: EventEmitter<ScreenView[]> = new EventEmitter<ScreenView[]>();

  @Output()
  addedScreensChange?: EventEmitter<ScreenView[]> = new EventEmitter<ScreenView[]>();

  @Output()
  previewScreensChange?: EventEmitter<ScreenView[]> = new EventEmitter<ScreenView[]>();

  @Output()
  indexTab: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  isActiveAddScreensSearch: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  isSearching: EventEmitter<boolean> = new EventEmitter<boolean>();

  expectedReach: number;
  isSearchingByArea: boolean;
  unsubscriber$ = new Subject<void>();
  isBulkSelect$ = null
  showDma = false;
  showDmaActive = false;
  dmaIds: string[] = [];

  constructor(
    private mapService: MapService,
    private dialogService: DialogService,
    private targetingFiltersService: TargetingFiltersService,
    private targetingService: TargetingsService,
    private bulkSelectService:BulkSelectService,
    private apiService: ApiService,
    private dialog: MatDialog,
    private audienceStateService: AudienceStateService,
    private route: ActivatedRoute,
    private dmaService: DmaStateService,
  ) {
    this.isBulkSelect$ = this.bulkSelectService.isTotalSelected$;
    this.bulkCount$= this.bulkSelectService.totalCount$;
    this.bulkRequestLimit = BULK_REQUEST_LIMIT
  }
  ngOnDestroy(): void {
    this.onReset();
    this.mapService.setAddScreenFromMap(null);
    this.mapService.setRemoveScreenFromMap(null);
    this.mapService.setAddScreensonVisibleMap([]);

    this.screensApi.setCountry?.(null);

    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchArea && changes.searchArea.currentValue) {
      this.areaSearch(changes.searchArea.currentValue);
    }
  }

  ngOnInit() {
    this.isActiveAddScreensSearch.emit(false);
    this.filtersForm = new FormGroup({
      screenTagSearchField: new FormControl([])
    });
    if (this.enableSave === undefined) {
      this.enableSave = true;
    }
    this.loadConfiguration(this.SCREENS.PREVIEW, this.editable, this.getPreviewScreenClass);
    this.loadConfiguration(this.SCREENS.ADD, true);
    this.deletedScreens = [];
    this.addedScreens = [];
    this.getScreens(this.SCREENS.PREVIEW, true);

    this.mapService.getData().pipe(takeUntil(this.unsubscriber$)).subscribe(res => {

      if (res.length > 0) {
        if (this.editable) {
          this.showDma = res.some( screenInfo => screenInfo.address.country === 'United States' );
        }
        if (this.currentTab === this.SCREENS_TAB) {
          this.screens[this.SCREENS.ADD].data = res;
          this.screens.add.syncDataSource();
        }
      } else {
        const searchData = this.filtersForm.get('screenTagSearchField').value;
        if(searchData[0]?.tag === 'Place') {
          this.mapService.syncSearchField(searchData);
        }
      }

      this.isSearching.emit(false);
    });
    this.targetingFiltersService.isPoiActive$.subscribe(isActive => {
      this.isPoiActive = isActive;
    });

    this.targetingFiltersService.isScreenPropertiesActive$.subscribe(isActive => {
      this.isScreenPropertiesActive = isActive;
    });

    this.targetingFiltersService.screenPropertiesFilters$.subscribe((filters: { limits: { floorPrice: { min: number, max: number }, audiencePerWeek: { min: number, max: number } }, tags: Array<{ name: string; properties: string[] }> }) => {
      if (!filters) {
        return;
      }

      const filterTagsMapByName = (filters?.tags || []).reduce(
        (obj, item) => {
          if (!item) {
            return obj;
          }
          obj[item.name] = {...item};
          return obj;
        },
        {}
      );
      const tagsMapByName = (this.activeScreenTags || []).reduce(
        (obj, item) => {
          if (!item) {
            return obj;
          }
          const tag = item.tag;
          if (!obj[tag]) {
            obj[tag] = {
              name: tag,
              properties: []
            };
          }
          if (obj[tag].properties === '') {
            obj[tag].properties = []
          }
          if (Array.isArray(obj[tag].properties)) {
            obj[tag].properties.push(item.id);
          }
          return obj;
        },
        filterTagsMapByName
      );
      if (!this.filterScreenObject) {
        this.filterScreenObject = {
          tags: [],
          pois: null
        }
      }


      Object.keys(tagsMapByName).forEach(tagName => {
        const index = this.filterScreenObject?.tags.findIndex(tag => tag.name === tagName);
        if (index === -1) {
          this.filterScreenObject.tags.push(tagsMapByName[tagName]);
        } else {
          this.filterScreenObject.tags[index] = tagsMapByName[tagName]
        }
      });
      this.filterScreenObject.limits = filters.limits;
      this.getScreens(this.SCREENS.ADD, true);
    });

    this.targetingFiltersService.poiFilters$.subscribe((filters: { type: string, pois: any, radius: number }) => {
      if (!filters) {
        return;
      }


      this.targetingFiltersService.resetTagsPages();
      this.filterScreenObject.pois = { points: filters.pois, radius: filters.radius };
      this.getScreens(this.SCREENS.ADD, true, true);
    });

    this.targetingFiltersService.getTagsNextPage$.subscribe(() => {
      this.getScreenFilters();
    });

    this.mapService.addScreenFromMap$.subscribe(screen => {
      if(screen){
        this.screens[this.SCREENS_MAP[this.currentTab]].addScreenFromMap(screen);
      }
    });

    this.mapService.removeScreenFromMap$.subscribe(screen => {
      if(screen){
      this.screens[this.SCREENS_MAP[this.currentTab]].removeScreenFromMap(screen);
      }
    });

    this.bulkSelectService.isTotalSelected$.pipe(takeUntil(this.unsubscriber$)).subscribe(
      isTotalSelected=>{
        if(isTotalSelected){
          const queryObj = formatScreenFilterObject(this.filterScreenObject);
          this.bulkSelectService.setQuery(queryObj);
        }
        else this.bulkSelectService.setQuery(null)
      }
    )
  }

  private loadConfiguration(type: string, hasCheckbox = false, getRowClass?: (row: any) => string) {
    const columnCustomizers = this.loadColumnCustomizers(hasCheckbox);
    this.screens[type].tableCustomizer = new TableCustomizer(
      'screens',
      columnCustomizers,
      false,
      null,
      null,
      getRowClass
    );
  }

  private loadColumnCustomizers(hasCheckbox: boolean): Array<ColumnCustomizer> {
    let columns: TableColumn[] = [
      {
        name: 'name',
        heading: 'Screen Name',
        valuePath: ['name'],
        type: hasCheckbox ? ColumnType.CHECKBOX_GROUP : ColumnType.STRING
      },
      {
        name: 'publisher_name',
        heading: 'Publisher Name',
        valuePath: ['publisher', 'name']
      },
      {
        name: 'ssp_name',
        heading: 'SSP',
        valuePath: ['ssp', 'name']
      },
      {
        name: 'address_country',
        heading: 'Country',
        valuePath: ['address', 'country']
      },
      {
        name: 'weekly_audience',
        heading: 'Audience',
        valuePath: ['audiencePerWeek'],
        type: ColumnType.ROUND_NUMBER,
        toolTipMessage: `“Audience size” is Audience size be achieved per week.`,
        toolTipIcon: 'help_outline'
      },
      {
        name: 'floor_price',

        heading: 'Floorprice',
        valuePath: ['convertedPrice', 'floorPrice'],
        type: ColumnType.MONEY,
        toolTipMessage: `“Floorprice” is on CPM, it costs to reach 1000 in that audience of that screen.`,
        toolTipIcon: 'help_outline'
      }
    ];
    if (this.isPlatformTypeSSP) {
      columns.some((column, index) => {
        if (!!column && column.name === 'floor_price') {
          columns[index].valuePath = ['floorPrice'];
          return true;
        }
      });
    }

    return columns.map(column => new ColumnCustomizer(column));
  }

  private getTableConfig() {
    return {
      tableCustomizer: null,
      data: [],
      dataSource: new MatTableDataSource<ScreenView>(),
      currentPage: 1,
      pageSize: PAGE_SIZE,
      totalElements: 0,
      sort: ['name,asc'],
      isLoading: false,
      subscription: null,
      selectedItems: [],
      isResetTable: false
    };
  }

  onSearchByTags() {
    this.isSearchingByArea = false;
    this.screens.add.currentPage = 1;
    if (!this.filtersForm || !this.filtersForm.get('screenTagSearchField')) {
      return;
    }
    this.bulkSelectService.resetBulkService();
    this.activeScreenTags = this.filtersForm.get('screenTagSearchField').value;
    if (!this.activeScreenTags || !this.activeScreenTags.length) {
      this.onFiltersReset();
      this.onAddScreensReset();
      return;
    }

    this.onFiltersReset();
    this.targetingFiltersService.resetTagsPages();

    let placeBbox: BboxObject[] = null;
    const checkIfPlaceSelected = this.activeScreenTags.some(item => item?.tag === 'Place');
    if (checkIfPlaceSelected) {
      placeBbox = [];
      this.activeScreenTags.forEach(item => {
        if (item?.tag === 'Place') {
          placeBbox.push(item?.area);
        }
      });
    }

    const tagsMapByName = this.activeScreenTags.reduce((obj, item) => {
      if (!item) {
        return obj;
      }
      if (item.tag === 'Place') {
        const countryTag = 'country';
        if (!obj[countryTag]) {
          obj[countryTag] = {
            name: countryTag,
            properties: []
          }
          obj[countryTag].properties.push(item.country);
        }
        return obj;
      }
      const tag = item.tag;
      if (!obj[tag]) {
        obj[tag] = {
          name: tag,
          properties: []
        };
      }
      if (obj[tag].properties === '') {
        obj[tag].properties = []
      }
      if (Array.isArray(obj[tag].properties)) {
        obj[tag].properties.push(item.id);
      }
      return obj;
    }, {});
    if (!this.filterScreenObject) {
      this.filterScreenObject = {
        tags: [],
        pois: null
      }
    }


    Object.keys(tagsMapByName).forEach(tagName => {
      const index = this.filterScreenObject?.tags.findIndex(tag => tag.name === tagName);
      if (index === -1) {
        this.filterScreenObject.tags.push(tagsMapByName[tagName]);
      } else {
        this.filterScreenObject.tags[index] = tagsMapByName[tagName]
      }
    });
    this.filterScreenObject.bboxes = placeBbox;

    this.audienceStateService.resetAudienceData();

    this.getScreens(this.SCREENS.ADD, true, true);
  }

  areaSearch($event, page = 1, pageSize = 50){
    this.isSearching.emit(true);
    this.screens.add.isLoading = true;
    this.isSearchingByArea = true;
    this.activeScreenTags = [];
    this.filtersForm.reset();
    this.filtersForm.patchValue({screenTagSearchField: []});
    this.screensApi.setCountry?.(null);
    this.targetingService.getScreensByArea($event, page, pageSize).subscribe( data => {
      this.mapService.setData(data.screens.content);
      this.mapService.setAddScreensonVisibleMap(data.screens.content);
      this.screens.add.currentPage = page;
      this.screens.add.pageSize = pageSize;
      this.screens.add.totalElements = data.screens.totalElements;
      this.screens.add.isLoading = false;
      this.isSearching.emit(false);
    },
    error=>{
      this.screens.add.isLoading=false;
    })
  }

  getScreens(type: string, refresh?: boolean, updateFilters?: boolean, pageStrategy = false) {

    if (this.screens[type].isLoading) {
      this.screens[type].subscription.unsubscribe();
    }

    this.screens[type].isLoading = true;
    this.isSearching.emit(true);

    if (refresh) {
      this.screens[type].currentPage = 1;
      this.screens[type].isResetTable = true;
    }
    this.screens[type].apiCall(refresh, updateFilters, pageStrategy,type);
  }

  getScreenFilters() {
    this.targetingFiltersService.tagsPagination$
      .pipe(first())
      .subscribe(tagsPagination => {
        this.tagsPagination = tagsPagination;
      });

      const screenTags: ScreenTagView[] = JSON.parse(JSON.stringify(this.activeScreenTags));

      this.filterScreenObject.tags.forEach(tagItem => {
        const screenTag: ScreenTagView = {
          tag: tagItem.name,
          id: tagItem.properties
        }
        screenTags.push(screenTag);
      });




    this.screensApi.getScreenFilters({
      screenTags: screenTags,
      pois: this.filterScreenObject.pois,
      pagination: this.tagsPagination,
      bboxes: this.filterScreenObject.bboxes,
      placeIq: this.filterScreenObject.placeIq
    }).subscribe(result => {
      this.targetingFiltersService.setLimits(result.limits);
      this.targetingFiltersService.setTags(result.tags);

      this.checkScreenPropertiesButton(result?.tags);
    });
  }

  onTablePaging($event, type:string) {
    if (type === 'add') {
      if (!this.isSearchingByArea) {
        this.screens[type].currentPage = $event?.pageNumber || 1;
        this.screens[type].pageSize = $event?.pageSize || this.screens[type].pageSize;
        this.getScreens(type, false, false, true);
      } else {
        this.areaSearch(this.searchArea, $event?.pageNumber || 1, $event?.pageSize);
      }
    } else {
      this.screens[type].currentPage = $event?.pageNumber || 1;
      this.screens[type].pageSize = $event?.pageSize || this.screens[type].pageSize;
      this.getScreens(type, false, false, true);
    }

  }

  onTableScroll(type: string) {
    if (this.screens[type].isLoading) {
      return;
    }

    this.screens[type].currentPage += 1;
    this.getScreens(type);
  }

  onSortChange($event: any, type: string) {
    this.screens[type].sort = [`${$event.name},${$event.direction}`];
    this.getScreens(type, true);
  }

  triggerAction($event: { element: ScreenView; action: string }, type: string) {
    const screen = $event.element;
    if ($event.action === 'SELECT') {
      this.screens[type].selectedItems.push(screen);
    } else if ($event.action === 'DESELECT') {
      this.screens[type].selectedItems = this.screens[type].selectedItems.filter(item => item.id !== screen.id);
    }
    this.screens[type].updateMarkerIcons();
  }

  selectAllMaster($event: { checked: boolean }, type: string) {
    this.isAllSelected=$event.checked;
    this.screens[type].selectedItems = this.isAllSelected ? this.screens[type].dataSource.data : [];
  }

  checkScreenPropertiesButton(tags: any[]): void {
    const hasVenueType = tags['venue_agg_result']?.values?.length > 0
    const hasPublisher = tags['publisher_agg_result']?.values?.length > 0
    const hasResolution = tags['resolution.name_agg_result']?.values?.length > 0

    if (!hasVenueType && !hasPublisher && !hasResolution) {
      this.disableScreenPropertiesButton = true;
    } else {
      this.disableScreenPropertiesButton = false
    }
  }

  addScreens(screens: ScreenView[], type: string) {
    const selectedScreenMap = screens.reduce((storage, screen) => {
      storage[screen.id] = true;
      return storage;
    }, {});

    this.screens[type].selectedItems = this.screens[type].selectedItems.filter(screen => !selectedScreenMap[screen.id]);
    this.addedScreens = [
      ...this.addedScreens,
      ...screens.map((screen) => ({ ...screen }))
    ];
    this.addedScreensChange.emit(this.addedScreens);

    this.screens[this.SCREENS.PREVIEW].syncDataSource();
    this.screens[this.SCREENS.ADD].syncDataSource();

    this.screens[type].updateMarkerIcons();
  }

  removeScreens(screens: ScreenView[], type: string) {
    const selectedScreenMap = screens.reduce((storage, screen) => {
      const id = screen.screenId || screen.id;
      storage[id] = true;
      return storage;
    }, {});

    this.screens[type].selectedItems = this.screens[type].selectedItems.filter(screen => !selectedScreenMap[screen.screenId || screen.id]);

    this.addedScreens = this.addedScreens.filter(screen => {
      if (selectedScreenMap[screen.id]) {
        selectedScreenMap[screen.id] = false;
        return false;
      }
      return true;
    });

    this.deletedScreens = [
      ...this.deletedScreens,
      ...screens.filter(screen => selectedScreenMap[screen.screenId])
    ];


    this.addedScreensChange.emit(this.addedScreens);
    this.deletedScreensChange.emit(this.deletedScreens);


    this.screens[this.SCREENS.PREVIEW].syncDataSource();
    this.screens[this.SCREENS.ADD].syncDataSource();

    this.mapService.setData(this.screens[type].dataSource.data);
    //this.screens[type].totalElements = this.screens[type].dataSource.data.length;
    this.screens[type].updateMarkerIcons();
  }

  add() {
    this.addScreens(this.screens[this.SCREENS.ADD].selectedItems, this.SCREENS.ADD);
  }

  delete() {
    const cache_previous_datasource_data = JSON.stringify(this.screens[this.SCREENS.PREVIEW].dataSource.data);
    this.removeScreens(this.screens[this.SCREENS.PREVIEW].selectedItems, this.SCREENS.PREVIEW);

    if (this.deletedScreens.length > 0) {
      let deleteCount = 0;
      const uniqueScreens = new Map();
      for(let screen_deleted of this.deletedScreens) {
        const info = `${screen_deleted.resolution.width}_${screen_deleted.resolution.height}_${screen_deleted.constraintFakeId}`;
        if(screen_deleted.hasCreatives && !uniqueScreens.has(info)) {
          deleteCount = deleteCount + 1;
          uniqueScreens.set(info, `${screen_deleted.resolution.width}X${screen_deleted.resolution.height}`);
        }
      }

      for(let prevScreen of this.screens[this.SCREENS.PREVIEW].dataSource.data) {
        const info = `${prevScreen.resolution.width}_${prevScreen.resolution.height}_${prevScreen.constraintFakeId}`;
        if(prevScreen.hasCreatives && uniqueScreens.has(info)) {
          deleteCount = deleteCount - 1;
          uniqueScreens.delete(info);
        }
      }
      if (deleteCount > 0) {
        this.openCancelConfirmDialog(
          `
          If you remove this screen(s) <b>${[...uniqueScreens.values()].join(', ')}</b> from your campaign,
          the creatives associated only with this screen(s) will also be removed from this campaign,
          but they will be saved in Creative Management, so you may use them again. Are you sure,
          that you want to remove this screen from the current campaign?
          `,
          true
        ).afterClosed().subscribe(
          resp => {
            if (!resp) {
              this.screens[this.SCREENS.PREVIEW].dataSource.data = JSON.parse(cache_previous_datasource_data)
              this.deletedScreens = [];
            }
          }
        );
      }

    }
    if(this.screens.preview.totalElements + this.addedScreens.length - this.deletedScreens.length > 0){
      if (this.deletedScreens.length >= this.screens[this.SCREENS.PREVIEW].pageSize) {
        if ((this.deletedScreens.length % this.screens[this.SCREENS.PREVIEW].pageSize) === 0) {
          this.screens[this.SCREENS.PREVIEW].currentPage += 1;
        }
      } else {
        this.screens[this.SCREENS.PREVIEW].currentPage = 1;
      }
      this.getScreens(this.SCREENS.PREVIEW, false, false, true);
    }
  }

  canDeactivate(): boolean {
    return !this.addedScreens.length && !this.deletedScreens.length;
  }

  afterSave() {
    this.onReset();
    this.getScreens(this.SCREENS.PREVIEW, true);
  }

  onCancel() {
    if (this.canDeactivate()) {
      this.onReset();
      this.cancel.emit({action:"no change"});
      this.getScreens(this.SCREENS.PREVIEW, true);
    } else {
      this.dialogService.alert({
        type: 'warning',
        message: 'screensTab.dialog.cancel.message',
        submitButtonText: 'screensTab.dialog.cancel.submitButton',
        cancelButtonText: 'screensTab.dialog.cancel.cancelButton'
      }).afterClosed().subscribe(res => {
        if (!res) return;
        this.onReset();
        this.cancel.emit({action:"changed"});
        this.getScreens(this.SCREENS.PREVIEW, true);
      });
    }
  }

  openCancelConfirmDialog(message: string, isHtml: boolean = false) {
    return this.dialogService.alert({
      type: 'warning',
      title: 'dialog.alert.title.sure',
      message,
      isHtml,
      submitButtonText: 'dialog.alert.submitButtonText.yes',
      cancelButtonText: 'dialog.alert.cancelButtonText.no',
    })
  }

  openErrorDialog(message: string): void {
    this.dialogService.alert({
      type: 'error',
      message
    })
  }

  async onSave() {
    const allScreenDeleted = ((this.screens.preview.totalElements + this.addedScreens.length - this.deletedScreens.length) - this.screens.preview.selectedItems.length) == 0;
    if(allScreenDeleted) {
      return this.openErrorDialog(`At least one screen must be added`);
    }

    const hasPlaceIqsScreens = this.addedScreens.some(screen=>screen.fromPlaceIQ);
    if(hasPlaceIqsScreens){
      const canProceed = await this.openPlaceIQWarningDialog().afterClosed().toPromise();
      if(!canProceed) return;
    }


    const deletedScreenMap = this.deletedScreens.reduce((storage, screen) => {
      storage[screen.screenId] = true;
      return storage;
    }, {});

    const addedScreens: IScreenBatchBody[] = this.addedScreens.reduce((storage, screen) => {
      if (!deletedScreenMap[screen.id]) {
        storage.push({
          isPlaceiqDemography: screen.fromPlaceIQ || false,
          isPlaceiqLocation: screen.fromPlaceIQ || false,
          screenId: screen.id
        });
      } else {
        deletedScreenMap[screen.id] = false;
      }
      return storage;
    }, []);

    const deletedScreensIds = this.deletedScreens.reduce((storage, screen) => {
      if (deletedScreenMap[screen.screenId]) {
        storage.push(screen.id);
      }
      return storage;
    }, []);

    const postCall = addedScreens.length
      ? this.screensApi.addScreens(addedScreens)
      : of(null);
    const deleteCall = deletedScreensIds.length
      ? this.screensApi.deleteScreens(deletedScreensIds)
      : of(null);

    forkJoin([deleteCall, postCall])
      .subscribe(
        () => {
          this.onReset();
          this.save.emit();

          this.getScreens(this.SCREENS.PREVIEW, true);
        }
      );
  }


  openPlaceIQWarningDialog() {
    return this.dialogService.alert({
      type: 'warning',
      title: 'Warning',
      message: "Selecting screens with this data partner will incur an additional cost. Continue?",
      submitButtonText: 'dialog.alert.submitButtonText.yes',
      cancelButtonText: 'dialog.alert.cancelButtonText.no',
    })
  }

  onReset() {
    this.activeScreenTags = [];
    this.filtersForm.reset();
    this.filtersForm.patchValue({screenTagSearchField: []});
    this.screensApi.setCountry?.(null);

    this.onFiltersReset();
    this.onAddScreensReset();

    this.currentTab = this.PREVIEW_SCREENS_TAB;
    this.mapService.setData(this.screens[this.SCREENS.PREVIEW].dataSource.data);
    this.mapService.setAddScreensonVisibleMap([]);

    this.addedScreens = [];
    this.deletedScreens = [];
    this.addedScreensChange.emit(this.addedScreens);
    this.deletedScreensChange.emit(this.deletedScreens);
  }

  onFiltersReset() {
    this.targetingFiltersService.setPoiStatus(false);
    this.targetingFiltersService.setScreenPropertiesStatus(false);
    this.mapService.setPoisMapData([]);
    this.screens[this.SCREENS.ADD].selectedItems = [];
    this.dmaIds = [];
    this.filterScreenObject = null;
  }

  onAddScreensReset() {
    this.screens[this.SCREENS.ADD] = {
      ...this.screens[this.SCREENS.ADD],
      currentPage: 1,
      pageSize: PAGE_SIZE,
      totalElements: 0,
      isLoading: false,
      selectedItems: []
    };
    this.screens[this.SCREENS.ADD].dataSource.data = [];
    this.mapService.setData([]);
  }

  changeTab($event) {
    this.currentTab = $event.index;
    this.indexTab.emit($event.index);

    if ($event.index === this.PREVIEW_SCREENS_TAB) {
      this.isActiveAddScreensSearch.emit(false);
      this.screens[this.SCREENS.PREVIEW].updateMarkerIcons();
      this.mapService.setData(this.screens[this.SCREENS.PREVIEW].dataSource.data);
    } else if ($event.index === this.SCREENS_TAB && this.screens[this.SCREENS.ADD].dataSource.data.length > 0) {
      this.screens[this.SCREENS.ADD].updateMarkerIcons();
      this.mapService.setData(this.screens[this.SCREENS.ADD].dataSource.data);
    }
  }

  openPoi() {
    this.targetingFiltersService.togglePoi(true);
  }

  openScreenProperties() {
    this.targetingFiltersService.toggleScreenProperties(true);
  }

  getPreviewScreenClass(row: ScreenView) {
    return !row.screenId ? 'screens-tab__preview-screen' : '';
  }

  checkAllowSearch($event): void {
    this.disableSearch = !$event;
  }

  addBulkScreens():void{
    this.isBulkAdding = true;
    const bulkQuery = this.bulkSelectService.getQueryCurrentValue();
    this.screensApi.addBulkScreens(bulkQuery).subscribe(
      result=>{
        this.isBulkAdding = false;
        this.bulkSelectService.resetBulkService();
        this.onReset();
        this.save.emit();

        this.getScreens(this.SCREENS.PREVIEW, true);
      },
      error=>{
        this.isBulkAdding = false;
      }
    )
  }

  getDma(): void {
    this.dialog.open(DmaDialogComponent, {
      panelClass: 'customize',
      data: {
        dmaId: this.dmaIds
      }
    }).afterClosed()
    .subscribe(
      resp => {
        if (resp && resp.dmaId) {
          this.showDmaActive = true;
          this.dmaIds = resp.dmaId;
          const tag = {
            name: 'dmaId',
            properties: this.dmaIds
          }
          this.filterScreenObject.tags.push(tag);
          this.getScreens(this.SCREENS.ADD, true, true);
        }
        if(this.dmaIds.length > 0 && resp?.reset) {
          if (this.filterScreenObject.tags.length > 0) {
            this.filterScreenObject.tags = this.filterScreenObject.tags.filter(tag => tag.name !== 'dmaId');
          }
          this.dmaIds = [];
          this.getScreens(this.SCREENS.ADD, true, true);
        }
      }
    );
  }

  downloadCSV(){
    this.isDownloadingCSV = true;
    this.screensApi.getScreensCSV()
    .pipe(
      takeUntil(this.unsubscriber$)
    )
    .subscribe(
      result=>{
        this.isDownloadingCSV = false;
        this.screensApi.openDownloadPage(result.screenCsvId);
      },
      error=>{
        this.isDownloadingCSV = false;
      }
    )
  }

  openAudience(){
    const campaignId = this.route.snapshot.params['campaignId'];
    const lineItemId = this.route.snapshot.params['lineItemId'];
    this.dialog.open(AudienceComponent, {
      panelClass: 'audience-modal',
      data: {
        filterObj: this.filterScreenObject,
        campaignId,
        lineItemId
      },
      height: '100vh',
      width: '100%',
      maxWidth: '680px',
      position: {
        right: '0',
      },
      autoFocus: false,
    }).afterClosed().subscribe(
      value=>{
        if(value){
          this.filterScreenObject.placeIq = value;
          this.getScreens(this.SCREENS.ADD, true, true);
        }
      }
    )
  }
}
