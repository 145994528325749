<div class="supported_format">
  <div class="supported_format_wrapper" >
    <h4>Support Formats</h4>
    <section class="supported_format__type">
      <img
        src="../../../assets/icons/ico-image.svg"
      >
      <span>gif, png, jpeg</span>
    </section>
    <section class="supported_format__type supported_format__type--space">
      <img class="video-icon"
        src="../../../assets/icons/ico-video.svg"
      >
      <span>
        x-msvideo, x-flv, mpeg, quicktime, mp4, webm
      </span>
    </section>
    <p class="max-msg">
      Maximum size for the uploaded file should not be more than 128MB.
    </p>
  </div>
</div>
