/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { AgencyView } from './agencyView';
import { InstanceView } from './instanceView';
import { AdvertiserView } from './advertiserView';

export interface UserView {
    advertisers?: Array<AdvertiserView>
    agencies?: Array<AgencyView>;
    dspRoles?: Array<UserView.RolesEnum>;
    roles: UserViewRole[];
    email?: string;
    firstName?: string;
    id?: string;
    instances?: Array<InstanceView>;
    lastName?: string;
    oohRoles?: Array<UserView.RolesEnum>;
    sspRoles?: Array<UserView.RolesEnum>;
    isSystemAdmin?: boolean;
    isNamespaceAdmin?: boolean;
    isArchived?: boolean;
    phone?: string;
    companyName?: string;
}

interface UserViewRole {
    dspRole: string
    sspRole: string
    instance: { id: string, name: string }
    agencies: { id: string, name: string }
    advertisers: { id: string, name: string }
}

export namespace UserView {
    export type DspRolesEnum = 'INSTANCE_ADMIN' | 'ADMIN' | 'CAMPAIGN_MANAGER' | 'ADVERTISER' | 'SMB_MANAGER' | 'WHITELABEL_ADMIN';
    export const DspRolesEnum = {
        INSTANCEADMIN: 'INSTANCE_ADMIN' as DspRolesEnum,
        ADMIN: 'ADMIN' as DspRolesEnum,
        CAMPAIGNMANAGER: 'CAMPAIGN_MANAGER' as DspRolesEnum,
        SMBMANAGER: 'SMB_MANAGER' as DspRolesEnum,
        ADVERTISER: 'ADVERTISER' as DspRolesEnum,
        WHITELABELADMIN: 'WHITELABEL_ADMIN' as DspRolesEnum,
    };
    export type OohRolesEnum = 'INSTANCE_ADMIN' | 'ADMIN' | 'CAMPAIGN_MANAGER' | 'ADVERTISER';
    export const OohRolesEnum = {
        INSTANCEADMIN: 'INSTANCE_ADMIN' as OohRolesEnum,
        ADMIN: 'ADMIN' as OohRolesEnum,
        CAMPAIGNMANAGER: 'CAMPAIGN_MANAGER' as OohRolesEnum,
        ADVERTISER: 'ADVERTISER' as OohRolesEnum
    };
    export type SspRolesEnum = 'INSTANCE_ADMIN' | 'ADMIN' | 'CAMPAIGN_MANAGER' | 'BUYER';
    export const SspRolesEnum = {
        INSTANCEADMIN: 'INSTANCE_ADMIN' as SspRolesEnum,
        ADMIN: 'ADMIN' as SspRolesEnum,
        CAMPAIGNMANAGER: 'CAMPAIGN_MANAGER' as SspRolesEnum,
        BUYER: 'BUYER' as SspRolesEnum
    };
    export type RolesEnum = DspRolesEnum | OohRolesEnum | SspRolesEnum;
}
