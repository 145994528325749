import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "@dooh/api";

@Injectable({
  providedIn: 'root'
})
export class InventoryPerformanceService {

  constructor(private apiService: ApiService) { }

  public validateMaxBudget(calculationResponse:any, budget: any): number {
    if (Number(budget) > calculationResponse?.totalCost) {
      return  calculationResponse?.totalImpressions;
    } else {
      return (+budget * calculationResponse?.totalImpressions) / calculationResponse?.totalCost;
    }
  }

  public getScreenPerformance(screens: []): Observable<any> {
    return this.apiService.post(`performance/screens`,{screens})
  }
}


