<form>
  <h1>{{title}}</h1>
  <mat-form-field
    appearance="outline"
    class="form-field-common"
  >
    <input
      matInput
      name="name"
      [placeholder]="placeHolderMessage"
      maxlength="255"
      [(ngModel)]="value"
    />

  </mat-form-field>
  <footer>
    <button class="btn" mat-raised-button (click)="cancel()">
      {{ 'generic.cancel' | translate }}
    </button>
    <button class="btn" mat-raised-button color="primary" [disabled]="!value || value.length < 3" (click)="proceed()">
      Continue
    </button>
  </footer>

</form>
