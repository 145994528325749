<div class="tabs-common" *ngIf="editable">
  <mat-select class="poi-actions" disableOptionCentering id="filterSelect" placeholder="Filters " *ngIf="activeScreenTags && activeScreenTags.length">
      <mat-option
        #matOption
        *ngIf="showDma"
        class="filter-button filter-dma-button"
        [ngClass]="{'filter-button_active': showDmaActive}"
        (click)="getDma()">
      <mat-icon class="filter-icon">tv</mat-icon>
        DMA
      </mat-option>
    
      <mat-option
      #matOption
        *ngIf="!hidePoi"
        (click)="openPoi()"
        class="filter-button"
        [ngClass]="{'filter-button_active': isPoiActive}"
      >
        <mat-icon class="filter-svg-icon" svgIcon="place"></mat-icon>
        POI
      </mat-option>

      <mat-option
      #matOption
      *ngIf="!hidePoi && hasAudience"
        (click)="openAudience()"
        class="filter-button"
        [ngClass]="{'filter-button_active': isPoiActive}"
      >
        <mat-icon class="filter-svg-icon" svgIcon="audience"></mat-icon>
        Audience
      </mat-option>

      <mat-option
      #matOption
        *ngIf="!hideScreenProperties"
        [disabled]="disableScreenPropertiesButton"
        matTooltip="Properties are loaded based on the input entered in the search box"
        (click)="openScreenProperties()"
        class="filter-button"
        [ngClass]="{'filter-button_active': isScreenPropertiesActive}"
        [disabled]="disableScreenPropertiesButton || screens.add.isLoading"
      >
        <mat-icon class="filter-svg-icon" svgIcon="filter"></mat-icon>
        Screen Properties
      </mat-option>
</mat-select>

  <mat-tab-group class="screens-tab__tab-group" (selectedTabChange)="changeTab($event)"
                 [selectedIndex]="isCreating ? 1 : 0">
    <mat-tab
      class="screens-tab__tab"
      label="Preview Screens ({{screens.preview.totalElements + addedScreens.length - deletedScreens.length}})"
    >
    <div class="table-container">
        <dooh-generic-table
          class="child-table"
          [dataSource]="screens.preview.dataSource"
          [tableCustomizer]="screens.preview.tableCustomizer"
          [totalElements]="screens.preview.totalElements + addedScreens.length - deletedScreens.length"
          [isLoading]="screens.preview.isLoading"
          [isMatSortDisabled]="deletedScreens.length > 0"
          (item)="triggerAction($event, SCREENS.PREVIEW)"
          (selectAll)="selectAllMaster($event, SCREENS.PREVIEW)"
          (scrolling)="onTableScroll(SCREENS.PREVIEW)"
          (onPage)="onTablePaging($event, SCREENS.PREVIEW)"
          (header)="onSortChange($event, SCREENS.PREVIEW)"
        ></dooh-generic-table>
      </div>
      <div class="buttons-container">
        <button class="color-primary" mat-raised-button [disabled]="!screens.preview.selectedItems.length"
                (click)="delete()">Delete
        </button>
          <button *ngIf="!hideSaveButton" mat-raised-button color="primary" (click)="onSave()" [disabled]="!enableSave">
            Save
          </button>
      </div>
    </mat-tab>
    <mat-tab class="screens-tab__tab" label="Add Screens">
      <form class="screen-tabs-filters" [formGroup]="filtersForm" (submit)="onSearchByTags()">
        <dooh-autocomplete-field
          class="screen-search"
          formControlName="screenTagSearchField"
          searchBy="id"
          [label]="'screensTab.label.search' | translate"
          [service]="screensApi"
          [multi]="true"
          [searchDelay]="800"
          [searchMinLength]="3"
          (allowSearch)="checkAllowSearch($event)"
          [itemTemplate]="screenTabItemTemplate"
          matTooltip="Introduce 3 letters to search tags"
        ></dooh-autocomplete-field>
        <button mat-icon-button matPrefix type="submit" [disabled]="disableSearch"
        [ngClass]="{'disabled': disableSearch}" class="screen-tabs-filters__button">
          <mat-icon svgIcon="search"></mat-icon>
        </button>
      </form>
      <div class="table-container">
        <dooh-generic-table
          class="child-table child-table_add"
          [dataSource]="screens.add.dataSource"
          [tableCustomizer]="screens.add.tableCustomizer"
          [totalElements]="screens.add.totalElements"
          [isReset]="screens.add.isResetTable"
          [isLoading]="screens.add.isLoading"
          (scrolling)="onTableScroll(SCREENS.ADD)"
          (onPage)="onTablePaging($event, SCREENS.ADD)"
          (item)="triggerAction($event, SCREENS.ADD)"
          (selectAll)="selectAllMaster($event, SCREENS.ADD)"
          [selectedElements]="selectedElements.asObservable()"
          [disablingElements]="disablingElements.asObservable()"
          (header)="onSortChange($event, SCREENS.ADD)"
          canBulkSelect = "true"
        ></dooh-generic-table>
      </div>
      <div class="buttons-container">
        <ng-container *ngIf="!(isBulkSelect$ | async)">
          <button class="color-primary" *ngIf="!hideCancelButton" mat-raised-button
                  (click)="onCancel()">{{ 'generic.cancel' | translate }}</button>
          <button mat-raised-button color="primary" (click)="add()" [disabled]="!screens.add.selectedItems.length">Add
          </button>
        </ng-container>
        <ng-container *ngIf="isBulkSelect$ | async">
          <div *ngIf="{bulkCount: bulkCount$ | async} as val" class="tooltip-wrapper" matTooltip="{{'bulkActions.addLimitMessage' | translate}}" [matTooltipDisabled] = 'val.bulkCount < bulkRequestLimit' >
            <button mat-raised-button class="btn--bulk" color="primary" (click)="addBulkScreens()" [disabled]="isBulkAdding || val.bulkCount >= bulkRequestLimit">
              <span>{{ 'smb.campaignManagement.campaign.tab.screens.addBulkScreens' | translate }}</span>
              <mat-spinner *ngIf="isBulkAdding" diameter="20"></mat-spinner>
            </button>
          </div>
        </ng-container>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div *ngIf="!editable" class="audience-tag">
  <div class="header">
    <dooh-audience-reach [expectedReach]="expectedReach"></dooh-audience-reach>
    <button
    class="btn btn--inline color-gray"
    (click)="downloadCSV()"
    [disabled]="isDownloadingCSV"
    >
      <mat-icon svgIcon="download-icon"></mat-icon>
      <span>Download CSV</span>
      <mat-spinner class="" diameter="20" *ngIf="isDownloadingCSV"></mat-spinner>
    </button>
  </div>
  <dooh-generic-table
    class="child-table"
    [dataSource]="screens.preview.dataSource"
    [tableCustomizer]="screens.preview.tableCustomizer"
    [totalElements]="screens.preview.totalElements"
    [isLoading]="screens.preview.isLoading"
    (scrolling)="onTableScroll(SCREENS.PREVIEW)"
    (onPage)="onTablePaging($event, SCREENS.PREVIEW)"
    (header)="onSortChange($event, SCREENS.PREVIEW)"
  ></dooh-generic-table>
</div>
<ng-template #screenTabItemTemplate let-item="item">
  <span *ngIf="!!item" class="autocomplete-container">
    <span class="icon-autocomplete">
      <mat-icon svgIcon="map-pin" *ngIf="item?.place_id; else publisher"></mat-icon>
      <ng-template #publisher>
        <mat-icon svgIcon="users"></mat-icon>
      </ng-template>
    </span>
    <span class="autocomplete-text">
      <span class="country">{{item.id}}</span>
      <span class="region">{{item.tag}}</span>
    </span>
  </span>
</ng-template>
