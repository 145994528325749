<div class="header-toolbar-input">
  <mat-form-field  appearance="outline">
    <mat-label>Workspace</mat-label>
    <mat-icon svgIcon="workspace" matPrefix></mat-icon>
    <mat-icon svgIcon="chevron-down" matSuffix></mat-icon>
    <input
      type="text"
      matInput
      [matAutocomplete]="auto"
      [value]="workspaceControl?.value?.name || ''"
      (input)="onInput($event.target.value)"
    />
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option *ngFor="let option of workspaceOptions" (click)="optionSelected(option)">
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>