import { Injectable } from '@angular/core';
import {ApiService} from "@dooh/api";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TutorialsService {

  constructor(private apiService: ApiService) { }

  public getDspVideoTutorials(): Observable<any>  {
    return this.apiService.get(`videos-tutorials/get`,);
  }
  public getSmbVideoTutorials(): Observable<any>  {
    return this.apiService.get(`videos-tutorials-smb/get`,);
  }
}
