<div class="header-toolbar-input">
  <mat-form-field  appearance="outline">
    <mat-label>Whitelabel</mat-label>
    <mat-icon svgIcon="whitelabel" matPrefix></mat-icon>
    <mat-icon svgIcon="chevron-down" matSuffix></mat-icon>
    <input
      type="text"
      matInput
      [matAutocomplete]="auto"
      [value]="whiteLabelControl?.value?.name || ''"
      (input)="onInput($event.target.value)"
      [disabled]="whitelabelOptions.length === 0"
    />
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option *ngFor="let option of whitelabelOptions" (click)="optionSelected(option)">
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>