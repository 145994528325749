<fieldset [formGroup]="budgetGroup">
  <mat-radio-group class="radio-group" aria-label="Select an option" formControlName="budgetOption">

    <div class="radio-section" [ngClass]="{'radio-section_checked': isCheckedCampaign}">
      <div class="selection-title">
        {{ 'budgetPicker.fieldCampaignBudget' | translate:{ currency: currency } }}
      </div>
      <div class="small-radio-button-box" (click)="onActiveCampaignBudget()">
        <mat-radio-button
          (focus)="onActiveCampaignBudget()"
          color="primary"
          [value]="'campaignBudget'"
        >
        </mat-radio-button>
        <mat-form-field class="general-info__field form-field-common" appearance="outline">
          <input
            autocomplete="off"
            type="number"
            matInput
            class="label-info"
            #campaignB
            formControlName="campaignBudget"
            [(ngModel)]="fieldValueCampaign"
            (input)="onChangeInput()"
            (blur)="onBlurInput()"
          >
        </mat-form-field>
      </div>
      <span
        class="description"
        *ngIf="
          !(budgetGroup.get('campaignBudget').hasError('required'))
           && !(budgetGroup.get('campaignBudget').hasError('pattern'))
           && !(budgetGroup.get('campaignBudget').hasError('number'))
         "
      >
        {{ 'budgetPicker.campaignBudgetDesc' | translate }}
      </span>
      <mat-error *ngIf="budgetGroup.get('campaignBudget').hasError('required')">
        {{ 'budgetPicker.validationError.required' | translate }}
      </mat-error>
      <mat-error
        *ngIf="
          !budgetGroup.get('campaignBudget').hasError('number')
          && budgetGroup.get('campaignBudget').hasError('pattern')
        "
      >
        {{ 'budgetPicker.validationError.onlyNumbers' | translate }}
      </mat-error>
      <mat-error
        *ngIf="
          !budgetGroup.get('campaignBudget').hasError('required')
          && budgetGroup.get('campaignBudget').hasError('number')
        "
      >
        <span *ngIf="offer">The Minimum Budget for this Campaign is {{ offer?.minimumBudget | number }}</span>
        <span *ngIf="!offer">
          {{ 'budgetPicker.validationError.greaterThan0' | translate }}
        </span>
      </mat-error>
    </div>

    <div class="radio-section" [ngClass]="{'radio-section_checked': isCheckedDaily}" *ngIf="!offer">
      <div class="selection-title">
        {{ 'budgetPicker.fieldDailyBudget' | translate:{ currency: currency } }}
      </div>
      <div class="small-radio-button-box" (click)="onActiveDailyBudget()" >
        <mat-radio-button
          (focus)="onActiveDailyBudget()"
          color="primary"
          [value]="'dailyBudget'"
          [disabled]="true"
        >
        </mat-radio-button>
        <mat-form-field class="general-info__field form-field-common" appearance="outline">
          <input
            autocomplete="off"
            type="number"
            matInput
            class="label-info"
            #dailyB
            [disabled]="true"
            formControlName="dailyBudget"
            [(ngModel)]="fieldValueDaily"
            (input)="onChangeInput(true)"
            (blur)="onBlurInput(true)"
           >
        </mat-form-field>
      </div>
      <div
        class="description"
        *ngIf="
          !(budgetGroup.get('dailyBudget').hasError('required'))
           && !(budgetGroup.get('dailyBudget').hasError('pattern'))
           && !(budgetGroup.get('dailyBudget').hasError('number'))
         "
      >
        {{ 'budgetPicker.dailyBudgetDesc' | translate }}
      </div>
      <mat-error *ngIf="budgetGroup.get('dailyBudget').hasError('required') ">
        {{ 'budgetPicker.validationError.required' | translate }}
      </mat-error>
      <mat-error
        *ngIf="
          !budgetGroup.get('dailyBudget').hasError('number')
          && budgetGroup.get('dailyBudget').hasError('pattern')
        "
      >
        {{ 'budgetPicker.validationError.onlyNumbers' | translate }}
      </mat-error>
      <mat-error
        *ngIf="
          !budgetGroup.get('dailyBudget').hasError('required')
          && budgetGroup.get('dailyBudget').hasError('number')
        "
      >
        {{ 'budgetPicker.validationError.greaterThan0' | translate }}
      </mat-error>
    </div>
  </mat-radio-group>
</fieldset>
