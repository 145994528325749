import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MatSideNavToggleService {
    private subject = new Subject<boolean>();

    setMatSideNavState(MatSideNavState: boolean): void {
        this.subject.next(MatSideNavState);
    }

    clearMatSideNavState(): void {
        this.subject.next();
    }

    getMatSideNavState(): Observable<boolean> {
        return this.subject.asObservable();
    }
}
