<div class="paginator">
  <mat-paginator
      *ngIf="isPaginated"
      [length]="pagination.totalElements"
      [pageIndex]="pagination.pageIndex - 1"
      [pageSize]="pagination.pageSize"
      [pageSizeOptions]="pageOptions"
      (page)="onPageHandler($event)"
      [disabled]="isLoading"
    ></mat-paginator>
</div>
<div class="wrapper" [ngClass]="{'lower': isPaginated }">
  <div
    class="map-container__overlay map-container__overlay_colored loader-center--flex"
    *ngIf="isLoading"
  >
    <dooh-custom-loader></dooh-custom-loader>
  </div>
    <agm-map [(latitude)]="location.lat" [(longitude)]="location.lng" [disableDefaultUI]="true" [zoomControl]="true"
             [styles]='styles' (boundsChange)="boundsChange($event)" (mapClick)="onMapClick()"
             [fitBounds]="markers && markers.length > 0 || pois && pois.length > 0" [zoom]="location.zoom"
             [streetViewControl]="true" (zoomChange)="zoomChange($event)" [minZoom]="2" [usePanning]='true' (mapReady)='logMapReady($event)' #AgmMap>
      <agm-marker-cluster [maxZoom]="15"
                          imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m" *ngIf="markers.length > 0">
        <ng-container *ngFor="let marker of markers">
          <agm-marker *ngIf="marker.location"
                      [(latitude)]="marker.location.latitude ? marker.location.latitude : marker.location.lat"
                      [(longitude)]="marker.location.longitude ? marker.location.longitude : marker.location.lon"
                      [markerDraggable]="false" (markerClick)="onMarkerClick(marker, iw)"
                      [iconUrl]="markerIconMap[marker.id] || '/assets/icons/ico-marker-new.svg'" [openInfoWindow]="!!infoWindowTemplate"
                      [agmFitBounds]="true">
            <agm-info-window #iw>
              <ng-template [ngTemplateOutlet]="infoWindowTemplate"
                           [ngTemplateOutletContext]="{ data: { marker: marker, type: markerTypeMap[marker.id] } }"></ng-template>
            </agm-info-window>
          </agm-marker>
        </ng-container>
      </agm-marker-cluster>
      <ng-container *ngFor="let marker of pois">
        <agm-marker [(latitude)]="marker.location.lat" [(longitude)]=" marker.location.lng" [markerDraggable]="false"
                    (markerClick)="onMarkerClick(marker)" [iconUrl]="marker.iconSvg" [agmFitBounds]="true"></agm-marker>
        <agm-circle [(latitude)]="marker.location.lat" [(longitude)]="marker.location.lng" *ngIf="pois.length < 10; else circleStrokes"
                    [radius]="marker.radius ? marker.radius : null" fillColor="#2969B0" (circleClick)="onMapClick()"></agm-circle>
        <ng-template #circleStrokes>            
                    <agm-circle [(latitude)]="marker.location.lat" [(longitude)]="marker.location.lng"
                                [radius]="marker.radius ? marker.radius : null" strokeColor="#2969B0" strokeWeight="2" fillColor="rgba(0, 0, 0, 0)" strokeOpacity="0.5" (circleClick)="onMapClick()"></agm-circle>
                              </ng-template>
      </ng-container>
    </agm-map>
  <button *ngIf="geoSearchButton && canGeoSearch" mat-raised-button class="geo-search__button" (click)="geoSearch()">
    <mat-icon>search</mat-icon>
    Search on this area
  </button>
</div>
