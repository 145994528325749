import { FilterScreenObject } from "@dooh/models";

function formatScreenFilterObject(filterScreenObject:FilterScreenObject): any {
    const body = {
        'params': {},
        'limits': filterScreenObject.limits ? filterScreenObject.limits : null,
        'poi': filterScreenObject.pois ? {
          'distance': filterScreenObject.pois.radius,
          'points': filterScreenObject.pois.points
        } : null,
        'postalCodes': filterScreenObject?.postalCodes?.length ? filterScreenObject?.postalCodes : null,
        'bboxes': filterScreenObject?.bboxes ? filterScreenObject?.bboxes : null,
        'placeIq': filterScreenObject?.placeIq ? filterScreenObject?.placeIq : null,
      };
  
      if(filterScreenObject?.tags.length){
        for (const tag of filterScreenObject?.tags) {
          if (tag.properties.length > 0 && !tag?.name?.startsWith('targeting')) {
            if (tag.name.toLocaleLowerCase() === 'package') {
              body['packages'] = [...tag?.properties];
              delete body['params'][tag.name]
            }
            body['params'][tag.name] = { 'filterTerms': [...tag.properties] };
          }
        }
      }
      let targetingTag = filterScreenObject.tags.find(t => t?.name?.startsWith('targeting'));
      if (targetingTag && targetingTag.properties) {
        body['targetingSets'] = targetingTag.properties;
      }
      return body
};

export default formatScreenFilterObject;