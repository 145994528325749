<div class="container">
    <div class="left-container">
        <div class="image-container">
            <img src="/assets/images/new-smb.png" alt="" />
        </div>
        <div class="image-container1">
            <img src="/assets/images/new-smb1.png" alt="" />
        </div>
    </div>
    <div class="right-container">
        <div class="text-container">
            <p class="title">Explore Our Latest Update</p>
            <p class="description">We're excited to introduce the newest version of our web platform! </p>
            <p class="tips">With this update, we've focused on enhancing your user experience and providing even more
                features to empower your online journey.</p>
        </div>
        <div class="links-container">
          <div class="link-wrapper" (click)="goToNewSystem()">
              Go to new SMB System
          </div>
          <div class="link-wrapper" (click)="logout()">
            Sign Out
          </div>
      </div>
    </div>
</div>
