import { ColumnCustomizer, MenuType } from '@dooh/models';

export const PageSizes = [ 50, 100, 250];

export class TableCustomizer {
  columnCustomizers: ColumnCustomizer[];
  containsMenu: boolean;
  menuType: MenuType;
  tableElementsName: string;
  idExtractor: (value: any) => string;
  getRowClass: (row: any) => string;

  constructor(
    tableElementsName: string,
    columnCustomizers: ColumnCustomizer[],
    containsMenu = false,
    menuType?: MenuType,
    idExtractor?: (value: any) => string,
    getRowClass?: (row: any) => string
  ) {
    this.columnCustomizers = columnCustomizers;
    this.containsMenu = containsMenu;
    this.menuType = menuType;
    this.tableElementsName = tableElementsName;
    this.idExtractor = idExtractor;
    this.getRowClass = getRowClass;
  }
}
