import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { MapsAPILoader} from "@agm/core";

declare var google: any;

const DEFAULT_COUNTRY = CountryISO.UnitedStates;

@Component({
  selector: 'dooh-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss']
})
export class PhoneNumberInputComponent implements OnInit {

  @Input('separateDialCode') separateDialCode = true;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  selectedCountryISO = DEFAULT_COUNTRY
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  @Input('control') control: FormControl;
  @Input('cssClass') cssClass: string = 'custom';
  @Input('searchCountryFlag') searchCountryFlag = true;

  geocoder: google.maps.Geocoder;

  constructor(public mapsApiLoader: MapsAPILoader) {
    this.mapsApiLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
      this.getCurrentCountry();
    })
   }

  ngOnInit(): void {
  }

  getCurrentCountry(){
    navigator.geolocation.getCurrentPosition((position)=>{
      this.getCurrentCountrySuccess(position);
    },
    ()=>{
      this.getCurrentCountryFail();
    })
  }
  getCurrentCountrySuccess(position){
    let lat = position.coords.latitude;
    let lng = position.coords.longitude;
    this.getCountryName(lat, lng)
  }
  getCurrentCountryFail(){
    this.selectedCountryISO = DEFAULT_COUNTRY;
  }
  getCountryName(lat:number, lng:number){
    let latlng = new google.maps.LatLng(lat, lng);
    this.geocoder.geocode({location: latlng}, (results) => {
      const country =  results[0].address_components.find(value=>value.types.includes("country"));
      const countryName = country.short_name.toLocaleLowerCase() as CountryISO;
      this.selectedCountryISO = countryName || DEFAULT_COUNTRY;;
    })
  }
}
