import { Component, Input, ViewChild, ViewContainerRef, OnInit, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AddDynamicComponentService } from '@dooh/common-services';
import { DayHourRangeComponent } from '../day-hour-range/day-hour-range.component';
import { DayScheduleForm } from '@dooh/models';

const DEFAULT_FROM_STR = '00:00';
const DEFAULT_TO_STR = '23:59';

@Component({
  selector: 'dooh-hour-weekly-table',
  templateUrl: './hour-weekly-table.component.html',
  styleUrls: ['./hour-weekly-table.component.scss']
})
export class HourWeeklyTableComponent implements OnInit, AfterViewInit {

  @Input()
  editable: boolean;

  days: any;

  componentDays: {
    [key: string]: any[];
  };

  @Input()
  initData?: DayScheduleForm[];

  fullInitData: DayScheduleForm[];

  value: any[];

  @ViewChild('containerday', { read: ViewContainerRef }) container: ViewContainerRef;

  @Output()
  changeValue: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  changeSelections: EventEmitter<any> = new EventEmitter<any>();

  weekDays: string[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];

  constructor(
    private dynamic: AddDynamicComponentService,
    public translateService: TranslateService
  ) {
    this.translateService.setDefaultLang('en');
  }

  ngOnInit() {
    this.fullInitData = this.getFullInitData(this.initData);
  }

  ngAfterViewInit() {
    if (!this.editable) {
      return;
    }

    this.initComponentByData();
  }

  toWeekdayKey(weekDayStr) {
    return weekDayStr && weekDayStr.toUpperCase();
  }

  getFullInitData(initData) {
    const fullInitData = Array.isArray(initData) ? [...initData] : [];
    const isMentionedSmth = Array.isArray(initData) && initData.length;

    if (isMentionedSmth) {
      return fullInitData;
    }

    this.weekDays.forEach((weekDayStr: string) => {
      const key = this.toWeekdayKey(weekDayStr);
      fullInitData.push({
        day: key,
        startTime: DEFAULT_FROM_STR,
        endTime: DEFAULT_TO_STR
      });
    });

    return fullInitData;
  }

  getValue() {
    const value = [];
    if (!Array.isArray(this.days)) {
      return value;
    }

    let isWholeWeekSelectedWholeDays = true;
    let isValid = true;
    this.days.forEach((item, i) => {
      const instance = (<DayHourRangeComponent>item.instance);
      if (!this.weekDays[i] || !instance) {
        isWholeWeekSelectedWholeDays = false;
        return;
      }


      const key = this.toWeekdayKey(this.weekDays[i]);
      const instValObj = instance.getValue();
      const instVal = instValObj.values;
      isValid = isValid && (instValObj.isValid);
      if (!Array.isArray(instVal) || !instVal.length) {
        isWholeWeekSelectedWholeDays = false;
        return;
      }

      isWholeWeekSelectedWholeDays = isWholeWeekSelectedWholeDays &&
        instValObj.isWholeDay &&
        instance.checked;

      if (!instance.checked) {
        return;
      }
      value[key] = {
        isWholeDay: instValObj.isWholeDay,
        checked: instance.checked,
        value: instVal
      };
    });

    if (!isValid) {
      return null;
    }

    if (isWholeWeekSelectedWholeDays) {
      return [];
    }
    return value;
  }

  initComponentByData() {
    this.days = [];

    this.componentDays = {};
    if (Array.isArray(this.fullInitData)) {
      this.fullInitData.forEach((element: DayScheduleForm) => {
        this.componentDays[element.day] = this.componentDays[element.day] || [];
        this.componentDays[element.day].push({
          startTime: element['startTime'],
          endTime: element['endTime']
        });
      });
    }

    /*
     * TODO:: Temporary fix to resolve problem when component doesn't re-render with specified day and initData params
     * To reproduce this issue you can remove setTimeout and try to open schedule tab edit mode multiple times (edit -> cancel -> ...)
     * This happens because of the ngAfterViewInit which we need to use to access this.container
     * Probably it would be better to remove dynamic components addition and define dayHourRange components in html template using loop,
     * but the problem is dayHourRange component also contain the same problem so we need to do refactroing inside all childs which have ngAfterViewInit usage
     */
    setTimeout(() => {
      this.dynamic.removeAllDynamicChildren(this.container);

      this.weekDays.map((weekDayStr: string) => {
        const component = this.dynamic.addDynamicComponent(DayHourRangeComponent, this.container);
        const instance = (<DayHourRangeComponent>component.instance);
        instance.day = this.toWeekdayKey(weekDayStr);
        instance.initData = this.componentDays[instance.day];
        instance.changeValue.subscribe( ()  => {
          this.changeValue.emit(true)
        });
        instance.changeSelection.subscribe(() => {
          this.changeSelections.emit(this.getValue());
        })
        this.days.push(component);
        
      });
    }, 0);
  }
}
