<div class="wrapper">
  <div class="boundary" [ngClass]="data?.disabled ? 'disabled' : ''">
    <div class="header">
      <p class="title">{{ data?.day | titlecase }}</p>
      <label class="switch" *ngIf="editable">
        <input
          type="checkbox"
          [checked]="!data?.disabled"
          (click)="toggleAvailability()"
        />
        <span class="slider round"></span>
      </label>
    </div>
    <div class="time-segment">
      <button
        mat-button
        color="primary"
        class="add-button"
        type="button"
        *ngIf="!data?.disabled && editable"
        (click)="addTimeClick()"
      >
        Add <mat-icon class="icon">add</mat-icon>
      </button>
      <div
        class="segment"
        *ngFor="let segment of data?.segment; let i = index"
        [ngStyle]="{
          height: getSegmentHeight(segment),
          'top.px': getSegmentOffset(segment?.startTime)
        }"
        (click)="editTimeClick(i)"
      >
        <p class="segment-value">
          {{ formatTime(segment?.startTime, 'hh:mm a') }} -
          {{ formatTime(segment?.endTime, 'hh:mm a') }}
        </p>
        <span
          class="menu"
          [matMenuTriggerFor]="menu"
          *ngIf="!data?.disabled && editable"
          (click)="preventDefault($event)"
        >
          <mat-icon class="icon">more_horiz</mat-icon>
        </span>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            class="menu-button"
            type="button"
            (click)="editTimeClick(i)"
          >
            <mat-icon class="menu-icon">edit</mat-icon> Edit
          </button>
          <button
            mat-menu-item
            class="menu-button"
            (click)="copyTimeClick(i)"
            type="button"
          >
            <mat-icon class="menu-icon">layers</mat-icon> Copy
          </button>
          <button
            mat-menu-item
            class="menu-button"
            type="button"
            color="warn"
            (click)="removeTimeClick(i)"
          >
            <mat-icon class="menu-icon">delete_outline</mat-icon>Delete
          </button>
        </mat-menu>
      </div>
      <div
        class="time"
        [ngClass]="{ isEditable: editable }"
        *ngFor="let time of timing"
        (click)="addTimeClick(time)"
      ></div>
    </div>
  </div>
</div>
