import {
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'dooh-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {

  @Input()
  type: string = 'warning';

  @Input()
  message: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
