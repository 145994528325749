import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@dooh/api';
import { interval, Observable } from 'rxjs';
import { mergeMap, takeWhile } from 'rxjs/operators';

export const enum ReportAssemblyStatusEnum {
  INITIALIZED = 'INITIALIZED',
  ASSEMBLING = 'ASSEMBLING',
  COMPLETED = 'COMPLETED',
}

@Component({
  selector: 'dooh-download-page',
  templateUrl: './download-page.component.html',
  styleUrls: ['./download-page.component.scss'],
})
export class DownloadPageComponent implements OnInit {
  url: string;
  fileName: string;
  INTERVAL_PERIOD = 1000;
  isLoading = true;
  isCompleted = false;
  successful = false;
  heading: string = 'DOWNLOADING CSV';
  subHeading: string = 'It will take a few seconds...';

  constructor(private route: ActivatedRoute, private apiService: ApiService) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const parseData = JSON.parse(params.data);
      this.url = parseData.endpoint;
      this.fileName = parseData.fileName;
      this.startPooling(this.url);
    });
  }

  retryDownload(): void {
    this.isLoading = true;
    this.isCompleted = false;
    this.successful = false;
    this.startPooling(this.url);
  }

  endPointUrl(url: string): Observable<any> {
    return this.apiService.get(url);
  }

  startPooling(url: string): void {
    interval(this.INTERVAL_PERIOD)
      .pipe(
        takeWhile(() => this.isCompleted === false),
        mergeMap(() => this.endPointUrl(url))
      )
      .subscribe(
        (data) => {
          if (data.status === ReportAssemblyStatusEnum.COMPLETED) {
            this.isCompleted = true;
            this.download(data.url);
          }
        },
        () => {
          this.isCompleted = true;
          this.successful = false;
          this.isLoading = false;
        }
      );
  }

  download(fileUrl: string): void {
    this.getFileBlob(fileUrl).subscribe(
      (blob) => {
        let hiddenElement = document.createElement('a');
        hiddenElement.href = URL.createObjectURL(blob);
        hiddenElement.target = '_blank';
        hiddenElement.download = this.fileName;
        hiddenElement.click();
        this.heading = 'CSV DOWNLOADED';
        this.successful = true;
        this.isLoading = false;
      },
      () => {
        this.heading = 'DOWNLOAD FAILED';
        this.successful = false;
        this.isLoading = false;
      }
    );
  }

  getFileBlob(url: string): Observable<any> {
    return this.apiService.getThirdPartyBlob(url);
  }
}
