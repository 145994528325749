import * as moment from 'moment';

function convertToIsoString(date: string, isEndDate?: boolean): string {
  const dateObject = moment(date);
  if (dateObject.isValid()) {
    const utcDateObject = dateObject.add(dateObject.utcOffset(), 'm').utc();
    return isEndDate
      ? utcDateObject.endOf('day').toISOString()
      : utcDateObject.startOf('day').toISOString();
  } else {
    return null;
  }
};

export default convertToIsoString;
