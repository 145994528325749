/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { CreativeUploadView } from '../model/creativeUploadView';
import { CreativeView } from '../model/creativeView';
import { IabCategoryForm } from '../model/iabCategoryForm';
import { IabCategoryView } from '../model/iabCategoryView';
import { PageDto } from '../model/pageDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CreativesService {

    protected basePath = 'https://dooh-api.staging.theneuron.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create new creative upload credentials
     * Create new creative upload credentials
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCreativeUpload(observe?: 'body', reportProgress?: boolean): Observable<CreativeUploadView>;
    public createCreativeUpload(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreativeUploadView>>;
    public createCreativeUpload(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreativeUploadView>>;
    public createCreativeUpload(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<CreativeUploadView>(`${this.basePath}/dsp/creative_uploads`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create new creative
     * Create new creative
     * @param body CreativeModel object
     * @param file Multimedia content
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCreativeWithContent(body: string, file: Blob, observe?: 'body', reportProgress?: boolean): Observable<CreativeView>;
    public createCreativeWithContent(body: string, file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreativeView>>;
    public createCreativeWithContent(body: string, file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreativeView>>;
    public createCreativeWithContent(body: string, file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createCreativeWithContent.');
        }

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling createCreativeWithContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        const convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (body !== undefined) {
            formParams = formParams.append('body', <any>body) || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<CreativeView>(`${this.basePath}/dsp/creatives`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create new IAB category
     * Create new IAB category
     * @param body IAB Category Object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createIabCategory(body: IabCategoryForm, observe?: 'body', reportProgress?: boolean): Observable<IabCategoryView>;
    public createIabCategory(body: IabCategoryForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IabCategoryView>>;
    public createIabCategory(body: IabCategoryForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IabCategoryView>>;
    public createIabCategory(body: IabCategoryForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createIabCategory.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<IabCategoryView>(`${this.basePath}/dsp/iab_categories`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete creative
     * Delete creative
     * @param creativeId Creative Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCreative(creativeId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteCreative(creativeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteCreative(creativeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteCreative(creativeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (creativeId === null || creativeId === undefined) {
            throw new Error('Required parameter creativeId was null or undefined when calling deleteCreative.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/dsp/creatives/${encodeURIComponent(String(creativeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete IAB category
     * Delete IAB category
     * @param iabCategoryId IAB Category Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteIABCategory(iabCategoryId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteIABCategory(iabCategoryId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteIABCategory(iabCategoryId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteIABCategory(iabCategoryId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (iabCategoryId === null || iabCategoryId === undefined) {
            throw new Error('Required parameter iabCategoryId was null or undefined when calling deleteIABCategory.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/dsp/iab_categories/${encodeURIComponent(String(iabCategoryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get creative by id
     * Get creative by id
     * @param creativeId Creative Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCreative(creativeId: string, observe?: 'body', reportProgress?: boolean): Observable<CreativeView>;
    public getCreative(creativeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreativeView>>;
    public getCreative(creativeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreativeView>>;
    public getCreative(creativeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (creativeId === null || creativeId === undefined) {
            throw new Error('Required parameter creativeId was null or undefined when calling getCreative.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CreativeView>(`${this.basePath}/dsp/creatives/${encodeURIComponent(String(creativeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of creatives
     * Get list of creatives, attributes: page&#x3D;2&amp;page_size&#x3D;10&amp;sort&#x3D;myfield,asc/desc;myfield2,asc/desc
     * @param page the number of page
     * @param pageSize the size of page
     * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCreatives(page?: number, pageSize?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
    public getCreatives(page?: number, pageSize?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
    public getCreatives(page?: number, pageSize?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
    public getCreatives(page?: number, pageSize?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('page_size', <any>pageSize);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageDto>(`${this.basePath}/dsp/creatives`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of IAB categories
     * Get list of IAB Categories. Available fields for sorting and filtering: - &#39;iab_category_id&#39; - the ID of IAB Category; - &#39;name&#39; - the name of IAB category; - &#39;code&#39; - the code of IAB category.  Request example: /iab_categories?page&#x3D;2&amp;page_size&#x3D;10&amp;sort&#x3D;iab_category_id,asc;name,desc&amp;filters&#x3D;name,like,%airport%.
     * @param filters Filtering criteria in the format: field_name,operation,value. Supported operations: eq (equal, &#x3D;), neq (not equal, !&#x3D;), lt (less than, &lt;), lte (less than or equal, &lt;&#x3D;), gt (greater than, &gt;), gte (greater than or equal, &gt;&#x3D;), like.
     * @param page the number of page
     * @param pageSize the size of page
     * @param sort Sorting criteria in the format: field_name(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIABCategories(filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageDto>;
    public getIABCategories(filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDto>>;
    public getIABCategories(filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDto>>;
    public getIABCategories(filters?: Array<string>, page?: number, pageSize?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters) {
            filters.forEach((element) => {
                queryParameters = queryParameters.append('filters', <any>element);
            })
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('page_size', <any>pageSize);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageDto>(`${this.basePath}/dsp/iab_categories`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get IAB category by id
     * Get IAB category by id
     * @param iabCategoryId IAB Category Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIABCategory(iabCategoryId: string, observe?: 'body', reportProgress?: boolean): Observable<IabCategoryView>;
    public getIABCategory(iabCategoryId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IabCategoryView>>;
    public getIABCategory(iabCategoryId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IabCategoryView>>;
    public getIABCategory(iabCategoryId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (iabCategoryId === null || iabCategoryId === undefined) {
            throw new Error('Required parameter iabCategoryId was null or undefined when calling getIABCategory.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<IabCategoryView>(`${this.basePath}/dsp/iab_categories/${encodeURIComponent(String(iabCategoryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update creative
     * Update creative
     * @param body CreativeModel object
     * @param creativeId Creative Id
     * @param file Multimedia content
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCreativeWithContent(body: string, creativeId: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<CreativeView>;
    public updateCreativeWithContent(body: string, creativeId: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreativeView>>;
    public updateCreativeWithContent(body: string, creativeId: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreativeView>>;
    public updateCreativeWithContent(body: string, creativeId: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateCreativeWithContent.');
        }

        if (creativeId === null || creativeId === undefined) {
            throw new Error('Required parameter creativeId was null or undefined when calling updateCreativeWithContent.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        const convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (body !== undefined) {
            formParams = formParams.append('body', <any>body) || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.put<CreativeView>(`${this.basePath}/dsp/creatives/${encodeURIComponent(String(creativeId))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update IAB category
     * Update IAB category
     * @param body IAB Category Object
     * @param iabCategoryId IAB Category Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateIABCategory(body: IabCategoryForm, iabCategoryId: string, observe?: 'body', reportProgress?: boolean): Observable<IabCategoryView>;
    public updateIABCategory(body: IabCategoryForm, iabCategoryId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IabCategoryView>>;
    public updateIABCategory(body: IabCategoryForm, iabCategoryId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IabCategoryView>>;
    public updateIABCategory(body: IabCategoryForm, iabCategoryId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateIABCategory.');
        }

        if (iabCategoryId === null || iabCategoryId === undefined) {
            throw new Error('Required parameter iabCategoryId was null or undefined when calling updateIABCategory.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<IabCategoryView>(`${this.basePath}/dsp/iab_categories/${encodeURIComponent(String(iabCategoryId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
