<div class="supported">
  <p class="supported__title">Supported Formats, Size and Duration</p>
  <div class="supported__format-container">
    <!-- IMAGE -->
    <div class="supported__format">
        <div class="supported__sub-title">Resolution:</div>
        <p class="supported__item">
          <span class="supported__item--size">Must be {{resolution}}px</span>
        </p>
    </div>

    <!-- IMAGE -->
    <div class="supported__format" *ngIf="creativeTypes.includes('image') || creativeTypes.length === 0">
        <div class="supported__sub-title">Image:</div>
        <p class="supported__item" *ngIf="creativeTypes.length === 0">
          <span class="supported__item--name">All Formats</span>
          <mat-icon class = "dot-seperator" svgIcon="dot-seperator"></mat-icon>
          <span class="supported__item--size">Up to 128MB</span>
        </p>
        <p class="supported__item" *ngFor="let type of imageTypes">
          <span class="supported__item--name">{{ type.split('/')[1] | uppercase }}</span>
          <mat-icon class = "dot-seperator" svgIcon="dot-seperator"></mat-icon>
          <span class="supported__item--size">Up to 128MB</span>
        </p>
    </div>

    <!-- VIDEO -->
    <div class="supported__format" *ngIf="creativeTypes.includes('video') || creativeTypes.length === 0">
        <div class="supported__sub-title">Video:</div>
        <p class="supported__item" *ngIf="creativeTypes.length === 0">
          <span class="supported__item--name">All Formats</span>
          <mat-icon class = "dot-seperator" svgIcon="dot-seperator"></mat-icon>
          <span class="supported__item--size">Up to 250MB</span>
          <mat-icon class = "dot-seperator" svgIcon="dot-seperator"></mat-icon>
          <span class="supported__item--duration">&#8734; sec</span>
        </p>
        <p class="supported__item" *ngFor="let type of videoTypes">
          <span class="supported__item--name">{{ type.split('/')[1] | uppercase }}</span>
          <mat-icon class = "dot-seperator" svgIcon="dot-seperator"></mat-icon>
          <span class="supported__item--size">Up to 250MB</span>
          <mat-icon class = "dot-seperator" svgIcon="dot-seperator"></mat-icon>
          <span class="supported__item--duration" *ngIf="showDuration()">{{ showDuration() }}</span>
        </p>
    </div>

    <!-- OTHERS -->
    <div class="supported__format" *ngIf="creativeTypes.includes('others')">
        <div class="supported__sub-title">Others:</div>
        <p class="supported__item" *ngFor="let type of otherTypes">
          <span class="supported__item--name">{{ type.split('/')[1] | uppercase }} (must be a .zip file)</span>
          <mat-icon class = "dot-seperator" svgIcon="dot-seperator"></mat-icon>
          <span class="supported__item--size">Up to 250MB</span>
          <mat-icon class = "dot-seperator" svgIcon="dot-seperator"></mat-icon>
          <span class="supported__item--duration">&#8734; sec</span>
        </p>
    </div>

    <!-- OTHER -->
    <div class="supported__format" hidden>
        <div class="supported__sub-title">Other:</div>
        <p class="supported__item">
          <span class="supported__item--name">HTML5</span>
          <mat-icon class = "dot-seperator" svgIcon="dot-seperator"></mat-icon>
          <span class="supported__item--size">Up to 10MB</span>
        </p>
    </div>

  </div>
</div>
