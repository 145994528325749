import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AlertDialogData } from '@dooh/models';
import * as moment from 'moment';
import { DialogService } from '../../../dialogs/dialog.service';
import { AddTimeComponent } from '../add-time/add-time.component';
import { take } from 'rxjs/operators';
import { CopyTimeComponent } from '../copy-time/copy-time.component';

const DEFAULT_FROM_STR = '00:00';
const DEFAULT_TO_STR = '23:59';
const END_HOUR_SECONDS = ':59.999999999';
const MINS_IN_A_DAY = 1439;
@Component({
  selector: 'dooh-week',
  templateUrl: './week.component.html',
  styleUrls: ['./week.component.scss'],
})
export class WeekComponent implements OnInit, OnChanges {
  @Input() editable: boolean;
  @Input() weekDays: string[];
  @Input() data?: any[];

  @Output() values: EventEmitter<any>;
  hours: any[] = [];

  weekDaysWithData: any[] = [];
  constructor(private dialogService: DialogService) {
    this.values = new EventEmitter<any>();
  }

  ngOnInit(): void {
    if (!this.data) {
      this.data = [];
    }
    const hours = Array.from(
      {
        length: 24,
      },
      (_, hour) =>
        moment({
          hour: hour,
          minutes: 0,
        }).format('h a')
    );

    hours.forEach((item) => {
      const itemArr = item.split(' ');
      this.hours.push({
        hour: itemArr[0],
        sup: itemArr[1],
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['weekDays'] && changes['weekDays']?.currentValue) {
      this.weekDays = changes['weekDays']?.currentValue;
      this.weekDaysWithData = [];
      this.weekDays.forEach((item) => {
        this.weekDaysWithData.push({
          day: item.toLowerCase(),
          segment: [],
          disabled: true,
        });
      });
      this.mergeDaysSegement();
    }
    if (changes['data'] && changes['data']?.currentValue) {
      this.data = changes['data']?.currentValue;
      this.mergeDaysSegement();
    }

    if (changes['editable'] && changes['editable']?.currentValue) {
      this.editable = changes['editable']?.currentValue;
      this.toggleDisableDays();
    }
  }

  toggleDisableDays(): void {
    this.weekDaysWithData.forEach((data) => {
      if (data?.segment.length === 0) {
        data.disabled = true;
      } else {
        data.disabled = false;
      }
    });
  }

  toggleChecked(isChecked: boolean, day: string): void {
    if (isChecked) {
      this.data[day.toUpperCase()] = [];
      this.data[day.toUpperCase()].push({
        startTime: DEFAULT_FROM_STR + ':00',
        endTime: this.toEndOfHour(DEFAULT_TO_STR),
      });
    } else {
      Object.keys(this.data).forEach((item) => {
        if (item.toLowerCase() === day) {
          delete this.data[item];
        }
      });
      this.weekDaysWithData.find((data) => data?.day === day).segment = [];
    }
    this.mergeDaysSegement();
    setTimeout(() => {
      this.values.emit(this.data);
    });
  }

  addTime(event: { day: string; timeSlot?: string }): void {
    const day = event?.day;
    const timeSlot = event?.timeSlot;
    const dayData = this.weekDaysWithData.find(
      (weekDay) => weekDay?.day === day
    ).segment;

    const dialogData: any = {
      type: 'default',
      heading: day,
      submitButtonText:
        'campaigns.createCampaign.schedule.addTimeModal.submitButton',
      data: dayData,
      timeSlot,
    };
    this.dialogService
      .openCustomComponentModal(dialogData, AddTimeComponent)
      .afterClosed()
      .pipe(take(1))
      .subscribe({
        next: (res: any) => {
          if (res) {
            const range = res?.range;
            const value = Object.keys(this.data).find(
              (item) => item.toLowerCase() === day
            );
            const daySegment = this.data[value];
            const startTime = `${daySegment[0]?.startTime.split(':')[0]}:${
              daySegment[0]?.startTime.split(':')[1]
            }`;
            const endTime = `${daySegment[0]?.endTime.split(':')[0]}:${
              daySegment[0]?.endTime.split(':')[1]
            }`;
            if (startTime === DEFAULT_FROM_STR && endTime === DEFAULT_TO_STR) {
              this.data[value] = [];
            }

            this.data[value].push(this.prepareValue(range));
            this.mergeDaysSegement();
            this.values.emit(this.data);
          }
        },
      });
  }

  editTime(index: number, timeSegIndex: number, day: string): void {
    const dayData = this.weekDaysWithData.find(
      (weekDay) => weekDay?.day === day
    ).segment;
    const timeToEdit = dayData[timeSegIndex];

    const dialogData: any = {
      type: 'default',
      heading: day,
      submitButtonText:
        'campaigns.createCampaign.schedule.addTimeModal.submitButton',
      data: dayData,
      presetData: timeToEdit,
    };

    this.dialogService
      .openCustomComponentModal(dialogData, AddTimeComponent)
      .afterClosed()
      .pipe(take(1))
      .subscribe({
        next: (res: any) => {
          if (res?.range) {
            this.removeTime(index, timeSegIndex, day, true);
            const range = res?.range;
            const value = Object.keys(this.data).find(
              (item) => item.toLowerCase() === day
            );
            this.data[value].push(this.prepareValue(range));
            this.mergeDaysSegement();
            this.values.emit(this.data);
          } else if (res?.delete) {
            this.removeTime(index, timeSegIndex, day);
          }
        },
      });
  }

  removeTime(
    index: number,
    timeSegIndex: number,
    day: string,
    preventEmit?: boolean
  ): void {
    const dataSeg: any[] = this.weekDaysWithData[index].segment;
    const timeToRemove = dataSeg[timeSegIndex];
    Object.keys(this.data).forEach((key) => {
      if (key.toLowerCase() === day && this.data[key] === timeToRemove) {
        delete this.data[key];
      }
    });
    dataSeg.splice(timeSegIndex, 1);
    this.mergeDaysSegement();
    if (!preventEmit) this.values.emit(this.data);
  }

  copyTime(timeSegIndex: number, day: string): void {
    const dayData = this.weekDaysWithData.find(
      (weekDay) => weekDay?.day === day
    ).segment;
    const timeToCopy = dayData[timeSegIndex];

    const dialogData: any = {
      type: 'default',
      heading: day,
      submitButtonText:
        'campaigns.createCampaign.schedule.addTimeModal.copyButton',
      data: {
        time: timeToCopy,
        selectedDay: day,
        weekDays: this.weekDays,
        weekDaysWithData: this.weekDaysWithData,
      },
    };

    this.dialogService
      .openCustomComponentModal(dialogData, CopyTimeComponent)
      .afterClosed()
      .pipe(take(1))
      .subscribe({
        next: (res: any) => {
          if (res) {
            const selectedDaysToCopyTo: string[] = res?.selectedDaysToCopyTo;
            selectedDaysToCopyTo.forEach((selectedDay: string) => {
              const selectedDayData: any[] = this.weekDaysWithData.find(
                (weekDay) => weekDay?.day === selectedDay
              ).segment;
              if (this.checkIsWholeDay(selectedDayData)) {
                const index = this.weekDaysWithData.findIndex(
                  (weekDay) => weekDay?.day === selectedDay
                );
                const timeIndex = selectedDayData.findIndex(time => time?.startTime === DEFAULT_FROM_STR + ':00' && time?.endTime === this.toEndOfHour(DEFAULT_TO_STR))
                this.removeTime(index, timeIndex, selectedDay, true);
              }
              
              const value = Object.keys(this.data).find(
                (item) => item.toLowerCase() === selectedDay
              );
              this.data[value].push(timeToCopy);
            });

            this.mergeDaysSegement();
            this.values.emit(this.data);
          }
        },
      });
  }

  mergeDaysSegement(): void {
    Object.keys(this.data).forEach((key) => {
      const weekDayData = this.weekDaysWithData.find(
        (x) => x?.day.toLowerCase() === key.toLowerCase()
      );
      weekDayData.segment = this.data[key];
    });
    this.toggleDisableDays();
  }

  prepareValue(val: any): any {
    const startTime = val?.value['startTime'] || DEFAULT_FROM_STR;
    const endTime = val?.value['endTime'] || DEFAULT_TO_STR;

    return {
      // isWholeDay: startTime === DEFAULT_FROM_STR && endTime === DEFAULT_TO_STR,
      startTime: startTime + ':00',
      endTime: this.toEndOfHour(endTime),
    };
  }

  toEndOfHour(val) {
    if (!val) {
      return null;
    }
    return val + END_HOUR_SECONDS;
  }

  checkIsWholeDay(dayData: any[]): boolean {
    let busyTimeInMins = 0;
    dayData.forEach((data) => {
      busyTimeInMins += this.getTimeDiffInMins(data);
    });

    return busyTimeInMins === MINS_IN_A_DAY;
  }

  getTimeDiffInMins(data: { startTime: string; endTime: string }): number {
    const startTime = moment(data?.startTime + ':00', 'hh:mm:ss a');
    const endTime = moment(data?.endTime + END_HOUR_SECONDS, 'hh:mm:ss a');
    const duration = endTime.diff(startTime, 'minutes');
    return duration;
  }
}
