<div class="package-list" [hidden]="isShowingPackageScreens">
  <div
    class="empty-results"
    *ngIf="!packages?.dataSource.data.length && packages?.isLoading === false"
  >
    There are no Packages
  </div>

  <div class="loader-wrapper">
    <div
      class="info-container__overlay info-container__overlay_colored loader-center--flex"
      *ngIf="packages?.isLoading"
    >
      <dooh-custom-loader></dooh-custom-loader>
    </div>
    <div
      class="packages-cards-wrapper"
      [hidden]="!packages?.dataSource.data.length"
    >
      <div
        class="package-card"
        [ngClass]="{ disabled: isDisable(element) }"
        *ngFor="let element of packages?.dataSource.data"
      >
        <div class="image-area">
          <div class="img-wrapper">
            <img [src]="element?.imageUrl" alt="" *ngIf="element?.imageUrl" />
            <img
              src="../../../assets/images/box.svg"
              alt=""
              *ngIf="!element?.imageUrl"
            />
          </div>
          <div class="screen-n-price">
            <p class="screen">
              <strong>{{ element?.screens | number: '1.0-0' }}</strong> screens
            </p>
          </div>
        </div>
        <div class="info-area">
          <h3 class="title">{{ element?.name }}</h3>
          <div class="screen-n-price">
            <div class="price">
              <p><small>Price (per 1000 impressions)</small></p>
              <p>
                <strong
                  >{{ element?.price || '-' | money }}
                  {{ element?.currency?.code }}
                </strong>
              </p>
            </div>
            <div class="price">
              <p><small>Audience</small></p>
              <p>
                <strong>{{ element?.avgWeeklyAudience | number: '1.0-0'}} </strong>
              </p>
            </div>
          </div>
          <button
            class="select"
            [ngClass]="{ selected: selection.isSelected(element) }"
            [disabled]="isDisable(element)"
            (click)="togglePackageSelection(element)"
          >
            <span *ngIf="!element?.hasRelation">
              {{
                selection.isSelected(element)
                  ? 'Unselect'
                  : 'View and Select Screens'
              }}
            </span>
            <span *ngIf="element?.hasRelation">
              {{
                selection.isSelected(element)
                  ? 'Remove Package Screens'
                  : 'Reselect Package'
              }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <mat-paginator
    class="mat-paginator-sticky"
    *ngIf="
      packages?.dataSource &&
      packages?.dataSource.data &&
      packages?.dataSource.data.length > 0
    "
    [length]="packages?.totalElements"
    [pageIndex]="packages?.currentPage - 1"
    [pageSize]="packages?.pageSize"
    [pageSizeOptions]="[10, 25, 50]"
    (page)="onPackagesPageChange($event)"
  >
  </mat-paginator>
</div>
<div class="package-screens" [hidden]="!isShowingPackageScreens">
  <div class="tool-box">
    <div class="back-button" (click)="backToPackages()">
      <mat-icon color="primary">keyboard_backspace</mat-icon>
      <span>Packages</span>
    </div>
    <div class="selected">
      <p>
        Selected Package:
        <span class="badge">{{ selectedPackage?.name }}</span>
      </p>
      <p>
        Total screens:
        <span class="badge">{{
          packageScreens?.totalElements | number: '1.0-0'
        }}</span>
      </p>
    </div>
  </div>
  <div class="table-container">
    <dooh-generic-table
      class="child-table"
      [dataSource]="packageScreens?.dataSource"
      [tableCustomizer]="packageScreens?.tableCustomizer"
      [totalElements]="packageScreens?.totalElements"
      [isLoading]="packageScreens?.isLoading"
      [isReset]="packageScreens?.isResetTable"
      (item)="triggerAction($event)"
      (scrolling)="onTableScroll()"
      (header)="onSortChange($event)"
      [selectedElements]="selectedElements.asObservable()"
      pagingStrategy="scroll"
      canBulkSelect="true"
    ></dooh-generic-table>
  </div>
</div>

<ng-template #formats let-data="data">
  <dooh-screens-formats [formats]="data"></dooh-screens-formats>
</ng-template>
