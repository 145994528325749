import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, CustomHttpUrlEncodingCodec } from '@dooh/api';
import { FieldFilterRequest, FilterScreenObject, PageDto } from "@dooh/models";
import { BehaviorSubject, Observable, Subject} from 'rxjs';
import {
  ActiveNamespaceService,
  PointOfInterestService,
  ScreensFilters,
  ScreensFiltersService,
  TargetingFiltersService
} from "@dooh/common-services";
import { apiUrl } from "../../../../../ssp/src/app/api-url";
import { map } from 'rxjs/operators';
import { IBulkSelectQuery } from 'libs/components-library/src/lib/services/bulk-select.service';
import { SearchFilterService } from 'libs/components-library/src/lib/dialogs/screen-search-dialog/service/search-filter.service';

@Injectable({
  providedIn: 'root'
})
export class ScreensService {
  public component$: BehaviorSubject<string> = new BehaviorSubject(null)
  public filters$: BehaviorSubject<FilterScreenObject> = new BehaviorSubject(null)

  googleSub$: any;
  dspSub$: any;
  packageSub$: any;

  constructor(
    private apiService: ApiService,
    private screensFiltersService: ScreensFiltersService,
    private poiService: PointOfInterestService,
    private targetingFilterService: TargetingFiltersService,
    private searchFilterSerice: SearchFilterService
  ) { }

  getScreenTags(searchString: string, page: number, pageSize: number, hasOffer?: boolean): Observable<PageDto> {
    const dspResults: PageDto = {
      totalElements: 0,
      totalPages: 0,
      content: []
    }
    const component = this.component$.getValue();
    const googleResults$ = this.getPlacesFromGoogleWithFilterEnabledCountries(searchString, component).map(result => result.pipe(map((val: any) => val?.predictions)));
    const dspResults$ = this.getScreensByPublisherAndSspTagsOnly(searchString, page, pageSize);
    const packageResults$ = this.getScreensByPackageTagsOnly(searchString, page, pageSize);

    const results$ = new Subject<PageDto>();

    if (this.googleSub$) {
      this.googleSub$.unsubscribe();
    }
    if (this.dspSub$) {
      this.dspSub$.unsubscribe();
    }
    if (this.packageSub$) {
      this.packageSub$.unsubscribe();
    }

    if (page === 1) {
      this.googleSub$ = googleResults$.forEach(googleResult => googleResult.subscribe({
        next: (res: any) => {
          res.reverse().forEach((item: any) => {
            item.id = item.description,
            item.tag = 'Place'
            dspResults.content.unshift(item);
          });
          results$.next(dspResults);
        }
      }));
    }

   

   this.dspSub$ = dspResults$.subscribe({
      next: (res: any) => {
        dspResults.content = [...dspResults?.content, ...res?.content];
        dspResults.totalElements = dspResults.totalElements + res?.totalElements;
        dspResults.totalPages = dspResults.totalPages + res?.totalPages;
        results$.next(dspResults);
      }
    });

    if (!hasOffer) {
      this.packageSub$ = packageResults$.subscribe({
        next: (res: any) => {
          res?.content.forEach(item => {
            item.tag = 'Package'
          })
          dspResults.content = [...dspResults?.content, ...res?.content];
          dspResults.totalElements = dspResults.totalElements + res?.totalElements;
          dspResults.totalPages = dspResults.totalPages + res?.totalPages;
          results$.next(dspResults);
        }
      })
    }

    return results$;
  }

  getAvailableScreens(campaignId: string, filterScreenObject: FilterScreenObject, page: number, pageSize: number, sort?: string[]): Observable<any> {
    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getAvailableScreens.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    if (sort && sort.length) {
      queryParameters = queryParameters.set('sort', <any>sort.join(';'));
    }

    this.setFilters(filterScreenObject);

    const body = {
      'params': {},
      'limits': filterScreenObject?.limits ? filterScreenObject.limits : null,
      'poi': filterScreenObject?.pois ? {
        'distance': filterScreenObject?.pois?.radius,
        'points': filterScreenObject?.pois?.points
      } : null,
      'postalCodes': filterScreenObject?.postalCodes?.length ? filterScreenObject?.postalCodes : null,
      'bboxes': filterScreenObject?.bboxes ? filterScreenObject.bboxes : null,
      'placeIq': filterScreenObject?.placeIq ? filterScreenObject.placeIq : null,
      'isNeededAggregation': page === 1 ? true : false
    };
    if(filterScreenObject?.tags.length){
      for (const tag of filterScreenObject?.tags) {
        if (tag.properties.length > 0) {
          if (tag.name.toLocaleLowerCase() === 'package') {
            body['packages'] = [...tag?.properties];
            delete body['params'][tag.name]
          }
          body['params'][tag.name] = { 'filterTerms': [...tag.properties] };
        }
      }
    }

    const queryUrl = `dsp/smb/screens/campaigns/${encodeURIComponent(campaignId)}/available_screens`;
    const targetingFilter: FieldFilterRequest = {
      body, queryParams: queryParameters, queryUrl
    }
    this.targetingFilterService.setFilterByFieldSource(targetingFilter);
    this.searchFilterSerice.setFilterByFieldSource(targetingFilter);
    return this.apiService.post(queryUrl, body, queryParameters);
  }

  public addBulkScreensToCampaign(campaignId: string, query:IBulkSelectQuery):Observable<any>{
    const queryUrl = `dsp/smb/campaigns/${campaignId}/attach_screens`;
    return this.apiService.post(queryUrl, query);
  }

  getScreens(campaignId: string, page: number, pageSize: number, sort?: Array<string>, filters?: Array<string>): Observable<PageDto> {
    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getScreens.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }

    if (sort && sort.length) {
      queryParameters = queryParameters.set('sort', <any>sort.join(';'));
    }

    if (filters && filters.length) {
      queryParameters = queryParameters.set('filters', <any>filters.join(';'));
    }

    const queryUrl = `dsp/smb/campaigns/${encodeURIComponent(campaignId)}/screens`;
    return this.apiService.get(queryUrl, queryParameters);
  }

  addScreens(campaignId: string, screens: IScreenBatchBody[]) {
    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling addScreens.');
    }

    const body = screens.map((screen) => ({
      screenId: screen.screenId,
      isPlaceiqLocation: screen.isPlaceiqLocation,
      isPlaceiqDemography: screen.isPlaceiqDemography,
      packageId: screen?.packageId
    }));

    const queryUrl = `dsp/smb/campaigns/${encodeURIComponent(campaignId)}/screens_batch`;
    return this.apiService.post(queryUrl, body);
  }

  getScreensCSV(campaignId: string,) {
    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling addScreens.');
    }

    const queryUrl = `dsp/smb/campaigns/${campaignId}/screens/csv`;
    return this.apiService.post(queryUrl);
  }

  deleteScreens(campaignId: string, relationIds: string[]) {
    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling deleteScreens.');
    }

    const queryUrl = `dsp/smb/campaigns/${encodeURIComponent(campaignId)}/screens_batch`;
    return this.apiService.delete(queryUrl, relationIds);
  }

  getScreensFilters(filters: ScreensFilters) {
    const queryUrl = 'dsp/screens/search';
    filters.bboxes = this.getFilters().bboxes;
    return this.screensFiltersService.getScreensFilters(queryUrl, filters);
  }

  getScreensByArea(area, page = 1, pageSize = 50) {
    const queryUrl = 'dsp/screens/search';
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    const filterScreenObject = this.getFilters();
    this.setComponent(null);

    let areaToSearch;

    if (filterScreenObject.bboxes) {
      areaToSearch = filterScreenObject.bboxes
    } else {
      areaToSearch = area
    }

    const body = {
      'params': {},
      'bboxes': areaToSearch
    };

    for (const tag of filterScreenObject.tags) {
      if (tag.properties.length > 0) {
        body['params'][tag.name] = { 'filterTerms': [...tag.properties] };
      }
    }


    return this.apiService.post(queryUrl, body, queryParameters);
  }

  getPlacesFromGoogle(searchString: string, components?: string): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (searchString !== undefined && searchString !== null) {
      queryParameters = queryParameters.set('input', `${searchString}`);
    }
    if (components !== undefined && components !== null) {
      queryParameters = queryParameters.set('components', `country:${components}`);
    }
    const queryUrl = `dsp/googlemaps/autocomplete`;
    return this.apiService.get(queryUrl, queryParameters);
  }

  getPlacesFromGoogleWithFilterEnabledCountries(searchString: string, country?: string): Observable<any[]>[] {
    const queryUrl = `dsp/googlemaps/autocomplete`;
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (searchString !== undefined && searchString !== null) {
      queryParameters = queryParameters.set('input', `${searchString}`);
    }

    const components = [];
    const enabledCountries = ActiveNamespaceService.getNamespace().enabledCountries;
    if (enabledCountries !== undefined && enabledCountries !== null) {
      enabledCountries.map(c => c.code).forEach(countryCode => components.push(countryCode));
    }
    if (country !== undefined && country !== null) {
      components.push(country)
    }

    const componentsSubLists = [];
    for (let i = 0; i < components.length; i += 5) {
      componentsSubLists.push(components.slice(i, i + 5));
    }

    const predictions = [];
    if (componentsSubLists.length !== 0) {
      componentsSubLists.forEach((currentSublist, index) => {
        const componentsStr = 'country:' + currentSublist.join('|country:')
        queryParameters = queryParameters.set('components', componentsStr);
        predictions.push(this.apiService.get(queryUrl, queryParameters));
      });
    } else {
      predictions.push(this.apiService.get(queryUrl, queryParameters));
    }

    return predictions;
  }

  getGooglePlaceInfo(placeId: string): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    queryParameters = queryParameters.set('place_id', `${placeId}`);
    const queryUrl = `dsp/googlemaps/geocode`;
    return this.apiService.get(queryUrl, queryParameters);
  }

  getScreensByPublisherAndSspTagsOnly(searchString: string, page = 1, pageSize = 50): Observable<PageDto> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (searchString !== undefined && searchString !== null) {
      queryParameters = queryParameters.set('search_string', `*${searchString}*`);
    }

    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }

    queryParameters = queryParameters.set('tags', 'publisher,ssp');

    const queryUrl = 'dsp/filtered_screens_tag_v2';
    return this.apiService.get(queryUrl, queryParameters);
  }

  getScreensByPackageTagsOnly(searchString: string, page = 1, pageSize = 50): Observable<PageDto> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (searchString !== undefined && searchString !== null) {
      queryParameters = queryParameters.set('search_string', `*${searchString}*`);
    }

    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }

    queryParameters = queryParameters.set('tags', 'package');

    const queryUrl = 'dsp/filtered_screens_tag_v2';
    return this.apiService.get(queryUrl, queryParameters);
  }

  getDma(filters?: string[], page: number = 1, pageSize: number = 50): Observable<any> {
    const queryUrl = `dsp/dma`;
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    if (filters !== undefined && filters !== null) {
      queryParameters = queryParameters.set('filters', <any>filters);
    }
    return this.apiService.get(queryUrl, queryParameters);
  }

  setComponent(value: string) {
    this.component$.next(value);
    this.poiService.component$.next(value);
  }

  setFilters(value: FilterScreenObject) {
    this.filters$.next(value);
  }

  getFilters(): FilterScreenObject {
    return this.filters$.getValue();
  }

  getScreenReach(filterScreenObject:any,campaignId:string, lineItemId:string): Observable<any>{
    const body = {
      'params': {},
      'limits': filterScreenObject?.limits ? filterScreenObject.limits : null,
      'poi': filterScreenObject?.pois ? {
        'distance': filterScreenObject?.pois?.radius,
        'points': filterScreenObject?.pois?.points
      } : null,
      'postalCodes': filterScreenObject?.postalCodes?.length ? filterScreenObject?.postalCodes : null,
      'bboxes': filterScreenObject?.bboxes ? filterScreenObject.bboxes : null,
      'placeIqCategory': filterScreenObject.placeIqCategory,
    };

    for (const tag of filterScreenObject.tags) {
      if (tag.properties.length > 0) {
        body['params'][tag.name] = { 'filterTerms': [...tag.properties] };
      }
    }
    const queryUrl = `dsp/screens/campaigns/${encodeURIComponent(campaignId)}/line_items/${encodeURIComponent(lineItemId)}/available_screens_place_iq`;
    return this.apiService.post(queryUrl, body,);
  }

  getTargetingScreenReach(filterScreenObject:any, targetingSetId:string): Observable<any>{
    const body = {
      'params': {},
      'limits': filterScreenObject?.limits ? filterScreenObject.limits : null,
      'poi': filterScreenObject?.pois ? {
        'distance': filterScreenObject?.pois?.radius,
        'points': filterScreenObject?.pois?.points
      } : null,
      'postalCodes': filterScreenObject?.postalCodes?.length ? filterScreenObject?.postalCodes : null,
      'bboxes': filterScreenObject?.bboxes ? filterScreenObject.bboxes : null,
      'placeIqCategory': filterScreenObject.placeIqCategory,
    };
    const queryUrl = `dsp/screens/targeting_sets/${encodeURIComponent(String(targetingSetId))}/available_screens_place_iq`
    return this.apiService.post(queryUrl, body,);
  }

  getScreenReachSMB(filterScreenObject:any,campaignId:string): Observable<any>{
    const body = {
      'params': {},
      'limits': filterScreenObject?.limits ? filterScreenObject.limits : null,
      'poi': filterScreenObject?.pois ? {
        'distance': filterScreenObject?.pois?.radius,
        'points': filterScreenObject?.pois?.points
      } : null,
      'postalCodes': filterScreenObject?.postalCodes?.length ? filterScreenObject?.postalCodes : null,
      'bboxes': filterScreenObject?.bboxes ? filterScreenObject.bboxes : null,
      'placeIqCategory': filterScreenObject.placeIqCategory,
    };


    for (const tag of filterScreenObject.tags) {
      if (tag.properties.length > 0) {
        body['params'][tag.name] = { 'filterTerms': [...tag.properties] };
      }
    }
    const queryUrl = `dsp/smb/screens/campaigns/${encodeURIComponent(campaignId)}/available_screens_place_iq`;
    return this.apiService.post(queryUrl, body,);
  }

  getScreenReachTargetingSet(filterScreenObject:any, targetingId:string): Observable<any>{
    const body = {
      'params': {},
      'limits': filterScreenObject?.limits ? filterScreenObject.limits : null,
      'poi': filterScreenObject?.pois ? {
        'distance': filterScreenObject?.pois?.radius,
        'points': filterScreenObject?.pois?.points
      } : null,
      'postalCodes': filterScreenObject?.postalCodes?.length ? filterScreenObject?.postalCodes : null,
      'bboxes': filterScreenObject?.bboxes ? filterScreenObject.bboxes : null,
      'placeIqCategory': filterScreenObject.placeIqCategory,
    };


    for (const tag of filterScreenObject.tags) {
      if (tag.properties.length > 0) {
        body['params'][tag.name] = { 'filterTerms': [...tag.properties] };
      }
    }
    const queryUrl = `dsp/screens/targeting_sets/${encodeURIComponent(targetingId)}/available_screens_place_iq`;
    return this.apiService.post(queryUrl, body);
  }

  getAvailablePackages(campaignId: string, page: number, pageSize: number, sort?: Array<string>): Observable<PageDto> {
    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getAvailablePackages.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }

    if (sort && sort.length) {
      queryParameters = queryParameters.set('sort', <any>sort.join(';'));
    }

    const queryUrl = `dsp/smb/campaigns/${encodeURIComponent(campaignId)}/available_packages`;
    return this.apiService.get(queryUrl, queryParameters);
  }

  getPackages(campaignId: string, page: number, pageSize: number, sort?: Array<string>): Observable<PageDto> {
    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getPackages.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }

    if (sort && sort.length) {
      queryParameters = queryParameters.set('sort', <any>sort.join(';'));
    }

    const queryUrl = `dsp/smb/campaigns/${encodeURIComponent(campaignId)}/packages`;
    return this.apiService.get(queryUrl, queryParameters);
  }
  getPackageScreens(packageId: string, page: number, pageSize: number, sort?: Array<string>): Observable<PageDto> {
    if (packageId === null || packageId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling getPackageScreens.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }

    if (sort && sort.length) {
      queryParameters = queryParameters.set('sort', <any>sort.join(';'));
    }

    const queryUrl = `dsp/packages/${encodeURIComponent(packageId)}/screens`;
    return this.apiService.get(queryUrl, queryParameters);
  }

  attachPackage(campaignId: string, packageId: string) {
    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling attachPackage.');
    }
    const body = {
      packageId
    }

    const queryUrl = `dsp/smb/campaigns/${encodeURIComponent(campaignId)}/attach_package`;
    return this.apiService.post(queryUrl, body);
  }
  detachPackage(campaignId: string, relationId: string) {
    if (campaignId === null || campaignId === undefined) {
      throw new Error('Required parameter campaignId was null or undefined when calling detachPackage.');
    }
    if (relationId === null || relationId === undefined) {
      throw new Error('Required parameter relationId was null or undefined when calling detachPackage.');
    }

    const queryUrl = `dsp/smb/campaigns/${encodeURIComponent(campaignId)}/packages/${encodeURIComponent(relationId)}`;
    return this.apiService.delete(queryUrl);
  }
}
export interface IScreenBatchBody{
  isPlaceiqDemography: boolean,
  isPlaceiqLocation: boolean,
  screenId: string,
  packageId?: string
}
