<div class="generic-table-container">
  <div class="empty-results" *ngIf="(dataSource.data.length==0 || isReset) && !isLoading">
    {{ 'genericTable.noElements' | translate }} {{tableCustomizer.tableElementsName}}
  </div>
  <div
    class="generic-table-container__overlay"
    [ngClass]="{'generic-table-container__overlay_colored': dataSource && dataSource.data && dataSource.data.length > 0}"
    *ngIf="isLoading"
  >
    <dooh-custom-loader></dooh-custom-loader>
  </div>
  <div
    class="generic-table-wrapper"
    (scroll)="onScrollEvent($event)"
    [class.supports-bulk] = "canBulkSelect"
    *ngIf="dataSource.data.length > 0"
  >
    <div class="bulk-select-container" *ngIf="isAllSelected && canBulkSelect">
      <section class="bulk-select" *ngIf="(totalElements > dataSource.data.length && totalElements <= bulkLimit) || (totalElements > dataSource.data.length && noBulkSelectLimit)">
        <ng-container *ngIf="!(isTotalSelected$ | async)" matToolTip="Maximum of 3000 screens can be selected in bulk. If your result is more than 3000, conside using filters.">
          <span class="no-selected">Selected <b>{{dataSource.data.length}}</b> screens.</span>
          <button class="btn btn--text btn--primary" (click) ="setIsTotalSelected(true)">
            <mat-icon svgIcon="double-check"></mat-icon>
            <span class="text">select all {{totalElementsFormatter()}} screens</span>
          </button>
        </ng-container>
        <ng-container *ngIf="isTotalSelected$ | async">
          <span class="no-selected">Selected all {{totalElements}} screens.</span>
          <button class="btn btn--text btn--primary" (click) ="setIsTotalSelected(false)">
            <mat-icon svgIcon="close"></mat-icon>
            <span class="text">Clear selection</span>
          </button>
        </ng-container>
      </section>
    </div>
    <mat-table
      [dataSource]=dataSource [trackBy]="trackById.bind(this)"
      [matSortDisabled]="isMatSortDisabled"
      matSort
      matSortDisableClear
      [matSortActive]="defaultSort"
      [matSortDirection]="defaultSortDirection"
      (matSortChange)="onSortChange($event)"
      [class.forScrollStrategy] = "pagingStrategy === 'scroll'"
    >
      <ng-container
        *ngFor="let columnCustomizer of tableCustomizer.columnCustomizers"
        matColumnDef="{{columnCustomizer.columnDef}}"
      >
        <ng-container *ngIf="!columnCustomizer.sortDisabled">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <ng-container *ngTemplateOutlet="headerCellContent"></ng-container>
          </mat-header-cell>
        </ng-container>
        <ng-container *ngIf="columnCustomizer.sortDisabled">
          <mat-header-cell *matHeaderCellDef mat-header>
            <ng-container *ngTemplateOutlet="headerCellContent"></ng-container>
          </mat-header-cell>
        </ng-container>
        <ng-template #headerCellContent>
          <mat-checkbox
            *ngIf="columnCustomizer.type === ColumnType.CHECKBOX_GROUP"
            class="generic-table__group-checkbox"
            color="primary"
            [checked]="isAllSelected"
            [disabled]="isAllItemsExcluded"
            [indeterminate]="hasExclusionData ? null : (selection.hasValue() && !isAllSelectedMaster())"
            (change)="$event ? onSelectAll() : null"
            (click)="$event.stopPropagation()"
          ></mat-checkbox>
          {{columnCustomizer.headerName}}
          <div *ngIf="columnCustomizer.toolTipMessage">
            <mat-icon class="tooltip_class" matTooltip="{{columnCustomizer.toolTipMessage}}">{{columnCustomizer.toolTipIcon}}</mat-icon>
          </div>
        </ng-template>

        <mat-cell *matCellDef="let element; let i = index">
          <div [ngSwitch]="true">
            <div
              *ngSwitchCase="columnCustomizer.type === ColumnType.CHECKBOX || columnCustomizer.type === ColumnType.CHECKBOX_GROUP"
              class="mat-checkbox-placeholder">
              <mat-checkbox
                            matTooltip="{{readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor)}}"
                            (change)="checkElement(element, $event)"
                            (click)="$event.stopPropagation()"
                            [checked]="selection.isSelected(element) || isAllSelected"
                            [disabled]="element.excluded"
                            color="primary">
                {{readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor, false)}}
              </mat-checkbox>
              <mat-icon
                    svgIcon="{{ columnCustomizer.icon }}"
                    *ngIf="columnCustomizer.showIcon && columnCustomizer.showIcon(element)"
                    matTooltip="{{ columnCustomizer.toolTipMessage }}"
              ></mat-icon>
            </div>
            <div
              mat-raised-button
              *ngSwitchCase="columnCustomizer.type === ColumnType.STRING">
              <span matTooltip="{{readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor)}}"
                [class.generic-table__text-field]="columnCustomizer.columnDef === 'name'">
                {{readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor)}}
              </span>
              <mat-icon
                    svgIcon="{{ columnCustomizer.icon }}"
                    *ngIf="columnCustomizer.showIcon && columnCustomizer.showIcon(element)"
                    matTooltip="{{ columnCustomizer.toolTipMessage }}"
              ></mat-icon>
            </div>
            <div *ngSwitchCase="columnCustomizer.type === ColumnType.AMOUNT">
              {{readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor) | money:'': element?.limitAmountCurrency}}
            </div>
            <div *ngSwitchCase="columnCustomizer.type === ColumnType.STATUS_PILLS">
              <div class="pill {{readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor, columnCustomizer.type).class}}">
                {{readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor, columnCustomizer.type).value}}
              </div>
            </div>
            <div *ngSwitchCase="columnCustomizer.type === ColumnType.MONEY">
              {{readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor) | money}}
            </div>
            <div *ngSwitchCase="columnCustomizer.type === ColumnType.ROUND_NUMBER">
              {{readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor, columnCustomizer.type, true)}}
            </div>
            <div *ngSwitchCase="columnCustomizer.type === ColumnType.DATE">
              {{readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor,columnCustomizer.type, false, true)}}
            </div>
            <div *ngSwitchCase="columnCustomizer.type === ColumnType.NUMBER">
              {{readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor,columnCustomizer.type)}}
            </div>
            <div *ngSwitchCase="columnCustomizer.type === ColumnType.DECIMAL">
              {{readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor,columnCustomizer.type, false, true)}}
            </div>
            <div *ngSwitchCase="columnCustomizer.type === ColumnType.DURATION">
              {{readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor,columnCustomizer.type, false, true)}}
            </div>
            <div *ngSwitchCase="columnCustomizer.type === ColumnType.ACTIVE_TOGGLE">
              <mat-slide-toggle (click)="$event.stopPropagation()" (change)="activeToggle($event, element, i)"
                                [disabled]="element.excluded_toggle"
                                [checked]="!readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor)"
                                [color]="'primary'"> {{!readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor) ? 'Active' : 'Inactive'}}</mat-slide-toggle>
            </div>
            <div *ngSwitchCase="columnCustomizer.type === ColumnType.ACTIVE_TOGGLE_SILENT">
              <mat-slide-toggle (click)="$event.stopPropagation()" (change)="activeToggle($event, element, i)"
                                [disabled]="element.excluded"
                                [checked]="readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor)"
                                [color]="'primary'"></mat-slide-toggle>
            </div>
            <div *ngSwitchCase="columnCustomizer.type === ColumnType.ACTIVE_STATUS">

              <mat-chip-list [disabled]="true">

                <ng-container
                  *ngIf="!readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor);then statusColumnActive else statusColumnInactive"></ng-container>
                <ng-template #statusColumnActive>
                  <mat-chip selected class="generic-table__status generic-table__status_active">
                    {{
                      columnCustomizer.statusColumnProps &&
                      columnCustomizer.statusColumnProps.active
                        ? (columnCustomizer.statusColumnProps.active | translate)
                        : ('genericTable.status.active' | translate)
                    }}
                  </mat-chip>

                </ng-template>
                <ng-template #statusColumnInactive>
                    <mat-chip selected class="generic-table__status generic-table__status_inactive">
                      {{
                        columnCustomizer.statusColumnProps &&
                        columnCustomizer.statusColumnProps.inactive
                          ? (columnCustomizer.statusColumnProps.inactive | translate)
                          : ('genericTable.status.inactive' | translate)
                      }}
                    </mat-chip>
                </ng-template>

              </mat-chip-list>
            </div>
            <div *ngSwitchCase="columnCustomizer.type === ColumnType.ACTIVE_STATUS_BG">
              <mat-chip-list [disabled]="true">
                <ng-container
                  *ngIf="!readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor);then statusColumnActiveBg else statusColumnInactiveBg"
                ></ng-container>
                <ng-template #statusColumnActiveBg>
                  <mat-chip selected class="generic-table__status generic-table__status_active">
                    {{
                    columnCustomizer.statusColumnProps &&
                    columnCustomizer.statusColumnProps.active
                      ? (columnCustomizer.statusColumnProps.active | translate)
                      : ('genericTable.status.active' | translate)
                    }}
                  </mat-chip>
                </ng-template>
                <ng-template #statusColumnInactiveBg>
                  <mat-chip selected class="generic-table__status generic-table__status_inactive">
                    {{
                    columnCustomizer.statusColumnProps &&
                    columnCustomizer.statusColumnProps.inactive
                      ? (columnCustomizer.statusColumnProps.inactive | translate)
                      : ('genericTable.status.inactive' | translate)
                    }}
                  </mat-chip>
                </ng-template>
              </mat-chip-list>
            </div>
            <div *ngSwitchCase="columnCustomizer.type === ColumnType.TRANSACTION_STATUS">
              <i class="status-icon" [ngClass]="
                readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor, columnCustomizer.type) === 'PROCESSED'
                ? 'status-icon--active' 
                : readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor, columnCustomizer.type) === 'ENABLED' ?
                 'status-icon--active'
                 : 'status-icon--inactive'
              "></i>
                {{readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor, columnCustomizer.type) | titlecase}}
            
            </div>
            <div *ngSwitchCase="columnCustomizer.type === ColumnType.ICON_TEXT">
              <div class="icon-title">
                <mat-icon svgIcon='{{columnCustomizer.icon}}' class="color-gray-2"></mat-icon>
                <div>{{readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor)}}</div>
              </div>
            </div>
            <div *ngSwitchCase="columnCustomizer.type === ColumnType.ICON_TOOLTIP">
              <div class="icon-title" *ngIf="readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor)">
                <mat-icon matTooltip="{{columnCustomizer.toolTipMessage}}"
                  svgIcon='{{columnCustomizer.icon}}' class=""></mat-icon>
              </div>
            </div>
            <div *ngSwitchCase="columnCustomizer.type === ColumnType.UNIT_TEXT">
              <div class="icon-title">
                <div>{{readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor)}} {{unit}}</div>
              </div>
            </div>
            <div *ngSwitchCase="columnCustomizer.type === ColumnType.FILE_SIZE">
              <div class="icon-title">
                <div>{{readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor) | filesize}}</div>
              </div>
            </div>
            <div *ngSwitchCase="columnCustomizer.type === ColumnType.APPROVAL_ARCHIVE">
              <div class="icon-title" *ngIf="element.isArchived">
                <mat-icon svgIcon="archive" class="color-gray-2"></mat-icon>
                <div class="color-gray-2">Archived</div>
              </div>
              <div *ngIf="!element.isArchived">
                <mat-chip-list [disabled]="true">
                  <!--                <mat-chip selected class="pending">Approved</mat-chip>-->
                  <mat-chip selected [ngClass]="{
              'approved': readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor) == 'APPROVED',
              'new': readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor) == 'NEW',
              'disapproved': readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor) == 'REJECTED',
              'pending': readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor) == 'PENDING',
              'pending': readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor) == 'FAILED'
              }">{{readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor) | titlecase}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div *ngSwitchCase="columnCustomizer.type === ColumnType.BUTTON">
              <button *ngIf="readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor) !== null  && !columnCustomizer.columnProps.icon" class="column__button box" mat-button
                      (click)="columnCustomizer.columnProps.onClick($event, element, i)">
                {{columnCustomizer.columnProps.secondTitle | translate}}
              </button>
              <button class="column__button" mat-button
                      *ngIf="readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor) === null && !columnCustomizer.columnProps.icon"
                      (click)="columnCustomizer.columnProps.onClick($event, element, i)">
                {{columnCustomizer.columnProps.title | translate}}
              </button>
              <button  [title]="columnCustomizer.columnProps.tooltip" class="button-icon" mat-button disableRipple
                      *ngIf="columnCustomizer.columnProps.icon && columnCustomizer.columnProps.iconType === undefined"
                      (click)="columnCustomizer.columnProps.onClick($event, element, i)">
                      <mat-icon>{{columnCustomizer.columnProps.icon}}</mat-icon>
              </button>
              <button  [title]="columnCustomizer.columnProps.tooltip" class="button-icon" mat-button disableRipple
                      *ngIf="columnCustomizer.columnProps.icon && columnCustomizer.columnProps.iconType === 'svg'"
                      (click)="columnCustomizer.columnProps.onClick($event, element, i)">
                      <mat-icon [svgIcon]="columnCustomizer.columnProps.icon"></mat-icon>
              </button>
            </div>
            <div *ngSwitchCase="columnCustomizer.type === ColumnType.CUSTOM">
              <ng-template [ngTemplateOutlet]="columnCustomizer.customTemplate" [ngTemplateOutletContext]="{ data: readObject(element, columnCustomizer.prop, columnCustomizer.valueExtractor)  }" class="custom"></ng-template>
            </div>
          </div>
        </mat-cell>
      </ng-container>


      <ng-container matColumnDef="menu">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell [hidden] = "!tableCustomizer.containsMenu" *matCellDef="let element" class="flex-justify-right">
          <button mat-icon-button [matMenuTriggerFor]="menu" onclick="event.stopPropagation()">
            <mat-icon svgIcon="ellipsis" class="color-gray-2"></mat-icon>
          </button>

          <mat-menu #menu="matMenu" class="generic-table__menu-panel">
            <div [ngSwitch]="tableCustomizer.menuType">
              <div *ngSwitchCase="MenuType.STATUS">
                <button mat-menu-item (click)="onActivate(element)" *ngIf="element.deactivated">
                  <mat-icon svgIcon="check"></mat-icon>
                  <span>{{ 'genericTable.menu.activate' | translate }}</span>
                </button>
                <button mat-menu-item (click)="onDeactivate(element)" *ngIf="!element.deactivated">
                  <mat-icon svgIcon="close-circle"></mat-icon>
                  <span>{{ 'genericTable.menu.deactivate' | translate }}</span>
                </button>
                <button mat-menu-item (click)="onDelete(element)">
                  <mat-icon svgIcon="delete"></mat-icon>
                  <span>{{ 'genericTable.menu.delete' | translate }}</span>
                </button>
              </div>
              <div *ngSwitchCase="MenuType.EDITION">
                <button mat-menu-item (click)="onEdit(element)">
                  <mat-icon svgIcon="edit"></mat-icon>
                  <span>{{ 'genericTable.menu.edit' | translate }}</span>
                </button>
                <button mat-menu-item (click)="onDelete(element)">
                  <mat-icon svgIcon="delete"></mat-icon>
                  <span>{{ 'genericTable.menu.delete' | translate }}</span>
                </button>
              </div>
              <div *ngSwitchCase="MenuType.ARCHIVE">
                <button mat-menu-item (click)="onArchive(element)" *ngIf="!element.isArchived && element.status !== 'DRAFT'">
                  <mat-icon svgIcon="archive"></mat-icon>
                  <span>{{ 'genericTable.menu.archive' | translate }}</span>
                </button>
                <button mat-menu-item (click)="onUnarchive(element)" *ngIf="element.isArchived">
                  <mat-icon svgIcon="archive"></mat-icon>
                  <span>{{ 'genericTable.menu.unarchive' | translate }}</span>
                </button>
                <button mat-menu-item (click)="onDelete(element)" [hidden]="element?.canDelete === false">
                  <mat-icon svgIcon="delete"></mat-icon>
                  <span>{{ 'genericTable.menu.delete' | translate }}</span>
                </button>
                <button mat-menu-item (click)="onCopy(element)">
                  <mat-icon class="copy_icon">content_copy</mat-icon>
                  <span>{{ 'genericTable.menu.copy' | translate }}</span>
                </button>
              </div>
              <div *ngSwitchCase="MenuType.ARC_DEL_ACT">
                <button mat-menu-item (click)="onActivate(element)" *ngIf="element.deactivated">
                  <mat-icon svgIcon="check"></mat-icon>
                  <span>{{ 'genericTable.menu.activate' | translate }}</span>
                </button>
                <button mat-menu-item (click)="onDeactivate(element)" *ngIf="!element.deactivated">
                  <mat-icon svgIcon="close-circle"></mat-icon>
                  <span>{{ 'genericTable.menu.deactivate' | translate }}</span>
                </button>
                <button mat-menu-item (click)="onArchive(element)">
                  <mat-icon svgIcon="archive"></mat-icon>
                  <span>{{ 'genericTable.menu.archive' | translate }}</span>
                </button>
                <button mat-menu-item (click)="onDelete(element)">
                  <mat-icon svgIcon="delete"></mat-icon>
                  <span>{{ 'genericTable.menu.delete' | translate }}</span>
                </button>
              </div>
              <div *ngSwitchCase="MenuType.RESTORE">
                <button mat-menu-item (click)="onRestore(element)">
                  <mat-icon svgIcon="rotate"></mat-icon>
                  <span>{{ 'genericTable.menu.restore' | translate }}</span>
                </button>
              </div>
              <div *ngSwitchCase="MenuType.CAMPAIGN_LIST_SMB">
                <button mat-menu-item (click)="onActivate(element)" *ngIf="element.deactivated">
                  <mat-icon svgIcon="check"></mat-icon>
                  <span>{{ 'genericTable.menu.activate' | translate }}</span>
                </button>
                <button mat-menu-item (click)="onDeactivate(element)" *ngIf="!element.deactivated">
                  <mat-icon svgIcon="close-circle"></mat-icon>
                  <span>{{ 'genericTable.menu.deactivate' | translate }}</span>
                </button>
                <button mat-menu-item (click)="onCancel(element)" [hidden]=" ![LINEITEM_STATE.ACTIVE, LINEITEM_STATE.PAUSED, LINEITEM_STATE.ENDED].includes(element.status)">
                  <mat-icon svgIcon="close"></mat-icon>
                  <span>{{ 'genericTable.menu.refund' | translate }}</span>
                </button>
                <button mat-menu-item (click)="onDelete(element)" [hidden]="element.status && element.status !== LINEITEM_STATE.DRAFT">
                  <mat-icon svgIcon="delete"></mat-icon>
                  <span>{{ 'genericTable.menu.delete' | translate }}</span>
                </button>
                <button mat-menu-item (click)="onCopy(element)">
                  <mat-icon class="copy_icon">content_copy</mat-icon>
                  <span>{{ 'genericTable.menu.copy' | translate }}</span>
                </button>
              </div>
              <div *ngSwitchCase="MenuType.DEAL_LIST_DSP">
                <button mat-menu-item (click)="onEdit(element)">
                  <mat-icon svgIcon="edit"></mat-icon>
                  <span>{{ 'genericTable.menu.edit' | translate }}</span>
                </button>
                <button mat-menu-item (click)="onArchive(element)" *ngIf="!element.isArchived">
                  <mat-icon svgIcon="archive"></mat-icon>
                  <span>{{ 'genericTable.menu.archive' | translate }}</span>
                </button>
                <button mat-menu-item (click)="onUnarchive(element)" *ngIf="element.isArchived">
                  <mat-icon svgIcon="archive"></mat-icon>
                  <span>{{ 'genericTable.menu.unarchive' | translate }}</span>
                </button>
                <button mat-menu-item (click)="onDelete(element)" [hidden]="element?.canDelete === false">
                  <mat-icon svgIcon="delete"></mat-icon>
                  <span>{{ 'genericTable.menu.delete' | translate }}</span>
                </button>
                
              </div>
              <div *ngSwitchCase="MenuType.DELETE">
                <button mat-menu-item (click)="onDelete(element)" [disabled]="element?.canBeDeleted === false">
                  <mat-icon svgIcon="delete"></mat-icon>
                  <span>{{ 'genericTable.menu.delete' | translate }}</span>
                </button>
              </div>
              <div *ngSwitchCase="MenuType.EDIT">
                <button mat-menu-item (click)="onEdit(element)">
                  <mat-icon svgIcon="edit"></mat-icon>
                  <span>{{ 'genericTable.menu.edit' | translate }}</span>
                </button>
              </div>
            </div>
          </mat-menu>
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="!isADminColumnExists">
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns;"
          [class]="tableCustomizer.getRowClass ? tableCustomizer.getRowClass(row) : ''"
          [ngClass]="{'mat-row--selected': selection.isSelected(row)}"
          (click)="route(row)"
        ></mat-row>
    </ng-container>

    <ng-container *ngIf="isADminColumnExists">
      <mat-header-row *matHeaderRowDef="displayedColumns.concat('menuColumn'); sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns.concat('menuColumn');"
      [class]="tableCustomizer.getRowClass ? tableCustomizer.getRowClass(row) : ''"
      [ngClass]="{'mat-row--selected': selection.isSelected(row)}"
      (click)="route(row)"
      >
     </mat-row>
      <ng-container matColumnDef="menuColumn">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row" class="last-column">
          <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">  
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="editUser(row)">
              <span>
                <mat-icon svgIcon="edit"></mat-icon>
              </span>
                <span>{{ 'genericTable.menu.edit' | translate }}</span>
            </button>
            <button mat-menu-item (click)="archiveUser(row)" *ngIf="!row.isArchived">
              <span>
                <mat-icon svgIcon="archive"></mat-icon>
              </span>
                <span>{{ 'genericTable.menu.archive' | translate }}</span>
            </button>
            <button mat-menu-item (click)="onUnarchiveUser(row)" *ngIf="row.isArchived">
              <span>
                <mat-icon svgIcon="archive"></mat-icon>
              </span>
                <span>{{ 'genericTable.menu.unarchive' | translate }}</span>
            </button>
            <button mat-menu-item (click)="generateAndCopyPassword(row)">
              <span>                
                <mat-icon svgIcon="lock"></mat-icon>
              </span>
              <span>
                <span>{{ 'genericTable.menu.newPassword' | translate }}</span>
              </span>            
            </button>
            <button mat-menu-item (click)="deleteUser(row)">
                <span> 
                    <mat-icon svgIcon="delete" class="color-danger"></mat-icon>
                </span>
                <span class="color-danger" >{{ 'genericTable.menu.delete' | translate }}</span>
            </button>
        </mat-menu>  
        </mat-cell>
      </ng-container>
    
    </ng-container>

    </mat-table>

    <div
      class="show-more"
      [ngClass]="{'show-more--active': isLoading}"
      *ngIf="pagingStrategy === 'scroll' && this.dataSource.data.length < this.totalElements && !isReset"
      inViewport
      [inViewportOptions]="{ threshold: 0 }"
      (inViewportAction)="onLoadMore($event)"
    >
      <button mat-raised-button color="primary-inverse" (click)="onLoadMore()">
        <mat-icon svgIcon="loader"></mat-icon>
        <span>{{ 'genericTable.loadMore' | translate }}</span>
      </button>
    </div>
    <div *ngIf="pagingStrategy === 'byPage' && totalElements > 0">
      <mat-paginator
        class="generic-table__paging"
        #paginator
        *ngIf="dataSource && dataSource.data && dataSource.data.length > 0"
        [length]="totalElements"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizes"
        (page)="onPageHandler($event)"
      ></mat-paginator>
    </div>
  </div>


  <mat-icon
    *ngIf="isTopButton"
    class="generic-table__top-button"
    svgIcon="arrow-up"
    (click)="top()"
  >{{ 'genericTable.arrowTop' | translate }}</mat-icon>
</div>

