import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { PageDto, CustomHttpUrlEncodingCodec } from '@dooh/models';
import { ApiService } from '@dooh/api';
import { AutocompleteService } from '@dooh/components-library';


@Injectable({
  providedIn: 'root'
})
export class SmbCurrenciesService implements AutocompleteService {
  sort = ['code,asc'];
  apiUrl = "admin";

  constructor(private apiService: ApiService) {}

  getAll(page: number = 1, pageSize: number = 50, filters?: Array<string>): Observable<PageDto> {
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()})
      .set('page', <any>page)
      .set('page_size', <any>pageSize);

    this.sort.forEach((element) => {
      queryParameters = queryParameters.append('sort', <any>element);
    });

    if (filters) {
      filters.forEach((element) => {
        queryParameters = queryParameters.append('filters', <any>element);
      });
    }

    return this.apiService.get(`${this.apiUrl}/smb/instances/currencies`, queryParameters);
  }
}
