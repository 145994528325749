/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface LineItemBudgetView { 
    budget?: number;
    cpmBidPerEachAd?: number;
    dailyBudget?: number;
    lineItemId?: string;
    pacing?: LineItemBudgetView.PacingEnum;
    remainingBudget?: number;
    remainingDailyBudget?: number;
}
export namespace LineItemBudgetView {
    export type PacingEnum = 'EVEN' | 'ASAP';
    export const PacingEnum = {
        EVEN: 'EVEN' as PacingEnum,
        ASAP: 'ASAP' as PacingEnum
    };
}
