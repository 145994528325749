<div mat-dialog-title>
  <span *ngIf="!isEditing"
    >{{ 'campaigns.createCampaign.schedule.addTimeModal.title' | translate }} To
    {{ data?.heading | titlecase }}</span
  >
  <span *ngIf="isEditing">Edit time for {{ data?.heading | titlecase }}</span>
  <div>
    <button mat-icon-button color="primary" (click)="onCloseClick()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
</div>

<div mat-dialog-content class="dialog-alert__content">
  <form
    appearance="outline"
    [formGroup]="timeRange"
    class="time-range-container"
  >
    <mat-form-field
      class="time-range-picker"
      matTooltip="Click on the clock to toggle dropdown"
      appearance="outline"
    >
      <mat-label>Select start time</mat-label>
      <input
        matInput
        type="time"
        formControlName="startTime"
        (change)="updateStartTime()"
        value="{{ startTime }}"
      />
    </mat-form-field>

    <mat-form-field
      class="time-range-picker"
      matTooltip="Click on the clock to toggle dropdown"
      appearance="outline"
    >
      <mat-label>Select end time</mat-label>
      <input
        matInput
        type="time"
        formControlName="endTime"
        (change)="updateEndTime()"
        value="{{ endTime }}"
      />
    </mat-form-field>
  </form>

  <span class="time-range__error">{{ error }}</span>
  <mat-menu #fromMenu="matMenu">
    <cdk-virtual-scroll-viewport itemSize="5" class="viewport-menu">
      <button
        mat-menu-item
        *ngFor="let item of times"
        (click)="updateStartTime(item)"
      >
        {{ item }}
      </button>
    </cdk-virtual-scroll-viewport>
  </mat-menu>

  <mat-menu #toMenu="matMenu">
    <cdk-virtual-scroll-viewport itemSize="5" class="viewport-menu">
      <button
        mat-menu-item
        *ngFor="let item of timesToField"
        (click)="updateEndTime(item)"
      >
        {{ item }}
      </button>
    </cdk-virtual-scroll-viewport>
  </mat-menu>
</div>

<div
  mat-dialog-actions
  class="dialog-custom__buttons-container"
  *ngIf="data?.submitButtonText"
>
<div class="dialog-custom__button" *ngIf="isEditing">
  <button
    mat-button
    type="button"
    color="warn"
    [mat-dialog-close]="true"
    (click)="onDeleteClick()"
  >
    Delete
  </button>
</div>
  <div class="dialog-custom__button">
    <button
      mat-flat-button
      type="button"
      color="primary"
      [mat-dialog-close]="true"
      [disabled]="disableSubmit"
      (click)="onSubmitClick()"
      cdkFocusInitial
    >
      {{ data?.submitButtonText | translate }}
    </button>
  </div>
</div>
