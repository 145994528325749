import { code } from 'currency-codes';
import { Pipe, PipeTransform } from '@angular/core';
import { SelectedInstanceService } from '@dooh/common-services';

enum PREFIXES {
  KILO = 'k',
  MEGA = 'M',
  GIGA = 'G',
  TERA = 'T',
  PETA = 'P',
  EXA = 'E',
  ZETTA = 'Z',
  YOTTA = 'Y',
}

const limitations = {
  [PREFIXES.KILO]: { from: 3, to: 6 },
  [PREFIXES.MEGA]: { from: 6, to: 9 },
  [PREFIXES.GIGA]: { from: 9, to: 12 },
  [PREFIXES.TERA]: { from: 12, to: 15 },
  [PREFIXES.PETA]: { from: 15, to: 18 },
  [PREFIXES.EXA]: { from: 18, to: 21 },
  [PREFIXES.ZETTA]: { from: 21, to: 24 },
  [PREFIXES.YOTTA]: { from: 24, to: 27 },
};

const regexp = /[^0-9.]/g;

@Pipe({
  name: 'abbreviate',
})
export class AbbreviatePipe implements PipeTransform {
  abbreviate(value: number, isCurrency?: boolean): string {
    const sign = value < 0 ? '-' : '';
    const unsignedValue = Math.abs(value);
    const instanceCurrency = SelectedInstanceService.getInstanceCurrency();
    const digits = code(instanceCurrency).digits;

    for (let limitation of Object.entries(limitations)) {
      const from = Math.pow(10, limitation[1].from);
      const to = Math.pow(10, limitation[1].to);
      if (unsignedValue >= from && unsignedValue < to) {
        const scaledValue = unsignedValue / from;
        return isCurrency
          ? `${sign}${scaledValue.toFixed(digits)}${limitation[0]}`
          : `${sign}${scaledValue.toFixed(2)}${limitation[0]}`;
      }
    }

    return `${value}`;
  }

  transform(input: string | number, isCurrency?: boolean): string {
    if (typeof input === 'string') {
      const possibleCurrencySymbols = input.match(regexp);
      const currencySymbol = isCurrency && !!possibleCurrencySymbols
        ? possibleCurrencySymbols.join('').trim()
        : null;
      const value = isCurrency && !!currencySymbol
        ? input.replace(regexp, '')
        : input;
      const _input = Number.parseFloat(value);
      const result = this.abbreviate(_input, isCurrency);
      return currencySymbol ? `${currencySymbol} ${result}` : `${result}`;
    } else {
      return `${this.abbreviate(input, isCurrency)}`;
    }
  }
}
