import { ValidatorFn, FormControl } from '@angular/forms';

interface ParamsInterface {
  max?: number;
  min?: number;
}
 export default function numberStringValidator(prms: ParamsInterface = {}): ValidatorFn {
  return (control: FormControl): {[key: string]: boolean} => {
    const val: number = control.value;
    if(!isNaN(prms.min)) {
      return val <= prms.min ? {"number": true} : null;
    } else if(!isNaN(prms.max)) {

      return val > prms.max ? {"number": true} : null;
    } else {

      return null;
    }
  };
}
