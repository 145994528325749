import { NativeDateAdapter } from '@angular/material/core';
import { Platform } from '@angular/cdk/platform';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

//initialize the date format
let MOMENT_DATE_FORMAT='dd/MM/YYYY';
let PIPE_DATE_FORMAT = 'dd/MM/yyyy';

//check if it's a US instance in case of dsp and change the format
let selectedInstance = localStorage.getItem('instance');
if(JSON.parse(selectedInstance)?.country?.code === "US"){
  MOMENT_DATE_FORMAT = 'MM/dd/yyyy';
  PIPE_DATE_FORMAT = 'MM/dd/yyyy';
}

export let CustomDateFormats = {
  parse: {
    dateInput: PIPE_DATE_FORMAT
  },
  display: {
    dateInput: PIPE_DATE_FORMAT,
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM yyyy'
  }
};

// export the format function, to be called after set instance in local storage
export function setFormat(){
  let selectedInstance = localStorage.getItem('instance');
  if(JSON.parse(selectedInstance)?.country?.code === "US"){
    MOMENT_DATE_FORMAT = 'MM/dd/yyyy';
    PIPE_DATE_FORMAT = 'MM/dd/yyyy';
  }else{
    MOMENT_DATE_FORMAT = 'dd/MM/YYYY';
    PIPE_DATE_FORMAT = 'dd/MM/yyyy';
  }
  CustomDateFormats.display.dateInput= PIPE_DATE_FORMAT;
  CustomDateFormats.parse.dateInput= PIPE_DATE_FORMAT;
}

export class CustomDateAdapter extends NativeDateAdapter {
  private dataPipe: DatePipe;

  constructor(matDateLocale: string, platform: Platform) {
    super(matDateLocale, platform);
    this.dataPipe = new DatePipe(matDateLocale);
  }

  parse(val: any): Date |  null {
    const date = moment(val, MOMENT_DATE_FORMAT)
    return date.isValid() ? date.toDate() : null;
  }

  format(date: Date, displayFormat: any): string {
    return this.dataPipe.transform(date, displayFormat);
  }
}
