import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProposalService } from '@dooh/api';
import { DialogService } from '../dialog.service';
import { CampaignService } from './../../../../../../apps/dsp-webapp/src/app/reporting-management/services/campaign.service';

@Component({
  selector: 'dooh-proposal-dialog',
  templateUrl: './proposal-dialog.component.html',
  styleUrls: ['./proposal-dialog.component.scss']
})
export class ProposalDialogComponent implements OnInit {
  shareForm: FormGroup;
  dialogData: any;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ProposalDialogComponent>,
    private proposalService: ProposalService,
    @Inject(MAT_DIALOG_DATA) dialogData: any,
    private dialogService: DialogService,
    private campaignService: CampaignService

  ) {
    this.dialogData = dialogData;
  }

  ngOnInit(): void {
    this.shareForm = this.formBuilder.group({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(\s*,\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))*\s*$/)
      ]),
        advertiserMarkup: new FormControl(null, [
        Validators.pattern(/^\s*(\d+(\.\d{1,2})?%?)?\s*$/),
        Validators.min(0),
      ]),
      showCPM: new FormControl(false),
      showStrategy: new FormControl(true),
      showTactics: new FormControl(true),
      strategy: new FormControl(''),
      tactics: new FormControl('')
    });
    this.campaignService.getAlladvertisersApi(this.dialogData.campaign.agency?.id,this.dialogData.campaign.advertiser?.id).subscribe((result) => {
      this.shareForm.get('advertiserMarkup').setValue(result.markup.toFixed(2));
    });
  }

  shareCampaign() {
    const emailsString = this.formatEmailString(this.shareForm.get('email').value);
    let advertiserMarkupValue = this.shareForm.get('advertiserMarkup').value;
    let advertiserMarkup: string | null = null;
  
    if (advertiserMarkupValue !== null && advertiserMarkupValue.trim() !== '') {
      const floatValue = parseFloat(advertiserMarkupValue);
      if (!isNaN(floatValue)) {
        advertiserMarkup = floatValue.toFixed(2);
      }
    }

    const payload: any = {
      emails: emailsString,
      showCpm: this.shareForm.get('showCPM').value,
      strategy: this.shareForm.get('strategy').value,
      tactics: this.shareForm.get('tactics').value,
      campaignId: this.dialogData?.campaignId,
      lineItemIds: this.dialogData?.lineItemIds,
    };
  
    if (advertiserMarkup !== null) {
      payload.advertiserMarkup = advertiserMarkup;
    }

    if (!this.shareForm.get('showStrategy').value) {
      payload.strategy = '';
    }
  
    if (!this.shareForm.get('showTactics').value) {
      payload.tactics = '';
    }
    this.proposalService.createProposal(payload).subscribe(
      () => {
        this.openSuccessDialog();
        this.dialogRef.close({ reset: true });
      }
    );
  }
  formatEmailString(emails: string): string {
    emails = emails.replace(/\s/g, '');
    const emailsArray = emails.split(',');
    return emailsArray.join(',');
  }

  openSuccessDialog(): void {
    this.dialogService.alert({
      type: 'success',
      message: `Proposal created successfully`,
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
  updateAdMarkupValue(event: any) {
    const value = event.target.value;
    const floatValue = parseFloat(value);
    if (!isNaN(floatValue)) {
      event.target.value = floatValue.toFixed(2);
      this.shareForm.get('advertiserMarkup').setValue(floatValue.toFixed(2));
    }
  }
}