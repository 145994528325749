import { Injectable } from '@angular/core';
import { ApiService } from '@dooh/api';
import { Observable } from 'rxjs';
import { PageDto, CustomHttpUrlEncodingCodec } from '@dooh/models';
import { HttpParams } from '@angular/common/http';
import { apiUrl } from '../../api-url';
import { SelectedInstanceService } from '@dooh/common-services';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  dspInstanceID: string;
  agencyID: string;
  advertiserID: string;

  constructor(
    private apiService: ApiService
  ) { }

  getAll(page: number = 1, pageSize: number = 50, filters?: string[]):  Observable<PageDto>{
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    queryParameters = queryParameters.set('page', <any>page);
    queryParameters = queryParameters.set('page_size', <any>pageSize);

    if (filters) {
      filters.forEach((element) => {
        queryParameters = queryParameters.append('filters', <any>element);
      });
    }

    return this.apiService.get(`${apiUrl}/instances/${this.dspInstanceID}/agencies/${this.agencyID}/advertisers/${this.advertiserID}/campaigns`, queryParameters);
  }

  setIds(dspInstanceID: string, agencyID: string, advertiserID: string){
    this.dspInstanceID = dspInstanceID;
    this.agencyID = agencyID;
    this.advertiserID = advertiserID;
  }
  getAlladvertisersApi(agencyID:string,advertiserID:string):  Observable<any>{
    const instanceId = SelectedInstanceService.getInstanceId();

    return this.apiService.get(`${apiUrl}/instances/${instanceId}/agencies/${agencyID}/advertisers/${advertiserID}`);
  }
}
