<div class="audience">
  <header class="header">
    <p class="title">Audience</p>
    <mat-icon svgIcon="close" class="close" (click)="closeDialog()"></mat-icon>
  </header>
  <div class="body">
    <section>
      <p class="sub-title">Data Provider</p>
      <mat-radio-group
        aria-label="Select an option"
        (change)="onPartnerChange($event)"
        [value]="partner"
      >
        <mat-radio-button
          class="small-radio-button-box"
          color="primary"
          *ngFor="let partner of partners"
          [value]="partner"
          >{{ partner | titlecase }}</mat-radio-button
        >
      </mat-radio-group>
    </section>
    <section>
      <p class="sub-title">Audience</p>
      <div class="button-group">
        <button class="btn btn--outline" (click)="openDemographics()">
          <mat-icon svgIcon="plus"></mat-icon>
          <span>Demographics</span>
        </button>
        <button class="btn btn--outline" (click)="openTargeting()">
          <mat-icon svgIcon="plus"></mat-icon>
          <span>Targeting</span>
        </button>
      </div>
    </section>
    <section [formGroup]="form">
      <ng-container formArrayName="categories">
        <div class="item" *ngFor="let category of categories.controls; let i=index">
          <ng-container [formGroupName]="i">
            <mat-form-field appearance="outline" class="select">
              <mat-select formControlName="verb" name="verb">
                <mat-option *ngFor="let verb of verbs" [value]="verb.value">
                  {{ verb.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="card">
              <div class="first-col">
                <p class="title">
                  <span class="title--main">{{category.value.category.split('->')[0]}}: </span>
                  <span class="title--sub"
                    >{{category.value.category.split('->').slice(1).join(' / ') | pascalCaseToText}}</span
                  >
                </p>
                <span class="content-placholder u-mb-16" *ngIf="isReachLoading && !(this.reach[category.value.category]) else total"></span>
                <ng-template #total>
                  <p class="screens-total">{{this.reach[category.value.category]?.high + this.reach[category.value.category]?.mid + this.reach[category.value.category]?.low}} SCREENS</p>
                </ng-template>
                <div class="ranges loader" *ngIf="isReachLoading && !(this.reach[category.value.category]) else rangesContent">
                  <span class="content-placholder"></span>
                  <span class="content-placholder"></span>
                  <span class="content-placholder"></span>
                </div>
                <ng-template  #rangesContent>
                  <div class="ranges" formGroupName="ranges">
                    <span class="high">
                        <mat-checkbox
                        color="primary"
                        formControlName="high"
                        >{{this.reach[category.value.category]?.high || 0}}</mat-checkbox
                      >
                      <span>High</span>
                    </span>
                    <span class="medium">
                        <mat-checkbox
                        color="primary"
                        formControlName="mid"
                        >{{this.reach[category.value.category]?.mid || 0}}</mat-checkbox
                      >
                      <span>Medium</span>
                    </span>
                    <span class="low">
                        <mat-checkbox
                        color="primary"
                        formControlName="low"
                        >{{this.reach[category.value.category]?.low || 0}}</mat-checkbox
                      >
                      <span>Low</span>
                    </span>
                  </div>
                </ng-template>
              </div>
              <div class="second-col" (click)="removeCategory(i)">
                <mat-icon svgIcon="close" class="close"></mat-icon>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </section>
  </div>
  <footer class="footer">
    <button class="btn btn--outline" (click)="resetForm()">Reset</button>
    <button class="btn btn--primary" (click)="submit(form.value)">
      <span>{{ 'screenProperties.footer.display' | translate }}</span>
    </button>
  </footer>
</div>
