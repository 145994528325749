import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { ScreensViewService } from "./services/screens-view.service";
import { ScreensApi } from "./screens-view.component.models";
import { ScreenView, SmbCampaignView } from "@dooh/models";
import { ScreensMapComponent } from "./components/screens-map/screens-map.component";
import { ScreensViewTabComponent } from "./components/screens-view-tab/screens-view-tab.component";
import { DialogService } from '@dooh/components-library';
import { Subject } from 'rxjs';
import { TargetingFiltersService } from '@dooh/common-services';
import { ApiService } from '@dooh/api';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dooh-screens-view',
  templateUrl: './screens-view.component.html',
  styleUrls: ['./screens-view.component.scss']
})
export class ScreensViewComponent implements AfterViewInit, OnDestroy {
  protected unsubscriber$ = new Subject<void>();

  @ViewChild("map") map: ScreensMapComponent;
  @ViewChild("tables") tables: ScreensViewTabComponent;


  @Input()
  screensApi: ScreensApi;

  @Input()
  editable: boolean;

  @Input()
  campaign: SmbCampaignView;

  @Input()
  isCreating: boolean;

  @Input()
  hideScreenProperties: boolean;

  @Input()
  bulkSuccesful$: Subject<any>

  @Output()
  deletedScreensChange?: EventEmitter<ScreenView[]> = new EventEmitter<ScreenView[]>();

  @Output()
  addedScreensChange?: EventEmitter<ScreenView[]> = new EventEmitter<ScreenView[]>();

  @Output()
  previewScreensChange?: EventEmitter<ScreenView[]> = new EventEmitter<ScreenView[]>();

  @Output()
  onEditClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output() cancel?: EventEmitter<void> = new EventEmitter<void>();

  @Output() isPaginated?: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() selectedView?: EventEmitter<boolean> = new EventEmitter<boolean>();

  mapView = true;
  isMapPaginated: boolean;
  expectedReach = 0;
  isDownloadingCSV: boolean;

  constructor(
    public dialogService: DialogService,
    public targetingFiltersService: TargetingFiltersService,
    private screensViewService: ScreensViewService,
    private apiService: ApiService
    ){}

  ngAfterViewInit() {
    this.selectedView.emit(this.mapView);
    this.bulkSuccesful$.subscribe(()=>{
      this.screensViewService.resetSharedData();
      this.afterSave();
    })
  }

  onPreviewScreensChange($event){
    this.previewScreensChange.emit($event)
  }

  onDeletedScreensChange($event){
    this.deletedScreensChange.emit($event);
  }

  onAddedScreensChange($event){
    this.addedScreensChange.emit($event);
  }

  checkPagination($event) {
    this.isMapPaginated = $event;
    this.isPaginated.emit(this.isMapPaginated);
  }

  afterSave(){
    !this.mapView && this.tables.afterSave()
    this.mapView && this.map.afterSave()
  }

  onCancel(){
    if ((this.mapView && this.map.canDeactivate()) || (!this.mapView && this.tables.canDeactivate())) {
      this.mapView && this.map.onCancelConfirm();
       !this.mapView && this.tables.onCancelConfirm();
       this.editable = false;

    } else {
      this.dialogService.alert({
        type: 'warning',
        message: 'screensTab.dialog.cancel.message',
        submitButtonText: 'screensTab.dialog.cancel.submitButton',
        cancelButtonText: 'screensTab.dialog.cancel.cancelButton',
      }).afterClosed().subscribe(res => {
        if (!res) return;
        this.mapView && this.map.onCancelConfirm();
        !this.mapView && this.tables.onCancelConfirm();
        this.editable = false;
      });
    }
  }

  toggleView(val: boolean) {
    this.mapView = val;
    this.selectedView.emit(this.mapView);
  }

  downloadCSV(){
    this.isDownloadingCSV = true;
    this.screensApi.getScreensCSV()
    .pipe(
      takeUntil(this.unsubscriber$)
    )
    .subscribe(
      result=>{
        this.isDownloadingCSV = false;
        this.screensApi.openDownloadPage(result.screenCsvId);
      },
      error=>{
        this.isDownloadingCSV = false;
      }
    )
  }

  ngOnDestroy() {
    this.screensViewService.resetSharedData();
    this.targetingFiltersService.setPoiStatus(false);
    this.targetingFiltersService.setScreenPropertiesStatus(false);
    this.targetingFiltersService.setPoiFilters(null);
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
