import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export const BULK_REQUEST_LIMIT = 3000;
@Injectable({
  providedIn: 'root',
})
export class BulkSelectService {
  private isTotalSelected: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  isTotalSelected$ = this.isTotalSelected.asObservable();
  private query: BehaviorSubject<IBulkSelectQuery> = new BehaviorSubject(null);
  query$ = this.query.asObservable();
  private totalCount: BehaviorSubject<number> = new BehaviorSubject(0);
  totalCount$ = this.totalCount.asObservable();

  constructor() {}

  setisTotalSelected(value): void {
    this.isTotalSelected.next(value);
  }

  setQuery(value: IBulkSelectQuery): void {
    this.query.next(value);
  }

  setTotalCount(value: number): void {
    this.totalCount.next(value);
  }

  getIsTotalSelectedCurrentValue(): boolean {
    return this.isTotalSelected.value;
  }

  getTotalCount(): number {
    return this.totalCount.value;
  }

  getQueryCurrentValue(): any {
    return this.query.value;
  }

  resetBulkService(){
    this.setisTotalSelected(false);
    this.setQuery(null);
    this.setTotalCount(0);
  }
}

export interface IBulkSelectQuery{
  bbox?: IBBox,
  bboxes?: IBBox[],
  clusters?: {
    precision: number,
    size: number
  },
  fieldName?: string,
  limits?: {
    [key: string]: {
      max: number,
      min: number
    }
  },
  params?: {
    [key: string]: {
      filterTerms?: string[],
      nameOfResultCollection?: string,
      page?: number,
      pageSize?: number
    }
  },
  poi?: {
    distance?: number,
    points?: number[][]
  },
  query?: string,
  venueTypes?: string[]
}

export interface IBBox{
  maxLatitude: number,
  maxLongitude: number,
  minLatitude: number,
  minLongitude: number
}