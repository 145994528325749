import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@dooh/api';
import { AutocompleteService } from '@dooh/components-library';
import { CustomHttpUrlEncodingCodec, PageDto } from '@dooh/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { apiUrl } from '../../../../../apps/admin-panel/src/app/api-url';

@Injectable({
  providedIn: 'root'
})
export class NamespaceSspService implements AutocompleteService {
  sort = ['field_name,asc'];
  filters = ['eq'];
  $sspId = new BehaviorSubject<string>(null)

  constructor(
    private apiService: ApiService
  ) { }

  getAll(page: number = 1, pageSize: number = 50, filters?: string[], args?: any, keyword?: string,): Observable<PageDto> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (keyword !== undefined && keyword !== null) {
      queryParameters = queryParameters.set('search_string', <any>keyword);
    }

    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('page_size', <any>pageSize);
    }
    
    const sspId = this.$sspId.value;

    return this.apiService.get(`dsp/namespace_inventory/${sspId}/publishers`, queryParameters);
  }

  setSSPId(id: any): void {
    this.$sspId.next(id);
  }
}
