import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TableCustomizer, ColumnType, MenuType, PageSizes, LINEITEM_STATE } from '@dooh/models';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { formatToDateWithoutMillSec } from '@dooh/utils';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { DatePipe, DecimalPipe } from '@angular/common';
import * as moment from 'moment';
import { BulkSelectService, BULK_REQUEST_LIMIT } from '../services/bulk-select.service';
import { UsDatePipe } from '@dooh/common-pipes';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 700,
  hideDelay: 700,
  touchendHideDelay: 700
};
const DEFAULT_CELL_VALUE = '—';

const DEFAULT_PAGE_SIZE = PageSizes[0];

@Component({
  selector: 'dooh-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }
  ]
})

export class GenericTableComponent<T> implements OnInit, OnChanges, OnDestroy, AfterViewInit  {
  @Input() tableCustomizer: TableCustomizer;
  @Output() item: EventEmitter<{ element: T, action: string }>;
  @Output() activate: EventEmitter<{ element: T, deactivated: boolean, index: number }>;
  @Output() header: EventEmitter<{ name: string, direction: string }>;
  @Output() scrolling: EventEmitter<boolean>;
  @Output() onPage: EventEmitter<{ pageNumber: number, pageSize: number }>;
  @Output() routingElement: EventEmitter<T>;
  @Output() selectAll: EventEmitter<{ checked: boolean }>;
  @Input() displayedColumns: string[] = [];
  @Input() renderTable: Observable<void>;
  @Input() dataSource: MatTableDataSource<T>;
  @Input() totalElements: number;
  @Input() isLoading: boolean;
  @Input() isReset: boolean;
  @Input() pageSize: number;
  @Input() defaultSort?: string;
  @Input() canBulkSelect: boolean = false;
  @Input() noBulkSelectLimit: boolean = false;
  @Input() defaultSortDirection?: string;
  @Input () isADminColumnExists: boolean = false;
  @Output() actionEvent: EventEmitter<{ action: string, element: string }>;
  @Output() generateAndCopyPasswords: EventEmitter<T>;
  @Output() deleteUsers: EventEmitter<T>;
  @Output() archiveUsers: EventEmitter<T>;  
  @Output() editUsers: EventEmitter<T>;
  @Output() onUnarchiveUsers: EventEmitter<T>;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  private eventsSubscription: Subscription;
  private subscriptionSelectedElements: Subscription;
  private subscriptionDisablingElements: Subscription;
  selection = new SelectionModel<T>(true, []);
  loading = false;
  @Input() selectedElements: Observable<{ action: string, items: string[] }>;
  @Input() disablingElements: Observable<{ action: string, items: string[] }>;
  idExtractor: (value: any) => string;
  isAllSelected = false;
  isTotalSelected$ = null;
  @Input()
  isMatSortDisabled = false;
  @Input()
  unit: string;
  @Input()
  pagingStrategy: 'scroll' | 'byPage';
  pageIndex: number;
  pageSizes = PageSizes;

  isTopButton: boolean;
  topButtonOffset = 0.2;
  @Input()
  hasExclusionData = false;
  isAllItemsExcluded: boolean;
  @Input() dateFormat?:string ='dd/MM/yyyy' ;
  LINEITEM_STATE = LINEITEM_STATE;

  bulkLimit = BULK_REQUEST_LIMIT;

  constructor(private hostRef: ElementRef, private _decimalPipe: DecimalPipe, private _datePipe: DatePipe, private _usDatePipe: UsDatePipe, private bulkSelectService: BulkSelectService) {
    this.item = new EventEmitter<{ element: T, action: string }>();
    this.header = new EventEmitter<{ name: string, direction: string }>();
    this.scrolling = new EventEmitter<boolean>();
    this.activate = new EventEmitter<{ element: T, deactivated: boolean, index: number }>();
    this.routingElement = new EventEmitter<T>();
    this.generateAndCopyPasswords=  new EventEmitter<T>();
    this.deleteUsers=  new EventEmitter<T>();
    this.archiveUsers= new EventEmitter<T>();
    this.editUsers= new EventEmitter<T>();
    this.onUnarchiveUsers= new EventEmitter<T>();
    this.selectAll = new EventEmitter<{ checked: boolean }>();
    this.onPage = new EventEmitter<{ pageNumber: number, pageSize: number }>();
    this.isTotalSelected$ = this.bulkSelectService.isTotalSelected$;
  }

  ngOnInit() {
    this.pageIndex = 0;
    this.pageSize = this.pageSize || DEFAULT_PAGE_SIZE;
    this.pagingStrategy = this.pagingStrategy || 'byPage';
    this.idExtractor = this.tableCustomizer?.idExtractor
      ? this.tableCustomizer?.idExtractor
      : item => item.id;

    this.displayColumns(this.tableCustomizer?.columnCustomizers);
    if (this.renderTable !== undefined) {
      this.eventsSubscription = this.renderTable.subscribe(() => {
        this.table.renderRows();
      });
    }

    this.checkSelectedAndDisabledItems();
    
  }

  ngAfterViewInit():void{
    // this.dataSource.paginator = this.paginator;
  }

  onScrollEvent($event) {
    const { target: { clientHeight, scrollTop } } = $event;
    this.isTopButton = scrollTop > (clientHeight * this.topButtonOffset);
  }

  top() {
    if (!this.hostRef || !this.hostRef.nativeElement) return;
    const table = this.hostRef.nativeElement.querySelector('.generic-table-wrapper');
    if (!table) return;
    table.scrollTop = 0;
  }

  ngOnDestroy() {
    if (this.renderTable !== undefined) {
      this.eventsSubscription.unsubscribe();
    }
    if (this.selectedElements !== undefined) {
      this.subscriptionSelectedElements.unsubscribe();
    }
    if (this.disablingElements !== undefined) {
      this.subscriptionDisablingElements.unsubscribe();
    }
  }

  ngOnChanges(changes) {
    if (changes['dataSource'] && this.dataSource) {
      this.checkSelectedAndDisabledItems();
    }
    if (changes['totalElements'] && this.totalElements && this.canBulkSelect) {
      this.bulkSelectService.resetBulkService();
    }
    if (changes['isReset'] && this.isReset) {
      if (this.paginator) this.paginator.pageIndex = 0;
      this.top();
    }
  }

  trackById(index: number, item: T) {
    return this.idExtractor(item);
  }

  onSortChange($event: Sort) {
    this.top();
    if (!this.header) {
      return;
    }
    const value = { name: $event['active'], direction: $event['direction'] };
    this.paginator?.firstPage();
    return this.header.emit(value);
  }


  onDeactivate(element: T) {
    if (!this.item) {
      return;
    }
    const value = { element: element, action: 'DEACTIVATE' };
    return this.item.emit(value);
  }

  onActivate(element: T) {
    if (!this.item) {
      return;
    }
    const value = { element: element, action: 'ACTIVATE' };
    return this.item.emit(value);
  }

  onDelete(element: T) {
    if (!this.item) {
      return;
    }
    const value = { element: element, action: 'DELETE' };
    return this.item.emit(value);
  }

  onEdit(element: T) {
    if (!this.item) {
      return;
    }
    const value = { element: element, action: 'EDIT' };
    return this.item.emit(value);
  }

  onArchive(element: T) {
    if (!this.item) {
      return;
    }
    const value = { element: element, action: 'ARCHIVE' };
    return this.item.emit(value);
  }

  onUnarchive(element: T) {
    if (!this.item) {
      return;
    }
    const value = { element: element, action: 'UNARCHIVE' };
    return this.item.emit(value);
  }

  onCancel(element: T) {
    if (!this.item) {
      return;
    }
    const value = { element: element, action: 'CANCEL' };
    return this.item.emit(value);
  }

  onRestore(element: T) {
    if (!this.item) {
      return;
    }
    const value = { element: element, action: 'RESTORE' };
    return this.item.emit(value);
  }

  onCopy(element: T) {
    if (!this.item) {
      return;
    }
    const value = { element: element, action: 'COPY' };
    return this.item.emit(value);
  }

  checkElement(element: T, $event: MouseEvent) {
    if (!this.item || !$event) {
      return;
    }
    const isSelected = this.isAllSelected ? !this.selection.isSelected(element) : this.selection.isSelected(element);

    let action = 'SELECT';
    if (isSelected) {
      action = 'DESELECT';
    }
    const value = { element: element, action: action };
    this.selection.toggle(element);
    return this.item.emit(value);
  }

  readObject(element: any, flatKeys: string[], valueExtractor: (element: any) => any, columnType?:ColumnType, isRoundNumber = false, canBeEmpty = false) {
    if ((flatKeys === null || flatKeys === undefined) && !valueExtractor) {
      return '';
    }
    if(columnType === ColumnType.STATUS_PILLS && valueExtractor){
      return valueExtractor(element);
    }
    if (valueExtractor) {
      return isRoundNumber ? Math.round(valueExtractor(element)) : valueExtractor(element);
    }
    
    

    let $current = element;
    for (const key of flatKeys) {
      if (Array.isArray($current)) {
        $current = $current[parseInt(key, 10) || 0];
      } else {
        $current = $current[key];
      }

      if ($current === null || $current === undefined) {
        if(columnType === ColumnType.DURATION){
          return 0
        }
        return DEFAULT_CELL_VALUE;
      }
      if (key === 'endDate') {
        return this._usDatePipe.transform(formatToDateWithoutMillSec($current.toString()),this.dateFormat);
      }
    }
    if(columnType === ColumnType.NUMBER){
      return this._decimalPipe.transform($current,'1.0-0')
    }
    if(columnType === ColumnType.DECIMAL){
      return this._decimalPipe.transform($current,'1.0-2')
    }
    if(columnType === ColumnType.DATE){
      return this._usDatePipe.transform($current,this.dateFormat)
    }
    
    return isRoundNumber ? this._decimalPipe.transform(Math.round($current),'1.0-0') : $current;
  }

  private displayColumns(columnCustomizers) {
    if (this.displayedColumns.length === 0) {
      for (const columnCustomizer of columnCustomizers) {
        this.displayedColumns.push(columnCustomizer.columnDef);
      }
    }

    if (this.tableCustomizer.containsMenu) {
      this.displayedColumns.push('menu');
    }
  }

  onLoadMore($event) {
    if ($event && !$event.visible) {
      return;
    }
    if (!this.scrolling) {
      return;
    }

    this.scrolling.emit(true);
  }

  activeToggle($event: MatSlideToggleChange, element: T, index: number) {
    if (!this.activate) {
      return;
    }
    const value = { element: element, deactivated: !$event.checked, index: index };
    this.activate.emit(value);
  }

  route(row: T) {
    if (!this.routingElement) {
      return;
    }
    this.routingElement.emit(row);
  }

  onPageHandler($event) {
    if (!this.onPage) {
      return;
    }
    this.onPage.emit(
      {
        pageNumber: $event.pageIndex + 1,
        pageSize: $event.pageSize
      }
    );
    this.pageSize = $event.pageSize;
    this.pageIndex = $event.pageIndex;
    this.clearAllSelection();
  }

  clearAllSelection(){
    this.isAllSelected = false;
    this.selection.clear();
    // this.selectAll.emit({ checked: this.isAllSelected });
  }

  setIsTotalSelected(val:boolean){
    this.bulkSelectService.setisTotalSelected(val);
    this.bulkSelectService.setTotalCount(this.totalElements);
  }
  get ColumnType() {
    return ColumnType;
  }

  get MenuType() {
    return MenuType;
  }

  private disableElements(elements: T[], isDisabled = true) {
    this.isAllItemsExcluded = this.dataSource.data.length === elements.length && isDisabled? true : false
    for (const item of elements) {
      item['excluded'] = isDisabled;
    }
  }

  onSelectAll() {

    if (!this.selectAll) {
      return;
    }
    this.isAllSelected = !this.isAllSelected;
    if(!this.isAllSelected) {
      this.setIsTotalSelected(this.isAllSelected)
    }
    this.dataSource.data.forEach(item => {
      if (!item['excluded']) {
        this.selection.deselect(item);
      }
    });

    this.selectAll.emit({ checked: this.isAllSelected });
  }

  totalElementsFormatter(){
    return this.totalElements.toLocaleString(navigator.language);
  }

  isAllSelectedMaster() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }


  private checkSelectedAndDisabledItems(): void {
    if (this.selectedElements !== undefined) {
      this.subscriptionSelectedElements = this.selectedElements.subscribe((resp: { action: string, items: string[] }) => {
        if (resp) {
          const elements: T[] = [];
          const responseHashMap = resp.items.reduce((storage, id) => {
            storage[id] = true;
            return storage;
          }, {});
          for (const item of this.dataSource.data) {
            if (this.idExtractor(item) in responseHashMap) {
              elements.push(item);
            }
          }

          if (resp.action === 'SELECT') {
            this.selection.select(...elements);
          } else if (resp.action === 'DESELECT') {
            this.selection.deselect(...elements);
          } else if (resp.action === 'DESELECT_ALL') {
            this.isAllSelected = false;
          } else if (resp.action === 'RESET') {
            this.selection.clear();
          }
        }

      });
    }
    if (this.disablingElements !== undefined) {

      this.subscriptionDisablingElements = this.disablingElements.subscribe((resp: { action: string, items: string[] }) => {
        if (resp) {
          const elements: T[] = [];
          const responseHashMap = resp.items.reduce((storage, id) => {
            storage[id] = true;
            return storage;
          }, {});
          for (const item of this.dataSource.data) {
            if (this.idExtractor(item) in responseHashMap) {
              elements.push(item);
            }
          }
          if (resp.action === 'DISABLE') {
            this.disableElements(elements);
          } else if (resp.action === 'ENABLE') {
            this.disableElements(elements, false);
          } else if (resp.action === 'RESET') {
            this.disableElements(this.dataSource.data, false);
          }
        }
      });
    }
  }
  
  generateAndCopyPassword(row:T) {    
    this.generateAndCopyPasswords.emit(row);
  }

  deleteUser(row:T) {
    this.deleteUsers.emit(row);
  }

  archiveUser(row: T) {
    this.archiveUsers.emit(row);
  }

  onUnarchiveUser(row: T) {
    this.onUnarchiveUsers.emit(row);
    this.table.renderRows();
  }

  editUser(row:T){    
    this.editUsers.emit(row); 
  }
}
